import React from "react";
import {OverlayTrigger, Tooltip as BsTooltip} from "react-bootstrap";
import {Placement} from "react-bootstrap/types";
import {TooltipStyled} from "./styled";

type ElementWithTooltipProps = {
    element: JSX.Element,
    tooltip: JSX.Element,
    placement?: Placement | undefined,
    fontSize?: number
}

export function ElementWithTooltip({element, tooltip, placement, fontSize}: ElementWithTooltipProps) {
    return (

        <OverlayTrigger
            key={"top"}
            placement={placement ? placement : 'top'}
            overlay={
                <BsTooltip id={`tooltip-top`}>
                    <TooltipStyled fontSize={fontSize}>
                        {tooltip}
                    </TooltipStyled>

                </BsTooltip>
            }
        >
            {element}
        </OverlayTrigger>

    );
}
