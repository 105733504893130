import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface DealOpenState {
    opened: boolean
}


const initialState: DealOpenState = {
    opened: false
}


export interface DealOpenToggleAction {
    opened?:boolean
}

export const dealOpenSlice = createSlice({
    name: 'dealOpenSlice',
    initialState,
    reducers: {
        toggleDealOpen(state, action: PayloadAction<DealOpenToggleAction>) {
            state.opened = !state.opened
        },
        setDealOpenState(state, action: PayloadAction<DealOpenToggleAction>) {
            state.opened = action.payload.opened || false
        },
    }
})

export const {
    toggleDealOpen,
    setDealOpenState
} = dealOpenSlice.actions

export default dealOpenSlice.reducer
