import styled from 'styled-components';

export const ContainerStyled = styled.div.attrs((props) => ({
    className:'row g-0'
}))`
    
`

export const InnerContainerStyled = styled.div.attrs((props) => ({
    className:'col-md-12'
}))`
    
`
