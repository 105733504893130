import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {SessionContext} from '../../../providers/session/SessionContext';
import {getSettings, validate} from '../../../services/ValidationService';
import {getStyle, isElementOverFlown} from '../../../utils/CssUtils';
import {notBlank} from '../../../utils/TypeCheckers';
import {assignReferences, constructLabel, noop, RunOnceEffect} from '../../../utils/Utils';
import {fieldService} from '../../FieldService';
import {FieldConfigDTO, FieldProperties, FormMode} from '../../types';
import {ElementWithTooltip} from '../element-with/element-with-tooltip/ElementWithTooltip';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {
    cleanUpDateSummary,
    DateSummaryResponseWrapper,
    DateSummaryState,
    getDateSummaryData,
    initDateSummary
} from './dateSummarySlice';
import {
    ButtonStyled,
    ColumnStyled,
    DateSelectorLabelStyled,
    DateSelectorStyled,
    DateSelectorWrapperStyled,
    DateSummaryWrapperStyled,
    TableBodyStyled,
    TableContainerStyled,
    TableContentStyled,
    TableResponsiveStyled,
    TableRowStyled,
    TableStyled
} from './styled';
import {DateSummaryTableBO, SummaryRowDTO} from './types';

export const DateSummaryField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const sessionContext = React.useContext(SessionContext);
    const dispatch = useAppDispatch();

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties)  ||  properties.data|| '');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentAbortController, setCurrentAbortController] = useState<AbortController | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const tableWrapper = document.getElementById('table_wrapper_' + properties.fieldConfig.name)
    // @ts-ignore
    const isOverFlown = isElementOverFlown({element: tableWrapper, direction: 'x'});
    const tBodyHeight = window.innerHeight - 200;

    useEffect(() => {
        if (properties?.data) {
            const summaryTable = properties?.data as DateSummaryTableBO
            dispatch(initDateSummary({
                code: properties.fieldConfig.name,
                sessionContext: sessionContext,
                allFields: summaryTable.fields || [],
                tableData: summaryTable.content || [],
                label: summaryTable.label,
                dataUrl: summaryTable.dataUrl,
                fromDate: summaryTable.fromDate,
                hasNext: summaryTable.hasNext,
                hasPrevious: summaryTable.hasPrevious
            }));
            setLoaded(true);
        }
        return (() => {
            dispatch(cleanUpDateSummary({code: properties.fieldConfig.name}))
        })
    }, [properties])


    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true);
        const message = validate(properties.menuItem?.code, properties.fieldConfig.name, properties.fieldConfig.validators, 'checkbox', fieldValue);
        if (message) {
            setErrorMessage(message);
            return false;
        }
        return true;
    }


    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        return <>Not Implemented</>
    }

    const renderFirstColumn = (fld: FieldConfigDTO, summaryRow: SummaryRowDTO, idx: number, idx2: number): React.ReactElement => {
        return <ColumnStyled bold={true} textAlign={'left'}
                             style={getStyle(fld.labelCss)}
                             key={'row_col' + fld.name + '_' + idx}
                             id={'row_col' + fld.name + '_' + idx}
        >{constructLabel(fld.label, summaryRow)}</ColumnStyled>
    }

    const renderColumn = (fld: FieldConfigDTO, summaryRow: SummaryRowDTO, idx: number, idx2: number): React.ReactElement => {
        return <ColumnStyled textAlign={fld.align}
                             key={'col_' + fld.name + '_' + idx + '_' + idx2}
                             id={'col_' + fld.name + '_' + idx + '_' + idx2}
                             style={getStyle(fld.labelCss)}>
            {fieldService.getTableField(fld, undefined, summaryRow)?.render()}
        </ColumnStyled>
    }

    const renderColumnHeadings = (state: DateSummaryState): React.ReactElement => {
        return (
            <TableRowStyled key={'hdr_row'} id={'hdr_row'}>
                <ColumnStyled bold={true} textAlign={'right'} key={'hdr_row'}>&nbsp;</ColumnStyled>
                {state.tableData && state.tableData
                    .map((summaryRow, idx) => <ColumnStyled bold={true} textAlign={'right'}
                                                            key={'hdr_row_' + idx}>{summaryRow.label}</ColumnStyled>)}
            </TableRowStyled>
        )
    }

    const renderRow = (state: DateSummaryState, idx: number, fld: FieldConfigDTO): React.ReactElement => {
        return (
            <TableRowStyled key={'id_' + idx + '_fld' + fld.name} id={'id_' + idx + '_fld' + fld.name}>
                {state.tableData && state.tableData.slice(0, 1).map((summaryRow: SummaryRowDTO, idx2: number) => renderFirstColumn(fld, summaryRow, idx, idx2))}
                {state.tableData && state.tableData.map((summaryRow: SummaryRowDTO, idx2: number) => renderColumn(fld, summaryRow, idx, idx2))}
            </TableRowStyled>
        )
    }


    const renderRows = (state: DateSummaryState): React.ReactElement => {
        return (
            <>
                {state.allFields && state.allFields
                    .map((fld, idx) => renderRow(state, idx, fld))}
            </>
        )
    }


    const refreshData = (state: DateSummaryState, next: boolean) => {
        const abortController = new AbortController();
        setCurrentAbortController(abortController);

        if (notBlank(state.dataUrl)) {
            dispatch(getDateSummaryData({
                code: properties.fieldConfig.name,
                url: state.dataUrl,
                next: next,
                fromDate: state.fromDate,
                sessionContext: sessionContext,
                abortController: abortController
            }))
                .unwrap()
                .then((reply: DateSummaryResponseWrapper) => {
                    setLoading(false);
                    setErrorMessage('');
                })
                .catch((error: any) => {
                    console.log('error', error)
                    setLoading(false);
                })
        }

    }
    const renderDateSelector = (state: DateSummaryState): React.ReactElement => {

        const onPreviousClicked = () => {
            console.log('previous clicked')
            if (state.hasPrevious) {
                refreshData(state, false)
            }
        }

        const onNextClicked = () => {
            console.log('next clicked')
            if (state.hasNext) {
                refreshData(state, true)
            }
        }

        return <DateSelectorWrapperStyled key={'ds_0'}>
            <DateSelectorLabelStyled>Date selector</DateSelectorLabelStyled>
            <ButtonStyled active={state.hasPrevious} role={'button'} onClick={() => onPreviousClicked()}>
                <ElementWithTooltip
                    element={<i className="fas fa-chevron-left"/>}
                    tooltip={<span>Previous week</span>}/>
            </ButtonStyled>
            <DateSelectorStyled>{state.label}</DateSelectorStyled>
            <ButtonStyled active={state.hasNext} role={'button'} onClick={() => onNextClicked()}>
                <ElementWithTooltip
                    element={<i className="fas fa-chevron-right"/>}
                    tooltip={<span>Next week</span>}/>
            </ButtonStyled>
        </DateSelectorWrapperStyled>
    }

    const renderInnerConnectedTable = (state: DateSummaryState): React.ReactElement => {
        return <DateSummaryWrapperStyled>
            {renderDateSelector(state)}
            <TableContainerStyled>
                <TableContentStyled>
                    <TableResponsiveStyled>
                        <TableStyled className={'table table-striped'}>
                            <TableBodyStyled tBodyHeight={tBodyHeight} isOverFlown={isOverFlown}>
                                {state.tableData && renderColumnHeadings(state)}
                                {renderRows(state)}
                            </TableBodyStyled>
                        </TableStyled>
                    </TableResponsiveStyled>
                </TableContentStyled>
            </TableContainerStyled>
        </DateSummaryWrapperStyled>
    }

    const renderInnerTable = (): React.ReactElement => {
        const mapStateToProps = (state: any): DateSummaryState => {
            return (state.dateSummarySlice[properties.fieldConfig.name])
        }
        const ConnectedInnerTable = connect(mapStateToProps)(renderInnerConnectedTable);
        return <>
            <ConnectedInnerTable/>
        </>
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        return (
            <>
                {!hideField && <>
                    {renderInnerTable()}
                </>}
            </>
        )
    }

    if (!loaded) {
        return <></>
    }
    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}
