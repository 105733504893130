import React, {useState} from 'react';
import {BehaviorSubject, Observable} from 'rxjs'
import {GlobalSearchDTO} from '../../components/common/global-search/type';
import {IMenuDTO, ISystemDTO, MenuType, SystemNotificationDTO, ToolbarItemDTO, WidgetDO} from '../../components/types';
import {log} from '../../services/LogService';
import {isDefined} from '../../utils/TypeCheckers';
import {RunOnceEffect} from '../../utils/Utils';
import {BlastContext} from '../blast/BlastContext';
import {SimpleMessageBO} from '../blast/commands';
import DataCache from '../blast/DataCache';
import {EnvironmentContext} from '../environment/EnvironmentContext';
import {environmentService} from '../environment/EnvironmentService';
import {SessionContext} from '../session/SessionContext';
import {GuslUser} from '../session/types';
import {ReactChildProperties} from '../theme/GuslThemeProvider';
import {SystemContext} from './SystemContext';

export const SystemProvider: React.FC<ReactChildProperties> = ({children}) => {

    const sessionContext = React.useContext(SessionContext)
    const blastContext = React.useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext)

    const [className] = React.useState<string>(() => 'SystemProvider-' + new Date().getTime());

    const [menuMap] = useState<Map<string, IMenuDTO>>(new Map<string, IMenuDTO>());
    const [systemLoadedSubject] = React.useState<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false));
    const [systemConfigSubject] = React.useState<BehaviorSubject<ISystemDTO>>(new BehaviorSubject<ISystemDTO>(
        {menuGroups: []} as ISystemDTO
    ));
    const [systemNotificationSubject] = React.useState<BehaviorSubject<SystemNotificationDTO | undefined>>(new BehaviorSubject<SystemNotificationDTO | undefined>(undefined));
    const [toolbarItemsMap] = useState<Map<string, ToolbarItemDTO>>(new Map<string, ToolbarItemDTO>());
    const [toolbarMenuMap] = useState<Map<string, IMenuDTO>>(new Map<string, IMenuDTO>());
    const [widgets, setWidgets] = useState<WidgetDO[]>([]);

    const [_hasMobileFooter, setHasMobileFooter] = React.useState<boolean>(false);

    const [globalSearchConfig, setGlobalSearchConfig] = useState<GlobalSearchDTO | undefined>(undefined);

    RunOnceEffect(() => {
        blastContext.observeInboundCommands().subscribe((inbound: SimpleMessageBO<any>) => {
            if (inbound && inbound.cmd) {
                switch (inbound.cmd) {
                    case DataCache.THEME_CHANGED_CMD :
                        if (inbound.data) {
                            sessionContext.updateTheme(inbound.data)
                        }
                }
            }
        })
    })

    // const injectExtraOptions = (system: ISystemDTO) => {
    //     if (system?.menuGroups) {
    //         system.menuGroups.push({
    //             label: 'Favorites', icon: 'pi pi-fw pi-home', displayOrder: 10,
    //             menuItems: [
    //                 {label: 'Dashboard', icon: 'pi pi-fw pi-home', code: '/', displayOrder: 10},
    //                 {label: 'Table', icon: 'pi pi-fw pi-table', code: '/table', displayOrder: 20},
    //                 {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', code: '/chart', displayOrder: 30},
    //             ]
    //
    //         } as IMenuDTO)
    //     }
    // }

    const loadCss = () => {
        try {
            const style = document.createElement('link');
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.href = `${(environmentService.getEnvironment()?.apiBase || '')
                .replace('/rest', '')}/css/bespoke.css?${new Date().getTime()}`
            document.getElementsByTagName('head')[0].appendChild(style);
        } catch (error) {
            log.error(className, 'ERR004', 'css load failed', error)
        }
    }

    const checkHasMobileFooter = (config: ISystemDTO): void => {
        const toolbarMenus: IMenuDTO[] = config?.toolbarMenus || []
        let found: boolean = false;
        toolbarMenus
            .filter(menuGroup => menuGroup.showInSideBar)
            .filter(menuGroup => menuGroup.footer)
            .forEach(toolbar => found = true);

        // if (found && environmentContext.isMobileDevice()) {
        //     setHasMobileFooter(true)
        //     document.documentElement.style.setProperty(`--${'gusl-footer-height'}`, FOOTER_HEIGHT + 'px');
        // } else {
        //     document.documentElement.style.setProperty(`--${'gusl-footer-height'}`, '0px');
        // }

        if (found && environmentContext.isMobileDevice()) {
            setHasMobileFooter(true)
        }

        if (!environmentContext.isMobileDevice()) {
            document.documentElement.style.setProperty(`--gusl-mobile-footer-bar`, '0px');
        }
    }
    const loadConfig = () => {
        log.info(className, 'MSG005', "loading system config")
        sessionContext.get<ISystemDTO>('/form/system')
            .then((response) => {
                const config: ISystemDTO = {...response.data} as ISystemDTO;

                if (isDefined(config)) {
                    // injectExtraOptions(config);
                    log.info(className, 'MSG004', "Loaded data", config)
                    createMaps(config);
                    checkHasMobileFooter(config);
                    systemConfigSubject.next(config)
                    systemLoadedSubject.next(true);
                }
            })
            .catch(error => {
                log.info(className, 'ERR001', "Error loading system config", error)
                sessionContext.clearSession()
            })
    }

    RunOnceEffect(() => {
        log.info(className, 'MSG001', '-- SystemProvider Init --')
        sessionContext.watchLogin()?.subscribe((user: GuslUser | null) => {
            if (isDefined(user)) {
                log.info(className, 'MSG001', "User loggedIn", user)
                loadConfig()
                loadCss()
            }
        });
    });


    const getSystemConfig = (): ISystemDTO => {
        return systemConfigSubject.getValue()
    }

    const getMenuConfig = (code: string): IMenuDTO | undefined => {
        let value = menuMap.get(code.toLowerCase());
        if (!value) {
            log.warn(className, 'MSG002', 'Failed to find menu code for [' + code + ']');
        }
        return value;
    }
    const getChildMenuConfig = (parentCode: string, childCode: string): IMenuDTO | undefined => {
        const menuConfig = getMenuConfig(parentCode);
        let found: IMenuDTO | undefined
        (menuConfig?.entityGroup?.menuItems || []).filter((menuItem: IMenuDTO) => menuItem.code === childCode).forEach((menuItem: IMenuDTO) => found = menuItem)
        return found;
    }


    const getMenuFromToolbarConfig = (code: string): IMenuDTO | undefined => {
        let value = toolbarMenuMap.get(code.toLowerCase());
        if (!value) {
            log.warn(className, 'MSG002', 'Failed to find toolbar code for [' + code + ']');
        }
        return value;
    }

    const getItemFromToolbarConfig = (code: string): ToolbarItemDTO | undefined => {
        let value = toolbarItemsMap.get(code.toLowerCase());
        if (!value) {
            log.warn(className, 'MSG002', 'Failed to find toolbar code for [' + code + ']');
        }
        return value;
    }


    const getMenuConfigFromPath = (path: string): IMenuDTO | undefined => {
        let strings = path.split("/");
        // console.log('looking for ',strings,strings.length,strings[strings.length - 1])
        if (strings.length >= 1) {
            return getMenuConfig(strings[strings.length - 1]);
        } else {
            log.warn(className, 'MSG003', 'Failed to find menu code from', path);
        }
    }
    const createMaps = (config: ISystemDTO) => {
        createMenuMap(config);
        createToolbarMap(config)
        createToolbarMenuMap(config)
        createWidgets(config)
    }

    const filterMenuTypes = (menuType: MenuType | undefined): boolean => {
        if (!menuType) {
            return false;
        }
        switch (menuType) {
            case MenuType.DATA_TABLE:
            case MenuType.ENTITY_GROUP:
            case MenuType.SINGLE_PAGE:
            case MenuType.ENTITY_GROUP_SINGLE_FORM:
                return true;
            default:
                console.log('ignoring menuType', menuType)
                return false;

        }
    }
    const createMenuMap = (config: ISystemDTO) => {
        if (config?.menuGroups) {
            config.menuGroups.forEach(menuGroup => {
                if (menuGroup?.menuItems) {
                    menuGroup.menuItems
                        .filter(item => filterMenuTypes(item.menuType))
                        .forEach(item => {
                            if (item.code) {
                                menuMap.set(item.code.toLowerCase(), item);
                            }
                        });
                }
            });
        }
    }

    const createToolbarMenuMap = (config: ISystemDTO) => {
        if (config?.toolbarMenus) {
            config.toolbarMenus.forEach(menuGroup => {
                if (menuGroup.code) {
                    toolbarMenuMap.set(menuGroup.code.toLowerCase(), menuGroup);
                }
                if (menuGroup.globalSearch) {
                    setGlobalSearchConfig(menuGroup.globalSearch)
                }
            });
        }
    }


    const createWidgets = (config: ISystemDTO) => {
        if (config?.widgets) {
            setWidgets(config.widgets)
        }
    }

    const createToolbarMap = (config: ISystemDTO) => {
        if (config?.toolbarItems) {
            config.toolbarItems.forEach(toolbar => {
                if (toolbar.code) {
                    toolbarItemsMap.set(toolbar.code.toLowerCase(), toolbar);
                }
            })
        }
    }

    const watchSystemLoaded = (): Observable<boolean> => {
        return systemLoadedSubject.asObservable();
    }

    const watchSystemNotifications = (): Observable<SystemNotificationDTO | undefined> => {
        return systemNotificationSubject.asObservable();
    }

    const toast = (notification: SystemNotificationDTO) => {
        if (notification) {
            systemNotificationSubject.next(notification)
        }
    }
    const hasMobileFooter = (): boolean => {
        return _hasMobileFooter
    }

    const getAllWidgets = (): WidgetDO[] => {
        return widgets
    }

    const getGlobalSearchConfig = (): GlobalSearchDTO | undefined => {
        return globalSearchConfig;
    }
    return (
        <SystemContext.Provider
            value={{
                getSystemConfig,
                getMenuConfig,
                getChildMenuConfig,
                getMenuConfigFromPath,
                watchSystemLoaded,
                watchSystemNotifications,
                toast,
                getMenuFromToolbarConfig,
                getItemFromToolbarConfig,
                hasMobileFooter,
                getAllWidgets,
                getGlobalSearchConfig
            }}>
            {children}
        </SystemContext.Provider>
    )
}

/*
var s = document.createElement("script");
                s.type = 'text/javascript';
                s.src = scripts[i].getAttribute("data-src");
                document.body.appendChild(s);
 */
