import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../../maintain-table/bootstrap/Icon";
import { TickerDO } from "../../../types";
import ChangePerc from "../../ChangePerc";
import { NewTickerDO } from "../../types";
import MGMutation from "../../../../../../providers/session/MGMutation";
import { SessionContext } from "../../../../../../providers/session/SessionContext";
import { ElementWithTooltip } from "../../../../element-with/element-with-tooltip/ElementWithTooltip";

export interface TickerSearchTableRowDO {
  ticker: NewTickerDO;
  performPreOrderQuery: Function;
  setWLQueryRefresher: Function;
  currentlySelectedFavorites: NewTickerDO[];

}

export default function EquitiesTableRow({
                                           ticker,
                                           performPreOrderQuery,
                                           setWLQueryRefresher,
                                           currentlySelectedFavorites

                                         }: TickerSearchTableRowDO) {

  const [className] = useState("TickerSearchTableRow-" + new Date().getTime());
  const sessionContext = useContext(SessionContext);

  const [isError, setIsError] = useState<boolean>(false);

  const [isFavorite, setIsFavorite] = useState<boolean>(currentlySelectedFavorites.some(favorite => favorite.name === ticker.name));
  useEffect(() => {
    setIsFavorite(currentlySelectedFavorites.some(favorite => favorite.name === ticker.name));
  }, [currentlySelectedFavorites, ticker]);

  async function removeFromWatchListHandler(ticker: NewTickerDO) {

    const favToRemove = currentlySelectedFavorites.find(favorite => favorite.name === ticker.name);
    if (favToRemove) {
      setIsError(false);
      await MGMutation({
        // @ts-ignore
        sessionContext,
        endpoint: `/watchlist/${favToRemove.id}/delete`,
        queryParams: {}
      }).then(response => {
        setWLQueryRefresher(new Date().getTime());
      }).catch(e => {
        console.log(e);
        setIsError(true);
      });
    }

  }

  async function addToWatchListHandler(ticker: NewTickerDO) {
    const realtickMapper: TickerDO = {
      symbol: ticker.symbol,
      displayTicker: ticker.displayTicker,
      name: ticker.name,
      id: ticker.id
    };
    setIsError(false);
    await MGMutation({
      // @ts-ignore
      sessionContext,
      endpoint: "/watchlist/insert",
      queryParams: { realtickMapper }
    }).then(response => {
      setWLQueryRefresher(new Date().getTime());
    }).catch(e => {
      console.log(e);
      setIsError(true);
    });
  }


  return (
    <tr className={"xs-small"}>
      <td onClick={() => {
        (isFavorite) ? removeFromWatchListHandler(ticker) : addToWatchListHandler(ticker);
      }}>
        <ElementWithTooltip
          element={
            <div><Icon icon={"fa-solid fa-star"}
                       extraClass={(isFavorite) ? "invalid" : ""} />
            </div>}
          tooltip={
            <span
              className={"xs-small"}>
              {isError ? <span className={"invalid"}>Something went wrong</span> : isFavorite ? "Remove from watchlist" : "Add to watchlist"}</span>
          } />


      </td>
      <td role={"button"} onClick={() => {
        performPreOrderQuery();
      }}>{ticker.displayTicker}</td>
      <td role={"button"} onClick={() => {
        performPreOrderQuery();
      }}>{ticker.name}</td>
      <td role={"button"} onClick={() => {
        performPreOrderQuery();
      }}>
        <ChangePerc value={ticker.change ?? 0} multiplyBy100 />
      </td>
    </tr>
  );
}

