import React, { useState } from "react";
import { InlineTableInputProps } from "../types";

export default function DateEditInput({
                                        rowId,
                                        idx,
                                        fld,
                                        inputRefs,
                                        inlineFieldEditHandler,
                                        setFocus,
                                        defaultValue
                                      }: InlineTableInputProps) {

  const [className] = useState("DateEditInput-" + new Date().getTime());

  return (
    <input key={"ff-det_" + rowId + "_" + idx + "---" + fld.name}
           ref={(el) => (inputRefs.current[idx] = el!)}
           id={"ff-det_" + rowId + "_" + idx + "---" + fld.name}
           style={{ color: "black" }}
           onBlur={(e) => {
             inlineFieldEditHandler(e, fld);
           }}
           onMouseLeave={(e) => {
             inlineFieldEditHandler(e, fld);
           }}
           onClick={() => {
             setFocus(idx);
           }}
      /*@ts-ignore*/
           defaultValue={defaultValue as InlineTableInputProps["defaultValue"]}
           type={fld.type === "date" ? "date" : "datetime-local"}
    />
  );
}

