import {createSlice, PayloadAction} from '@reduxjs/toolkit'


// -------------- actions
export interface TableRefreshInitPayload {
    code: string;
    openModalActionId: string;
    openModalRowId: string;
    openInlineActionId: string;
    openInlineRowId: string;
    openExpandedRowId: string;
}


export interface OpenModalActionIdPayload {
    code: string;
    openModalActionId: string;
}

export interface OpenModalRowIdPayload {
    code: string;
    openModalRowId: string;
}

export interface OpenInlineActionIdPayload {
    code: string;
    openInlineActionId: string;
}

export interface OpenInlineRowIdPayload {
    code: string;
    openInlineRowId: string;
}

export interface OpenExpandedRowIdPayload {
    code: string;
    openExpandedRowId: string;
}

export interface CleanUpPayload {
    code: string;
}


// -------------- states
export interface TableRefreshState extends TableRefreshInitPayload {
}

interface MasterTableRefreshState {
    [id: string]: TableRefreshState
}


const initialState: MasterTableRefreshState = {}


// -------------- utils
const loadInitValues = (values: TableRefreshInitPayload): TableRefreshState => {
    return {
        code: values.code,
        openModalActionId: values.openModalActionId,
        openModalRowId: values.openModalRowId,
        openInlineActionId: values.openInlineActionId,
        openInlineRowId: values.openInlineRowId,
        openExpandedRowId: values.openExpandedRowId,
    }

}


export const createDefault = (code: string): TableRefreshState => {
    return {
        code: code,
        openModalActionId: "",
        openModalRowId: "",
        openInlineActionId: "",
        openInlineRowId: "",
        openExpandedRowId: "",
    }
}

const getState = (state: MasterTableRefreshState, code: string): TableRefreshState => {
    let entry: TableRefreshState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry;
}

// -------------- config
export const guslTableRefreshSlice = createSlice({
    name: 'guslTableRefreshSlice',
    initialState,
    reducers: {
        initTableRefresh(state, action: PayloadAction<TableRefreshInitPayload>) {
          //  state[action.payload.code] = loadInitValues(action.payload)
            const entry: TableRefreshState = getState(state, action.payload.code)
             loadInitValues(action.payload)
            state[action.payload.code] = entry
        },

        tableRefreshCleanUp(state, action: PayloadAction<CleanUpPayload>) {
            delete state[action.payload.code]
        },
        setOpenModalActionId(state, action: PayloadAction<OpenModalActionIdPayload>) {
            const entry: TableRefreshState = getState(state, action.payload.code)
            entry.openModalActionId = action.payload.openModalActionId;
        },
        setOpenModalRowId(state, action: PayloadAction<OpenModalRowIdPayload>) {
            const entry: TableRefreshState = getState(state, action.payload.code)
            entry.openModalRowId = action.payload.openModalRowId;

        },
        setOpenInlineActionId(state, action: PayloadAction<OpenInlineActionIdPayload>) {
            const entry: TableRefreshState = getState(state, action.payload.code)
            entry.openInlineActionId = action.payload.openInlineActionId;

        },
        setOpenInlineRowId(state, action: PayloadAction<OpenInlineRowIdPayload>) {
            const entry: TableRefreshState = getState(state, action.payload.code)
            entry.openInlineRowId = action.payload.openInlineRowId;

        },
        setOpenExpandedRowId(state, action: PayloadAction<OpenExpandedRowIdPayload>) {
            const entry: TableRefreshState = getState(state, action.payload.code)
            entry.openExpandedRowId = action.payload.openExpandedRowId;
        },

    }
})

export const {
    initTableRefresh,
    tableRefreshCleanUp,
    setOpenModalRowId,
    setOpenModalActionId,
    setOpenExpandedRowId,
    setOpenInlineActionId,
    setOpenInlineRowId
} = guslTableRefreshSlice.actions

export default guslTableRefreshSlice.reducer
