import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../../../app/hooks';
import {SystemContext} from '../../../../providers/system/SystemContext';
import MaintainTable from '../../../common/maintain-table/MaintainTable';
import {IMenuDTO} from '../../../types';
import ArticleModal from '../article-modal/ArticleModal';
import {RISK_ARB} from '../navigation/SecondaryOttoNav';
import {OTTO_HOME} from '../OttoEvents';
import ottoService from '../ottoService';
import {OttoBoxStyled, OttoCol12, OttoCol3, OttoCol6, OttoRow} from '../styled';
import GeneralArticles from './general/GeneralArticles';
import {OttoTableState} from './ottoTableSlice';
import PopularArticles from './popular/PopularArticles';
import RecommendedArticles from './recommended/RecommendedArticles';

import {OttoTableContainerStyled, OttoTableLeftContainerStyled, OttoTableRightContainerStyled} from './styled';

interface OttoTableProperties {
    hasLeftPanel?: boolean
}

export default function OttoTable({hasLeftPanel = true}: OttoTableProperties) {
    const systemContext = useContext(SystemContext);
    const [menuItem, setMenuItem] = useState<IMenuDTO | undefined>(undefined);

    const params = useParams()
    const tabParam = params?.tab ? decodeURIComponent(params?.tab) : undefined;
    // vvv one of life's little mysteries
    const articleId = params?.dealId ? decodeURIComponent(params?.dealId) : undefined;

    // console.log(`tabParam: ${tabParam} articleId: ${articleId} `)

    const _ottoTableSlice: OttoTableState = useAppSelector(state => state.ottoTableSlice[tabParam || RISK_ARB]);

    useEffect(() => setMenuItem(ottoService.getMenuConfigForTab(systemContext, tabParam)))
    const [contentsHeight, setContentsHeight] = useState('250px');
    const [bottomHeight, setBottomHeight] = useState('250px');
    const contentsElement = useRef(null);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (contentsElement) {
                    // @ts-ignore
                    const rect = contentsElement?.current?.getBoundingClientRect();
                    if (rect) {
                        let h = window.innerHeight - rect.top - 120
                        setContentsHeight(Math.round(h * 0.33) + 'px')
                        setBottomHeight(Math.round(h * 0.73) + 'px')
                    }
                }
            }, 100);
        });
    }, [(_ottoTableSlice?.refreshCounter || 0)])


    const renderLeftPanel = (): React.ReactElement => {
        return (
            <OttoRow>
                <OttoCol6>
                    <OttoBoxStyled>
                        <RecommendedArticles code={'recommended'} contentsHeight={contentsHeight}
                                             dealType={tabParam || RISK_ARB}/>
                    </OttoBoxStyled>
                </OttoCol6>
                <OttoCol3>
                    <OttoBoxStyled>
                        <GeneralArticles code={'general'} contentsHeight={contentsHeight}
                                         dealType={tabParam || RISK_ARB}/>
                    </OttoBoxStyled>
                </OttoCol3>
                <OttoCol3>
                    <OttoBoxStyled>
                        <PopularArticles code={'popular'} contentsHeight={contentsHeight}
                                         dealType={tabParam || RISK_ARB}/>
                    </OttoBoxStyled>
                </OttoCol3>
            </OttoRow>
        )
    }


    return (
        <OttoTableContainerStyled>
            <OttoBoxStyled>
                {hasLeftPanel && <OttoRow>
                    <OttoCol12>
                        <OttoTableLeftContainerStyled ref={contentsElement}
                                                      key={'otto-table-left-' + (_ottoTableSlice?.refreshCounter || 0)}
                                                      id={'otto-table-left-' + (_ottoTableSlice?.refreshCounter || 0)}>
                            {renderLeftPanel()}
                        </OttoTableLeftContainerStyled>
                    </OttoCol12>
                </OttoRow>}
                <OttoRow>
                    <OttoCol12>
                        <OttoTableRightContainerStyled height={hasLeftPanel ? bottomHeight : undefined}>
                            <OttoBoxStyled>
                                {menuItem &&
                                    <MaintainTable menuItem={menuItem} hideHeaderPanel={true} isTabChild={false}/>}
                            </OttoBoxStyled>
                        </OttoTableRightContainerStyled>
                    </OttoCol12>
                </OttoRow>
            </OttoBoxStyled>
            {_ottoTableSlice?.currentArticle && articleId &&
                <ArticleModal articleId={articleId || ''} returnUrl={OTTO_HOME + '/' + tabParam}/>}
        </OttoTableContainerStyled>
    )
}

