import React from "react";
import Money from '../../../components/common/buy-sell/money';
import {ExtraFieldProperties} from '../../../components/common/money/types';
import {
    DownArrowStyled,
    LeftArrowStyled,
    NoMovementWrapperStyled,
    RightArrowStyled,
    UpArrowStyled
} from "../../../components/common/position/styled";
import {CurrencyDTO} from '../../../components/types';
import {convertToMoney} from '../../../utils/money';
import {isString} from "../../../utils/TypeCheckers";

interface BlastFieldProperties {
    fieldType: string | undefined,
    value: any | undefined,
    extraFieldProperties: ExtraFieldProperties | undefined,
    currencyMap: { [id: string]: CurrencyDTO }
}

export const BlastField = ({
                               fieldType,
                               value,
                               extraFieldProperties,
                               currencyMap
                           }: BlastFieldProperties): React.ReactElement => {
    const emptyElement = <div></div>

    const renderPosition = (): React.ReactElement => {
        if (value > 0) {
            return <UpArrowStyled/>
        } else if (value < 0) {
            return <DownArrowStyled/>
        } else {
            return <NoMovementWrapperStyled><LeftArrowStyled/><RightArrowStyled/></NoMovementWrapperStyled>
        }
    }

    const renderNumber = (): React.ReactElement => {
        if (!isString(value)) {
            if (value >= 0) {
                const css = {
                    color: 'green'
                }
                return <div style={css}>{value}</div>
            } else {
                const css = {
                    color: 'red'
                }
                return <div style={css}>{value}</div>
            }
        }
        return emptyElement

    }

    if (fieldType === 'money') {
        const money: any = convertToMoney(currencyMap, value)
        if (money?.value) {
            return <Money {...extraFieldProperties} amount={money}/>
        } else {
            return <></>
        }
    }

    if (fieldType === 'position') {
        return renderPosition()
    }

    if (fieldType === 'number') {
        return renderNumber()
    }


    return emptyElement
}
