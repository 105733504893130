import {AxiosResponse} from 'axios';
import {UserAgentApplication} from 'msal';
import {Observable} from 'rxjs'
import {EndpointDTO, QueryParamsDTO} from '../../components/types';
import {QrCodeResponseDTO} from '../../pages/login/types';

export type JWTTokens = {
    sessionToken?: string
    userToken?: string
}

export type Session = {
    jwtTokens?: JWTTokens;
    ssoToken?: string | null;
    isSsoToken: () => boolean;
    msalToken?: string | null;
    isMsalToken: () => boolean
}

export interface SessionContextProps {
    clearSession: () => void
    hasNoSession: () => boolean
    loginWithGoogleSsoToken: (id_token: string) => Promise<SignInResponseDTO>
    renewSession: () => Promise<SignInResponseDTO>
    loginWithMsalSsoToken: (name: string, username: string, id_token: string) => Promise<SignInResponseDTO>
    getJwtSession: () => JWTTokens | undefined;
    setJwtSession: (tokens: JWTTokens) => void;
    getGoogleToken: () => string;
    setGoogleToken: (token: string) => void;
    isGoogle: () => boolean;
    isJwt: () => boolean;
    signIn: (username: string, password: string, totp?: string) => Promise<SignInResponseDTO>;
    getQrCode: (username: string) => Promise<QrCodeResponseDTO>;
    watchLogin: () => Observable<GuslUser | null>;
    watchTandCUpgradeRequired: () => Observable<boolean>;
    watchLogout: () => Observable<number>;
    getLoggedInUser: () => User;
    watchSystemReady: () => Observable<boolean>;
    watchSessionStatus: () => Observable<SessionStatus>;

    watchTimezone: () => Observable<string>;
    get: <Response> (url: string, controller?: AbortController) => Promise<AxiosResponse<Response>>;
    post: <Request, Response>(url: string, body?: Request, controller?: AbortController) => Promise<AxiosResponse<Response>>;
    download: <Request, Response>(url: string, body?: Request, controller?: AbortController) => Promise<AxiosResponse<Response>>;
    upload: <Request, Response>(url: string, body?: Request, controller?: AbortController) => Promise<AxiosResponse<Response>>;
    put: <Request, Response>(url: string, body?: Request, controller?: AbortController) => Promise<AxiosResponse<Response>>;
    sendRequest: <Request, Response>(endpoint: EndpointDTO, body?: Request, controller?: AbortController) => Promise<AxiosResponse<Response>>;
    signOut: () => Promise<SignOutResponseDTO>;
    setOriginalPath: (pathname: string) => void;
    getOriginalPath: () => string;
    getMsalInstance: () => UserAgentApplication | undefined;

    updateTheme: (themeId: string, lastUpdated?: string) => void;

    registerRefreshRate: (refreshRate: number) => void;

    unregisterRefreshRate: (refreshRate: number) => void;

    watchRefreshRate: () => Observable<number>;

    setQueryParams: (query: string | null | undefined) => void;

    getQueryParams: () => QueryParamsDTO | undefined;
    hasPowerOfAttorney: () => boolean;

    updateUser: (user: GuslUser | undefined) => void;

    getHomePage: () => string | undefined;
    setTimezone: (timezone: string) => void;
}

export interface JwtTokenSignInDTO {
    userToken: string;
    sessionToken: string;
    timezone?: string;

}


export interface SignInRequestDTO {
    token?: string;
    username?: string;
    password?: string;
    totp?: string;
    msalUsername?: string;
    msalToken?: string;
    msalName?: string;
    timezone?: string;
}

export interface SignInResponseDTO {
    username: string;
    userToken: string;
    sessionToken: string;
    avatar?: string;
    theme?: string;
    nickname?: string;
    passwordChangeRequired?: boolean;
    properties?: {
        [key: string]: string | number;
    };
    themeLastUpdated?: string;
    hasPowerOfAttorney?: boolean;

    homePage?: string;

    timezonePreference?: string;
    acceptTandCRequired: boolean | false;
}

export interface SignOutResponseDTO {
    success: boolean;
}

export interface User {
    id: string;
    email: string;
    avatar?: string;
    status?: string;
}

export interface CohortDTO {
    id: string;
    name: string;
    logo: string;

}

export interface UserCohortsDTO {
    allowedCohorts?: CohortDTO[] | undefined
    currentCohort?: CohortDTO | undefined

}

export interface GuslUser {
    googleUser?: any; // gapi.auth2.GoogleUser,
    user?: User,
    id: string,
    username: string,
    nickname?: string,
    thirdPartyId?: string,
    // password: string,
    // totp: string,
    userToken?: string,
    sessionToken?: string,
    avatar?: string;
    theme?: string;
    accountType?: string,
    properties?: {
        [key: string]: string | number;
    };

    userCohorts?: UserCohortsDTO,
    acceptTandCRequired?: boolean | false;
    status?: string;

}

export enum SessionStatus {
    LOADING = "LOADING",
    REQUESTING = "REQUESTING",
    VALID = "VALID",
    INVALID = "INVALID"
}

