import styled from 'styled-components';

export const ItemStyled = styled.div.attrs((props) => ({
}))`
    border: solid 1px rgb(101 245 125 / 20%);
    border-radius: 5px;
    padding: 5px;
    background-color:  rgb(255 255 255 / 10%);
    width: max-content;
    color: #fff;
    &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
        cursor: pointer;
    }    
`
