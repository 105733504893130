import React, {useState} from 'react';
import {log} from '../../../../services/LogService';
import {areOrderByActionsMediaTypeSensitive, matchMediaTypeWithOrderByAction} from '../../../../utils/Utils';
import {FieldConfigDTO, MediaType, OrderByActionDO, OrderByDTO, QueryParamsDTO} from '../../../types';
import {maintainTableService} from '../../maintain-table/MaintainTableService';
import {OrderByActionStyled, OrderByActionWrapperStyled} from './styled';

interface OrderByActionProperties {
    orderByActions: OrderByActionDO[],
    isMobile: boolean;
    mediaType: MediaType;
    lastQueryParams: QueryParamsDTO | undefined;
    onQueryParamsChange: (orderBys: OrderByDTO[]) => void;
}

export const OrderByActions = ({
                                   orderByActions, isMobile, mediaType, lastQueryParams, onQueryParamsChange
                               }: OrderByActionProperties): React.ReactElement<OrderByActionProperties> => {

    const [className] = useState('OrderByActions-' + new Date().getTime());

    const actionMediaTypeSensitive = areOrderByActionsMediaTypeSensitive(orderByActions)

    const isOrderedBy = (action: OrderByActionDO): [boolean, string | undefined] => {
        let orderedBy = false;
        let order: string | undefined

        const orderBys = lastQueryParams?.orderBys || [];
        try {
            if (orderBys?.length) {
                orderedBy = orderBys[0].field === action.field
                order = orderBys[0].order
            }
        } catch (e) {
            log.error(className, 'MSG-E-001', 'orderBy detection error')
        }
        return [orderedBy, order];
    }

    const onOrderBy = (action: OrderByActionDO) => {
        const fld: FieldConfigDTO = {
            name: action.field,
            label: '',
            type: 'text'
        }

        const orderBys = maintainTableService.inCurrentOrderBy(fld, lastQueryParams)
            ? maintainTableService.toggleOrderBy(fld, lastQueryParams)
            : maintainTableService.getOrderByWithOrder(fld, action.order)
        if (lastQueryParams) {
            onQueryParamsChange(orderBys)
        }
    }

    return (
        <OrderByActionWrapperStyled>
            {orderByActions
                .filter(action => matchMediaTypeWithOrderByAction(action, mediaType, actionMediaTypeSensitive))
                .map((action: OrderByActionDO, idx) => {
                        const [isOrdered, currentOrder] = isOrderedBy(action)
                        return <OrderByActionStyled
                            className={!currentOrder || currentOrder === 'ASC' ? action.ascIcon : action.descIcon}
                            isMobile={isMobile}
                            active={isOrdered}
                            onClick={() => onOrderBy(action)} key={'ord_' + idx}/>
                    }
                )
            }
        </OrderByActionWrapperStyled>
    )
}
