// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ActionConfigDTO, ConditionalRowDetailsDTO, FieldConfigDTO, TableRowDTO} from "../../../types";


// -------------- actions
export interface TableRowInitPayload {
    rowId: string,
    row: TableRowDTO,
    fields: FieldConfigDTO[],
    menuItem: any,
    performTableRefresh: () => void,
    conditionalRowDetails?: ConditionalRowDetailsDTO | undefined
}

export interface UpdateCurrentActionPayload {
    rowId: string
    currentAction: ActionConfigDTO | undefined
}

export interface ToggleRowActionItemPayload {
    rowId: string
    actionItem: ActionConfigDTO | undefined,
    showType: ShowActionType
}

export interface ProcessingActionItemPayload {
    rowId: string
    processing: boolean
}

export interface RowCleanUpPayload {
    rowId: string
}

export interface CloseActionPayload {
    rowId: string
}


// -------------- states
export interface MasterTableRowState {
    [id: string]: TableRowState
}

export interface TableRowState {
    rowId: string;
    row: TableRowDTO,
    allFields: FieldConfigDTO[],
    showFields: FieldConfigDTO[],
    menuItem: any,
    performTableRefresh: () => void,
    conditionalRowDetails?: ConditionalRowDetailsDTO | undefined,
    expandableRow: boolean,
    showActionModal: ShowActionType
    currentAction: ActionConfigDTO | undefined

    processingAction: boolean,


}

const initialState: MasterTableRowState = {}

export const enum ShowActionType {
    NO_ACTION,
    MODAL,
    IN_LINE,
    NO_MODAL
}

// -------------- utils
const loadInitValues = (values: TableRowInitPayload): TableRowState => {
    const showFields: FieldConfigDTO[] = values.fields.filter(fld => fld.displayInTable)
    return {
        rowId: values.rowId,
        row: values.row,
        allFields: values.fields,
        showFields: showFields,
        menuItem: values.menuItem,
        performTableRefresh: values.performTableRefresh,
        conditionalRowDetails: values.conditionalRowDetails,
        expandableRow: (values.conditionalRowDetails && (values.conditionalRowDetails.expandable || values.conditionalRowDetails.expand)) || false,
        showActionModal: ShowActionType.NO_ACTION,
        currentAction: undefined,
        processingAction: false
    }

}

export const createDefault = (rowId: string): TableRowState => {
    return {
        rowId: rowId,
        row: {id: rowId},
        allFields: [],
        showFields: [],
        menuItem: undefined,
        performTableRefresh: () => {
        },
        conditionalRowDetails: undefined,
        expandableRow: false,
        showActionModal: ShowActionType.NO_ACTION,
        currentAction: undefined,
        processingAction: false
    }
}

const cloneWithNoAction = (from: TableRowState): TableRowState => {
    const to = Object.assign({}, from)
    to.showActionModal = ShowActionType.NO_ACTION;
    to.currentAction = undefined;
    return to
}
const getState = (state: MasterTableRowState, rowId: string): TableRowState => {
    let entry: TableRowState = state[rowId]
    if (!entry) {
        entry = createDefault(rowId)
    }
    return entry;
}

// -------------- config
export const tableRowSlice = createSlice({
    name: 'tableRowSlice',
    initialState,
    reducers: {
        initRow(state, action: PayloadAction<TableRowInitPayload>) {
            state[action.payload.rowId] = loadInitValues(action.payload)
        },
        updateCurrentAction(state, action: PayloadAction<UpdateCurrentActionPayload>) {
            const entry: TableRowState = getState(state, action.payload.rowId)
            entry.currentAction = action.payload.currentAction;
            entry.showActionModal = ShowActionType.IN_LINE;
            // entry.processingAction = isDefined(action.payload.currentAction);
            // console.log('updateCurrentAction =>',entry.processingAction)
            state[action.payload.rowId] = entry
        },
        toggleActionItem(state, action: PayloadAction<ToggleRowActionItemPayload>) {
            const entry: TableRowState = getState(state, action.payload.rowId)
            entry.currentAction = action.payload.actionItem;
            entry.showActionModal = action.payload.showType;
            // if (!action.payload.actionItem) {
            //     entry.processingAction = false;
            // }
            // console.log('toggleActionItem =>', action.payload.showType, action.payload.actionItem, entry.processingAction)
            state[action.payload.rowId] = entry
        },
        processingActionItem(state, action: PayloadAction<ProcessingActionItemPayload>) {
            const entry: TableRowState = getState(state, action.payload.rowId)
            entry.processingAction = action.payload.processing
            state[action.payload.rowId] = entry
        },
        cleanUp(state, action: PayloadAction<RowCleanUpPayload>) {
            delete state[action.payload.rowId]
        },
        closeAction(state, action: PayloadAction<CloseActionPayload>) {
            const entry: TableRowState = getState(state, action.payload.rowId)
            state[action.payload.rowId] = cloneWithNoAction(entry)
        }

    }
})

export const {
    initRow,
    updateCurrentAction,
    toggleActionItem,
    cleanUp,
    closeAction,
    processingActionItem
} = tableRowSlice.actions

export default tableRowSlice.reducer
