import {Http, HttpResponse} from '@capacitor-community/http';
import {HttpHeaders, HttpOptions} from '@capacitor-community/http/dist/esm/definitions';
import {HttpResponseType} from '@capacitor/core';
import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {AuthError, AuthResponse} from 'msal';
import {NavigateFunction} from 'react-router-dom';
import {BehaviorSubject, Observable} from 'rxjs';
import {BROWSER_TIMEZONE} from '../../components/common/timezone/TimezoneField';
import {EndpointDTO} from '../../components/types';
import {QrCodeRequestDTO, QrCodeResponseDTO} from '../../pages/login/types';
import {log} from '../../services/LogService';
import {isDefined, notBlank} from '../../utils/TypeCheckers';
import {BlastProps} from '../blast/BlastContext';
import {environmentService} from '../environment/EnvironmentService';
import {AuthType, EnvironmentContextProps} from '../environment/types';
import {BaseTheme} from '../theme/GuslThemeProvider';
import {GuslThemeProperties} from '../theme/types';
import {guslStorage} from './GuslStorage';
import {
    EXCLUDE_HEADERS_FOR,
    GOOGLE_TOKEN_HEADER,
    GOOGLE_X_TOKEN_HEADER,
    GUSL_USER_TOKEN_HEADER,
    MSAL_TOKEN_HEADER,
    SESSION_TOKEN_HEADER,
    TOTP_SESSION_TOKEN_HEADER,
    TOTP_USER_TOKEN_HEADER
} from './SessionProvider';
import {GuslUser, JWTTokens, JwtTokenSignInDTO, Session, SessionStatus, SignInRequestDTO, SignInResponseDTO, SignOutResponseDTO} from './types';

export const axiosClientDownload = axios.create({
    timeout: 20000,
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json, application/vnd.ms-excel, application/octet-stream, text/plain, application/pdf'
    },
})

class SessionService {

    private timezoneSubject = new BehaviorSubject<string>(BROWSER_TIMEZONE)
    private tandCUpgradeRequiredSubject = new BehaviorSubject<boolean>(false)
    private powerOfAttorney: boolean = false;
    private homePage: string | undefined = undefined;

    private environmentContext: EnvironmentContextProps | undefined;
    private navigate: NavigateFunction | undefined;
    private blastContext: BlastProps | undefined;
    private guslThemeContext: GuslThemeProperties | undefined;

    private loginSubject = new BehaviorSubject<GuslUser | null>(null)
    private logoutSubject = new BehaviorSubject<number>(0)

    private systemReadySubject = new BehaviorSubject<boolean>(false)
    private sessionStatusSubject = new BehaviorSubject<SessionStatus>(SessionStatus.LOADING)


    public initialise(environmentContext: EnvironmentContextProps, navigate: NavigateFunction, blastContext: BlastProps, guslThemeContext: GuslThemeProperties) {
        this.environmentContext = environmentContext
        this.navigate = navigate
        this.blastContext = blastContext
        this.guslThemeContext = guslThemeContext

        axiosClientDownload.interceptors.request.use(
            config => {
                const excludeHeaders = (config: AxiosRequestConfig) => {
                    return isDefined(EXCLUDE_HEADERS_FOR.find(hdr => config.url?.endsWith(hdr)))
                }

                if (excludeHeaders(config)) {
                    return config
                }
                // @ts-ignore
                return sessionService.addHeaders(config);
            },
            error => {
                return Promise.reject(error)
            },
        )


    }

    public getServerSideUrl = (url: string): string => {
        return `${environmentService.getEnvironment()?.apiBase}${url}`
    }

    public async download<Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> {
        log.info('sessionService', 'MSG001', 'API POST Request:' + url, body)
        return axiosClientDownload
            .post<Response>(
                `${environmentService.getEnvironment()?.apiBase}${url}`,
                body || null,
                controller ? {
                    signal: controller.signal,
                    responseType: 'arraybuffer'
                } : {},
            )
    }

    public async upload<Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> {
        // return performRequest('POST', url, body, controller, 'arraybuffer', true)
        // vvv customer file upload
        console.log('==========> upload request', body)
        return this.performRequest('POST', url, body, controller, 'json', true)
    }

    public async sendRequest<Request, Response>(endpoint: EndpointDTO, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> {
        if (endpoint.method.toLowerCase() === 'get') {
            return this.get<Request, Response>(endpoint.url, controller)
        } else if (endpoint.method.toLowerCase() === 'put') {
            return this.put<Request, Response>(endpoint.url, body, controller)
        } else {
            return this.post<Request, Response>(endpoint.url, body, controller)
        }
    }

    public addHeaders(config: HttpOptions): HttpOptions {
        this.addTokensHeaders(config.headers || {})
        return config;
    }

    public addTokensHeaders(headers: HttpHeaders): void {
        if (environmentService.getEnvironment()?.authType === AuthType.TIME_BASED_ONE_TIME_PASSWORD
            || environmentService.getEnvironment()?.authType === AuthType.USERNAME_PASSWORD) {
            // if (guslStorage.isJwt()) {
            const jwtSession = guslStorage.getJwtSession()
            if (jwtSession) {
                if (headers) {
                    if (environmentService.getEnvironment()?.authType === AuthType.TIME_BASED_ONE_TIME_PASSWORD) {
                        headers[TOTP_SESSION_TOKEN_HEADER] = jwtSession.sessionToken || ''
                        headers[TOTP_USER_TOKEN_HEADER] = jwtSession.userToken || ''
                    } else {
                        headers[SESSION_TOKEN_HEADER] = jwtSession.sessionToken || ''
                        headers[GUSL_USER_TOKEN_HEADER] = jwtSession.userToken || ''
                    }
                }
            }
        }
        if (environmentService.getEnvironment()?.authType === AuthType.GOOGLE_SSO) {
            // if (guslStorage.isGoogle()) {
            const googleToken = guslStorage.getGoogleToken()
            if (googleToken) {
                if (headers) {
                    headers[GOOGLE_TOKEN_HEADER] = googleToken || ''
                    headers[GOOGLE_X_TOKEN_HEADER] = googleToken || ''
                }
            }
        }
        if (environmentService.getEnvironment()?.authType === AuthType.MSAL) {
            // if (guslStorage.isMsal()) {
            const msalToken = guslStorage.getMsalToken()
            if (msalToken) {
                if (headers) {
                    headers[MSAL_TOKEN_HEADER] = msalToken || ''
                }
            }
        }

        // return config
    }

    public excludeHeaders(url: string) {
        return isDefined(EXCLUDE_HEADERS_FOR.find(hdr => url?.endsWith(hdr)))
    }

    public navigateTo(location: string) {
        if (this.navigate) {
            this.navigate(location)
        }
    }

    public async performRequest<Request, Response>(method: Method,
                                                   url: string,
                                                   body?: Request,
                                                   controller?: AbortController,
                                                   responseType?: HttpResponseType,
                                                   upload?: boolean,
    ): Promise<AxiosResponse<Response>> {
        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            log.info('sessionService', 'MSG001', 'API POST Request:' + url, body)

            const options: HttpOptions = {
                url: `${environmentService.getEnvironment()?.apiBase}${url}`,
                headers: {
                    'Content-Type': (upload ? 'multipart/form-data' : 'application/json'),
                    'accept': 'application/json, application/vnd.ms-excel, application/octet-stream, text/plain, application/pdf',
                    'Media-Type': this.environmentContext?.getCurrentMediaType() || '',
                    'Orientation': this.environmentContext?.getCurrentOrientation() || ''
                },
                method: method
            };
            if (body) {
                options.data = body
            }
            if (responseType) {
                options.responseType = responseType;
            }

            if (!this.excludeHeaders(url)) {
                sessionService.addHeaders(options)
            }

            const start = new Date().getTime()
            try {
                Http.request(options)
                    .then(async (response: HttpResponse) => {
                        // console.log('Request: ' + url + ' status: ' + response.status + ' duration: ' + (new Date().getTime() - start) + 'ms')
                        //  log.infoNoConsole(className, 'MSG010', 'Request: ' + url + ' status: ' + response.status + ' duration: ' + (new Date().getTime() - start) + 'ms')

                        if (response.status === 200) {
                            // we wrap response as axios - so we do not have whole scale changes
                            const axiosResponse: AxiosResponse<Response, Request> = {
                                data: response.data,
                                status: response.status,
                                statusText: 'OK',
                                headers: response.headers,
                                config: {
                                    url: options.url,
                                    method: method,
                                    baseURL: `${environmentService.getEnvironment()?.apiBase}`,
                                    headers: options.headers
                                },
                                request: body || null
                            }
                            resolve(axiosResponse)
                        } else if (response.status === 406) {
                            this.navigateTo('/restricted')
                        } else if (response.status === 401 || response.status === 403) {

                            if (!url.endsWith('token-login')) {
                                // console.log('-- 393 401 received - renewSession')
                                this.renewSession()
                                    .then(() => {
                                        this.performRequest<Request, Response>(method, url, body, controller, responseType, upload)
                                            .then((axiosResponse: AxiosResponse<Response, Request>) => {
                                                resolve(axiosResponse)
                                            })
                                            .catch((err: any) => {
                                                log.error('sessionService', 'ERR001', 'Create session failed', err)
                                                this.navigateTo('/login')
                                                return reject(err)
                                            })
                                    })
                                    .catch((err: any) => {
                                        log.error('sessionService', 'ERR001', 'Create session failed', err)
                                        this.navigateTo('/login')
                                        return reject(err)
                                    })
                            } else {
                                this.navigateTo('/login')
                                return reject('need to login')
                            }


                        } else if (response.status === 402) {
                            // check error code but go to location barred
                            reject('location barred')

                        } else {
                            reject(response.data);
                        }
                    }).catch(error => {
                    console.log('Request: ' + url + ' status: error  duration: ' + (new Date().getTime() - start) + 'ms', error)
                    reject(error);
                })
            } catch (err) {
                console.error('error', err)
                reject(err);
            }
        })

    }

    public getTimezone(): string | undefined {
        try {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (error) {
            console.log('error failed to get timezone')
            return undefined;
        }
    }


    public async post<Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> {
        return this.performRequest('POST', url, body, controller)
    }

    public async put<Request, Response>(url: string,
                                        body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> {
        return this.performRequest('PUT', url, body, controller)
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public async get<Request, Response>(url: string,
                                        controller?: AbortController): Promise<AxiosResponse<Response>> {
        return this.performRequest('GET', url, undefined, controller)
    }

    public async signInWithJwtToken(jwtToken: JWTTokens | undefined): Promise<SignInResponseDTO> {
        const request: JwtTokenSignInDTO = {
            userToken: jwtToken?.userToken || '',
            sessionToken: jwtToken?.sessionToken || '',
            timezone: this.getTimezone()
        }
        const response = await this.post<JwtTokenSignInDTO, SignInResponseDTO>('/security/v1/token-login', request)

        return {...response.data} as SignInResponseDTO
    }

    public async signInWithGoogleSsoToken(idToken: string): Promise<SignInResponseDTO> {
        const request: SignInRequestDTO = {token: idToken, timezone: this.getTimezone()}
        const response = await this.post<SignInRequestDTO, SignInResponseDTO>('/security/v1/token-login', request)
        log.debug('sessionService', 'MSG001', 'login', response)
        return {...response.data} as SignInResponseDTO
    }

    public msalTokenReceivedCallback(response: AuthResponse) {
        if (response.account?.userName) {
            guslStorage.setMsalUserName(response.account.userName);
        }
        if (response.account?.name) {
            guslStorage.setMsalName(response.account.name);
        }
        guslStorage.setMsalToken(response?.idToken?.rawIdToken || '')
    }

    public msalErrorReceivedCallback(authErr: AuthError, accountState: string) {
        console.error('GUSL001 - MSAL call back error', authErr, accountState)
        guslStorage.removeMsalName()
    }

    public async silentlySignInWithMsal() {
        console.log('silentlySignInWithMsal')
        return new Promise<boolean>((resolve, reject) => {
            if (this.environmentContext?.isMsalRedirect()) {
                this.environmentContext?.getMsalInstance()?.handleRedirectCallback(this.msalTokenReceivedCallback, this.msalErrorReceivedCallback)
            }

            if (this.environmentContext?.getMsalInstance() && (this.environmentContext?.getMsalInstance()?.getAccount() || guslStorage.getMsalName())) {
                const tokenRequest = {
                    scopes: environmentService.getEnvironment()?.msalScopes || [],
                    account: this.environmentContext?.getMsalInstance()?.getAccount() || guslStorage.getMsalName(),
                    loginHint: this.environmentContext?.getMsalInstance()?.getAccount()?.userName || guslStorage.getMsalUserName(),
                    sid: 'fockers2',
                    forceRefresh: true,
                    nonce: "5e216a1d-15ef-4c3e-9a14-f1b48762290e",
                    objectId: "2657d7a5-c96c-482b-ba55-534425ddf439"
                }
                // @ts-ignore
                this.environmentContext?.getMsalInstance().acquireTokenSilent(tokenRequest)
                    .then(response => {
                        guslStorage.setMsalToken(response.idToken.rawIdToken)
                        resolve(true)
                    })
                    .catch((err: any) => {
                        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                        // console.log('silentlySignInWithMsal error', err)
                        if (err.name === "InteractionRequiredAuthError") {
                            if (this.environmentContext?.isMsalRedirect()) {
                                resolve(false)
                            } else {
                                // @ts-ignore
                                this.environmentContext?.getMsalInstance().acquireTokenPopup(tokenRequest)
                                    .then(response => {
                                        guslStorage.setMsalToken(response.accessToken)
                                        resolve(true)
                                    })
                                    .catch((err: any) => {
                                        // handle error
                                        resolve(false)
                                    });
                            }
                            reject(err)
                        }
                    });
            } else {
                resolve(false)
            }
        });
    }

    public async signInWithMsalSsoToken(
        name: string | undefined | null,
        username: string | undefined | null,
        idToken: string | undefined | null
    ): Promise<SignInResponseDTO> {

        return new Promise((resolve, reject) => {
            const request: SignInRequestDTO = {
                msalName: name || '',
                msalUsername: username || '',
                msalToken: idToken || '',
                timezone: this.getTimezone()
            }
            this.post<SignInRequestDTO, SignInResponseDTO>('/security/v1/token-login', request)
                .then((response) => {
                    log.debug('sessionService', 'MSG001', 'login', response)
                    resolve({...response.data} as SignInResponseDTO)
                })
                .catch(error => {
                    console.error('-----------------> error signInWithMsalSsoToken', error)
                    log.error('sessionService', 'signInWithMsalSsoToken', error)
                    reject(error)
                })
        })
    }

    public convertToGuslUser(response: SignInResponseDTO): GuslUser {

        return {...response} as GuslUser
    }

    /**
     * User has logged in (or session is renewed), check that UI theme definition is the latest
     * @param themeId The theme Id
     * @param themeLastUpdated Date theme was last updated on server
     */
    public checkAndUpdateTheme(themeId: string | undefined, themeLastUpdated: string | undefined): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!themeId) {
                /**
                 * no themeId - not a lot we can do
                 */
                resolve(true)
                return
            }

            const storagePrefix: string | undefined = this.environmentContext?.getStoragePrefix()
            const uiThemeId: string | undefined = guslStorage.getThemeId();
            const serverDate = themeLastUpdated ? new Date(themeLastUpdated) : new Date()
            const uiThemeLastUpdated: Date | undefined = guslStorage.getThemeLastUpdated();

            // console.log('serverDate vs uiThemeLastUpdated', themeId, uiThemeId, serverDate, uiThemeLastUpdated, (uiThemeLastUpdated ? (serverDate > uiThemeLastUpdated) : 'no date'))
            if (themeId !== uiThemeId || !themeLastUpdated || !uiThemeLastUpdated || serverDate > uiThemeLastUpdated) {
                this.get<Request, BaseTheme>(`/profile/${themeId}/theme`).then((response: AxiosResponse<BaseTheme>) => {
                    console.log('updating theme ', themeId, serverDate, response)
                    guslStorage.saveThemeId(themeId)
                    guslStorage.saveTheme(response.data);
                    guslStorage.saveThemeLastUpdated(serverDate)
                    this.guslThemeContext?.setTheme(themeId, response.data, this.environmentContext?.isMobileDevice() || false)
                })
                    .then(() => resolve(true))
                    .catch(error => reject(error))
            } else {
                // console.log('Theme - have latest doing nothing')
                this.guslThemeContext?.setTheme(storagePrefix, guslStorage.getTheme(), this.environmentContext?.isMobileDevice() || false)
                resolve(true)
            }
        });
    }

    public signInWithMsalSso() {
        const onError = (reject: any, error: any) => {
            log.error('sessionService', 'ERR001', 'renewSession:', error)
            this.sessionStatusSubject.next(SessionStatus.INVALID)
            this.systemReadySubject.next(true)
            guslStorage.setMsalToken(undefined)
            reject(error)
        }
        return new Promise<boolean>((resolve, reject) => {

            if (notBlank(guslStorage.getMsalToken())) {
                this.signInWithMsalSsoToken(guslStorage.getMsalName(), guslStorage.getMsalUserName(), guslStorage.getMsalToken())
                    .then((response: SignInResponseDTO) => {
                        this.checkAndUpdateTheme(response.theme, response.themeLastUpdated)
                            .then((success: boolean) => {
                                if (success) {
                                    guslStorage.setMsalToken(response.sessionToken)
                                    this.homePage = response?.homePage
                                    this.blastContext?.login(response.sessionToken)
                                    const guslUser = this.convertToGuslUser(response);
                                    this.loginSubject.next(guslUser)
                                    log.setUser(guslUser)
                                    this.sessionStatusSubject.next(SessionStatus.VALID)
                                    this.systemReadySubject.next(true)
                                    resolve(true)
                                }
                            })
                            .catch(error => {
                                onError(reject, error)
                            })
                    })
                    .catch(error => {
                        onError(reject, error)
                    })
            } else {
                this.sessionStatusSubject.next(SessionStatus.INVALID)
                this.systemReadySubject.next(true)
                reject('no token')
            }
        });
    }

    public performSilentSignInWithMsal(resolve: any, reject: any) {
        this.silentlySignInWithMsal()
            .then((response: any) => {
                this.signInWithMsalSso()
                    .then(() => resolve(true))
                    .catch((error) => reject(error))
            })
            .catch(error => {
                console.log('error', error);
                reject(error)
            })

    }

    public async renewSession(): Promise<SignInResponseDTO> {
        return new Promise<SignInResponseDTO>((resolve, reject) => {
            try {
                if (environmentService.getEnvironment()?.authType === AuthType.TIME_BASED_ONE_TIME_PASSWORD
                    || environmentService.getEnvironment()?.authType === AuthType.USERNAME_PASSWORD) {
                    // if (isDefined(guslStorage.getJwtSession())) {
                    this.signInWithJwtToken(guslStorage.getJwtSession())
                        .then((response: SignInResponseDTO) => {
                            guslStorage.setJwtSession({
                                sessionToken: response.sessionToken,
                                userToken: response.userToken,
                            } as JWTTokens)
                            if (response.timezonePreference) {
                                this.setTimezone(response.timezonePreference)
                            }
                            this.tandCUpgradeRequiredSubject.next(response.acceptTandCRequired)
                            this.powerOfAttorney = (response?.hasPowerOfAttorney || false)
                            console.log('renew session set home page', response?.homePage)
                            this.homePage = response?.homePage
                            resolve(response)
                        })
                        .catch(error => {
                            log.error('sessionService', 'ERR001', 'renewSession:', error)
                            reject(error)
                        })
                } else if (environmentService.getEnvironment()?.authType === AuthType.GOOGLE_SSO) {
                    // } else if (isDefined(guslStorage.getGoogleToken())) {
                    this.signInWithGoogleSsoToken(guslStorage.getGoogleToken() || '')
                        .then((response: SignInResponseDTO) => {
                            guslStorage.setGoogleSessionToken(response.sessionToken)
                            this.homePage = response?.homePage
                            resolve(response)
                        })
                        .catch(error => {
                            log.error('sessionService', 'ERR001', 'renewSession:', error)
                            reject(error)
                        })
                } else if (environmentService.getEnvironment()?.authType === AuthType.MSAL) {
//                 } else if (isDefined(guslStorage.getMsalToken())) {
                    console.log('-- 318 renew session')
                    this.performSilentSignInWithMsal(resolve, reject)

                    // console.log('-- 322 wonder if this is required')
                    // signInWithMsalSsoToken(guslStorage.getMsalName(), guslStorage.getMsalUserName(), guslStorage.getMsalToken())
                    //     .then((response: SignInResponseDTO) => {
                    //         guslStorage.setMsalToken(response.sessionToken)
                    //         resolve(response)
                    //     })
                    //     .catch(error => {
                    //         console.log('error =>', error)
                    //         log.error(className, 'ERR001', 'renewSession:', error)
                    //         reject(error)
                    //     })
                }
            } catch (error) {
                log.error('sessionService', 'ERR003', 'renewSession:', error)
                reject(error)
            }
        })
    }

    public async signOut(): Promise<SignOutResponseDTO> {
        return new Promise<SignOutResponseDTO>((resolve, reject) => {
            if (environmentService.getEnvironment()?.authType === AuthType.MSAL) {
                this.environmentContext?.getMsalInstance()?.logout()
            }
            guslStorage.clearSession()
            this.logoutSubject.next(new Date().getTime())

            this.post<any, SignOutResponseDTO>('/security/v1/sign-out')
                .then((response) => {
                    guslStorage.clearSession()
                    this.blastContext?.disconnect()
                    resolve(response.data)
                    this.navigateTo('/login/')
                }).catch(error => {
                log.error('SessionProvider', 'ERR001 SignIn error', error)
                reject(error)
            })
        })

    }


    public async validateSession(): Promise<boolean> {
        console.log('-- 588 validateSession ----')
        return new Promise<boolean>((resolve, reject) => {
            try {
                if (environmentService.getEnvironment()?.authType === AuthType.MSAL) {
                    // || isDefined(sessionStorage.getItem('msal.idtoken'))
                    if (notBlank(guslStorage.getMsalToken())) {
                        this.signInWithMsalSso()
                            .then(() => {
                                this.performSilentSignInWithMsal(resolve, reject)
                            })
                            .catch((error) => reject(error))
                    } else {
                        this.performSilentSignInWithMsal(resolve, reject)
                    }
                } else {

                    if (environmentService.getEnvironment()?.authType === AuthType.TIME_BASED_ONE_TIME_PASSWORD
                        || environmentService.getEnvironment()?.authType === AuthType.USERNAME_PASSWORD) {
                        // if (isDefined(guslStorage.getJwtSession())) {
                        this.signInWithJwtToken(guslStorage.getJwtSession())
                            .then((response: SignInResponseDTO) => {
                                log.debug('sessionService', 'MSG005', 'SignIn Response:', response)

                                if (response.passwordChangeRequired) {
                                    guslStorage.clearSession()
                                    this.navigateTo('/login/')
                                }

                                // blastContext.login(response.sessionToken)
                                // loginSubject.next(convertToGuslUser(response))
                                if (response.timezonePreference) {
                                    this.setTimezone(response.timezonePreference)
                                }
                                this.tandCUpgradeRequiredSubject.next(response.acceptTandCRequired)
                                this.powerOfAttorney = response?.hasPowerOfAttorney || false
                                this.homePage = response?.homePage
                                this.blastContext?.login(response.sessionToken)
                                const guslUser = this.convertToGuslUser(response);
                                this.loginSubject.next(guslUser)
                                log.setUser(guslUser)

                                resolve(true)
                            })
                            .catch(error => {
                                log.error('sessionService', 'ERR001', 'validateSession:', error)
                                resolve(false)
                            })
                    } else if (environmentService.getEnvironment()?.authType === AuthType.GOOGLE_SSO) {
                        // } else if (isDefined(guslStorage.getGoogleToken())) {
                        this.signInWithGoogleSsoToken(guslStorage.getGoogleToken() || '').then((response: SignInResponseDTO) => {
                            log.debug('sessionService', 'MSG006', 'SignIn Response:', response)
                            this.homePage = response?.homePage
                            this.blastContext?.login(response.sessionToken)
                            const guslUser = this.convertToGuslUser(response);
                            this.loginSubject.next(guslUser)
                            log.setUser(guslUser)

                            resolve(true)
                        }).catch(error => {
                            log.error('sessionService', 'ERR002', 'validateSession:', error)
                            resolve(false)
                        })
                    } else if (environmentService.getEnvironment()?.authType === AuthType.MSAL) {
                        // } else if (isDefined(guslStorage.getMsalToken())) {
                        this.signInWithMsalSsoToken(guslStorage.getMsalName(), guslStorage.getMsalUserName(), guslStorage.getMsalToken())
                            .then((response: SignInResponseDTO) => {
                                // guslStorage.setMsalToken(response.sessionToken)
                                this.homePage = response?.homePage
                                resolve(true)
                            })
                            .catch(error => {
                                log.error('sessionService', 'ERR001', 'renewSession:', error)
                                reject(error)
                            })
                    }
                }
            } catch (error) {
                log.error('sessionService', 'ERR003', 'validateSession:', error)
                resolve(false)
            }
        })
    }

    public performSessionValidation() {
        return new Promise<boolean>((resolve, reject) => {
            this.validateSession()
                .then((success: boolean) => {
                    this.sessionStatusSubject.next(success ? SessionStatus.VALID : SessionStatus.INVALID)
                    this.systemReadySubject.next(true)
                    resolve(true)
                })
                .catch(error => {
                    log.info('sessionService', 'ERR001', 'validateSession error', error)
                    this.sessionStatusSubject.next(SessionStatus.INVALID)
                    this.systemReadySubject.next(true)
                    reject(error)
                })
        })
    }


    public async loginWithGoogleSsoToken(idToken: string): Promise<SignInResponseDTO> {
        return new Promise<SignInResponseDTO>((resolve, reject) => {
            this.signInWithGoogleSsoToken(idToken).then((response: SignInResponseDTO) => {
                log.debug('sessionService', 'MSG001', 'SignIn Response:', response)
                this.sessionStatusSubject.next(SessionStatus.VALID)
                this.systemReadySubject.next(true)
                const guslUser = this.convertToGuslUser(response);
                this.loginSubject.next(guslUser)
                log.setUser(guslUser)
                this.blastContext?.login(idToken)
                resolve(response)
            }, reason => {
                this.sessionStatusSubject.next(SessionStatus.INVALID)
                this.systemReadySubject.next(true)
                reject(reason)
            })
        })
    }

    public async loginWithMsalSsoToken(name: string, username: string, idToken: string): Promise<SignInResponseDTO> {
        return new Promise<SignInResponseDTO>((resolve, reject) => {
            this.signInWithMsalSsoToken(name, username, idToken).then((response: SignInResponseDTO) => {
                log.debug('sessionService', 'MSG001', 'SignIn Response:', response)
                this.homePage = response?.homePage
                this.sessionStatusSubject.next(SessionStatus.VALID)
                this.systemReadySubject.next(true)
                this.blastContext?.login(idToken)
                resolve(response)
            }, reason => {
                this.sessionStatusSubject.next(SessionStatus.INVALID)
                this.systemReadySubject.next(true)
                reject(reason)
            })
        })
    }


    public signIn(username: string, password: string, totp?: string): Promise<SignInResponseDTO> {
        return new Promise<SignInResponseDTO>((resolve, reject) => {
            const request: SignInRequestDTO = {
                username: username,
                password: password,
                totp: totp,
                timezone: this.getTimezone()
            }
            this.post<SignInRequestDTO, SignInResponseDTO>('/security/v1/sign-in', request)
                // signIn(username, password, totp)
                .then((response) => {
                    const signInResponse: SignInResponseDTO = response.data
                    log.debug('sessionService', 'MSG001', 'SignIn Response:', signInResponse)
                    guslStorage.setJwtSession({
                        sessionToken: signInResponse.sessionToken,
                        userToken: signInResponse.userToken,
                    } as JWTTokens)
                    if (signInResponse.passwordChangeRequired) {
                        resolve(signInResponse)
                    } else {
                        if (signInResponse.timezonePreference) {
                            this.setTimezone(signInResponse.timezonePreference)
                        }
                        this.tandCUpgradeRequiredSubject.next(signInResponse.acceptTandCRequired)
                        this.powerOfAttorney = signInResponse?.hasPowerOfAttorney || false
                        this.homePage = signInResponse?.homePage
                        this.performSessionValidation()
                            .then(() => {
                                log.info('sessionService', 'MSG002', 'Session validation complete post sign-in')
                                resolve(signInResponse)
                            })
                            .catch(error => {
                                log.error('sessionService', 'ERR002', 'Session validation error', error)
                                reject(error)
                            })
                    }
                })
                .catch(error => {
                    console.log('----------- signIn failed', error)
                    log.error('sessionService', 'ERR001 SignIn error', error)
                    reject(error)
                })
        })
    }

    public async getQrCode(username: string): Promise<QrCodeResponseDTO> {
        return new Promise<QrCodeResponseDTO>((resolve, reject) => {
            const request: QrCodeRequestDTO = {username: username}
            this.post<QrCodeRequestDTO, QrCodeResponseDTO>('/totp/v1/qrcode', request)
                .then((response) => {
                    log.debug('sessionService', 'MSG001', 'getQrCode response', response)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        });
    }

    // ----------------------------------- System Ready
    public getSystemReady(): boolean {
        return this.systemReadySubject.getValue()
    }

    public getSystemReadyAsObservable(): Observable<boolean> {
        return this.systemReadySubject.asObservable()
    }


    public setSystemReady(value: boolean) {
        this.systemReadySubject.next(value)
    }

    // ----------------------------------- Session Status

    public getSessionStatus(): SessionStatus {
        return this.sessionStatusSubject.getValue()
    }

    public setSessionStatus(status: SessionStatus) {
        this.sessionStatusSubject.next(status)
    }

    public getSessionStatusAsObservable(): Observable<SessionStatus> {
        return this.sessionStatusSubject.asObservable()
    }

    // ----------------------------------- login
    public getLoginValue(): GuslUser | null {
        return this.loginSubject.getValue()
    }

    public setLoginValue(value: GuslUser | null): void {
        this.loginSubject.next(value)
    }

    public getLoginSubjectAsObservable(): Observable<GuslUser | null> {
        return this.loginSubject.asObservable()
    }

    // ----------------------------------- T&C upgrade

    public getTandCUpgradeRequired(): boolean {
        return this.tandCUpgradeRequiredSubject.getValue()
    }

    public setTandCUpgradeRequired(value: boolean): void {
        this.tandCUpgradeRequiredSubject.next(value)
    }

    public getTandCUpgradeRequiredAsObservable(): Observable<boolean> {
        return this.tandCUpgradeRequiredSubject.asObservable()
    }

    // ----------------------------------- Logout

    public getLogout(): number {
        return this.logoutSubject.getValue()
    }

    public getLogoutAsObservable(): Observable<number> {
        return this.logoutSubject.asObservable()
    }

    public setLogout(value: number): void {
        return this.logoutSubject.next(value)
    }

    // ----------------------------------- timezone

    public setTimezone(timezone: string): void {
        if (timezone) {
            this.timezoneSubject.next(timezone)
        }
    }

    public getTimezoneAsObservable(): Observable<string> {
        return this.timezoneSubject.asObservable()
    }

    // ----------------------------------- power of attorney
    public hasPowerOfAttorney(): boolean {
        return this.powerOfAttorney
    }

    // ----------------------------------- power of attorney
    public getHomePage(): string | undefined {
        return this.homePage
    }

    // ----------------------------------- start

    public start() {
        this.setSystemReady(false)
        guslStorage.getSession().then((current: Session) => {

            log.info('sessionService', 'MSG001', 'getSession', current)
            if (environmentService.getEnvironment()?.authType === AuthType.MSAL) {
                this.performSessionValidation()
                    .then(() => {
                        log.info('sessionService', 'MSG002', 'Session validation complete')
                    }).catch((error: any) => {
                    console.error('---> MSAL performSessionValidation error', error)
                    const loginPromise = new Promise<any>((resolve, reject) => {
                        this.performSilentSignInWithMsal(resolve, reject)
                    });
                    loginPromise.then((a: any) => {
                    }).catch((error) => {
                        console.log('error again - user not valid', error)
                    })
                })
            } else if (isDefined(current) && (isDefined(current.jwtTokens) || isDefined(current.ssoToken) || isDefined(current.msalToken))) {
                this.performSessionValidation().then(() => {
                    log.info('sessionService', 'MSG002', 'Session validation complete')
                }).catch((error: any) => {
                    console.error('---> performSessionValidation error', error)
                })
            } else {
                log.info('sessionService', 'MSG003', 'No session data exists')
                this.setSessionStatus(SessionStatus.INVALID)
                this.setSystemReady(true)
            }
        })
    }

    public updateTheme(themeId: string, lastUpdated?: string): void {
        this.checkAndUpdateTheme(themeId, lastUpdated)
            .then(() => {
                console.log('Theme updated');
            })
            .catch(error => {
                console.log('Error updating theme');
            })
    }

}

const sessionService = new SessionService();

export {sessionService};

export default SessionService;
