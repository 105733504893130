import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import {SessionContext} from '../../../../providers/session/SessionContext';
import {SystemContext} from '../../../../providers/system/SystemContext';
import {notBlank} from '../../../../utils/TypeCheckers';
import {cancelAbortController, mapSinglePageFields} from '../../../../utils/Utils';
import {ActionButtonStyledOtto} from '../../../common/buy-sell-form/styled_v2';
import {ClosedStyled} from '../../../common/global-search/results/styled';
import TableSpinner from '../../../common/gusl-table/spinner/TableSpinner';
import ActionButton from '../../../common/maintain-table/bootstrap/ActionButton';
import {fieldService} from '../../../FieldService';
import {FieldConfigDTO} from '../../../types';
import ArticlesView from '../articles-view/ArticlesView';
import {ArticleHeaderStyled, ArticleInfoStyled} from '../articles-view/styled';
import SecondaryOttoNav, {HOME} from '../navigation/SecondaryOttoNav';
import {OttoTicker} from '../otto-ticker/OttoTicker';
import {OTTO_HOME} from '../OttoEvents';
import {DealResponseWrapper, getDealData} from '../ottoEventsSlice';
import DummyChart from '../resources/DummyChart';
import {OttoBoxStyled, OttoCol12, OttoCol4, OttoContentStyled, OttoGrid, OttoRow} from '../styled';
import {DealOpenState, toggleDealOpen} from './dealOpenSlice';
import {ChartWrapperStyled, DealContainerStyled, DealInnerContainerStyled} from './styled';

interface DealViewProperties {
    code?: string
}

const DEAL_VIEW_URL: string = '/deal/{id}/get'

export const DEAL_PAGE = '/DEAL'
const DealView = ({code}: DealViewProperties): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext);
    const systemContext = useContext(SystemContext);

    const params = useParams();
    const dealId = params?.dealId ? decodeURIComponent(params?.dealId) : undefined;
    const articleId = params?.articleId ? decodeURIComponent(params?.articleId) : undefined;
    const navigate = useNavigate()
    // console.log(`dealview params dealId ${dealId} articleId ${articleId}`)

    const [loading, setLoading] = useState<boolean>(true);

    const _dealOpenSlice: DealOpenState = useAppSelector(state => state.dealOpenSlice);

    // const _ottoEventsSlice: OttoEventState = useAppSelector(state => {
    //     console.log(`dealId ${dealId} code ${code} articleId ${articleId}`)
    //     if (dealId) {
    //         console.log('deal=>',state.ottoEventsSlice[dealId])
    //         return state.ottoEventsSlice[dealId]
    //     } else if (code) {
    //         return state.ottoEventsSlice[code]
    //     } else {
    //         return state.ottoEventsSlice['deal_view']
    //     }
    // });
    const dispatch = useAppDispatch();

    const [fieldMap] = useState<{
        [id: string]: FieldConfigDTO
    }>(mapSinglePageFields(systemContext.getMenuConfig('deal')));

    const [dealData, setDealData] = useState<any | undefined>(undefined);

    const id = dealId // || _ottoEventsSlice.selectedEvent?.dealId || ''

    useEffect(() => {
        const abortController = new AbortController();
        if (notBlank(id)) {
            dispatch(getDealData({
                code: id || '',
                url: DEAL_VIEW_URL,
                sessionContext: sessionContext,
                abortController: abortController,
                pathParams: {id: id}
            })).unwrap()
                .then((reply: DealResponseWrapper) => {
                    setLoading(false);
                    setDealData(reply.response)
                })
                .catch((error: any) => {
                    console.error("error", error);
                    setLoading(false);
                    navigate(OTTO_HOME)
                });
        } else {
            console.log('no id')
            navigate(OTTO_HOME + '/' + HOME)
        }
        return () => {
            cancelAbortController(abortController);
        };

    }, [code, dealId, articleId]);

    const onCloseDeal = () => {
        dispatch(toggleDealOpen({opened: false}))
    }

    if (loading) {
        return <TableSpinner/>

    }

    const toggleOpenDeal = (opened: boolean) => {
        dispatch(toggleDealOpen({opened: opened}))
    }

    const displayField = (field: string): React.ReactElement => {
        const fld: FieldConfigDTO | undefined = fieldMap[field]
        if (!fld) {
            return <></>
        }

        return (
            <ArticleInfoStyled><span>{fld.label}: </span> {fieldService.getTableField(fld, undefined, dealData, undefined, undefined, undefined, undefined, true)?.render()}
            </ArticleInfoStyled>
        )
    }

    const renderClosed = (closed: boolean): React.ReactElement => {
        if (!closed) {
            return <></>
        }
        return (
            <ClosedStyled>CLOSED</ClosedStyled>
        )
    }

    const renderTitleAndDealDetails = (): React.ReactElement => {
        if (!dealData || !dealData?.dealType) {
            return <></>
        } else if (dealData?.dealType === 'HOLD_CO') {
            return renderHoldCo()
        } else if (dealData?.dealType === 'RISK_ARB') {
            return renderRiskArb()

        } else if (dealData?.dealType === 'SHARE_CLASS') {
            return renderShareClass()

        } else if (dealData?.dealType === 'PRE_EVENT') {
            return renderRiskArb()
        } else
            return (<></>)
    }

    const renderRiskArb = (): React.ReactElement => {
        return (
            <div className="d-flex align-items-baseline justify-content-between"
                 key={'ra'}>
                <div className={"fs-4 p-2"}>
                    <ArticleHeaderStyled>{dealData?.dealName}{renderClosed(dealData?.closed || false)}</ArticleHeaderStyled>
                    <OttoGrid>
                        <OttoRow>
                            <OttoCol4>
                                {displayField('dealType')}
                                {displayField('targetTicker')}
                                {displayField('acquirerTicker')}
                                {displayField('geography')}
                                {displayField('country')}
                                {displayField('sector')}
                            </OttoCol4>
                            <OttoCol4>
                                {displayField('dealSize')}
                                {displayField('terms')}
                                {displayField('announcementDate')}
                                {displayField('expectedClosingDate')}
                            </OttoCol4>
                            <OttoCol4>
                                {displayField('probability')}
                                {displayField('downside')}
                                {displayField('netSpread')}
                                {displayField('netAnnualisedSpread')}
                                {displayField('spreadMoveVs2DaysAgo')}
                                {displayField('spreadMoveVs10DaysAgo')}
                            </OttoCol4>
                        </OttoRow>
                    </OttoGrid>
                </div>
                <ActionButtonStyledOtto>
                    {/*<ActionButton label={_dealOpenSlice.opened ? "Close Deal" : "View Deal"} action={"default"}*/}
                    {/*              onClick={() => toggleOpenDeal(_dealOpenSlice.opened)}/>*/}
                </ActionButtonStyledOtto>
            </div>
        )
    }

    const renderShareClass = (): React.ReactElement => {

        return (
            <div className="d-flex align-items-baseline justify-content-between"
                 key={'sc'}>
                <div className={"fs-4 p-2"}>
                    <ArticleHeaderStyled>{dealData?.companyName}{renderClosed(dealData?.closed || false)}</ArticleHeaderStyled>
                    <OttoGrid>
                        <OttoRow>
                            <OttoCol4>
                                {displayField('dealType')}
                                {displayField('shareClass')}
                                {displayField('preferenceTicker')}
                                {displayField('nonPreferenceTicker')}
                            </OttoCol4>
                            <OttoCol4>
                                {displayField('currentPremium')}
                                {displayField('historicMean')}
                                {displayField('votingRights')}
                                {displayField('liquidityDifferential')}
                                {displayField('resistanceLevel')}
                            </OttoCol4>
                            <OttoCol4>
                                {displayField('liquidityPrefEurm')}
                                {displayField('liquidityOrdEurm')}
                                {displayField('spreadMovement5Day')}
                                {displayField('spreadMovement9Day')}
                                {displayField('spreadMovement30Day')}
                            </OttoCol4>
                        </OttoRow>
                    </OttoGrid>
                </div>
                <ActionButtonStyledOtto>
                    {/*<ActionButton label={_dealOpenSlice.opened ? "Close Deal" : "View Deal"} action={"default"}*/}
                    {/*              onClick={() => toggleOpenDeal(_dealOpenSlice.opened)}/>*/}
                </ActionButtonStyledOtto>
            </div>
        )
    }

    const renderHoldCo = (): React.ReactElement => {
        return (
            <div className="d-flex align-items-baseline justify-content-between"
                 key={'hc'}>
                <div className={"fs-4 p-2"}>
                    <ArticleHeaderStyled>{dealData?.companyName}{renderClosed(dealData?.closed || false)}</ArticleHeaderStyled>
                    <OttoGrid>
                        <OttoRow>
                            <OttoCol4>
                                {displayField('dealType')}
                                {displayField('ticker')}
                                {displayField('navDiscount')}
                                {displayField('listedAssetsPercOfGav')}
                                {displayField('unlistedAssetsPercOfGav')}
                            </OttoCol4>
                            <OttoCol4>
                                {displayField('investments')}
                                {displayField('navDiscountChangeSince5Days')}
                                {displayField('navDiscountChangeSince10Days')}
                                {displayField('navDiscountChangeSince30Days')}
                            </OttoCol4>
                        </OttoRow>
                    </OttoGrid>
                </div>
                <ActionButtonStyledOtto>
                    {/*<ActionButton label={_dealOpenSlice.opened ? "Close Deal" : "View Deal"} action={"default"}*/}
                    {/*              onClick={() => toggleOpenDeal(_dealOpenSlice.opened)}/>*/}
                </ActionButtonStyledOtto>
            </div>
        )
    }

    const renderChart = (): React.ReactElement => {
        return (
            <ChartWrapperStyled>
                <DummyChart/>
            </ChartWrapperStyled>
        )
    }

    const renderArticles = (): React.ReactElement => {
        return (
            <ArticlesView code={id || ''} height={'100%'} hideClose={true}
                          dealId={dealId}
                          articleId={articleId}
                          useMaxHeight={true}
            />
        )
    }

    const renderDealPage = (): React.ReactElement => {
        return (
            <DealContainerStyled
                key={'key_ ' + (dealId || 'deal')}>
                <div className="row g-0">
                    <div className="col-lg-12">
                        <DealInnerContainerStyled>
                            <OttoRow>
                                <OttoCol12>
                                    <OttoBoxStyled>
                                        {renderTitleAndDealDetails()}
                                    </OttoBoxStyled>
                                </OttoCol12>
                                <OttoCol12>
                                    <OttoBoxStyled>
                                        {renderArticles()}
                                    </OttoBoxStyled>
                                </OttoCol12>
                            </OttoRow>
                        </DealInnerContainerStyled>
                    </div>
                </div>
            </DealContainerStyled>
        )
    }

    return (
        <OttoContentStyled>
            <SecondaryOttoNav/>
            <OttoTicker/>
            {renderDealPage()}
        </OttoContentStyled>
    )
}
export default DealView
