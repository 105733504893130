export const GAUGE_DEFAULT = {

    maintainAspectRatio: false,
    responsive: true,
    circumference: 250,
    rotation: 235,
    plugins: {
        tooltip: {
            enabled: false
        },
    },
    legend: {
        display: false
    },

}