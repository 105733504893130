import React, { useContext, useState } from "react";
import { ActionIconStyled } from "../styled/table-controls/styled";
import { getRowsToUpdate, InlineEditState, resetInlineEdit, toggleInlineEdit } from "./inlineEditSlice";
import MGMutation from "../../../../providers/session/MGMutation";
import { refreshListView } from "../queryParamsSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {ActionCompletedBO, IMenuDTO} from "../../../types";
import { SystemContext } from "../../../../providers/system/SystemContext";
import { EnvironmentContext } from "../../../../providers/environment/EnvironmentContext";
import { SessionContext } from "../../../../providers/session/SessionContext";
import { TableControlsDO } from "../filters/types";
import { maintainTableService } from "../../maintain-table/MaintainTableService";
import ElementWithPopover from "../../element-with/element-with-popover/ElementWithPopover";
import SaveRowsInfoMessage from "./SaveRowsInfoMessage";
import { SaveIconStyled } from "./styled";

export interface SaveButtonDO {
  code: string;
  widgetPanelProperties?: TableControlsDO["widgetPanelProperties"];
}

export default function SaveRowsButton({ code, widgetPanelProperties }: SaveButtonDO) {

  const [className] = useState("SaveRowsButton-" + new Date().getTime());
  const dispatch = useAppDispatch();

  const environmentContext = useContext(EnvironmentContext);
  const systemContext = useContext(SystemContext);
  const sessionContext = useContext(SessionContext);

  const [menuItem] = useState<IMenuDTO | undefined>(
    systemContext.getMenuConfig(code)
  );

  const fields = (menuItem && maintainTableService.extractAllFields(menuItem)) || [];
  const inlineEditState: InlineEditState = useAppSelector(state => state.inlineEditSlice[code]);
  const rowsInEditMode: InlineEditState["rowsInEditMode"] = inlineEditState.rowsInEditMode;
  const isMobile = environmentContext.isMobileDevice(widgetPanelProperties);

  const initialMessage: React.JSX.Element = <SaveRowsInfoMessage level={"initial"} />;
  const [infoMessage, setInfoMessage] = useState<React.JSX.Element>(initialMessage);

  async function saveRowsHandler() {

    setInfoMessage(<SaveRowsInfoMessage level={"loading"} />);

    const rowsToUpdate: InlineEditState["rowsInEditMode"] = getRowsToUpdate(rowsInEditMode, fields);
    console.log({ rowsToUpdate });

    if (rowsToUpdate.length === 0) {
      setInfoMessage(<SaveRowsInfoMessage level={"noRows"} />);
      setTimeout(() => {
        setInfoMessage(initialMessage);
      }, 3000);
      return;
    }

    await MGMutation({
      // @ts-ignore
      sessionContext,
      endpoint: menuItem?.updateMany as string,
      queryParams: rowsToUpdate

    }).then((response) => {
      // console.log(response);
      // @ts-ignore
      const serverNotification: ActionCompletedBO = response.data as ActionCompletedBO;

      if (serverNotification.failed) {

        setInfoMessage(<SaveRowsInfoMessage level={"error"} message={serverNotification.notificationMessage} />);

        setTimeout(() => {
          setInfoMessage(initialMessage);
        }, 3500);
      } else {

        setInfoMessage(<SaveRowsInfoMessage level={"success"} message={serverNotification.notificationMessage} />);

        setTimeout(() => {
          dispatch(resetInlineEdit({ code }));
          dispatch(toggleInlineEdit({ code }));
          dispatch(refreshListView({ code }));
        }, 2000);

      }

    }).catch(e => {
      console.error("ERROR SAVING UPDATED ROWS------->", e);
      setInfoMessage(<SaveRowsInfoMessage level={"error"} message={"Error."} />);
    });
  }

  return (
    <ActionIconStyled isMobile={isMobile}>
      <ElementWithPopover
        placement={"left"}
        element={
          <SaveIconStyled
            level={""}
            onClick={saveRowsHandler}
            icon={"fa-regular fa-square-check p-1 me-2  text-info"} />}
        popover={infoMessage} />
    </ActionIconStyled>
  );
}

