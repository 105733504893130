import React, {RefObject, useContext, useEffect, useState} from "react";
import {createRoot, Root} from 'react-dom/client';
import {Subscription} from "rxjs";
import {ExtraFieldProperties, PROPERTY_NAMES} from '../../../components/common/buy-sell/types';
import {CurrencyDTO} from '../../../components/types';
import {safeStream, unSubscribe} from "../../../utils/Utils";

import {BlastContext} from "../BlastContext";
import {FieldDeltaDO, SimpleMessageBO} from "../commands";
import {BlastField} from './BlastField';

const ATTR_TABLE_KEY: string = 'data-blast-table-key'

const ATTR_KEY_FIELD: string = 'data-blast-key-field'
const ATTR_KEY_VALUE: string = 'data-blast-key-value'
const ATTR_DATA_FIELD: string = 'data-blast-data-field'
const ATTR_FIELD_TYPE: string = 'data-field-type'
const ATTR_EXTRA_PROPERTY_PREFIX: string = 'data-extra-property-'
const ATTR_INIT_VALUE: string = 'data-blast-init-value'


interface RegistraInfo {
    tableKey: string,
    keyField: string,
    keyValue: string,
    dataField: string,
    element: HTMLElement | Element,
    fieldType: string | undefined,
    root?: Root | undefined,
    extraFieldProperties?: ExtraFieldProperties
}

export const useBlastDelta = (ref: RefObject<HTMLDivElement>) => {

    const blastContext = useContext(BlastContext)

    const _registra: RegistraInfo[] = [];

    let currentKey: string | undefined = undefined;
    let currentField: string | undefined = undefined;
    let currentValue: string | undefined = undefined;
    let initValue: any | undefined = undefined;

    let blastDeltaSubscription: Subscription | undefined = undefined;
    const [currencyMap] = useState<{ [id: string]: CurrencyDTO }>(blastContext.getCurrencyMap)

    const setupBlastSubscription = () => {

        blastDeltaSubscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {

            if (message?.cmd === 'table.delta' || message?.cmd === 'field.delta') {
                safeStream(_registra)
                    .filter(entry => entry.tableKey === message?.data?.tableKey)
                    .filter(entry => entry.keyField === message?.data?.keyField)
                    .filter(entry => entry.keyValue === message?.data?.keyValue)
                    .forEach(entry => {
                        if (entry.element) {
                            // console.log('message',message)
                            // console.log(`update ${entry.dataField} ${message?.data?.value[entry.dataField]}`)
                            if (message?.data?.value[entry.dataField]) {
                                if (entry.fieldType) {
                                    addField(entry, message?.data?.value[entry.dataField])
                                } else {
                                    entry.element.innerHTML = message?.data?.value[entry.dataField]
                                }
                            }
                        }
                    });
            }
        });
    }

    const addField = (entry: RegistraInfo, value: any) => {
        if (!entry.root) {
            entry.root = createRoot(entry.element);
        }
        entry.root.render(<BlastField fieldType={entry.fieldType} value={value} currencyMap={currencyMap}
                                      extraFieldProperties={entry.extraFieldProperties}/>);
    }
    const registerElement = (
        element: HTMLElement | Element,
        currentKey: string | undefined,
        currentField: string | undefined,
        currentValue: string | undefined,
        initValue: any | undefined,
        dataField: string | undefined,
        fieldType: string | undefined,
        extraFieldProperties: ExtraFieldProperties,
        index: number
    ) => {
        // console.log(`currentKey: ${currentKey} currentField: ${currentField} currentValue: ${currentValue} dataField: ${dataField} initValue: ${initValue}`)

        if (element && currentKey && currentField && currentValue && dataField) {
            if (!blastDeltaSubscription) {
                setupBlastSubscription();
            }

            const entry: RegistraInfo = {
                element: element,
                tableKey: currentKey,
                keyField: currentField,
                keyValue: currentValue,
                dataField: dataField,
                fieldType: fieldType,
                extraFieldProperties: extraFieldProperties
            }

            _registra.push(entry)

            if (fieldType) {
                if (initValue) {
                    try {
                        addField(entry, JSON.parse(initValue.replace('&amp;', '&')))
                    } catch (error: any) {
                        console.log('error parsing json')
                        addField(entry, element.innerHTML)
                    }

                } else if (element.innerHTML) {
                    addField(entry, element.innerHTML)
                }
            }

        }
    };

    const checkAttributes = (element: HTMLElement | Element, attributes: NamedNodeMap | undefined, index: number) => {
        const extractProperties = (attributes: NamedNodeMap) => {
            const extraFieldProperties: ExtraFieldProperties = {
                amount: undefined
            }
            for (let i = 0; i < attributes.length; i++) {
                const attr = attributes.item(i);
                if (attr?.name?.startsWith(ATTR_EXTRA_PROPERTY_PREFIX)) {
                    const propertyName = attr.name.replace(ATTR_EXTRA_PROPERTY_PREFIX, '')
                    safeStream(PROPERTY_NAMES).filter((name: string) => name.toLowerCase() === propertyName.toLowerCase())
                        .forEach((name: string) => {
                            if (attr.value === 'true') {
                                // @ts-ignore
                                extraFieldProperties[name] = true
                            } else if (attr.value === 'false') {
                                // @ts-ignore
                                extraFieldProperties[name] = false
                            } else {
                                // @ts-ignore
                                extraFieldProperties[name] = attr.value
                            }
                        })
                }
            }
            return extraFieldProperties;
        };


        if (attributes) {
            if (attributes.getNamedItem(ATTR_TABLE_KEY)?.value) {
                currentKey = attributes.getNamedItem(ATTR_TABLE_KEY)?.value
            }
            if (attributes.getNamedItem(ATTR_KEY_FIELD)?.value) {
                currentField = attributes.getNamedItem(ATTR_KEY_FIELD)?.value
            }
            if (attributes.getNamedItem(ATTR_KEY_VALUE)?.value) {
                currentValue = attributes.getNamedItem(ATTR_KEY_VALUE)?.value
            }
            if (attributes.getNamedItem(ATTR_INIT_VALUE)?.value) {
                initValue = attributes.getNamedItem(ATTR_INIT_VALUE)?.value
            }
            if (attributes.getNamedItem(ATTR_DATA_FIELD)?.value) {
                const fieldType = attributes.getNamedItem(ATTR_FIELD_TYPE)?.value

                const extraFieldProperties: ExtraFieldProperties = extractProperties(attributes)

                registerElement(element,
                    currentKey,
                    currentField,
                    currentValue,
                    initValue,
                    attributes.getNamedItem(ATTR_DATA_FIELD)?.value,
                    fieldType,
                    extraFieldProperties,
                    index)
            }
        }

    }

    const registerWithBlast = (element: HTMLElement | Element | undefined | null, children: HTMLCollection | undefined, index: number) => {
        if (!element || !children) {
            return
        }
        for (let i = 0; i < children.length; i++) {
            checkAttributes(children[i], children[i].attributes, index + 1)
            registerWithBlast(children[i], children[i].children, index + 1)
        }
    }


    useEffect(() => {
        if (!blastDeltaSubscription && ref?.current) {
            checkAttributes(ref.current, ref.current.attributes, 0)
            registerWithBlast(ref.current, ref.current?.children, 0)
        }
        return () => {
            unSubscribe(blastDeltaSubscription);
        }
    })
}
