import styled, {ThemeProps} from 'styled-components';

interface WysiwygProps extends ThemeProps<any> {
}

export const WysiwygStyled = styled.div.attrs((props: WysiwygProps) => ({}))<WysiwygProps>`

    .jodit-container {
        min-height: 430px !important;
        height: 430px !important;
        min-width: 200px;
        max-width: 100%;
        width: auto;
    }

`
export const WysiwygTableValueStyled = styled.div.attrs((props) => ({
    id: 'wysiwyg_table'
}))`
`

interface WysiwygTableWrapperProperties {
    height: string
}

export const WysiwygTableWrapperStyled = styled.div.attrs((props: WysiwygTableWrapperProperties) => ({
    id: 'wysiwyg_content'
}))<WysiwygTableWrapperProperties>`
    background-color: #fff;
    height: ${props => props.height};
    overflow: auto;
`
export const WysiwygTableContentStyled = styled.div.attrs((props) => ({
    className: 'wysiwyg-content',
    id: 'wysiwyg_content'
}))`
    padding: 10px;
    height:100%;
    
`
