import React, {useContext, useEffect, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {assignReferences, noop, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {DownArrowStyled, LeftArrowStyled, NoMovementWrapperStyled, RightArrowStyled, UpArrowStyled} from './styled';

export const PositionField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<number>(properties?.data || 0);
    const [formValue, setFormValue] = useState<number>(_guslFormState?.getFieldValue(properties) || properties.data || 0);
    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            // console.log('newValue=>', properties.fieldConfig.name, newValue)
            setFormValue(newValue)
            setRefreshCounter(refreshCounter + 1)
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        }
    }, [properties]);


    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || 0);
    }

    useEffect(() => {
        setFormValue(properties?.data || 0)
    }, [properties])

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, () => {
            return true
        })
    });

    const renderTableView = (): React.ReactElement => {
        if (formValue > 0) {
            return (
                <UpArrowStyled key={properties.fieldConfig.name + 'u_' + refreshCounter}/>
            )
        }
        if (formValue < 0) {
            return (
                <DownArrowStyled key={properties.fieldConfig.name + 'd_' + refreshCounter}/>
            )
        }
        return (
            <NoMovementWrapperStyled><LeftArrowStyled
                key={properties.fieldConfig.name + 'l_' + refreshCounter}/><RightArrowStyled
                key={properties.fieldConfig.name + 'r_' + refreshCounter}/></NoMovementWrapperStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        return (
            <></>
        )

    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
