import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {compare} from "../../../../utils/Utils";
import {FieldConfigDTO, WidgetPanelProperties} from "../../../types";
import {MultiLookupFieldAsTextFilter} from "../../multi-lookup/MultiLookupFieldAsTextFilter";
import FavQueriesWrapper from "../fav-queries/FavQueriesWrapper";
import {GuslTableState, toggleFilters} from "../guslTableSlice";
import {SidePanelTitle} from "../side-panel/SidePanelTitle";
import {TableSidePanelWrapperStyled} from "../styled/side-panel/styled";
import {date_time_units, date_units} from "./date-range/date-time-units/dateTimeUnits";
import IndividualDateRangeFilter from "./date-range/IndividualDateRangeFilter";
import FilterBlock from "./FilterBlock";
import {FilterDO} from "./types";
import { filtersClosed } from "../../../../features/ui/uiSlice";

type FiltersProps = {
    code: string,
    data: any,
    widgetPanelProperties?: WidgetPanelProperties
}
export const filterableTypes: string[] = ["text", "option", "lookup", "number", "checkbox", "date", "date_time"];
export const renderFilterTop = (field: FieldConfigDTO, idx: number, code: string, data: any, widgetPanelProperties?: WidgetPanelProperties): React.ReactElement => {
    const filter: FilterDO = {
        name: field.name,
        label: field.label,
    } as FilterDO

    // MK 29/09/2023
    const isFilterable: boolean | undefined = field.advancedFilter === true;
    if (!isFilterable) {
        return <></>
    }
    // MK 10/aug/2023 input text filter lookup

    switch (field.type) {
        case 'text':
        case 'option':
        // MK 22/08/2023 I think we can have a lookup here as well
        case 'lookup':
            return <MultiLookupFieldAsTextFilter field={field} code={code} data={data}
                                                 widgetPanelProperties={widgetPanelProperties}/>;

        // case 'lookup':
        //     filter.type = 'text'
        //     filter.distinctUrl = field.distinctUrl
        //     return <FilterBlock key={idx} filter={filter} code={code}/>
        case 'number':
            filter.type = 'number'
            return <FilterBlock key={idx} filter={filter} code={code} data={data}/>
        case 'checkbox':
            filter.type = 'checkbox'
            return <FilterBlock key={idx} filter={filter} code={code} data={data}/>
        case 'date':
        case 'date_time':
            filter.type = 'date_time'
            addDateRangeQueryParamsTop(filter, field)
            return <IndividualDateRangeFilter key={idx} field={filter} code={code}/>
        default:
            return <></>
    }
}
export const addDateRangeQueryParamsTop = (filter: FilterDO, field: FieldConfigDTO) => {
    filter.hasTime = field.hasTime;
    filter.hasFuture = field.hasFuture;
    filter.hasPast = field.hasPast; // is it safe to assume that date would always have a past?
    filter.units = field.hasTime ? date_time_units : date_units;
}
export default function Filters({code, data}: FiltersProps) {

    const [className] = useState('Filters-' + new Date().getTime());
    const title = 'Filters';
    const state: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);

    const dispatch = useAppDispatch();

    function RenderSidePanelTitle() {
        return <>
            <SidePanelTitle title={title}
                            onCloseClick={() => {
                                dispatch(toggleFilters({code: code}));
                                if(state.showFilters){
                                    dispatch(filtersClosed());
                                }
                            }}/>
            <FavQueriesWrapper code={code}/>

        </>;
    }

    const addDateRangeQueryParams = (filter: FilterDO, field: FieldConfigDTO) => {
        filter.hasTime = field.hasTime;
        filter.hasFuture = field.hasFuture;
        filter.hasPast = field.hasPast;
        filter.units = field.hasTime ? date_time_units : date_units;
    }
// MK 21/08/2023 new prop "side" => showing label on side filters, not on top filters
    const renderFilter = (field: FieldConfigDTO, idx: number): React.ReactElement => {
        const filter: FilterDO = {
            name: field.name,
            label: field.label,
        } as FilterDO

        switch (field.type) {
            case 'text':
            case 'lookup':
                filter.type = 'text'
                filter.distinctUrl = field.distinctUrl
                return <FilterBlock key={idx} filter={filter} code={code} data={data} side/>
            case 'number':
                filter.type = 'number'
                return <FilterBlock key={idx} filter={filter} code={code} data={data} side/>
            case 'checkbox':
                filter.type = 'checkbox'
                return <FilterBlock key={idx} filter={filter} code={code} data={data} side/>
            case 'option':
                filter.type = 'option'
                filter.options = field.options
                return <FilterBlock key={idx} filter={filter} code={code} data={data} side/>
            case 'date':
            case 'date_time':
                filter.type = 'date_time'
                addDateRangeQueryParams(filter, field)
                return <IndividualDateRangeFilter key={idx} field={filter} code={code} side/>
            default:
                return <></>
        }
    }
// console.log({filterable:state.filterableFields})
    const renderFieldFilters = (): React.ReactElement => {
        return <>
            {state.filterableFields
                .filter(fld => fld.displayInTable)
                .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                .map((fld, idx) => <div key={'filter_' + idx}>{renderFilter(fld, idx)}</div>)}
        </>
    }


    return (
        <TableSidePanelWrapperStyled>
            <RenderSidePanelTitle/>
            {renderFieldFilters()}
        </TableSidePanelWrapperStyled>
    );

}
