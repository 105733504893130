import React from 'react';
import {getStyle, replaceBespokeVariables} from '../../../../utils/CssUtils';
import {fieldService} from '../../../FieldService';
import {FieldConfigDTO} from '../../../types';
import {ButtonLabelStyled, ButtonStyled, ColumnLabelStyled, SpinnerStyled} from '../styled';
import {ButtonAction, OutcomeInfoDO, QuoteState} from '../types';

interface RequestButtonProperties {
    isSelected: boolean;
    label?: string,
    state: QuoteState,
    onClick: (action: ButtonAction, outcome?: OutcomeInfoDO) => void,
    isMobile: boolean,
    outcome?: OutcomeInfoDO,
}


export const RequestButton = ({
                                  isSelected,
                                  isMobile,
                                  label,
                                  state,
                                  onClick,
                                  outcome
                              }: RequestButtonProperties): React.ReactElement<RequestButtonProperties> => {

    const renderTableField = (
        fieldType: string,
        value: any,
        fieldName?: string,
        extendedProperties?: string,
        blastDeltaCommand?: string,
        keyField?: string,
        keyValue?: string,
        innerCss?: string | undefined,
        outerCss?: string | undefined,
        dateFormat?: string | undefined,
    ) => {
        const outerStyle = replaceBespokeVariables(isMobile, getStyle(outerCss, ''))
        const name = fieldName || fieldType
        const fieldConfig: FieldConfigDTO = {
            name: name,
            label: name,
            type: fieldType,
            properties: extendedProperties,
            blastDeltaCommand: blastDeltaCommand,
            keyField: keyField,
            keyValue: keyValue,
            tableCss: innerCss,
            dateFormat: dateFormat
        }

        const rowData = {}
        // @ts-ignore
        rowData[name] = value || 0

        return (
            <div style={outerStyle} id={'outer'}>
                {fieldService.getTableField(fieldConfig, undefined, rowData)?.render()}
            </div>
        )

    }

    const renderOddsField = () => {
        if (!outcome || !outcome?.odds) {
            return (
                <SpinnerStyled/>
            )
        }
        return (
            <ColumnLabelStyled>
                {renderTableField('odds', outcome?.odds, outcome?.outcomeId)}
            </ColumnLabelStyled>
        )
    }

    if (state === QuoteState.INIT
        || state === QuoteState.EXPIRED
        || state === QuoteState.TRADER_CANCELLED
        || state === QuoteState.CUSTOMER_CANCELLED
        || state === QuoteState.NETWORK_ERROR
    ) {
        return (
            <ButtonStyled isSelected={isSelected} onClick={() => onClick(ButtonAction.REQUEST)}><ButtonLabelStyled>Request
                Quote</ButtonLabelStyled></ButtonStyled>
        )

    } else if (state === QuoteState.REQUESTING
        || state === QuoteState.REQUESTED
        || state === QuoteState.REQUEST_WAITING
        || state === QuoteState.CANCELLING
        || state === QuoteState.STRIKING
    ) {
        return (
            <ButtonStyled isSelected={isSelected}><SpinnerStyled/></ButtonStyled>
        )
    } else if (state === QuoteState.ACCEPTING || state === QuoteState.REPLIED) {
        return (
            <ButtonStyled isSelected={isSelected}
                          onClick={() => onClick(ButtonAction.SET_OUTCOME, outcome)}><ButtonLabelStyled
                isSelected={isSelected}>{renderOddsField()}</ButtonLabelStyled></ButtonStyled>
        )
    }
    return <></>
}
