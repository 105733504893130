import { NewTickerDO } from "../../types";

export class TrieNode {
  children: { [key: string]: TrieNode };
  tickers: Set<NewTickerDO>;

  constructor() {
    this.children = {};
    this.tickers = new Set<NewTickerDO>();
  }
}

export class EquitiesTrie {
  root: TrieNode;

  constructor() {
    this.root = new TrieNode();
  }

  insert(ticker: NewTickerDO) {
    const words = [ticker.id.toLowerCase(), ticker.name.toLowerCase(), ticker.displayTicker.toLowerCase()];
    for (const word of words) {
      let node = this.root;
      for (const char of word) {
        if (!node.children[char]) {
          node.children[char] = new TrieNode();
        }
        node = node.children[char];
        node.tickers.add(ticker);
      }
    }
  }

  search(prefix: string): NewTickerDO[] {
    let node = this.root;
    for (const char of prefix) {
      if (!node.children[char]) {
        return [];
      }
      node = node.children[char];
    }
    return Array.from(node.tickers);
  }
}
