import {Capacitor} from '@capacitor/core';
import {animated, config, useSpring} from '@react-spring/web';
import {useTimeout} from 'beautiful-react-hooks';
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React, {useEffect, useState} from 'react';

import CookieConsent from 'react-cookie-consent';
import {initReactI18next} from "react-i18next";
import {RouteObject, useLocation, useNavigate, useRoutes, useSearchParams} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UI_CONSTANTS} from './app/ComponentMap';
import {useAppSelector} from "./app/hooks";
import {IAppWrapperProps} from './app/types';
import {ErrorBoundary} from './components/common/error-boundary/ErrorBoundary';
import {FieldComponentLoader} from './components/FieldComponentMap';
import {splashScreenService} from './components/splash/SplashScreenService';
import {SystemNotificationDTO} from './components/types';
import {BlastContext} from './providers/blast/BlastContext';
import {EnvironmentContext} from './providers/environment/EnvironmentContext';
import {environmentService} from './providers/environment/EnvironmentService';
import {CookieConsentProperties, IEnvironment, PolicyLink} from './providers/environment/types';
import {guslStorage} from './providers/session/GuslStorage';
import {SessionContext} from './providers/session/SessionContext';
import {SessionStatus} from './providers/session/types';
import {SystemContext} from './providers/system/SystemContext';
import {GuslThemeContext} from './providers/theme/GuslThemeProvider';
import {log} from './services/LogService';
import {extractToastMessage, extractToastOptions} from './services/TransformService';
import {
    CookieConsentStyled,
    ExternalAppStyled,
    LogoStyled,
    PolicyLinkStyled,
    SpinnerContainerStyled,
    SplashContainerStyled,
    SplashWrapperStyled
} from './styled';
import {notBlank} from './utils/TypeCheckers';
import {
    addImageSuffix,
    IMountedMonitor,
    MountedMonitor,
    replaceStringWithElement,
    RunOnceEffect,
    unSubscribe
} from './utils/Utils';

const App = (props: IAppWrapperProps): React.ReactElement => {
    const [className] = useState('App-' + new Date().getTime());

    const navigate = useNavigate();
    const location = useLocation()

    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const systemContext = React.useContext(SystemContext)
    const blastContext = React.useContext(BlastContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const [refreshCounter, setRefreshCounter] = useState<number>(() => {
        return new Date().getTime()
    })

    const customTheme = useAppSelector(state => state.uiSlice.customTheme);
    const sliceTheme = useAppSelector(state => state.uiSlice.theme);
    const savedTheme = localStorage.getItem('theme') || 'default';
    const theme = savedTheme ? savedTheme : sliceTheme;
    const isCustomThemeSelected = localStorage.getItem('isCustomThemeSelected')
    const [animationDone, setAnimationDone] = React.useState(false)

    const [loaded, setLoaded] = useState<boolean>(false);
    const [hasSession, setHasSession] = useState<boolean>(false);
    const [validSession, setValidSession] = useState<boolean>(false);
    const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.LOADING);
    const [homePage, setHomePage] = useState<string | undefined>(undefined);
    const [systemLoaded, setSystemLoaded] = useState<boolean>(false);
    const [cookieProperties, setCookieConsentProperties] = useState<CookieConsentProperties | undefined>(undefined);

    const [routes, setRoutes] = useState<RouteObject []>(() => {
        log.info(className, 'MSG005', 'Initialising pre authenticated routes')
        return [...props.menuService.createPreAuthenticatedRoutes()]
    });
    const [noService, setNoService] = useState<boolean>(false);

    const [mountedMonitor] = useState<IMountedMonitor>(MountedMonitor(className, true));
    const [searchParams, setSearchParams] = useSearchParams();


    const contentProps = useSpring({
        config: config.molasses,
        from: {
            width: '80%',
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    })


    useEffect(() => {

            if (isCustomThemeSelected === null) {
                // @ts-ignore
                import('./static/css/themes/default.css');
            }
            if (isCustomThemeSelected === 'false') {
                import('./static/css/themes/' + theme + '.css');
            }
        },
        [sliceTheme, customTheme])

    useEffect(() => {
            log.info('Router', 'MSG001', 'Page: ' + location.pathname)
        },
        [location])

    RunOnceEffect(() => {
        log.setSessionContext(sessionContext)

        log.info(className, 'MSG001', '-- App Init --')
        splashScreenService.showSplash().then(() => {
        }).catch((reason: any) => {
        })

        if (props.ignoreClasses) {
            log.setIgnoreClasses(props.ignoreClasses)
        }
    });

    RunOnceEffect(() => {
        let subscription = sessionContext.watchLogout().subscribe((count: number) => {
            if (count > 0) {
                console.log('--- logged out ---')
                setValidSession(false)
                setHasSession(false)
                setLoaded(true)
                setSystemLoaded(false)
            }
        });
        return () => {
            unSubscribe(subscription)
        }

    });

    RunOnceEffect(() => {
        let subscription = environmentContext.watchEnvironment().subscribe((environment: IEnvironment) => {
            if (environment.loaded) {
                const cohortId = searchParams.get("cohortId") || undefined
                if (notBlank(cohortId)) {
                    // @ts-ignore
                    guslStorage.setCohortId(cohortId)
                }

                if (!Capacitor.isNativePlatform()) {
                    setCookieConsentProperties(environment.cookieConsent)
                }

                splashScreenService.hideSplash().then(() => {
                }).catch((reason: any) => {
                })
                setHomePage(environment.homePage);
            }
        });
        let noServiceSubscription = environmentContext.watchNoService().subscribe((_noService: boolean) => {
            setNoService(_noService)
        })
        let notificationSubscription = blastContext.observeNotifications().subscribe((notification: SystemNotificationDTO) => {
            toast(extractToastMessage(notification), extractToastOptions(notification))
        });
        let systemNotificationSubscription = systemContext.watchSystemNotifications().subscribe((notification: SystemNotificationDTO | undefined) => {
            log.debug(className, 'MSG001', 'Notification', notification)
            if (notification) {
                toast(extractToastMessage(notification), extractToastOptions(notification))
            }
        });
        let loaderSubscription = systemContext.watchSystemLoaded().subscribe((systemLoaded: boolean) => {
            setSystemLoaded(systemLoaded);
            if (systemLoaded) {
                log.setSystemLoaded()
                setRoutes(props.menuService.createPostAuthenticatedRoutes(systemContext.getSystemConfig()?.menuGroups || []))
            }
        });
        sessionContext.setOriginalPath(props.location?.pathname || '/');
        return () => {
            unSubscribe(subscription)
            unSubscribe(noServiceSubscription)
            unSubscribe(notificationSubscription);
            unSubscribe(loaderSubscription);
            unSubscribe(systemNotificationSubscription);
        }
    })

    // let mountedMonitor = MountedMonitor(className,true);

    const routing = useRoutes(routes);
    useEffect(() => {
        sessionContext.setOriginalPath(props.location?.pathname || '/');

        const _routes = systemLoaded
            ? props.menuService.createPostAuthenticatedRoutes(systemContext.getSystemConfig()?.menuGroups || [])
            : props.menuService.createPreAuthenticatedRoutes()
        setRoutes(_routes);

        const userHomePage: string | undefined = sessionContext.getHomePage() || homePage;
        const redirect = props.menuService.redirectTo(sessionContext.getOriginalPath(),
            validSession, hasSession, loaded, systemLoaded, userHomePage);

        log.info(className, 'MSG010', 'Redirect to: [' + redirect + '] using params ',
            'path: ' + sessionContext.getOriginalPath() +
            ' validSession: ' + validSession +
            ' hasSession: ' + hasSession +
            ' loaded: ' + loaded +
            ' systemLoaded: ' + systemLoaded +
            ' homePage: ' + userHomePage)

        // console.log('Redirect to: [' + redirect + '] using params ',
        //     'path: ' + sessionContext.getOriginalPath() +
        //     ' validSession: ' + validSession +
        //     ' hasSession: ' + hasSession +
        //     ' loaded: ' + loaded +
        //     ' systemLoaded: ' + systemLoaded +
        //     ' homePage: ' + userHomePage)

        let nonAuthPage: boolean = false
        for (const origKey in UI_CONSTANTS) {
            if (UI_CONSTANTS.hasOwnProperty(origKey)) {
                if (UI_CONSTANTS[origKey] !== '/' && UI_CONSTANTS[origKey] !== '/login' && UI_CONSTANTS[origKey] === sessionContext.getOriginalPath()) {
                    nonAuthPage = true;
                }
            }
        }

        sessionContext.setQueryParams(searchParams?.get('query'))

        // console.log(`navigate to => loaded: ${loaded} validSession: ${validSession} systemLoaded: ${systemLoaded} nonAuthPage: ${nonAuthPage} originalPath: ${sessionContext.getOriginalPath()} sessionStatus: ${sessionStatus}`)

        if (systemLoaded && nonAuthPage) {
            navigate(sessionContext.getOriginalPath());
        } else if (systemLoaded && redirect) { // && validSession
            navigate(redirect);
        } else if (sessionStatus === SessionStatus.INVALID) {
            navigate('/login');
        }

    }, [validSession, hasSession, loaded, systemLoaded, homePage, sessionStatus]);

    RunOnceEffect(() => {
        window.scrollTo(0, 0)
        i18n
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
                resources: props.translationResource
            }).then(() => {
        });

        i18n.changeLanguage(props.defaultLanguage).then(() => {
        });
    });

    RunOnceEffect(() => {
        let subscription = sessionContext.watchSessionStatus().subscribe((latestSessionStatus: SessionStatus) => {
            log.info(className, 'MSG002', "session status change from " + sessionStatus + " to ", latestSessionStatus)
            if (mountedMonitor.isMounted()) {
                if (latestSessionStatus !== sessionStatus) {
                    setSessionStatus(latestSessionStatus);
                    log.info(className, 'MSG003', "session status change", latestSessionStatus)
                    switch (latestSessionStatus) {
                        case SessionStatus.LOADING:
                        case SessionStatus.REQUESTING:
                            break;
                        case SessionStatus.INVALID:
                            setHasSession(false)
                            setLoaded(true)
                            setValidSession(false)
                            break;
                        case SessionStatus.VALID:
                            setHasSession(true)
                            setLoaded(true)
                            setValidSession(true)
                            break;
                    }
                }
            }
        });

        return () => {
            unSubscribe(subscription)
        }
    });

    useTimeout(() => {
        setAnimationDone(true)
    }, 1000)


    const renderCookieText = (): React.ReactElement => {

        const onPolicyClick = (policy: PolicyLink) => {
            window.open(policy.href);
        }
        if (!cookieProperties?.policy) {
            return <span>{cookieProperties?.text || ''}</span>
        }
        const policy = cookieProperties?.policy
        return (
            <CookieConsentStyled key={policy.key}>
                {replaceStringWithElement(cookieProperties?.text || '', '{' + policy.key + '}', (policyKey: string, i: number) =>
                    <PolicyLinkStyled onClick={() => {
                        onPolicyClick(policy)
                    }} key={policy.key}>{policy.label}   </PolicyLinkStyled>
                )}
            </CookieConsentStyled>
        )
    }


    const renderCookieConsent = (): React.ReactElement => {
        if (!cookieProperties) {
            return <></>
        }
        return (
            <CookieConsent
                location={cookieProperties?.location || 'bottom'}
                buttonText={cookieProperties?.buttonText || 'Accept'}
                cookieName={cookieProperties?.cookieName || 'GuslPolicy'}
                expires={cookieProperties?.expires || 150}
            > {renderCookieText()}
            </CookieConsent>
        )
    }
    if (noService) {
        return <>
            <p>no service</p>
        </>
    } else if (animationDone && loaded) {
        return <>
            <ErrorBoundary>
                <FieldComponentLoader key={'loader_' + refreshCounter}/>
                <ExternalAppStyled
                    className={environmentContext.getDeviceClassname() + ' ' + environmentContext.getStoragePrefix() + ' ' + environmentService.getEnvironment()?.layout || ''}
                    systemLoaded={systemLoaded}
                    coverUrl={environmentService.getEnvironment()?.coverUrl} id={'app'}>
                    {routing}
                    <ToastContainer/>
                    {renderCookieConsent()}
                </ExternalAppStyled>
            </ErrorBoundary>
        </>
    } else {
        return <>
            <ErrorBoundary>
                <FieldComponentLoader key={'loader_' + refreshCounter}/>
                <SplashContainerStyled>
                    <animated.div style={contentProps}>
                        <LogoStyled
                            src={addImageSuffix(environmentService.getEnvironment()?.splashLogoUrl, guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix)}
                            alt="loading"/>
                    </animated.div>
                    <SplashWrapperStyled>
                        <SpinnerContainerStyled/>
                    </SplashWrapperStyled>
                </SplashContainerStyled>
            </ErrorBoundary>
        </>
    }
}

export default App;
