import styled, {ThemeProps} from 'styled-components'
import {
    CONTENT_START_POSITION,
    LHM_WIDTH,
    RELEASE_DETAILS_FOOTER_HEIGHT
} from '../../providers/theme/GuslThemeProvider';

export const LogoStyled = styled.img`
  width: 80%;
`

interface MainContainerProperties extends ThemeProps<any> {
    $extraClassName: string | undefined
}

export const MainContainer = styled.main.attrs((props: MainContainerProperties) => ({
    className: props.$extraClassName ? props.$extraClassName : '',
}))<MainContainerProperties>`
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  background-color: ${props => props.theme.modal.background};

  .login {
    background-color: ${props => props.theme.login.innerBackground};
  }

`
export const ForgottenDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.4em;
  cursor: pointer;

  h1 {
    color: ${props => props.theme.login.linkColor};
  }

`

interface LeftHandMenuProperties extends ThemeProps<any> {
    $isSideNavOpen: boolean;
    isIOS: boolean;
}


export const ContentWrapperStyled = styled.div.attrs(props => ({
    className: "row g-0",
}))`
  padding: 0;
`
// d-none d-lg-block  mt-2
export const LeftHandWrapperStyled = styled.div.attrs((props: LeftHandMenuProperties) => ({
    className: props.$isSideNavOpen ? 'col-lg-2 ' : '',
}))<LeftHandMenuProperties>`

`

export const MainContentWrapperStyled = styled.div.attrs((props: LeftHandMenuProperties) => ({
    className: props.$isSideNavOpen ? 'col-lg-10' : 'col-lg-12',
}))<LeftHandMenuProperties>`
  min-height: ${props => 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + CONTENT_START_POSITION + 'px)'};
  padding: 3px 0 0 0;
  position: fixed;
  top: ${props => CONTENT_START_POSITION}px;
  left: 0;
  transform: ${props => props.$isSideNavOpen ? 'translateX(' + LHM_WIDTH + 'px)' : 'translateX(0)'};
  transition: transform 0.25s ease;
`

export const ContentContainerStyled = styled.div.attrs(props => ({
    className: "mx-1 mx-sm-4",
}))`
  padding-bottom: 30px;
`


export const WarningMessageTitleStyled = styled.h1`
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
`
export const WarningMessageContainerStyled = styled.div`
  color: ${props => props.theme.colors.background[0]};
  background-color: ${props => props.theme.colors.warning};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  line-height: 1.3em;
`

export const StyledHeader = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-start align-items-baseline row g-0'
}))`
  height: 80px;
  border-bottom: ${props => '1px solid ' + props.theme.navigation.sideMenu.footer.color}
`
export const StyledHeaderMobile = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-start align-items-center row g-0'
}))`
  height: 60px;
  border-bottom: ${props => '1px solid ' + props.theme.colors.accentColorMuted};
  margin-bottom: 5px;

`
export const StyledAvatar = styled.div.attrs((props) => ({
    className: 'col-3 d-flex justify-content-center align-items-center'
}))`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 10px;
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};
  background-color: ${props => props.theme.navigation.sideMenu.header.avatar.bgColor};
  font-size: 14px;
  font-weight: bold;


`
export const StyledInitials = styled.div.attrs((props) => ({
    className: 'col-3 ' +
        ''
}))`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #a89362;
  margin: 0 10px;
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};
  background-color: ${props => props.theme.navigation.sideMenu.header.avatar.bgColor};

  font-size: 24px;
  font-weight: bold;

`
export const StyledInitial = styled.div.attrs((props) => ({
    className: 'col-2'
}))`
  text-align: center;
  vertical-align: middle;
  margin-top: 7px;

`

export const StatusWrapperStyled = styled.div.attrs((props) => ({}))`
  font-size: 16px;
  padding-top: 5px;
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};

`

export const VerifyStyle = styled.div.attrs((props) => ({}))`
  display: inline-block;
  background-color: var(--negative);
  color: #fff;
  border-radius: 5px;
  padding: 4px;
`

export const EnabledStyle = styled.div.attrs((props) => ({}))`
  display: inline-block;
  background-color: var(--positive);
  color: #fff;
  border-radius: 5px;
  padding: 4px;
`


export const StyledAvatarName = styled.span.attrs((props) => ({
    className: ''
}))`
  font-size: 14px;
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  //white-space:nowrap;
  width: calc(100% - 90px);
  word-wrap: break-word;
`

export const StyledAccountType = styled.div.attrs((props) => ({}))`
  font-size: 18px;
  vertical-align: middle;
`

export const StyledFooter = styled.div.attrs((props) => ({}))`
  width: 100%;
  font-size: 12px;
  vertical-align: middle;
  height: 30px;
  margin: -5px;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  background-color: ${props => props.theme.navigation.sideMenu.footer.bgColor};
  color: ${props => props.theme.navigation.sideMenu.footer.color};
`

/*
  display:  ${props => {
    if (props.$isSideNavOpen) {
        return 'flex'
    } else {
        return 'flex'
    }
}};

 */

export const StyledLeftHandMenu = styled.nav.attrs((props: LeftHandMenuProperties) => ({}))<LeftHandMenuProperties>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: ${props => props.theme.navigation.sideMenu.bgColor};;
  border-right: solid 1px ${props => props.theme.navigation.sideMenu.rightBorderColor};
  height: ${props => 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + CONTENT_START_POSITION + 'px)'};
  text-align: left;
  padding: 5px;
  position: fixed;
  top: ${props => CONTENT_START_POSITION}px;
  left: 0;
  transform: ${props => props.$isSideNavOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.25s ease;
  width: ${props => LHM_WIDTH}px;
`

interface LeftHandMenuWrapperProperties extends ThemeProps<any> {
    isIOS: boolean;
    startPos: number,
}

// 170
export const LeftHandMenuWrapperStyled = styled.div.attrs((props: LeftHandMenuWrapperProperties) => ({}))<LeftHandMenuWrapperProperties>`
  overflow-y: auto;
  height: ${props => 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + RELEASE_DETAILS_FOOTER_HEIGHT) + 'px);'};

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};
  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;
  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;

`

export const StyledPageFooter = styled.div.attrs((props) => ({}))`
  width: 100%;
  font-size: 12px;
  vertical-align: middle;
  height: 30px;
  margin: -5px;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  background-color: ${props => props.theme.navigation.sideMenu.footer.bgColor};
  color: ${props => props.theme.navigation.sideMenu.footer.color};
`
