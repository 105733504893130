import styled from 'styled-components';

export const IconPlaceholderStyled = styled.span.attrs((props ) => ({
}))`
  float: right;
  padding-left: 10px;
  padding-bottom: 2px;
  color: ${props => props.theme.table.columnColor};
  border-left: ${props => '1px solid ' + props.theme.table.columnColor} !important;
  // background-color: var(--widget-blue) !important;
  
  // background-color:${props => props.theme.table.columnColor};
`
