import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {isNumber, safeStream} from "../../../utils/Utils";
import {RangeQueryDO} from "../../charts/types";
import {FieldConfigDTO, InQueryDTO, MatchQueryDTO, OrderByDTO, QueryParamsDTO, RangeQueryDTO} from '../../types';
import paginationService, {ROWS_PER_PAGE} from './PaginationService';


// -------------- actions

export interface QueryParamsInitPayload {
    code: string;
    queryParams: QueryParamsDTO;
}

export interface GuslTableInitialOrderBysPayload {
    code: string;
    initialOrderBys: OrderByDTO | undefined;
}

export interface QueryParamsUpdatePayload {
    code: string;
    queryParams: QueryParamsDTO;
}

export interface ChangePaginationRows {
    code: string;
    limit: number;
}


// -------------- states

interface GuslQueryParamState {
    [id: string]: GuslTableQueryParamState;
}

const initialState: GuslQueryParamState = {};

export interface GuslTableQueryParamState {
    code: string,
    queryParams: QueryParamsDTO;

    initialOrderBys: OrderByDTO | undefined;
}

// --------------- queryBuilder
export interface QueryBuilderMatchQueryPayload {
    code: string;
    field: string;
    value: string;
}

export interface QueryBuilderNumberRangeQueryPayload {
    code: string;
    field: string;
    from?: number;
    to?: number,
    type?: RangeQueryDO["type"]
}

export interface CurrentQueryBuilderRangeQueryPayload {

    field: string;
    from?: string | number | Date | undefined;
    to?: string | number | Date | undefined;
    type?: RangeQueryDO["type"];
}

export interface CurrentQueryBuilderNumberRangeQueryPayload {

    field: string;
    from?: number | Date | undefined;
    to?: number | Date | undefined;
    type?: RangeQueryDO["type"];
}

export interface QueryBuilderResetSingleNumberRangeQueryPayload {
    code: string;
    field: string;

}

// MK 23/08/2023
export interface QueryBuilderResetSingleInPayload extends QueryBuilderResetSingleNumberRangeQueryPayload {
}

export interface QueryBuilderResetSingleDateRangePayload {
    code: string;
    field: string;

}

export interface QueryBuilderShouldQueryPayload {
    code: string;
    fields: string[];
    value: string;
}

export interface QueryBuilderRangeQueryPayload {
    code: string;
    rangeQuery: RangeQueryDTO;
}

export interface QueryBuilderResetFiltersPayload {
    code: string;
}

export interface RemoveFiltersPayload {
    code: string;
    fieldName: string;
}

export interface AddFiltersPayload {
    code: string;
    fieldName: string;
    values: string [];
}


export interface QueryBuilderSearchablePayload {
    code: string;
    fields: FieldConfigDTO[];
    searchValue: string;
    fuzzy?: boolean;
}

export interface QueryBuilderResetSearchablePayload {
    code: string;
}

export interface QueryBuilderRefreshQueryPayload {
    code: string;
}

export interface QueryBuilderFuzzyPayload {
    code: string;
}


const createDefault = (code: string): GuslTableQueryParamState => {
    return {
        code: code,
        queryParams: paginationService.blankQueryParam(),
        initialOrderBys: undefined
    };
};


// ----------- function
const getQueryParamState = (state: GuslQueryParamState, code: string): GuslTableQueryParamState => {
    let entry: GuslTableQueryParamState = state[code];
    if (!entry) {
        entry = createDefault(code);
    }
    return entry;
};

const isFieldTypeSearchable = (field: FieldConfigDTO, isNum: boolean) => {
    switch (field.type) {
        case 'text' :
        case 'html' :
            return true;
        case 'number' :
            return isNum;
    }
    return false;
}


const updateSearchable = (entry: GuslTableQueryParamState, fields: FieldConfigDTO[], searchValue: string, fuzzy?: boolean | undefined) => {
    const isNum: boolean = isNumber(searchValue);
    const matchQueries: MatchQueryDTO[] = [];
    const _fuzzy: boolean = typeof fuzzy === "undefined" ? true : typeof entry.queryParams.fuzzy === "undefined" ? true : fuzzy ? fuzzy : entry.queryParams.fuzzy;

    fields.filter(fld => fld.searchableSelected)
        .filter(fld => isFieldTypeSearchable(fld, isNum))
        .forEach(fld => matchQueries.push({
            field: fld.name,
            value: searchValue,
            fuzzy: _fuzzy
        }));
    entry.queryParams.should = matchQueries;
    //entry.queryParams.musts = matchQueries;
    entry.queryParams.searchString = searchValue;
    entry.queryParams.skip = 0;
};

const clearSearchable = (entry: GuslTableQueryParamState) => {
    entry.queryParams.should = [];
    //entry.queryParams.musts = [];
    entry.queryParams.searchString = "";
};


export const queryParamsSlice = createSlice({
    name: "queryParamsSlice",
    initialState,
    reducers: {
        initQueryParams: (state, action: PayloadAction<QueryParamsInitPayload>) => {
            let entry: GuslTableQueryParamState = state[action.payload.code];
            if (!entry) {
                entry = createDefault(action.payload.code);
                entry.queryParams = action.payload.queryParams;
            } else {
                // reset previous - comment to keep old settings
                // entry.queryParams = action.payload.queryParams
            }
            state[action.payload.code] = entry;
        },
        updateQueryParams: (state, action: PayloadAction<QueryParamsUpdatePayload>) => {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams = action.payload.queryParams;
            state[action.payload.code] = entry;
        },
        changePaginationRows: (state, action: PayloadAction<ChangePaginationRows>) => {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.limit = action.payload.limit;
            entry.queryParams.skip = 0;
            state[action.payload.code] = entry;
        },

        setMustNot(state, action: PayloadAction<QueryBuilderMatchQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);

            if (!entry.queryParams.mustNots) {
                entry.queryParams.mustNots = [{field: action.payload.field, value: action.payload.value}];
            } else {
                entry.queryParams.mustNots?.push({field: action.payload.field, value: action.payload.value});
            }
            state[action.payload.code] = entry;

        },
        resetMustNot(state, action: PayloadAction<QueryBuilderMatchQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.mustNots = entry.queryParams.mustNots?.filter(arrayItem =>
                (arrayItem.value !== action.payload.value));
            state[action.payload.code] = entry;

        },
        handleMustNots(state, action: PayloadAction<QueryBuilderMatchQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);

            function alreadyIn(action: PayloadAction<QueryBuilderMatchQueryPayload>) {
                return entry.queryParams?.mustNots?.filter(
                    (field) => (field.field === action.payload.field && field.value === action.payload.value)).length === 1;
            }

            if (!entry.queryParams.mustNots) {
                entry.queryParams.mustNots = [{field: action.payload.field, value: action.payload.value}];
            } else if (alreadyIn(action)) {
                entry.queryParams.mustNots = entry.queryParams.mustNots?.filter(arrayItem =>
                    (arrayItem.value !== action.payload.value));

            } else {
                entry.queryParams.mustNots.push({field: action.payload.field, value: action.payload.value});
            }
            // entry.queryParams.musts = [];

            state[action.payload.code] = entry;

        },
        // MK 11/08/2023 new text input multi option select
        handleIns(state, action: PayloadAction<QueryBuilderMatchQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            const value: string = action.payload.value;
            const field: string = action.payload.field;

            let currentIn: InQueryDTO | undefined = entry.queryParams?.ins?.filter(_in => _in.field === field)[0];

            function alreadyIn() {
                return currentIn?.values.includes(value);
            }

            // first to add
            if (entry.queryParams.ins?.length === 0) {
                entry.queryParams.ins = [{field: field, values: [value]}];
                // removing single value from "in.values" and possibly full "in" if it is the last value to be removed
            } else if (currentIn && alreadyIn()) {
                const filteredOutValues: string[] = currentIn?.values.filter(_value => _value !== value) || [];
                // remove the full in first
                entry.queryParams.ins = entry.queryParams.ins?.filter(_in =>
                    (_in.field !== field));
                // if filteredOutValues.length > 0 => we have some values for currentIn  left after removing current value
                if (filteredOutValues.length > 0) {
                    // @ts-ignore
                    // if there are some other "ins" push updated "in"
                    if (entry.queryParams.ins?.length > 0) {
                        entry.queryParams.ins?.push({field, values: filteredOutValues});
                    }
                    // if there are no "ins", create first "in"
                    else {
                        entry.queryParams.ins = [{field, values: filteredOutValues}];
                    }
                }
            }
            // new value for current "in"
            else if (currentIn && !alreadyIn()) {
                const newAddedValues: string[] = [...currentIn?.values, value];
                const updatedIn: InQueryDTO | undefined = {field, values: newAddedValues};

                if (updatedIn) {
                    // filter out current
                    entry.queryParams.ins = entry.queryParams.ins?.filter(_in =>
                        (_in.field !== field));
                    // push updated
                    entry.queryParams.ins?.push(updatedIn);
                }
            }
            // next "in"; 2nd, 3rd....
            else {
                entry.queryParams.ins?.push({field: field, values: [value]});
            }

            state[action.payload.code] = entry;

        },
        resetSingleIn(state, action: PayloadAction<QueryBuilderResetSingleInPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            const field: string = action.payload.field;
            entry.queryParams.ins = entry.queryParams.ins?.filter(_in =>
                (_in.field !== field));
            state[action.payload.code] = entry;
        },
        resetSingleNumberRange(state, action: PayloadAction<QueryBuilderResetSingleNumberRangeQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.rangeQueries = entry.queryParams?.rangeQueries?.filter(
                (field) => (field.field !== action.payload.field));
            state[action.payload.code] = entry;
        },

        handleNumberRangeQueries(state, action: PayloadAction<QueryBuilderNumberRangeQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);

            const currentPayload: CurrentQueryBuilderNumberRangeQueryPayload = {
                field: action.payload.field,
                from: action.payload.from,
                to: action.payload.to,
                type: "NUMBER"
            };

            function alreadyIn(action: PayloadAction<QueryBuilderNumberRangeQueryPayload>) {
                return entry.queryParams?.rangeQueries?.filter(
                    (field) => (field.field === action.payload.field)).length === 1;
            }

            if (!entry.queryParams?.rangeQueries) {
                entry.queryParams.rangeQueries = [currentPayload];
            } else if (alreadyIn(action)) {
                entry.queryParams.rangeQueries = entry.queryParams?.rangeQueries?.filter(
                    (field) => (field.field !== currentPayload.field));
                entry.queryParams.rangeQueries?.push(currentPayload);
            } else {
                entry.queryParams.rangeQueries.push(currentPayload);
            }

            state[action.payload.code] = entry;

        },
        setSearchMusts(state, action: PayloadAction<QueryBuilderMatchQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.musts = [{field: action.payload.field, value: action.payload.value}];
            state[action.payload.code] = entry;
        },
        resetSearchMusts(state, action: PayloadAction<QueryBuilderResetFiltersPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.musts = [];
            entry.queryParams.searchString = "";
            state[action.payload.code] = entry;
        },
        setShould(state, action: PayloadAction<QueryBuilderShouldQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.should = [];
            action.payload.fields.forEach((field) => entry.queryParams.should?.push({
                field,
                value: `${action.payload.value}`
            }));
            state[action.payload.code] = entry;
        },
        handleRangeQueries(state, action: PayloadAction<QueryBuilderRangeQueryPayload>) {

            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);

            const currentPayload: CurrentQueryBuilderRangeQueryPayload = {
                field: action.payload.rangeQuery.field,
                from: action.payload.rangeQuery.from,
                to: action.payload.rangeQuery.to,
                type: "DATE"
            };

            function alreadyIn(action: PayloadAction<QueryBuilderRangeQueryPayload>) {
                return entry.queryParams?.rangeQueries?.filter(
                    (field) => (field.field === action.payload.rangeQuery.field)).length === 1;
            }

            if (!entry.queryParams?.rangeQueries) {
                entry.queryParams.rangeQueries = [currentPayload];
            } else if (alreadyIn(action)) {
                entry.queryParams.rangeQueries = entry.queryParams?.rangeQueries?.filter(
                    (field) => (field.field !== currentPayload.field));
                entry.queryParams.rangeQueries?.push(currentPayload);
            } else {
                entry.queryParams.rangeQueries.push(currentPayload);
            }

            state[action.payload.code] = entry;


        },
        // MK 14/08/2023
        resetSingleDateRange(state, action: PayloadAction<QueryBuilderResetSingleDateRangePayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.rangeQueries = entry.queryParams?.rangeQueries?.filter(
                (field) => (field.field !== action.payload.field));
            state[action.payload.code] = entry;
        },
        // END OF 14/08/2023
        resetFilters(state, action: PayloadAction<QueryBuilderResetFiltersPayload>) {
            let entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.should = [];
            entry.queryParams.mustNots = [];
            entry.queryParams.musts = [];
            entry.queryParams.searchString = "";
            entry.queryParams.rangeQueries = [];
            entry.queryParams.resetAt = new Date().getTime();
            entry.queryParams.fuzzy = true;
            if (entry.initialOrderBys) {
                entry.queryParams.orderBys = [entry.initialOrderBys];
            } else {
                entry.queryParams.orderBys = [];
            }
            // MK 11/08/2023 look-up text input search in filters
            entry.queryParams.ins = [];

            // MK 27/09/2023
            entry.queryParams.limit = ROWS_PER_PAGE[0];
            entry.queryParams.skip = 0;
            // END OF 27/09/2023

            clearSearchable(entry);

        },
        setInitialOrderBys(state, action: PayloadAction<GuslTableInitialOrderBysPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);

            if (action.payload.initialOrderBys) {
                entry.initialOrderBys = action.payload.initialOrderBys;
            }
        },
        setSearchable(state, action: PayloadAction<QueryBuilderSearchablePayload>) {
            let entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            // console.log(action.payload.fields, action.payload.searchValue, action.payload.fuzzy)
            updateSearchable(entry, action.payload.fields, action.payload.searchValue, action.payload.fuzzy);
            state[action.payload.code] = entry;
        },
        resetSearchable(state, action: PayloadAction<QueryBuilderResetSearchablePayload>) {
            let entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            clearSearchable(entry);
            state[action.payload.code] = entry;
        },
        refreshListView(state, action: PayloadAction<QueryBuilderRefreshQueryPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.refresher = (entry.queryParams.refresher || 0) + 1;
            state[action.payload.code] = entry;

        },
        toggleFuzzySearch(state, action: PayloadAction<QueryBuilderFuzzyPayload>) {
            const entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            entry.queryParams.fuzzy = !entry.queryParams.fuzzy;
            state[action.payload.code] = entry;

        },
        removeAllMustNots(state, action: PayloadAction<RemoveFiltersPayload>) {
            let entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);
            const mustNots: MatchQueryDTO[] = [];
            entry.queryParams.mustNots?.filter(arrayItem =>
                (arrayItem.field !== action.payload.fieldName))
                .forEach(arrayItem => mustNots.push({field: arrayItem.field, value: arrayItem.value}));
            entry.queryParams.mustNots = mustNots;
            // MK 04-01-2024
            entry.queryParams.musts = [];
            state[action.payload.code] = entry;
        },
        addAllMustNots(state, action: PayloadAction<AddFiltersPayload>) {
            let entry: GuslTableQueryParamState = getQueryParamState(state, action.payload.code);

            function alreadyIn(fieldName: string, value: any) {
                return entry.queryParams?.mustNots?.filter(
                    (field) => (field.field === fieldName && field.value === value)).length === 1;
            }

            safeStream(action.payload.values).filter(value => !alreadyIn(action.payload.fieldName, value))
                .forEach(value => {
                    if (!entry.queryParams.mustNots) {
                        entry.queryParams.mustNots = [{field: action.payload.fieldName, value: value}];
                    } else {
                        entry.queryParams.mustNots?.push({field: action.payload.fieldName, value: value});
                    }
                });
            // MK 04-01-2024
            entry.queryParams.musts = [];

            state[action.payload.code] = entry;
        }


    }
});

export const {
    initQueryParams,
    updateQueryParams,
    changePaginationRows,
    setMustNot,
    resetMustNot,
    setSearchMusts,
    resetSearchMusts,
    handleMustNots,
    setShould,
    handleRangeQueries,
    resetFilters,
    refreshListView,
    setSearchable,
    resetSearchable,
    handleNumberRangeQueries,
    resetSingleNumberRange,
    toggleFuzzySearch,
    removeAllMustNots,
    addAllMustNots,
    setInitialOrderBys,
    handleIns,
    resetSingleDateRange,
    resetSingleIn
} = queryParamsSlice.actions;


export default queryParamsSlice.reducer;
