import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useAppDispatch} from "../../../app/hooks";
import {ActionConfigDTO, TableRowDTO} from '../../types';
import {DraggableModalDialog} from '../draggable-modal/draggable-modal';
import MaintainForm from '../maintain-form/MaintainForm';
import {maintainTableService} from '../maintain-table/MaintainTableService';
import {setOpenModalActionId, setOpenModalRowId} from "./actionSlice";
import {ActionDialogBodyStyled, ActionDialogHeaderStyled, ActionDialogTitleStyled} from './styled';

type Props = {
    action: ActionConfigDTO | undefined,
    cannotClose?: boolean
    data?: TableRowDTO,
    onHide?: () => void,
    closeDialog?: () => void,
    refreshTable?: () => void,
    selectedRows?: { [id: string]: TableRowDTO } | undefined
}
const ActionDialog = ({
                          action,
                          data,
                          onHide,
                          closeDialog,
                          refreshTable,
                          cannotClose,
                          selectedRows
                      }: Props): React.ReactElement => {
    // const [className] = useState('ActionDialog-' + new Date().getTime());
    const [rowData] = useState<TableRowDTO>(data || {id: '1'} as TableRowDTO);
    const dispatch = useAppDispatch();
    if (action?.minModalWidth || action?.minModalHeight) {
        let root = document.documentElement;
        if (action?.minModalWidth) {
            if (action.minModalWidth.indexOf('px') > 0) {
                root.style.setProperty('--modal-width', (parseInt(action.minModalWidth.replace('px', ''), 10) + 10) + 10 + 'px');
            } else {
                root.style.setProperty('--modal-width', action.minModalWidth);
            }
        }
        if (action?.minModalHeight) {
            root.style.setProperty('--modal-height', action.minModalHeight);
        }
    }

    const canClose = cannotClose === undefined ? true : cannotClose

    function resetOpenModalActionHandler() {
        dispatch(setOpenModalActionId(""));
        dispatch(setOpenModalRowId(""));
    }

    let formData: any = {}
    if (selectedRows) {
        formData.rows = Object.values(selectedRows)
        formData.details = {}
    }
    // keyboard={!canClose}
    return (
        <Modal
            dialogAs={DraggableModalDialog}
            centered
            show={true}
            keyboard={false}
            onHide={() => {
                onHide && onHide()
                // resetOpenModalActionHandler()
            }}
            // backdrop={!canClose && 'static'}
            backdrop={cannotClose ? 'static' : true}
            dialogClassName="modal-wrapper"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <ActionDialogHeaderStyled closeButton={!cannotClose}>
                <ActionDialogTitleStyled>{maintainTableService.getTitle(rowData, action?.popUpTitle || '')}</ActionDialogTitleStyled>
            </ActionDialogHeaderStyled>
            <ActionDialogBodyStyled onMouseDown={(e:MouseEvent) => e.stopPropagation}>
                <>
                    {action && <MaintainForm
                        isDialog={true}
                        controlOnTop={false}
                        canEdit={maintainTableService.getCanEditForAction(action)}
                        formMode={maintainTableService.getFormModeForAction(action)}
                        rowData={{...rowData}}
                        data={formData}
                        menuItem={maintainTableService.convertToMenu(rowData, action)}
                        minModalWidth={action.minModalWidth}
                        minModalHeight={action.minModalHeight}
                        refreshTable={() => {
                            refreshTable && refreshTable()
                        }}
                        closeDialog={() => {
                            closeDialog && closeDialog()
                            //resetOpenModalActionHandler()
                        }}/>}
                </>
            </ActionDialogBodyStyled>
        </Modal>
    )
}
export default ActionDialog;
