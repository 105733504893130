import {Resource} from 'i18next';
import React from 'react';
import {useLocation, useNavigate, useRoutes} from 'react-router-dom';
import App from '../App';
import {log} from '../services/LogService';
import {TRANSLATIONS_EN} from '../translation/en/translations';
import {TRANSLATIONS_ES} from '../translation/es/translations';
import {RunOnceEffect} from '../utils/Utils';
import {menuService} from './MenuService';
import {IAppWrapperProps} from './types';

const LOG_IGNORE_CLASSES: { [id: string]: string[] } = {
    'App': [],
    'AppWrapper': ['MSG001', 'MSG500'],
    'BlastService': [],
    'BlastProvider': [],
    'SessionProvider': ['MSG001', 'MSG002', 'MSG003'],
    'sessionService': ['MSG001', 'MSG002', 'MSG003'],
    'SessionStorage': [],
    'SystemProvider': [],
    'DataCache': ['MSG001', 'MSG002', 'MSG003', 'MSG004', 'MSG009', 'MSG010', 'MSG100', 'ERR003'],
    'EnvironmentProvider': ['MSG001', 'MSG002', 'MSG003', 'MSG004', 'MSG006'],
    'Login': [],
    'MaintainTableDataService': [],
    'MaintainTableService': [],
    'MaintainTable': [],
    'ReportUtils': [],
    'NestedTableField': [],
    'Table': [],
    'GuslTable': [],
    'MainToolbar': [],
    'MobileNav': [],
    'DateField': [],
    'TextField': [],
    'OptionField': [],
    'MultiSelectField': [],
    'MenuService': [],
    'LoginUsernamePassword': [],
    'MGQuery': [],
    'LeftHandMenu': [],
    'SiteTabs': [],
    'TableRow': [],
    'Report': [],
    'MainToolbarDropdown': []
}
const appWrapperProperties: IAppWrapperProps = {
    menuService: menuService,
    ignoreClasses: LOG_IGNORE_CLASSES,
    defaultLanguage: 'en',
    translationResource: {
        en: {
            translation: TRANSLATIONS_EN
        },
        es: {
            translation: TRANSLATIONS_ES
        }
    } as Resource
}

const AppWrapper = (props: JSX.IntrinsicAttributes): React.ReactElement => {
    const [className] = React.useState<string>(() => 'AppWrapper-' + new Date().getTime());
    RunOnceEffect(() => {
        log.info(className, 'MSG001', '-- AppWrapper Init --')
    });

    const allProps = {...props, ...appWrapperProperties}
    return (<App {...allProps}/>)
}

export const wrap = (Component: any) => {
    return (props: JSX.IntrinsicAttributes) => {

        const allProps = {
            ...props,
            ...appWrapperProperties,
            location: useLocation(),
            navigate: useNavigate(),
            useRoutes: useRoutes
        }
        return <Component {...allProps} />;
    }
}


export default wrap(AppWrapper)
