import {AxiosResponse} from 'axios';
import {UserAgentApplication} from 'msal';
import React from 'react';
import {Observable} from 'rxjs'
import {EndpointDTO, QueryParamsDTO} from '../../components/types';
import {QrCodeResponseDTO} from '../../pages/login/types';
import {GuslUser, JWTTokens, SessionContextProps, SessionStatus, SignInResponseDTO, SignOutResponseDTO} from './types';


const createDefault = (): SessionContextProps => ({
    hasNoSession: (): boolean => {
        return true;
    },
    loginWithGoogleSsoToken: (): Promise<SignInResponseDTO> => {
        return new Promise<SignInResponseDTO>(resolve => resolve({} as SignInResponseDTO));
    },
    loginWithMsalSsoToken: (): Promise<SignInResponseDTO> => {
        return new Promise<SignInResponseDTO>(resolve => resolve({} as SignInResponseDTO));
    },
    renewSession: (): Promise<SignInResponseDTO> => {
        return new Promise<SignInResponseDTO>(resolve => resolve({} as SignInResponseDTO));
    },
    clearSession: (): void => {
    },

    getJwtSession: () => {
        return {}
    },

    /* eslint-disable @typescript-eslint/no-unused-vars */
    setJwtSession: (tokens: JWTTokens) => {
    },

    getGoogleToken: (): string => {
        return ''
    },

    /* eslint-disable @typescript-eslint/no-unused-vars */
    setGoogleToken: (token: string) => {
    },

    isGoogle: (): boolean => {
        return false;
    },

    isJwt: (): boolean => {
        return false;
    },

    signIn: (): Promise<SignInResponseDTO> => {
        return new Promise<SignInResponseDTO>(resolve => resolve({} as SignInResponseDTO));
    },
    getQrCode: (): Promise<QrCodeResponseDTO> => {
        return new Promise<QrCodeResponseDTO>(resolve => resolve({} as QrCodeResponseDTO));
    },
    watchLogin: (): Observable<GuslUser | null> => {
        return new Observable<GuslUser | null>((observer) => {
            observer.complete();
        });
    },
    watchTandCUpgradeRequired: (): Observable<boolean> => {
        return new Observable<boolean>((observer) => {
            observer.complete();
        });
    },

    watchLogout: (): Observable<number> => {
        return new Observable<number>((observer) => {
            observer.complete();
        });
    },
    getLoggedInUser: () => {
        return {
            id: '',
            email: '',
            avatar: ''
        };
    },
    watchSystemReady: (): Observable<boolean> => {
        return new Observable<boolean>((observer) => {
            observer.complete();
        });
    },
    watchSessionStatus: (): Observable<SessionStatus> => {
        return new Observable<SessionStatus>((observer) => {
            observer.complete();
        });
    },
    watchTimezone: (): Observable<string> => {
        return new Observable<string>((observer) => {
            observer.complete();
        });
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    get: <Request, Response>(url: string, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            reject('not configured' + url + controller);
        });
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    post: <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {

        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            reject('not configured' + url + body + controller);
        });
    },
    download: <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {

        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            reject('not configured' + url + body + controller);
        });
    },
    upload: <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {

        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            reject('not configured' + url + body + controller);
        });
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    put: <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            reject('not configured' + url + body + controller);
        });
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    sendRequest: <Request, Response>(endpoint: EndpointDTO, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            reject('not configured' + endpoint + body + controller);
        });
    },
    signOut: (): Promise<SignOutResponseDTO> => {
        return new Promise<SignOutResponseDTO>(resolve => resolve({success: false} as SignOutResponseDTO));
    }, setOriginalPath: (pathname: string) => {
    },
    getOriginalPath: (): string => {
        return '';
    },
    getMsalInstance: (): UserAgentApplication | undefined => {
        return undefined;
    },
    updateTheme: (themeId: string, lastUpdated?: string): void => {

    },
    registerRefreshRate: (refreshRate: number) => {
    },

    unregisterRefreshRate: (refreshRate: number) => {
    },

    watchRefreshRate: (): Observable<number> => {
        return new Observable<number>((observer) => {
            observer.complete();
        });
    },
    setQueryParams: (query: string | null | undefined) => {

    },
    getQueryParams: (): QueryParamsDTO | undefined => {
        return undefined
    },
    hasPowerOfAttorney: (): boolean => {
        return false;
    },
    updateUser: (user: GuslUser | undefined) => {
    },
    getHomePage: (): string | undefined => {
        return undefined
    },
    setTimezone: (timezone: string): void => {

    }
})


export const SessionContext = React.createContext<SessionContextProps>(
    createDefault(),
)
