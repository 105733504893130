import styled, {ThemeProps} from 'styled-components';
import SecondaryOttoNav from './SecondaryOttoNav';


export const SecondaryOttoNavContainerStyled = styled.div.attrs((props) => ({
    className: '',
    id:'secondary-nav-container'
}))`
`

export const SecondaryOttoNavContentsStyled = styled.div.attrs((props) => ({
    className: 'd-flex flex-wrap align-items-center justify-content-center justify-content-lg-start pt-2 pb-2',
    id:'secondary-nav-contents'
}))`
`

export const SecondaryTabsStyled = styled.div.attrs((props) => ({
    className: ' col-12 col-lg-auto me-lg-auto mb-2 d-flex justify-content-start align-items-baseline mb-md-0',
    id:'secondary-nav-tabs'
}))`
`

export const SecondaryTabTitleStyled = styled.div.attrs((props) => ({
    className: ' nav-link px-2 fs-4 text-white',
    id:'secondary-nav-title'
}))`
`


interface SecondaryTabProperties {
    active: boolean
}
export const SecondaryTabStyled = styled.div.attrs((props:SecondaryTabProperties) => ({
    className: 'otto-nav-link nav-link px-2 ' +( props.active ? 'active' :'') ,
}))<SecondaryTabProperties>`

`
