import React, {useState} from "react";
import {GuslTableState} from "./guslTableSlice";
import {useAppSelector} from "../../../app/hooks";

interface GetDefaultSearchableFieldsDO {
    code: string
}

export default function GetDefaultSearchableFields({code}: GetDefaultSearchableFieldsDO) {
    const [className] = useState("GetDefaultSearchableFields-" + new Date().getTime());
    // MK 29/08/2023
    // const systemContext = useContext(SystemContext);
    // const menuItem = systemContext.getMenuConfig(code);
    // const allFields: FieldConfigDTO[] | undefined = menuItem?.entityGroup?.dataSources[0].definition.rows[0].columns[0].fields;
    // MK 29/08/2023
    const tableState: GuslTableState = useAppSelector(state => state.guslTableSlice[code])

    return tableState?.allFields?.filter(fld => fld.searchable);
};