import {environmentService} from '../../providers/environment/EnvironmentService';
import {notDefined} from '../../utils/TypeCheckers';
import {safeStream} from '../../utils/Utils';

class WidgetService {

    public fixUrl(url: string): string {
        if (!url.startsWith('/')) {
            return url
        }
        return `${(environmentService.getEnvironment()?.apiBase || '')
            .replace('/rest', '')}${url}?${new Date().getTime()}`

    }


    public loadScripts(elementId: string, externalJsUrls: string[] | undefined, externalCssUrls: string[] | undefined, js: string | undefined, style: string | undefined) {
        const element = document.getElementById(elementId)
        this.loadScriptsOnElement(element, externalJsUrls, externalCssUrls, js, style)
    }

    public loadScriptsOnElement(element: HTMLElement | null, externalJsUrls: string[] | undefined, externalCssUrls: string[] | undefined, js: string | undefined, style: string | undefined) {
        if (notDefined(element)) {
            return
        }

        element = element?.parentElement ? element?.parentElement.parentElement : element

        safeStream(externalJsUrls).forEach((js: string) => {
            const script = document.createElement('script')
            if (element) {
                script.src = this.fixUrl(js)
                script.type = 'scoped'
                element.appendChild(script)
                script.addEventListener('load', () => {
                    console.log(`script: ${js} loaded`)
                })
            }
        })
        // safeStream(externalCssUrls).forEach((css: string) => {
        //     const link = document.createElement('link')
        //     if (element) {
        //         link.href = this.fixUrl(css)
        //         link.rel = 'stylesheet'
        //         link.type = 'scoped'
        //         element.appendChild(link)
        //         link.addEventListener('load', () => {
        //             console.log(`stylesheet: ${css} loaded`)
        //         })
        //     }
        // })

        if (js) {
            const script = document.createElement('script')
            if (element) {
                script.src = js
                script.type = 'scoped'
                element.appendChild(script)
                script.addEventListener('load', () => {
                    console.log(`script:  loaded`)
                })
            }
        }

        // if (style) {
        //     const link = document.createElement('link')
        //     if (element) {
        //         link.href = style
        //         link.rel = 'stylesheet'
        //         link.type = 'scoped'
        //         element.appendChild(link)
        //         link.addEventListener('load', () => {
        //             console.log(`stylesheet: loaded`)
        //         })
        //     }
        // }
    }
}

const widgetService = new WidgetService();

export {widgetService};

export default WidgetService;
