import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface CardPanelMasterState {
    [id: string]: CardPanelState
}

export interface CardPanelState {
    code: string
    opened: boolean
}


const initialState: CardPanelMasterState = {}

export interface PanelOpenInitPayloadAction {
    code: string
}


export interface PanelOpenPayloadAction {
    code: string
    opened: boolean
}

export interface PanelCleanUpPayload {
    code: string
}

const getState = (state: CardPanelMasterState, code: string): CardPanelState => {
    let entry: CardPanelState = state[code]
    if (!entry) {
        entry = {code: code, opened: false}
    }
    return entry;
}

export const cardPanelOpenSlice = createSlice({
    name: 'cardPanelOpenSlice',
    initialState,
    reducers: {
        initCardPanelOpen(state, action: PayloadAction<PanelOpenInitPayloadAction>) {
            state[action.payload.code] = {code: action.payload.code, opened: false}
        },

        toggleCardPanelOpen(state, action: PayloadAction<PanelOpenPayloadAction>) {
            let entry: CardPanelState = getState(state, action.payload.code)
            entry.code = action.payload.code
            entry.opened = action.payload.opened
        },
        cleanUpCardPanelOpen(state, action: PayloadAction<PanelCleanUpPayload>) {
            delete state[action.payload.code]
        },

    }
})

export const {
    initCardPanelOpen,
    toggleCardPanelOpen,
    cleanUpCardPanelOpen
} = cardPanelOpenSlice.actions

export default cardPanelOpenSlice.reducer
