// import {CustomerBalanceDTO} from '../../features/wallet/api/types'
import {BlastCommand} from './types'

export type CustomerLoginBE = {
    sessionToken?: string
    customerToken?: string
}

export class PlayerLoginBO implements BlastCommand {
    public cmd: string = 'auth.customer.login'

    constructor(public sessionToken?: string, public customerToken?: string) {
    }
}

export class ChatMessageBO implements BlastCommand {
    public cmd: string = 'chat.msg'

    constructor(public data: string) {
    }
}

export class TopicSubscribe implements BlastCommand {
    public cmd: string = 'topic.subscribe'

    constructor(public data: string) {
    }
}

export class TopicUnsubscribe implements BlastCommand {
    public cmd: string = 'topic.unsubscribe'

    constructor(public data: string) {
    }
}


export class SystemNotificationBO implements BlastCommand {
    public cmd: string = 'notification.system'

    public static getCommand(): string {
        return 'notification.system'
    }

    constructor(
        public notificationId: number,
        public playerId: number,
        public autoDismissible: boolean,
        public dismissTimeInSec: number,
        public header: string,
        public image: string,
        public content: string,
    ) {
    }
}

// export class CustomerBalanceBO implements BlastCommand {
//     public cmd: string = 'balance.update'
//
//     public static getCommand(): string {
//         return 'balance.update'
//     }
//
//     constructor(public data: CustomerBalanceDTO) {}
// }


export class SimpleMessageBO<T> implements BlastCommand {
    public static getCommand(): string {
        return 'notification.system'
    }

    constructor(
        public cmd: string,
        public data?: T,
        public hasError?: boolean,
        public errorMessage?: string) {
    }
}

export enum DeltaAction {
    INSERT = "INSERT",
    UPDATE = "UPDATE",
    REMOVE = "REMOVE"

}

export interface FieldDeltaDO {
    tableKey: string,
    keyField: string,
    keyValue: string,
    fieldName: string,
    value: any,
    preJs: string,
    postJs: string,
    action: DeltaAction,

}

