import styled from 'styled-components';

export const BreadcrumbWrapperStyled = styled.div.attrs((props) => ({}))`
  display: inline-block;
  text-align: left;
  margin: 0 auto;
  width: fit-content;
`

export const BreadcrumbStyled = styled.div.attrs((props) => ({}))`
  display: inline-block;
`

interface SlashProperties {
    idx: number
}

export const SlashStyled = styled.span.attrs((props: SlashProperties) => ({}))<SlashProperties>`
  display: inline-block;
  margin-right: ${props => props.idx > 0 ? '5px' : '0p'};
  margin-left: ${props => props.idx > 0 ? '5px' : '0p'};
`
