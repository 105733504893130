import {OddsDTO} from '../../common/odds/types';
import {BreadcrumbsDO, DecimalDO, MoneyDTO, TotalDO} from '../../types';

export enum EventRequestAction {
    SPREAD_HOME = 'SPREAD_HOME',
    SPREAD_AWAY = 'SPREAD_AWAY',
    TOTAL_HOME = 'TOTAL_HOME',
    TOTAL_AWAY = 'TOTAL_AWAY',
    MONEYLINE_HOME = 'MONEYLINE_HOME',
    MONEYLINE_AWAY = 'MONEYLINE_AWAY',
    FULL_EVENT = 'FULL_EVENT'
}

export interface BrokerBetRequestDTO {
    eventId: string;

    marketId: string;
}

export interface OutcomeInfoDO {

    // quoteId
    id?: string;
    eventId: string;

    marketId: string;

    outcomeId: string;
    displayOrder: number;
    name: string
    odds: OddsDTO

}

export interface SelectedEventDTO {
    id: string;
    breadCrumbs: BreadcrumbsDO

    currencyCode: string;

    outcomes: OutcomeInfoDO[]


    eventName: string;

    includesPrice: boolean;

    homeTeam: string;

    awayTeam: string;

    homeSpread: DecimalDO;
    awaySpread: DecimalDO;

    moneylineHome: string;

    moneylineAway: string;

    homeTotal: TotalDO;

    awayTotal: TotalDO;

    priceHome: DecimalDO;

    priceAway: DecimalDO;


    // ui only
    refreshCounter: number | undefined

}


export enum ButtonAction {
    REQUEST,
    SET_OUTCOME,
    CANCEL,
    STRIKE_BET
}

export enum QuoteState {
    INIT = 'INIT',
    REQUESTING = 'REQUESTING',
    REQUESTED = 'REQUESTED',
    REPLIED = 'REPLIED',
    REQUEST_WAITING = 'REQUEST_WAITING',
    EXPIRED = 'EXPIRED',
    CANCELLING = 'CANCELLING',
    TRADER_CANCELLED = 'TRADER_CANCELLED',
    CUSTOMER_CANCELLED = 'CUSTOMER_CANCELLED',
    NETWORK_ERROR = 'NETWORK_ERROR',
    ACCEPTING = 'ACCEPTING',
    STRIKING = 'STRIKING'


}

export enum ButtonState {
    INIT,
    REQUESTING,
    QUOTE_RECEIVED,
    TRADER_CANCELLED,
    CANCELLING,
    CUSTOMER_CANCELLED,
    ACCEPTING
}

export interface QuoteRequestDTO {
    eventId: string;
    marketId: string;
    outcomeId?: string;

    riskAmount: MoneyDTO;

}

export interface QuoteResponseDTO {
    id: string;

    requestTimeout: number;

    acceptTimeout: number;

}

export interface QuoteCancelRequestDTO {
    id: string;
    eventId: string;
    marketId: string;
}


export interface OutcomeInfoDO {
    marketId: string;
    outcomeId: string;
    displayOrder: number;
    name: string;
    odds: OddsDTO;
}

export interface OutcomeInfosDO {
    outcomes: OutcomeInfoDO [];

}

export interface QuoteDO {
    id: string;

    breadCrumbs: BreadcrumbsDO;

    requestTime?: string;
    customerId?: string;
    customerName?: string;
    eventId: string;
    eventName?: string;
    requestCountdown?: number;
    acceptCountdown?: number;
    requestTimeout?: number;
    acceptTimeout?: number;
    marketId: string;
    marketName?: string;
    periodName?: string;
    riskAmount: MoneyDTO;
    outcomes: OutcomeInfosDO;
    status?: string;

    // ui
    refreshCounter?: number | 0;

    selectedOutcome?: OutcomeInfoDO | undefined

    quoteState: QuoteState;

    buttonAction?: ButtonAction

}

export interface MainButtonContentsDO {
    label: string;
    subLabel?: string;
}

export interface BetPlacedDTO {
    breadCrumbs: BreadcrumbsDO

    eventName: string;

    outcomeName: string;

    referenceId: string;
    datePlaced: string;

    riskAmount: MoneyDTO;

    gainAmount: MoneyDTO


}

export interface BrokerBet {
    eventId: string;
    marketId: string;

}

export interface TabDO {
    label: string;
    code: string;
}

export interface OpenBetSlipResponseDTO {
    allowedTabs: string[];
    currentQuotes: QuoteDO[];

    event: SelectedEventDTO,

}

export const MAIN_BUTTON_CONTENTS: { [id: string]: MainButtonContentsDO } = {
    INIT: {label: 'REQUEST QUOTE'},
    REQUESTING: {label: ''},
    CANCELLING: {label: ''},
    REQUESTED: {label: 'AWAITING RESPONSE ({requestCountdown}s)'},
    REQUEST_WAITING: {label: 'AWAITING RESPONSE ({requestCountdown}s)'},
    REPLIED: {label: 'AWAITING RESPONSE ({requestCountdown}s)'},
    ACCEPTING: {label: 'SELECT ODDS ({acceptCountdown}s)'},
    PLACE_BET: {label: 'PLACE BET ({acceptCountdown}s)'},
    EXPIRED: {label: 'REQUEST EXPIRED', subLabel: 'Try Again'},
    CUSTOMER_CANCELLED: {label: 'CANCELLED', subLabel: 'Try Again'},
    TRADER_CANCELLED: {label: 'TRADER CANCELLED', subLabel: 'Try Again'},
    NETWORK_ERROR: {label: 'NETWORK ERROR', subLabel: 'Try Again'},
}

export enum TAB_CODES {
    SINGLES = 'SINGLES',
    BROKERAGE = 'BROKERAGE',
    PARLAYS = 'PARLAYS',
    OPEN_BETS = 'OPEN_BETS'
}

export const SingleTab: TabDO =
    {
        code: TAB_CODES.SINGLES,
        label: 'SINGLES'
    }

export const ParlaysTab: TabDO =
    {
        code: TAB_CODES.PARLAYS,
        label: 'PARLAYS'
    }

export const BrokerageTab: TabDO =
    {
        code: TAB_CODES.BROKERAGE,
        label: 'BROKERAGE'
    }

export const OpenBetsTab: TabDO =
    {
        code: TAB_CODES.OPEN_BETS,
        label: 'OPEN BETS'
    }

export const AllTabs: TabDO [] = [SingleTab, ParlaysTab, BrokerageTab, OpenBetsTab]
