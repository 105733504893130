import {UserAgentApplication} from 'msal';
import React from 'react';
import {Observable} from 'rxjs'
import {MediaType, WidgetPanelProperties} from '../../components/types';
import {EnvironmentContextProps, IEnvironment} from './types';

const createDefault = (): EnvironmentContextProps => ({
    watchEnvironment: (): Observable<IEnvironment> => {
        console.warn(
            'Ops likely you forgot to add the environment context provider on top of your app',
        )
        return new Observable<IEnvironment>((observer) => {
            observer.complete();
        });
    },
    getMsalInstance: (): UserAgentApplication | undefined => {
        return undefined;
    },
    isMsalRedirect: () => {
        return true;
    },
    watchNoService: (): Observable<boolean> => {
        console.warn(
            'Ops likely you forgot to add the environment context provider on top of your app',
        )
        return new Observable<boolean>((observer) => {
            observer.complete();
        });
    },
    getStoragePrefix: (): string | undefined => {
        return undefined;
    },
    isMobileDevice: (widgetPanelProperties? : WidgetPanelProperties): boolean => {
        return false;
    },
    isNativeDevice: (): boolean => {
        return false;
    },
    isNativeIOS: (): boolean => {
        return false;
    },

    watchMediaType: (): Observable<MediaType> => {
        console.warn(
            'Ops likely you forgot to add the environment context provider on top of your app',
        )
        return new Observable<MediaType>((observer) => {
            observer.complete();
        });
    },

    getCurrentMediaType: (widgetPanelProperties? : WidgetPanelProperties): MediaType => {
        console.warn(
            'Ops likely you forgot to add the environment context provider on top of your app',
        )
        return MediaType.Desktop
    },

    watchResize: (): Observable<number> => {
        console.warn(
            'Ops likely you forgot to add the environment context provider on top of your app',
        )
        return new Observable<number>((observer) => {
            observer.complete();
        });
    },
    getDeviceClassname: (): string => {
        return ''
    },
    watchOrientation: (): Observable<string> => {
        return new Observable<string>((observer) => {
            observer.complete();
        });
    },
    getCurrentOrientation: (): string => {
        return 'portrait';
    }
})


export const EnvironmentContext = React.createContext<EnvironmentContextProps>(
    createDefault(),
)
