import {BlastException} from '../types';

export class PathDetails {
    _collection: string | undefined;
    _keyField: string | undefined;
    _keyValue: string | undefined;
    _queryParams: string[] | undefined;
    _isRoot: boolean | undefined;
    _isCollectionOnly: boolean | undefined;

    public static splitPath(key: string): PathDetails[] {
        const details: PathDetails[] = [];

        if (key === null || key.trim().length === 0 || key === 'root') {
            // root
            const tmpPathDetails: PathDetails = new PathDetails();
            tmpPathDetails.setRoot();
            tmpPathDetails.setNotCollectionOnly();

            details.push(tmpPathDetails);
            return details;
        }
        if (key.startsWith('/')) {
            throw new BlastException('key cannot start with / - must specify collection e.g. collection/keyname:value');
        }

        const splits: string[] = key.split('/');

        if (splits[0].includes(':')) {
            throw new BlastException('must specify collection e.g. collection/keyname:value');
        }

        // check if it is collection only
        if (splits.length === 1) {
            // collection
            const tmpPathDetails: PathDetails = new PathDetails();
            tmpPathDetails.setNotRoot();
            tmpPathDetails.setCollection(key);
            tmpPathDetails.setCollectionOnly();
            details.push(tmpPathDetails);
            return details;
        }

        let pathDetails: PathDetails = new PathDetails();
        pathDetails.setNotRoot();
        pathDetails.setCollection(splits[0]);
        details.push(pathDetails);

        for (let x = 1; x < splits.length; x++) {
            if (splits[x].includes(':')) {
                const keySplit: string[] = splits[x].split(':');
                if (keySplit.length !== 2) {
                    throw new BlastException('look up must be in form keyname:value - found: ' + splits[x]);
                }
                pathDetails.setKeyField(keySplit[0]);
                pathDetails.setKeyValue(keySplit[1]);
                pathDetails.setNotCollectionOnly();

            } else {
                if (splits[x].startsWith('?')) {
                    pathDetails.setQueryParams(splits[x].substring(1).split(','));
                } else {
                    pathDetails = new PathDetails();
                    pathDetails.setNotRoot();
                    pathDetails.setCollectionOnly();
                    pathDetails.setCollection(splits[x]);
                    details.push(pathDetails);
                }
            }
        }
        return details;
    }

    setRoot() {
        this._isRoot = true;
        this._collection = 'root';
    }

    setNotRoot() {
        this._isRoot = false;
    }

    setCollection(collection: string) {
        this._collection = collection;
    }

    setCollectionOnly() {
        this._isCollectionOnly = true;
    }

    setNotCollectionOnly() {
        this._isCollectionOnly = false;
    }

    setKeyField(keyField: string) {
        this._keyField = keyField;
    }

    setKeyValue(keyValue: string) {
        this._keyValue = keyValue;
    }

    setQueryParams(params: string[]) {
        this._queryParams = params;
    }

    getCollection(): string | undefined {
        return this._collection;
    }

    getKeyField(): string | undefined {
        return this._keyField;
    }

    getKeyValue(): string | undefined {
        return this._keyValue;
    }

    getQueryParams(): string[] | undefined {
        return this._queryParams;
    }

    isRoot(): boolean | undefined {
        return this._isRoot;
    }

    isCollectionOnly(): boolean | undefined {
        return this._isCollectionOnly;
    }

}
