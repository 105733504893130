import styled, {ThemeProps} from 'styled-components';
import {ColumnFieldProperties} from '../maintain-table/styled';

export const CommentWrapperStyled = styled.div.attrs((props) => ({
}))`

`

interface TextCommentProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
    numberRows:number;
    fieldWidth:string;
}

export const TextCommentStyled = styled.textarea.attrs((props: TextCommentProps) => ({
    className: "form-control form-control-sm bg-transparent rounded-0 text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<TextCommentProps>`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
  height: ${props => props.numberRows * 10}px !important;
  width: ${props => props.fieldWidth} !important;
  min-width: ${props => props.fieldWidth} !important;
  max-width: ${props => props.fieldWidth} !important;
  border: none;
`
export const TextCommentTableStyled = styled.div.attrs((props:ColumnFieldProperties) => ({
}))<ColumnFieldProperties>`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};

`
