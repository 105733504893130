import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {translateService} from '../../../services/TranslateService';
import {
    FieldStyled,
    IconWrapperStyled,
    PasswordCardStyled,
    PasswordIconStyled,
    PasswordStyled,
    TogglePasswordStyled
} from '../styled';
import {LabelStyled} from './styled';

type PasswordProps = {
    value: string
    hasLabel: boolean;
    asUppercase: boolean;
    hasIcon: boolean;
    autoFocus: boolean;
    onNextField: () => void;
    onChange: (value: string) => void;
    formSubmitted: boolean;
}
const Password = (props: PasswordProps): React.ReactElement => {

    const label = translateService.getValue('login.password.label', 'Password')
    const placeholder = translateService.getValue('login.password.placeholder', 'Password')
    const [isPasswordRevealed, setIsPasswordRevealed] = useState<boolean>(false);

    return (
        <PasswordCardStyled>
            {props.hasLabel && <LabelStyled htmlFor="password" asUppercase={props.asUppercase}>{label}</LabelStyled>}
            <PasswordStyled>
                <Container fluid>
                    <Row>
                        {props.hasIcon && <IconWrapperStyled>
                            <PasswordIconStyled/> </IconWrapperStyled>}
                        <Col>
                            <FieldStyled
                                name="password"
                                type={!isPasswordRevealed ? 'password' : 'text'}
                                placeholder={placeholder}
                                tabIndex={2}
                                autoFocus={props.autoFocus}
                                value={props.value}
                                onChange={e => {
                                    props.onChange(e.target.value)
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        props.onNextField()
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <TogglePasswordStyled capitalise={environmentService.getEnvironment()?.loginUiProperties?.noModal}>
                        <a href='#' onClick={() => {
                            setIsPasswordRevealed(!isPasswordRevealed)
                        }}>{isPasswordRevealed ? 'Hide' : 'Show'}</a>
                    </TogglePasswordStyled>
                </Container>
            </PasswordStyled>
        </PasswordCardStyled>
    )
}

export default Password;
