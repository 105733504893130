import {MutableRefObject} from 'react';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {BlastProps} from '../../providers/blast/BlastContext';
import {FieldDeltaDO, SimpleMessageBO} from '../../providers/blast/commands';
import {EnvironmentContextProps} from '../../providers/environment/types';
import {SessionContextProps} from '../../providers/session/types';
import {FieldPositionProperties, FieldProperties, FormMode} from '../types';

export const watchBlastDeltas = (
    sessionContext: SessionContextProps,
    blastContext: BlastProps,
    properties: FieldProperties,
    formMode: FormMode
): [Subscription, Subscription | undefined, Observable<any>] => {

    const _subject = new BehaviorSubject<any>(properties.data)

    let blastDeltaSubscription: Subscription | undefined = undefined;
    let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {

        // hard coded name used for debug purposes only
        const watchField: string | undefined = 'homeSpread';

        // if (properties.fieldConfig.name === watchField) {
        //     console.log('==>', properties.fieldConfig.name, properties.fieldConfig.blastDeltaCommand, properties.fieldConfig.keyValue, properties.fieldConfig.keyField,properties.isTableView,formMode === FormMode.VIEW)
        // }

        if (systemReady && properties.fieldConfig.blastDeltaCommand
            && properties.fieldConfig.keyValue
            && properties.fieldConfig.keyField
            && (properties.isTableView || formMode === FormMode.VIEW)) {
            blastDeltaSubscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {

                // if (properties.fieldConfig.name === watchField) {
                //     console.log('inbound ==>', properties.fieldConfig.name,
                //         'bdc:' + properties.fieldConfig.blastDeltaCommand,
                //         'kv:' + properties.fieldConfig.keyValue,
                //         'kf:' + properties.fieldConfig.keyField,
                //         'tk:' + message?.data?.tableKey,
                //         'kv:' + message?.data?.keyValue,
                //         (message?.cmd === 'table.delta' || message?.cmd === 'field.delta'),
                //         (message?.data?.tableKey === properties.fieldConfig.blastDeltaCommand),
                //         (message?.data?.keyValue === properties.fieldConfig.keyValue)
                //     )
                // }


                if (properties.fieldConfig.blastDeltaCommand
                    && systemReady
                    && message
                    && (message.cmd === 'table.delta' || message.cmd === 'field.delta')
                    && message.data
                    && message.data.tableKey === properties.fieldConfig.blastDeltaCommand
                    && message.data.keyValue === properties.fieldConfig.keyValue
                ) {
                    // @ts-ignore
                    // console.log('==> updated', properties.fieldConfig.name, message.data.keyValue, message.cmd, message, message.data.value[properties.fieldConfig.name]);

                    if (properties.fieldConfig.name === watchField) {
                        console.log('==> updated', properties.fieldConfig.name, message.data.keyValue, message.cmd, message, message.data.value, message.data.value[properties.fieldConfig.name]);
                    }

                    if (message.cmd === 'field.delta') {
                        if (message.data.value) {
                            _subject.next(message.data.value)
                        }
                        // gbw vvvv
                        if (message.data.value[properties.fieldConfig.name]) {
                            console.log('----> here 1', message.data.value[properties.fieldConfig.name])
                            // @ts-ignore
                            _subject.next(message.data.value[properties.fieldConfig.name])
                        }
                    } else {
                        if (message.data.value[properties.fieldConfig.name]) {
                            // @ts-ignore
                            _subject.next(message.data.value[properties.fieldConfig.name])
                        }
                    }
                }
            });
        }
    });
    return [loaderSubscription, blastDeltaSubscription, _subject.asObservable()]
}


export const initFieldPositionProperties = (environmentContext: EnvironmentContextProps, properties: FieldProperties) => {
    return {
        name: properties?.fieldConfig?.name || 'xxx',
        startPos: 0,
        windowWidth: window.innerWidth,
        isMobile: environmentContext.isMobileDevice(),
        inLine: properties.inline,
        isDialog: properties.isDialog,
        hasLabel: false
    } as FieldPositionProperties
}

export const handleWindowChange = (
    element: MutableRefObject<null>,
    fieldPositionProperties: FieldPositionProperties,
    setterMethod: Function
): void => {
    // return new Promise<FieldPositionProperties>((resolve) => {
    window.requestAnimationFrame(function () {
        setTimeout(() => {
            if (element) {
                // @ts-ignore
                const rect = element?.current?.getBoundingClientRect();
                if (rect) {
                    setterMethod({
                        ...fieldPositionProperties,
                        windowWidth: window.innerWidth,
                        panelWidth: rect.width,
                        startPos: rect.x
                    })
                }
            }
        }, 100)
    });
    // });
}

export const handleResize = (
    environmentContext: EnvironmentContextProps,
    element: MutableRefObject<null>,
    oldResize: number | undefined,
    fieldPositionProperties: FieldPositionProperties,
    setterMethod: Function,
    setOldResize: Function
): Subscription => {
    return environmentContext.watchResize().subscribe((value: number) => {
        if (!oldResize || oldResize !== value) {
            handleWindowChange(element, fieldPositionProperties, setterMethod)
        }
        setOldResize(value)
    })
}

export const DEFAULT_BADGE_COLOR = '#fff'
export const DEFAULT_BADGE_BG_COLOR = '#0185b7'

// export const watchBadgeBlastDeltas = (
//     sessionContext: SessionContextProps,
//     blastContext: BlastProps,
//     menuItem: IMenuDTO
// ): [Subscription, Subscription | undefined, Observable<MenuItemBadgeMessageBO>] => {
//
//     const _subject = new BehaviorSubject<MenuItemBadgeMessageBO>({
//         value: 0,
//         color: DEFAULT_BADGE_COLOR,
//         backgroundColor: DEFAULT_BADGE_BG_COLOR,
//         menuCode: menuItem.code
//     })
//
//     let blastDeltaSubscription: Subscription | undefined = undefined;
//     let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
//
//         if (systemReady && menuItem.code) {
//             blastDeltaSubscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<MenuItemBadgeMessageBO>) => {
//
//                 if (menuItem.code
//                     && systemReady
//                     && message
//                     && (message.cmd === 'menuitem.badge')
//                     && message.data
//                     && message.data.menuCode === menuItem.code
//                 ) {
//                     // @ts-ignore
//                     console.log('==> updated', menuItem.label, message.cmd, message, message.data);
//
//                     if (isDefined(message.data)) {
//                         _subject.next(message.data)
//                     }
//                 }
//             });
//         }
//     });
//     return [loaderSubscription, blastDeltaSubscription, _subject.asObservable()]
// }
// useEffect(() => {
//     const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBadgeBlastDeltas(sessionContext, blastContext, properties.menuItem)
//     const valueSubscription = valueObservable.subscribe((badgeInfo: MenuItemBadgeMessageBO) => {
//         setBadgeValue(badgeInfo.value)
//         setColor(badgeInfo.color)
//         setBackgroundColor(badgeInfo.backgroundColor)
//
//     });
//     return () => {
//         unSubscribe(loaderSubscription);
//         unSubscribe(blastDeltaSubscription);
//         unSubscribe(valueSubscription);
//     }
// }, [properties]);
//

