import {FieldConfigDTO} from '../../types';

export interface RadialMeterProperties {
    fieldConfig: FieldConfigDTO;
    data: RadialMeterDTO;
}

export interface RadialMeterDTO {
    value: number;
    min: number;
    max: number;
    properties?: RadialMeterPropertiesDTO
}

export interface RadialMeterPropertiesDTO {
    start: number;
    end: number;
    voidColor: string;
    textColor: string;
    outerColor: string | string[];
    outerVoidColor: string;
    tickCount: number;
    tickColor: string | string[];
    tickVoidColor: string;
    labelColor: string;
    labelFont: string;
    textTransform: (val: number) => string;
}

export const defaultTextTransform = (val: number) => {
    console.log('defaultTextTransform', val);
    return Number.parseFloat('' + val).toFixed(1)
}

export const DefaultRadialMeterPropertiesDTO: RadialMeterPropertiesDTO = {
    start: (0.75 * Math.PI),
    end: (0.25 * Math.PI),
    voidColor: 'yellow',
    textColor: '#979797',
    outerColor: ['#205BE1', '#CF2340'],
    outerVoidColor: 'pink',
    tickCount: 50,
    tickColor: 'red',
    tickVoidColor: 'wheat',
    labelColor: '#CF2340',
    labelFont: 'Poppins-Regular',
    textTransform: defaultTextTransform
}

/*
export const DefaultRadialMeterPropertiesDTO: RadialMeterPropertiesDTO = {
    start: (0.75 * Math.PI),
    end: (0.25 * Math.PI),
    voidColor: '#D7DBEC',
    textColor: '#979797',
    outerColor: ['#205BE1', '#CF2340'],
    outerVoidColor: 'transparent',
    tickCount: 10,
    tickColor: '#FFFFFF',
    tickVoidColor: 'transparent',
    labelColor: '#CF2340',
    labelFont: 'Poppins-Regular',
    textTransform: defaultTextTransform
}

 */
interface Ring {
    /** Radius of the OuterRing as a percent compared to the overall size of the canvas.
     * @type {number}
     */
    _radius?: number;
    /** Thickness of the OuterRing as a percent compared to the overall size of the canvas.
     * @type {number}
     */
    _thickness?: number;
    /** Color of the OuterRing. Note: An object can be provided with steps of colors for each property to create a gradient.
     * @type {string|object}
     */
    color?: string | string[];
    voidColor?: string;
    count?: number;
    tickColor?: string | object;
    tickVoidColor?: string;
    fontFamily?: string

}


export default class RadialMeterOptions {
    Start: number;
    End: number;
    VoidColor: string;
    TextColor: string;
    OuterRing: Ring;
    TickRing: Ring;
    Label: Ring;


    constructor(props: RadialMeterPropertiesDTO) {
        if (typeof props === 'undefined' || props === null) {
            props = DefaultRadialMeterPropertiesDTO;
        }

        /** Starting angle (in radians) for the radial graphic
         * @type {number}
         */
        this.Start = props.start || (0.75 * Math.PI);

        /** Ending angle (in radians) for the radial graphic
         * @type {number}
         */
        this.End = props.end || (0.25 * Math.PI);

        /** Background color (void) for the graphic area
         * @type {string|object}
         */
        this.VoidColor = props.voidColor || '#1E1B1B';

        /** General label text color (i.e. in the OuterRing). Available for future-proofing
         * @type {string|object}
         */
        this.TextColor = props.textColor || '#949494';

        /** Variables for rendering the OuterRing area of the graphic */
        this.OuterRing = {
            /** Radius of the OuterRing as a percent compared to the overall size of the canvas.
             * @type {number}
             */
            _radius: 0.45,
            /** Thickness of the OuterRing as a percent compared to the overall size of the canvas.
             * @type {number}
             */
            _thickness: 0.0748,
            /** Color of the OuterRing. Note: An object can be provided with steps of colors for each property to create a gradient.
             * @type {string|object}
             */
            color: props.outerColor,
            voidColor: props.outerVoidColor
        };

        /** Variables for rendering the Tick Ring area of the graphic */
        this.TickRing = {
            /** Radius of the TickRing as a percent compared to the overall size of the canvas.
             * @type {number}
             */
            _radius: 0.3357,
            /** Thickness of the TickRing as a percent compared to the overall size of the canvas.
             * @type {number}
             */
            _thickness: 0.0748,
            /** Number of ticks to draw within the bounds of the tick area.
             * @type {number}
             */
            count: props.tickCount || 100,
            /** Color of the TickRing. Note: an object can be provided with steps of colors for each property to create a gradient.
             * @type {string|object}
             */
            color: props.tickColor,
            voidColor: props.tickVoidColor,
        };

        /** Variables for rendering the Label area of the graphic */
        this.Label = {
            /** Ideal radius that the label should take up as a percent compared to the overall size of the canvas.
             * Note: The text will try to grow/shrink depending on the size of the canvas and how much space the text takes within the control.
             * @type {number}
             */
            _radius: 0.2214,
            /** Color of the Label. Note: an object can be provided with steps of colors for each property to create a gradient.
             * @type {string|object}
             */
            color: props.labelColor,
            /** Name of the font family to render the text with. */
            fontFamily: props.labelFont
        };
    }
}
