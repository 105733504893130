import React, {useContext, useEffect, useState} from "react";
import {environmentService} from '../../../../providers/environment/EnvironmentService';
import {HeaderDTO, IMenuDTO, PanelConfigDTO, WidgetPanelProperties} from "../../../types";
import {constructUrl, RunOnceEffect, unSubscribe} from "../../../../utils/Utils";
import {SessionContext} from "../../../../providers/session/SessionContext";
import {HeaderPanels} from '../../header-panel/HeaderPanels';
import {deltaUpdate, GuslTableState} from "../guslTableSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {ComponentTitleWrapperStyled} from "../../../ui/styled";
import {TableMenuBarStyled, TableTitleStyled} from "../../maintain-table/styled";
import {HeaderItemStyled} from "../../tabbed-page/styled";
import {GuslReport} from "../../gusl-report/GuslReport";
import {arrayNotEmpty} from "../../../../utils/TypeCheckers";
import MenuBar from "../../maintain-table/bootstrap/MenuBar";
import {GuslTableProperties} from "../GuslTable";
import {Subscription} from "rxjs";
import {maintainTableService} from "../../maintain-table/MaintainTableService";
import {SystemContext} from "../../../../providers/system/SystemContext";
import {FieldDeltaDO, SimpleMessageBO} from "../../../../providers/blast/commands";
import {BlastContext} from "../../../../providers/blast/BlastContext";

interface TableHeaderDO {
    code: GuslTableProperties["code"];
    isSummaryHeader: GuslTableProperties["isSummaryHeader"];
    isSummaryFooter: GuslTableProperties["isSummaryFooter"];
    headerUrl: GuslTableProperties['headerUrl'];
    blastDeltaCommand: GuslTableProperties["blastDeltaCommand"];
    widgetPanelProperties? : WidgetPanelProperties
    headerPanels?: GuslTableProperties["headerPanels"]
}

// MK 12/08/2023
// moving TableHeader outside of ListView into GuslTable
// because when top advanced search is open TableHeader is between
// advanced search and table
// so we will move it to the top
export default function TableHeader({
                                        code,
                                        isSummaryHeader,
                                        isSummaryFooter,
                                        headerUrl,
                                        blastDeltaCommand,
                                        widgetPanelProperties,
                                        headerPanels
                                    }: TableHeaderDO) {
    const [className] = useState("TableHeader-" + new Date().getTime());
    const dispatch = useAppDispatch();
    const sessionContext = useContext(SessionContext);
    const systemContext = useContext(SystemContext);
    const blastContext = useContext(BlastContext);
    const state: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);
    const [hasComplexHeader, setHasComplexHeader] = useState<boolean>(false);
    const [headerLoading, setHeaderLoading] = useState<boolean>(false);
    const [headerData, setHeaderData] = useState<HeaderDTO | undefined>(undefined);
    const [headerRefreshRate, setHeaderRefreshRate] = useState<number>(-1);
    const [menuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(code));
    const [systemReady, setSystemReady] = useState<boolean>(false);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        let loaded = true;
        let blastDeltaSubscription: Subscription;
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            if (systemReady && blastDeltaCommand && loaded) {
                blastDeltaSubscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {
                    if (blastDeltaCommand && systemReady && message && message.cmd === 'table.delta' && message.data && message.data.tableKey === blastDeltaCommand) {
                        dispatch(deltaUpdate({
                            code: code,
                            fieldDelta: message.data
                        }))
                    }
                });
            }
        });
        return () => {
            loaded = false;
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
        }
    }, [code, blastDeltaCommand]);

    useEffect(() => {
        let refreshRate: number = -1;
        let subscription: Subscription | undefined = undefined;
        if (systemReady) {
            refreshRate = maintainTableService.extractRefreshRate(menuItem);
            subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
                // original had shouldPauseRefresh && ... but now it is out of a ListView so it won't affect the ListView
                if (nextRefreshRate !== -1 && headerRefreshRate === nextRefreshRate) {
                    getHeaderData()
                }

            })
        }
        return () => {
            sessionContext.unregisterRefreshRate(refreshRate);
            if (headerRefreshRate) {
                sessionContext.unregisterRefreshRate(headerRefreshRate);
            }
            unSubscribe(subscription);
        }
    }, [systemReady, menuItem]);

    // log.info(className, 'MSG001')
    const getHeaderData = () => {
        setHasComplexHeader(true)
        const headerLoading = true
        setHeaderLoading(headerLoading);
        // @ts-ignore
        sessionContext.get<HeaderDTO>(constructUrl(headerUrl, {id: code}))
            .then(response => {
                if (headerLoading) {
                    setHeaderData(response.data)
                    setHeaderLoading(false);
                    // setRefreshCounter(new Date().getTime())

                    // console.log('header -> response.data', headerUrl, response.data.refreshRate, response.data.refreshRateInSeconds)
                    if (response.data.refreshRate || response.data.refreshRateInSeconds) {
                        if (!headerRefreshRate && (response?.data?.refreshRate || response?.data?.refreshRateInSeconds || 0) !== -1) {
                            // @ts-ignore
                            let rate = (response?.data?.refreshRate || response?.data?.refreshRateInSeconds) * 1000
                            setHeaderRefreshRate(rate)
                            sessionContext.registerRefreshRate(rate)
                        }
                    }

                }
            }).catch(error => {
            if (headerLoading) {
                setHeaderLoading(false);
                setHasComplexHeader(false)
                console.error('Error in getting header', error)
            }
        })
    }
    return (
        <ComponentTitleWrapperStyled isSummaryHeader={isSummaryHeader} isSummaryFooter={isSummaryFooter}>
            {<div className={"col d-flex "}>
                <TableTitleStyled isSummaryHeader={isSummaryHeader} isMobile={false}
                                  footerHeight={footerHeight}
                                  isSummaryFooter={isSummaryFooter}>{state?.label || ''}</TableTitleStyled>
            </div>}
            <div
                className={"col-md-6 px-2 d-flex justify-content-" + ((headerData && headerData.align) ? headerData.align : "start")}>

                {headerPanels && <HeaderPanels parentData={{}} panels={headerPanels}/>}
                {headerData && headerData.info && headerUrl &&

                    <HeaderItemStyled>
                        <GuslReport
                            code={code}
                            data={headerData.info}
                        />
                    </HeaderItemStyled>}
            </div>
            <TableMenuBarStyled isSummaryHeader={isSummaryHeader} isSummaryFooter={isSummaryFooter}
                                footerHeight={footerHeight}>
                {arrayNotEmpty(state?.tableMenuGroups) &&
                    <MenuBar entityId={code} menuGroups={state?.tableMenuGroups}
                             widgetPanelProperties={widgetPanelProperties}/>}
            </TableMenuBarStyled>
        </ComponentTitleWrapperStyled>
    );
};
