import React, {useState} from 'react';
import GlobalSearchBox from '../../../../common/global-search/search-box/GlobalSearchBox';
import {SearchFieldsDTO} from '../../../../common/global-search/type';
import {OttoBoxStyled} from '../../styled';
import {SearchWrapperStyled} from '../styled';

interface OtherDealsSearchProperties {
    onSearchValueChange: (value: string | undefined) => void
}

const OtherDealsSearch = (props: OtherDealsSearchProperties): React.ReactElement => {
    const [searchString, setSearchString] = useState<string>('')
    const [refreshCounter, setRefreshCounter] = useState<number>(0)
    const [searchFields] = useState<SearchFieldsDTO[]>([
        // {
        //     label: 'All',
        //     displayOrder: 1,
        //     selected: true
        // }
    ]);


    const onSearchChange = (value: string | undefined) => {
        if (props.onSearchValueChange) {
            props.onSearchValueChange(value)
            setSearchString(value || '')
        }
    }
    const onFuzzySearch = () => {
    }

    const onSearchableFieldChange = () => {
    }
    const onSearchReset = () => {
        if (props.onSearchValueChange) {
            props.onSearchValueChange(undefined)
            setSearchString('')
            setRefreshCounter(refreshCounter + 1)
        }
    }

    return (
        <OttoBoxStyled>
            <SearchWrapperStyled>
                <GlobalSearchBox
                    key={'other_gs_' + refreshCounter}
                    code={'other_gs'}
                    searchableFields={searchFields}
                    searchString={searchString}
                    fuzzy={true}
                    searchPrompt={'Filter'}
                    showFieldSelect={true}
                    onSearchValueChange={(searchValue: string | undefined) => onSearchChange(searchValue)}
                    onFuzzySearch={onFuzzySearch}
                    onSearchableFieldChange={onSearchableFieldChange}
                    onSearchReset={onSearchReset}
                    autoSearchLength={2}
                />
            </SearchWrapperStyled>
        </OttoBoxStyled>
    )
}
export default OtherDealsSearch
