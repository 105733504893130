import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../../app/hooks';
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {SessionContext} from '../../../../providers/session/SessionContext';
import {SystemContext} from '../../../../providers/system/SystemContext';
import {log} from '../../../../services/LogService';
import {cancelAbortController, getErrorMessage} from '../../../../utils/Utils';
import {GuslErrors, MoneyDTO, NotificationPosition, NotificationType} from '../../../types';
import {
    betSlipTabSelected,
    cancelQuote,
    CancelQuoteRequestResponseWrapper,
    changeQuoteState,
    outcomeSelected,
    QuoteRequestResponseWrapper,
    removeQuote,
    requestQuote,
    riskAmountChanged,
    strikeQuote,
    StrikeQuoteRequestResponseWrapper
} from '../betSlipSlice';
import {
    BrokerBetStyled,
    ButtonStyled,
    ButtonsWrapperStyled,
    ColStyled,
    ContentPanelStyled,
    LabelStyled,
    OutcomesPanelStyled,
    QuoteEventNameStyled,
    RowStyled,
    SpinnerStyled,
    SubLabelStyled,
    TableBodyStyled,
    TableStyled,
    TeamNameColStyled
} from '../styled';
import {
    BetPlacedDTO,
    ButtonAction,
    MAIN_BUTTON_CONTENTS,
    OpenBetsTab,
    OutcomeInfoDO,
    QuoteDO,
    QuoteState
} from '../types';
import {BetPlaced} from './BetPlaced';
import {Breadcrumb} from './Breadcrumb';
import {RequestButton} from './RequestButton';
import {StakeAmount} from './StakeAmount';


const CANCEL_QUOTE_URL = '/event-list/cancel-quote'
const REQUEST_QUOTE_URL = '/event-list/request-quote'
const STRIKE_QUOTE_URL = '/event-list/strike-quote'

interface QuoteProperties {
    quote: QuoteDO,
    onClose?: () => void

}

export const Quote = ({
                          quote,
                          onClose
                      }: QuoteProperties): React.ReactElement<QuoteProperties> => {

    const [className] = useState('quote-' + quote.id);

    const dispatch = useAppDispatch();

    const environmentContext = React.useContext(EnvironmentContext)
    const systemContext = React.useContext(SystemContext);
    const sessionContext = React.useContext(SessionContext)

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [majorError, setMajorError] = useState<boolean>(false);

    const [requestTimeout] = useState<number>(-1);
    const [acceptTimeout] = useState<number>(-1);

    const [betPlaced, setBetPlaced] = useState<BetPlacedDTO | undefined>(undefined);

    /**
     * TimeoutHandler - server will control the timeout, however this is a catchall if server is taken down
     */
    const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | undefined>(undefined);

    const [currentAbortController, setCurrentAbortController] = useState<AbortController | undefined>(undefined);


    const isMobile = environmentContext.isMobileDevice();
    const id = 'quote_' + quote.id + '_' + (quote.refreshCounter || 0)

    useEffect(() => {
        if (requestTimeout !== -1) {
            clearTimeout(timeoutHandler);
            const tout = setTimeout(() => {
                if (quote.quoteState === QuoteState.REQUESTING || quote.quoteState === QuoteState.REQUEST_WAITING) {
                    dispatch(changeQuoteState({quoteId: quote.id, quoteState: QuoteState.NETWORK_ERROR}))
                }
            }, requestTimeout);
            setTimeoutHandler(tout)
        }
        return () => {
            clearTimeout(timeoutHandler);
            cancelAbortController(currentAbortController);
        }

    }, [requestTimeout]);


    useEffect(() => {
        if (acceptTimeout !== -1) {
            clearTimeout(timeoutHandler);
            const tout = setTimeout(() => {
                if (quote.quoteState === QuoteState.STRIKING || quote.quoteState === QuoteState.ACCEPTING) {
                    dispatch(changeQuoteState({quoteId: quote.id, quoteState: QuoteState.NETWORK_ERROR}))
                }
            }, acceptTimeout);
            setTimeoutHandler(tout)
        }
    }, [acceptTimeout]);


    const sendCancelQuote = () => {
        if (quote.id) {
            if (quote.quoteState === QuoteState.INIT
                || quote.quoteState === QuoteState.CUSTOMER_CANCELLED
                || quote.quoteState === QuoteState.NETWORK_ERROR
                || quote.quoteState === QuoteState.EXPIRED
                || quote.quoteState === QuoteState.TRADER_CANCELLED
            ) {// remove and close bet slip
                dispatch(removeQuote({quoteId: quote.id}))
                if (onClose) {
                    onClose()
                }
                return
            }
            const abortController = new AbortController();
            setCurrentAbortController(abortController);

            dispatch(cancelQuote({
                url: CANCEL_QUOTE_URL,
                eventId: quote.eventId,
                marketId: quote.marketId,
                quoteId: quote.id,
                sessionContext: sessionContext,
                abortController: abortController,
            }))
                .unwrap()
                .then((reply: CancelQuoteRequestResponseWrapper) => {
                    if (!reply?.response?.hideNotification) {
                        const msg = reply?.response?.notificationMessage || 'Request cancelled'
                        systemContext.toast({
                            type: NotificationType.SUCCESS,
                            position: NotificationPosition.BOTTOM_RIGHT,
                            noIcon: false,
                            noAutoClose: false,
                            message: msg,
                            autoCloseDuration: 2000
                        })
                    }
                    if (quote.quoteState === QuoteState.REPLIED
                        || quote.quoteState === QuoteState.ACCEPTING
                    ) {// remove and close bet slip
                        if (onClose) {
                            onClose()
                        }
                        return
                    }
                })
                .catch((errors: GuslErrors) => {
                    setErrorMessage(getErrorMessage(errors))
                    setMajorError(true)
                    log.error(className, 'ERR001', errors)
                })
        }
    }

    const onOptionSelected = (action: ButtonAction, outcomeInfo?: OutcomeInfoDO) => {
        // validate click allowed
        console.log('quote.quoteState', quote.quoteState)
        if (action !== ButtonAction.CANCEL) {
            const quoteState = quote.quoteState;
            if (quoteState === QuoteState.REQUESTING
                || quoteState === QuoteState.REQUESTED
                || quoteState === QuoteState.REQUEST_WAITING
                || quoteState === QuoteState.CANCELLING
                || quoteState === QuoteState.STRIKING
            ) {
                console.log('-- not allowed', action, quote.quoteState,)
                return
            }
        }

        switch (action) {
            case ButtonAction.REQUEST:
                if (!quote.riskAmount || quote.riskAmount.value <= 0) {
                    setSubmitted(true)
                    setErrorMessage(!quote.riskAmount ? 'Stake required' : 'Stake must be greater than zero')
                    setMajorError(false)
                    return;
                } else {
                    setErrorMessage(undefined)
                    setMajorError(false)
                }
                dispatch(changeQuoteState({quoteId: quote.id, quoteState: QuoteState.REQUESTING}))

                const abortController = new AbortController();
                setCurrentAbortController(abortController);

                dispatch(requestQuote({
                    url: REQUEST_QUOTE_URL,
                    quoteId: quote.id,
                    eventId: quote.eventId,
                    marketId: quote.marketId,
                    sessionContext: sessionContext,
                    abortController: abortController,
                    outcomeId: outcomeInfo?.outcomeId,
                    riskAmount: quote.riskAmount
                }))
                    .unwrap()
                    .then((reply: QuoteRequestResponseWrapper) => {
                    })
                    .catch((errors: GuslErrors) => {
                        setErrorMessage(getErrorMessage(errors))
                        setMajorError(true)
                        log.error(className, 'ERR003', errors)
                    })
                break;
            case ButtonAction.SET_OUTCOME:
                if (outcomeInfo && quote) {
                    dispatch(outcomeSelected({quoteId: quote.id, outcomeSelected: outcomeInfo}))
                }
                break;
            case ButtonAction.CANCEL:
                sendCancelQuote()
                break;
            case ButtonAction.STRIKE_BET:
                if (quote.selectedOutcome) {
                    const abortController1 = new AbortController();
                    setCurrentAbortController(abortController1);

                    dispatch(strikeQuote({
                        url: STRIKE_QUOTE_URL,
                        sessionContext: sessionContext,
                        abortController: abortController1,
                        quoteId: quote.id,
                        selectedOutcome: quote.selectedOutcome
                    }))
                        .unwrap()
                        .then((reply: StrikeQuoteRequestResponseWrapper) => {
                            dispatch(betSlipTabSelected({tab: OpenBetsTab}))
                            console.log('reply', reply)
                            // setBetPlaced(reply.response)
                        })
                        .catch((errors: GuslErrors) => {
                            console.log('------------> errors', errors)
                            setErrorMessage(getErrorMessage(errors))
                            setMajorError(true)
                            log.error(className, 'ERR002', errors)
                        })
                }
        }
    }


    const renderRequestButtonContents = (): React.ReactElement => {
        if (quote.quoteState === QuoteState.REQUESTING
            || quote.quoteState === QuoteState.CANCELLING
            || quote.quoteState === QuoteState.STRIKING
        ) {
            return (
                <LabelStyled><SpinnerStyled/></LabelStyled>
            )
        }

        const mainButtonContent = quote.buttonAction === ButtonAction.STRIKE_BET ? MAIN_BUTTON_CONTENTS['PLACE_BET'] : MAIN_BUTTON_CONTENTS[quote.quoteState]
        if (!mainButtonContent) {
            console.log('failed to find', quote.quoteState, quote)
            return <></>
        }
        return (
            <>
                <LabelStyled>{(mainButtonContent?.label || '')
                    .replace('{requestCountdown}', '' + quote.requestCountdown)
                    .replace('{acceptCountdown}', '' + quote.acceptCountdown)}
                    <br/><SubLabelStyled>{mainButtonContent.subLabel}</SubLabelStyled>
                </LabelStyled>
            </>
        )
    }


    const renderRequestButton = (): React.ReactElement => {
        const action = quote.buttonAction === ButtonAction.STRIKE_BET ? ButtonAction.STRIKE_BET : ButtonAction.REQUEST
        return (
            <ButtonsWrapperStyled key={id + '_btn'}>
                {!majorError && <ButtonStyled large={true} isSelected={false}
                                              onClick={() => onOptionSelected(action)}>{renderRequestButtonContents()}</ButtonStyled>}
                <ButtonStyled isSelected={false}
                              onClick={() => onOptionSelected(ButtonAction.CANCEL)}><LabelStyled>{'Cancel'}</LabelStyled></ButtonStyled>
            </ButtonsWrapperStyled>
        )
    }


    const renderOutcomes = (): React.ReactElement => {
        return (
            <OutcomesPanelStyled id={'outcome_panel'}>
                <TableStyled>
                    <TableBodyStyled>
                        {(quote?.outcomes?.outcomes || []).map((outcome, idx) => {
                            return <RowStyled key={'row_' + idx}>
                                <TeamNameColStyled>{outcome.name}</TeamNameColStyled>
                                <ColStyled>
                                    {!majorError && <RequestButton
                                        isSelected={quote?.selectedOutcome?.outcomeId === outcome.outcomeId}
                                        onClick={(action: ButtonAction, pOutcome: OutcomeInfoDO | undefined) => onOptionSelected(action, pOutcome)}
                                        isMobile={isMobile}
                                        state={quote.quoteState}
                                        outcome={outcome}
                                        label={''}/>}
                                </ColStyled>
                            </RowStyled>
                        })}
                    </TableBodyStyled>
                </TableStyled>
            </OutcomesPanelStyled>
        )
    }

    const renderEventName = (): React.ReactElement => {
        return (
            <QuoteEventNameStyled>{quote.eventName}</QuoteEventNameStyled>
        )
    }

    const renderBetPlaced = (): React.ReactElement => {
        return (
            <>
                {betPlaced && <BetPlaced betPlaced={betPlaced}></BetPlaced>}
            </>
        )
    }


    const onStakeChange = (riskAmount: MoneyDTO) => {
        dispatch(riskAmountChanged({quoteId: quote.id, riskAmount: riskAmount}))
    }

    let stakeDisabled = true
    if (quote.quoteState === QuoteState.INIT
        || quote.quoteState === QuoteState.CUSTOMER_CANCELLED
        || quote.quoteState === QuoteState.NETWORK_ERROR
        || quote.quoteState === QuoteState.EXPIRED
        || quote.quoteState === QuoteState.TRADER_CANCELLED
    ) {
        stakeDisabled = false
    }

    return (
        <BrokerBetStyled id={id} key={id}>
            {renderEventName()}
            <Breadcrumb breadcrumb={quote?.breadCrumbs}/>
            <ContentPanelStyled>
                {betPlaced && renderBetPlaced()}
                {!betPlaced && renderOutcomes()}
                {!betPlaced && <StakeAmount riskAmount={quote.riskAmount}
                                            submitted={submitted}
                                            disabled={stakeDisabled}
                                            onChange={(riskAmount: MoneyDTO) => onStakeChange(riskAmount)}/>}
                {errorMessage &&
                    <small className="yellow">{errorMessage}</small>}
            </ContentPanelStyled>
            {renderRequestButton()}
        </BrokerBetStyled>
    )


}
