import React, {useContext, useRef, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {GuslThemeContext} from '../../../../providers/theme/GuslThemeProvider';
import {safeStream} from '../../../../utils/Utils';
import {ElementWithTooltip} from '../../element-with/element-with-tooltip/ElementWithTooltip';
import {FuzzySearchStyled, SearchDropdownItemStyled, SearchIconStyled} from '../../gusl-table/styled/search-box/styled';
import {WarningMsgStyled} from '../../gusl-table/styled/table-controls/styled';
import {SearchboxContainerStyled, SearchboxWrapperStyled} from '../../gusl-table/table-controls/search-box/styled';
import {SearchFieldsDTO} from '../type';

export interface SearchBoxProperties {
    code: string,
    searchableFields: SearchFieldsDTO[];
    searchString: string;
    searchPrompt: string
    onSearchValueChange: (value: string | undefined) => void;
    onFuzzySearch: () => void;
    onSearchableFieldChange: (field: SearchFieldsDTO) => void;
    showFieldSelect?: boolean;
    autoSearchLength?: number | undefined;
    onSearchReset: () => void;
    fuzzy: boolean
}

export default function GlobalSearchBox({
                                            code,
                                            searchableFields,
                                            searchPrompt,
                                            searchString,
                                            showFieldSelect = true,
                                            onSearchValueChange,
                                            onFuzzySearch,
                                            onSearchableFieldChange,
                                            onSearchReset,
                                            autoSearchLength,
                                            fuzzy
                                        }: SearchBoxProperties) {
    const searchBoxRef = useRef<HTMLInputElement>(null);
    const [missingSearchTerm, setMissingSearchTerm] = useState<boolean>(false);
    const [missingSearchField, setMissingSearchField] = useState<boolean>(false);
    const guslThemeContext = useContext(GuslThemeContext);
    const environmentContext = React.useContext(EnvironmentContext);
    const [themeColors] = useState(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()));

    const isMobile = environmentContext.isMobileDevice();

    const [haveNoAuto] = useState<boolean>(!(autoSearchLength && autoSearchLength > 0))

    const haveFieldSelected = (): boolean => {
        let found: boolean = false;
        (searchableFields || []).filter(fld => fld.selected)
            .forEach(fld => found = true)
        return found;
    }

    const selectFieldsHandler = (field: SearchFieldsDTO) => {
        setMissingSearchTerm(false);
        setMissingSearchField(false);
        if (onSearchableFieldChange) {
            onSearchableFieldChange(field)
        }
        if (onSearchValueChange) {
            onSearchValueChange(searchBoxRef.current?.value)
        }
    }

    const resetSearchHandler = () => {
        if (onSearchReset) {
            onSearchReset()
        }
        setMissingSearchTerm(false);
        setMissingSearchField(false);
    }

    function sendSearchQueryHandler() {
        if (searchableFields.length > 0 && !searchBoxRef.current?.value) {
            setMissingSearchTerm(true);
            return
        } else {
            setMissingSearchTerm(false);
        }
        if (searchableFields.length > 0 && !haveFieldSelected()) {
            setMissingSearchField(true);
            return
        } else {
            setMissingSearchField(false);
        }

        if (searchableFields.length === 0) {
            if (onSearchValueChange) {
                onSearchValueChange(searchBoxRef.current?.value)
            }
        } else if (searchBoxRef.current?.value && haveFieldSelected()) {
            setMissingSearchTerm(false);
            setMissingSearchField(false);
            if (onSearchValueChange) {
                onSearchValueChange(searchBoxRef.current?.value)
            }
        }
    }

    function SearchableFieldsDropdown() {
        return (
            <span className={"m-0 p-0 " + (isMobile ? "" : "input-group-text")}
                  id={"global_search_box_" + code}>
                    <Dropdown className={isMobile ? 'row g-0' : ''}>
                        <Dropdown.Toggle
                            className={'rounded-0 border-0 text-start ' + (isMobile ? 'mb-1' : '')}
                            style={{
                                color: themeColors.colors.dark[1],
                                backgroundColor: themeColors.colors.dark[0],
                            }}
                            id={'searchable_fields_' + code}>
                            {searchPrompt}

                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'end'}
                                       className={'m-0 ' + (isMobile ? 'p-0 rounded-0' : 'p-1 border-0')}
                                       style={{
                                           backgroundColor: themeColors.colors.dark[0],

                                       }}>
                            {safeStream(searchableFields).map((field, idx) =>
                                <Dropdown.Item
                                    key={idx}
                                    className={'p-0 m-0'}
                                >
                                    <SearchDropdownItemStyled role={'button'}
                                                              onClick={() => selectFieldsHandler(field)}
                                                              className={'d-flex justify-content-between px-1 ' + (isMobile ? ' py-2' : '')}
                                                              active={field.selected}
                                    >
                                        {(field.selected) ?
                                            <span className={" px-2"}>&#x2713;</span> :
                                            <span className={" px-2"}>&#x2717;</span>}
                                        <span> {field.label} </span>
                                    </SearchDropdownItemStyled>
                                </Dropdown.Item>
                            )}
                            <FuzzySearchStyled role={'button'}
                                               onClick={() => {
                                                   if (onFuzzySearch) {
                                                       onFuzzySearch()
                                                   }
                                               }}
                                               className={'d-flex justify-content-between px-1 ' + (isMobile ? ' py-2' : '')}
                                               active={fuzzy}
                            >
                                  {fuzzy ?
                                      <span className={" px-2"}>&#x2713;</span> :
                                      <span className={" px-2"}>&#x2717;</span>}
                                <span>  fuzzy search</span>
                             </FuzzySearchStyled>
                        </Dropdown.Menu>
                </Dropdown>
            </span>
        );
    }

    const onDataInputChange = (value: string) => {
        if (!haveNoAuto) {
            if (value && value.length >= (autoSearchLength || 5)) {
                sendSearchQueryHandler()
            } else if (!value || value.length === 0) {
                resetSearchHandler()
            }
        }
    }

    return (

        <SearchboxContainerStyled>
            <div> {isMobile && showFieldSelect && searchableFields.length > 0 &&
                <SearchableFieldsDropdown/>
            }</div>
            <SearchboxWrapperStyled>
                <input type="text"
                       autoFocus
                       ref={searchBoxRef}
                       id={'search_' + code}
                       defaultValue={searchString || ''}
                       className="form-control border-0 rounded-0"
                       placeholder={'Search'}
                       aria-label="Search..."
                       aria-describedby={"global_search_box_" + code}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               sendSearchQueryHandler()
                           }
                       }}
                       onChange={(e) => onDataInputChange(e.target.value)}

                />

                {!isMobile && showFieldSelect && searchableFields.length > 0 && <SearchableFieldsDropdown/>}

                <SearchIconStyled
                    className={"input-group-text border-0"}
                    haveNoAuto={haveNoAuto}
                    role={haveFieldSelected() ? 'button' : ''}
                    onClick={sendSearchQueryHandler}
                    id={"global_search_box2_" + code}
                    active={true}
                >
                    <i className={'fa-solid fa-search'}/>
                </SearchIconStyled>
                <SearchIconStyled
                    className={"input-group-text border-0"}
                    role={'button'}
                    onClick={resetSearchHandler}
                    id={"reset_" + code}
                    active={true}
                >
                    <ElementWithTooltip
                        element={<i className={'fa-solid fa-close'}/>}
                        tooltip={<span>Reset search.</span>}/>
                </SearchIconStyled>
            </SearchboxWrapperStyled>

            <div className="text-start  small ">
                {missingSearchTerm && <WarningMsgStyled>Missing search term</WarningMsgStyled>}
                {missingSearchField && <WarningMsgStyled>Missing search field</WarningMsgStyled>}
            </div>

        </SearchboxContainerStyled>

    );
}
