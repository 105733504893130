import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {FilterActionItemStyled} from "../../../flex-list/styled";
import {FavQueryDTO, QueryParamsDTO} from "../../../types";
import {ElementWithTooltip} from "../../element-with/element-with-tooltip/ElementWithTooltip";
import Icon from "../../maintain-table/bootstrap/Icon";
import {GuslTableQueryParamState, updateQueryParams} from "../queryParamsSlice";
import {queryParamsAreTheSame} from "./functions";

export interface FavQueryAsHeaderFilterDO {
    code: string;
    favQuery: FavQueryDTO;
}

export default function FavQueryAsHeaderFilter({code, favQuery}: FavQueryAsHeaderFilterDO) {

    const [className] = useState("FavQueryAsHeaderFilter-" + new Date().getTime());
    const queryParamsState: GuslTableQueryParamState = useAppSelector(state => state.queryParamsSlice[code]);
    const currentQuery: QueryParamsDTO = queryParamsState.queryParams;
    const dispatch = useAppDispatch();
    const isActive: boolean = queryParamsAreTheSame(favQuery.queryParams, currentQuery);
    const maxNumOfCharacters: number = 20;
    const isGlobal: FavQueryDTO["isGlobal"] = favQuery.isGlobal;

    function selectFavQueryHandler() {
        dispatch(updateQueryParams({
            code: code,
            queryParams: favQuery.queryParams
        }));
    }

    // useEffect(() => {
    //     console.log('currentQuery', code, currentQuery)
    // }, [currentQuery]);

    function DisplayName() {

        if (favQuery.queryName.length > maxNumOfCharacters) {
            return <ElementWithTooltip
                element={<span>{favQuery.queryName.substring(0, maxNumOfCharacters)}...</span>}
                tooltip={<span>{favQuery.queryName}</span>}/>;
        } else {
            return <div>{favQuery.queryName}</div>;
        }
    }

    return (
        <FilterActionItemStyled
            active={isActive}
            isFlex={false}
            className={"d-flex align-items-center"}
            onClick={selectFavQueryHandler}>
            <Icon icon={`${isGlobal ? "fa-solid fa-users" : "fa-regular fa-heart"} me-2`}/>
            <DisplayName/>
        </FilterActionItemStyled>
    );
}

