import {ChartArea} from "chart.js";

export default function createGradient(ctx: CanvasRenderingContext2D,
                                       area: ChartArea,
                                       colors: Array<string>) {

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    const numOfColors= colors.length;
    // const unit = 10 / numOfColors;
    const stops :{[index:string]:Array<any>}= {
        2:[0,1],
        3:[0,0.5,1],
        4:[0,0.33,0.66,1],
        5:[0,0.25,0.5,0.75,1]
    }

    colors?.forEach((color, idx) => gradient.addColorStop(stops[numOfColors][idx], color))

    return gradient;
}
export  function createRadialGradientInt(ctx: CanvasRenderingContext2D,
                                       area: ChartArea,
                                       colors: Array<any>) {

    const gradient =   ctx.createRadialGradient(area.left, area.top, 0, area.right, area.bottom, 2);
    const numOfColors= colors.length;
    // const unit = 10 / numOfColors;
    const stops :{[index:string]:Array<any>}= {
        2:[0,1],
        3:[0,0.5,1],
        4:[0,0.33,0.66,1],
        5:[0,0.25,0.5,0.75,1]
    }

    colors?.forEach((color, idx) => gradient.addColorStop(stops[numOfColors][idx], color))

    return gradient;
}
