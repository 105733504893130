// @ts-ignore
import gradientPalette from './createGradientPaletteArray'

type Props = {
    score:number,
    maxScore:number,
    gaugeColors:Array<any>,
    inactiveColor:string
}

export default function displayColors({score,maxScore,gaugeColors,inactiveColor}:Props) {


    const colors = gradientPalette
        .setGradient( ...gaugeColors)
        .setMidpoint(maxScore*10)
        .getArray();


    return colors.map((color: any, idx: number) => idx < score * 10 ? color : inactiveColor);

}
