import React from 'react';
import {GuslThemeProvider} from '../../../../../providers/theme/GuslThemeProvider';
import GlobalStyle from '../../../../../styles/themes/globalStyle';
import '../../../../../static/css/App.css';
import '../../../../../App.css';

interface GuslUiWrapperProperties {
    children: React.JSX.Element | React.JSX.Element[]
}

export const GuslUiWrapper = (props: GuslUiWrapperProperties): React.ReactElement => {
    return (
        <GuslThemeProvider>
            <GlobalStyle/>
            {props.children}
        </GuslThemeProvider>
    )
}
