import styled from 'styled-components';

export const ExternalLinkWrapperStyled = styled.div.attrs((props) => ({}))`
  float: right;
  padding-right: 20px;
  padding-bottom: 10px;
`


export const ExternalLinkStyled = styled.div.attrs(props => ({
}))`
  color: ${props => props.theme.table.titleBgColor};
  margin: 10px;
`
