import styled from 'styled-components';

export const FloatingFormStyled = styled.div.attrs((props) => ({
    className: "form-floating"
}))`

`

export const OptionViewWrapperStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.columnColor};    
`

export const MultiSelectWrapperStyled = styled.div.attrs((props) => ({
    className: "form-control  mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light option-edit-wrapper"
}))`
  // gap seemed to big in select allowed cohorts
  // padding-bottom:40px !important;
`
export const MultiSelectFieldStyled = styled.div.attrs((props) => ({}))`
`


interface MultiSelectRowContainerProperties {
    tableView: boolean;
}

export const MultiSelectRowContainerStyled = styled.div.attrs((props: MultiSelectRowContainerProperties) => ({}))<MultiSelectRowContainerProperties>`
  position: relative;
    margin-top: 10px;    
  
`

interface MultiSelectRowItemProperties {
    tableView: boolean;
    index: number;
}

export const MultiSelectRowItemStyled = styled.div.attrs((props: MultiSelectRowItemProperties) => ({}))<MultiSelectRowItemProperties>`
  color: ${props => props.theme.table.columnColor};
  border: 1px solid white;
  padding: 3px 5px 3px 3px;
  border-radius: 3px;
  background-color: ${props => props.theme.table.panelBgColor};
  ${props => props.tableView && 'position: absolute;top: ' + (-20 + (props.index * 3)) + 'px;left:' + (props.index * 3) + 'px;'}
  
`
export const MultiSelectPanelWrapperStyled = styled.div.attrs((props) => ({}))`
  display: flex;
  padding: 5px;
`


