export const isMobile: boolean = false;

export const isPresent = (obj: any): boolean => {
    return obj !== undefined && obj !== null
}

export const isString = (obj: any): boolean => {
    return typeof obj === 'string'
}

export const isDate = (obj: any): boolean => {
    return obj instanceof Date
}

export const isArray = (obj: any): boolean => {
    return Array.isArray(obj)
}

export const isFunction = (obj: any): boolean => {
    return typeof obj === 'function'
}

export const isJson = (obj: any): boolean => {
    try {
        JSON.parse(obj)
        return true
    } catch (exception) {
        return false
    }
}

export function notBlank(obj: any): boolean {
    return obj !== undefined && obj !== null && (obj + '').trim() !== ''
}


export function isBlank(obj: any): boolean {
    return obj === undefined || obj === null || (obj + '').trim() === ''
}

export function isZero(obj: any): boolean {
    return obj === undefined || obj === null || (obj + '').trim() === '' || (obj + '').trim() === '0'
}

export function isDefined(obj: any): boolean {
    return obj !== undefined && obj !== null && '' + obj !== 'null'
}

export function notDefined(obj: any): boolean {
    return obj === undefined || obj === null
}

export function isObject(val: any): boolean {
    return val instanceof Object
}

export function isNotObject(val: any): boolean {
    return !(val instanceof Object)
}

export function arrayNotEmpty(obj: Array<any> | undefined): boolean {
    return Array.isArray(obj) && obj.length > 0
}

export function arrayIsEmpty(obj?: Array<any>): boolean {
    return obj === undefined || obj === null || (Array.isArray(obj) && obj.length === 0)
}
