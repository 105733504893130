import React, {useState} from 'react';
import {RequestQuote} from './bespoke/request-quote/RequestQuote';
import {TradePanel} from './bespoke/trade/TradePanel';
import {AvatarField} from './common/avatar/AvatarField';
import {BadgeField} from "./common/badge/BadgeField";
import {BreadcrumbField} from './common/breadcrumb/BreadcrumbField';
import {BuySellForm} from './common/buy-sell-form/BuySellForm';
import {BuySellField} from "./common/buy-sell/BuySellField";
import {CardField} from './common/card/CardField';
import {ChartField} from './common/chart/ChartField';
import {CheckboxField} from './common/checkbox/CheckboxField';
import {CircleField} from './common/circle/CircleField';
import {CodeField} from './common/code/CodeField';
import {CohortLogoField} from './common/cohort-logo/CohortLogoField';
import {ColorField} from './common/color/ColorField';
import {ColorsField} from './common/color/ColorsField';
import {CommentField} from './common/comment/CommentField';
import {CronField} from "./common/cron/CronField";
import {DateSummaryField} from './common/date-summary/DateSummaryField';
import {DateField} from './common/date/DateField';
import {DecimalField} from './common/decimal/DecimalField';
import {ExternalLinkField} from './common/external-link/ExternalLinkField';
import {FavouriteField} from './common/favourite/FavouriteField';
import {FileUploadField} from './common/file-upload/FileUpload';
import {FontSizeField} from './common/font-size/FontSizeField';
import {GrapeJsField} from './common/grapejs/GrapeJsField';
import {HtmlField} from './common/html/HtmlField';
import {IframeField} from './common/iframe/IframeField';
import {ImageUploaderField} from './common/image-uploader/ImageUploaderField';
import {ImageField} from './common/image/ImageField';
import {TableLinkField} from "./common/link/TableLinkField";
import {LookupModal} from './common/lookup-modal/LookupModal';
import {LookupField} from './common/lookup/LookupField';
import {MoneyField} from './common/money/MoneyField';
import {MultiLookupField} from "./common/multi-lookup/MultiLookupField";
import {MultiSelectField} from './common/multi-select/MultiSelectField';
import {NestedTableField} from './common/nested-table/NestedTableField';
import {NumberField} from './common/number/NumberField';
import {OddsField} from './common/odds/OddsField';
import {OptionAsyncField} from './common/option-async/OptionAsyncField';
import {OptionGroupsField} from './common/option-groups/OptionGroupsField';
import {OptionListField} from './common/option-list/OptionListField';
import {OptionField} from './common/option/OptionField';
import {PasswordField} from './common/password/PasswordField';
import {PercentField} from "./common/percent/PercentField";
import {PermissionsField} from './common/permissions/PermissionsField';
import {PixelField} from './common/pixel/PixelField';
import {PositionField} from "./common/position/PositionField";
import {RadialMeterField} from './common/radial-meter/RadialMeterField';
import {ReportField} from './common/report/ReportField';
import {RiskGainRadioField} from './common/risk-gain/RiskGainRadioField';
import {RuleActionField} from './common/rule-action/RuleActionField';
import {RuleConditionField} from './common/rule-condition/RuleConditionField';
import {ScoreField} from './common/score/ScoreField';
import {SubPanelField} from './common/sub-panel/SubPanelField';
import {SymbolField} from './common/symbol/SymbolField';
import {TextAreaField} from './common/text-area/TextAreaField';
import {TextCommentField} from './common/text-comment/TextCommentField';
import {TextField} from './common/text/TextField';
import {ThemeCollectionField} from './common/theme-definition/ThemeCollectionField';
import {ThemeDefinitionField} from './common/theme-definition/ThemeDefinitionField';
import {ThemeField} from './common/theme-field/ThemeField';
import {TimezoneField} from './common/timezone/TimezoneField';
import {TotalField} from './common/total/TotalField';
import {WysiwygField} from './common/wysiwyg/WysiwygField';


export const LEFT_HAND_MENU_COMMAND = 'toggle-side-menu'
export let FIELD_COMPONENT_MAP: { [id: string]: any } = {}


export const FieldComponentLoader = (): React.ReactElement => {

    const [refreshCounter, setRefreshCounter] = useState<number>(() => {
        return new Date().getTime()
    })


    FIELD_COMPONENT_MAP = {
        BuySellForm: BuySellForm,
        RequestQuote: RequestQuote,
        TradePanel: TradePanel,
        avatar: AvatarField,
        badge: BadgeField,
        breadcrumb: BreadcrumbField,
        buy_sell: BuySellField,
        card: CardField,
        chart: ChartField,
        checkbox: CheckboxField,
        circle: CircleField,
        code: CodeField,
        cohort_logo: CohortLogoField,
        color: ColorField,
        colors: ColorsField,
        comment: CommentField,
        cron: CronField,
        date: DateField,
        date_summary: DateSummaryField,
        date_time: DateField,
        decimal: DecimalField,
        external_link: ExternalLinkField,
        favourite: FavouriteField,
        file_upload: FileUploadField,
        font_size: FontSizeField,
        html: HtmlField,
        iframe: IframeField,
        image: ImageField,
        image_uploader: ImageUploaderField,
        link: TableLinkField,
        lookup: LookupField,
        lookup_modal: LookupModal,
        money: MoneyField,
        multi_lookup: MultiLookupField,
        multi_select: MultiSelectField,
        nested_table: NestedTableField,
        new_money: MoneyField,
        number: NumberField,
        odds: OddsField,
        option: OptionField,
        option_async: OptionAsyncField,
        option_groups: OptionGroupsField,
        option_list: OptionListField,
        password: PasswordField,
        percent: PercentField,
        permissions: PermissionsField,
        pixel: PixelField,
        position: PositionField,
        radial_meter: RadialMeterField,
        report: ReportField,
        rule_action: RuleActionField,
        rule_condition: RuleConditionField,
        score: ScoreField,
        sub_panel: SubPanelField,
        symbol: SymbolField,
        text: TextField,
        text_comment: TextCommentField,
        textarea: TextAreaField,
        theme: ThemeField,
        theme_collection: ThemeCollectionField,

        // used by card to pick up bespoke pages
        theme_definition: ThemeDefinitionField,
        timezone_option: TimezoneField,
        total: TotalField,
        risk_gain_radio: RiskGainRadioField,
        grape: GrapeJsField,
        wysiwyg: WysiwygField

    };
    return (
        <div id={'loader_' + refreshCounter} key={'loader_' + refreshCounter} />
    )
}
