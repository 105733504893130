// -------------- config
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ActiveUserTogglePayload {
    showBOUsers: boolean,
    showFEUsers: boolean,
}

export interface ActiveUsersState {
    showBOUsers: boolean,
    showFEUsers: boolean,
}

const initialState: ActiveUsersState = {
    showBOUsers: false,
    showFEUsers: false,

}

export const activeUsersToggleSlice = createSlice({
    name: 'activeUsersToggleSlice',
    initialState,
    reducers: {
        toggleActiveUsers(state, action: PayloadAction<ActiveUserTogglePayload>) {
            state.showBOUsers = action.payload.showBOUsers
            state.showFEUsers = action.payload.showFEUsers
        },
    },
})
export const {
    toggleActiveUsers,
} = activeUsersToggleSlice.actions

export default activeUsersToggleSlice.reducer
