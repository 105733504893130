import styled, {ThemeProps} from 'styled-components';

export const PaginationWrapperStyled = styled.div.attrs((props) => ({
    id: 'pagination_wrapper'
}))
    `
        display: flex;
        text-align: center;
        margin: 0 auto;
        width: 400px;
    `

interface RowsPerPageContainerStyledProps extends ThemeProps<any> {
    isMobile?: boolean,
}

export const RowsPerPageContainerStyled = styled.div.attrs((props: RowsPerPageContainerStyledProps) => ({
    className: "d-flex align-items-center justify-content-start mx-2"
}))<RowsPerPageContainerStyledProps>`
    //width: 60px;
    margin-top: ${props => props.isMobile ? '4px' : '4px'} !important;
`

export const RowsPerPageWrapperStyled = styled.div.attrs((props) => ({
}))`
    display: inline-flex;
    min-width: 60px;
    vertical-align: middle;    
`

export const PaginationRowsPerPageLookupStyled = styled.div.attrs((props) => ({
    id: props.id,
}))`
    z-index: 20;
    width: 60px;
    position: relative;
    //left: -50px;
    //top: 35px;
    height: 20px;

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }
;

    ::-webkit-scrollbar-track {
        background: ${props => props.theme.table.panelBgColor};

    }
;

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.dark[0]};
        border: 1px solid ${props => props.theme.colors.dark[1]};
        border-radius: 10px;

    }
;

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.table.titleBgColor};
    }
;
`

export const PaginationFromToStyled = styled.div.attrs((props) => ({
    className : 'pagination-label small text-muted'
}))`
    min-width: 100px;
    //font-size: 0.7em;
    display: inline-flex;
    margin-top: 15px
`

export const PaginationButtonsStyled = styled.div.attrs((props) => ({
}))`
    margin-left: 10px;
    margin-top: 4px;
`
