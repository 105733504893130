import styled, { ThemeProps } from "styled-components";
import { HEADER_HEIGHT } from "../../providers/theme/GuslThemeProvider";
import Icon from "../common/maintain-table/bootstrap/Icon";

interface FlexListProperties extends ThemeProps<any> {
  isMobile: boolean;
}


export const FlexListTitleWrapperStyled = styled.div.attrs((props) => ({
  role: "toolbar",
  className: "row g-0 d-flex align-items-center justify-content-between "
}))
  `
    background-color: ${props => props.theme.table.titleBgColor};
    padding: ${props => props.theme.table.titlePadding};
  `;

interface FlexListPanelProperties extends ThemeProps<any> {
  isMobile: boolean
  listStartPos: number,

}

export const FlexListSectionWrapperStyled = styled.div.attrs((props) => ({}))
  `

  `;

interface FlexListSectionItemsWrapperProperties {
  isMobile: boolean;
}

/*
removed nova events
      ${props => props.isMobile && 'max-width: ' + MOBILE_WIDTH + 'px;'};

 */
export const FlexListSectionItemsWrapperStyled = styled.div.attrs((props: FlexListSectionItemsWrapperProperties) => ({}))<FlexListSectionItemsWrapperProperties>
  `
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 auto;

  `;

/*
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + (props.isMobile ? FOOTER_HEIGHT : 0) + TAB_HEIGHT) + 'px)'

 */
export const FlexListSectionStyled = styled.div.attrs((props: FlexListPanelProperties) => ({}))<FlexListPanelProperties>`
  height: ${props => {
    if (props.isMobile) {
      return "calc(var(--internal-height) + (props.isMobile ? FOOTER_HEIGHT : 0)" + (props.listStartPos) + "px)";
    }
    return "inherit";
  }};

  overflow: ${props => {
    if (props.isMobile) {
      return "auto";
    }
    return "inherit";
  }};

`;
/*
  height: ${props => {
    if (props.isMobile) {
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + (props.isMobile ? FOOTER_HEIGHT : 0)) + 'px)'
    }
    return 'inherit'
  }};

 */
export const FlexListPanelStyled = styled.div.attrs((props: FlexListPanelProperties) => ({}))<FlexListPanelProperties>`
  height: 100%;
  overflow: ${props => {
    if (props.isMobile) {
      return "auto";
    }
    return "inherit";
  }};

  display: ${props => props.isMobile ? "block" : "flex"};
  flex-wrap: ${props => props.isMobile ? "inherit" : "wrap"};
  padding: 10px;
`;
export const FlexListTitleStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.titleColor};
  font-size: ${props => props.theme.table.titleFontSize};
`;

interface FlexListRowProperties extends ThemeProps<any> {
  isMobile: boolean;
}

export const FlexListRowStyled = styled.div.attrs((props: FlexListRowProperties) => ({}))<FlexListRowProperties>`
  padding-bottom: 5px;
  text-align: center;
  margin: auto;
`;

interface FlexListColumnProperties extends ThemeProps<any> {

}

export const FlexListColumnStyled = styled.div.attrs((props: FlexListColumnProperties) => ({}))<FlexListColumnProperties>`
  color: ${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};

`;
export const BreadcrumbsWrapperStyled = styled.div.attrs((props) => ({}))
  `
    background-color: ${props => props.theme.table.titleBgColor};
    padding: ${props => props.theme.table.titlePadding};
    color: ${props => props.theme.table.titleColor};
    font-size: ${props => props.theme.table.titleFontSize};
  `;

export const FilterActionContainerStyled = styled.div.attrs((props) => ({}))
  `
      width: 100%;
      padding-top: 10px;
  `;

interface FilterActionWrapperProperties {
  isFlex: boolean;
}

export const FilterActionWrapperStyled = styled.div.attrs((props: FilterActionWrapperProperties) => ({
  id: 'faw'
}))<FilterActionWrapperProperties>
  `
    display: flex;
    overflow: auto;
    white-space: nowrap;
    ${props => !props.isFlex && "border-bottom: solid 1px " + props.theme.colors.dark[0] + ";"};
    ${props => props.isFlex && "border-bottom: solid 1px " + props.theme.colors.dark[0] + ";"};
    ${props => !props.isFlex && "padding-bottom: 5px;"}


    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }

      ::-webkit-scrollbar-track {
          background: ${props => props.theme.table.panelBgColor};
      }

      ::-webkit-scrollbar-thumb {
          background: ${props => props.theme.colors.dark[0]};
          border: 1px solid ${props => props.theme.colors.dark[1]};
          border-radius: 10px;

      }

      ::-webkit-scrollbar-thumb:hover {
          background: ${props => props.theme.table.titleBgColor};
      }
      
  `
// .colors.dark[1]
interface FilterActionItemProperties {
  active: boolean;
  isFlex: boolean;
  className?:string;
}

/*
      ${props => !props.isFlex && "background-color: " + (props.active ? props.theme.table.columnColor : props.theme.panel.panelBgColor) +"px;"};
    ${props => !props.isFlex && "color: " + (props.active ? props.theme.panel.panelBgColor : props.theme.table.columnColor) +';'};

 */
export const FilterActionItemStyled = styled.div.attrs((props: FilterActionItemProperties) => ({
  className: props.className ? props.className : ""
}))<FilterActionItemProperties>
  `
    ${props => !props.isFlex && "padding: 7px 5px 5px 5px;"}
    ${props => props.isFlex && "padding: 5px 0px 5px 5px;"}

    ${props => !props.isFlex && "background-color: " + (props.active ? props.theme.table.titleBgColor : props.theme.colors.dark[0]) + ";"};
    ${props => !props.isFlex && "color: " + (props.active ? props.theme.colors.light : props.theme.colors.dark[1]) + ";"};
    ${props => !props.isFlex && props.active && "font-weight: bold;"}

    ${props => !props.isFlex && "font-size: 10px;"};

    ${props => props.isFlex && "background-color: " + props.theme.table.titleBgColor + ";"};
    ${props => props.isFlex && "color: " + props.theme.table.titleColor + ";"};
    ${props => props.isFlex && "font-size: " + props.theme.table.titleFontSize + ";"};

    text-transform: uppercase;
    cursor: pointer;
    ${props => !props.isFlex && "border: solid 1px " + props.theme.colors.dark[0] + ";"};

    ${props => !props.isFlex && "margin-right: 5px;"}
    ${props => !props.isFlex && "border-radius: 3px;"}
    &:hover {
      text-decoration: underline;
    }

    ${props => props.isFlex && "&:after {content: '';padding-left: 5px;padding-right: 5px;border-right: solid 1px " + props.theme.table.columnColor + ";color: " + props.theme.table.columnColor + ";}"};

  `;

interface GroupByNameProperties {
  isOpen: boolean;
}

export const GroupByNameStyled = styled.div.attrs((props: GroupByNameProperties) => ({}))<GroupByNameProperties>
  `
    width: 100%;
    background-color: ${props => props.isOpen ? props.theme.table.titleBgColor : "inherit"};
    color: ${props => props.isOpen ? props.theme.table.titleColor : props.theme.table.columnHeader};
    font-size: ${props => props.theme.table.titleFontSize};
    padding: ${props => props.theme.table.titlePadding};
  `;
export const OpenIconStyled = styled(Icon).attrs((props: GroupByNameProperties) => ({
  icon: props.isOpen ? "fa fa-chevron-down" : "fa fa-chevron-right"
}))<GroupByNameProperties>`
  color: ${props => props.isOpen ? props.theme.table.titleColor : props.theme.table.columnHeader};
  float: right;
`;

export const TableControlsStyled = styled.div.attrs((props) => ({
  id: "table_control"
}))
  `
    width: 100%;
  `;

export const OrderByActionsStyled = styled.div.attrs((props) => ({
  id: "order_by_actions"
}))
  `
    text-align: right;
    margin: auto;
    padding: 5px;
    font-size: 16px !important;
  `;
export const SearchBoxWrapperStyled = styled.div.attrs((props) => ({
    id:'search_box_wrapper'
}))
  `
    padding: 5px;

    .float-end {
      float: none !important;;
    }
  `;

export const NoDataStyled = styled.div.attrs((props) => ({}))
  `
    padding: 5px;
  `;

interface MainContainerProperties {
  rendered: boolean;
}

export const MainContainerStyled = styled.div.attrs((props: MainContainerProperties) => ({}))<MainContainerProperties>
  `
    display: ${props => props.rendered ? "block" : "none"};
    // padding: 5px;
  `;
