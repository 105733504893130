import React, {useContext, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../../../app/hooks';
import {toggleSplitScreen} from "../../../../features/ui/uiSlice";
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../../providers/session/SessionContext';
import {isBlank, isDefined} from '../../../../utils/TypeCheckers';
import {
    areMenusMediaTypeSensitive,
    constructUrl,
    countChildrenWithMediaTypeWithRequiredAndSensitivity,
    matchMediaTypeWithRequiredAndSensitivity
} from '../../../../utils/Utils';
import {setActiveAction} from '../../../common/action-dialog/actionSlice';
import {maintainTableService} from '../../../common/maintain-table/MaintainTableService';
import {LEFT_HAND_MENU_COMMAND} from '../../../FieldComponentMap';
import {IMenuDTO, MediaType} from '../../../types';
import {Badge} from '../badge/Badge';
import {IconStyled} from '../navbar/styled';
import {handleNavigationClicks} from "../site-tabs/handleNavigationClicks";
import {openDialog} from './footerOverlaySlice';
import {
    MenuGroupHeaderStyled,
    MenuItemsStyled,
    MenuItemStyled,
    MobileFooterButtonLabelStyled,
    MobileFooterButtonStyled,
    MobileFooterLogoStyled,
    MobileIconStyled
} from './styled';

export type MenuGroupProperties = {
    menuGroup: IMenuDTO,
    idx: number,

    mediaTypeSensitive: boolean,
    isMobile?: boolean
}


const MenuGroup = (props: MenuGroupProperties): React.ReactElement => {
    const [className] = useState('MenuGroup-' + new Date().getTime());
    const environmentContext = React.useContext(EnvironmentContext)
    const sessionContext = useContext(SessionContext);
    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(props.menuGroup.alwaysOpen || false);
    const [assetsUrl] = useState<string>(environmentService.getEnvironment()?.assetsUrl || '');
    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType());
    const isMobile = environmentContext.isMobileDevice();

    const logoutHandler = () => {
        sessionContext.signOut().then(() => {
        }).catch((error: any) => console.error('logout error', error));
        navigate("/login");
    }

    const printScreenHandler = () => {
        window.print();
    }

    const toggleSplitScreenHandler = () => {
        dispatch(toggleSplitScreen());
    }

    const toggleSideMenu = () => {
        environmentService.toggleSideMenu()
    }

    const commands: {
        [id: string]: Function
    } = {
        print: () => printScreenHandler(),
        logout: () => logoutHandler(),
        toggleView: () => toggleSplitScreenHandler(),
        'toggle-side-menu': () => toggleSideMenu()
    };

    const onItemClick = (event: React.MouseEvent, item: IMenuDTO, setClose?: Function) => {
        event.stopPropagation();


        handleNavigationClicks({event, item, dispatch});

        if (item.command) {
            if (LEFT_HAND_MENU_COMMAND !== item.command) {
                environmentService.closeSideMenu()
            }
            commands[item.command]();
            return;
        }

        if (isMobile) {
            environmentService.closeSideMenu()
        }

        if (item.route === 'BetSlip' || (item.overlay && item.route)) {
            if (isMobile) {
                dispatch(openDialog({
                    title: item?.label || '',
                    route: 'RequestQuote' /*item.route*/,
                    placement: 'bottom'
                }))
                return;
            }
        }

        if (item.route) {
            // console.log('-- closing panel')
            // dispatch(panelClosed({code: ''}))


            const url = ("/" + constructUrl(item.route, item)).replace('//', '/')
            navigate(url);
            return;
        }

        if (item.action) {
            // console.log('item action====>',item.action.actionType === 'ROUTE')
            if (item.action.actionType === 'ROUTE') {
                const url = ("/" + constructUrl(item.action.route as string, item)).replace('//', '/')
                navigate(url)
                return
            } else {
                dispatch(setActiveAction({action: item.action}))
                return;
            }

        }

        if (item.code) {
            navigate('/' + item.code);
        }

    };

    const renderCaret = (): React.ReactElement => {
        return <span className={isOpen ? 'orderBy-asc' : 'orderBy-desc'}> <span className={'caret'}/></span>
    }

    const renderLabel = (menuGroup: IMenuDTO, isItem?: boolean): React.ReactElement => {
        if (menuGroup.iconOnly && isDefined(menuGroup.icon)) {
            return <IconStyled icon={menuGroup.icon || ''} $isItem={isItem || false}/>
        } else if (isDefined(menuGroup.icon)) {
            return <IconStyled icon={menuGroup.icon || ''} label={menuGroup.label}
                               $isItem={isItem || false}/>
        } else {
            return <IconStyled label={menuGroup.label} $isItem={isItem || false}/>
        }
    }

    const renderItem = (menuItem: IMenuDTO, idx: number): React.ReactElement => {

        return <MenuItemStyled
            idx={idx}
            key={'mi_' + idx}
            id={'mi_' + idx}
            onClick={(e) => onItemClick(e, menuItem)}
        > {renderLabel(menuItem, true)}
            <Badge menuItem={menuItem}/>
        </MenuItemStyled>
    }

    const renderChildren = (menuGroup: IMenuDTO, idx: number): React.ReactElement => {
        const mediaTypeSensitive = areMenusMediaTypeSensitive((menuGroup?.menuItems || []));
        return <MenuItemsStyled key={'mis_' + idx} id={'mis_' + idx}>

            {(menuGroup?.menuItems || [])
                .filter(menuItem => menuItem.showInSideBar)
                .filter(menuItem => matchMediaTypeWithRequiredAndSensitivity(menuItem.mediaType, mediaType, mediaTypeSensitive))
                .map((menuItem, index: number) => {
                        return renderItem(menuItem, index)
                    }
                )}
        </MenuItemsStyled>
    }

    const renderWithChildren = (menuGroup: IMenuDTO, idx: number): React.ReactElement => {
        return (
            <MenuGroupHeaderStyled
                key={'mg_' + idx}
                id={'mg_' + idx}
                idx={idx}
                $hasChildren={true}
                isMobile={props.isMobile}
                onClick={() => setIsOpen(!isOpen)}
            >
                {renderLabel(menuGroup)}
                {renderCaret()}
                {isOpen && renderChildren(menuGroup, idx)}
            </MenuGroupHeaderStyled>
        )
    }

    const renderAsMobileButton = (menuGroup: IMenuDTO): React.ReactElement => {
        const isFontAwesome = (menuGroup?.icon || '').indexOf('fa-') >= 0 || (menuGroup?.icon || '').indexOf('fas-') >= 0
        const isImg = !isFontAwesome && menuGroup.icon

        // console.log('location.pathname', location.pathname, 'url:' + maintainTableService.getFirstPartFromUrl(), 'r:' + props?.menuGroup?.route, maintainTableService.getFirstPartFromUrl() === props?.menuGroup?.route)

        const active: boolean = maintainTableService.getFirstPartFromUrl() === props?.menuGroup?.route

        return <MobileFooterButtonStyled active={active} id={'btn_' + menuGroup.code}
                                         onClick={(e) => onItemClick(e, props.menuGroup)}>


            {(menuGroup.iconOnly || isImg) &&
                <MobileFooterLogoStyled src={assetsUrl + menuGroup.icon || ''} alt={menuGroup.label}/>}

            {(menuGroup.iconOnly || isFontAwesome) &&
                <MobileIconStyled icon={menuGroup.icon || ''}/>}

            {!menuGroup.iconOnly &&
                <MobileFooterButtonLabelStyled
                    active={location.pathname === '/' + props.menuGroup.route}>{menuGroup.label}</MobileFooterButtonLabelStyled>}
        </MobileFooterButtonStyled>
    }

    // GBW 31/08 was true
    let numValidChildren = countChildrenWithMediaTypeWithRequiredAndSensitivity(props.menuGroup, mediaType, props.mediaTypeSensitive)


    if (numValidChildren === 0) {
        // if ((props.menuGroup.menuItems?.length || 0) === 0) {
        if (isBlank(props.menuGroup.route) && isBlank(props.menuGroup.command) && isBlank(props.menuGroup.action)) {
            return <></>
        }

        if (props.isMobile) {
            return renderAsMobileButton(props.menuGroup)
        }


        return <div key={'yyy_' + props.idx} id={'yyy_' + props.idx}>
            <MenuGroupHeaderStyled
                isMobile={props.isMobile}
                $hasChildren={false}
                onClick={(e) => onItemClick(e, props.menuGroup)}
                idx={props.idx}>{renderLabel(props.menuGroup)}</MenuGroupHeaderStyled>
        </div>
    } else {
        return <div key={'xxx_' + props.idx} id={'xxx_' + props.idx}>
            {renderWithChildren(props.menuGroup, props.idx)}
        </div>
    }
}

export default MenuGroup;
