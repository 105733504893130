export class Instruction {
    _operation: string;
    _path: string;
    _collection: string;
    _changes: any[];
    _record: any;

    constructor(jsonObject: any) {
        this._operation = jsonObject['operation'];
        this._path = jsonObject['path'];
        this._collection = jsonObject['collection'];
        this._changes = jsonObject['changes'];
        this._record = jsonObject['record'];
    }

    getOperation(): string {
        return this._operation;
    }

    getPath(): string {
        return this._path;

    }

    getCollection(): string {
        return this._collection;

    }

    getChanges(): any[] {
        return this._changes;

    }

    getRecord(): any {
        return this._record;

    }
}
