import AutoNumeric, {Options} from 'autonumeric';
import React, {useEffect, useRef, useState} from 'react';
import {CurrencyDTO, MoneyDTO} from '../../../types';
import {StakeAmountInputStyled, StakeInputStyled, StakeWrapperStyled} from '../styled';

interface StakeAmountProperties {
    riskAmount: MoneyDTO,
    onChange: (riskAmount: MoneyDTO) => void,
    submitted?: boolean,
    disabled?: boolean,
}

const getRiskValueAsNumeric = (riskAmount: MoneyDTO) => {
    if (riskAmount && riskAmount.value != 0) {
        return riskAmount.value / Math.pow(10, riskAmount?.currency?.decimalPlaces || 2);
    }
    return undefined

}
export const StakeAmount = ({
                                riskAmount,
                                onChange,
                                submitted,
                                disabled,
                            }: StakeAmountProperties): React.ReactElement<StakeAmountProperties> => {
    const [formValue] = useState<number | undefined>(() => getRiskValueAsNumeric(riskAmount))
    const valueRef = useRef<any>('');
    const [fieldValue, setFieldValue] = useState<AutoNumeric | undefined>();
    const currency: CurrencyDTO = riskAmount?.currency;

    useEffect(() => {
        window.requestAnimationFrame(function () {
            try {
                if (valueRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(valueRef.current)) {
                        const options: Options = {
                            currencySymbol: '   ' + riskAmount.currency?.code || '',
                            modifyValueOnWheel: false,
                            decimalPlaces: riskAmount.currency?.decimalPlaces || 2,
                            selectOnFocus: false,
                            styleRules: {
                                positive: 'autoNumeric-positive',
                                negative: 'autoNumeric-negative'
                            },
                            currencySymbolPlacement: 's',
                            minimumValue: '0'
                        }
                        const valueElement = new AutoNumeric(valueRef.current, getRiskValueAsNumeric(riskAmount), options)
                        valueElement.node().addEventListener("input", (event: Event) => {
                            // @ts-ignore
                            const value = parseFloat(((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', '')), 10)
                            try {
                                if (onChange) {
                                    onChange({
                                        currency: currency,
                                        value: value * Math.pow(10, currency.decimalPlaces || 2)
                                    })
                                }
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setFieldValue(valueElement)
                    } else {
                        // ensure tested as was messing up entry
                        // @ts-ignore
                        // fieldValue.set(properties?.data?.value === 0 ? '' : (properties?.data?.value || 0) / Math.pow(10, currency.decimalPlaces || 2))
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [riskAmount])

    const numOfDecimals: number = 0;
    const maxNumberOfDecimals = 10;
    let stepMap: { [index: number]: number } = {}

    Array.from({length: maxNumberOfDecimals}).forEach((_, idx) => {
        stepMap[idx] = 1 / Math.pow(10, idx);
    })
    const step = stepMap[numOfDecimals];


    const onDataInputChange = (value: string) => {

        try {
            const number = parseFloat(value);
            if (!isNaN(number) && onChange) {
                onChange({currency: riskAmount.currency, value: ((number || 0) * Math.pow(10, 2))})
            }
        } catch (err) {
            // log.warn(className, 'WRN001', 'not a number', value);
        }
    }

    return (
        <StakeWrapperStyled>
            <StakeInputStyled>
                <StakeAmountInputStyled type={'text'}
                                        ref={valueRef}
                                        autoFocus={true}
                    // step={step}
                                        id={'riskAmount'}
                                        placeholder={'Stake'}
                    // onChange={(e) => onDataInputChange(e.target.value)}
                                        required={true}
                                        submitted={submitted || false}
                                        disabled={disabled || false}
                                        noValue={!formValue}
                    // value={formValue}
                />
            </StakeInputStyled>
        </StakeWrapperStyled>
    )
}
