import {BehaviorSubject, Observable} from 'rxjs'
import {isNotObject} from '../../utils/TypeCheckers';
import {CONTENT_START_POSITION, updateMainToolbarHeight} from '../theme/GuslThemeProvider';
import {IEnvironment, MenuOrientation} from './types';

class EnvironmentService {
    protected environment: IEnvironment | null = null;
    protected environmentSubject: BehaviorSubject<IEnvironment> = new BehaviorSubject<IEnvironment>({loaded: false} as IEnvironment);
    protected sideMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    protected menuOrientation: BehaviorSubject<MenuOrientation> = new BehaviorSubject<MenuOrientation>(MenuOrientation.LEFT);

    protected footerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    protected headerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(CONTENT_START_POSITION);

    public getEnvironment = (): IEnvironment | null => {
        return this.environment;
    }
    public setEnvironment = (environment: IEnvironment | null): void => {
        this.environment = environment
        if (environment) {
            this.environmentSubject.next(environment);
        }
    }

    public watchEnvironment = (): Observable<IEnvironment> => {
        return this.environmentSubject.asObservable();
    }

    public watchMenuOrientation = (): Observable<MenuOrientation> => {
        return this.menuOrientation.asObservable();
    }

    public setMenuOrientation = (menuOrientation: MenuOrientation): void => {
        this.menuOrientation.next(menuOrientation);
    }

    public watchSideMenu = (): Observable<boolean> => {
        return this.sideMenuSubject.asObservable();
    }

    public toggleSideMenu = (): void => {
        this.sideMenuSubject.next(!this.sideMenuSubject.getValue())
    }
    public closeSideMenu = (): void => {
        this.sideMenuSubject.next(false)
    }

    public openSideMenu = (): void => {
        this.sideMenuSubject.next(true)
    }

    public getGoogleSSOClientId(): string {
        return this.environment?.googleSSO || '';
    }

    public setFooterHeight = (height: number): void => {
        this.footerHeight.next(height)
    }

    public setHeaderHeight = (height: number): void => {
        updateMainToolbarHeight(height)
        this.headerHeight.next(height)
    }

    public watchHeaderHeight = (): Observable<number> => {
        return this.headerHeight.asObservable();
    }

    public watchFooterHeight = (): Observable<number> => {
        return this.footerHeight.asObservable();
    }

    public getImageSrc(
        imageSrc: string | Object,
        assetsUrl: string,
        imageSuffix: string | undefined,
        noImageSuffix?: boolean | undefined
    ): string {
        if (isNotObject(imageSrc)) {
            // only add image suffix, if internal
            // @ts-ignore
            const src: string = imageSrc
            if (!src || src.startsWith('http')) {
                // @ts-ignore
                return src
            }
            return ((assetsUrl + this.addImageSuffix(src, imageSuffix, noImageSuffix)) || '')
        } else {
            // @ts-ignore
            return imageSrc?.name;
        }
    }

    public addImageSuffix(logoUrl?: string,
                          suffix?: string,
                          noImageSuffix?: boolean | undefined
    ): string | undefined {
        if (!suffix || !logoUrl || noImageSuffix) {
            return logoUrl
        }
        const period = logoUrl.lastIndexOf('.')
        if (period < 0) {
            return logoUrl + '-' + suffix
        }
        return logoUrl.substring(0, period) + '-' + suffix + logoUrl.substring(period)
    }
}


const environmentService = new EnvironmentService();

export {environmentService};

export default EnvironmentService;
