import React, {useEffect, useRef, useState} from 'react';
import {Subscription} from 'rxjs';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {log} from '../../../services/LogService';
import {isMobile} from '../../../utils/TypeCheckers';
import {RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import LoadingSpinner from '../../common/loading-spinner/LoadingSpinner';
import {setOverlayTitle} from '../../ui/navigation/left-hand-menu/footerOverlaySlice';
import {BetSlipState, betSlipTabSelected, openBetSlip, OpenBetSlipResponseWrapper} from './betSlipSlice';
import {BetSlipPanelStyled, BetSlipTabPanelStyled, BetSlipTabsStyled, BetSlipTabStyled} from './styled';
import {BrokerageTab} from './tabs/brokerage/BrokerageTab';
import {OpenBetsTab} from './tabs/open-bets/OpenBetsTab';
import {ParlaysTab} from './tabs/parlays/ParlaysTab';
import {SinglesTab} from './tabs/singles/SinglesTab';
import {TAB_CODES, TabDO} from './types';

const GET_OPEN_BET_SLIP_URL = '/event-list/open-bet-slip'

interface BetSlipProperties {
    onClose?: () => void;
    eventId?: string;
    marketId?: string;

}

export const BetSlip = ({onClose, eventId, marketId}: BetSlipProperties): React.ReactElement<BetSlipProperties> => {
    const [className] = useState('BetSlip-' + new Date().getTime());

    const dispatch = useAppDispatch();
    const sessionContext = React.useContext(SessionContext)

    const _betSlipState: BetSlipState = useAppSelector(state => state.betSlipSlice);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [currentAbortController, setCurrentAbortController] = useState<AbortController | undefined>(undefined);
    const [contentHeight, setContentHeight] = useState<number>(300);
    const contentElement = useRef(null);
    const [startPosition, setStartPosition] = useState<number>(100);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (contentElement) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setContentHeight(window.innerHeight - rect.top - footerHeight - 2)
                        setStartPosition(rect.y)
                    }
                }

            }, 100)
        });
    }, [loaded]);

    dispatch(setOverlayTitle({title: 'BETSLIP'}))

    useEffect(() => {
        setLoaded(false)
        const abortController = new AbortController();
        setCurrentAbortController(abortController);

        dispatch(openBetSlip({
            url: GET_OPEN_BET_SLIP_URL,
            sessionContext: sessionContext,
            abortController: abortController,
            eventId: eventId,
            marketId: marketId
        }))
            .unwrap()
            .then((reply: OpenBetSlipResponseWrapper) => {
                setLoaded(true)
                console.log('---------> reply', reply)

            })
            .catch((error: any) => {
                log.error(className, 'ERR001', error)
                setLoaded(true)
            })

    }, [eventId, marketId]);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
            }, 100)
        });
    }, [loaded]);


    const renderTabLabel = (tab: TabDO): React.ReactElement => {
        return <BetSlipTabStyled key={tab.code}
                                 active={tab.code === _betSlipState?.currentTab?.code}
                                 onClick={() => dispatch(betSlipTabSelected({tab: tab}))}
        >{tab.label}</BetSlipTabStyled>
    }

    const renderTabLabels = (): React.ReactElement => {
        return (
            <BetSlipTabsStyled>
                {_betSlipState?.tabs && _betSlipState?.tabs.map((tab: TabDO) => renderTabLabel(tab))}
            </BetSlipTabsStyled>
        )

    }
    const renderTabContents = (): React.ReactElement => {
        switch (_betSlipState?.currentTab?.code) {
            case TAB_CODES.SINGLES:
                return <SinglesTab onClose={onClose}/>
            case TAB_CODES.PARLAYS:
                return <ParlaysTab onClose={onClose}/>
            case TAB_CODES.BROKERAGE:
                return <BrokerageTab onClose={onClose}/>
            case TAB_CODES.OPEN_BETS:
                return <OpenBetsTab onClose={onClose}/>
        }
        return <></>
    }
    const renderPanel = (): React.ReactElement => {

        return (
            <BetSlipPanelStyled>
                {renderTabLabels()}
                <BetSlipTabPanelStyled ref={contentElement} height={contentHeight} startPosition={startPosition}
                                       isMobile={isMobile}>
                    {renderTabContents()}
                </BetSlipTabPanelStyled>
            </BetSlipPanelStyled>
        )
    }
    return (
        <>{!loaded ? <LoadingSpinner/> : renderPanel()}</>
    )

}
