import { Capacitor } from "@capacitor/core";
import React, { createContext, ReactNode, useState } from "react";
import { ThemeProvider } from "styled-components";
import {isObject} from '../../utils/TypeCheckers';
import { guslStorage } from "../session/GuslStorage";
import defaultTheme from "./themes/defaultTheme";
import { GuslThemeProperties } from "./types";
import { defaultCellWidth, inlineEditCellWidth } from "../../components/common/gusl-table/guslTableSlice";


export const ACTION_ITEM_WIDTH: number = 30;

// height from top of page to top of table
export const HEADER_HEIGHT: number = 80;
export const TAB_HEIGHT: number = 65;


export const LHM_WIDTH: number = 300;

// see App.css --gusl-mobile-footer-bar
export const FOOTER_HEIGHT: number = 50;

export const TANDC_FOOTER_HEIGHT: number = 170;

export const FOOTER_ICON_FONT_SIZE: number = 12;

export const LOGIN_IMAGE_HEIGHT: number = 130;

export let MAIN_TOOLBAR_HEIGHT: number = 53;

export let CONTENT_START_POSITION: number = 53;

export const updateMainToolbarHeight = (height: number): void => {
  if (CONTENT_START_POSITION === MAIN_TOOLBAR_HEIGHT) {
    CONTENT_START_POSITION = CONTENT_START_POSITION + height;
  }
};
export const BET_SLIP_NON_MOBILE_PANEL_WIDTH: number = 400;

export const EDIT_BUTTON_HEIGHT: number = 60;

export const MARGIN_TOP_HDR_TOOLBAR_ITEMS: number = 0; // 10;

export const PANEL_HEADER_HEIGHT: number = 50; // 37; // 45;

export const MOBILE_WIDTH = 350;

export const IOS_TOP_STATUS_BAR_HEIGHT = 20;

export const RELEASE_DETAILS_FOOTER_HEIGHT = 30;


// MK 12/08/2023
// when top/advanced search is open, we need to check and adjust cell widths
// as some columns might be too narrow and that does not look great
export const ADVANCED_SEARCH_CELL_WIDTH: number = 250;
// MK 02/10/2023
export const MOBILE_HEADER_OFFSET: number = 55;
//MK 30-12-2023 cellWidth will not be < defaultCellWidth
export const fieldToDefaultWidth: { [fieldType: string]: number } = {
  avatar: defaultCellWidth,
  booster: defaultCellWidth,
  card_panel: defaultCellWidth,
  chart: defaultCellWidth,
  changes: defaultCellWidth,
  checkbox: defaultCellWidth,
  cohort_logo: defaultCellWidth,
  circle: defaultCellWidth,
  codepen: defaultCellWidth,
  color: defaultCellWidth,
  comment: defaultCellWidth,
  condition: defaultCellWidth,
  deeplink: defaultCellWidth,
  bundle: defaultCellWidth,
  count: defaultCellWidth,
  date_range: 180,
  date_time: 180,
  date: 180,
  decimal: defaultCellWidth,
  diamond_panel: defaultCellWidth,
  draw_options: defaultCellWidth,
  duration: defaultCellWidth,
  emoji: defaultCellWidth,
  future_draws: defaultCellWidth,
  file_upload: defaultCellWidth,
  header: defaultCellWidth,
  html: defaultCellWidth,
  html_block: defaultCellWidth,
  id: defaultCellWidth,
  image_uploader: defaultCellWidth,
  iframe: defaultCellWidth,
  image: defaultCellWidth,
  link: defaultCellWidth,
  logo: defaultCellWidth,
  lookup: 120,
  lotto_line: defaultCellWidth,
  money: 150,
  new_money: 150,
  map: defaultCellWidth,
  multi_option: defaultCellWidth,
  multiple: defaultCellWidth,
  nested_table: defaultCellWidth,
  number: 125,
  offer: defaultCellWidth,
  option: 120,
  option_panel: defaultCellWidth,
  option_list: defaultCellWidth,
  open_in_player: defaultCellWidth,
  payment: defaultCellWidth,
  password: defaultCellWidth,
  permissions: defaultCellWidth,
  player_status: defaultCellWidth,
  prize_table: defaultCellWidth,
  properties: defaultCellWidth,
  quick_picks: defaultCellWidth,
  radio: 20,
  rate: defaultCellWidth,
  report: defaultCellWidth,
  rewards: defaultCellWidth,
  spending_limit: defaultCellWidth,
  sub_panel: defaultCellWidth,
  hero_slides: defaultCellWidth,
  hero_slide: defaultCellWidth,
  rule: defaultCellWidth,
  rule_condition: defaultCellWidth,
  rule_action: defaultCellWidth,
  symbol: defaultCellWidth,
  shape: defaultCellWidth,
  text: 120,
  theme: defaultCellWidth,
  theme_definition: defaultCellWidth,
  video: defaultCellWidth,
  textarea: defaultCellWidth,
  time: defaultCellWidth,
  todo: defaultCellWidth,
  toggle: defaultCellWidth,
  weekdays: defaultCellWidth,
  rearranging_list: defaultCellWidth,
  code: defaultCellWidth,
  adyen_ref: defaultCellWidth,
  graph: defaultCellWidth,
  slide: defaultCellWidth,
  slide_deck: defaultCellWidth,
  metric: defaultCellWidth,
  tree_content: defaultCellWidth,
  tree_children: defaultCellWidth,
  tree_parent: defaultCellWidth,
  tree: defaultCellWidth,
  chips: defaultCellWidth,
  multi_lookup: defaultCellWidth,
  multi_select:180,
  percent: 40,
  badge: 20,
  position: 30,
  cron: defaultCellWidth,
  card: defaultCellWidth,
  text_comment: defaultCellWidth,
  favourite: defaultCellWidth
};
export function setAdvancedCellWidth(currentWidth: number | string | undefined,
                                     isTopSearchOpen: boolean,
                                     isIndividualOpen: boolean | undefined,
                                     sortable: boolean | undefined,
                                     isHeader: boolean,
                                     fieldType: String,
                                     inlineEdit: boolean) {

  if (inlineEdit) {
    return inlineEditCellWidth;
  }
  if (isTopSearchOpen || isIndividualOpen) {
    return ADVANCED_SEARCH_CELL_WIDTH;
  }

  let defaultWidth = defaultCellWidth;
  if (fieldToDefaultWidth[fieldType as string]) {
    defaultWidth = fieldToDefaultWidth[fieldType as string];
  }

  try {
    const _currentWidth = typeof currentWidth === "string" ? parseInt(currentWidth as string) : currentWidth;
    const calculatedWidth = (_currentWidth || 0) - (isHeader && (_currentWidth || 0) <= 150 ? 10 : 0);
    return calculatedWidth < defaultWidth ? defaultWidth : calculatedWidth;
  } catch (e) {
    return defaultCellWidth;
  }
}


const createDefault = (): GuslThemeProperties => ({
  hasTheme: (): boolean => {
    return false;
  },
  /* eslint-disable @typescript-eslint/no-unused-vars */
  setTheme: (theme: string | undefined, baseTheme: BaseTheme, isNMobile: boolean) => {
  },
  getCurrentTheme: (): BaseTheme => {
    return defaultTheme;
  },
  getThemeId: (): string => {
    return "default";
  }
});

export const GuslThemeContext = createContext<GuslThemeProperties>(
  createDefault()
);

// const themeMap: { [index: string]: BaseTheme } = {
//     "alvar": alvarTheme,
//     "intertrader": interTraderTheme,
//     "supernova-fe": novaFeTheme,
//     "blue": blueTheme,
//     "coal": novaFeTheme,
//     "golden": goldenTheme,
//     "gold": goldenTheme,
//     "default": defaultTheme
// }

export type BaseTheme = typeof defaultTheme

declare module "styled-components" {
  export interface Theme extends BaseTheme {
  }
}
export type ReactChildProperties = {
  children: ReactNode
}
export const GuslThemeProvider: React.FC<ReactChildProperties> = ({ children }: ReactChildProperties) => {
  // store the entire theme as state, using your constant as the initial state
  const [currentTheme, setCurrentTheme] = useState<BaseTheme>(defaultTheme);
  const [themeId, setThemeId] = useState("default");

  const detectIfMobile = (): boolean => {
    const ua = navigator.userAgent;
    let mobileDevice = false;

    if (/(tablet|ipad|playbook|silk) | (android(?!.*mobi))/i.test(ua)) {
      mobileDevice = true;
    } else if (/Mobile|Android}iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      mobileDevice = true;
    }
    return mobileDevice;
  };

  const flatten = (label:string, entity: any) => {
    if (entity) {
      Object.keys(entity).forEach((key:string) => {
        if (isObject(entity[key])) {
          flatten(`${label}-${key}`,entity[key])
        } else {
          document.documentElement.style.setProperty(`--${label}-${key}`, entity[key]);
        }
      })
    }

  }

  const flattenTheme = (theme: BaseTheme) => {
      flatten('theme',theme)
  };
  /**
   * Used to change the theme
   * @param themeId The new themeId
   * @param theme  Theme definition to set
   * @param isMobile I mobile environment
   */
  const setTheme = (themeId: string | undefined, theme: BaseTheme, isMobile: boolean) => {
    // theme = mobileTheme  // gbw <-- for testing
    if (!themeId) {
      return;
    }
    setCurrentTheme(theme);
    setThemeId(themeId);

    const isIOS = ("ios" === Capacitor.getPlatform()) && Capacitor.isNativePlatform();

    // override vars
    document.documentElement.style.setProperty(`--widget-blue`, theme.colors.background[0]);
    document.documentElement.style.setProperty(`--green`, theme.colors.light);


    document.documentElement.style.setProperty(`--modal-background-color`, theme.modal.innerBackground);
    if (theme.table.highlightBgColor) {
      document.documentElement.style.setProperty(`--table-highlight-background-color`, theme.table.highlightBgColor);
    }

    document.documentElement.style.setProperty(`--negative`, theme.money.negativeColor);
    document.documentElement.style.setProperty(`--positive`, theme.money.positiveColor);
    document.documentElement.style.setProperty(`--ls-green`, theme.money.positiveColor);
    document.documentElement.style.setProperty(`--neutral`, theme.table.columnColor);
    document.documentElement.style.setProperty(`--column-color`, theme.table.columnColor);
    document.documentElement.style.setProperty(`--header-color`, theme.table.headerColor);
    document.documentElement.style.setProperty(`--bs-pagination-font-size`, "30px");
    // document.documentElement.style.setProperty(`--${'modal-border'}`, theme.modal.innerBackground);
    // document.documentElement.style.setProperty(`--${'modal-radius'}`, theme.modal.innerBackground);
    // document.documentElement.style.setProperty(`--${'modal-box-shadow'}`, theme.modal.innerBackground);

    document.documentElement.style.setProperty(`--scrollbar-track`, theme.colors.dark[0]);
    document.documentElement.style.setProperty(`--scrollbar-thumb-background`, theme.colors.dark[1]);
    document.documentElement.style.setProperty(`--scrollbar-thumb-border`, theme.colors.dark[0]);
    document.documentElement.style.setProperty(`--scrollbar-hover`, theme.colors.dark[1]);
    if (detectIfMobile()) {
      document.documentElement.style.setProperty(`--scrollbar-width`, "3px");
      document.documentElement.style.setProperty(`--scrollbar-height`, "3px");
    } else {
      document.documentElement.style.setProperty(`--scrollbar-width`, "10px");
      document.documentElement.style.setProperty(`--scrollbar-height`, "10px");
    }

    document.documentElement.style.setProperty(`--muted`, theme.table.columnColor);
    document.documentElement.style.setProperty(`--white`, theme.table.columnColor);

    document.documentElement.style.setProperty(`--text-color`, theme.table.columnColor);


    document.documentElement.style.setProperty(`--bs-light-rgb`, theme.table.columnColor);
    document.documentElement.style.setProperty(`--panel-bgcolor`, theme.panel.panelBgColor);

    document.documentElement.style.setProperty(`--bs-body-color`, theme.table.columnColor);

    // document.documentElement.style.setProperty(`--${'gusl-offcanvas-width'}`, window.innerWidth + 'px');

    document.documentElement.style.setProperty(`--offcanvas-backdrop-height`, "calc(var(--internal-height)  - " + (CONTENT_START_POSITION) + "px)");
    document.documentElement.style.setProperty(`--offcanvas-backdrop-top`, (CONTENT_START_POSITION + 5) + "px");

    document.documentElement.style.setProperty(`--button-color`, theme.table.menuButtonColor);
    document.documentElement.style.setProperty(`--button-bgcolor`, theme.table.menuButtonBgColor);

    document.documentElement.style.setProperty(`--table-title-bgcolor`, theme.table.titleBgColor);
    document.documentElement.style.setProperty(`--table-title-color`, theme.table.titleColor);

    if (theme?.colors?.bespoke) {
      theme?.colors?.bespoke.forEach((color, index) => {
        document.documentElement.style.setProperty(`--${"bespoke-" + index}`, color);
      });
    }
    flattenTheme(theme)
  };

  const getCurrentTheme = (): BaseTheme => {
    return currentTheme;
  };

  const getThemeId = (): string => {
    return themeId;
  };


  const hasTheme = (): boolean => {
    return guslStorage.hasTheme();
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <GuslThemeContext.Provider value={{ setTheme, getCurrentTheme, getThemeId, hasTheme }}>
        {children}
      </GuslThemeContext.Provider>
    </ThemeProvider>
  );
};
