

export const downloadBlob = (filename: string, contentType: string, data: any) => {
    // const blob = new Blob([data as any], {type: 'application/vnd.ms-excel'});
    const blob = new Blob([data as any], {type: contentType});
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = url;
    // link.setAttribute('download', 'promotion_id_' + this.data.id + '_exported_on_' + moment().format('YYYY_MM_DD_HH_mm'));

    document.body.appendChild(link);

    link.setAttribute('download', filename);
    link.setAttribute('target', '_external');

    document.body.appendChild(link);
    link.click();

}

