/**
 * Extracts specific string values from an array of LookupObjectDO | MultiSelectObjectDO objects for lookup, multi_select dropdown options.
 *
 * Iterates over each object in the provided array, extracts values corresponding to accepted keys,
 * and returns them as an array of strings. Filters out undefined or non-string values,
 *
 * Constructing a string by joining all values with a dash if no valid string values are found. (safety-net...)
 *
 * Example for joining
 *
 * acceptedKeys = ["id","name"]
 *
 * single object {code:"some",value:"thing"}
 *
 * dropdown option => "some-thing"
 *
 * This is just for the user to have visual on the "object"
 * Object being passed for saving remains the same structure
 *
 * @param data Array of LookupObjectDO objects to extract values from.
 * @param acceptedKeys Array of objects possible keys, we'll try them in order they are passed
 * @returns An array of strings containing the extracted values for select options.
 */

export function extractValues<T extends Record<string, any>>(data: T[], acceptedKeys: string[]): string[] {
  return data.map(item => {
    const values: (string | undefined)[] = acceptedKeys.map(key => {
      return (item as any)[key];
    });

    const filteredValues: string[] = values.filter(value => value !== undefined && typeof value === "string") as string[];

    return filteredValues.length > 0 ? filteredValues[0] : Object.values(item).join("-");
  });
}