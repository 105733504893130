import styled, {ThemeProps} from 'styled-components';

interface LabelProperties extends ThemeProps<any> {
    htmlFor?: string,
    fontSize?: string
}

interface ResetProperties extends ThemeProps<any> {

    onClick: Function
}

interface FilterTypeWrapperProperties extends ThemeProps<any> {
    className?: string
    height?: number

}

interface HtmlCheckProps {
    isActive?: boolean
}

export interface SearchBarProperties extends ThemeProps<any> {
    colorName: string,
    onClick?: Function,
    notBordered?: boolean,
    notRounded?: boolean

}

interface SpanProperties extends ThemeProps<any> {
    colorName?: string,
    active?: boolean,
    onClick?: Function,
    className: string,

}

export interface WidgetHeadingProperties extends ThemeProps<any> {
    onClick?: Function,
    active?: boolean,
    color?: string,
    extraClass?: string
}


export const TableSidePanelTitleWrapperStyled = styled.div.attrs((props) => ({}))`
    background-color: ${props => props.theme.table.titleBgColor}; 
    padding: 10px 0px  ;
    height:50px;
`
export const TableSidePanelTitleStyled = styled.h5.attrs((props) => ({}))`
    color:${props => props.theme.table.titleColor};
    font-size: 15px;
    padding: 5px;  
    text-align:center;
`

export const TableSidePanelActionsStyled = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-end'
}))`
    background-color:${props => props.theme.table.panelBgColor};
    font-size: 14px;
    padding: 5px;  
`

export const TableSidePanelWrapperStyled = styled.div.attrs((props) => ({
    className:'ms-0 ms-md-1 mb-1',
}))`
    padding-bottom:10px;
`

export const WidgetHeadingStyled = styled.h6.attrs((props: WidgetHeadingProperties) => ({
    onClick: props.onClick,
    role: 'button',
}))<WidgetHeadingProperties>`
      cursor:pointer;
      font-size:16px;
      padding:0px 5px;
      margin-bottom: 10px;
      color: ${props => props.color ?
    (props.active ? props.theme.colors.light : props.theme.colors[props.color]) :
    (props.active ? props.theme.colors.accentColor : props.theme.colors.dark[1])};
    
    
`
export const FilterTypeWrapperStyled = styled.div.attrs((props: FilterTypeWrapperProperties) => ({
    // className: props.className || 'mt-3 px-2',
}))<FilterTypeWrapperProperties>`
  ${props => props.height && 'height: ' + props.height + 'px;'};
  overflow: auto;
`

export const ResetStyled = styled.span.attrs((props: ResetProperties) => ({
    onClick: props.onClick
}))<ResetProperties>
    `
        color:${props => props.theme.colors.accentColorLight};
        margin:5px 0;
        font-size:12px;
    `
export const LabelStyled = styled.label.attrs((props: LabelProperties) => ({
    htmlFor: props.htmlFor,
    className:'ps-1 ps-md-0'

}))<LabelProperties>
    `
        color:${props => props.theme.colors.accentColorLight};
        margin:5px 0;
        font-size:${props => props.fontSize ? props.fontSize : 'inherit'};
        
    `


export const ErrorMsgStyled = styled.p.attrs((props) => ({}))
    `
        color:${props => props.theme.colors.warning};
        font-size:13px;
        text-align:center;
        padding:3px;
        
    `
export const SearchButtonStyled = styled.button.attrs((props: SearchBarProperties) => ({
    onClick: props.onClick
}))<SearchBarProperties>`
    background-color:${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.notBordered ? 'transparent' : props.theme.colors.dark[1]};
    color:${props => props.theme.colors.dark[1]};
    padding:12px 50px;
    border-radius:${props => props.notRounded ? '0px' : '4px'};
    :hover{
     color:${props => props.theme.colors.light};
     background-color:${props => props.theme.colors.accentColorMuted};
    }
    
`

export const SidePanelSpanStyled = styled.span.attrs((props: SpanProperties) => ({
    className: props.className ? props.className : '',

}))<SpanProperties>
    `
    color:${props => !props.active ? props.theme.colors.accentColorMuted : props.theme.colors.dark[1]};
    text-align: left;
    `

export const HtmlCheckStyled = styled.span.attrs((props: HtmlCheckProps) => ({}))<HtmlCheckProps>`

     color:${props => props.isActive ? props.theme.colors.accentColor : props.theme.colors.accentColorMuted};
       
`

export const FavQueriesWrapperStyled = styled.div.attrs((props) => ({
    className: 'row g-0 mb-1 py-2'
}))`
    background-color:${props => props.theme.colors.accentColorMuted};
`
export const FavQueriesFormStyled = styled.div.attrs((props) => ({
    className:"pe-2"
}))`
    background-color:${props => props.theme.colors.background[1]};
  
  
`

