import styled, {ThemeProps} from 'styled-components';

interface AvatarWrapperStyledProps extends ThemeProps<any> {
}


export const AvatarWrapperStyled = styled.div.attrs((props: AvatarWrapperStyledProps) => ({
}))<AvatarWrapperStyledProps>`
margin: 0px 0px 20px 10px;
`
