import React from 'react';
import {useNavigate} from 'react-router-dom';
import {fieldService} from '../../../FieldService';
import {FieldConfigDTO, MoneyDTO} from '../../../types';
import {
    BetPlacedBreadcrumbsStyled,
    BetPlacedPanelStyled,
    EventNameStyled,
    FieldLabelStyled,
    FieldStyled,
    LinkLabelStyled,
    LinkWrapperStyled,
    MoneyBodyStyled,
    MoneyContainerStyled,
    MoneyLabelStyled,
    MoneyLeftWrapperStyled,
    MoneyRightWrapperStyled,
    MoneyValueStyled,
    MoneyWrapperStyled,
    OutcomeNameStyled,
    RiskGainStyled,
    TitleStyled,
    ValueStyled
} from '../styled';
import {BetPlacedDTO} from '../types';

interface BetPlacedProperties {
    betPlaced: BetPlacedDTO;
}

export const BetPlaced = ({
                              betPlaced,
                          }: BetPlacedProperties): React.ReactElement<BetPlacedProperties> => {

    const navigate = useNavigate();

    const renderBreadcrumbs = (): React.ReactElement => {
        const fieldConfig: FieldConfigDTO = {
            name: 'breadcrumb',
            label: '',
            type: 'breadcrumb'
        }

        const rowData: any = {}
        rowData.breadcrumb = betPlaced.breadCrumbs;
        return (
            <BetPlacedBreadcrumbsStyled>
                {fieldService.getTableField(fieldConfig, undefined, rowData)?.render()}
            </BetPlacedBreadcrumbsStyled>
        )
    }

    const renderMoney = (name: string, value: MoneyDTO): React.ReactElement => {
        const fieldConfig: FieldConfigDTO = {
            name: name,
            label: '',
            type: 'money',
            properties: JSON.stringify({showCurrency: true})
        }

        const rowData: any = {}
        rowData[name] = value;
        return (
            <>
                {fieldService.getTableField(fieldConfig, undefined, rowData)?.render()}
            </>
        )
    }


    const renderDatePlaced = (): React.ReactElement => {
        const fieldConfig: FieldConfigDTO = {
            name: 'datePlaced',
            label: '',
            type: 'date_time',
            dateFormat: 'dd-MM-yyyy @ HH:mm'
        }

        const rowData: any = {}
        rowData['datePlaced'] = betPlaced.datePlaced;
        return (
            <>
                {fieldService.getTableField(fieldConfig, undefined, rowData)?.render()}
            </>
        )
    }

    const renderOpenBetsLink = (): React.ReactElement => {
        const onLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
            console.log('link clicked')
            navigate('/reports')

        };

        return (
            <LinkWrapperStyled><LinkLabelStyled onClick={(e: React.MouseEvent<HTMLDivElement>) => onLinkClick(e)}
            >View Open Bets</LinkLabelStyled></LinkWrapperStyled>
        )
    }

    return (
        <BetPlacedPanelStyled>
            <TitleStyled>Bet Placed</TitleStyled>
            <FieldStyled>
                <OutcomeNameStyled>{betPlaced.outcomeName}</OutcomeNameStyled>
            </FieldStyled>
            <FieldStyled>
                <EventNameStyled>{betPlaced.eventName}</EventNameStyled>
            </FieldStyled>
            {renderBreadcrumbs()}
            <FieldStyled>
                <FieldLabelStyled>Reference Id:</FieldLabelStyled>
                <ValueStyled>{betPlaced.referenceId}</ValueStyled>
            </FieldStyled>
            <FieldStyled>
                <FieldLabelStyled>Placed:</FieldLabelStyled>
                <ValueStyled>{renderDatePlaced()}</ValueStyled>
            </FieldStyled>
            <RiskGainStyled>
                <MoneyContainerStyled>
                    <MoneyBodyStyled>
                        <MoneyWrapperStyled>
                            <MoneyLeftWrapperStyled>
                                <MoneyLabelStyled>Stake:</MoneyLabelStyled>
                                <MoneyValueStyled>{renderMoney('riskAmount', betPlaced.riskAmount)}</MoneyValueStyled>
                            </MoneyLeftWrapperStyled>
                            <MoneyRightWrapperStyled>
                                <MoneyLabelStyled>Return:</MoneyLabelStyled>
                                <MoneyValueStyled>{renderMoney('gainAmount', betPlaced.gainAmount)}</MoneyValueStyled>
                            </MoneyRightWrapperStyled>
                        </MoneyWrapperStyled>
                    </MoneyBodyStyled>
                </MoneyContainerStyled>
            </RiskGainStyled>
            {renderOpenBetsLink()}
        </BetPlacedPanelStyled>

    )

}
