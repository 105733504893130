import styled, {ThemeProps} from 'styled-components';
import {defaultCellWidth} from '../gusl-table/guslTableSlice';
import {DraggableColumnProperties} from '../gusl-table/styled/columns-settings/styled';
import {pxOrPerc, TdProperties} from '../maintain-table/styled';
import {NestedTableRow} from './NestedTableRow';

export const NestedTableResponsiveStyled = styled.div.attrs((props) => ({
    className: 'table-responsive',
}))`
  border: 1px solid ${props => props.theme.colors.accentColorMuted} !important;

`


export const NestedTableStyled = styled.table.attrs((props) => ({
    className: 'table table-striped'
}))`
`

export const NestedTableHeadStyled = styled.thead.attrs((props) => ({}))`
`

export const NestedTableBodyStyled = styled.tbody.attrs((props) => ({}))`
`

export interface NestedRowStyledProperties extends ThemeProps<any> {
    firstTab?: string | undefined,
    active?:boolean,
    lookupField?:boolean,
}

export const NestedTableRowStyled = styled.tr.attrs((props:NestedRowStyledProperties) => ({}))<NestedRowStyledProperties>`
  color: ${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};

  :nth-of-type(odd) > * {
    color: ${props => props.theme.table.columnColor} !important;
    font-size: ${props => props.theme.table.columnFontSize};
  }
`

export const NestedTableColumnStyled = styled.td.attrs((props: TdProperties) => ({}))<TdProperties>`
  color: ${props => props.theme.table.columnColor};
  //word-break: break-all;
  font-size: ${props => props.theme.table.columnFontSize};
  width: ${props => typeof props.cellWidth !== "undefined" ? pxOrPerc(props.cellWidth) : pxOrPerc(defaultCellWidth)};
  min-width: ${props => typeof props.cellWidth !== "undefined" ? pxOrPerc(props.cellWidth) : pxOrPerc(defaultCellWidth)};
  max-width: ${props => typeof props.cellWidth !== "undefined" ? pxOrPerc(props.cellWidth) : pxOrPerc(defaultCellWidth)};
  // background-color: ${props => !props.beingResized ? "transparent" : props.theme.colors.dark[0]} !important;



`

export const AddRowLinkStyled = styled.p.attrs((props) => ({}))`
  color: ${props => props.theme.colors.accentColorMuted} !important;
  float:right;
  font-size:12px;
  &:hover {
    text-decoration: underline;
  }
`

export const RemoveIconStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-trash'
}))`
  margin-top: 3px;
  margin-right: 10px;
  float: right;

  &:hover {
    background-color: ${props => props.theme.colors.accentColorMuted};
  }

`
export const DraggableRowStyled = styled(NestedTableRow).attrs((props: DraggableColumnProperties) => ({
    key: props.key,
    role: props.role,
    onClick: props.onClick,
    onDragEnter: props.onDragEnter,
    onDragStart: props.onDragStart,
    onDragEnd: props.onDragEnd,
    onDragOver: props.onDragOver,
    draggable: true,
    className: 'd-flex justify-content-between mb-1' + (props.active ? 'active' : '')

}))<DraggableColumnProperties>
    `
 color:${props => !props.active ?  props.theme.money.negativeColor : props.theme.colors.dark[1]};
 border: 1px dashed ${props => props.active ? props.theme.colors.dark[1] : 'transparent'};
 // border-bottom: 1px solid ${props => props.theme.colors.dark[0]};
 font-size:14px;
  width: 100%;

  @media all and (max-width: 768px){
    line-height: 30px;
  }
  
  @media only screen and (max-device-width: 1024px) {
    line-height: 30px;
  }
  
  
`
export const NestedFieldContentWrapperStyled = styled.div.attrs((props) => ({
    className: "form-control bg-transparent rounded-0 text-light ",
    id:'nested_field'
}))`
`
export const NestedFieldContentStyled = styled.div.attrs((props) => ({}))`
    padding-right: 20px;
`
