import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Subscription} from 'rxjs';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import MGQuery from "../../../providers/session/MGQuery";
import {SystemContext} from "../../../providers/system/SystemContext";
import {RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {AccordionListPanelStyled, ListContainerStyled, ListScrollContainerStyled} from '../../accordion-list/styled';
import {ComponentTitleWrapperStyled, TradeCentralLinkStyled} from "../../ui/styled";
import MiniLoadingSpinner from "../gusl-table/table-controls/MiniLoadingSpinner";
import {TableTitleStyled} from "../maintain-table/styled";
import {TradingCentralIframeStyled} from './styled';

export interface TradingCentralDO {
    url: string;
    page: string;
}

export default function TradingCentralPage() {
    const [className] = useState<string>('TradingCentralPage-' + new Date().getTime());
    const environmentContext = React.useContext(EnvironmentContext);
    const systemContext = useContext(SystemContext);

    const [activeLink, setActiveLink] = useState<string>();
    const isMobile = environmentContext.isMobileDevice();
    const [listStartPos] = useState<number>(164);


    const menuItem = systemContext.getSystemConfig().menuGroups
        .filter((menuGroup => menuGroup.label === "Dashboard"))[0].menuItems?.filter(
        (menuItem) => menuItem.label === "Market Sentiment")[0];

    const selectUrl: string | undefined = menuItem?.selectUrl;

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    const {isLoading, isSuccess, isError, error, data} =
        MGQuery({
            method: 'post',
            endpoint: (selectUrl as string) + "?page=" + activeLink,
            queryParams: {page: activeLink},
            dontRunQuery: typeof selectUrl === "undefined"
        });
    /* ANALYST_VIEWS("av_ideas"),
     MARKET_BUZZ("marketbuzz_ranking"),
     ECONOMIC_INSIGHT("economic_calendar"),
     FOREX("featured_forex");*/
    const links: { [index: string]: string } = {
        av_ideas: "ANALYST_VIEWS",
        marketbuzz_ranking: "MARKET_BUZZ",
        economic_calendar: "ECONOMIC_INSIGHT",
        featured_forex: "FOREX"

    }
    /*@ts-ignore*/
    const page = data.page;
    useEffect(() => {

        setActiveLink(page)
    }, [page])
    if (isSuccess && data) {


        // @ts-ignore
        return (
            <div>
                <ComponentTitleWrapperStyled>
                    <TableTitleStyled isMobile={false} footerHeight={footerHeight}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div> Trading central</div>
                            {Object.entries(links).map((link, idx) =>
                                <TradeCentralLinkStyled
                                    isActive={activeLink === link[1]}
                                    onClick={() => {
                                        setActiveLink(link[1])
                                    }}
                                    key={idx}>
                                    {link[1].replace("_", " ")}
                                </TradeCentralLinkStyled>
                            )}
                        </div>

                    </TableTitleStyled>

                </ComponentTitleWrapperStyled>
                <AccordionListPanelStyled isMobile={isMobile} id={'tc_lp'} listStartPos={listStartPos}
                                          hasOrderByActions={false}
                                          footerHeight={footerHeight}
                                          searchOpen={false}>
                    <ListContainerStyled isMobile={isMobile} id={'fl_l'} listStartPos={listStartPos} footerHeight={footerHeight}>
                        <ListScrollContainerStyled isMobile={isMobile} id={'fl_scroll'} listStartPos={listStartPos} footerHeight={footerHeight}>
                            <TradingCentralIframeStyled
                                id="tradingcentral"
                                /*@ts-ignore*/
                                src={data.url as unknown as string}/>
                        </ListScrollContainerStyled>
                    </ListContainerStyled>
                </AccordionListPanelStyled>

            </div>
        );
    } else if (isError) {
        return <p className="lead text-center invalid">Could not load the Trading Central iFrame!</p>
    } else if (isLoading) {
        return <MiniLoadingSpinner isLoading={isLoading}/>
    } else {
        return <></>
    }


}
