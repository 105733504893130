import * as React from 'react';
import {useState} from 'react';
import {Placement} from "react-bootstrap/types";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {toggleSplitScreen} from "../../../../../features/ui/uiSlice";
import {ElementWithTooltip} from "../../../element-with/element-with-tooltip/ElementWithTooltip";
import {SplitIconStyled} from "../../styled/table-controls/styled";

export interface SplitViewBtnDO {
   placement?: Placement | undefined
}

export default function SplitViewBtn({placement}:SplitViewBtnDO) {
    const [className] = useState('SplitViewBtn-' + new Date().getTime());
     const splitScreen: boolean = useAppSelector(state => state.uiSlice.splitScreen);
    const dispatch = useAppDispatch();
    return (
        <SplitIconStyled>
            <ElementWithTooltip
                placement={placement || 'auto'}
                element={<i className={'fa-solid fa-table-columns fa-rotate-90 me-2 float-end'} onClick={() => {
                    dispatch(toggleSplitScreen())
                }}/>}
                tooltip={<span>{splitScreen ? 'Single view.':'Split view.'}</span>}/>
        </SplitIconStyled>
    );
};
