import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {SystemContext} from '../../../../../providers/system/SystemContext';
import {constructUrl, mapSinglePageFields} from '../../../../../utils/Utils';
import {fieldService} from '../../../../FieldService';
import PagedResponse from '../../../../paged-response/PagedResponse';
import {FieldConfigDTO} from '../../../../types';
import {ArticleHeaderStyled, ArticleSummaryStyled, ArticleTitleStyled} from '../../articles-view/styled';
import {DEAL_PAGE} from '../../deal-view/DealView';
import {OttoBoxStyled, OttoCol4, OttoRow, ScrollBox} from '../../styled';
import {CardInfoStyled, CollectionHeaderStyled, OttoTableCard, OttoTableGrid} from '../styled';

const SELECT_URL = '/otto-articles/{dealType}/paged-recommendations'

interface RecommendedArticlesProperties {
    code: string,
    dealType: string,
    contentsHeight: string
}

const RecommendedArticles = ({code, dealType, contentsHeight}: RecommendedArticlesProperties): React.ReactElement => {
    const systemContext = useContext(SystemContext);
    const navigate = useNavigate()

    const [fieldMap] = useState<{
        [id: string]: FieldConfigDTO
    }>(mapSinglePageFields(systemContext.getMenuConfig('deal')));

    const onDealClick = (dealData: any) => {
        // dispatch(selectedOttoTableArticle({code: tabParam || RISK_ARB, currentArticle: selectedEvent}))
        navigate(DEAL_PAGE + '/' + dealData?.id || '')
    }

    const displayField = (field: string, dealData: any): React.ReactElement => {
        const fld: FieldConfigDTO | undefined = fieldMap[field]
        if (!fld) {
            return <></>
        }

        return (
            <CardInfoStyled><span>{fld.label}: </span> {fieldService.getTableField(fld, undefined, dealData, undefined, undefined, undefined, undefined, true)?.render()}
            </CardInfoStyled>
        )
    }
    const renderHoldCo = (dealData: any): React.ReactElement => {
        return (
            <OttoTableCard>
                <ArticleHeaderStyled>{dealData?.companyName}</ArticleHeaderStyled>
                <OttoTableGrid>
                    <OttoRow>
                        <OttoCol4>
                            {/*{displayField('dealType',dealData)}*/}
                            {displayField('ticker', dealData)}
                            {displayField('navDiscount', dealData)}
                            {displayField('listedAssetsPercOfGav', dealData)}
                            {displayField('unlistedAssetsPercOfGav', dealData)}
                        </OttoCol4>
                        <OttoCol4>
                            {displayField('investments', dealData)}
                            {/*{displayField('navDiscountChangeSince5Days',dealData)}*/}
                            {/*{displayField('navDiscountChangeSince10Days',dealData)}*/}
                            {/*{displayField('navDiscountChangeSince30Days',dealData)}*/}
                        </OttoCol4>
                        <OttoCol4>
                            {/*{displayField('investments',dealData)}*/}
                            {displayField('navDiscountChangeSince5Days', dealData)}
                            {displayField('navDiscountChangeSince10Days', dealData)}
                            {displayField('navDiscountChangeSince30Days', dealData)}
                        </OttoCol4>
                    </OttoRow>
                </OttoTableGrid>
            </OttoTableCard>
        )
    }

    const renderRiskArb = (dealData: any): React.ReactElement => {
        return (
            <OttoTableCard>
                <ArticleHeaderStyled>{dealData?.dealName}</ArticleHeaderStyled>
                <OttoTableGrid>
                    <OttoRow>
                        <OttoCol4>
                            {/*{displayField('dealType')}*/}
                            {displayField('targetTicker', dealData)}
                            {displayField('acquirerTicker', dealData)}
                            {displayField('geography', dealData)}
                            {displayField('country', dealData)}
                            {displayField('sector', dealData)}
                        </OttoCol4>
                        <OttoCol4>
                            {displayField('dealSize', dealData)}
                            {displayField('terms', dealData)}
                            {displayField('announcementDate', dealData)}
                            {displayField('expectedClosingDate', dealData)}
                        </OttoCol4>
                        <OttoCol4>
                            {displayField('probability', dealData)}
                            {displayField('downside', dealData)}
                            {displayField('netSpread', dealData)}
                            {displayField('netAnnualisedSpread', dealData)}
                            {displayField('spreadMoveVs2DaysAgo', dealData)}
                            {displayField('spreadMoveVs10DaysAgo', dealData)}
                        </OttoCol4>
                    </OttoRow>
                </OttoTableGrid>
            </OttoTableCard>
        )
    }

    const renderShareClass = (dealData: any): React.ReactElement => {

        return (
            <OttoTableCard>
                <ArticleHeaderStyled>{dealData?.companyName}</ArticleHeaderStyled>
                <OttoTableGrid>
                    <OttoRow>
                        <OttoCol4>
                            {/*{displayField('dealType')}*/}
                            {displayField('shareClass', dealData)}
                            {displayField('preferenceTicker', dealData)}
                            {displayField('nonPreferenceTicker', dealData)}
                        </OttoCol4>
                        <OttoCol4>
                            {displayField('currentPremium', dealData)}
                            {displayField('historicMean', dealData)}
                            {displayField('votingRights', dealData)}
                            {displayField('liquidityDifferential', dealData)}
                            {displayField('resistanceLevel', dealData)}
                        </OttoCol4>
                        <OttoCol4>
                            {displayField('liquidityPrefEurm', dealData)}
                            {displayField('liquidityOrdEurm', dealData)}
                            {displayField('spreadMovement5Day', dealData)}
                            {displayField('spreadMovement9Day', dealData)}
                            {displayField('spreadMovement30Day', dealData)}
                        </OttoCol4>
                    </OttoRow>
                </OttoTableGrid>
            </OttoTableCard>
        )
    }

    const renderRecommendationCards = (dealData: any): React.ReactElement => {
        if (!dealData || !dealData?.dealType) {
            return <></>
        } else if (dealData?.dealType === 'HOLD_CO') {
            return renderHoldCo(dealData)
        } else if (dealData?.dealType === 'RISK_ARB') {
            return renderRiskArb(dealData)

        } else if (dealData?.dealType === 'SHARE_CLASS') {
            return renderShareClass(dealData)

        } else if (dealData?.dealType === 'PRE_EVENT') {
            return renderRiskArb(dealData)
        } else
            return (<></>)
    }

    const renderItem = (dealData: any, idx: number): React.ReactElement => {
        return (
            <div key={idx} className={"p-2"}>
                <ArticleTitleStyled
                    key={'t_' + idx + dealData.id || 'id'}
                    large={true}
                    onClick={() => {
                        onDealClick(dealData)
                    }}
                    active={false}>
                    <ArticleSummaryStyled>
                        {renderRecommendationCards(dealData)}
                    </ArticleSummaryStyled>
                </ArticleTitleStyled>
            </div>
        )
    }


    return (
        <OttoBoxStyled>
            <CollectionHeaderStyled>Recommendations</CollectionHeaderStyled>
            <ScrollBox id={'scrollable_' + code} height={contentsHeight}>
                <PagedResponse code={code} selectUrl={constructUrl(SELECT_URL, {dealType})} renderItem={renderItem}/>
            </ScrollBox>
        </OttoBoxStyled>
    )
}

export default RecommendedArticles
