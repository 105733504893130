import styled, {ThemeProps} from 'styled-components';

interface TooltipStyledProps extends ThemeProps<any> {
    fontSize?:number;
}
export const TooltipStyled = styled.div.attrs((props:TooltipStyledProps) => ({

}))<TooltipStyledProps>`
  font-size:${props => props.fontSize ? (props.fontSize +'px') : props.theme.fontSizes.tooltip};
`
