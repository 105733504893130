import React, { useState } from "react";
import { ButtonGroupStyled, BuySellButtonStyled } from "../styled_v2";

export interface MarketLimitButtonsDO {
  onOrderType: Function;
  isMarket: boolean;
}

export default function MarketLimitButtons({ onOrderType, isMarket }: MarketLimitButtonsDO) {

  const [className] = useState("MarketLimitButtons-" + new Date().getTime());

  return (
    <div className="pe-2">
      <ButtonGroupStyled className="row g-0 row-cols-2 ">
        <BuySellButtonStyled
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, "MARKET")}
          active={isMarket}
        >Market
        </BuySellButtonStyled>
        <BuySellButtonStyled
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onOrderType(e, "LIMIT")}
          active={!isMarket}
        >Limit
        </BuySellButtonStyled>
      </ButtonGroupStyled>
    </div>
  );
}

