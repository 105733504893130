import styled, {ThemeProps} from 'styled-components';
import {OddsDTO} from './types';

interface OddsStyledProps extends ThemeProps<any> {
    odds: OddsDTO | undefined,
    colorise: boolean,
}

export const OddsStyled = styled.div.attrs((props: OddsStyledProps) => ({}))<OddsStyledProps>`
color: ${props => {
    let value = props.theme.table.columnColor
    if (props.colorise) {
        if (props.odds && props.odds.value) {
            value = props.odds.value < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
        }
    }
    return value
}} !important ;
font-size: ${props => props.theme.table.columnFontSize};

`

export const OddsWrapper = styled.div.attrs((props ) => ({}))`
    display:flex;
`

export const OddsTypeWrapper = styled.div.attrs((props ) => ({}))`
  width: 55px;
`

export const OddsValueWrapper = styled.div.attrs((props ) => ({}))`
  vertical-align: bottom;
  margin: auto;
  margin-bottom: 5px;  
`
export const OddsTypeLookupStyled = styled.div.attrs((props) => ({
    id: props.id,
}))`
  z-index: 20;
  width: 60px;
  position: relative;
  //left: -50px;
  //top: 35px;
  //height: 20px;

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};

  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;

  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;
`
