import {add, format, sub} from "date-fns";
import * as React from "react";
import {useState} from "react";
import {CalendarItemTooltipDO} from "../../../types";


export default function CalendarItemTooltip({textRange, currentNum, currentUnit, direction, fromTo, inTheField}: CalendarItemTooltipDO) {

    const [className] = useState('CalendarItemTooltip-' + new Date().getTime());

    const oneUnitAgoMap: { [index: string]: string } = {
        h: 'An hour ago',
        d: 'Yesterday',
        w: 'Last week',
        M: 'Last month',
        y: 'Last year'
    };
    const oneUnitAgoText: string = oneUnitAgoMap[currentUnit];

    const inOneUnitTextMap: { [index: string]: string } = {
        h: 'In one hour',
        d: 'Tomorrow',
        w: 'Next week',
        M: 'Next month',
        y: 'Next year'
    }
    const inOneUnitText: string = inOneUnitTextMap[currentUnit];

    const unitMap: { [index: string]: string } = {
        h: 'hours',
        d: 'days',
        w: 'weeks',
        M: 'months',
        y: 'years'
    }
    // https://date-fns.org/v2.29.3/docs/format
    const formatMap: { [index: string]: string } = {
        h: 'eeee H aaaa',
        d: 'eeee d / MMM',
        w: 'wo / y',
        M: 'MMMM / y',
        y: 'y'
    }
    const fnMap: { [index: string]: Function } = {
        past: sub,
        future: add
    }
    const _currentNum = Math.abs(currentNum);
    const fn: Function = fnMap[direction];
    const key = unitMap[currentUnit];
    const dateFormat = formatMap[currentUnit];
    const label = textRange?.label?.toLowerCase();
    const displayDate: string = format(fn(new Date(), {
        [key]: _currentNum,
    }), dateFormat);

    if (currentUnit === "") {
        return currentNum === 0 ? <span>0</span> :
            <span>{direction === 'future' ? `Select next ${_currentNum} unit`
                : `Select previous ${_currentNum} unit`} <br/> And then select datetime unit. </span>
    }

    if (_currentNum === 1) {
        return <span>{fromTo !== 'Date' && fromTo} {currentNum === 1 ? oneUnitAgoText : inOneUnitText}</span>
    }

    return (

        <span>
            {currentNum !== 0 ? <>
                {fromTo !== 'Date' ? fromTo : ''} {direction === 'future' ? ' in ' : ''}
                {!inTheField && <>
                    {_currentNum} {_currentNum !== 1 ? label : label.slice(0, -1)}
                    {direction === 'past' ? ' ago.' : ''}
                    <br/>
                </>}
            </> : <>{fromTo !== 'Date' && fromTo} Current {label.slice(0, -1)} <br/></>}
            {displayDate}
        </span>


    );
}
