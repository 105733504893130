import styled from 'styled-components';

export const HomePageStyled = styled.div.attrs((props) => ({}))`
  padding: 20px 0px 20px 10px;
  height:100%;
  
`

export const HomePageTextStyled = styled.div.attrs((props) => ({}))`
  padding:3px;
  color:${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};
`
