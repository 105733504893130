import 'bootstrap/dist/css/bootstrap.css';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import AppWrapper from './app/AppWrapper';
import {store} from './app/store';
import ScrollToTop from "./components/ui/ScrollToTop";
import {BlastProvider} from './providers/blast/BlastProvider';
import {EnvironmentProvider} from './providers/environment/EnvironmentProvider';
import {SessionProvider} from './providers/session/SessionProvider';
import {SystemProvider} from './providers/system/SystemProvider';
import {GuslThemeProvider} from './providers/theme/GuslThemeProvider';
import './static/css/App.css';
import './App.css';

import GlobalStyle from './styles/themes/globalStyle'

const container = document.getElementById('root');
const root = createRoot(container!);


root.render(
    <GuslThemeProvider>
        <GlobalStyle/>
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <EnvironmentProvider>
                <BlastProvider>
                    <SessionProvider>
                        <SystemProvider>
                            <Provider store={store}>
                                <ScrollToTop/>
                                <AppWrapper/>
                            </Provider>
                        </SystemProvider>
                    </SessionProvider>
                </BlastProvider>
            </EnvironmentProvider>
            </Suspense>
        </BrowserRouter>
    </GuslThemeProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
