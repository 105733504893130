import styled, {ThemeProps} from 'styled-components';
import {MoneyDTO} from '../../types';

interface MoneyStyledProps extends ThemeProps<any> {
    amount: MoneyDTO | undefined,
    colorise: boolean,
    banner: boolean
}

export const AmountStyled = styled.div.attrs((props: MoneyStyledProps) => ({}))<MoneyStyledProps>`
color: ${props => {
    let value = props.theme.table.columnColor
    if (props.colorise) {
        if (props.amount && props.amount.value) {
            value = props.amount.value < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
        }
    }
    return value
}} !important ;
// font-size: ${props => props.banner ? '32px' : props.theme.table.columnFontSize};

`

interface BannerStyledProps extends ThemeProps<any> {
    amount: MoneyDTO | undefined,
    colorise: boolean,
}

export const BannerStyled = styled.div.attrs((props: BannerStyledProps) => ({}))<BannerStyledProps>`
color: ${props => {
    let value = props.theme.table.columnColor
    if (props.colorise) {
        if (props.amount && props.amount.value) {
            value = props.amount.value < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
        }
    }
    return value
}} !important ;
font-size: 32px; // ${props => props.theme.table.columnFontSize};
  box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.75);
  border-radius: 5px;
background-color: ${props => props.theme.panel.panelBgColor};
width:300px;
text-align:center;
padding: 10px;
margin-bottom: 20px;
`
export const BannerLabelStyled = styled.div.attrs((props) => ({}))`
label {
color: ${props => props.theme.table.columnColor} !important;
font-size: ${props => props.theme.table.columnFontSize};
}
`

