import styled, {ThemeProps} from 'styled-components';

interface InputStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}

export const TextAreaStyled = styled.textarea.attrs((props: InputStyledProps) => ({
    className: "form-control form-control-sm bg-transparent rounded-0 text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<InputStyledProps>`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`
