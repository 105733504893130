import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IMenuDTO} from '../../types';

export interface MaintainTableToggleAction {
    code: string;
    menuItem: IMenuDTO | undefined;
}

interface MaintainTableState {
    [id: string]: MaintainTableActionState
}

export interface MaintainTableActionState {
    showInline: boolean,
    activeItem: IMenuDTO | undefined,
}

const initialState: MaintainTableState = {
    // showInline: false,
    // activeItem: undefined
}

export const maintainTableSlice = createSlice({
    name: 'maintainTableSlice',
    initialState,
    reducers: {
        toggle(state, action: PayloadAction<MaintainTableToggleAction>) {
            // const newState = Object.assign({}, state)
            let entry: MaintainTableActionState = state[action.payload.code]
            if (!entry) {
                entry = {
                    showInline: false,
                    activeItem: undefined
                }
            }
            if (!entry.showInline) {
                entry.activeItem = action.payload.menuItem;
            } else {
                entry.activeItem = undefined;
            }
            entry.showInline = !entry.showInline;
            // newState[action.payload.code] = entry
            state[action.payload.code] = entry
            // return newState
        },
    }
})

export const {
    toggle,
} = maintainTableSlice.actions

export default maintainTableSlice.reducer
