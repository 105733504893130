import React, { useContext, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { EnvironmentContext } from "../../../../providers/environment/EnvironmentContext";
import { log } from "../../../../services/LogService";
import {
  areOrderByActionsMediaTypeSensitive,
  canShowFilters,
  canShowPagination,
  canShowRefresh,
  canShowSearch,
  matchMediaTypeWithOrderByAction
} from "../../../../utils/Utils";
import {
  FieldConfigDTO,
  IMenuDTO,
  MediaType,
  OrderByActionDO,
  OrderByDTO,
  WidgetPanelProperties
} from "../../../types";
import { maintainTableService } from "../../maintain-table/MaintainTableService";
import { GuslTableState } from "../guslTableSlice";
import { updateQueryParams } from "../queryParamsSlice";
import TableSpinner from "../spinner/TableSpinner";
import { OrderByActions } from "./OrderByActions";
import SearchBox from "./search-box/SearchBox";
import SidePanelControls from "./side-panel-controls/SidePanelControls";
import { OrderByActionStyled, OrderByActionWrapperStyled, TableControlsStyled } from "./styled";
import { SystemContext } from "../../../../providers/system/SystemContext";
import { boolean } from "yargs";

export type TableControlsProps = {
  code: string,
  state: GuslTableState,

  haveFilters?: boolean,
  isLoading?: boolean,
  pagination?: JSX.Element | JSX.Element[]

  isHeader: boolean,

  isFooter: boolean,

  orderByActions: OrderByActionDO[],
  // MK 29/09/2023
  hasAdvancedFilters?: boolean,

  widgetPanelProperties?: WidgetPanelProperties

}

export default function TableControls({
                                        code,
                                        state,
                                        haveFilters,
                                        isLoading,
                                        pagination,
                                        isHeader,
                                        isFooter,
                                        orderByActions,
                                        hasAdvancedFilters,
                                        widgetPanelProperties
                                      }: TableControlsProps) {
  const [className] = useState("TableControls-" + new Date().getTime());
  const environmentContext = useContext(EnvironmentContext);
  const systemContext = useContext(SystemContext);
  const showSearchBox: boolean = state?.showSearchBox || false;
  const haveSearchBox: boolean = state?.haveSearchBox || false;
  const splitScreen: boolean = useAppSelector(state => state.uiSlice.splitScreen);
  const isMobile = environmentContext.isMobileDevice(widgetPanelProperties);
  const dispatch = useAppDispatch();

  const [showPagination] = useState<boolean>(canShowPagination(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl));
  const [showSearch] = useState<boolean>(canShowSearch(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl));
  const [showFilter] = useState<boolean>(canShowFilters(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl));
  const [showRefresh] = useState<boolean>(canShowRefresh(environmentContext.getCurrentMediaType(widgetPanelProperties), state.tableControl));
  const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(widgetPanelProperties));

  const showDesktopTableControls: boolean = !isMobile && !splitScreen;
  const showMobileTableControls: boolean = isMobile && !splitScreen;
  // const showMobileContent = state.showMobileTableControls;
  const showMobileContent = true;
  const showOrderBys = (orderByActions || []).length > 0;
  // MK 12/08/2023
  const showTopTableFilters = state?.showTopFilters;

  if (!showPagination && !showSearch && !showFilter && !showRefresh && !showOrderBys) {
    return <></>;
  }

  const isOrderedBy = (action: OrderByActionDO): [boolean, string | undefined] => {
    let orderedBy = false;
    let order: string | undefined;

    const orderBys = state.lastQueryParams?.orderBys || [];
    try {
      if (orderBys?.length) {
        orderedBy = orderBys[0].field === action.field;
        order = orderBys[0].order;
      }
    } catch (e) {
      log.error(className, "MSG-E-001", "orderBy detection error");
    }
    return [orderedBy, order];
  };

  const renderOrderByActions = (): React.ReactElement => {

    const actionMediaTypeSensitive = areOrderByActionsMediaTypeSensitive(orderByActions);

    const onOrderBy = (action: OrderByActionDO) => {
      const fld: FieldConfigDTO = {
        name: action.field,
        label: "",
        type: "text"
      };

      const orderBys = maintainTableService.inCurrentOrderBy(fld, state.lastQueryParams)
        ? maintainTableService.toggleOrderBy(fld, state.lastQueryParams)
        : maintainTableService.getOrderByWithOrder(fld, action.order);
      if (state.lastQueryParams) {
        dispatch(updateQueryParams({
          code: code,
          queryParams: { ...state.lastQueryParams, orderBys: orderBys, skip: 0 }
        }));
      }
      // @ts-ignore
      // dispatch(setScrollLeftPosition({code, scrollLeftPosition: topScrollBarPositionRef!.current}));


    };

    return (
      <OrderByActionWrapperStyled>
        {orderByActions
          .filter(action => matchMediaTypeWithOrderByAction(action, mediaType, actionMediaTypeSensitive))
          .map((action: OrderByActionDO, idx) => {
              const [isOrdered, currentOrder] = isOrderedBy(action);
              return <OrderByActionStyled
                className={!currentOrder || currentOrder === "ASC" ? action.ascIcon : action.descIcon}
                isMobile={isMobile}
                active={isOrdered}
                onClick={() => onOrderBy(action)} key={"ord_" + idx} />;
            }
          )
        }
      </OrderByActionWrapperStyled>
    );
  };

  const onQueryParamsChange = (orderBys: OrderByDTO[]) => {
    if (state.lastQueryParams) {
      dispatch(updateQueryParams({
        code: code,
        queryParams: { ...state.lastQueryParams, orderBys: orderBys, skip: 0 }
      }));
    }
  };
  return (
    <TableControlsStyled>
      <div>
        {isLoading ?
          <TableSpinner /> : <span></span>}
      </div>

      <div className="row g-0 mb-2 d-flex align-items-center justify-content-between">
        {splitScreen && <>
          <div className="col-md-6 col-lg-5 col-xxl-4">
            {pagination}
          </div>
          <div className="col-md-6 col-lg-5 col-xxl-4">
            {/* MK 12/08/2023  hiding search box when top filters are open*/}
            {showTopTableFilters ? "show" : "hide"}
            {(state.searchableFields.length && !showTopTableFilters) ?
              <SearchBox
                code={code}
                searchableFields={state.searchableFields}
                searchString={state.searchString || ""}
                searchPrompt={state.searchPrompt}
                splitView
              /> : <></>}
          </div>
        </>
        }
        {showDesktopTableControls && <>
          {showPagination && <div className=" d-none d-md-flex col-lg-4">{pagination}</div>}
          {showSearch && <div className={" col-md-6 col-lg-5 col-sm-12 mx-auto  text-center"}>
            {(haveSearchBox && showSearchBox && !showTopTableFilters) ?
              <SearchBox
                code={code}
                searchableFields={state.searchableFields}
                searchString={state.searchString || ""}
                searchPrompt={state.searchPrompt}
              /> : <></>}
          </div>}
          <div className=" col-md-6 col-lg-3 col-sm-12 text-end">
            {state && <SidePanelControls
              code={code}
              state={state}
              haveFilters={haveFilters}
              isHeader={isHeader}
              isFooter={isFooter}
              hasAdvancedFilters={hasAdvancedFilters}
              widgetPanelProperties={widgetPanelProperties}
            />}

          </div>
        </>}
      </div>
      {showMobileTableControls &&
        <>
          <div className={"row g-0"}>
            {showMobileContent && <div className="col-12 text-end">
              {/*{renderOrderByActions()}*/}
              <OrderByActions
                orderByActions={orderByActions}
                isMobile={isMobile}
                mediaType={mediaType}
                lastQueryParams={state.lastQueryParams}
                onQueryParamsChange={(orderBys: OrderByDTO[]) => onQueryParamsChange(orderBys)}
              />
              <SidePanelControls code={code} state={state} haveFilters={haveFilters}
                                 isHeader={isHeader}
                                 isFooter={isFooter}
                                 widgetPanelProperties={widgetPanelProperties} />
            </div>}
          </div>
          <div className={"row g-0  mt-2"}>
            {showMobileContent && showSearch &&
              <div className="col-12 ">
                {(haveSearchBox && showSearchBox) ?
                  <SearchBox code={code}
                             searchableFields={state.searchableFields}
                             searchString={state.searchString || ""}
                             searchPrompt={state.searchPrompt}
                             showFieldSelect={!isMobile}
                             widgetPanelProperties={widgetPanelProperties}
                  /> : <></>}
              </div>
            }
          </div>

        </>

      }

    </TableControlsStyled>

  );


}
