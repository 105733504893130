import React from 'react';
import {Observable} from 'rxjs'
import {GlobalSearchDTO} from '../../components/common/global-search/type';
import {IMenuDTO, ISystemDTO, SystemNotificationDTO, ToolbarItemDTO, WidgetDO} from '../../components/types';


export interface SystemProps {
    getSystemConfig: () => ISystemDTO,
    getMenuConfig: (code: string) => IMenuDTO | undefined,

    getChildMenuConfig: (parentCode: string, childCode: string) => IMenuDTO | undefined,
    getMenuFromToolbarConfig: (code: string) => IMenuDTO | undefined,

    getItemFromToolbarConfig: (code: string) => ToolbarItemDTO | undefined,

    hasMobileFooter: () => boolean,

    getMenuConfigFromPath: (path: string) => IMenuDTO | undefined,
    watchSystemLoaded: () => Observable<boolean>;
    watchSystemNotifications: () => Observable<SystemNotificationDTO | undefined>;
    toast: (notification: SystemNotificationDTO) => void;

    getAllWidgets(): WidgetDO[];

    getGlobalSearchConfig: () => GlobalSearchDTO | undefined,
}


const getBlankConfig = (): ISystemDTO => {
    return {} as ISystemDTO;
}

const createDefault = (): SystemProps => ({

    getSystemConfig: (): ISystemDTO => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return getBlankConfig();
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    getChildMenuConfig: (parentCode: string, childCode: string): IMenuDTO | undefined => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return undefined;
    },
    getMenuConfig: (code: string): IMenuDTO | undefined => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return undefined;
    },
    getMenuFromToolbarConfig: (code: string): IMenuDTO | undefined => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return undefined;
    },
    getItemFromToolbarConfig: (code: string): ToolbarItemDTO | undefined => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return undefined;
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    getMenuConfigFromPath: (path: string): IMenuDTO | undefined => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return undefined;
    },
    watchSystemLoaded: (): Observable<boolean> => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return new Observable<boolean>((observer) => {
            observer.complete();
        });
    },
    watchSystemNotifications: (): Observable<SystemNotificationDTO | undefined> => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return new Observable<SystemNotificationDTO | undefined>((observer) => {
            observer.complete();
        });
    },
    toast: (notification: SystemNotificationDTO) => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
    },
    hasMobileFooter: () => {
        return false;
    },
    getAllWidgets: () => {
        return []
    },
    getGlobalSearchConfig: () => {
        console.warn(
            'Oops likely you forgot to add the system context provider on top of your app',
        )
        return undefined;
    }


});

export const SystemContext = React.createContext<SystemProps>(
    createDefault(),
)
