import {log} from '../../../services/LogService';
import {splitAndAddCssProperties} from '../../../utils/CssUtils';
import {isDefined, notDefined} from '../../../utils/TypeCheckers';
import {ReportColumnDO, ReportRowDO, ReportTableDO} from './types';

export const getTableWidth = (table: ReportTableDO, ignoreTableCss? :boolean): object => {
    const style: { [id: string]: any } = {
        width: ((table.w === undefined || table.w === 0 || table.w === -1) ? '100' : table.w) + '%',
        overflow: 'hidden',
    }
        // border-shadow
    if (!ignoreTableCss && isDefined(table.css)) {
        splitAndAddCssProperties(style, table.css);
    }
    if (!ignoreTableCss && isDefined(table.icss)) {
        splitAndAddCssProperties(style, table.icss);
    }
    log.info('ReportUtils', 'MSG002', 'style', style)
    return style;
}

export const getColWidth = (table: ReportTableDO, row: ReportRowDO, col: ReportColumnDO, index: number): object => {

    const style: { [id: string]: any } = {}

    if (isDefined(table.cw)) {
        if (index < table.cw.length) {
            style['width'] = table.cw[index] + '%'
            if (isDefined(col.css)) {
                splitAndAddCssProperties(style, col.css);
            }
            return style;
        }
    }

    let width: number = 10;

    if (row !== undefined && row.c !== undefined) {
        width = 100 / row.c.length;
    }
    style['width'] = width + '%'
    log.info('ReportUtils', 'MSG008', 'col.css', col.css)

    if (isDefined(col.css)) {
        splitAndAddCssProperties(style, col.css);
    }
    log.info('ReportUtils', 'MSG008', 'style', style)
    return style;
}

export const getRowStyle = (row: ReportRowDO): object => {
    const style: { [id: string]: any } = {}
    if (isDefined(row.css)) {
        splitAndAddCssProperties(style, row.css);
    }
    return style;
}

export const getColFormat = (table: ReportTableDO, row: ReportRowDO, col: ReportColumnDO): string => {
    if (isDefined(col.f) && col.f.length > 0) {
        return getFormat(col.f);
    }
    if (isDefined(row.f) && row.f.length > 0) {
        return getFormat(row.f);
    }
    if (isDefined(table?.f) && table.f.length > 0) {
        return getFormat(table.f);
    }
    return '';
}

export const getRowFormat = (table: ReportTableDO, row: ReportRowDO): string => {
    if (isDefined(row.f) && row?.f?.length > 0) {
        return getFormat(row.f);
    }
    if (isDefined(table.f) && table?.f?.length > 0) {
        return getFormat(table.f);
    }
    return '';
}

export const getFormat = (formats: any[]): string => {
    if (notDefined(formats)) {
        return '';
    }
    let cssClasses = '';
    (formats || []).forEach(format => cssClasses = cssClasses + ' ' + format);
    log.info('ReportUtils', 'MSG001', 'cssClasses', cssClasses)
    return cssClasses;
}
