import React, {useContext, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {hasValidation, shouldDisable, shouldHide} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {isZero} from '../../../utils/TypeCheckers';
import {extractExtraFieldProperties, unSubscribe} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {ExtraFieldProperties} from '../money/types';
import {FloatingFormStyled, InputStyled} from "../text/styled";
import {PercentStyled, PercentTableValueStyled} from './styled';


// copy me when creating a new field
// add me to (1) FieldService.ts
/* eslint-disable @typescript-eslint/no-unused-vars */
export const PercentField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'PercentField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [tableValue] = useState<any>({__html: isBlank(properties.data) || isZero(properties.data) ? '' : properties.data + ' %'})
    // tableValue.__html += ' %'
    // const [formValue, setFormValue] = useState<number>(properties?.data || '');
    const [formValue, setFormValue] = useState<number>(_guslFormState?.getFieldValue(properties)  ||  properties.data|| '');

    const valueRef = useRef(properties?.data);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false);
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties));
    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            setFormValue(newValue)
            setRefreshCounter(refreshCounter + 1)
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        }
    }, [properties]);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])


    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss)
        if (!style['textAlign']) {
            if (properties.isTableView) {
                style['textAlign'] = 'right'
                style['wordWrap'] = 'break-word'
            } else {
                style['textAlign'] = 'left'
            }
        }

        if (formValue === undefined && !extraFieldProperties.defaultZero) {
            return <></>
        }

        let value = parseFloat('' + formValue || '')
        if (isNaN(value)) {
            value = 0
        } else {
            value = value * (extraFieldProperties.flip ? -1 : 1)
        }

        if (isZero(value) && (extraFieldProperties.zeroAsDash || extraFieldProperties.zeroAsSpace)) {
            return <div style={{'textAlign': 'right'}}>{extraFieldProperties.zeroAsDash ? '-' : ' '}</div>
        }

        let sign = ''
        if (extraFieldProperties.withSign) {
            sign = value >= 0 ? '+' : ''
        } else if (extraFieldProperties.withNegative && value < 0) {
            sign = ''
        }
        const percSymbol = ' %'

        let prefix = '';
        if (extraFieldProperties.prefix) {
            prefix = extraFieldProperties.prefix
        }
        let suffix = '';
        if (extraFieldProperties.suffix) {
            suffix = extraFieldProperties.suffix
        }

        return (
            <PercentStyled id={'perc_' + properties.fieldConfig.name} colorise={extraFieldProperties.colorise || false}
                           style={style}
                           key={properties.fieldConfig.name + '_' + refreshCounter}
                           value={value}><PercentTableValueStyled>{`${prefix}${sign}${value}${percSymbol}`}</PercentTableValueStyled></PercentStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const onDataInputChange = (value: string) => {
            setFormValue(parseInt(value));
            properties.onChange(properties.fieldConfig.name, value);
        }

        const hideField = shouldHide(formMode, properties.fieldConfig);
        const disableField: boolean = shouldDisable(formMode, properties.fieldConfig);
        const required = hasValidation(properties.fieldConfig?.validators);
        // still to do

        return (
            <>
                {
                    !hideField &&
                    <FloatingFormStyled>
                        <InputStyled type={properties?.fieldConfig?.label === 'Password' ? 'password' : 'text'}
                                     id={properties?.fieldConfig?.name || 'def'}
                                     ref={valueRef}
                                     value={formValue}
                                     autoFocus={properties.isFirstField}
                                     readOnly={formMode === 0 || properties?.fieldConfig?.label === 'Id'}
                                     disabled={formMode === 0 || properties?.fieldConfig?.label === 'Id'}
                                     onChange={(e) => onDataInputChange(e.target.value)}
                                     required={required}
                                     submitted={submitted}
                                     noValue={!formValue}
                        />
                        <FieldLabel properties={properties}/>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}

                    </FloatingFormStyled>
                }
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
