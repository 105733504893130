import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {EnabledStyle, StatusWrapperStyled, StyledAvatarName, VerifyStyle} from "../../layouts/alvar-fe-layout/styled";
import {StyledAvatar, StyledHeader} from '../../layouts/mobile-layout/styled';
import {environmentService} from '../../providers/environment/EnvironmentService';
import GetLoggedInUser from "./GetLoggedInUser";

export interface UserInfoDO {

}

export default function UserInfo() {
    const user = GetLoggedInUser();
    const [showStatus] = useState<boolean>(environmentService.getEnvironment()?.showStatus || false);

    const navigate = useNavigate();

    let initial = ''
    if (user.thirdPartyId && user.thirdPartyId.length > 0 && user.thirdPartyId.length < 8) {
        // 8 = so we do not show id
        initial = user.thirdPartyId
    } else if (user.nickname && user.nickname.length > 0) {
        initial = (user.nickname || ' ').substring(0, 1)
    }

    let nickname = user.nickname || ''

    let accountType = ''
    if (user.accountType && user.accountType.length > 0) {
        accountType = (user.accountType + '  A/c')
    }


    const renderStatus = (): React.ReactElement => {
        const onReadMoreClick = () => {
            environmentService.closeSideMenu()
            navigate('/legal/verify');
        }
        switch (user.status) {
            case 'VERIFY' :
                return <StatusWrapperStyled>Status: <VerifyStyle
                    onClick={() => onReadMoreClick()}>UNVERIFIED</VerifyStyle></StatusWrapperStyled>
            case 'ENABLED' :
                return <StatusWrapperStyled>Status: <EnabledStyle
                >VERIFIED</EnabledStyle></StatusWrapperStyled>
        }
        return <></>
    }


    return (
        <StyledHeader>
            <StyledAvatar>{initial}</StyledAvatar>
            <StyledAvatarName>{nickname}
                <br/>
                <div><b>{accountType}</b></div>
                {showStatus && renderStatus()}
            </StyledAvatarName>
        </StyledHeader>
    )
}
