import React from 'react';
import {fieldService} from '../../../FieldService';
import {BreadcrumbsDO, FieldConfigDTO} from '../../../types';
import {BreadcrumbsStyled} from '../styled';

interface BreadcrumbProperties {
    breadcrumb?: BreadcrumbsDO
}


export const Breadcrumb = ({
                               breadcrumb
                           }: BreadcrumbProperties): React.ReactElement<BreadcrumbProperties> => {

    if (!breadcrumb) {
        return <></>
    }
    const fieldConfig: FieldConfigDTO = {
        name: 'breadcrumb',
        label: '',
        type: 'breadcrumb'
    }

    const rowData: any = {}
    rowData.breadcrumb = breadcrumb;
    return (
        <BreadcrumbsStyled>
            {fieldService.getTableField(fieldConfig, undefined, rowData)?.render()}
        </BreadcrumbsStyled>
    )

}
