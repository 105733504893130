import styled, {ThemeProps} from 'styled-components';

interface SelectProperties extends ThemeProps<any> {
    name: string,
    id: string,
    onChange?: Function,
    ref: HTMLSelectElement
}

interface InputProperties extends ThemeProps<any> {
    name: string,
    id: string,
    type: string,
    ref: HTMLInputElement,
    min?: Date | undefined,
    max?: Date | undefined,
    step?:number,
    lightBg?:boolean,
    className?:string,
    // MK 13/08/2023
    placeholder?:string,
    // MK 22/08/2023
    defaultValue?:number
}

interface OptionProperties extends ThemeProps<any> {
    key: number,
    value: string,

}

export interface DateRangeUnitProperties extends ThemeProps<any> {
    active?: boolean | undefined,
    className?: string,
    onClick?: Function,

}

export interface PastUnitsPaginationBtnProperties extends ThemeProps<any> {
    active: boolean,
    onClick: Function,
    className: string
}

export interface PastUnitsPaginationIconProperties extends ThemeProps<any> {
    active: boolean,
    icon: string
}

export const InputStyled = styled.input.attrs((props: InputProperties) => ({
    id: props.id,
    name: props.name,
    ref: props.ref,
    type: props.type,
    min: props.min ? props.min : undefined,
    max: props.max ? props.max : undefined,
    step:props.step,
    className: props.className,
    placeholder: props.placeholder ? props.placeholder : "",
    defaultValue:props.defaultValue

}))<InputProperties>
    `
      color: ${props => props.theme.colors.dark[1]};
      border: none;
      border-radius: 0;
      background-color: ${props => !props.lightBg ? 'transparent' : props.theme.colors.dark[0]};
      // MK 13/8/2023
      border-bottom: 1px solid ${props => props.theme.action.borderColor};


    `

export const DateRangeUnitStyled = styled.span.attrs((props: DateRangeUnitProperties) => ({
    onClick: props.onClick,
    className: 'col'
}))<DateRangeUnitProperties>`
      color: ${props => props.color ?
    (props.active ? props.theme.colors.background[1] : props.color) :
    (props.active ? props.theme.colors.light : props.theme.colors.dark[1])};
    
    background-color:${props => props.active ? props.theme.colors.accentColorMuted: props.theme.colors.dark[0]};
    font-size:12px;
    margin:5px 3px;
    border-radius:2px;
    padding:4px 5px;
    text-align:center;
     :hover{
                cursor:pointer;
                color:${props => props.theme.colors.light};
                background-color:${props => props.theme.colors.accentColorMuted};
            }
`
export const CustomDatesWrapperStyled = styled.div.attrs((props: DateRangeUnitProperties) => ({
    className: 'row g-0'
}))<DateRangeUnitProperties>`
      color: ${props => props.theme.colors.dark[1]};
    font-size:0.75rem;
    margin:5px 5px 0 5px;
`
export const CustomDateStyled = styled.div.attrs((props: DateRangeUnitProperties) => ({
    className: 'col '+props.className
}))<DateRangeUnitProperties>`
      
`
export const PastUnitsPaginationBtnStyled = styled.div.attrs((props: PastUnitsPaginationBtnProperties) => ({
    onClick: props.onClick,
    className: props.className,
    role: 'button'
}))<PastUnitsPaginationBtnProperties>
    `
     text-align:center;
     border: 1px solid ${props => props.theme.colors.dark[0]};
     :hover {
        background-color:${props => props.theme.colors.accentColorMuted};
     }
    `

export const PastUnitsPaginationIconStyled = styled.i.attrs((props: PastUnitsPaginationIconProperties) => ({
    className: props.icon,

}))<PastUnitsPaginationIconProperties>
    `
     color:${props => props.active ? props.theme.colors.light : props.theme.colors.dark[0]};
     font-size: 13px;
    `
