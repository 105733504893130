import {Dispatch} from 'react';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {SessionContextProps} from '../../../providers/session/types';
import {SystemProps} from '../../../providers/system/SystemContext';
import {log} from '../../../services/LogService';
import {constructUrl, parse, substituteValues} from '../../../utils/Utils';
import {DEAL_PAGE} from '../../bespoke/otto-events/deal-view/DealView';
import {ActionConfigDTO, ActionType, GuslErrors, SystemNotificationDTO} from '../../types';
import {openDialog} from '../../ui/navigation/left-hand-menu/footerOverlaySlice';
import {openCardPanel} from '../card/cardActionSlice';

export class ActionHandler {

    constructor(
        public navigate: NavigateFunction,
        public sessionContext: SessionContextProps,
        public systemContext: SystemProps,
        public dispatcher: Dispatch<any>,
    ) {

    }

    public handle(code: string, actionItem: ActionConfigDTO, request: any, rowData?: any, reLoad?: () => void): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            if (actionItem.actionType === ActionType.ROUTE) {
                this.navigate("/" + constructUrl(actionItem?.route || '', rowData || {}));
                return
            }
            if (actionItem.actionType === ActionType.FIRE_COMMAND) {
                // I think these have to be bespoke
                if (actionItem?.command === 'open-deal-page') {
                    this.navigate(DEAL_PAGE + '/' + request?.id)
                }
            }

            if (actionItem.actionType === ActionType.ACTION_ONLY) {
                try {
                    this.sessionContext.post<any, SystemNotificationDTO>(constructUrl(actionItem.url, rowData), request, new AbortController())
                        .then((response: any) => {
                            if (response.data['reload'] && reLoad) {
                                reLoad()
                            }
                            this.systemContext.toast(response.data)
                            resolve(true)
                            return
                        })
                        .catch((reason: GuslErrors) => {
                            console.error('Error', reason)
                            reject(reason)
                            return
                        });
                } catch (err) {
                    log.error('ActionHandler', 'ERR001', 'performActionOnly error', actionItem, err)
                    reject(err)
                    return
                }
            }
            if (actionItem.actionType === ActionType.ROUTE_IN_CARD_PANEL) {
                this.dispatcher(openCardPanel({
                    code: code,
                    opened: true,
                    bespokePage: actionItem?.route,
                    route: actionItem?.route,
                    rowData: rowData,
                    properties: parse(substituteValues(actionItem?.properties || '', rowData))
                }))
                resolve(true)
            }

            if (actionItem.actionType === ActionType.ROUTE_IN_OVERLAY) {
                this.dispatcher(openDialog({
                    title: actionItem.label || '',
                    route: actionItem.route || '',
                    rowData: rowData,
                    properties: parse(substituteValues(actionItem?.properties || '', rowData)),
                    placement: 'bottom'
                }))

            }
        })
    }
}
