import {StylesConfig} from 'react-select/dist/declarations/src/styles';
import {getCssValue} from '../../../utils/Utils';

export const OptionFieldStyles: StylesConfig = {

    option: (provided: any, state: any) => {
        return {
            ...provided,
            padding: '5px 10px',
            color: getCssValue('--muted') + ' !important',
           // backgroundColor: state.isSelected ? getCssValue('--main-background-color') : "",
            cursor: 'pointer',

        }
    },
    multiValueRemove: (provided: any, state: any) => {
        return {
            ...provided,
            //color: getCssValue('--muted') + ' !important',
            backgroundColor: "transparent !important",
            cursor: 'pointer',

        }
    },
    indicatorSeparator    : (provided: any, state: any) => {
        return {
            ...provided,
            backgroundColor: getCssValue('--muted') + ' !important',
        }
    },

    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: getCssValue('--white') + ' !important',
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: getCssValue('--muted') + ' !important',
    }),

    control: () => ({
        border: 'none',
        cursor: 'default',
        display: 'flex',
        webkitBoxFlexWrap: 'wrap',
        webkitFlexWrap: 'wrap',
        msFlexWrap: 'wrap',
        flexWrap: 'wrap',
        webkitBoxPack: 'justify',
        webkitJustifyContent: 'space-between',
        justifyContent: 'space-between',
        minHeight: '38px',
        outline: '0!important',
        position: 'relative',
        webkitTransition: 'all 100ms',
        transition: 'all 100ms',
        boxSizing: 'border-box'
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided, opacity, transition,
            //border: '1px solid #dee2e6 !important',
            border: 'none !important',
            fontSize: '14px',
            marginRight: '0.25rem !important',
            marginLeft: '0.25rem !important',
            padding: '0.25rem !important',
            borderRadius: '0.25rem !important',
            backgroundColor: 'none',
            color: getCssValue('--muted') + ' !important'
        };
    },
    multiValue: (provided: any, state: any) => {
        return {
            ...provided,
            border: '1px solid #dee2e6 !important',
            fontSize: '0.9em !important',
            marginRight: '0.25rem !important',
            marginLeft: '0.25rem !important',
            padding: '0.25rem !important',
            borderRadius: '3px !important',
            backgroundColor: 'none',
            color: getCssValue('--muted') + ' !important'
        };
    },
    multiValueLabel: (provided: any, state: any) => {
        return {
            ...provided,
            color: getCssValue('--muted') + ' !important'
        };
    },

}
export const PaginatorStyles: StylesConfig = {
    ...OptionFieldStyles,
    dropdownIndicator: () => ({
        display: 'none'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    singleValue: (provided: any, state: any) => {
        return {
            ...provided,
            width:'30px',
            //border: '1px solid #dee2e6 !important',
            border: 'none !important',
            borderBottom: '1px solid ' + getCssValue('--muted') + ' !important',
            fontSize: '0.875em',
            marginRight: '0.25rem !important',
            marginLeft: '0.25rem !important',
            padding: '0.25rem !important',
            backgroundColor: 'none',
            color: getCssValue('--muted') + ' !important'
        };
    },

}

