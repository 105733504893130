import {AxiosResponse} from 'axios'
import {UserAgentApplication} from 'msal';
import React, {useContext} from 'react'
import {useNavigate} from "react-router-dom";
import {Observable} from 'rxjs'
import {EndpointDTO, QueryParamsDTO} from '../../components/types';
import {QrCodeResponseDTO} from '../../pages/login/types';
import {isDefined, notDefined} from '../../utils/TypeCheckers'
import {RunOnceEffect} from '../../utils/Utils'
import {BlastContext} from '../blast/BlastContext'
import {SimpleMessageBO} from '../blast/commands';
import DataCache from '../blast/DataCache';
import {EnvironmentContext} from '../environment/EnvironmentContext';
import {GuslThemeContext, ReactChildProperties} from '../theme/GuslThemeProvider';
import {guslStorage} from './GuslStorage'
import {refreshService} from './RefreshService';
import {SessionContext} from './SessionContext'
import {sessionService} from './SessionService';

import {GuslUser, SessionStatus, SignInResponseDTO, SignOutResponseDTO, User,} from './types'

//Headers with JWT Tokens
// need to be user-token until we change old ui
export const GUSL_USER_TOKEN_HEADER = 'user-token'
export const SESSION_TOKEN_HEADER = 'session-token'

// Timed one time password
export const TOTP_USER_TOKEN_HEADER = 'totp-user-token'
export const TOTP_SESSION_TOKEN_HEADER = 'totp-session-token'


// Google
export const GOOGLE_TOKEN_HEADER = 'access_token'
export const GOOGLE_X_TOKEN_HEADER = 'X-Access-Token'

// MSAL
export const MSAL_TOKEN_HEADER = 'msal-token'


export const EXCLUDE_HEADERS_FOR: string [] = [
    '/totp/v1/qrcode',
    '/security/v1/login',
    '/security/v1/token-login',
    '/security/v1/sign-in',
    '/legal/v1/policy',
    '/user/forgotten-details',
    'ui-props',
]


export const SessionProvider: React.FC<ReactChildProperties> = ({children}) => {

    const navigate = useNavigate()
    const blastContext = React.useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = useContext(GuslThemeContext);

    const [pathname, setPathname] = React.useState<string | undefined>(undefined)
    const [queryParams, setTheQueryParams] = React.useState<QueryParamsDTO | undefined>(undefined)

    sessionService.initialise(environmentContext, navigate, blastContext, guslThemeContext)

    RunOnceEffect(() => {
        blastContext.observeInboundCommands().subscribe((inbound: SimpleMessageBO<any>) => {
            if (inbound && inbound.cmd) {
                switch (inbound.cmd) {
                    case DataCache.TIMEZONE_CHANGED_CMD :
                        if (inbound.data) {
                            sessionService.setTimezone(inbound.data)
                        }
                }
            }
        })
    })
    RunOnceEffect(() => {
        sessionService.start()
    })

    const download = async <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return sessionService.download(url, body, controller)
    }
    const upload = async <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return sessionService.upload(url, body, controller)
    }
    const sendRequest = async <Request, Response>(endpoint: EndpointDTO, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return sessionService.sendRequest(endpoint, body, controller)
    }

    const hasNoSession = (): boolean => {
        const hasSession = isDefined(guslStorage.getJwtSession()) || isDefined(guslStorage.getGoogleToken())
        return !hasSession
    }

    const watchLogin = (): Observable<GuslUser | null> => {
        return sessionService.getLoginSubjectAsObservable()
    }

    const watchTandCUpgradeRequired = (): Observable<boolean> => {
        return sessionService.getTandCUpgradeRequiredAsObservable()
    }


    const watchLogout = (): Observable<number> => {
        return sessionService.getLogoutAsObservable()
    }

    const getLoggedInUser = (): User => {
        const guslUser = sessionService.getLoginValue();
        if (guslUser) {
            return {
                id: guslUser.id,
                email: guslUser.username,
                avatar: guslUser.avatar,
                status: guslUser.status
            };
        }
        return {
            id: '',
            email: '',
            avatar: '',
            status: ''
        };
    }
    const updateUser = (user: GuslUser | undefined) => {
        if (user) {
            sessionService.setLoginValue(user)
        }
    }

    const watchSystemReady = (): Observable<boolean> => {
        return sessionService.getSystemReadyAsObservable()
    }

    const watchSessionStatus = (): Observable<SessionStatus> => {
        return sessionService.getSessionStatusAsObservable()
    }

    const watchTimezone = (): Observable<string> => {
        return sessionService.getTimezoneAsObservable()
    }

    const setTimezone = (timezone: string): void => {
        sessionService.setTimezone(timezone)
    }

    const setOriginalPath = (path: string): void => {
        if (notDefined(pathname)) {
            setPathname(path)
        }
    }
    const getOriginalPath = (): string => {
        return pathname || ''
    }

    const getMsalInstance = (): UserAgentApplication | undefined => {
        return environmentContext.getMsalInstance()
    }

    const updateTheme = (themeId: string, lastUpdated?: string): void => {
        sessionService.updateTheme(themeId, lastUpdated)
    }

    const registerRefreshRate = (refreshRate: number) => {
        refreshService.registerRefreshRate(refreshRate)
    }

    const unregisterRefreshRate = (refreshRate: number) => {
        refreshService.unregisterRefreshRate(refreshRate)
    }

    const watchRefreshRate = (): Observable<number> => {
        return refreshService.watchRefreshRate()
    }

    const setQueryParams = (query: string | null | undefined) => {
        if (isDefined(query)) {
            try {
                setTheQueryParams(JSON.parse(query || ''))
            } catch (error) {
                console.log('failed to parse query params', error)
            }
        }
    }

    const getQueryParams = (): QueryParamsDTO | undefined => {
        return queryParams
    }

    const hasPowerOfAttorney = (): boolean => {
        return sessionService.hasPowerOfAttorney();
    }

    const getHomePage = (): string | undefined => {
        return sessionService.getHomePage();
    }

    const loginWithGoogleSsoToken = async (idToken: string): Promise<SignInResponseDTO> => {
        return sessionService.loginWithGoogleSsoToken(idToken)
    }

    const loginWithMsalSsoToken = async (name: string, username: string, idToken: string): Promise<SignInResponseDTO> => {
        return sessionService.loginWithMsalSsoToken(name, username, idToken)
    }

    const signOut = async (): Promise<SignOutResponseDTO> => {
        return sessionService.signOut()
    }

    const signIn = async (username: string, password: string, totp?: string): Promise<SignInResponseDTO> => {
        // if you want to block login
        // return new Promise<SignInResponseDTO>((resolve, reject) => {
        // })
        return sessionService.signIn(username, password, totp)
    }

    const getQrCode = async (username: string): Promise<QrCodeResponseDTO> => {
        return sessionService.getQrCode(username)
    }

    const post = async <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return sessionService.post(url, body, controller)
    }

    const put = async <Request, Response>(url: string, body?: Request, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return sessionService.put(url, body, controller)
    }

    const get = async <Request, Response>(url: string, controller?: AbortController): Promise<AxiosResponse<Response>> => {
        return sessionService.get<Request, Response>(url, controller)
    }

    const renewSession = async (): Promise<SignInResponseDTO> => {
        return sessionService.renewSession()
    }

    return (
        <SessionContext.Provider
            value={{
                hasNoSession,
                loginWithGoogleSsoToken,
                loginWithMsalSsoToken,
                clearSession: guslStorage.clearSession,
                getJwtSession: guslStorage.getJwtSession,
                setJwtSession: guslStorage.setJwtSession,
                getGoogleToken: guslStorage.getGoogleToken,
                setGoogleToken: guslStorage.setGoogleToken,
                isGoogle: guslStorage.isGoogle,
                isJwt: guslStorage.isJwt,
                signIn,
                signOut,
                getQrCode,
                watchLogin,
                watchTandCUpgradeRequired,
                watchLogout,
                getLoggedInUser,
                watchSystemReady,
                watchSessionStatus,
                watchTimezone,
                get,
                post,
                download,
                upload,
                put,
                sendRequest,
                setOriginalPath,
                getOriginalPath,
                getMsalInstance,
                updateTheme,
                registerRefreshRate,
                unregisterRefreshRate,
                watchRefreshRate,
                setQueryParams,
                getQueryParams,
                hasPowerOfAttorney,
                updateUser,
                getHomePage,
                setTimezone,
                renewSession
            }}>
            {children}
        </SessionContext.Provider>
    )
}
