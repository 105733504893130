import React, {useEffect, useRef, useState} from 'react';
import {Subscription} from 'rxjs';
import {useAppDispatch} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {CONTENT_START_POSITION} from '../../../providers/theme/GuslThemeProvider';
import {log} from '../../../services/LogService';
import {constructUrl, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {BuySellForm} from '../../common/buy-sell-form/BuySellForm';
import {panelClosed} from '../../common/card/cardActionSlice';
import {GuslReport} from '../../common/gusl-report/GuslReport';
import LoadingSpinner from '../../common/loading-spinner/LoadingSpinner';
import MenuBar from '../../common/maintain-table/bootstrap/MenuBar';
import {TableContainerStyled, TableContentStyled} from '../../common/maintain-table/styled';
import {ReportDO} from '../../common/report/types';
import {
    TabbedPageMenuBarWrapperStyled,
    TabContainerStyled,
    TabsContainerStyled,
    TabsStyledWrapperStyled,
    TabStyled
} from '../../common/tabbed-page/styled';
import {LEFT_HAND_MENU_COMMAND} from '../../FieldComponentMap';
import {ActionConfigDTO, IMenuDTO} from '../../types';
import {TradePanelContentStyled} from './styled';
import {createMenuItems, selectActiveMenu} from './TradeUtils';

interface TradePanelProperties {
    rowData?: any;
    properties?: any;
}

export const enum TradeAction {
    CloseOut = 'CloseOut',
    NewOrder = 'NewOrder',
    WatchListInfo = 'WatchListInfo',
    PositionInfo = 'PositionInfo',
    PositionNewOrder = 'PositionNewOrder',
}

const POSITION_INFO_URL = '/order-entry/{id}/get-position-info'

const WATCHLIST_INFO_URL = '/watchlist/{id}/get-watchlist-info'

export const TradePanel = ({rowData, properties}: TradePanelProperties): React.ReactElement<TradePanelProperties> => {

    const dispatch = useAppDispatch();

    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)

    const [menuItems, setMenuItems] = useState<IMenuDTO[]>([]);
    const [activeItem, setActiveItem] = useState<IMenuDTO | undefined>(undefined);
    const [activeItemMenuGroups, setActiveItemMenuGroups] = useState<ActionConfigDTO[] | undefined>([]);
    const [id, setId] = useState<string | undefined>('trade');
    const [report, setReport] = useState<ReportDO | undefined>(undefined);
    const [infoLoaded, setInfoLoaded] = useState<boolean>(false);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const isMobile = environmentContext.isMobileDevice();

    const contentElement = useRef(null);
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
            }, 100)
        });
    }, [contentElement]);

    RunOnceEffect(() => {
        const menus: IMenuDTO[] = createMenuItems(properties)
        setMenuItems(menus)
        const activeMenu = selectActiveMenu(menus, properties);
        if (activeMenu?.label === 'Info') {
            performGetInfo();
        }
        setActiveItem(activeMenu)
        return (() => {
            dispatch(panelClosed({code: ''}))
        })
    });

    const performGetInfo = () => {
        setInfoLoaded(false)
        const isPartOfCloseOut = properties?.action === 'PositionNewOrder'

        let url = isPartOfCloseOut ? POSITION_INFO_URL : WATCHLIST_INFO_URL
        url = constructUrl(url, {id: properties?.id})
        sessionContext.post<any, ReportDO>(url, {})
            .then((response) => {
                setReport(response.data)
                setInfoLoaded(true)
            }, reason => {
                console.log('error')
                setInfoLoaded(true)
            })
    }

    const renderTabLabel = (menuItem: IMenuDTO, idx: number, len: number): React.ReactElement => {
        const onTabClicked = () => {
            if (menuItem.label === 'Info') {
                performGetInfo();
            }
            setActiveItem(menuItem)
            setRefreshCounter(refreshCounter + 1)

        }

        const active: boolean = (activeItem?.code === menuItem?.code) || (activeItem?.code === LEFT_HAND_MENU_COMMAND)

        return (
            <TabStyled
                active={active}
                key={'tab_' + menuItem.code}
                isFirst={idx === 0}
                isLast={idx === len - 1}
                isMobile={isMobile}
                onClick={() => onTabClicked()}>{menuItem.label}</TabStyled>
        )
    }

    const renderCloseOut = (): React.ReactElement => {
        const props = {
            ticker: properties?.bbgTicker,
            actionParam: 'close',
            noTitle: true,
            side: properties?.side,
            isCloseout: true
        }

        return (
            <BuySellForm key={'buysell_' + refreshCounter} {...props} isTradePanel/>
        )
    }

    const renderNewOrder = (): React.ReactElement => {
        const props = {
            ticker: properties?.bbgTicker,
            actionParam: 'new',
            noTitle: true,
            side: properties?.side,
            isCloseout: false
        }
        return (
            <BuySellForm key={'buysell_' + refreshCounter} {...props} isTradePanel/>
        )

    }

    const renderInfoDetail = (): React.ReactElement => {
        return (
            <GuslReport
                code={'order_info'}
                data={report}
            />
        )
    }

    const renderInfo = (): React.ReactElement => {
        return (
            <>{infoLoaded ? renderInfoDetail() : <LoadingSpinner/>}</>
        )

    }

    const renderContents = (): React.ReactElement => {
        switch (activeItem?.label) {
            case 'Close Out':
                return renderCloseOut()
            case 'New Order':
                return renderNewOrder()
            case 'Info':
                return renderInfo()
        }
        return (
            <></>
        )
    }

    const renderTabLabels = (): React.ReactElement => {
        /**
         * We only render labels if there are more than 1 tab (there is always at least one)
         */
        return (
            <TabsStyledWrapperStyled isMobile={isMobile}>
                <TabsContainerStyled isMobile={isMobile}>
                    {menuItems && menuItems.length > 1 &&
                        menuItems.map((menuItem, idx: number) => renderTabLabel(menuItem, idx, menuItems.length))
                    }
                </TabsContainerStyled>
                {/** actions on the same level as tabs , can be under tabs as well, line 430*/}
                <div className={"ms-auto"}>{activeItemMenuGroups &&
                    <TabbedPageMenuBarWrapperStyled id={"menu-bar-wrapper"}>
                        {/*@ts-ignore*/}
                        <MenuBar entityId={id} menuGroups={activeItemMenuGroups}/>
                    </TabbedPageMenuBarWrapperStyled>
                }</div>
            </TabsStyledWrapperStyled>
        )
    }

    const renderTabs = (): React.ReactElement => {
        return (
            <TableContainerStyled id={'trade_panel_tabs'} footerHeight={footerHeight}>
                <TradePanelContentStyled isMobile={isMobile} id={'trade_panel_content'} ref={contentElement} startPos={startPos} footerHeight={footerHeight}>
                    {renderTabLabels()}
                    <TabContainerStyled id={'trade_panel_container'}>
                        {renderContents()}
                    </TabContainerStyled>
                </TradePanelContentStyled>
            </TableContainerStyled>
        )
    }


    return (
        <>{renderTabs()}</>
    )
}
