import Offcanvas from 'react-bootstrap/Offcanvas';
import styled, {ThemeProps} from 'styled-components'
import {
    CONTENT_START_POSITION,
    FOOTER_HEIGHT,
    LHM_WIDTH,
    LOGIN_IMAGE_HEIGHT,
    RELEASE_DETAILS_FOOTER_HEIGHT
} from '../../providers/theme/GuslThemeProvider';


export const LogoStyled = styled.img`
  width: 80%;
`

interface PageContainerProperties extends ThemeProps<any> {
    isMobile: boolean
}

export const PageContainerStyled = styled.div.attrs((props: PageContainerProperties) => ({}))<PageContainerProperties>`
  height: ${props => props.isMobile ? 'var(--internal-height)' : 'auto'};
  width: 100vw;
`

interface PageContentContainers extends ThemeProps<any> {
    isMobile: boolean;
    startPos: number;
    footerHeight: number;
}

export const PageContentContainerStyled = styled.div.attrs((props: PageContentContainers) => ({
    id: 'page_content'
}))<PageContentContainers>`
  height: ${props => {
    if (!props.isMobile) {
        return '100%'
    }
    return 'calc(var(--internal-height) - env(safe-area-inset-bottom) - ' + (props.footerHeight + props.startPos) + 'px)'
}};

  overflow: hidden;
`


interface MainContainerProperties extends ThemeProps<any> {
    $extraClassName: string | undefined
    coverUrl: string | undefined
}

export const MainContainer = styled.main.attrs((props: MainContainerProperties) => ({
    className: props.$extraClassName ? props.$extraClassName : '',
}))<MainContainerProperties>`
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;

  ${props => !props.coverUrl && 'background-color: ' + props.theme.modal.background + ';'}
  .login {
    ${props => !props.coverUrl && 'background-color: ' + props.theme.login.innerBackground + ';'}
  }

`

interface ForgottenDetailsProperties {
    capitalise?: boolean | undefined;
}

export const ForgottenDetailsStyled = styled.div.attrs((props: ForgottenDetailsProperties) => ({}))<ForgottenDetailsProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.4em;
  cursor: pointer;

  h1 {
    color: ${props => props.theme.login.linkColor};
    ${props => props.capitalise && 'text-transform: uppercase;'}
    ${props => props.capitalise && 'font-size:12px;'}
    ${props => props.capitalise && 'text-decoration: underline;'}
  }

`

interface LeftHandMenuProperties extends ThemeProps<any> {
    $isSideNavOpen: boolean;
    isMobile?: boolean;
    isIOS: boolean;
}

export const ContentWrapperStyled = styled.div.attrs(props => ({
    className: "row g-0",
}))`
  padding: 0;
`
export const LeftHandWrapperStyled = styled.div.attrs((props: LeftHandMenuProperties) => ({}))<LeftHandMenuProperties>`
  width: ${props => props.$isSideNavOpen ? LHM_WIDTH : 0}px;
`

interface MainContentWrapperProperties extends ThemeProps<any> {
    $isSideNavOpen: boolean;
    isMobile?: boolean;
    startPos: number;
    isIOS: boolean;
    footerHeight: number;
}

/*
  top: ${props => CONTENT_START_POSITION}px;

 */
export const MainContentWrapperStyled = styled.div.attrs((props: MainContentWrapperProperties) => ({
    id:'main-content'
}))<MainContentWrapperProperties>`
  // vvv scroll on table
  height: ${props => 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.footerHeight + props.startPos) + 'px)'};
  overflow: auto;
  padding: 3px 0 0 0;
  position: fixed;
  top: 36px;
  left: 0;
  ${props => !props.isMobile && props.$isSideNavOpen ? 'transform: translateX(' + LHM_WIDTH + 'px);' : 'transform: translateX(0);'}
  width: ${props => 'calc(var(--internal-width) - ' + (!props.isMobile && props.$isSideNavOpen ? LHM_WIDTH : 0) + 'px) !important;'}
`

export const WarningMessageTitleStyled = styled.h1`
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
`
export const WarningMessageContainerStyled = styled.div`
  color: ${props => props.theme.colors.background[0]};
  background-color: ${props => props.theme.colors.warning};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  line-height: 1.3em;
`

export const StyledHeader = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-start align-items-baseline row g-0'
}))`
  height: 80px;
  border-bottom: ${props => '1px solid ' + props.theme.navigation.sideMenu.footer.color}
`
export const StyledHeaderMobile = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-start align-items-center row g-0'
}))`
  height: 60px;
  border-bottom: ${props => '1px solid ' + props.theme.colors.accentColorMuted};
  margin-bottom: 5px;

`
export const StyledAvatar = styled.div.attrs((props) => ({
    className: 'col-3 d-flex justify-content-center align-items-center'
}))`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #a89362;
  margin: 10px;
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};
  background-color: ${props => props.theme.navigation.sideMenu.header.avatar.bgColor};
  font-size: 14px;
  font-weight: bold;


`
export const StyledInitials = styled.div.attrs((props) => ({
    className: 'col-3 ' +
        ''
}))`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #a89362;
  margin: 0 10px;
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};
  background-color: ${props => props.theme.navigation.sideMenu.header.avatar.bgColor};

  font-size: 24px;
  font-weight: bold;

`
export const StyledInitial = styled.div.attrs((props) => ({}))`
  text-align: center;
  vertical-align: middle;
  margin-top: 7px;
  margin-left: 1px;

`

export const StyledAvatarName = styled.span.attrs((props) => ({
    className: 'col'
}))`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
`

export const StyledAccountType = styled.div.attrs((props) => ({}))`
  font-size: 18px;
  vertical-align: middle;
`

export const VerifyStatusStyled = styled.div.attrs((props) => ({}))`
  height: 50px;
  background-color: var(--toastify-color-warning);
  color: #000;
  padding: 10px;
`

export const VerifyLinkStyled = styled.div.attrs((props) => ({}))`
  text-decoration: underline;
`

interface StyledFooterProperties {
    isMobile: boolean;
}

/*
  border-top: ${props => '1px solid ' + props.theme.navigation.sideMenu.footer.color};

 */
export const StyledFooter = styled.div.attrs((props: StyledFooterProperties) => ({}))<StyledFooterProperties>`
  width: 100%;
  font-size: 12px;
  vertical-align: middle;
  height: ${props => RELEASE_DETAILS_FOOTER_HEIGHT + 'px'};
  padding-top: 8px;
  position: absolute;

  bottom: ${props => (props.isMobile ? 'var(--gusl-mobile-footer-bar)' : 0)}px;
  text-align: center;
    // background-color: ${props => props.theme.navigation.sideMenu.footer.bgColor};
  background: ${props => props.theme.navigation.sideMenu.bgColor};;
  color: ${props => props.theme.navigation.sideMenu.footer.color};
  border-top: 1px solid #605f5f;
  z-index: 10;
`


export const StyledLeftHandMenu = styled.nav.attrs((props: LeftHandMenuProperties) => ({}))<LeftHandMenuProperties>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: ${props => props.theme.navigation.sideMenu.bgColor};;
  height: ${props => 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (CONTENT_START_POSITION) + 'px)'};
  text-align: left;
  padding: 5px;
  position: fixed;
  top: ${props => CONTENT_START_POSITION}px;
  left: 0;
  transform: ${props => props.$isSideNavOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.25s ease;
  width: ${props => LHM_WIDTH}px;
`


export const LeftHandMenuWrapperStyled = styled.div.attrs((props) => ({}))`
  overflow-y: auto;
  height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  170px);

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};

  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;

  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;

`

export const StyledPageFooter = styled.div.attrs((props) => ({}))`
  width: 100%;
  font-size: 12px;
  vertical-align: middle;
  height: 30px;
  margin: -5px;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  background-color: ${props => props.theme.navigation.sideMenu.footer.bgColor};
  color: ${props => props.theme.navigation.sideMenu.footer.color};
`
export const FooterTextStyled = styled.div.attrs((props) => ({}))`

  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 20px 10px 20px;

  p {
    color: #fff;
    line-height: 23px;
    text-align: center;
    margin: auto;
  }

  a {
    color: #fff;
    text-decoration: underline !important;
  }
`

export const LegalsWrapperStyled = styled.div.attrs((props) => ({}))`

  padding: 0 0 0 10px;

  .legals-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    padding: 10px 0 0 10px;
    text-align: left;
  }

  .legals {
  }

  .legal {
    font-size: 10px;
    line-height: 8px;
    font-weight: normal;
  }
`
export const AppSealWrapperStyled = styled.div.attrs((props) => ({}))`
  .app-seal {
    background-color: unset;
  }
`

interface OffCanvasProperties {
    ismobile: string
}

export const LoginOffCanvasStyled = styled(Offcanvas).attrs((props: OffCanvasProperties) => ({}))<OffCanvasProperties>`
    // height: ${props => 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (CONTENT_START_POSITION + (props.ismobile === 'true' ? (FOOTER_HEIGHT - 5) : 0)) + 'px)'} !important;
  height: var(--internal-height);
  //max-width: 100% !important;
  background-color: ${props => props.theme.panel.panelBgColor};
  // padding: 0px !important;
  position: absolute;
    // top: ${props => (LOGIN_IMAGE_HEIGHT)}px !important;
  top: 0 !important;
  left: 0;
  width: 100% !important;
  border-left: ${props => 'solid 1px  ' + props.theme.colors.dark[0]};

  .offcanvas-body {
    padding: 0px;
    overflow: hidden;
  }

  .offcanvas {
    width: 500px !important;
  }
`

interface SlidingPanelHeaderProperties {
    width: number
}

export const LoginSlidingPanelHeaderStyled = styled.div.attrs((props: SlidingPanelHeaderProperties) => ({}))<SlidingPanelHeaderProperties>`
  display: inline-block;
  color: ${props => props.theme.table.titleColor};
  font-size: ${props => props.theme.table.titleFontSize};
  //padding: 7px 10px 10px 5px;
  //padding-top: 3px;
  background-color: ${props => props.theme.table.titleBgColor};
  width: ${props => props.width}px;
  height: 40px;
  border: none;

`
