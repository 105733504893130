import React, {useRef, useState} from "react";
import {safeStream} from '../../utils/Utils';
import ChartSwitchMenu from "./chart-panel/ChartSwitchMenu";
import ChartTitle from "./chart-panel/ChartTitle";
import ChartTypeMenu from "./chart-panel/ChartTypeMenu";
import RangeQueryMenu from "./chart-panel/RangeQueryMenu";
import TimeUnitsMenu from "./chart-panel/TimeUnitsMenu";
import {ChartPanelProps, ChartTypeMenuDO, RangeQueryDO, TimeUnitsMenuDO} from "./types";
import {MASTER_VOLUME_CHARTS} from "./utils/masterVolumeCharts";

export default function ChartPanel(
    {
        setChartType,
        setRangeQuery,
        setTimeUnitQuery,
        setChartFor,
        chartType,
        rangeQuery,
        timeUnitQuery,
        serverData,
        endpoint,
        chartFor,
        singleChart,
        customCharts,
        mixedChart

    }: ChartPanelProps) {
    const [className] = useState('ChartPanel-' + new Date().getTime());
    const dbField = serverData?.currentQuery?.field;
    const chartTypeMenu: ChartTypeMenuDO[] | undefined = serverData?.chartTypeMenu;
    const startDateRef = useRef<any>(null);
    const endDateRef = useRef<any>(null);
    // @ts-ignore
    const [timeUnit, setTimeUnit] = useState<string>(serverData?.initialTimeUnit?.label);
    const [currentDataset, setCurrentDataset] = useState<string>(serverData.initialDataset);


    const chartNames: string[] = []

    serverData?.newDatasets?.forEach(dataset => {
        chartNames.push(dataset.label)
    })
    const showChartsSwitchMenu = (chartNames.length > 0 && !singleChart &&
        !mixedChart && MASTER_VOLUME_CHARTS.indexOf(chartType) === -1);

    // @ts-ignore

    const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
        <div
            className={'chart_date '}
            // @ts-ignore
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            {children}
            &#x25bc;
        </div>
    ));

    function dateSelectHandler() {

        /*todo validation on dates??*/
        const toIsoStr = (str: string | number | Date) => {
            try {
                return new Date(str).toISOString();
            } catch (e) {
                /*weeks todo ask daren*/
                return str;
            }
        };

        const gt = toIsoStr(startDateRef?.current?.value);
        let lt = toIsoStr(endDateRef?.current?.value);
        if (!endDateRef?.current) {
            /*we are adding one unit of time to end date
            * when there is only single date select,
            * todo ask, maybe there is easier approach on the server side*/
            // Date.now() gives the epoch date value (in milliseconds) of current date
            const startDate = new Date(startDateRef?.current?.value).getTime();
            // lt = new Date(startDate + serverData.timeUnit).toISOString();

            setRangeQuery({
                field: dbField,
                gt: gt,
                lt: lt
            })

        } else {
            setRangeQuery({
                field: dbField,
                gt: gt,
                lt: lt
            })
        }
    }

    function isItemActive(itemQuery: RangeQueryDO,
                          currentQuery: RangeQueryDO, isCustomSelectRange: boolean) {

        if (isCustomSelectRange) {
            return itemQuery?.from === currentQuery?.from && itemQuery?.to === currentQuery?.to;
        } else if (!isCustomSelectRange) {

            return (itemQuery?.lt === currentQuery?.lt && itemQuery?.gt === currentQuery?.gt);
        }

    }

    function timeUnitsHandler(timeUnit: TimeUnitsMenuDO) {
        setTimeUnit(timeUnit.label)
        setTimeUnitQuery(timeUnit);
        setRangeQuery({});

    }

    function chartChangeHandler(dataset: React.SetStateAction<string>) {
        setCurrentDataset(dataset);
        setChartFor(dataset);

    }

    return (
        <>
            <div className="row g-0 d-flex align-items-center mb-2">
                <ChartTitle
                    chartTitle={serverData.chartTitle}
                    singleChart={singleChart}
                    customCharts={customCharts}
                    mixedChart={mixedChart}/>

                <>

                    {/*<ChartSwitchMenu*/}
                    {/*    showChartsSwitchMenu={showChartsSwitchMenu}*/}
                    {/*    chartFor={chartFor}*/}
                    {/*    chartNames={chartNames}*/}
                    {/*    chartChangeHandler={chartChangeHandler}*/}
                    {/*    singleChart={singleChart}*/}
                    {/*    mixedChart={mixedChart}/>*/}

                    {/*{endpoint && <TimeUnitsMenu timeUnitsMenu={serverData.timeUnitsMenu} timeUnit={timeUnit}*/}
                    {/*                  timeUnitsHandler={timeUnitsHandler} />}*/}

                    {/*<ChartTypeMenu chartTypeMenu={chartTypeMenu}*/}
                    {/*               chartType={chartType}*/}
                    {/*               initialChartType={serverData.initialChartType}*/}
                    {/*               setChartType={setChartType}/>*/}
                </>

            </div>
            {endpoint &&  <RangeQueryMenu
                rangeQueryMenu={serverData.rangeQueryMenu}
                /*@ts-ignore*/
                currentQuery={serverData.currentQuery}
                timeUnitsMenu={serverData.timeUnitsMenu}
                setRangeQuery={setRangeQuery}
                customSelectRange={serverData.customSelectRange}
                isItemActive={isItemActive}
                CustomToggle={CustomToggle}
                dateSelectHandler={dateSelectHandler}/>}

        </>
    )
}
