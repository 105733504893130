import styled, {ThemeProps} from 'styled-components';

interface ColorStyledProps extends ThemeProps<any> {
    color: string;
}
export const ColorListStyled = styled.div.attrs((props) => ({
}))`
  display: inline-table;
`


export const ColorBlockStyled = styled.div.attrs((props:ColorStyledProps) => ({
}))<ColorStyledProps>`
  background-color: ${props => props.color} !important;
  width:160px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  margin-right:5px;
`
export const ColorValueStyled = styled.div.attrs((props:ColorStyledProps) => ({
}))<ColorStyledProps>`
  color: ${props => props.color} !important;
    // font-size: ${props => props.theme.table.columnFontSize};
`
interface ColorPickerPanelProps extends ThemeProps<any> {
    index: number;
}


export const ColorPickerPanelStyled = styled.div.attrs((props:ColorPickerPanelProps) => ({
}))<ColorPickerPanelProps>`
  z-index: 20000000;
  position: relative;
  top: -25px;
  left: 185px;
`
export const ColorPickerIconStyled = styled.div.attrs((props) => ({
}))`
  display: inline;
  margin-left:3px;
  margin-right:20px;
`

interface InputStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}

export const ColorInputStyled = styled.input.attrs((props: InputStyledProps) => ({
    className: "form-control form-control-sm bg-transparent rounded-0 border-0  text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<InputStyledProps>`
color: ${props => props.theme.table.columnColor} !important ;
font-size: ${props => props.theme.table.columnFontSize};
`
