import React from "react";
import { InputStyled, QuantityLabelStyled } from "../../styled_v2";

export interface RenderLimitDO {
  limitRef: React.MutableRefObject<any>;
  limitStep: number;
}

export default function RenderLimit({ limitRef, limitStep }: RenderLimitDO) {

  return (
    <div className={"d-flex align-items-center justify-content-between"}>
      <QuantityLabelStyled>Limit</QuantityLabelStyled>
      <InputStyled type={"tel"}
                   ref={limitRef}
                   step={limitStep}
                   id={"limitPrice"}
                   placeholder={"Limit Price"}
                   autoComplete={"off"}
                   required={true}
      />
    </div>
  );
}

