import {NAKED_CHART_OPTIONS} from "../nakedChartOptions";

export const MULTI_TYPE_DEFAULT_OPTION = {
    ...NAKED_CHART_OPTIONS,
    ...{
        maintainAspectRatio: false,
        borderWidth: 1,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 8,
                    boxHeight: 8,
                    // color: '#fafafa',
                    // usePointStyle:true,
                    // rotation:30
                    // pointStyle:'circle',
                    font: {size: 10}
                }
            },

        },
        barPercentage: 0.5,
        barThickness: 10,
        maxBarThickness: 18,
    }
}
