import {Dispatch, SetStateAction} from 'react';
import {
    FieldConfigDTO,
    FormColumnConfigDTO,
    FormMode,
    MoneyDTO,
    PermissionGroupDTO,
    UiValidator
} from '../components/types';
import {arrayIsEmpty, arrayNotEmpty, isArray, isBlank, isDefined, notBlank, notDefined,} from '../utils/TypeCheckers';
import {translateService} from './TranslateService';

interface Validator {
    validate: (uiValidation: UiValidator, type: string, value: any) => boolean;
    errorMessage: string;
}

const validationMap: { [id: string]: Validator } = {
    NOT_NULL: {
        validate: (uiValidation: UiValidator, fieldType: string, value: any) => {
            if (fieldType === 'text') {
                return notBlank(value);
            } else if (fieldType === 'option') {
                return notBlank(value);
            } else if (fieldType === 'money') {
                const money: MoneyDTO | undefined = value
                return notBlank(money?.value) && notBlank(money?.currency);
            } else if (fieldType === 'date') {
                return notBlank(value);
            } else if (fieldType === 'multi-select') {
                return isArray(value) && value.length >= 1
            } else if (fieldType === 'permissions') {
                if (!isArray(value)) {
                    return false;
                }
                const permissions: PermissionGroupDTO[] = value;
                let onePermissionSet = false;
                permissions.forEach(group => group.permissions?.forEach(permission => {
                    if (permission.value) {
                        onePermissionSet = true;
                    }
                }))
                return onePermissionSet
            }
            return notBlank(value);
        },
        errorMessage: 'validation.required',
    },
    GUSL_MIN_LENGTH: {
        validate: (uiValidation: UiValidator, fieldType: string, value: any) => {
            if (fieldType === 'text') {
                return (value || '').length >= (uiValidation.longValue || 5)
            } else if (fieldType === 'option') {
                return notBlank(value);
            } else if (fieldType === 'multi-select') {
                return isArray(value) && value.length >= (uiValidation.longValue || 5)
            }
            return true;
        },
        errorMessage: 'validation.min',
    },
    GUSL_MAX_LENGTH: {
        validate: (uiValidation: UiValidator, fieldType: string, value: any) => {
            if (fieldType === 'text') {
                return (value || '').length <= (uiValidation.longValue || 5)
            } else if (fieldType === 'multi_select') {
                return isArray(value) && value.length <= (uiValidation.longValue || 5)
            }
            return true;
        },
        // to={`/device/${device._id}`}
        errorMessage: 'validation.max',
    },
};

// export const performValidation = (type: UiValidatorType, value: any): boolean => {
//     const validator = validationMap[type];
//     if (notDefined(validator)) {
//         log.error('ValidationService', 'ERR001', 'Unknown validation type', type);
//         return true;
//     }
//     return validator.validate(type, value);
// }


export const performValidation = (
    formMode: FormMode,
    menuCode: string | undefined,
    fieldConfig: FieldConfigDTO,
    value: any,
    submittedState?: Dispatch<SetStateAction<boolean>> | undefined,
    errorMessageState?: Dispatch<SetStateAction<string | undefined>> | undefined,
): boolean => {
    if (submittedState) {
        submittedState(true);
    }
    if (errorMessageState) {
        errorMessageState(undefined)
    }
    const hideField = shouldHide(formMode, fieldConfig, value);
    if (!hideField) {
        const errorMessage = validate(menuCode,
            fieldConfig.name,
            fieldConfig.validators,
            fieldConfig.type,
            value)
        if (errorMessage && errorMessageState) {
            errorMessageState(errorMessage);
            return false;
        }
    }
    return true;
}

export const getSettings = (
    formMode: FormMode,
    fieldConfig: FieldConfigDTO,
    data?: any,
): [boolean, boolean, boolean] => {
    const hideField = shouldHide(formMode, fieldConfig, data);
    const disableField: boolean = shouldDisable(formMode, fieldConfig);
    const required = hasValidation(fieldConfig?.validators);
    return [hideField, disableField, required]
}

export const getPostUrl = (
    formMode: FormMode,
    formConfig: FormColumnConfigDTO
): string => {
    if (notDefined(formMode)) {
        return '';
    }

    switch (formMode) {
        case FormMode.DELETE:
            // @ts-ignore
            return formConfig.deleteUrl;
        case FormMode.EDIT:
        case FormMode.NEW:
        case FormMode.ACTION_DIALOG:
        case FormMode.VIEW_WITH_ACTION:
        case FormMode.VIEW:
        default:
            // @ts-ignore
            return formConfig.updateUrl;
    }
}

export const canShowActionButtons = (
    formMode: FormMode,
): boolean => {
    if (notDefined(formMode)) {
        return false;
    }

    switch (formMode) {
        case FormMode.EDIT:
        case FormMode.NEW:
        case FormMode.DELETE:
        case FormMode.ACTION_DIALOG:
        case FormMode.VIEW_WITH_ACTION:
            return true;
        case FormMode.VIEW:
        default:
            return false;
    }
}

export const shouldHide = (
    formMode: FormMode,
    fieldConfig: FieldConfigDTO,
    data?: any,
): boolean => {
    if (formMode === FormMode.NEW && fieldConfig.addIgnore) {
        return true;
    } else if (formMode === FormMode.VIEW && fieldConfig?.hideIfEmpty) {
        return isBlank(data);
    } else if (formMode === FormMode.EDIT && fieldConfig.editHide) {
        return true;
    } else if (formMode === FormMode.DELETE && fieldConfig.deleteHide) {
        return true;
    }
    return false;
};

export const shouldDisable = (
    formMode: FormMode,
    fieldConfig: FieldConfigDTO,
): boolean => {

    if (formMode === FormMode.EDIT && 'true' === fieldConfig?.editReadOnly) {
        return true;
    } else if (formMode === FormMode.EDIT && fieldConfig?.hideIfEmpty) {
        return true;
    } else if (formMode === FormMode.NEW && fieldConfig?.addIgnore) {
        return true;
    } else if (formMode === FormMode.NEW && fieldConfig?.addReadOnly) {
        return true;
    } else if (formMode === FormMode.VIEW) {
        return true;
    } else if (formMode === FormMode.DELETE) {
        return true;
    } else if (formMode === FormMode.VIEW_WITH_ACTION) {
        return true;
    }
    return false;
};

export const canShowOnForm = (fieldConfig: FieldConfigDTO, formMode: FormMode): boolean => {
    switch (formMode) {
        case FormMode.VIEW:
        case FormMode.VIEW_WITH_ACTION:
            return !(fieldConfig.viewHide || false);
        case FormMode.EDIT:
            return !(fieldConfig.editHide || false);
        case FormMode.DELETE:
            return !(fieldConfig.deleteHide || false);
        case FormMode.NEW:
        case FormMode.ACTION_DIALOG:
            return !(fieldConfig.addIgnore || false);
        default:
            return !(fieldConfig.viewHide || false);
    }
}


export const hasValidation = (
    validators: UiValidator[] | undefined,
): boolean => {
    return arrayNotEmpty(validators || []);
};

export const validate = (
    menuCode: string | undefined,
    fieldCode: string | undefined,
    validators: UiValidator[] | undefined,
    fieldType: string,
    value: any,
): string | undefined => {
    if (arrayIsEmpty(validators)) {
        return undefined;
    }
    let errorMessage = '';
    validators?.forEach((uiValidation) => {
        const validator = validationMap[uiValidation.type];
        if (isDefined(validator)) {
            if (!validator.validate(uiValidation, fieldType, value)) {
                errorMessage += translateService.getKey(validator.errorMessage, uiValidation) + '. ';
            }
        }
    });
    return isBlank(errorMessage) ? undefined : errorMessage;
};
