import styled, {ThemeProps} from 'styled-components';
import {getTextAlign} from '../../../utils/CssUtils';
import {ColumnFieldProperties} from '../maintain-table/styled';

interface OptionStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}


export const OptionStyled = styled.input.attrs((props: OptionStyledProps) => ({
    className: "form-control shadow mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<OptionStyledProps>`
color: ${props => props.theme.table.columnColor} !important ;
font-size: ${props => props.theme.table.columnFontSize};
`

export const OptionContainerStyled = styled.div.attrs((props: OptionStyledProps) => ({
    className: "form-control shadow mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<OptionStyledProps>`
    //height: 80px !important;
    padding-right: 0px !important;
`

export const OptionViewStyled = styled.div.attrs((props) => ({
    className: "form-control shadow mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light "

}))`
color: ${props => props.theme.table.columnColor} !important ;

 //height: 60px !important;
font-size: ${props => props.theme.table.columnFontSize};
`

export const OptionComboStyled = styled.div.attrs((props) => ({

}))`
  color: ${props => props.theme.table.columnColor} !important ;
  //  height: 30px !important;
  font-size: ${props => props.theme.table.columnFontSize};
  vertical-align: middle;
  line-height: 32px;  
  cursor:pointer;
`


export const SelectWrapperStyled = styled.div.attrs((props) => ({
}))`
   // padding-top: 5px;
   margin-left:-15px;

`
export const OptionTableFieldStyled = styled.div.attrs((props: ColumnFieldProperties) => ({
    // className: 'pt-2 pe-2  pb-2 d-flex justify-content-start'
}))<ColumnFieldProperties>`
    text-align: ${props => getTextAlign(props.textAlign)};
    display: inline-block;

`
export const OptionTableContainerStyled = styled.div.attrs((props) => ({}))`
  //margin-bottom:3px;
  border: 1px solid ${props => props.theme.colors.accentColorMuted};
  background-color: ${props => props.theme.table.panelBgColor};
  
`
export const OptionTableValueStyled = styled.div.attrs((props) => ({

}))`
    display: inline-block;
`
