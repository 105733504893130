import AutoNumeric, {Options} from 'autonumeric';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {getSettings, validate} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {isDefined, isObject} from '../../../utils/TypeCheckers';
import {assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {CurrencyDTO, FieldProperties, FormMode, MoneyDTO} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FloatingFormStyled, InputStyled} from '../text/styled';
import Money from './money';
import {BannerLabelStyled, BannerStyled} from './styled';
import {ExtraFieldProperties} from './types';


const getDecimalPlaces = (extraFieldProperty: number | undefined, currencyDecimalPlaces: number | undefined): number => {
    if (isDefined(extraFieldProperty)) {
        // @ts-ignore
        return extraFieldProperty;
    }
    if (isDefined(currencyDecimalPlaces)) {
        // @ts-ignore
        return currencyDecimalPlaces;
    }
    return 2
}

export const MoneyField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {


    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);


    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('MoneyField-' + new Date().getTime());
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)

    const valueRef = useRef(properties?.data);
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<MoneyDTO>(properties?.data || '');
    const [formValue, setFormValue] = useState<MoneyDTO>(_guslFormState?.getFieldValue(properties) || properties.data || '');

    const [tableValue, setTableValue] = useState<MoneyDTO>(properties?.data || '');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [banner, setBanner] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties, setExtraFieldProperties] = useState<ExtraFieldProperties>(extractExtraFieldProperties(properties));
    const currency: CurrencyDTO = extraFieldProperties?.amount?.currency || formValue?.currency;
    const [fieldValue, setFieldValue] = useState<AutoNumeric | undefined>();

    // const [refreshCounter, setRefreshCounter] = useState<number>(1);
    // setRefreshCounter(refreshCounter + 1)

    useEffect(() => {
            // console.log(`subscribe fld: ${properties.fieldConfig.name} bdc: ${properties.fieldConfig.blastDeltaCommand} kv: ${properties.fieldConfig.keyValue}  kf:${properties.fieldConfig.keyField} `)
            const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
            const valueSubscription = valueObservable.subscribe((newValue: any) => {
                // newValue could be just money or object with field
                if (newValue) {
                    // if (properties.fieldConfig.name === 'sellPrice' || properties.fieldConfig.name === 'buyPrice') {
                    // console.log(`new value ${properties.fieldConfig.name} `, newValue)
                    // }
                    if (isObject(newValue)) {

                        if (newValue[properties.fieldConfig.name]) {
                            setExtraFieldProperties({
                                ...extraFieldProperties,
                                amount: newValue[properties.fieldConfig.name]
                            })
                            setRefreshCounter(refreshCounter + 1)
                        } else if (isDefined(newValue['value'])) {
                            setExtraFieldProperties({
                                ...extraFieldProperties,
                                amount: newValue
                            })
                            setRefreshCounter(refreshCounter + 1)
                        }
                    } else {
                        setExtraFieldProperties({...extraFieldProperties, amount: newValue})
                        setRefreshCounter(refreshCounter + 1)
                    }
                }
            });
            return () => {
                unSubscribe(loaderSubscription);
                unSubscribe(blastDeltaSubscription);
                unSubscribe(valueSubscription);
            }
        }, [properties]
    )
    ;

    useEffect(() => {
        window.requestAnimationFrame(function () {
            if (properties.isTableView) {
                return
            }
            try {
                if (valueRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(valueRef?.current)) {

                        const options: Options = {
                            currencySymbol: '   ' + properties?.data?.currency?.code || currency?.code || '',
                            modifyValueOnWheel: false,
                            decimalPlaces: properties?.data?.currency?.decimalPlaces || currency?.decimalPlaces || 2,
                            selectOnFocus: false,
                            styleRules: {
                                positive: 'autoNumeric-positive',
                                negative: 'autoNumeric-negative'
                            },
                            currencySymbolPlacement: 's'
                        }
                        if (extraFieldProperties.noNegativeValue) {
                            options.minimumValue = '0'
                        }
                        const valueElement = new AutoNumeric(valueRef.current,
                            // properties?.data?.value === 0 ? '' : (properties?.data?.value || 0) / Math.pow(10, currency.decimalPlaces || 2),
                            formValue?.value === 0 ? '' : (formValue?.value || 0) / Math.pow(10, currency?.decimalPlaces || 2),
                            options);
                        valueElement.node().addEventListener("input", (event: Event) => {
                            // @ts-ignore
                            const value = parseFloat(((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', '')), 10)

                            try {
                                setFormValue({
                                    currency: currency,
                                    value: value * Math.pow(10, currency.decimalPlaces || 2)
                                });
                                properties.onChange(properties.fieldConfig.name, {
                                    currency: currency,
                                    value: value * Math.pow(10, currency.decimalPlaces || 2)
                                })
                            } catch (err) {
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setFieldValue(valueElement)
                    } else {
                        // ensure tested as was messing up entry
                        // @ts-ignore
                        // fieldValue.set(properties?.data?.value === 0 ? '' : (properties?.data?.value || 0) / Math.pow(10, currency.decimalPlaces || 2))
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [properties])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
        setTableValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
        setBanner(extraFieldProperties?.banner || false)
        setExtraFieldProperties(extractExtraFieldProperties(properties))
    }, [properties])

    const doValidation = (): boolean => {
        setSubmitted(true);
        // console.log('formValue', formValue)
        const message = validate(properties.menuItem?.code, properties.fieldConfig.name, properties.fieldConfig.validators, 'money', formValue);
        if (message) {
            setErrorMessage(message);
            return false;
        }
        return true;
    }


    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });


    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss)
        if (!style['textAlign']) {
            if (properties.isTableView) {
                style['textAlign'] = 'right'
                style['wordWrap'] = 'break-word'
                // MK 16/08/2023 in HeaderPanel.tsx money value was overflown to right and partly not visible
                style["paddingRight"] = "10px"
            } else {
                style['textAlign'] = 'left'
            }
        }


        // extraFieldProperties.decimalPlaces = getDecimalPlaces(extraFieldProperties?.decimalPlaces, properties?.data?.currency?.decimalPlaces)
        extraFieldProperties.decimalPlaces = getDecimalPlaces(extraFieldProperties?.decimalPlaces, tableValue?.currency?.decimalPlaces)

        extraFieldProperties.displayPlaces = tableValue?.currency?.displayPlaces

        if (!extraFieldProperties.prefix) {
            extraFieldProperties.prefix = ''
        }

        if (!extraFieldProperties.suffix) {
            extraFieldProperties.suffix = ''
        }

        return (
            <div style={style} key={properties.fieldConfig.name + '_' + refreshCounter}>
                <Money {...extraFieldProperties}/>
            </div>
        )
    }

    const renderAsBanner = (): React.ReactElement => {
        return <BannerStyled colorise={extraFieldProperties?.colorise || false} amount={formValue}>
            <Money {...extraFieldProperties}/>
            <BannerLabelStyled> <FieldLabel properties={properties}/>
            </BannerLabelStyled>
        </BannerStyled>

    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        const onDataInputChange = (value: string) => {

            try {
                const number = parseFloat(value);

                if ('' + number === value) {
                    setFormValue({value: parseFloat(value), currency});
                    properties.onChange(properties.fieldConfig.name, parseFloat(value));
                }
            } catch (err) {
                // log.warn(className, 'WRN001', 'not a number', value);
            }
        }

        return (
            <>
                {!hideField && banner && renderAsBanner()}
                {!hideField && !banner && <>
                    <FloatingFormStyled>
                        <InputStyled type={'text'}
                                     id={properties?.fieldConfig?.name || 'def'}
                                     ref={valueRef}
                            // defaultValue={formValue.value}
                            // onChange={(e) => onDataInputChange(e.target.value)}
                                     required={required}
                                     readOnly={disableField}
                                     disabled={disableField}
                                     submitted={submitted}
                                     noValue={!formValue}
                            // step={0.01}
                        />
                        <FieldLabel properties={properties}/>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}

                    </FloatingFormStyled>
                </>}

            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}

