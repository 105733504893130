import styled from 'styled-components';

export const OttoTableContainerStyled = styled.div.attrs((props) => ({
    className: 'col-12',
    id: 'otto-table'
}))`
    padding-top: 5px;
`

export const OttoTableLeftContainerStyled = styled.div.attrs((props) => ({
    className: '',
}))`
`
//     id: 'otto-table-left'


interface OttoTableRightContainerProperties {
    height?: string,
}
export const OttoTableRightContainerStyled = styled.div.attrs((propsLOttoTableRightContainerProperties) => ({
    className: 'p-2',
    id: 'otto-table-right'
}))<OttoTableRightContainerProperties>`
    ${props => props.height && 'height: ' + props.height + ';'}

`


export const CollectionHeaderStyled = styled.div.attrs((props) => ({
    className: 'fs-4 p-2'
}))`
    background-color: #0c4233;
    text-decoration-color:  rgba(255, 240, 246, 0.5);
    text-decoration-line: underline;
    text-underline-offset: 4px;
`
export const CardInfoStyled = styled.div.attrs((props) => ({}))`
    font-size: 10px;
    padding-top: 2px;
    line-height: 16px;

    span {
        font-weight: bold;
    }
`
export const OttoTableGrid = styled.div.attrs((props) => ({
    className: 'g-0',
}))`
    width: 100%;
`
export const OttoTableCard = styled.div.attrs((props) => ({
    className: 'p-2',
}))`
    width: 100%;
`
