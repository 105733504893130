import styled, {ThemeProps} from 'styled-components'

interface ModalThemeProperties extends ThemeProps<any> {
    $extraClassName: string | undefined
    coverUrl?: string | undefined
    noModal?: boolean | undefined
}

export const ModalWrapperStyled = styled.div.attrs((props: ModalThemeProperties) => ({
    className: props.$extraClassName ? props.$extraClassName : '',
}))<ModalThemeProperties>`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  // width: calc(100% - 40px);
  width: calc(100% - 0px);
  height: 100%;
  // background-color: rgba(255, 255, 255, 0.5);
  ${props => !props.coverUrl && 'background-color: ' + props.theme.modal.outerBackground + ';'} // background-color: #f1ebeb;

  display: flex;
  justify-content: center;
  ${props => props.noModal && 'padding: 10% 2px;'}
  ${props => !props.noModal && 'padding: 10% 20px;'}
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;


  .login {
    background-color: ${props => props?.theme?.login?.innerBackground || 'red'}; 
    ${props => props.noModal && 'padding: 0;'}
    ${props => {
        if (props.noModal) {
          return 'padding: 0px; box-shadow:none; border:none;';
        }
    }}
  }

  .action {
      background-color: ${props => props?.theme?.login?.innerBackground || 'red'};
  }

  .lookup {
      background-color: ${props => props?.theme?.login?.innerBackground || 'red'};
  }
`

export const ModalContainerStyled = styled.div.attrs((props: ModalThemeProperties) => ({
    className: props.$extraClassName ? props.$extraClassName : '',
}))<ModalThemeProperties>`

  width: 100%;
  height: fit-content;
  max-width: 400px;
  background-color: ${props => props?.theme?.modal?.innerBackground || 'red'};
  position: relative;
  border: 5px;
  box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 20px;

  .login {
      background-color: ${props => props?.theme?.login?.innerBackground || 'red'};
    ${props => props.noModal && 'padding: 0;'}
  }

  .action {
      background-color: ${props => props?.theme?.login?.innerBackground || 'red'};
  }

  .lookup {
      background-color: ${props => props?.theme?.login?.innerBackground || 'red'};
  }
`
