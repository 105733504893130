import {Configuration, UserAgentApplication} from 'msal';
import {Observable} from 'rxjs'
import {
    ActionConfigDTO,
    FavQueryActionConfigDTO,
    LayoutType,
    MediaType,
    WidgetPanelProperties
} from '../../components/types';

export interface LoginUiProperties {
    withLabel: boolean;
    asUppercase: boolean;
    withIcon: boolean;
    noModal: boolean;
    loginLogoUrl: string;
    loginTitle?: string;
    loginText?: string;
    loginSmallLogo?: string;
    loginFooterText?: string;
    hideNotRegistered?: boolean;
    notRegisteredEmail?: string;
}

export interface PolicyLink {
    key: string;
    label: string;
    href: string;
}

export interface CookieConsentProperties {
    text: string;

    location: "top" | "bottom" | "none";
    buttonText: string;
    cookieName: string;
    expires: number;
    policy: PolicyLink

}

export interface MsalProperties {
    clientId: string;
    authority: string;
    redirectUri: string;
    cacheLocation: "localStorage" | "sessionStorage";
    storeAuthStateInCookie: boolean;
    scopes: string[];
    graphMeEndpoint?: string;
}

export interface MsalGraphConfig {
    graphMeEndpoint: string
}

export interface UiPropertiesRequestDTO {
    hostname: string;
    port: string;
    scheme: string,
    cohortId?: string | undefined,

    timezone?: string | undefined
}

export interface PaymentOptionDTO {
    name: string;
    logoUrl: string;
}

export interface PaymentOptionsDTO {
    label: string;
    options: PaymentOptionDTO[];
    logoWidth: string;
    logoHeight: string;
}

export interface AgePropertiesDTO {
    label?: string;
    age?: string;
}

export interface AppSealDTO {
    label: string;
    javascript: string;
    id: string;
    sealId: string;
    imageSize: string;
    imageType: string;
    // ui only
    noText?: boolean;
    noImage?: boolean;
}

export interface LegalDTO {
    label: string;
    code: string;
    logoUrl?: string;
}

export interface LegalsDTO {
    label: string;
    horizontalLayout: boolean;
    legals: LegalDTO[];

    onLegalClick?: (legal: LegalDTO) => void
    onClose?: () => void

}

export interface StatusMacrosDO {
    status: string;
    templateMacroCode: string;

}

export interface AllowedBespokePages {
    pages: string[];
}

export interface IEnvironment {
    name: string;
    production: boolean;
    apiKey: string,
    authorisation: string,
    VERSION: string;
    apiBase: string;
    useMsalRedirect?: boolean;
    theme?: string;

    /**
     * Last theme update time on server as ISO date string
     */
    themeLastUpdated?: string;
    loaded?: boolean
    wsEndpoint?: string;
    googleSSO?: string;
    titlePrefix?: string;
    homePage?: string;
    splashLogoUrl?: string;
    coverUrl?: string;
    logoUrl?: string;
    mobileLogoUrl?: string;

    noMobileLogo?: boolean;

    withNameInMobileToolbar?: boolean;
    loginUiProperties?: LoginUiProperties;

    cookieConsent?: CookieConsentProperties;
    locationOriginUrl?: string; // gets populated with window.location.origin
    authType?: AuthType;
    footer?: string;
    changePassword?: ActionConfigDTO;
    layout?: LayoutType;
    msalConfig?: Configuration;
    msalScopes?: string[];
    msalGraphConfig?: MsalGraphConfig;
    showActiveUsers?: boolean;
    showActiveFEUsers?: boolean;
    showActiveUserAvatar?: boolean;
    assetsUrl?: string;
    paymentOptions?: PaymentOptionsDTO;
    ageProperties?: AgePropertiesDTO;
    appSeal?: AppSealDTO;
    legals?: LegalsDTO;
    headerURL?: string;
    forgottenDetails?: ActionConfigDTO;
    cohortId?: string;
    favQueriesUrls?: FavQueryActionConfigDTO;
    storagePrefix?: string;

    hideHamburger?: boolean;

    showStatus?: boolean;
    statusLinks?: StatusMacrosDO[];
    squashedTableDisplay?: MediaType;

    showPowerOfAttorney?: MediaType;
    showCohortSelector?: MediaType;

    /**
     * bespoke react landing page
     */
    bespokeLandingPage?: string
    allowedBespokePages?: AllowedBespokePages
}

export enum MenuOrientation {
    LEFT = "LEFT",
    RIGHT = "RIGHT",

    TOP = "TOP"
}

export enum AuthType {
    GOOGLE_SSO = "GOOGLE_SSO",
    USERNAME_PASSWORD = "USERNAME_PASSWORD",
    TIME_BASED_ONE_TIME_PASSWORD = "TIME_BASED_ONE_TIME_PASSWORD",
    MSAL = "MSAL"
}

export interface EnvironmentContextProps {
    watchEnvironment: () => Observable<IEnvironment>;
    getMsalInstance: () => UserAgentApplication | undefined;
    isMsalRedirect: () => boolean;

    watchNoService: () => Observable<boolean>;

    getStoragePrefix: () => string | undefined;

    isMobileDevice: (widgetPanelProperties?: WidgetPanelProperties) => boolean;

    isNativeDevice: () => boolean;
    isNativeIOS: () => boolean;

    watchMediaType: () => Observable<MediaType>;
    getCurrentMediaType: (widgetPanelProperties?: WidgetPanelProperties) => MediaType;
    watchResize: () => Observable<number>;

    getDeviceClassname: () => string;
    watchOrientation: () => Observable<string>;
    getCurrentOrientation: () => string;
}
