import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {getSettings} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {assignReferences, extractExtraFieldProperties, noop, RunOnceEffect} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FloatingFormStyled} from '../text/styled';
import {
    DashStyled,
    LeftTeamStyled,
    LogoStyled,
    RightTeamStyled,
    ScoreStyled,
    ScoreValueWrapperStyled,
    ScoreWrapperStyled,
    TeamNameStyled
} from './styled';
import {ScoreDTO} from './types';

export const ScoreField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<ScoreDTO | undefined>(properties?.data);
    const [formValue, setFormValue] = useState<ScoreDTO | undefined>(_guslFormState?.getFieldValue(properties));
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);

    const extraFieldProperties = extractExtraFieldProperties(properties);
    extraFieldProperties.showLogo = false;

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data);
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return true;
    }


    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss, '')

        if (!formValue) {
            return <></>
        }
        return (
            <ScoreWrapperStyled style={style}>
                <LeftTeamStyled>
                    <TeamNameStyled>{formValue?.left?.name || ''}</TeamNameStyled>
                    {extraFieldProperties.showLogo && formValue?.left?.logo &&
                        <LogoStyled src={formValue?.right.logo}>{formValue?.left.logo}</LogoStyled>}
                    <ScoreValueWrapperStyled><ScoreStyled
                        textAlign={'right'}>{formValue?.left?.score || ''}</ScoreStyled></ScoreValueWrapperStyled>
                </LeftTeamStyled>
                <DashStyled>-</DashStyled>
                <RightTeamStyled>
                    <ScoreValueWrapperStyled><ScoreStyled
                        textAlign={'right'}>{formValue?.right?.score || ''}</ScoreStyled></ScoreValueWrapperStyled>
                    {extraFieldProperties.showLogo && formValue?.right?.logo &&
                        <LogoStyled src={formValue?.right.logo}>{formValue?.right.logo}</LogoStyled>}
                    <TeamNameStyled>{formValue?.right?.name || ''}</TeamNameStyled>
                </RightTeamStyled>
            </ScoreWrapperStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        {renderTableView()}
                        <FieldLabel properties={properties}/>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}
                    </FloatingFormStyled>
                </>}
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}

