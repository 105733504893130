import React, {useEffect, useRef, useState} from 'react';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {log} from '../../../services/LogService';
import {StatusBarBackgroundStyled} from './styles';

/**
 * This is used so that the app does not clash with IOS status bar
 */
export const StatusBarBackground = (): React.ReactElement => {
    const environmentContext = React.useContext(EnvironmentContext);
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS());
    const headerElement = useRef(null);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (headerElement) {
                    // @ts-ignore
                    const rect = headerElement?.current?.getBoundingClientRect();
                    if (rect) {
                        log.info('Header', 'MSG001', 'height: ' + rect.height)
                        environmentService.setHeaderHeight(rect.height)
                    }
                }
            }, 100)
        });
    }, [headerElement]);


    return (
        <StatusBarBackgroundStyled isIOS={isIOS} ref={headerElement}/>
    )
}
