import {ThreeDots} from 'react-loader-spinner';
import styled, {ThemeProps} from 'styled-components';

interface ThreeDotsProperties extends ThemeProps<any> {
    flip?: boolean
}

export const ThreeDotsStyled = styled(ThreeDots).attrs((props: ThreeDotsProperties) => ({
    color: (props.flip ? props.theme.panel.titleColor : props.theme.spinner.color)
}))<ThreeDotsProperties>`
`


interface LoadingSpinner {
    loaded?: boolean | undefined
}


export const LoadingSpinnerStyled = styled.div.attrs((props: LoadingSpinner) => ({
    id: 'loading_spinner',
    className: 'd-flex justify-content-center'
}))<LoadingSpinner>
    `
      color: blue;

    ${props => {
        return props.loaded && 'display: none !important;'}}
`

