import * as React from "react";
import {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {CloseTabIconStyled, OpenTabStyled, OpenTabWrapper, SplitHeaderStyled} from "../styled/split-list-view/styled";
import SplitViewBtn from "../table-controls/split-view-btn/SplitViewBtn";

export interface SplitHeaderDO {
    code: string,
    currentLabel: string | undefined,
    labels: string[],
    onClick: Function,
}

export default function SplitHeader({code, currentLabel, labels, onClick}: SplitHeaderDO) {

    const [className] = useState<string>('SplitHeader-' + new Date().getTime());
    const [openTabs, setOpenTabs] = useState<string[]>([]);


    function openTabsHandler(tab: string) {
        const currentTabs = openTabs;
        if (currentTabs.indexOf(tab) === -1) {
            currentTabs.push(tab);
        }
        setOpenTabs(currentTabs);
    }

    function removeOpenTabHandler(tab: string) {
        let currentTabs = openTabs;
        currentTabs = currentTabs.filter((_tab => _tab !== tab));
        setOpenTabs(currentTabs);

    }

    return (
        <>
            <SplitHeaderStyled>
                <div className={'d-flex align-items-center justify-content-start'}>
                    <Dropdown>
                        <Dropdown.Toggle as={'span'} role={'button'} id={"dropdown-" + code} className={'me-4'}>
                            <span>{currentLabel}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {labels.map((label, idx) =>
                                <Dropdown.Item key={idx}> <small className={'mx-2'}
                                                                 onClick={() => {
                                                                     onClick(label);
                                                                     openTabsHandler(label);
                                                                 }}
                                                                 role={'button'}
                                                                 key={idx}>{label}
                                </small></Dropdown.Item>)}

                        </Dropdown.Menu>
                    </Dropdown>

                    {openTabs.map((tab, idx) =>
                        <OpenTabWrapper key={idx} isLast={idx + 1 === openTabs.length} isActive={tab === currentLabel}>
                            <OpenTabStyled
                                onClick={() => {
                                    onClick(tab);
                                }}
                            >{tab}</OpenTabStyled>
                            <CloseTabIconStyled onClick={() => {
                                removeOpenTabHandler(tab)
                            }}><i className={'fa-solid fa-close'}/></CloseTabIconStyled></OpenTabWrapper>
                    )}
                </div>

                <div><SplitViewBtn/></div>

            </SplitHeaderStyled>

        </>


    );
}
