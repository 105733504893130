import React from "react";
import IconWrapper from "../ui/IconWrapper";

export default function ChartLoaderPlaceholder() {
    return (
        <div className={'text-center py-5'}>

            <IconWrapper icon={'fa-solid fa-circle-notch fa-spin primary_text'}/>
            <IconWrapper icon={'fa-solid fa-circle-notch fa-spin accent_text'}/>
            <IconWrapper icon={'fa-solid fa-circle-notch fa-spin secondary_text'}/>
            <p className={'xs_text'}>loading...</p>
        </div>
    );
}
