import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {OffcanvasPlacement} from 'react-bootstrap/Offcanvas';

export interface OpenOverlayAction {
    title: string

    route: string;
    placement: OffcanvasPlacement,
    rowData?: any | undefined;
    properties?: any | undefined;

}

export interface SetTitleAction {
    title: string
}

export interface FooterOverlayState {
    showOverlay: boolean,
    route: string | undefined;

    title: string | undefined,
    rowData?: any | undefined;
    properties?: any | undefined;

}

const initialState: FooterOverlayState = {
    showOverlay: false,
    title: undefined,
    route: undefined,
}

const performClose = (state: FooterOverlayState) => {
    state.showOverlay = false;
    state.route = undefined;
    state.title = undefined;
    state.rowData = undefined;
    state.properties = undefined;
}

const performOpen = (state: FooterOverlayState, action: PayloadAction<OpenOverlayAction>) => {
    state.route = action.payload.route;
    state.title = action.payload.title;
    state.showOverlay = true;
    state.rowData = action.payload.rowData;
    state.properties = action.payload.properties;
}

export const footerOverlaySlice = createSlice({
    name: 'footerOverlaySlice',
    initialState,
    reducers: {

        openDialog(state, action: PayloadAction<OpenOverlayAction>) {
            if (state.route === action.payload.route) {
                performClose(state)
            } else {
                performOpen(state, action)
            }
        },
        closeDialog(state, action: PayloadAction<boolean>) {
            performClose(state)
        },
        setOverlayTitle(state, action: PayloadAction<SetTitleAction>) {
            state.title = action.payload.title;
        }

    }
})

export const {
    openDialog,
    closeDialog,
    setOverlayTitle

} = footerOverlaySlice.actions

export default footerOverlaySlice.reducer
