import React, {useEffect, useId, useRef, useState} from 'react';
import {Subscription} from 'rxjs';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {SessionContext} from '../../providers/session/SessionContext';
import {cleanWidthFromStyle, getStyle} from '../../utils/CssUtils';
import {
    areFieldsMediaTypeSensitive,
    compare,
    matchMediaTypeWithField,
    RunOnceEffect,
    unSubscribe
} from '../../utils/Utils';
import {AccordionListPanelStyled, ListContainerStyled, ListScrollContainerStyled} from '../accordion-list/styled';
import {OrderByActions} from '../common/gusl-table/table-controls/OrderByActions';
import SearchBox from '../common/gusl-table/table-controls/search-box/SearchBox';
import {SearchIconStyled} from '../common/gusl-table/table-controls/search-box/styled';
import {maintainTableService} from '../common/maintain-table/MaintainTableService';
import {fieldService} from '../FieldService';
import {OrderByActionsStyled, SearchBoxWrapperStyled, TableControlsStyled} from '../flex-list/styled';
import {
    FieldConfigDTO,
    IMenuDTO,
    MatchQueryDTO,
    MediaType,
    OrderByActionDO,
    OrderByDTO,
    QueryParamsDTO,
    WidgetPanelProperties
} from '../types';
import {AlphaListColumnStyled, AlphaListTableBodyStyled, AlphaListTableRowStyled, AlphaListTableStyled} from './styled';

type AlphaListViewProperties = {
    menuItem: IMenuDTO
    queryParams?: QueryParamsDTO | undefined;
    widgetPanelProperties?: WidgetPanelProperties
}

export const AlphaListView = (props: AlphaListViewProperties): React.ReactElement => {

    const id = useId()

    const environmentContext = React.useContext(EnvironmentContext);
    const sessionContext = React.useContext(SessionContext);

    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(props.widgetPanelProperties));
    const [fields] = useState<FieldConfigDTO[]>(maintainTableService.extractFields(props.menuItem, true));
    const mediaTypeSensitive = areFieldsMediaTypeSensitive(fields)
    const [dataForTable, setDataForTable] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectUrl] = useState<string | undefined>(maintainTableService.extractSelectAllEndpoint(props.menuItem));
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const isMobile = environmentContext.isMobileDevice();

    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchableFields, setSearchableFields] = useState<FieldConfigDTO[]>(maintainTableService.extractAllSearchableFields(props.menuItem))
    const [lastQueryParams, setLastQueryParams] = useState<QueryParamsDTO>(props.queryParams || {skip: 0, limit: 20});
    const [orderByActions, setOrderByActions] = useState<OrderByActionDO[]>(maintainTableService.extractOrderByActionsFromMenuItem(props.menuItem));
    const [hasSearch] = useState<boolean>(maintainTableService.extractAllSearchableFields(props.menuItem).length > 0);
    const [searchPrompt] = useState<string>('Many');

    const listElement = useRef(null);
    const [listStartPos, setListStartPos] = useState<number>(150);
    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });


    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (listElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = listElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setListStartPos(rect.top)
                    } else {
                        setListStartPos(150)
                    }
                }
            }, 100)
        });
    }, [loaded]);
    const getData = (abortController: AbortController, query?: QueryParamsDTO) => {
        if (selectUrl) {
            setLoaded(false)
            console.log('getData', query || lastQueryParams)

            sessionContext.post<any, any>(selectUrl, query || lastQueryParams, abortController)
                .then(response => {
                    setLoaded(true)
                    setDataForTable(response.data);
                    setRefreshCounter(refreshCounter + 1)
                    setLastQueryParams(response.data.queryParams)

                }).catch(error => {
                console.error("Error", error);
                setLoaded(true)
            })
        }
    }
    useEffect(() => {
        getData(new AbortController())
    }, [props]);

    const reLoad = (query?: QueryParamsDTO) => {
        console.log('reload', query)
        console.log('reload lastQueryParams', lastQueryParams)
        getData(new AbortController(), query || lastQueryParams)
    }


    const renderFields = (row: any, rowId: number): React.ReactElement => {
        return (
            <>
                {fields.filter(fld => fld.displayInTable)
                    .filter(fld => matchMediaTypeWithField(fld, mediaType, mediaTypeSensitive))
                    .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                    .map((fld, idx) => {
                        return <AlphaListColumnStyled key={'det_' + rowId + '_' + idx}
                                                      beingResized={false}
                                                      style={fld.style ? cleanWidthFromStyle(fld.style) : cleanWidthFromStyle(getStyle(fld.tableCss))}
                                                      id={'det_' + rowId + '_' + idx}>
                            {fieldService.getTableField(fld, undefined, row, reLoad)?.render()}
                        </AlphaListColumnStyled>
                    })
                }
            </>
        )
    }

    const renderTableControls = (): React.ReactElement => {
        const onQueryParamsChange = (orderBys: OrderByDTO[]) => {
            const query: QueryParamsDTO = {...lastQueryParams, orderBys: orderBys, skip: 0}
            setLastQueryParams(query)
            reLoad(query)
        }

        const onSearchChange = (searchValue: string | undefined) => {
            console.log('-- onSearchChange', searchValue)
            const shoulds: MatchQueryDTO[] = [];
            if (searchValue) {
                searchableFields.forEach((field) => shoulds.push({
                    field: field.name,
                    value: searchValue || '',
                    fuzzy: true
                }))
            }
            const query: QueryParamsDTO = {...lastQueryParams, should: shoulds, skip: 0, fuzzy: true}
            setLastQueryParams(query)
            reLoad(query)
        }

        return (
            <TableControlsStyled>
                <OrderByActionsStyled>
                    <OrderByActions
                        orderByActions={orderByActions}
                        isMobile={isMobile}
                        mediaType={mediaType}
                        lastQueryParams={lastQueryParams}
                        onQueryParamsChange={(orderBys: OrderByDTO[]) => onQueryParamsChange(orderBys)}
                    />
                    {hasSearch && <SearchIconStyled active={showSearch} onClick={() => {
                        setShowSearch(!showSearch)
                    }}/>}
                </OrderByActionsStyled>
                {showSearch && <SearchBoxWrapperStyled>
                    <SearchBox code={id}
                               searchableFields={searchableFields}
                               searchString={''}
                               searchPrompt={searchPrompt}
                               showFieldSelect={!isMobile}
                               onSearchValueChange={(searchValue: string | undefined) => onSearchChange(searchValue)}
                    />
                </SearchBoxWrapperStyled>}
            </TableControlsStyled>
        )
    }
    const renderList = (): React.ReactElement => {

        return (
            <AlphaListTableStyled key={"tbl_" + refreshCounter}>
                <AlphaListTableBodyStyled>
                    {dataForTable?.map((row: any, rowIndex: number) => {
                        return <AlphaListTableRowStyled
                            key={'row_' + rowIndex}>{renderFields(row, rowIndex)}</AlphaListTableRowStyled>
                    })}
                </AlphaListTableBodyStyled>
            </AlphaListTableStyled>
        )
    }

    return (
        <>
            {renderTableControls()}
            <AccordionListPanelStyled isMobile={isMobile} id={'fl_lp'} ref={listElement} listStartPos={listStartPos}
                                      hasOrderByActions={orderByActions?.length > 0}
                                      footerHeight={footerHeight}
                                      searchOpen={showSearch}>
                <ListContainerStyled isMobile={isMobile} id={'fl_l'} listStartPos={listStartPos}
                                     footerHeight={footerHeight}>
                    <ListScrollContainerStyled isMobile={isMobile} id={'afl_scroll'} listStartPos={listStartPos}
                                               footerHeight={footerHeight}>
                        {renderList()}
                    </ListScrollContainerStyled>
                </ListContainerStyled>
            </AccordionListPanelStyled>

        </>
    )
}
/*
        <ListPanelStyled isMobile={isMobile}>
            {renderList()}
        </ListPanelStyled>

 */
