import {Capacitor} from '@capacitor/core';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {RELEASE_DETAILS} from '../../../../features/release-details';
import {LeftHandMenuWrapperStyled} from "../../../../layouts/alvar-fe-layout/styled";
import {AppSeal} from '../../../../layouts/mercury-layout/app-seal/AppSeal';
import {Legals} from '../../../../layouts/mercury-layout/legals/Legals';
import {StyledFooter} from '../../../../layouts/mobile-layout/styled';
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../../providers/environment/EnvironmentService';
import {AppSealDTO, LegalsDTO} from '../../../../providers/environment/types';
import {SystemContext} from '../../../../providers/system/SystemContext';
import {log} from '../../../../services/LogService';
import {
    areMenusMediaTypeSensitive,
    haveChildrenWithMediaTypeWithRequiredAndSensitivity,
    matchMediaTypeWithRequiredAndSensitivity,
    unSubscribe
} from '../../../../utils/Utils';
import {IMenuDTO, MediaType} from '../../../types';
import sortByDisplayOrder from '../navbar/utils/sortByDisplayOrder';
import MenuGroup from './MenuGroup';

const LeftHandMenu = (): React.ReactElement => {

    const [className] = useState('LeftHandMenu-' + new Date().getTime());
    const systemContext = useContext(SystemContext);
    const environmentContext = React.useContext(EnvironmentContext);
    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType());
    const [mediaTypeSensitive, setMediaTypeSensitive] = useState<boolean>(false);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS());

    const contentElement = useRef(null);
    const [startPos, setStartPos] = useState<number>(150);

    const [menuGroups] = useState<IMenuDTO[]>(() => {
        const items = sortByDisplayOrder([...(systemContext.getSystemConfig()?.menuGroups || [])]);
        log.debug(className, 'MSG001', 'menuGroups', items)
        setMediaTypeSensitive(areMenusMediaTypeSensitive(items))
        return items;
    });

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setStartPos(rect.top)
                    } else {
                        setStartPos(150)
                    }
                }
            }, 100)
        });
    }, [contentElement]);

    useEffect(() => {
        const subscriptionMediaType = environmentContext.watchMediaType().subscribe((mediaType: MediaType) => {
            setMediaType(mediaType)
            setRefreshCounter(refreshCounter + 1);
        });
        return () => {
            unSubscribe(subscriptionMediaType)
        }
    }, [])


    const renderAppSeal = (): React.ReactElement => {
        const appSeal: AppSealDTO | undefined = environmentService.getEnvironment()?.appSeal;
        if (appSeal) {
            return <AppSeal {...appSeal}/>

        }
        return <></>
    }

    const renderLegals = (): React.ReactElement => {
        const legals: LegalsDTO | undefined = environmentService.getEnvironment()?.legals;
        if (legals) {
            legals.horizontalLayout = false
            legals.label = ''
            return <Legals {...legals}/>
        }
        return <></>
    }
    const renderMenuFooter = (): React.ReactElement => {
        const platform = Capacitor?.getPlatform() || 'uk'
        const native: string = Capacitor?.isNativePlatform() ? 'true' : 'false'

        return <>
            <StyledFooter isMobile={false}>
                <div>Release: {RELEASE_DETAILS.releaseNumber} ({platform}/{native})</div>
            </StyledFooter>
        </>
    }

    return <LeftHandMenuWrapperStyled
        id={'lhm_ws'}
        key={'lhm_ws_' + refreshCounter}
        isIOS={isIOS}
        ref={contentElement}
        startPos={startPos}
    >
        {menuGroups
            .filter(menuGroup => menuGroup.showInSideBar)
            .filter(menuGroup => matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, mediaTypeSensitive))
            .filter(menuGroup => haveChildrenWithMediaTypeWithRequiredAndSensitivity(menuGroup, mediaType, mediaTypeSensitive))

            .map((menuGroup, idx: number) => {
                    return <MenuGroup
                        menuGroup={menuGroup}
                        idx={idx}
                        key={'mmg_' + idx}
                        mediaTypeSensitive={mediaTypeSensitive}
                    />
                }
            )}
        {renderLegals()}
        {renderAppSeal()}
        {renderMenuFooter()}

    </LeftHandMenuWrapperStyled>
}
export default LeftHandMenu;
