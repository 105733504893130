import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {getSettings} from '../../../services/ValidationService';
import {FieldProperties, FormMode} from '../../types';
import FieldLabel from '../field-label/FieldLabel';
import {GuslReport} from '../gusl-report/GuslReport';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FloatingFormStyled} from '../text/styled';
import {ReportWrapperStyled} from './styles';

export const ReportField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'TextFormField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties)  ||  properties.data|| '');

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const renderTableView = (): React.ReactElement => {
        return <GuslReport
            code={properties?.fieldConfig?.name || 'rep'}
            data={properties.data}
            rowData={properties.getCurrentRowData()}
            reLoad={properties.reLoad}
        />
    }

    const renderFormView = (): React.ReactElement => {

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        // @ts-ignore
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        <ReportWrapperStyled>
                            <GuslReport
                                code={properties?.fieldConfig?.name || 'rep'}
                                data={properties.data}
                                rowData={properties.getCurrentRowData()}
                                reLoad={properties.reLoad}
                            />
                        </ReportWrapperStyled>
                        <FieldLabel properties={properties}/>

                    </FloatingFormStyled>
                </>}
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}


// export class ReportField {
//
//     public static FORM_FIELD = (properties: FieldProperties) => {
//         return {
//             element: (
//                 <div className="card report" key={properties.fieldConfig.name}>
//                     <span className={'text-danger'}>PRComp</span>
//                     {/*<Report {...{fieldConfig: properties.fieldConfig, reportData: properties.reportData}}  />*/}
//                 </div>
//             )
//         }
//     }
//
//
// }
