import * as React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import ElementWithShortenedText from "../../../element-with/element-with-shortened-text/ElementWithShortenedText";
import { GuslTableQueryParamState, handleMustNots, resetSearchMusts } from "../../queryParamsSlice";
import { TextFilterValueStyled, TextFilterWrapperStyled } from "../../styled/text-filter/styled";
import { FilterDO } from "../types";
import { ActionStyled } from "../../styled/number-filter/styled";
import { FieldConfigDTO, QueryParamsDTO } from "../../../../types";
import { GuslTableState } from "../../guslTableSlice";

export interface OptionCheckTextFilterDO {
  filter: FilterDO;
  code: string;
  label?: string;
  count?: number;
  isIndividual?: boolean | undefined;
}

export const DefaultTextFilter = ({ filter, code, label, count, isIndividual }: OptionCheckTextFilterDO) => {

  const [className] = useState<string>("DefaultTextFilter-" + new Date().getTime());
  const queryParamsState: GuslTableQueryParamState = useAppSelector(state => state.queryParamsSlice[code]);

  const value = filter.value as string;
  const musts: QueryParamsDTO["musts"] = queryParamsState.queryParams.musts;
  const mustNots: QueryParamsDTO["mustNots"] = queryParamsState.queryParams.mustNots;
  const mustsAreNotPresent: boolean = (typeof musts === "undefined" && mustNots?.length === 0) || (mustNots?.length === 0 && musts?.length === 0);
  const state: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);

  function getFieldOptions(): string[] {
    const filterableFields: FieldConfigDTO[] = state.filterableFields || [];

    const currentField = filterableFields.find(field => field.name === filter.name);

    if (currentField && currentField.options && currentField.options.length > 0) {
      return currentField.options;
    }

    return [];
  }


  const fieldOptions: string[] = getFieldOptions();

  const dispatch = useAppDispatch();

  function isSelectedHandler(queryParams: QueryParamsDTO["musts"] | QueryParamsDTO["mustNots"]): boolean {
    return queryParams?.filter((param) =>
      (param.value === value && param.field === (filter.fieldName || filter.name))).length === 1;
  }

  const [isSelected, setSelected] = useState<boolean>(isSelectedHandler(mustNots));

  useEffect(() => {
    if (mustsAreNotPresent || (mustNots && mustNots?.length > 0)) {
      setSelected(isSelectedHandler(mustNots));
    } else {
      setSelected(!isSelectedHandler(musts));
    }
  }, [mustsAreNotPresent, musts, mustNots]);


  function filterHandler() {
    // MK 04-01-2024 to play nicely with header filters
    // when we have musts(header filter) and user selects
    // mustNot (side panel filters)
    // we are creating mustNots based on current must and currently
    // selected mustNot and re-setting musts

    if (musts && musts.length > 0) {
      // musts selected
      const currentMustValue: string = musts[0].value;
      const mustNotsToBeFilteredOut: string[] = [value, currentMustValue];
      const newMustNots = fieldOptions.filter(option => !mustNotsToBeFilteredOut.includes(option));

      if (newMustNots.length > 0) {
        newMustNots.forEach(mustNotValue => {
          dispatch(handleMustNots({ code, field: filter.fieldName || filter.name, value: mustNotValue }));
        });
        dispatch(resetSearchMusts({ code }));
      }
    } else {
      // no musts selected => back to normal
      dispatch(handleMustNots({ code, field: filter.fieldName || filter.name, value }));
    }
  }


  // MK 26/08/2023
  const commonProps = {
    isActive: !isSelected,
    onClick: filterHandler
  };
  const commonContent = (
    <>
      {!isSelected ? <>&#x2713;</> : <>&#x2717;</>}
      <span className={"px-2"}>
              <ElementWithShortenedText text={label || value}
                                        maxLength={17}
                                        as={"tooltip"}
                                        placement={"left"} />
            </span>
      {count && <TextFilterValueStyled>{count}</TextFilterValueStyled>}
    </>
  );

  return isIndividual ? (
    <ActionStyled {...commonProps}>
      {commonContent}
    </ActionStyled>
  ) : (
    <TextFilterWrapperStyled {...commonProps}>
      {commonContent}
    </TextFilterWrapperStyled>
  );
};
