import styled, {ThemeProps} from 'styled-components';

export const PixelFieldStyled = styled.div.attrs((props) => ({
    className: "form-control form-control-sm bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light "
}))`
  color: ${props => props.theme.table.columnColor} !important ;
  font-size: ${props => props.theme.table.columnFontSize};
`

interface InputStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}

export const PixelInputStyled = styled.input.attrs((props:InputStyledProps) => ({
    className: "bg-transparent  rounded-0 border-top-0 border-start-0 border-end-0 border-0 text-light"
}))<InputStyledProps>`
color: ${props => props.theme.table.columnColor} !important ;
font-size: ${props => props.theme.table.columnFontSize};
`
