import {Chart as ChartJS, Legend, LinearScale, PointElement, Title, Tooltip,} from 'chart.js';
import React, {useEffect, useRef, useState} from 'react';
import {Bubble} from 'react-chartjs-2';
import {log} from "../../../services/LogService";
import ErrorMsg from "../../ui/ErrorMsg";
import {ChartProps} from "../types";
import prepareChartData from "../utils/PrepareChartData";

ChartJS.register(
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    Title
);

export default function BubbleChart({endpoint, serverData, chartFor}: ChartProps) {

    const [className] = useState('BubbleChart-' + new Date().getTime())
    const chartRef = useRef<ChartJS>(null);
    const [chartData, setChartData] = useState({datasets: [], labels: []});
    const _options = serverData?.chartOptions?.bubble
    const [options, setOptions] = useState(_options);
    const _height = serverData?.chartOptions?.bubble?.chart?.canvas.height
    const [height, setHeight] = useState<string | number | undefined>(_height);
    try {
        useEffect(() => {
            const chart = chartRef.current;
            if (!chart) {
                return;
            }
            const {datasets, options, labels} = prepareChartData({
                chartType: 'bubble',
                endpoint,
                serverData,
                chart
            })

            // @ts-ignore
            setChartData({labels, datasets});
            setOptions(options);
        }, [chartFor,JSON.stringify(serverData)]);

        //@ts-ignore
        return <Bubble ref={chartRef} options={options} data={chartData} type={'bubble'} height={height}/>;
    } catch (e) {
        log.error(className, 'MSG001', { endpoint})
        return <ErrorMsg/>
    }


}
