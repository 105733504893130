import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface OptionFieldOpenState {
    id: string | undefined
}


const initialState: OptionFieldOpenState = {
    id: undefined
}


export interface OptionFieldOpenToggleAction {
    id: string | undefined
}

export const optionFieldOpenSlice = createSlice({
    name: 'optionFieldOpenSlice',
    initialState,
    reducers: {
        toggleOptionFieldOpen(state, action: PayloadAction<OptionFieldOpenToggleAction>) {
            let entry: OptionFieldOpenState = state
            entry.id = action.payload.id
        },
    }
})

export const {
    toggleOptionFieldOpen,
} = optionFieldOpenSlice.actions

export default optionFieldOpenSlice.reducer
