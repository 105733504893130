import {Popover} from "react-bootstrap";
import styled from 'styled-components';

export const PopoverStyled = styled(Popover).attrs(props => ({}))`
   background-color:${props => props.theme.colors.dark[0]};
   color: ${props => props.theme.colors.dark[1]};
 
`
export const PopoverContentStyled = styled.div.attrs((props) => ({}))`  
   padding: 5px;
   max-height:400px;
   overflow-y:auto;
 
   ::-webkit-scrollbar {
     width: var(--scrollbar-width);
     height: var(--scrollbar-height);
    };
    
    ::-webkit-scrollbar-track {
        background: ${props => props.theme.table.panelBgColor};
         
    };
    
    ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;
   
    };
    
    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.table.titleBgColor};
    }; 
    
`
