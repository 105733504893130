import React from "react";
import {isDefined} from '../../../utils/TypeCheckers';
import {BarChart} from "../individual-charts/BarChart";
import BubbleChart from "../individual-charts/BubbleChart";
import DoughnutChart from "../individual-charts/DoughnutChart";
import GaugeChart from "../individual-charts/GaugeChart";
import LineChart from "../individual-charts/LineChart";
import MultiTypeChart from "../individual-charts/MultiTypeChart";
import PieChart from "../individual-charts/PieChart";
import PolarAreaChart from "../individual-charts/PolarAreaChart";
import RadarChart from "../individual-charts/RadarChart";
import ScatterChart from "../individual-charts/ScatterChart";
import {ChartProps} from '../types';
import {MASTER_VOLUME_CHARTS} from "./masterVolumeCharts";
import setChartOptions from "./setChartOptions";
import TransformDatasets from "./TransformDatasets";


export function getChart({
                             endpoint, serverData, chartType, chartFor,
                             observable, singleChart, customCharts,
                             singleValue, mixedChart, clientChartOptions,
                             colors, themeColors

                         }: ChartProps) {

    const className = 'getChart-' + new Date().getTime();

    //log.info(className,'MSG001',{colors})
    const wantsItAll:boolean = (!singleChart && MASTER_VOLUME_CHARTS.indexOf(chartType) !== -1) || isDefined(mixedChart);
    // @ts-ignore
    observable.subscribe({
        next(chartFor: any) {

            if (serverData?.newDatasets) {

                serverData.newDatasets.forEach((dataset: { label: string | undefined; datasets: any; }) => {
                    // @ts-ignore
                    if (dataset.label === chartFor) {
                        let {transformedDatasets, labels} = TransformDatasets({
                            chartType,
                            datasets: wantsItAll ? serverData?.newDatasets : dataset.datasets,
                            singleChart,
                            mixedChart,
                            colors,
                            themeColors
                        })


                        // @ts-ignore
                        serverData['datasets'] = transformedDatasets;
                        // @ts-ignore
                        serverData['labels'] = labels;


                    }
                })
                // @ts-ignore
                serverData = setChartOptions({serverData, chartType, clientChartOptions, singleChart, singleValue})

            }


        },
        error(err: string) {
            console.error('something wrong occurred: ' + err);
        },
        complete() {
            // console.log('done',);
        }
    });


    // @ts-ignore
    return {
        'line':
            <LineChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'lineBar':
            <LineChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'lineProgressive':
            <LineChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'bar':
            <BarChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'doughnut':
            <DoughnutChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'pie':
            <PieChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'polarArea':
            <PolarAreaChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'gauge':
            <GaugeChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'bubble':
            <BubbleChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'radar':
            <RadarChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'scatter':
            <ScatterChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,
        'multiType':
            <MultiTypeChart
                endpoint={endpoint}
                serverData={serverData}
                chartFor={chartFor}
            />,

    } [chartType as string]


}
