import {isArray, isDefined} from '../../../utils/TypeCheckers';
import {BlastService} from '../BlastService';
import {BlastException} from '../types';
import {Attachment} from './attachment';
import {CollectionTraversor} from './collection-traversor';
import {GraphMessageType} from './graph-message-type';
import {GraphRequest} from './graph-request';
import {GraphResponseMessage} from './graph-response-message';
import {Instruction} from './instruction';
import {LogService} from './log-service';
import {Operation} from './operation';
import {PathDetails} from './path-details';
import {PathParameters} from './path-parameters';
import {decodeResponse, encodeRequest} from './utils';

export const useKebab = false;

export class GraphService {
    private _correlatedGraphRequestMap: GraphRequest[] = [];
    private _collectionMap: any = {};
    private _correlationId = 0;

    private blastService: BlastService | undefined
    _logService: LogService = new LogService();

    add(collection: string, entity: any): Promise<any> {
        return this.sendGraphRequest(new GraphRequest('add', collection, entity));
    }

    update(key: string, entity: any): Promise<any> {
        return this.sendGraphRequest(new GraphRequest('update', key, entity));
    }

    remove(key: string): Promise<any> {
        return this.sendGraphRequest(new GraphRequest('remove', key));
    }

    private randomId(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    attach(key: string, data: any, parameters?: PathParameters): Attachment | undefined {
        //    attach(key: string, data: any, parameters?: PathParameters): Promise<any> {

        if (key.trim().length === 0) {
            key = 'root';
        }

        let array = isArray(data);

        // perform path and path->collection validation
        const pathDetails: PathDetails[] = PathDetails.splitPath(key);
        if (pathDetails[pathDetails.length - 1].getKeyField() !== undefined && array) {
            this.handlePromiseError(new BlastException('Can only attach to a type of {} when keyField:keyValue is last element of path'));
            return;
        }
        if (pathDetails[pathDetails.length - 1].getKeyField() === undefined && !array) {
            this.handlePromiseError(new BlastException('Can only attach to a type of [] when last element of path is a collection'));
            return;
        }

        const attachmentId = this.randomId();
        // add collection to map of collections

        const attachment: Attachment = new Attachment(attachmentId, key, this._logService);
        if (array) {
            attachment.setListData(data);
        } else {
            attachment.setData(data);
        }
        this._collectionMap[attachmentId] = attachment;
        this.sendGraphRequest(new GraphRequest('attach', key, null, parameters, attachmentId));
        return attachment;
    }

    handlePromiseError(blastException: BlastException): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                reject(blastException.message);
            }
        );
    }

    detach(key: string | undefined) {
        if (key) {
            console.log('detach', key)
            return this.sendGraphRequest(new GraphRequest('detach', key));
        }
    }

    detachAll() {
        return this.sendGraphRequest(new GraphRequest('detachAll'));
    }

    getAttachments() {
        return this.sendGraphRequest(new GraphRequest('attachments'));
    }

    fetch(key: string, parameters?: PathParameters) {
        return this.sendGraphRequest(new GraphRequest('fetch', key, parameters));
    }

    fetchRoot() {
        return this.sendGraphRequest(new GraphRequest('fetch', ''));
    }

    getSchema() {
        return this.sendGraphRequest(new GraphRequest('schema'));
    }

    sendGraphRequest(request: GraphRequest): Promise<any> {
        let complete: any, err: any;

        //  create an empty response - we use the onFulFill and onReject functions on the response
        const promise: Promise<any> = new Promise((onFulfilled, onRejected) => {
            complete = onFulfilled;
            err = onRejected;
        });

        request.setCorrelationInfo(++this._correlationId, complete, err);

        //  we add the requestDO to a map - used to marry response with request
        this._correlatedGraphRequestMap[request._correlationId || 1] = request;


        // console.log('request.getMessage()', request.getMessage());
        //  send the message
        if (useKebab) {
            this.send(encodeRequest(request.getMessage()));
        } else {
            this.send(request.getMessage());
        }

        //  return the promise - will be fulfilled when we get a response from the server
        return promise;
    }

    send(data: any, binary?: boolean): any {
        if (this.blastService) {
            this.blastService.send(data, binary);
        } else {
            console.log('no blast to send message')
        }
    }


    buildPath(startIndex: number, pathDetails: PathDetails[], addLastKey: boolean): string {
        let builder = '';

        for (let x = startIndex; x < pathDetails.length; x++) {
            if (builder.length > 0) {
                builder = builder + '/';
            }
            builder = builder + pathDetails[x].getCollection();
            if (x < pathDetails.length - 1 || addLastKey) {
                builder = builder + '/';
                if (pathDetails[x].getKeyField() != null) {
                    builder = builder + pathDetails[x].getKeyField() + ':' + pathDetails[x].getKeyValue();
                }
            }

        }
        return builder;
    }

    calculateTruePath(key: string | undefined, path: string | undefined, operation: string | undefined): string {

        const keyDetails: PathDetails[] = PathDetails.splitPath(key || '');

        const pathDetails: PathDetails[] = PathDetails.splitPath(path || '');

        let truePath = '';

        if (keyDetails[keyDetails.length - 1].isRoot()) {
            truePath = this.buildPath(0, pathDetails, operation === Operation.UPDATE);
        } else if (keyDetails[keyDetails.length - 1].getKeyField() == null) {
            // collection is an array
            for (let x = 0; x < pathDetails.length; x++) {
                // loop through until collection in path matches last collection in key
                if (pathDetails[x].getCollection() === keyDetails[keyDetails.length - 1].getCollection()) {
                    truePath = this.buildPath(x, pathDetails, operation === Operation.UPDATE);
                    break;
                }
            }
        } else {
            // collection is a map
            if (keyDetails.length === pathDetails.length) {
                truePath = this.buildPath(pathDetails.length - 1, pathDetails, operation === Operation.UPDATE);
            } else {
                truePath = this.buildPath(keyDetails.length, pathDetails, operation === Operation.UPDATE);
            }
        }

        return truePath;

    }

    applyChangeToRecord(collection: any, instruction: Instruction) {
        for (let x = 0; x < instruction.getChanges().length; x++) {
            // name = name of field, value = new value
            collection[instruction.getChanges()[x]['name']] = instruction.getChanges()[x]['value'];
        }
    }

    getGraphMessage(value: string): GraphResponseMessage {
        if (useKebab) {
            return new GraphResponseMessage(JSON.parse(decodeResponse(value)));
        } else {
            return new GraphResponseMessage(JSON.parse(value));
        }
    }

    handleInitialLoad(graphRequest: GraphRequest, graphMessage: GraphResponseMessage) {
        //        const clientCollection: ClientCollection = this._collectionMap[graphMessage.getKey()];
        const attachment: Attachment = this._collectionMap[graphMessage.getAttachmentId()];

        // console.log('retrieve [initial load] from collection', graphMessage.getAttachmentId(), attachment, this._collectionMap);

        if (attachment == null) {
            console.error('Failed to find collection for key: ', graphMessage.getAttachmentId());
            if (graphRequest._onError) {
                graphRequest._onError('Failed to find collection for key: ' + graphMessage.getAttachmentId());
            }
            return;
        }

        if (attachment.isList()) {
            // as its initial load we can just add to collection
            if (graphMessage.getData() !== null && graphMessage.getData() !== undefined) {
                Array.prototype.push.apply(attachment.getListData(), graphMessage.getData());
                attachment.load(graphMessage.getData());
                if (graphRequest._onFulFilled) {
                    graphRequest._onFulFilled(attachment.getListData());
                }
            } else {
                attachment.load(undefined);
                if (graphRequest._onFulFilled) {
                    graphRequest._onFulFilled(attachment.getListData());
                }
            }
        } else {
            this.mergeMap(attachment.getData(), graphMessage.getData());
            if (graphRequest._onFulFilled) {
                graphRequest._onFulFilled(attachment.getData());
            }
        }

    }


    handleJsonMessage(message: any): boolean {
        try {
            if (!message['graphMessageType']) {
                // not for us
                return false;
            }
            this._logService.log('raw message ', message);

            const graphMessage: GraphResponseMessage = new GraphResponseMessage(decodeResponse(message));
            this._logService.log('graph message ', graphMessage);
            this.handleCommand(graphMessage);
            return true;
        } catch (blastException) {
            // not a valid graph message, so return false so can be handled by normal flow
            console.error(blastException);
            return false;
        }
    }

    setBlastService(blastService: BlastService | undefined) {
        this.blastService = blastService
        if (this.blastService) {
            this.blastService.registerMessageHandler(this.handleJsonMessage, this)
        }
    }


    handleCommand(graphMessage: GraphResponseMessage) {
        this.handleTheCommand(graphMessage);
    }

    handleTheCommand(graphMessage: GraphResponseMessage) {

        try {

            // 1st pass - handle responses that don't have a future attached
            switch (graphMessage.getCommand()) {
                case GraphMessageType.GRAPH_ADD_RESPONSE:
                case GraphMessageType.GRAPH_UPDATE_RESPONSE:
                case GraphMessageType.GRAPH_REMOVE_RESPONSE:
                    this.handleGraphModify(graphMessage);
                    return;
            }

            const correlationId = isDefined(graphMessage.getCorrelationId()) ? graphMessage.getCorrelationId() : 1;
            const graphRequest: GraphRequest = this._correlatedGraphRequestMap[correlationId];
            if (graphRequest === undefined) {
                throw new BlastException('Failed to find correlation id: ' + graphMessage.getCorrelationId());
            }

            switch (graphMessage.getCommand()) {
                case GraphMessageType.GRAPH_DETACH_RESPONSE:
                case GraphMessageType.GRAPH_DETACH_ALL_RESPONSE:
                case GraphMessageType.GRAPH_OK_RESPONSE:
                    if (graphRequest._onFulFilled) {
                        graphRequest._onFulFilled(graphMessage);
                    }
                    break;
                case GraphMessageType.GRAPH_FAIL_RESPONSE:
                    if (graphRequest._onFulFilled) {
                        graphRequest._onFulFilled(graphMessage);
                    }
                    break;
                case GraphMessageType.GRAPH_INITIAL_LOAD_RESPONSE:
                    this.handleInitialLoad(graphRequest, graphMessage);
                    break;
                case GraphMessageType.GRAPH_CLIENT_ATTACHMENTS_RESPONSE:
                case GraphMessageType.GRAPH_SCHEMA_RESPONSE:
                case GraphMessageType.GRAPH_FETCH_RESPONSE:
                    if (graphRequest._onFulFilled) {
                        graphRequest._onFulFilled(graphMessage.getData());
                    }
                    break;
                case GraphMessageType.GRAPH_ADD_RESPONSE:
                case GraphMessageType.GRAPH_UPDATE_RESPONSE:
                case GraphMessageType.GRAPH_REMOVE_RESPONSE:
                    break;
            }
        } catch (blastException) {
            // console.log('Exception', blastException);
            // eventHandler.onError(new WebSocketException(ex.getMessage(), ex));
        }
    }

    handleGraphModify(graphMessage: GraphResponseMessage) {
        // console.log('Handling ...', graphMessage);

        if (this.shouldAbandonProcessing(graphMessage)) {
            // 'No Instruction or no changes - doing nothing'
            return;
        }
        const attachment: Attachment = this._collectionMap[graphMessage.getAttachmentId()];
        // console.log('retrieve [modify] from collection', graphMessage.getAttachmentId(), clientCollection, this._collectionMap);

        if (attachment === undefined) {
            // 'Cannot find collection for {}', graphMessage.getKey());
            return;
        }

        const path: string = this.calculateTruePath(graphMessage.getKey(),
            graphMessage.getInstruction()?.getPath(),
            graphMessage.getInstruction()?.getOperation());

        let parentList: any[] = [];
        let record: any = {};

        // console.log('Ready to ...', graphMessage.getInstruction().getOperation());
        const instruction = graphMessage.getInstruction();
        if (instruction) {
            switch (instruction.getOperation()) {
                case Operation.ADD:
                    // console.log('Okay Im Adding');
                    if (attachment.isList()) {
                        CollectionTraversor.findList(path, attachment.getListData()).push(instruction.getRecord());
                    } else {
                        CollectionTraversor.findList(path, attachment.getData()).push(instruction.getRecord());
                    }
                    attachment.added(instruction.getRecord());

                    break;
                case Operation.UPDATE:
                    // console.log('Okay Im Updating');
                    if (attachment.isList()) {
                        record = CollectionTraversor.findRecord(path, attachment.getListData());
                        this.applyChangeToRecord(record, instruction);
                        attachment.changed(record);

                    } else {
                        if (path.length === 0 || graphMessage.getKey().endsWith(path)) {
                            // if key =  markets/id:101/runners/id:103 and path = runners/id:103
                            // then data is not actually hierarchal
                            record = attachment.getData();
                        } else {
                            record = CollectionTraversor.findRecord(path, attachment.getData());
                        }
                        const instruction = graphMessage.getInstruction()
                        if (instruction) {
                            this.applyChangeToRecord(record, instruction);
                        }
                        attachment.changed(record);
                    }
                    break;

                case Operation.REMOVE:
                    // console.log('Okay Im Removing');
                    const pathDetails: PathDetails[] = PathDetails.splitPath(graphMessage.getInstruction()?.getPath() || '');

                    const recordKey: string = pathDetails[pathDetails.length - 1].getCollection() + '/'
                        + pathDetails[pathDetails.length - 1].getKeyField() + ':'
                        + pathDetails[pathDetails.length - 1].getKeyValue();


                    let recordIndex: number;
                    if (attachment.isList()) {
                        parentList = attachment.getListData();
                        // record = CollectionTraversor.findRecord(recordKey, clientCollection.getListData());
                        // @ts-ignore
                        recordIndex = CollectionTraversor.findRecordIndexInList(pathDetails[pathDetails.length - 1].getKeyField(),
                            pathDetails[pathDetails.length - 1].getKeyValue(),
                            attachment.getListData());

                    } else {
                        parentList = CollectionTraversor.findList(path, attachment.getData());
                        if (recordKey.length === 0 || graphMessage.getKey().endsWith(recordKey)) {
                            // record = clientCollection.getData();
                            recordIndex = 0;
                        } else {
                            // record = CollectionTraversor.findRecord(recordKey, clientCollection.getData());
                            // @ts-ignore
                            recordIndex = CollectionTraversor.findRecordIndexInList(pathDetails[pathDetails.length - 1].getKeyField(),
                                pathDetails[pathDetails.length - 1].getKeyValue(),
                                parentList);
                        }
                    }
                    // parentList.remove(record);
                    parentList.splice(recordIndex, 1);

                    attachment.removed(record);

                    break;
            }
        }
    }

    private shouldAbandonProcessing(graphMessage: GraphResponseMessage) {
        if (!graphMessage.getInstruction()) {
            return true;
        }

        const instruction = graphMessage.getInstruction()
        const operationRemove = instruction?.getOperation() === Operation.REMOVE;
        const noChanges = (!instruction?.getChanges() || instruction.getChanges().length === 0);
        const noRecord = instruction?.getRecord();

        return operationRemove && noChanges && noRecord;
    }

    mergeMap(baseObject: any, changedObject: any) {
        for (const p in changedObject) {
            if (changedObject.hasOwnProperty(p)) {
                try {
                    // Property in destination object set; update its value.
                    if (changedObject[p].constructor === Object) {
                        baseObject[p] = this.mergeMap(baseObject[p], changedObject[p]);
                    } else {
                        baseObject[p] = changedObject[p];
                    }
                } catch (e) {
                    // Property in destination object not set; create it and set its value.
                    baseObject[p] = changedObject[p];
                }
            }
        }
    }


}


const graphService = new GraphService();

export {graphService};

export default GraphService;

