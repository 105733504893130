import {createSlice, PayloadAction} from '@reduxjs/toolkit'


interface panel {
    opened: boolean
}

interface PanelPayload {
    id: string
}

// Define a type for the slice state
export interface GrapeState {
    panels: { [id: string]: panel }
    resized: number,
}

// Define the initial state using that type
const initialState: GrapeState = {
    resized: 1,
    panels: {}
}

export const grapeSlice = createSlice({
    name: 'grapeSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        openGrapePanel(state, action: PayloadAction<PanelPayload>) {
            state.panels[action.payload.id].opened = true
        },
        closeGrapePanel(state, action: PayloadAction<PanelPayload>) {
            state.panels[action.payload.id].opened = false
        },
    }
})

export const {
    openGrapePanel,
    closeGrapePanel
} = grapeSlice.actions


export default grapeSlice.reducer
