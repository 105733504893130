import {PastFutureDateRangeUnitDO} from "../../../types";

export const pastFutureRangeUnits: PastFutureDateRangeUnitDO[] = [
    {
        unit: 'h',
        label: 'Hours',
        textUnits: [
            {
                text: 'One hour ago',
                number: 1
            },
            {
                text: 'Current hour',
                number: 0
            },
            {
                text: 'In one hour',
                number: -1
            }
        ],
        numberOfUnits: 24 + 1,
        startPastFrom: 2,
        startFutureFrom: 2,
    },
    {
        unit: 'd',
        label: 'Days',
        textUnits: [
            {
                text: 'Two days ago',
                number: 2
            },
            {
                text: 'Yesterday',
                number: 1
            },
            {
                text: 'Today',
                number: 0
            },
            {
                text: 'Tomorrow',
                number: -1
            },
            {
                text: 'Day after tomorrow',
                number: -2
            }
        ],
        numberOfUnits: 31 + 1,
        startPastFrom: 3,
        startFutureFrom: 3,
    },
    {
        unit: 'w',
        label: 'Weeks',
        textUnits: [

            {
                text: 'Two weeks ago',
                number: 2
            },
            {
                text: 'Last week',
                number: 1
            },
            {
                text: 'This week',
                number: 0
            },
            {
                text: 'Next week',
                number: -1
            }
        ],
        numberOfUnits: 52 + 1,
        startPastFrom: 3,
        startFutureFrom: 2,
    },
    {
        unit: 'M',
        label: 'Months',
        textUnits: [
            {
                text: 'Last month',
                number: 1
            },
            {
                text: 'This month',
                number: 0
            },
            {
                text: 'Next month',
                number: -1
            }
        ],
        numberOfUnits: 12 + 1,
        startPastFrom: 2,
        startFutureFrom: 2,
    },
    {
        unit: 'y',
        label: 'Years',
        textUnits: [
            {
                text: 'Last year',
                number: 1
            },
            {
                text: 'This year',
                number: 0
            },
            {
                text: 'Next year',
                number: -1
            }
        ],
        numberOfUnits: 6 + 1,
        startPastFrom: 2,
        startFutureFrom: 2,
    }
];
