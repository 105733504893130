import {decode} from 'html-entities'
import React from 'react'
import {AmountStyled} from './styled';
import {ExtraFieldProperties} from './types'


const getLanguage = () => new Intl.NumberFormat().resolvedOptions().locale

const applyDecimals = (n: number, decimalPlace: number) => {
    return n / Math.pow(10, decimalPlace)
}

const formatAmount = (amount: number, decimalPlaces: number): string => {
    return amount.toLocaleString(getLanguage(), {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })
}

const Money: React.FC<ExtraFieldProperties> = ({
                                                   amount,
                                                   showCurrency = true,
                                                   defaultZero = false,
                                                   colorise = false,
                                                   flip = false,
                                                   withSign = false,
                                                   withNegative = false,
                                                   zeroAsDash = false,
                                                   zeroAsSpace = false,
                                                   decimalPlaces = 2,
                                                   nonDecimal = false,
                                                   raw = false
                                               }) => {
    if (amount === undefined && !defaultZero) {
        return <></>
    }
    const symbol = showCurrency ? decode(amount?.currency?.symbol || '') : ''

    let value = applyDecimals(amount?.value || 0, decimalPlaces) * (flip ? -1 : 1)

    if (nonDecimal) {
        value = Math.trunc(value)
        decimalPlaces = 0
    }

    if (value === 0 && (zeroAsDash || zeroAsSpace)) {
        return <>{zeroAsDash ? '-' : ' '}</>
    }

    let sign = ''
    if (withSign) {
        sign = value >= 0 ? '+' : '-'
    } else if (withNegative && value < 0) {
        sign = '-'
    }
    // if (raw) {
    //     return <div>{`${symbol}${sign}${formatAmount(value, decimalPlaces)}`}</div>
    // }
    return (
        <AmountStyled colorise={colorise}
                      amount={amount}>{`${symbol}${sign}${formatAmount(value, decimalPlaces)}`}</AmountStyled>
    )
}

export default Money
