import React from 'react';
import {log} from '../../../services/LogService';
import {notDefined} from '../../../utils/TypeCheckers';
import GuslChart from '../../charts/GuslChart';
import {FieldProperties} from '../../types';


export const ChartField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const [className] = React.useState<string>(() => 'ChartField-' + new Date().getTime());
    log.debug(className, 'MSG001', 'properties', properties);


    const renderNoData = (): React.ReactElement => {
        return (
            <div className="card" key={properties.fieldConfig.name}>
                <h5 className="text-center card-title">{properties.fieldConfig.label}</h5>
                <p className={'card-text'}>No Data found</p>
            </div>
        )
    }

    if (notDefined(properties.data)) {
        return <>{renderNoData()}</>
    }

    return (
        <>
            <div key={properties.fieldConfig.name}>
                <h5 className="text-center card-title">{properties.fieldConfig.label}</h5>
                <GuslChart chart={properties.data}/>
            </div>
        </>
    )
}
