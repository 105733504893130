import styled, {ThemeProps} from 'styled-components';

export const ContentWrapperStyled = styled.div.attrs((props) => ({
    id: 'lookup_modal_content'
}))`
    height :500px;
    .modal-lg {
        --bs-modal-width: 1103px !important;
    }
`

export const LookupTitleStyled = styled.div.attrs((props) => ({}))`
    font-size: 16px;
    text-transform: capitalize;    
`

export const LookupTableStyled = styled.table.attrs((props) => ({
    className: 'table table-striped'
}))`
`

export const LookupTableHeadStyled = styled.thead.attrs((props) => ({}))`
`

export const LookupTableBodyStyled = styled.tbody.attrs((props) => ({
    id:'lookup_body'
}))`
`
export const LookupTableRowStyled = styled.tr.attrs((props) => ({}))`
    :hover{
        background-color: ${props => props.theme.colors.accentColorLight};
        color: ${props => props.theme.colors.dark[0]};
    }`

export const LookupTableColumnStyled = styled.td.attrs((props) => ({}))`
`
export const NavTabsWrapperStyled = styled.div.attrs((props) => ({}))`
    margin-bottom: 10px;
    padding: 10px 10px 10px 0;

`
export const NavTabsStyled = styled.ul.attrs((props) => ({}))`
`
interface NavTabProps extends ThemeProps<any> {
    active: boolean;
    onClick: Function;
}

export const NavTabStyled = styled.li.attrs((props: NavTabProps) => ({
    onClick: props.onClick
}))<NavTabProps>`
    margin-right: 10px;
    background-color: ${props => props.active ? props.theme.colors.dark[1] : "inherit"};
    padding: 7px 20px;
    border-radius: 20px;
    cursor: pointer;

    :hover {
        background-color: ${props => props.theme.colors.dark[1]};
        opacity: 0.7;
    }
`

export const TableColumnMultiSelectStyled = styled.th.attrs((props) => ({}))`
    color: ${props => props.theme.table.headerColor};
    font-size: ${props => props.theme.table.headerFontSize};
    padding: 10px 0 5px 5px !important;
    height: 40px;
    font-weight: bold;
    background-color: ${props => props.theme.table.headerBgColor}!important;
`

