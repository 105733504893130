import styled from 'styled-components';
import {getTextAlign} from '../../../utils/CssUtils';
import {defaultCellWidth} from "../gusl-table/guslTableSlice";
import {ColumnHeaderProperties} from '../maintain-table/styled';


export const HeaderPanelsStyled = styled.div.attrs((props) => ({
    className: "row g-0"
}))`


`

export const HeaderPanelStyled = styled.div.attrs((props) => ({
    className: 'col-md'
}))`
  background: ${props => props.theme.headerPanel.bgColor};
  border: ${props => props.theme.headerPanel.border};
  color: ${props => props.theme.headerPanel.fgColor};
  border-radius: ${props => props.theme.headerPanel.borderRadius};
  padding: 5px;
`

export const HeaderTableStyled = styled.table.attrs((props) => ({
    className: "table table-sm"
}))`
  margin-bottom: 5px;
`

export const HeaderColumnHeaderStyled = styled.th.attrs((props: ColumnHeaderProperties) => ({
    className: props.className
}))<ColumnHeaderProperties>`
  text-align: ${props => getTextAlign(props.textAlign)};
  color: ${props => props.theme.table.headerColor};
    // background-color:${props => props.theme.table.panelBgColor}!important;
  font-size: ${props => props.theme.table.headerFontSize};
  min-width: ${props => typeof props.cellWidth !== "undefined" ? props.cellWidth : defaultCellWidth + "px"};
  max-width: ${props => typeof props.cellWidth !== "undefined" ? props.cellWidth : defaultCellWidth + "px"};
  padding: 0;
`
export const HeaderColumnDetailStyled = styled.td.attrs((props: ColumnHeaderProperties) => ({}))<ColumnHeaderProperties>`
  color: ${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};
  overflow: hidden !important;
`

export const HeaderDataPanelStyled = styled.div.attrs((props) => ({}))`
  overflow: hidden !important;
  background: ${props => props.theme.table.panelBgColor};
  border: 1px solid ${props => props.theme.colors.dark[1]};
  border-radius: 5px;
  padding: 5px;
`

export const HeaderFormWrapperStyled = styled.div.attrs((props) => ({}))`
  display: flex;
`
