import React, {useContext, useEffect, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {inputClass} from '../../../pages/login/LoginUsernamePassword';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {getSettings, validate} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {assignReferences, noop, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {formatDecimalNumber} from '../../../utils/WidgetUtils';
import {DecimalDO, FieldProperties, FormMode} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import {DecimalStyled} from '../decimal/styled';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled} from '../text/styled';


export const TotalField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)


    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) ||  properties.data || '');

    const [decimalValue, setDecimalValue] = useState<DecimalDO>(properties?.data?.value || {v: 0, p: 0} as DecimalDO);
    const [prefixValue, setPrefixValue] = useState<string>(properties?.data?.prefix || '');
    const [suffixValue, setSuffixValue] = useState<string>(properties?.data?.suffix || '');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            setFormValue(newValue)
            if (newValue) {
                setDecimalValue(newValue?.value || {v: 0, p: 0} as DecimalDO);
                setPrefixValue(newValue?.prefix || '');
                setSuffixValue(newValue?.suffix || '');
            }

            setRefreshCounter(refreshCounter + 1)
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        }
    }, [properties]);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
            setDecimalValue(properties?.data?.value || {v: 0, p: 0} as DecimalDO);
            setPrefixValue(properties?.data?.prefix || '');
            setSuffixValue(properties?.data?.suffix || '');

    }

    useEffect(() => {
        setFormValue(properties?.data || '')
        setDecimalValue(properties?.data?.value || {v: 0, p: 0} as DecimalDO);
        setPrefixValue(properties?.data?.prefix || '');
        setSuffixValue(properties?.data?.suffix || '');
    }, [properties])

    const doValidation = (fieldValue:any): boolean => {
        setSubmitted(true);
        const message = validate(properties.menuItem?.code, properties.fieldConfig.name, properties.fieldConfig.validators, 'checkbox', fieldValue);
        if (message) {
            setErrorMessage(message);
            return false;
        }
        return true;
    }


    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss)
        if (!style['textAlign']) {
            if (properties.isTableView) {
                style['textAlign']='right'
                style['wordWrap']='break-word'
            } else {
                style['textAlign']='left'
            }
        }

        const formattedNumber = formatDecimalNumber(properties.fieldConfig, decimalValue)
        return (
            <div style={style} key={properties.fieldConfig.name + '_' + refreshCounter}>
                <DecimalStyled decimal={decimalValue} inline={false}
                               extraProperties={properties.fieldConfig.properties}>{prefixValue} {formattedNumber} {properties.fieldConfig.suffix} {suffixValue}</DecimalStyled>
            </div>
        )
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                                <div className={inputClass() + ((submitted && !formValue) ? 'yellow' : '')}>
                                    {formMode === FormMode.VIEW && renderTableView()}
                                </div>
                            </FieldContentStyled>
                        </FieldContentWrapperStyled>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                </>}
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}

