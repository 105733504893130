import styled from 'styled-components';

export const PullDownToRefresh = styled.div.attrs((props) => ({
}))`
    text-align: center;
    font-size: 12px;
    color: #fff;
`

export const ReleaseToRefreshStyled = styled.div.attrs((props) => ({
}))`
    text-align: center;
    font-size: 12px;
    color: #fff;
`

export const EndMessageStyled = styled.div.attrs((props) => ({
}))`
    text-align: center;
    font-size: 12px;
    color: #fff;

`
