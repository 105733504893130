import React, {useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BaseTheme} from '../../../providers/theme/GuslThemeProvider';
import blueTheme from '../../../providers/theme/themes/blueTheme';
import {getSettings, performValidation} from '../../../services/ValidationService';
import {isArray, isObject} from '../../../utils/TypeCheckers';
import {assignReferences, clone, noop, RunOnceEffect} from '../../../utils/Utils';
import {fieldService} from '../../FieldService';
import {ChildReference, FieldConfigDTO, FieldProperties, FormMode} from '../../types';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FloatingFormStyled} from '../text/styled';
import {
    CollectionFieldsContainerStyled,
    CollectionNameStyled,
    ThemeCollectionStyled,
    ThemeContainerStyled,
    ThemeWrapperStyled
} from './styled';
import {THEME_DEFINITION_ALL_FIELD} from './types';

export const ThemeDefinitionField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    const [formValue, setFormValue] = useState<any>(_guslFormState?.getFieldValue(properties) || properties.data || '');


    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'ThemeDefinitionField-' + new Date().getTime());
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [childReferences] = useState<Map<string, ChildReference>>(new Map());

    const loadInitialValue = (): BaseTheme => {
        if (properties.data) {
            return properties.data as BaseTheme
        }
        return blueTheme
    }
    const [formData, setFormData] = useState<BaseTheme>(() => {
        return loadInitialValue();
    });

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormData(loadInitialValue())
        childReferences.forEach(reference => {
            if (reference.changeMode) {
                reference.changeMode(mode);
            }
        })

    }

    const doValidation = (fieldValue: any): boolean => {
        let passed = true;
        childReferences.forEach(reference => {
            if (reference.doValidation) {
                if (!reference.doValidation(fieldValue[reference.name])) {
                    passed = false;
                }
            }
        })
        if (!passed) {
            setErrorMessage('Failed form validation')
            return false;
        }

        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            formValue,
            setSubmitted,
            setErrorMessage)
    }


    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        // const style = getStyle(properties.fieldConfig.tableCss, '')
        return (
            <p>Could be a screen shot</p>
        )
    }

    const renderField = (key: string, index: number, fieldConfig: FieldConfigDTO): React.ReactElement => {
        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference);
        }
        let firstField: number = -1;

        const onSubmitted = (): void => {
            // do nothing
        }
        const onChange = (name: string, value: any): void => {
            const currentFormData: any = clone(formValue)
            if (currentFormData) {
                const fieldValue = currentFormData[key]
                if (isObject(fieldValue)) {
                    currentFormData[key][name] = value;
                } else {
                    currentFormData[key] = value;
                }
            }
            setFormValue(currentFormData)
            properties.onChange(properties.fieldConfig.name, currentFormData);

        }
        const getCurrentRowData = (): any => {
            return formValue
        }
        const currentFormData: any = clone(formValue)
        let fieldVal = undefined
        if (currentFormData) {
            if (isObject(currentFormData[key])) {
                fieldVal = currentFormData[key][fieldConfig.name]
            } else {
                fieldVal = currentFormData[key]
            }
        }
        return <div key={fieldConfig.name}>
            {fieldService.getFormTemplate({
                code: 'theme',
                formMode: formMode,
                isFirstField: false,
                fieldConfig,
                menuItem: properties.menuItem,
                data: fieldVal,
                onSubmitted,
                onChange,
                getCurrentRowData,
                reference: {
                    name: key + '_' + fieldConfig.name,
                    displayOrder: index,
                    register: registerChildReference
                }
            })}
        </div>
    }

    const renderCollection = (key: string, fields: FieldConfigDTO[]): React.ReactElement => {
        return (
            <ThemeCollectionStyled key={key}>
                <CollectionNameStyled>{key}</CollectionNameStyled>
                <CollectionFieldsContainerStyled>
                    {
                        fields?.map((fld: FieldConfigDTO, index: number) => renderField(key, index, fld))
                    }
                </CollectionFieldsContainerStyled>
            </ThemeCollectionStyled>
        )
    }

    const renderThemeDefinition = (): React.ReactElement => {
        return (
            <ThemeWrapperStyled>{Object.keys(formData).map(key => {
                if (THEME_DEFINITION_ALL_FIELD[key]) {
                    const fields: FieldConfigDTO[] | FieldConfigDTO = THEME_DEFINITION_ALL_FIELD[key]
                    if (isArray(fields)) {
                        return renderCollection(key, fields as FieldConfigDTO[])
                    } else {
                        const fld = fields as FieldConfigDTO
                        return (
                            <ThemeCollectionStyled key={key}>
                                <CollectionNameStyled>{key}</CollectionNameStyled>
                                <CollectionFieldsContainerStyled>
                                    {
                                        renderField(key, 1, fld)
                                    }
                                </CollectionFieldsContainerStyled>
                            </ThemeCollectionStyled>
                        )
                    }
                } else {
                    return <div key={key}></div>
                }
            })}</ThemeWrapperStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        if (hideField) {
            return <></>
        }

        return <>
            <FloatingFormStyled>
                <ThemeContainerStyled>
                    {renderThemeDefinition()}
                </ThemeContainerStyled>
                <FieldLabel properties={properties}/>
            </FloatingFormStyled>
        </>
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}
