import React from 'react';
import {useNavigate} from 'react-router-dom';
import GuslModal from '../../components/ui/modal/gusl-modal';
import {LogoStyled, MainContainer} from '../../layouts/mobile-layout/styled';
import {environmentService} from '../../providers/environment/EnvironmentService';

// @ts-ignore
import notFoundImage from './not-found.svg'
import {ImageStyled, NotFoundLinkStyled, NotFoundStyled, NotFoundWrapperStyled} from './styled';

export const NotFoundPage = (): React.ReactElement => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/' + environmentService.getEnvironment()?.homePage || '/');
    };

    return (
        <>
            {environmentService.getEnvironment() && <GuslModal dismissible={false} extraClassName='login'>
                <MainContainer $extraClassName={'login'} id={'main_container'}
                               coverUrl={environmentService.getEnvironment()?.coverUrl}>
                    <LogoStyled src={environmentService.getEnvironment()?.loginUiProperties?.loginLogoUrl} alt="Logo"/>
                    <NotFoundWrapperStyled>
                        <ImageStyled src={notFoundImage} alt="not found"/>
                        <NotFoundStyled>Page not found</NotFoundStyled>
                        <NotFoundLinkStyled
                            onClick={goDashboard}
                        >Return home</NotFoundLinkStyled>
                    </NotFoundWrapperStyled>
                </MainContainer>
            </GuslModal>
            }
        </>
    );
};
