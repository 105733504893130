import React, {useEffect, useId, useRef, useState} from 'react';
import {sessionService} from '../../../../../../providers/session/SessionService';
import {isDefined} from '../../../../../../utils/TypeCheckers';
import {safeStream} from '../../../../../../utils/Utils';
import {widgetService} from '../../../../../dashboard/widgetService';

export const HtmlContent = (props: any): React.ReactElement => {

    const id = useId()

    const [loading, setLoading] = useState<boolean>(true);
    const contentElement = useRef(null);
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [counter, setCounter] = useState<number>(0);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // console.log('wtf =>',ReactDOM.findDOMNode(this).parentNode.getAttribute("id"))
                if (contentElement) {

                    const elementById = document.getElementById('for-fucks-sake');
                    console.log('requestAnimationFrame', id, elementById);
                    if (elementById) {
                        console.log('elementById?.parentElement?', elementById?.parentElement, elementById?.parentElement?.getAttribute('count'))
                        if (isDefined(elementById.parentElement) && elementById?.parentElement?.getAttribute('count')) {
                            setCounter(parseInt(elementById?.parentElement?.getAttribute('count') || '0'))
                        }
                    }

                    console.log('ontentElement.current', contentElement.current)
                    widgetService.loadScriptsOnElement(contentElement.current, props?.grapeComponent?.externalJsUrls, props?.grapeComponent?.externalCssUrls, props?.grapeComponent?.js, props?.grapeComponent?.style)
                }
            }, 200)
        })

    }, [loading, contentElement])

    useEffect(() => {
            // Not sure about this ..
            const request: any = props.formData || {}
            request.name = props?.grapeComponent?.name

            console.log('htmlcontent -> props', props)
            if (!props.grapeComponent.content) {
                if (props?.grapeComponent?.url || props?.url) {
                    sessionService.post<any, any>(props?.grapeComponent?.url || props?.url, request)
                        .then(response => {
                            setHtmlContent(response?.data?.content || '');
                            setLoading(false)
                        })
                        .catch((error: any) => {
                            console.log('error', error)
                            setHtmlContent('<h3>we got an error</h3>');
                            setLoading(false)
                        })
                }
            }
        }, []
    )

    return (
        <div id={'for-fucks-sake'}>
            <div key={'html-content-' + counter} id={'html-content-' + counter} ref={contentElement}
                 dangerouslySetInnerHTML={{__html: htmlContent}}></div>
            {
                safeStream(props?.grapeComponent?.externalCssUrls).map((url: string, idx: number) => <style
                        key={'style_' + idx}>
                        @import url({widgetService.fixUrl(url)});
                    </style>
                )}
            {props?.grapeComponent?.style &&
                <style scoped key={'style_extra'}>
                    @import url({props?.grapeComponent?.style});
                </style>}

        </div>
    )
}
