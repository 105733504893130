import styled from 'styled-components';

export const TextFilterToolbarStyled = styled.div.attrs((props) => ({
    className: "row"
}))
    `
      display: inline-block;
      padding-bottom: 10px;
      width: 100%;
      text-align: right;
    `


export const TextFilterToolbarItemStyled = styled.div.attrs((props) => ({
}))
    `
      color: ${props =>  props.theme.colors.accentColorLight};
      font-size: 12px;
      display: inline;
      
      &:hover {
        text-decoration: underline;
      }
    `
