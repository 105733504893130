import React from 'react';
import {AppSealDTO} from '../../../providers/environment/types';
import {RunOnceEffect} from '../../../utils/Utils';
import {DescriptionStyled, LicenceContainerStyled} from '../styled';
import {AppImageSealStyled} from './styled';


export const AppSeal = (props: AppSealDTO): React.ReactElement => {

    RunOnceEffect(() => {
        try {
            // @ts-ignore
            window.apg_a76b4e10_e681_45f7_afdd_46135ee0ebae.init()
        } catch (e) {
        }
    });

    return (
        <>
            <LicenceContainerStyled className={'app-seal'}>
                {!props.noImage && <AppImageSealStyled
                    id={props.id}
                    data-apg-seal-id={props.sealId}
                    data-apg-image-size={props.imageSize}
                    data-apg-image-type={props.imageType}
                ></AppImageSealStyled>}
                {props.label && !props.noText && <DescriptionStyled dangerouslySetInnerHTML={{__html: props.label}}/>}
            </LicenceContainerStyled>
        </>
    )
}
