import React, {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {guslStorage} from '../../../providers/session/GuslStorage';
import {SessionContext} from '../../../providers/session/SessionContext';
import {SystemContext} from '../../../providers/system/SystemContext';
import {canShowOnForm} from '../../../services/ValidationService';
import {isDefined} from '../../../utils/TypeCheckers';
import {cancelAbortController, doQueryParamsMatch, safeStream} from '../../../utils/Utils';
import {fieldService} from '../../FieldService';
import {FilterActionContainerStyled, FilterActionItemStyled, FilterActionWrapperStyled} from '../../flex-list/styled';
import {
    FavQueryDTO,
    FieldConfigDTO,
    FieldPositionProperties,
    FieldProperties,
    FilterActionControlDO,
    FilterActionDO,
    FormMode,
    MediaType,
    QueryParamsDTO,
    TableRowDTO
} from '../../types';
import {InputCheckboxStyled} from '../checkbox/styled';
import {ElementWithTooltip} from '../element-with/element-with-tooltip/ElementWithTooltip';
import ColumnsSettings from '../gusl-table/columns-settings/ColumnsSettings';
import FavQueryAsHeaderFilter from '../gusl-table/fav-queries/FavQueryAsHeaderFilter';
import Filters from '../gusl-table/filters/Filters';
import {initColumnResize} from '../gusl-table/guslColumnResizeSlice';
import {
    cleanUp,
    getTableData,
    GuslTableState,
    initTable,
    setResponseTotal,
    TableResponseWrapper,
    toggleRowSelection
} from '../gusl-table/guslTableSlice';
import TablePagination from '../gusl-table/pagination/TablePagination';
import paginationService, {ROWS_PER_PAGE} from '../gusl-table/PaginationService';
import {GuslTableQueryParamState, initQueryParams, updateQueryParams} from '../gusl-table/queryParamsSlice';
import {setScrollLeftPosition} from '../gusl-table/scrollSlice';
import {TabbedPageMenuBarStyled} from '../gusl-table/styled';
import TableControls from '../gusl-table/table-controls/TableControls';
import TableColumnHeader from '../gusl-table/TableColumnHeader';
import {MatchesContentStyled, NoMatchStyled} from '../lookup/styled';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {CheckboxWrapperStyled} from '../maintain-table/styled';
import {cleanupGroupAction} from '../maintain-table/table-row/groupActionsSlice';
import {OptionFieldOpenState} from '../option/optionFieldSlice';
import {
    ContentWrapperStyled,
    LookupTableBodyStyled,
    LookupTableColumnStyled,
    LookupTableHeadStyled,
    LookupTableRowStyled,
    LookupTableStyled,
    LookupTitleStyled,
    TableColumnMultiSelectStyled
} from './styled';

interface LookupModalContentProperties extends FieldProperties {
    fieldPositionProperties: FieldPositionProperties,
    onRowSelected: (row: TableRowDTO) => void,
    onMultiRowSelected: (row: TableRowDTO []) => void,
    multiSelect?: boolean
}

export const LookupModalContent = (properties: LookupModalContentProperties): React.ReactElement<LookupModalContentProperties> => {
    const systemContext = useContext(SystemContext);
    const sessionContext = useContext(SessionContext);
    const blastContext = useContext(BlastContext);
    const environmentContext = React.useContext(EnvironmentContext);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [lookupData, setLookupData] = useState<TableRowDTO[]>([]);
    const [filterActions, setFilterActions] = useState<FilterActionDO[]>([]);
    const [currentFilterAction, setCurrentFilterAction] = useState<FilterActionDO | undefined>(undefined);

    const [favouriteQueries, setFavouriteQueries] = useState<FavQueryDTO[]>([]);

    const [fields] = useState<FieldConfigDTO[]>((properties.fieldConfig.innerFields || [])
        .filter(fld => fld.displayInTable));

    const _queryParamsState: GuslTableQueryParamState = useAppSelector(
        (state) => state.queryParamsSlice[properties.fieldConfig.name]
    );
    const [code] = React.useState<string>(() => properties.fieldConfig.name);
    const _guslTableState: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);

    const [className] = React.useState<string>(() => "LookupModalContent-" + new Date().getTime());
    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);
    const optionFieldOpenState: OptionFieldOpenState = useAppSelector(state => state.optionFieldOpenSlice);

    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const inputRef = useRef<HTMLInputElement>(null);

    const serverSearch: boolean = typeof properties?.fieldConfig?.lookupSearchUrl !== "undefined";
    const blastSearch: boolean = properties?.fieldConfig?.lookupCollection !== "";

    const [searchValue, setSearchValue] = useState<string>(inputRef?.current?.value || "");
    const topScrollBarPositionRef = useRef(0);
    const [disableColumnResize] = useState<boolean>(true)

    const sidePanelOpen = _guslTableState?.showFilters || _guslTableState?.showColumnsSettings;

    const divClassName = () => {
        return " col-md-" + (sidePanelOpen ? '9' : '12') + " col-lg-" + (sidePanelOpen ? '9' : '12')
            + " col-xl-" + (sidePanelOpen ? '10' : '12')
    }

    const [
        displayFavQueriesWithHeaderFilters,
        setDisplayFavQueriesWithHeaderFilters
    ] = useState<boolean>(false);

    const [filterActionControl, setFilterActionControl] = useState<
        FilterActionControlDO | undefined
    >(undefined);
    const [activeFilterAction, setActiveFilterAction] = useState<
        FilterActionDO | undefined
    >(undefined);

    const [mediaType, setMediaType] = useState<MediaType>(
        environmentContext.getCurrentMediaType(undefined)
    );

    const blankQueryParamsHandler = () => {
        return paginationService.blankQueryParam();
    }

    useEffect(() => {
        let abortController = new AbortController();
        dispatch(
            initTable({
                code: code,
                sessionContext: sessionContext,
                conditionalRowDetails: undefined,
                label: properties.fieldConfig.label,
                tableData: undefined,
                selectUrl: properties.fieldConfig.lookupSearchUrl || properties.fieldConfig.lookupSelectUrl || '',
                hideHeaderPanel: true,
                hideHeaderColumns: false,
                allFields: fields || [],
                headerSummary: undefined,
                footerSummary: undefined,
                filterPanelOpened: false,
                tableActions: [],
                rowActions: [],
                groupActions: [],
                orderByActions: [],
                expandableRow: false,
                rowsPerPage: ROWS_PER_PAGE[0],
                onRefresh: undefined,
                createConfig: undefined,
                onOpenCreateNewEntityDialog: undefined,
                onInlineMenuGroupAction: undefined,
                performDownloadWithoutPromptAction:
                undefined,
                performActionOnly: undefined,
                showFilters: false,
                showTopFilters: false,
                showColumnsSettings: false,
                openFilterBlocks: [],
                serverRangeQueries: [],
                editRowId: "",
                searchString: "",
                highlightDelta: false,
                disableTableControls: false,
                disableColumnResize: disableColumnResize,
                subLabel: undefined,
                scrollLeftPosition: 0,
                favQueriesOpen: false,
                showMobileTableControls: false,
                mediaType: mediaType,
                tableControl: {
                    pagination: MediaType.Tablet,
                    resize: MediaType.Tablet,
                    filters: MediaType.Tablet,
                    search: MediaType.Tablet,
                    refresh: MediaType.Tablet,
                    columnSettings: MediaType.Tablet
                },
                responseTotal: 0,
                individualAdvancedSearchFields: [],
                favQueriesUpdatedAt: undefined,
                favQueries: [],
                headerPanels: [],
                serverQueryParams:undefined,
                currentContentTotal:0
            })
        );
        dispatch(
            initColumnResize({
                code: code,
                resizedColumns: guslStorage.getResizedColumns(code),
                columnsResetAt: null
            })
        )
        dispatch(
            initQueryParams({code: code, queryParams: blankQueryParamsHandler()})
        );
        setActiveFilterAction(undefined);


        return () => {
            cancelAbortController(abortController)
            dispatch(cleanUp({code: code}));
        }
    }, [code])

    useEffect(() => {
        if (_queryParamsState?.queryParams) {
            let abortController = new AbortController();
            setLoading(true);
            dispatch(
                getTableData({
                    code: code,
                    url: properties.fieldConfig.lookupSearchUrl || properties.fieldConfig.lookupSelectUrl || '',
                    pathParams: undefined,
                    queryParams: _queryParamsState?.queryParams,
                    sessionContext: sessionContext,
                    abortController: abortController
                })
            )
                .unwrap()
                .then((reply: TableResponseWrapper) => {
                    if (reply.response?.filterAction) {
                        const filterActionControl = reply.response?.filterAction;
                        if (filterActionControl && filterActionControl.filterActions) {
                            filterActionControl.filterActions.sort(
                                (a: FilterActionDO, b: FilterActionDO) =>
                                    (a?.displayOrder || 0) - (b?.displayOrder || 0)
                            );
                        }
                        if (filterActionControl?.includeFavQueries) {
                            setDisplayFavQueriesWithHeaderFilters(true);
                        }
                        setFilterActionControl(filterActionControl);

                        safeStream(filterActionControl?.filterActions).forEach((filterAction: FilterActionDO) => {
                            if (filterAction.queryParams) {
                                if (doQueryParamsMatch(filterAction.queryParams, reply.response.queryParams)) {
                                    setActiveFilterAction(filterAction);
                                }
                            }
                        })
                    }

                    dispatch(
                        setResponseTotal({code, responseTotal: reply.response.total})
                    );
                    dispatch(cleanupGroupAction({code: code}));
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.error("error", error);
                    setLoading(false);
                });
        }
    }, [_queryParamsState?.queryParams])

    useEffect(() => {
        dispatch(setScrollLeftPosition({
            code: code,
            scrollLeftPosition: topScrollBarPositionRef.current
        }));
    }, [topScrollBarPositionRef.current])

    useEffect(() => {
        dispatch(initColumnResize({
            code: code,
            resizedColumns: {},
            columnsResetAt: null
        }));

        setLoading(false)
    }, [(properties)])


    const startAgainHandler = () => {
        // setFormValue("");
        // setTableValue("");
        // setSearchValue("");
        setErrorMessage("");
        // dispatch(toggleOptionFieldOpen({id: undefined}));
        // setCursor(-1);
        // if (inputRef?.current?.value) {
        //     inputRef.current.value = "";
        // }
        // if (properties.setTicker) {
        //     properties.setTicker("");
        // }
        // resetOverwriteFormData();
    }

    const StartAgain = (): React.ReactElement => {
        return <ElementWithTooltip
            element={<i className={"fa-solid fa-xmark ms-3 me-1"}
                        onClick={startAgainHandler}
            />}
            tooltip={<span>Cancel / Start again</span>}
        />;
    }

    const renderFilterActionItem = (
        filterAction: FilterActionDO,
        idx: number
    ): React.ReactElement => {
        const onFilterActionItemClick = (filterAction: FilterActionDO) => {
            if (filterAction.route) {
                sessionContext.setQueryParams(JSON.stringify(filterAction.queryParams));
                navigate("/" + filterAction.route);
            }
            setActiveFilterAction(filterAction);
            if (
                filterAction?.queryParams?.rangeQueries &&
                filterAction.queryParams.rangeQueries[0]
            ) {
                if (filterAction.displayOrder === 0) {
                    // future
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now/d";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now+60d/d";
                }
                if (filterAction.displayOrder === 1) {
                    // today
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now/d";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now+1d/d";
                }
                if (filterAction.displayOrder === 2) {
                    // yesterday
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now-1d/d";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now-1d/d";
                }
                if (filterAction.displayOrder === 3) {
                    // this week
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now-1w/w";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now/w";
                }
                if (filterAction.displayOrder === 4) {
                    // last week
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now-2w/w";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now-1w/w";
                }
                if (filterAction.displayOrder === 5) {
                    // this month
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now/M";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now+1M/M";
                }
                if (filterAction.displayOrder === 6) {
                    filterAction.queryParams.rangeQueries[0].exclusive = true;
                    // last month
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now-1M/M";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now/M-1d";
                }
                if (filterAction.displayOrder === 7) {
                    // year
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = "now/y";
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = "now+1y/y";
                }
            }

            if (_queryParamsState?.queryParams?.limit) {
                filterAction.queryParams.limit =
                    _queryParamsState?.queryParams?.limit;
                filterAction.queryParams.skip = 0;
            }
            dispatch(
                updateQueryParams({
                    code,
                    queryParams: filterAction.queryParams
                })
            );
            // refreshData(filterAction.queryParams)
        };

        // MK 06-01-2024
        const containsRangeQueries: boolean = !!(
            filterAction?.queryParams?.rangeQueries &&
            filterAction?.queryParams?.rangeQueries?.length > 0
        );

        const isActive: boolean = containsRangeQueries
            ? filterAction.displayOrder === activeFilterAction?.displayOrder &&
            doQueryParamsMatch(
                activeFilterAction?.queryParams as QueryParamsDTO,
                _guslTableState?.lastQueryParams || paginationService.blankQueryParam()
            )
            : doQueryParamsMatch(
                filterAction?.queryParams as QueryParamsDTO,
                _guslTableState?.lastQueryParams || paginationService.blankQueryParam()
            );

        return (
            <FilterActionItemStyled
                key={"lvai_" + idx}
                isFlex={false}
                //  active={filterAction.displayOrder === activeFilterAction?.displayOrder}
                active={isActive}
                onClick={() => onFilterActionItemClick(filterAction)}
            >
                {filterAction.label}
            </FilterActionItemStyled>
        )
    }

    const renderFilterAction = (): React.ReactElement => {
        if (!filterActionControl) {
            return <></>;
        }

        return (
            <FilterActionContainerStyled>
                <FilterActionWrapperStyled isFlex={false}>
                    {safeStream(filterActionControl?.filterActions).map((action, idx) =>
                        renderFilterActionItem(action, idx)
                    )}
                    {displayFavQueriesWithHeaderFilters && favouriteQueries.length ? (
                        favouriteQueries.map((favQuery, idx) => (
                            <FavQueryAsHeaderFilter
                                key={"fav-f-" + idx}
                                code={code}
                                favQuery={favQuery}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                </FilterActionWrapperStyled>
            </FilterActionContainerStyled>
        )
    }

    const renderColumnHeader = (fld: FieldConfigDTO, idx: number): React.ReactElement => {
        return <TableColumnHeader code={code} fld={fld}
                                  formMode={FormMode.VIEW}
                                  topScrollBarPositionRef={topScrollBarPositionRef}
                                  key={'hdr_' + idx}
                                  disableColumnResize={disableColumnResize}/>
    }

    const RenderResultsHeader = (): React.ReactElement => {
        return (
            <LookupTableHeadStyled>
                <LookupTableRowStyled>
                    {properties.multiSelect &&
                        <TableColumnMultiSelectStyled>Select</TableColumnMultiSelectStyled>
                    }
                    {fields?.filter((fieldConfig: FieldConfigDTO) => canShowOnForm(fieldConfig, FormMode.VIEW)).map((fld, idx) => renderColumnHeader(fld, idx))}
                </LookupTableRowStyled>
            </LookupTableHeadStyled>
        )
    }

    const onRowSelected = (e: React.MouseEvent<HTMLTableRowElement>, row: TableRowDTO, idx: number) => {
        if (e) {
            e.stopPropagation()
        }
        if (properties.onMultiRowSelected && properties.multiSelect) {
            properties.onMultiRowSelected(Object.values(_guslTableState.selectedRows))
        }

        if (properties.onRowSelected && !properties.multiSelect) {
            properties.onRowSelected(row)
        }
    }
    const onDataInputChange = (e: ChangeEvent<HTMLInputElement>, row: TableRowDTO, idx: number) => {
        // @ts-ignore
        const shiftKey = e?.nativeEvent?.shiftKey;

        console.log('onDataInputChange row', row)
        dispatch(toggleRowSelection({
            code: code,
            row: row,
            withShiftKey: shiftKey,
            rowIndex: idx,
            selected: e.target.checked,
        }))
        // row.rowSelected = !row.rowSelected
        // if (properties.onRowSelected) {
        //     properties.onRowSelected(row)
        // }
    }

    const onCheckboxClick = (e: any, row: TableRowDTO) => {
        // required from stopping row open
        if (e) {
            e.stopPropagation();
        }
        e.preventDefault();
        return false;
    };

    const renderResultsRow = (row: TableRowDTO, idx: number): React.ReactElement => {
        const selected: boolean = _guslTableState && _guslTableState?.selectedRows && isDefined(_guslTableState?.selectedRows[row.id]);
        return (
            <LookupTableRowStyled key={'results_row_' + idx} onClick={(e) => onRowSelected(e, row, idx)}>
                {properties.multiSelect &&
                    <LookupTableColumnStyled key={'results_multiselect'}>
                        <CheckboxWrapperStyled>
                            <InputCheckboxStyled type="checkbox"
                                                 id={"multiSelect_" + idx}
                                                 key={"multiSelect_" + idx}
                                                 name={"multiSelect"}
                                                 checked={selected}
                                                 readonly={false}
                                                 disabled={false}
                                                 onChange={(e) => onDataInputChange(e, row, idx)}
                                                 onClick={(e) => onCheckboxClick(e, row)}
                                                 submitted={false}
                                                 noValue={selected}
                            />
                        </CheckboxWrapperStyled>

                    </LookupTableColumnStyled>
                }
                {fields.map((fld: FieldConfigDTO, index: number) => {
                    return (
                        <LookupTableColumnStyled key={'results_row_col_' + index}>
                            {fieldService.getTableField(fld, undefined, row)?.render()}
                        </LookupTableColumnStyled>
                    )
                })}
            </LookupTableRowStyled>
        )
    }

    const RenderResultsBody = (): React.ReactElement => {
        return (
            <LookupTableBodyStyled>
                {safeStream(_guslTableState?.tableData).map((row, idx) => renderResultsRow(row, idx))}
            </LookupTableBodyStyled>
        )
    }

    const RenderResults = (): React.ReactElement => {
        return (
            <MatchesContentStyled {...properties.fieldPositionProperties}>
                <LookupTableStyled>
                    <RenderResultsHeader/>
                    <RenderResultsBody/>
                </LookupTableStyled>
            </MatchesContentStyled>
        )
    }
    const renderContent = (): React.ReactElement => {
        if (!_guslTableState) {
            return <></>
        }
        return (
            <ContentWrapperStyled key={'cws'}>
                <LookupTitleStyled>{properties.fieldConfig.label}</LookupTitleStyled>
                {renderFilterAction()}
                <TableControls key={code + '-tc'} code={code}
                               state={_guslTableState}
                               isLoading={false}
                               isHeader={false}
                               isFooter={false}
                               haveFilters={_guslTableState?.haveFilters || false}
                               pagination={<TablePagination code={code}
                                                            widgetPanelProperties={undefined}/>}
                               orderByActions={[]}
                               hasAdvancedFilters={false}
                               widgetPanelProperties={undefined}
                />
                <div key={'div_1_' + code} className={'row g-0'} id={'gt_1_' + code}>

                    <div key={'div_2_' + code} className={"col order-last order-md-first " + divClassName()}>
                        <RenderResults/>
                    </div>
                    {sidePanelOpen &&
                        <TabbedPageMenuBarStyled>
                            {_guslTableState?.showFilters && <Filters code={code} data={{id: properties?.data?.id}}/>}
                            {_guslTableState?.showColumnsSettings && <ColumnsSettings code={code}/>}
                        </TabbedPageMenuBarStyled>}
                </div>
            </ContentWrapperStyled>
        )
    }

    // if (loading) {
    //     return <MiniLoadingSpinner isLoading={loading}/>;
    // } else
    if (errorMessage) {
        return <NoMatchStyled>{errorMessage} <StartAgain/></NoMatchStyled>;
    } else {
        return <div key={'lmc_content'}>{renderContent()}</div>
    }
}
