// import {ColumnProps} from 'primereact/column';
import React from 'react';
import {FieldProperties} from '../../types';


export class TypeNotFound {

    public static TABLE_FIELD = (properties: FieldProperties) => {
        return (rowData: any, ...parameters: any): React.ReactNode => {
            properties.rowData = rowData;
            // properties.columnProps = columnProps;
            properties.parameters = parameters;
            // properties.data = columnProps.field ? rowData[columnProps.field] : undefined;
            return (
                <>
                    <span className="p-column-title">Not found [{properties.fieldConfig.type}]</span>
                </>
            );
        }
    }

    public static FORM_FIELD = (properties: FieldProperties) => {
        return {
            element: (
                <>
                    <span className="p-column-title">Not found [{properties?.fieldConfig?.type || 'No Type'}]</span>
                </>
            )
        };
    }
}
