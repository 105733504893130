import AutoNumeric from 'autonumeric';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {getSettings, validate} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {isDefined} from '../../../utils/TypeCheckers';
import {assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {formatDecimalNumber} from '../../../utils/WidgetUtils';
import {DecimalDO, FieldProperties, FormMode} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {ExtraFieldProperties} from '../money/types';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, InputStyled} from '../text/styled';
import {DecimalStyled} from './styled';


export const DecimalField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)


    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data ? '' + (properties?.data.v / Math.pow(10, properties?.data.p)) : '');
    const [formValue, setFormValue] = useState<string>(() => {
        if (_guslFormState?.getFieldValue(properties)) {
            return _guslFormState?.getFieldValue(properties) ? '' + ((_guslFormState?.getFieldValue(properties)?.data?.v || 0) / Math.pow(10, (_guslFormState?.getFieldValue(properties)?.data?.p || 2))) : ''
        }
        return properties?.data ? '' + (properties?.data.v / Math.pow(10, properties?.data.p)) : ''
    });
    const [blastedValue, setBlastedValue] = useState<DecimalDO | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties));
    const valueRef = useRef(properties?.data);
    const [fieldValue, setFieldValue] = useState<AutoNumeric | undefined>();

    const numOfDecimals: number = extraFieldProperties?.decimalPlaces || 0;
    const maxNumberOfDecimals = 10;
    let stepMap: { [index: number]: number } = {}

    Array.from({length: maxNumberOfDecimals}).forEach((_, idx) => {
        stepMap[idx] = 1 / Math.pow(10, idx);
    })
    const step = stepMap[numOfDecimals];

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (newValue?.v) {
                setBlastedValue(newValue)
            }
            // setFormValue(newValue ? '' + (newValue.v / Math.pow(10,newValue.p)) : '')
            // setFormValue(newValue)
            setRefreshCounter(refreshCounter + 1)
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        }
    }, [properties]);

    useEffect(() => {
        if (properties.isTableView) {
            return
        }
        window.requestAnimationFrame(function () {
            try {
                if (valueRef?.current) {
                    if (!AutoNumeric.isManagedByAutoNumeric(valueRef?.current)) {
                        let initValue: string | number = ''
                        if (isDefined(properties?.data?.v)) {
                            initValue = properties?.data?.v === 0 ? '' : (properties?.data?.v || 0) / Math.pow(10, properties?.data?.p)
                        }
                        const valueElement = new AutoNumeric(valueRef.current,
                            initValue,
                            {
                                modifyValueOnWheel: false,
                                decimalPlaces: isDefined(properties?.data?.p) ? properties?.data?.p : 2,
                                selectOnFocus: false
                            });
                        valueElement.node().addEventListener("input", (event: Event) => {
                            // @ts-ignore
                            const value = parseFloat(((event?.target?.value ? '' + event?.target?.value : '0').replaceAll(',', '')), 10)

                            try {
                                setFormValue('' + value);
                                const decimalPlaces = countDecimals(value);
                                properties.onChange(properties.fieldConfig.name, {
                                    v: value * Math.pow(10, decimalPlaces),
                                    p: decimalPlaces
                                });
                            } catch (err) {
                                console.log("== WRN001")
                                // log.warn(className, 'WRN001', 'not a number', value);
                            }
                        })
                        setFieldValue(valueElement)
                    } else {
                        // if (fieldValue && properties?.data?.v) {
                        //     console.log("=====> properties?.data?.value",properties?.data?.v, (properties?.data.v / Math.pow(10, properties?.data.p)))
                        //     // fieldValue.set(properties?.data?.v)
                        //     fieldValue.set(properties?.data.v / Math.pow(10, properties?.data.p))
                        // }
                    }
                }
            } catch (err) {
                console.error('error', err)
            }
        })
    }, [properties])

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data ? '' + (properties?.data.v / Math.pow(10, properties?.data.p)) : '');
    }

    useEffect(() => {
        setFormValue(properties?.data ? '' + (properties?.data.v / Math.pow(10, properties?.data.p)) : '')
    }, [properties])


    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true);
        const message = validate(properties.menuItem?.code, properties.fieldConfig.name, properties.fieldConfig.validators, 'checkbox', fieldValue);
        if (message) {
            setErrorMessage(message);
            return false;
        }
        return true;
    }


    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.isTableView ? properties.fieldConfig.tableCss : properties.fieldConfig.entryCss)
        if (!style['textAlign']) {
            if (properties.isTableView) {
                style['textAlign'] = 'right'
                style['wordWrap'] = 'break-word'
            } else {
                style['textAlign'] = 'left'
            }
        }

        let prefix = '';
        if (extraFieldProperties.prefix) {
            prefix = extraFieldProperties.prefix
        }
        let suffix = '';
        if (extraFieldProperties.suffix) {
            suffix = extraFieldProperties.suffix
        }

        const formattedNumber = blastedValue ? formatDecimalNumber(properties.fieldConfig, blastedValue) : formatDecimalNumber(properties.fieldConfig, properties.data)
        return (
            <span style={style} key={properties.fieldConfig.name + '_' + refreshCounter}
                  id={'wrap_dec_' + properties.fieldConfig.name}>
                <DecimalStyled id={'dec_' + properties.fieldConfig.name} decimal={properties.data}
                               inline={properties.inline || false}
                               extraProperties={properties.fieldConfig.properties}>{prefix} {formattedNumber}</DecimalStyled>
            </span>
        )
    }

    const countDecimals = (value: number) => {
        if (Math.floor(value) === value) {
            return 0;
        }
        return value.toString().split(".")[1].length || 0;
    }
    const onDataInputChange = (value: string) => {

        try {
            const number = parseFloat(value);
            if (!isNaN(number)) {
                setFormValue('' + number);
                const decimalPlaces = countDecimals(number);
                // console.log('value=> ', {v: number * Math.pow(10, decimalPlaces), p: decimalPlaces})
                properties.onChange(properties.fieldConfig.name, {
                    v: number * Math.pow(10, decimalPlaces),
                    p: decimalPlaces
                });
            }
        } catch (err) {
            // log.warn(className, 'WRN001', 'not a number', value);
        }
    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                            </FieldContentStyled>
                            <InputStyled type={'text'}
                                         id={properties?.fieldConfig?.name || 'def'}
                                         ref={valueRef}
                                // defaultValue={formValue}
                                         readOnly={disableField}
                                         disabled={disableField}
                                         required={required}
                                         submitted={submitted}
                                         noValue={!formValue}
                            />
                        </FieldContentWrapperStyled>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                </>}
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}

/*
                                <div className={inputClass() + ((submitted && !formValue) ? 'yellow' : '')}>
                                    {formMode === FormMode.VIEW && renderTableView()}
                                </div>

 */
