import React from 'react'
import {Observable} from 'rxjs'
import {CurrencyDTO, SystemNotificationDTO} from '../../components/types';
import {SimpleMessageBO} from './commands';
// import {CustomerBalanceDTO} from '../../features/wallet/api/types'
// import {CustomerBalanceBO} from './commands'

export interface BlastProps {
    login(idToken: string): void;

    observeConnection(): Observable<boolean>;

    observeNotifications(): Observable<SystemNotificationDTO>;

    observeInboundCommands(): Observable<SimpleMessageBO<any>>;

    observeCollection(collection: string): Observable<any>;

    getCollection(collection: string): any[];

    // observeBalance(): Observable<CustomerBalanceDTO>;
    subscribeTopic(blastTopic: String | undefined): void;

    unsubscribeTopic(blastTopic: String | undefined): void;

    disconnect(): void;

    attachAndWatch(path: string): Observable<any>;

    detach(path: string): void,

    getCurrencyMap(): { [id: string]: CurrencyDTO }
}

const createDefault = (): BlastProps => ({
    /* eslint-disable @typescript-eslint/no-unused-vars */
    login: (idToken: string) => {
    },
    observeConnection: (): Observable<boolean> => {
        return new Observable<boolean>()
    },
    observeNotifications: (): Observable<SystemNotificationDTO> => {
        return new Observable<SystemNotificationDTO>()
    },
    observeInboundCommands: (): Observable<SimpleMessageBO<any>> => {
        return new Observable<SimpleMessageBO<any>>()
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    observeCollection: (collection: string): Observable<SystemNotificationDTO> => {
        return new Observable<any>()
    },

    getCollection: (collection: string): any[] => {
        return []
    },

    subscribeTopic: (topic: string) => {
    },

    unsubscribeTopic: (topic: string) => {
    },
    disconnect: () => {

    },
    attachAndWatch: (path: string): Observable<any> => {
        return new Observable<any>()
    },
    detach: (path: string): void => {
    },
    getCurrencyMap: (): { [id: string]: CurrencyDTO } => {
        return {}
    }
})


export const BlastContext = React.createContext<BlastProps>(
    createDefault(),
)
