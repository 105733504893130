import React from 'react';
import {useNavigate} from 'react-router-dom';
import GuslModal from '../../components/ui/modal/gusl-modal';
import {LogoStyled, MainContainer} from '../../layouts/mobile-layout/styled';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {NotFoundLinkStyled} from '../common/styled';
// @ts-ignore
import restrictedLogo from './restricted.svg'
import {ImageStyled, RestrictedContentStyled, RestrictedHeaderStyled} from './styled';

export const GeoRestrictedPage = (): React.ReactElement => {

    const navigate = useNavigate();

    const goHome = () => {
        navigate('/' + environmentService.getEnvironment()?.homePage || '/');
    };

    return (
        <>
            {environmentService.getEnvironment() && <GuslModal dismissible={false} extraClassName='login'>
                <MainContainer $extraClassName={'login'} id={'main_container'}
                               coverUrl={environmentService.getEnvironment()?.coverUrl}>
                    <LogoStyled src={environmentService.getEnvironment()?.loginUiProperties?.loginLogoUrl} alt="Logo"/>
                    <RestrictedHeaderStyled>Location Restricted</RestrictedHeaderStyled>
                    <ImageStyled src={restrictedLogo} alt="restricted"/>
                    <RestrictedContentStyled>Sorry, you are currently in a location that access is restricted.</RestrictedContentStyled>

                    <NotFoundLinkStyled
                        onClick={goHome}
                    >Try again.</NotFoundLinkStyled>

                </MainContainer>
            </GuslModal>
            }
        </>
    )

}
