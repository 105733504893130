import React, { useState } from "react";
import { NewTickerDO } from "../types";
import { PreOrderDTO } from "../../types";

export interface BSChartDO {
  ticker: NewTickerDO;
  acuityCode: PreOrderDTO["acuityCode"];
}

export default function BSInfo({ ticker, acuityCode }: BSChartDO) {

  const [className] = useState("BSChart-" + new Date().getTime());
  const html: string = `
                <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Document</title>
                       <style>
                        div[class*="AssetInfo__wrapper"] {
                            /*display: none !important;*/
                            /*border: 2px solid red;*/
                        }
                    
                        [class*="Title__title"] {
                            font-size: 30px !important;
                        }
                    
                        [class*="AcuityAssetPage__mainWrapper"] {
                            padding: 0 !important;
                            background-color: transparent !important;
                        }
                    
                        [class*="index__AcuityWidget"] {
                            padding: 0 !important;
                            margin: 0 !important;
                        }
                    
                        [class*="AcuityResearchTerminalWidget__padding"][class*="AcuityAssetPage__assetInfo"] {
                            padding: 0 !important;
                        }
                    
                        [class*="AcuityAssetPage__widgetPart"][class*="AcuityResearchTerminalWidget__padding"] {
                            padding: 0 !important;
                        }
                    
                        [class*="TradingViewChart__wrapper"] {
                            padding: 0 !important;
                        }
                    </style>

                    </head>
                    <body>
                       <div id="acuity-assetiq"></div>

                  <script src="https://prodstorage.azureedge.net/Widgets/lib/@1.0.0/widget-core.js"></script>
                  
                  <script>
                    AcuityWidgets.globals({
                        apikey: "585daa3f-67fd-4815-acd5-2511695ce609",
                        locale: "en-GB"
                    });
                    
                    var widget = AcuityWidgets.CreateWidget(
                    "acuityassetiq",
                    document.getElementById("acuity-assetiq"),
                    { 
                      assetId: ${acuityCode},
                      settingId: 2739 }
                    );
                    
                    widget.mount();
                    </script>
                                        </body>
                                    </html>
                                `;


  return (
    <iframe
      title="Acuity Info Widget"
      srcDoc={html}
      width="100%"
      height="650px"
      frameBorder="0"
    />
  );
}

