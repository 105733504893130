export default function sortByDisplayOrder(collection: any[])
{
    collection.sort((a, b) => {
        const orderA = a.displayOrder;
        const orderB = b.displayOrder;
        return orderA - orderB;
    });

    return collection;
}
