import React, {useEffect, useRef, useState} from 'react';
import {Subscription} from 'rxjs';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {SessionContext} from '../../providers/session/SessionContext';
import {areFieldsMediaTypeSensitive, RunOnceEffect, unSubscribe} from '../../utils/Utils';
import {maintainTableService} from '../common/maintain-table/MaintainTableService';
import {ExpandableList} from '../expandable-list/ExpandableList';
import {ListItem} from '../expandable-list/types';
import {FieldConfigDTO, IMenuDTO, MediaType, QueryParamsDTO, WidgetPanelProperties} from '../types';
import {AccordionListPanelStyled, ListContainerStyled, ListScrollContainerStyled, TitleWrapperStyled} from './styled';

type AccordionProperties = {
    menuItem: IMenuDTO,
    queryParams?: QueryParamsDTO | undefined;
    widgetPanelProperties? : WidgetPanelProperties
}

export const AccordionListView = (props: AccordionProperties): React.ReactElement => {

    const environmentContext = React.useContext(EnvironmentContext);
    const sessionContext = React.useContext(SessionContext);


    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(props.widgetPanelProperties));
    const [fields] = useState<FieldConfigDTO[]>(maintainTableService.extractFields(props.menuItem, true));
    const mediaTypeSensitive = areFieldsMediaTypeSensitive(fields)
    const [dataForTable, setDataForTable] = useState<ListItem[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectUrl] = useState<string | undefined>(maintainTableService.extractSelectAllEndpoint(props.menuItem));
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const isMobile = environmentContext.isMobileDevice();
    const listElement = useRef(null);
    const [showSearch, setShowSearch] = useState<boolean>(false);

    const [listStartPos, setListStartPos] = useState<number>(150);
    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (listElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = listElement?.current?.getBoundingClientRect();
                    if (rect) {
                        console.log('rect', rect)
                        setListStartPos(rect.top)
                    } else {
                        setListStartPos(150)
                    }
                }
            }, 100)
        });
    }, [loaded]);

    const getData = (abortController: AbortController) => {
        console.log('selectUrl', selectUrl)
        if (selectUrl) {
            setLoaded(false)
            sessionContext.post<any, any>(selectUrl, props?.queryParams || {}, abortController)
                .then(response => {
                    setLoaded(true)
                    setDataForTable(response.data.content);
                    setRefreshCounter(refreshCounter + 1)
                }).catch(error => {
                console.error("Error", error);
                setLoaded(true)
            })
        }
    }
    useEffect(() => {
        getData(new AbortController())
    }, [props]);

    const reLoad = () => {
        getData(new AbortController())
    }

    const renderList = (): React.ReactElement => {
        return (
            <ExpandableList items={dataForTable}></ExpandableList>
        )
    }

    return (
        <>
            <TitleWrapperStyled>{props?.menuItem?.label || ''}</TitleWrapperStyled>
            <AccordionListPanelStyled isMobile={isMobile} id={'al_lp'} ref={listElement} listStartPos={listStartPos}
                                      hasOrderByActions={false}
                                      footerHeight={footerHeight}
                                      searchOpen={showSearch}>
                <ListContainerStyled listStartPos={listStartPos} footerHeight={footerHeight}>
                    <ListScrollContainerStyled listStartPos={listStartPos} footerHeight={footerHeight}>
                        {loaded && renderList()}
                    </ListScrollContainerStyled>
                </ListContainerStyled>
            </AccordionListPanelStyled>
        </>
    )
}
