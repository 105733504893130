import {BlastException} from '../types';
import {GraphMessageType} from './graph-message-type';
import {Instruction} from './instruction';

export class GraphResponseMessage {
    _correlationId: number;
    _key: string;
    _instruction: Instruction | undefined;
    _data: any;
    _status: string;
    _graphMessageType: string;
    _cmd: string;
    _attachmentId: string;

    constructor(jsonObject: any) {
        if (jsonObject['graphMessageType'] === undefined) {
            throw new BlastException('not a graph response message');
        }
        this._graphMessageType = jsonObject['graphMessageType'];

        if (GraphMessageType[this._graphMessageType] === undefined) {
            throw new BlastException('not a valid graph response message type');
        }

        this._correlationId = jsonObject['correlationId'];
        this._key = jsonObject['key'];
        if (jsonObject['instruction']) {
            this._instruction = new Instruction(jsonObject['instruction']);
        }
        this._data = jsonObject['data'];
        this._status = jsonObject['status'];
        this._cmd = jsonObject['cmd'];
        this._attachmentId = jsonObject['attachmentId'];
        this._graphMessageType = jsonObject['graphMessageType'];
    }

    getCorrelationId(): number {
        return this._correlationId;
    }

    getKey(): string {
        return this._key;
    }

    getInstruction(): Instruction | undefined {
        return this._instruction;
    }

    getData(): any {
        return this._data;
    }

    getStatus(): string {
        return this._status;
    }

    getGraphMessageType(): string {
        return this._graphMessageType;
    }

    getCommand(): string {
        return this._cmd;
    }

    getAttachmentId(): string {
        return this._attachmentId;
    }

}
