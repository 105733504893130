import {Params} from 'react-router-dom';
import {SystemProps} from '../../../providers/system/SystemContext';
import {IMenuDTO} from '../../types';
import {ALL_EVENT, getTab, HOLDCO, PRE_EVENT, RISK_ARB, SHARE_CLASS} from './navigation/SecondaryOttoNav';

class OttoService {

    public getMenuConfigForTab(systemContext: SystemProps, tab: string | undefined): IMenuDTO | undefined {
        // console.log(`tabParam ${tabParam} RISK_ARB ${getTab(RISK_ARB)} ${(tabParam === getTab(RISK_ARB))}`)

        let returnVal: IMenuDTO | undefined = undefined
        if (tab === getTab(RISK_ARB)) {
            returnVal = systemContext.getMenuConfig('RiskArb')
        } else if (tab === getTab(HOLDCO)) {
            returnVal = systemContext.getMenuConfig('HoldCo')
        } else if (tab === getTab(SHARE_CLASS)) {
            returnVal = systemContext.getMenuConfig('ShareClass')
        } else if (tab === getTab(PRE_EVENT)) {
            returnVal = systemContext.getMenuConfig('PreEvent')
        } else if (tab === getTab(ALL_EVENT)) {
            returnVal = systemContext.getMenuConfig('AllEvents')
        } else {
            returnVal = undefined
        }
        return returnVal;
    }

    getPathParams(params: Readonly<Params<string>>) {
        const tabParam = params?.tab ? decodeURIComponent(params?.tab) : undefined;
        const dealId = params?.dealId ? decodeURIComponent(params?.dealId) : undefined;
        const articleId = params?.articleId ? decodeURIComponent(params?.articleId) : undefined;

        return [tabParam, dealId, articleId]
    }
}

const ottoService = new OttoService();

export {ottoService};

export default ottoService;
