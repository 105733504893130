import React, {useContext, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {GuslThemeContext} from '../../../providers/theme/GuslThemeProvider';
import {getSettings} from '../../../services/ValidationService';
import {isBlank} from '../../../utils/TypeCheckers';
import {
    assignReferences,
    constructUrl,
    extractExtraFieldProperties,
    noop,
    RunOnceEffect,
    unSubscribe
} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {ExtraFieldProperties} from '../money/types';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled} from '../text/styled';
import {IframeStyled, IFrameWrapperStyled} from './styled';

export const IframeField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)

    console.log('IframeField', properties)
    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'IframeField-' + new Date().getTime());

    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const [trueColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.positiveColor);
    const [falseColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.negativeColor);

    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(constructUrl((_guslFormState?.getFieldValue(properties) || properties.data || ''),properties.getCurrentRowData()));

    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties] = useState<ExtraFieldProperties>(extractExtraFieldProperties(properties));
    const [isIFrameLoaded, setIFrameLoaded] = useState<boolean>(false);
    const iFrameRef = useRef<HTMLIFrameElement>(null);
    const iframeCurrent = iFrameRef.current;

    useEffect(() => {
        iframeCurrent?.addEventListener('load', (e) => {
            setIFrameLoaded(true)
        });
        return () => {
            iframeCurrent?.removeEventListener('load', () => setIFrameLoaded(true));
        };
    }, [iframeCurrent]);

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (newValue) {
                setFormValue(newValue)
                setRefreshCounter(refreshCounter + 1)
            }
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        }
    }, [properties]);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(constructUrl((properties?.data || ''), properties.getCurrentRowData()))
    }

    useEffect(() => {
        setFormValue(constructUrl((properties?.data || ''), properties.getCurrentRowData()))
    }, [properties])


    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

const fixUrl = (url: string): string => {
        return `${(environmentService.getEnvironment()?.apiBase || '')}${url}?` //
    }

    const renderTableView = (): React.ReactElement => {
        if (isBlank(formValue)) {
            return <></>
        }
        const src = fixUrl(formValue)
        return (
            <IFrameWrapperStyled>
                <IframeStyled loaded={isIFrameLoaded} id={'iframe_' + properties.fieldConfig.name} ref={iFrameRef}
                              src={src || ''}/>
            </IFrameWrapperStyled>
        )
    }
    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        return (
            <>
                {!hideField &&
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                                {renderTableView()}
                            </FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                }
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}
