import React, { useState } from "react";
import { BuySellFormProperties } from "../BuySellForm";
import { BuySellButtonV3Styled } from "../styled_v2";
import { BuySellOrder } from "../types";
import { BuySellState } from "../buySellSlice";

export interface BSButtonsDO {
  properties: BuySellFormProperties;
  newOrder: boolean;
  setSideHandler: Function;
  tradeOrder: boolean;
  buySellState: BuySellState;
}

export default function BSButtons({ properties, newOrder, setSideHandler, tradeOrder, buySellState }: BSButtonsDO) {

  const [className] = useState("BSButtons-" + new Date().getTime());
  const order: BuySellOrder | undefined = buySellState.order;
  // <div className={"row g-0 row-cols-2 d-flex justify-content-between"}>
  //           <div className="col">BUY: <span
  //             className={"xs-small "}>
  //                     {order?.getBuyPrice() ? order?.getBuyPrice() : ""} {order?.getBuyPrice() ? buySellState.currency : ""}
  //                   </span>
  //           </div>
  //           <div className="col">SELL: <span
  //             className={"xs-small"}> {order?.getSellPrice() ? order?.getSellPrice() : ""} {order?.getSellPrice() ? buySellState.currency : ""}</span>
  //           </div>
  //         </div>


  if (newOrder && !properties.isCloseOut) {

    return (
      <div className="row g-0   mb-3">
        <div className="col-xl-6 col-lg-12  px-2 text-center">
          <BuySellButtonV3Styled
            className={"mb-2"}
            side={"SELL"}
            onClick={() => {
              setSideHandler("SELL");
            }}
            active={order?.getSide() === "SELL"}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <span>SELL</span>
              </div>
              <div className={"ms-1"}>
                <span className={"me-1"}>{order?.getSellPrice() ? order?.getSellPrice() : ""}</span>
                <span>{order?.getSellPrice() ? buySellState.currency : ""}</span>
              </div>
            </div>

          </BuySellButtonV3Styled>
        </div>
        <div className="col-xl-6 col-lg-12 px-2 text-center">
          <BuySellButtonV3Styled
            side={"BUY"}
            className={""}
            onClick={() => {
              setSideHandler("BUY");
            }}
            active={order?.getSide() === "BUY"}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <span>BUY</span>
              </div>
              <div className={"ms-1"}>
                <span className={"me-1"}>{order?.getBuyPrice() ? order?.getBuyPrice() : ""}</span>
                <span>{order?.getBuyPrice() ? buySellState.currency : ""}</span>
              </div>

            </div>
          </BuySellButtonV3Styled>
        </div>


      </div>
    );
  }
  if (order?.getSide() === "BUY" || (tradeOrder && properties.isCloseOut && order?.getSide() === "SELL")) {
    return (
      <div className="row g-0 row-cols-2 d-flex justify-content-between">
        <BuySellButtonV3Styled role={"button"}
                               side={"BUY"}
                               active={order?.getSide() === "BUY"}
                               onClick={() => {
                                 setSideHandler("BUY");
                               }}>
          <span>BUY</span>
          <span>{order?.getBuyPrice() ? order?.getBuyPrice() : ""} {order?.getBuyPrice() ? buySellState.currency : ""}</span>
        </BuySellButtonV3Styled>
      </div>
    );
  } else {
    return (
      <div className="row g-0 row-cols-2 d-flex justify-content-between">
        <BuySellButtonV3Styled role={"button"}
                               side={"SELL"}
                               active={order?.getSide() === "sell"}
                               onClick={() => {
                                 setSideHandler("SELL");
                               }}>
          <span>SELL</span>
          <span>{order?.getSellPrice() ? order?.getSellPrice() : ""} {order?.getSellPrice() ? buySellState.currency : ""}</span>
        </BuySellButtonV3Styled>
      </div>
    );
  }
}

