import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../../app/hooks';
import {SessionContext} from '../../../../providers/session/SessionContext';
import {cancelAbortController, RunOnceEffect, unSubscribe} from '../../../../utils/Utils';
import {Ticker} from '../../../ticker/Ticker';
import {setDealOpenState} from '../deal-view/dealOpenSlice';
import {selectedOttoEvent} from '../ottoEventsSlice';
import {ItemStyled} from './styled';

const TEST_DATA: { id: string, value: string }[] = [
    {
        id: '1',
        value:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        id: '2',
        value:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        id: '3',
        value:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        id: '4',
        value:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    }
];


const REFRESH_RATE = 10000

export const OttoTicker = (): React.ReactElement => {

    const sessionContext = React.useContext(SessionContext);
    const [tickerData, setTickerData] = useState<{ id: string, value: string }[]>([]);
    const [label, setLabel] = useState<string| undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    const [systemReady, setSystemReady] = useState<boolean>(false);
    const [lastDealId, setLastDealId] = useState<string | undefined>(undefined);

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext
            .watchSystemReady()
            .subscribe((systemReady: boolean) => {
                setSystemReady(systemReady);
            })
        sessionContext.registerRefreshRate(REFRESH_RATE);
        let refreshSubscription = sessionContext
            .watchRefreshRate()
            .subscribe((nextRefreshRate: number) => {
                if (nextRefreshRate !== -1 && REFRESH_RATE === nextRefreshRate) {
                    refreshData()
                }
            });

        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(refreshSubscription);
        };
    });

    const refreshData = () => {
        let abortController = new AbortController();
        sessionContext.post<any, {
            label: string,
            tickers: { id: string, value: string }[]
        }>('/otto-articles/get-ticker-data', {}, abortController)
            .then(response => {
                setLoading(false)
                if (response?.data) {
                    setTickerData(response?.data?.tickers)
                    setLabel(response?.data?.label)
                }
            }).catch(error => {
            setLoading(false)
            return () => {
                cancelAbortController(abortController);
            }
        })
    }

    useEffect(() => {
        let mounted = true;
        let abortController = new AbortController();
        if (systemReady) {
            refreshData()
        }
        return () => {
            cancelAbortController(abortController);
            mounted = false;
        }

    }, [systemReady]);

    const onItemClicked = (item: { id: string, value: string }) => {
        console.log('item clicked', item);
        let code ='events'
        dispatch(setDealOpenState({opened: false}))
        dispatch(selectedOttoEvent({code: code, selectedEvent: {dealId: item.id, id: item.id}}))
        dispatch(setDealOpenState({opened: true}))
        setLastDealId(item.id)
    }
    const renderItem = (item: { id: string, value: string }) => {
        return (
            <ItemStyled onClick={() => onItemClicked(item)}>{item.value}</ItemStyled>
        );
    };

    return (
        <>{systemReady && tickerData.length > 0 &&
            <Ticker
                label = {label}
                component={renderItem}
                data={tickerData}
                speed={50} /* 60 or less */
                delay={0}
                loop={true}
                direction={'normal'}
                keyName="_id"
                tickerStyle={{
                    background: '-webkit-linear-gradient(left, #0a4232 0%, #579b88 100%)', /* Chrome10-25,Safari5.1-6 */
                    width: "100%",
                    height: "40px",
                    zIndex: 99,
                    borderRadius: '5px',
                    padding: '5px',
                    marginTop: '5px',
                }}
            />
        }
        </>
    )
}
