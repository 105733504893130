import React, {useEffect, useRef, useState} from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select/base';
import {useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {log} from '../../../services/LogService';
import {getSettings, performValidation} from '../../../services/ValidationService';
import {
    assignReferences,
    cancelAbortController,
    canColorise,
    canColoriseText,
    getCssValue,
    noop,
    RunOnceEffect, unSubscribe
} from '../../../utils/Utils';
import {FieldPositionProperties, FieldProperties, FormMode} from '../../types';
import {handleResize, handleWindowChange, initFieldPositionProperties} from '../../ui/FieldUtils';
import FieldLabel from '../field-label/FieldLabel';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {OptionFieldStyles} from '../option/types';
import {FieldContentStyled, FieldContentWrapperStyled, HintStyled} from '../text/styled';
import {HoverablePanel} from '../hoverable-panel/HoverablePanel';
import {
    FloatingFormStyled,
    MultiSelectPanelWrapperStyled,
    MultiSelectRowContainerStyled,
    MultiSelectRowItemStyled
} from './styled';

/*
https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/__tests__/Select.test.tsx
 */
export interface MultiSelectOption {
    name: string;
    code: string;
}


interface Option {
    readonly label: string;
    readonly value: string;
    readonly isDisabled?: boolean;
}

export const MultiSelectField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext);
    const animatedComponents = makeAnimated();


    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);


    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'MultiSelectField-' + new Date().getTime());
    const valueRef = useRef(properties?.data);
    const [formMode, setFormMode] = useState(properties.formMode);
    const [loading, setLoading] = useState<boolean>(false);

    const [colorise] = useState<boolean>(canColorise(properties.fieldConfig));
    const [coloriseText] = useState<boolean>(canColoriseText(properties.fieldConfig));

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false);
    // const [formValue, setFormValue] = useState<Option[]>(properties?.data?.selections || []);
    const [formValue, setFormValue] = useState<Option[]>(_guslFormState?.getFieldValue(properties)?.selections || properties?.data?.selections || []);

    const [dropdownItems, setDropdownItems] = useState<Option[]>([]);

    const lookupElement = useRef(null);
    const [oldResize, setOldResize] = useState<number | undefined>(undefined);
    const [fieldPositionProperties, setFieldPositionProperties] = useState<FieldPositionProperties>(() => initFieldPositionProperties(environmentContext, properties));

    RunOnceEffect(() => {
        const subscriptionResize = handleResize(environmentContext, lookupElement, oldResize, fieldPositionProperties, setFieldPositionProperties, setOldResize)
        return () => {
            unSubscribe(subscriptionResize)
        }
    })

    useEffect(() => {
        handleWindowChange(lookupElement, fieldPositionProperties, setFieldPositionProperties)
    }, [loading, formMode]);

    useEffect(() => {
        let mounted = true;
        let abortController = new AbortController();
        if (properties.formMode === FormMode.EDIT || properties.formMode === FormMode.NEW) {
            if (properties.fieldConfig.selectUrl) {
                setLoading(true);
                sessionContext.get<Option[]>(properties.fieldConfig.selectUrl, abortController)
                    .then((response) => {
                        setDropdownItems(response.data || []);
                        setLoading(false);
                    }, reason => {
                        log.error(className, 'ERR001', 'Error getting template data', reason)
                    });
            } else {
                setLoading(false);
            }
        }
        return () => {
            cancelAbortController(abortController);
            mounted = false;
        }
    }, [sessionContext, properties.formMode]);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data?.selections || [])
    }

    RunOnceEffect(() => {
        onDataInputChange(formValue);
    });

    const doValidation = (fieldValue: any): boolean => {
        log.info(className, 'MSG003', 'doValidation', valueRef.current?.props?.value, fieldValue)
        const valid = performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            fieldValue,
            setSubmitted,
            setErrorMessage)
        return valid
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderHoverPanel = (tableView: boolean): React.ReactElement => {
        return (
            <MultiSelectPanelWrapperStyled>
                {formValue?.map((selection: Option, index: number) => {
                    return <MultiSelectRowContainerStyled key={selection.value} tableView={false}>

                        <MultiSelectRowItemStyled index={index} tableView={false}
                                                  className={getClassName(selection.value || '')}>{selection.label}</MultiSelectRowItemStyled>
                    </MultiSelectRowContainerStyled>
                })}
            </MultiSelectPanelWrapperStyled>
        )
    }

    const renderLinkPanel = (tableView: boolean): React.ReactElement => {
        return (
            <>
                {formValue?.map((selection: Option, index: number) => {
                    return <MultiSelectRowContainerStyled key={selection.value} tableView={tableView}>

                        <MultiSelectRowItemStyled index={index} tableView={tableView}
                            // className={'p-1 border rounded small mx-1 box_shadow ' + getClassName((selection.value || ''))}>{selection.label}</span>
                                                  className={getClassName(selection.value || '')}>{selection.label}</MultiSelectRowItemStyled>
                    </MultiSelectRowContainerStyled>
                })}
            </>
        )
    }
    const getClassName = (value: string) => {
        let extraClass = '';
        if (colorise) {
            extraClass += '  gusl-small mx-1 status-badge status-' + value.toLowerCase();
        }
        if (coloriseText) {
            extraClass += 'p-1  gusl-small mx-1  status-text-' + value.toLowerCase();
        }
        return extraClass;
    }

    const renderTableView = (tableView: boolean): React.ReactElement => {


        return (
            <div className={'pt-2 pe-2  pb-2 d-flex justify-content-start'}>
                {formValue.length > 1 && <HoverablePanel link={renderLinkPanel(tableView)} panel={renderHoverPanel(tableView)}/>}
                {formValue.length === 1 && renderLinkPanel(tableView)}
            </div>
        )
    }


    const onDataInputChange = (options: Option[]) => {
        setFormValue(options);
        properties.onChange(properties.fieldConfig.name, {selections: options});
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        if (hideField) {
            return <></>
        }

        if (formMode === FormMode.VIEW || disableField) {
            return <FloatingFormStyled>
                <FieldContentWrapperStyled>
                    <FieldContentStyled>
                        <div className={'option-view-wrapper'}>
                            {renderTableView(false)}
                        </div>
                    </FieldContentStyled>
                    {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                </FieldContentWrapperStyled>
                <FieldLabel properties={properties}/>
            </FloatingFormStyled>
        }

        return (
            <FloatingFormStyled>
                <FieldContentWrapperStyled  {...fieldPositionProperties}>
                    <FieldContentStyled>

                        {/*new select*/}
                        {/*@ts-ignore*/}
                        <Select
                            /*@ts-ignore*/
                            styles={OptionFieldStyles}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    text: getCssValue('muted'),
                                    primary25: getCssValue('--widget-blue-light'),
                                    primary: getCssValue('--main-background-color'),
                                },
                            })}
                            ref={valueRef}
                            options={[...dropdownItems]}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            isSearchable={false}
                            menuIsOpen={menuOpen}
                            onChange={(newValue, actionMeta) => {
                                onDataInputChange(newValue as Option[])
                            }}
                            onInputChange={(e) => {
                            }}
                            filterOption={(value, search: string) => {
                                return value.value.indexOf(search) > -1
                            }}
                            onMenuOpen={() => {
                                setMenuOpen(true);
                            }}
                            onMenuClose={() => {
                                setMenuOpen(false);
                            }}
                            value={formValue}
                        />
                    </FieldContentStyled>
                    {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                    {submitted && errorMessage &&
                        <small className="yellow">{errorMessage}</small>}
                </FieldContentWrapperStyled>
                <FieldLabel properties={properties}/>
            </FloatingFormStyled>
        )
    }
    const renderPage = (): React.ReactElement => {
        return (
            <>
                {properties.isTableView ? renderTableView(true) : renderFormView()}
            </>
        )
    }
    return (
        <>
            {loading ? <LoadingSpinner/> : renderPage()}
        </>
    )

}
