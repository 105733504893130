// @ts-nocheck
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SessionContextProps} from '../../../../providers/session/types';
import {constructUrl} from '../../../../utils/Utils';
import {ActionCompletedBO, ActionConfigDTO} from '../../../types';
import {TableRowDTO} from '../../types';

// -------------- actions

export interface GroupStatusChangeRequest {
    rows: TableRowDTO[],

}

export interface GroupActionInitPayload {
    code: string,
    haveGroupActions: boolean,
}

export interface GroupActionCleanupPayload {
    code: string
}

export interface GroupActionClearAllRowsPayload {
    code: string
}

export interface RowSelectedPayload {
    code: string,
    rowId: string,
    rowIndex: number,
    row: TableRowDTO,
    selected: boolean,
    withShiftKey: boolean
    tableData: TableRowDTO[] | undefined,
}


interface PerformActionRequest {

    code: code
    actionItem: ActionConfigDTO,
    sessionContext: SessionContextProps,
    abortController: AbortController,


}

// -------------- states
export interface GroupActionMasterState {
    [id: string]: GroupActionState
}

export interface GroupActionState {
    code: string,

    haveGroupActions: boolean,

    selectedRows: { [id: string]: TableRowDTO },

    haveSelectedRows: boolean

}

// -------------- utils
const loadInitValues = (values: GroupActionInitPayload): GroupActionState => {
    return {
        code: values.code,
        haveGroupActions: values.haveGroupActions,
        selectedRows: {},
        haveSelectedRows: false,
    }
}


const createDefault = (code: string): GroupActionState => {
    return {
        code: code,
        selectedRows: {},
        haveSelectedRows: false,
    }
}

const getEntry = (state: GroupActionMasterState, code: string): GroupActionState => {
    let entry: GroupActionState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry;
}

const findPrevious = (entry: GroupActionState, currentIndex: number, selected: boolean, tableData: TableRowDTO[]): number => {
    if (tableData.length > currentIndex) {
        for (let i = currentIndex - 1; i >= 0; i--) {
            if ((selected && entry.selectedRows[tableData[i].rowId]) || (!selected && !entry.selectedRows[tableData[i].rowId])) {
                return i
            }
        }
    }
    return 0
}
const performToggleRowSelection = (
    entry: GroupActionState,
    rowId: string,
    rowIndex: number,
    row: TableRowDTO,
    selected: boolean,
    withShiftKey: boolean,
    tableData: TableRowDTO[] | undefined,
): GroupActionState => {
    console.log('tableData -> ',tableData)
    if (rowIndex === 0 || !tableData || !withShiftKey) {
        if (!selected) {
            delete entry.selectedRows['r_' +rowIndex]
        } else {
            console.log('add -> ','r_' +rowIndex,row)
            entry.selectedRows['r_' +rowIndex] = row
        }
    } else if (withShiftKey){
        const startIdx = findPrevious(entry, rowIndex, selected, tableData || [])
        for (let i = startIdx; i <= rowIndex; i++) {
            if (!selected) {
                delete entry.selectedRows['r_' +i]
            } else {
                entry.selectedRows['r_' +i] = tableData[i]
            }
        }
    }
    entry.haveSelectedRows = Object.keys(entry.selectedRows).length > 0
    return entry
}

export interface GroupActionResponseWrapper {
    code: string,
    response: ActionCompletedBO,

}

export const sendGroupActionRequest = createAsyncThunk('url', async (request: PerformActionRequest, {getState}) => {
    const state = getState();
    const entry: GroupActionState = getEntry(state.groupActionsSlice, request.code)
    const requestBody: GroupStatusChangeRequest = {
        rows: Object.values(entry.selectedRows)
    }
    console.log('requestBody',request.code,requestBody)

    const response = await request.sessionContext.post<GroupStatusChangeRequest, ActionCompletedBO>(constructUrl(request.actionItem.url, {}),
        requestBody, request.abortController)
    return {code: request.code, response: response?.data} as GroupActionResponseWrapper;
})

const performClearAllRows = (entry: GroupActionState): GroupActionState => {
    entry.selectedRows = {}
    entry.haveSelectedRows = false
    return entry
}

// -------------- config
const initialState: GroupActionMasterState = {}
export const groupActionsSlice = createSlice({
    name: 'groupActionsSlice',
    initialState,
    reducers: {
        initGroupAction(state, action: PayloadAction<GroupActionInitPayload>) {
            state[action.payload.code] = loadInitValues(action.payload)
        },
        toggleRowSelection(state, action: PayloadAction<RowSelectedPayload>) {
            const entry: GroupActionState = getEntry(state, action.payload.code)
            performToggleRowSelection(entry, action.payload.rowId, action.payload.rowIndex, action.payload.row, action.payload.selected, action.payload.withShiftKey, action.payload.tableData)
            state[action.payload.code] = entry
        },
        cleanupGroupAction(state, action: PayloadAction<GroupActionCleanupPayload>) {
            delete state[action.payload.code]
        },
        clearAllRows(state, action: PayloadAction<GroupActionClearAllRowsPayload>) {
            const entry: GroupActionState = getEntry(state, action.payload.code)
            performClearAllRows(entry)
        },
        extraReducers: (builder) => {
            // Add reducers for additional action types here, and handle loading state as needed
            builder.addCase(sendGroupActionRequest.fulfilled, (state, action: PayloadAction<ActionCompletedBO>) => {
                const entry: GroupActionState = getEntry(state, action.payload.code)
                return clearAllRows(entry)
            })
        }
    }
})

export const {
    initGroupAction,
    toggleRowSelection,
    cleanupGroupAction,
    clearAllRows
} = groupActionsSlice.actions

export default groupActionsSlice.reducer
