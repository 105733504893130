type Props ={
    min:number,
    max:number
}

export function getRandomIntInclusive({min, max}:Props) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

export function getRandomFloatInclusive({min, max}:Props) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min)+(getRandomIntInclusive({min:0,max:9})/10); //The maximum is inclusive and the minimum is inclusive
}