import React from 'react';
import {log} from '../../../services/LogService';
import {translateService} from '../../../services/TranslateService';
import {isDefined} from '../../../utils/TypeCheckers';
import {FieldProperties} from '../../types';
import {FieldLabelStyled} from './styled';

interface FieldLabelProperties {
    properties?: FieldProperties | undefined,
    label?: string,
    htmlFor?: string
}

export const FieldLabel = ({properties, label, htmlFor}: FieldLabelProperties): React.ReactElement<FieldProperties> => {
    const lbl = label ? label : translateService.getFormLabel(properties?.menuItem?.code, properties?.fieldConfig.name, properties?.fieldConfig.label);
    const fieldName = htmlFor ? htmlFor : properties?.fieldConfig?.name || 'default';
    const hasLabel = isDefined(properties?.fieldConfig?.noLabel) ? !properties?.fieldConfig?.noLabel : true;
    // const extraFieldProperties = extractExtraFieldProperties(properties);
    const symbol = undefined; // decode(extraFieldProperties?.amount?.currency?.symbol || '');

    let style = {};
    try {
        const labelCss = properties?.fieldConfig?.labelCss || '{"fontWeight":"bold"}'
        style = JSON.parse(labelCss);
    } catch (error) {
        log.error('FieldLabel', 'MSG001', 'parse style error', error);
    }

    if (properties?.inline) {
        return <></>
    }
    return (
        <>
            {hasLabel && <FieldLabelStyled
                                htmlFor={fieldName}>
                <div
                    style={style}>{symbol ? <span className={'yellow'}>{symbol} </span> : ''} {lbl} </div>
            </FieldLabelStyled>}
        </>
    )
}

export default FieldLabel;
