import React from 'react';
import {getStyle} from '../../../utils/CssUtils';
import {PanelConfigDTO} from '../../types';
import {HeaderPanel} from './HeaderPanel';
import {HeaderPanelsStyled, HeaderPanelStyled} from './styled';

type HeaderPanelsProperties = {
    parentData: any,
    panels: PanelConfigDTO[];
}

export const HeaderPanels = ({parentData, panels}: HeaderPanelsProperties): React.ReactElement => {
    const renderPanel = (panel: PanelConfigDTO, idx: number): React.ReactElement => {
        const style = getStyle(panel.panelCss, '')
        return (
            <HeaderPanelStyled key={'panel_' + idx} style={style}  id={'panel_' + idx}>
                <HeaderPanel parentData={parentData} panel={panel}/>
            </HeaderPanelStyled>
        )
    }


    return (
        <HeaderPanelsStyled id={'header_panels'}>
            {panels?.map((panel, index) => renderPanel(panel, index))}
        </HeaderPanelsStyled>
    )
}
