import React from 'react';

interface ParlaysTabProperties {
    onClose?: () => void

}

export const ParlaysTab = ({}: ParlaysTabProperties): React.ReactElement<ParlaysTabProperties> => {

    return (
        <p>Parlays - coming (not so) soon.</p>
    )
}
