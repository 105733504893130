import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { OverlayTriggerType } from "react-bootstrap/OverlayTrigger";
import { EnvironmentContext } from "../../../../providers/environment/EnvironmentContext";
import { BaseTheme, GuslThemeContext } from "../../../../providers/theme/GuslThemeProvider";
import { maintainTableService } from "../../maintain-table/MaintainTableService";
import { PopoverContentStyled, PopoverStyled } from "./styled";
import Icon from "../../maintain-table/bootstrap/Icon";
import { ActionIconStyled } from "../../gusl-table/styled/table-controls/styled";
import { ElementWithTooltip } from "../element-with-tooltip/ElementWithTooltip";
import { PopoverProps } from "react-bootstrap/Popover";

interface ElementWithPopoverProps extends PopoverProps {
  element: JSX.Element,
  popover: JSX.Element,
  trigger?: OverlayTriggerType | OverlayTriggerType[] | undefined,
  withClose?: boolean,
  closeHandler?: Function,
  open?: boolean,
  _title?: JSX.Element | string,
  popoverStyle?: { [index: string]: string },
  popoverClass?: string | undefined;
}

export default function ElementWithPopover({
                                             element,
                                             popover,
                                             placement,
                                             trigger,
                                             withClose,
                                             closeHandler,
                                             open,
                                             _title,
                                             popoverStyle,
                                             popoverClass
                                           }: ElementWithPopoverProps) {
  const [className] = useState("ElementWithPopover-" + new Date().getTime());
  const guslThemeContext = React.useContext(GuslThemeContext);
  const environmentContext = React.useContext(EnvironmentContext);
  const [themeColors] = useState<BaseTheme>(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()));
  const currentCode = maintainTableService.getCodeFromUrl();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleCloseHandler() {
    handleClose();
    if (closeHandler) {
      closeHandler();
    }
  }

  function toggleHandlerHandler() {

    if (closeHandler && show) {
      closeHandler();
    }
    setShow(!show);
  }

  useEffect(() => {
    handleClose();
  }, [currentCode]);

  useEffect(() => {
    if (open) {
      setShow(open);
    }

  }, [open]);

  const _popover = (
    <PopoverStyled>
      <PopoverContentStyled style={popoverStyle ? popoverStyle : {}} className={popoverClass ? popoverClass : ""}>
        <div className="row g-0 ">
          <div className={"col"}>{_title}</div>
          {withClose &&
            <div className="col-1 text-end ms-2">
              <ElementWithTooltip tooltip={<span>close</span>}
                                  element={
                                    <ActionIconStyled>
                                      <Icon icon={"fa-solid fa-xmark"}
                                            onClick={() => {
                                              handleCloseHandler();
                                            }} />
                                    </ActionIconStyled>} />
            </div>}

        </div>
        {popover}

      </PopoverContentStyled>
    </PopoverStyled>
  );

  const _element = () => (
    <OverlayTrigger trigger={trigger ? trigger : ["hover", "focus"]}
                    show={show}
                    onToggle={() => {
                      toggleHandlerHandler();
                    }}
                    placement={placement ? placement : "right"}
                    overlay={_popover}
    >
      {element}
    </OverlayTrigger>
  );

  return _element();
}
