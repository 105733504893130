import styled, {ThemeProps} from 'styled-components';
import {flipY, TableStyledProps} from '../common/gusl-table/styled/table/styled';

export const PowerOfAttorneySelectorContainerStyled = styled.div.attrs((props) => ({}))`
  margin-left: 150px;
  margin-top: 10px;
  display: contents;  
`
export const PowerOfAttorneyLookupStyled = styled.div.attrs((props) => ({
    id: props.id,
}))`
  z-index: 20;
  width: 150px;
  //position: relative;
  //left: -92px;
  //top: 20px;
  position: absolute;
  height: 20px;

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};

  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;

  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;
`

export interface PowerOfAttorneyContainerStyledProps extends ThemeProps<any> {

    isMobile?: boolean,


}

export const PowerOfAttorneyContainerStyled = styled.div.attrs((props: PowerOfAttorneyContainerStyledProps) => ({
    // className: "d-flex align-items-center justify-content-start mx-2"
}))<PowerOfAttorneyContainerStyledProps>`
  //width: 60px;
  margin-top: ${props => props.isMobile ? '7px' : '7px'} !important;
`
export const PowerOfAttorneyTableResponsiveStyled = styled.div.attrs((props: TableStyledProps) => ({
    className: props.className,
    id: props.id
}))<TableStyledProps>`
    // ${flipY}   ;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 0px;
  height: 100px;
  overflow: auto;
`
export const PowerOfAttorneyLabelStyled = styled.div.attrs((props ) => ({
}))`
  color:${props => props.theme.navigation.sideMenu.color};
  display: inline;
  padding-right:3px;
  font-size: 14px;
`

export const PowerOfAttorneySelectionStyled = styled.div.attrs((props ) => ({
}))`
  display: inline;
`
export const PowerOfAttorneyOptionComboStyled = styled.div.attrs((props) => ({

}))`
  color: ${props => props.theme.table.columnColor} !important ;
  //  height: 30px !important;
  font-size: ${props => props.theme.table.columnFontSize};
  vertical-align: middle;
  line-height: 32px;  
  cursor:pointer;
  display: inline;
`
export const MobilePOAWrapperStyled = styled.div.attrs((prop) => ({
    id: 'poa_we'
}))`
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 1rem !important;
`
export const ClientsWrapperStyled = styled.div.attrs((prop) => ({
    id: 'poa_cws'
}))`
  div:first-child {
    margin-top: 10px;
  }


`
export const TitleStyled = styled.div.attrs((prop) => ({
    id: 'poa_t'
}))`
  display: inline-block;
  padding-left: 5px;
`
export const ClientStyled = styled.div.attrs((prop) => ({
}))`
  margin-left: 20px;
  margin-bottom: 15px;
  color: ${props => props.theme.navigation.sideMenu.color};
  padding: 5px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.navigation.sideMenu.hoverColor};
    background: ${props => props.theme.navigation.sideMenu.hoverBgColor};
    border-radius: 5px;
  }

`
