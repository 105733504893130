import React, {useContext, useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Subscription} from 'rxjs';
import {useAppDispatch} from "../../../../app/hooks";
import {toggleSideNavOpen} from "../../../../features/ui/uiSlice";
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../../providers/environment/EnvironmentService';
import {MenuOrientation} from '../../../../providers/environment/types';
import {SessionContext} from "../../../../providers/session/SessionContext";
import {GuslUser, UserCohortsDTO} from '../../../../providers/session/types';
import {SystemContext} from "../../../../providers/system/SystemContext";
import {GuslThemeContext} from '../../../../providers/theme/GuslThemeProvider';
import {log} from "../../../../services/LogService";
import {isDefined} from '../../../../utils/TypeCheckers';
import {
    addImageSuffix,
    areMenusMediaTypeSensitive,
    constructUrl,
    matchMediaTypeWithRequiredAndSensitivity,
    RunOnceEffect,
    unSubscribe
} from "../../../../utils/Utils";
import {ActiveFeUsers} from '../../../active-fe-users/ActiveFeUsers';
import {ActiveUsers} from '../../../active-users/ActiveUsers';
import {CohortSelector} from '../../../cohort-selector/CohortSelector';
import {GuslReport} from "../../../common/gusl-report/GuslReport";
import {HeaderItemStyled} from "../../../common/tabbed-page/styled";
import {GuslHtmlBlock} from '../../../html-block/GuslHtmlBlock';
import {PowerOfAttorneySelector} from '../../../power-of-attorney/PowerOfAttorneySelector';
import {HeaderDTO, IMenuDTO, MediaType} from '../../../types';
import {MainHeaderStyled} from '../../styled';
import MainToolbarDropdown from './MainToolbarDropdown';
import {
    ActiveUsersPanelStyled,
    ContentPanelStyled,
    GradientLineStyled,
    HamburgerStyled,
    HamburgerWrapperStyled,
    HorizontalMenuStyled,
    LogoImageStyled, LogoWrapperStyled,
    NavBarContainerStyledStyled,
    NavbarStyled,
    SelectorsStyled,
    TickerMenuStyled,
    ToolbarMenuStyled
} from './styled';
import {MainToolbarProperties} from './types';
import sortByDisplayOrder from "./utils/sortByDisplayOrder";

const MainToolbar = (props: MainToolbarProperties): React.ReactElement => {
    const systemContext = useContext(SystemContext);
    const sessionContext = useContext(SessionContext);
    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)
    const isMobile = environmentContext.isMobileDevice();
    const dispatch = useAppDispatch();

    const [className] = useState('MainToolbar-' + new Date().getTime());
    const [headerData, setHeaderData] = useState<HeaderDTO | undefined>(undefined);
    const [userCohorts, setUserCohorts] = useState<UserCohortsDTO | undefined>(undefined);
    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType());
    const [hideHamburger, setHideHamburger] = useState<boolean>(environmentService.getEnvironment()?.hideHamburger || false);
    const [mediaTypeSensitive, setMediaTypeSensitive] = useState<boolean>(false);
    const [toolbarMediaTypeSensitive, setToolbarMediaTypeSensitive] = useState<boolean>(false);

    const [menuOrientation, setMenuOrientation] = useState<MenuOrientation>(MenuOrientation.LEFT);

    RunOnceEffect(() => {
        let menuOrientationSubscription: Subscription = environmentService.watchMenuOrientation().subscribe((value: MenuOrientation) => {
            setMenuOrientation(value)
        })
        return () => {
            unSubscribe(menuOrientationSubscription);
        }
    });
    const [hasPowerOfAttorney] = useState<boolean>(sessionContext.hasPowerOfAttorney());

    const code = "Position";
    const [logoUrl] = useState<string>(() => {
        return addImageSuffix(environmentService.getEnvironment()?.logoUrl, guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix) || ''
    });
    const [homePage] = useState<string>(() => {
        return environmentService.getEnvironment()?.homePage || ''
    });
    const [headerUrl] = useState<string>(() => {
        return environmentService.getEnvironment()?.headerURL || ''
    });
    const [toolbarMenus] = useState<IMenuDTO[]>(() => {
        const items = sortByDisplayOrder(systemContext.getSystemConfig()?.toolbarMenus || []);
        setToolbarMediaTypeSensitive(areMenusMediaTypeSensitive(items))

        log.debug(className, 'MSG001', 'toolbarMenus', items)
        return items;
    });

    const [menuGroups] = useState<IMenuDTO[]>(() => {
        const items = sortByDisplayOrder(systemContext.getSystemConfig()?.menuGroups || []);
        setMediaTypeSensitive(areMenusMediaTypeSensitive(items))
        log.debug(className, 'MSG001', 'menuGroups', items)
        return items;
    });
    const [componentActive, setComponentActive] = useState<boolean>(true);


    const [defaultRefreshRate, setDefaultRefreshRate] = useState<number>(-1);
    const [fuckingCounter, setFuckingCounter] = useState<number>(0);

    const [isSideNavOpen, setSideNavOpen] = useState<boolean>(false);

    RunOnceEffect(() => {
        let sideMenuSubscription: Subscription = environmentService.watchSideMenu().subscribe((menuOpen: boolean) => {
            setSideNavOpen(menuOpen)
        })
        return () => {
            unSubscribe(sideMenuSubscription);
        }
    });

    const getHeaderData = () => {
        sessionContext.get<HeaderDTO>(constructUrl(headerUrl, {id: code}))
            .then(response => {
                setHeaderData({...response.data})
                setFuckingCounter(new Date().getTime())
                if (response.data.refreshRate) {
                    if (defaultRefreshRate === -1 && response.data.refreshRate !== -1) {
                        setDefaultRefreshRate(response.data.refreshRate * 1000)
                    }
                }
            })
            .catch(error => {
                console.log('Error in getting header', error)
            })
    }

    useEffect(() => {
        if (headerUrl) {
            getHeaderData()
        }
    }, [headerUrl, sessionContext])

    useEffect(() => {
        let subscription: Subscription | undefined = undefined;
        if (defaultRefreshRate > 0) {
            subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
                if (nextRefreshRate !== -1 && defaultRefreshRate === nextRefreshRate) {
                    // if (componentActive) {
                    getHeaderData()
                    // }
                }
            })
            sessionContext.registerRefreshRate(defaultRefreshRate)
        }
        return () => {
            unSubscribe(subscription);
            sessionContext.unregisterRefreshRate(defaultRefreshRate)
        }
    }, [defaultRefreshRate]);

    RunOnceEffect(() => {
        setComponentActive(true);
        let subscription = sessionContext.watchLogin().subscribe((user: GuslUser | null) => {
            if (isDefined(user) && isDefined(user?.userCohorts)) {
                // @ts-ignore
                setUserCohorts(user.userCohorts)
            }
        })
        return () => {
            unSubscribe(subscription)
            setComponentActive(false);
        }
    })

    const renderLogo = (): React.ReactElement => {
        return <LogoWrapperStyled sidebarNavigation={props.sidebarNavigation}>
            <NavLink to={"/" + homePage}><LogoImageStyled src={logoUrl} alt="Logo"/></NavLink>
        </LogoWrapperStyled>
    }


    const renderHamburger = (): React.ReactElement => {
        if (hideHamburger && isMobile) {
            return <div style={{width: '20px'}}></div>
        }
        if (menuOrientation === MenuOrientation.LEFT) {
            return <HamburgerWrapperStyled id={'burger'}>
                <HamburgerStyled $isSideNavOpen={isSideNavOpen}
                                 onClick={() => {
                                     environmentService.toggleSideMenu();
                                     // MK 15/08/2023
                                     dispatch(toggleSideNavOpen());
                                 }}
                />
            </HamburgerWrapperStyled>
        }
        return <></>
    }

    const renderHorizontalMenuGroups = (): React.ReactElement => {
        if (menuOrientation === MenuOrientation.TOP) {
            return <>
                <HorizontalMenuStyled>
                    {menuGroups
                        .filter(menuGroup => menuGroup.showInSideBar)
                        .filter(menuGroup => matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, mediaTypeSensitive))
                        .filter(menuGroup => !menuGroup.footer)
                        .map((menuGroup, idx: any) =>
                            <MainToolbarDropdown key={'menugroup_' + idx} menuGroup={[menuGroup]}/>
                        )}
                </HorizontalMenuStyled>
            </>
        }
        return <></>
    }


    const renderPowerOfAttorney = (): React.ReactElement => {
        return (
            <>{hasPowerOfAttorney && <PowerOfAttorneySelector/>}</>
        )
    }
    const renderCohortSelector = (): React.ReactElement => {
        return (
            <>{userCohorts && <CohortSelector userCohorts={userCohorts}/>}</>
        )
    }

    const renderActiveUsers = (): React.ReactElement => {
        return (<ActiveUsers/>)

    }

    const renderFeUsers = (): React.ReactElement => {
        return (<ActiveFeUsers/>)

    }

    const renderTickers = (): React.ReactElement => {
        return <>
            <TickerMenuStyled>
            </TickerMenuStyled>
        </>
    }

    const renderToolbarMenus = (): React.ReactElement => {
        return <>
            <ToolbarMenuStyled>
                {toolbarMenus
                    .filter(toolbarMenu => !toolbarMenu.footer)
                    .filter(menuGroup => matchMediaTypeWithRequiredAndSensitivity(menuGroup.mediaType, mediaType, toolbarMediaTypeSensitive))
                    .map((toolbarMenu, idx: any) =>
                        <MainToolbarDropdown key={'toolbar_' + idx} menuGroup={[toolbarMenu]}/>
                    )}
            </ToolbarMenuStyled>
        </>
    }

    const renderHtmlBlock = (): React.ReactElement => {
        return <p>html block</p>
    }

    const renderHeaderDetail = (): React.ReactElement => {

        if (headerData?.htmlBlock) {
            return <GuslHtmlBlock htmlContent={headerData?.htmlBlock}/>
        } else if (headerData?.info) {
            return (
                <GuslReport
                    code={code + '_' + fuckingCounter + '_rep'}
                    key={code + '_' + fuckingCounter + '_rep'}
                    data={{...headerData.info}}
                />
            )
        }
        return <></>
    }

    const renderHeader = (): React.ReactElement => {
        if (!headerData || (!headerData?.info && !headerData?.htmlBlock)) {
            return <></>
        }

        return <MainHeaderStyled
            align={(headerData && headerData.align) ? headerData.align : "start"}
            key={code + '_' + fuckingCounter + '_hdr'}
        >
            <HeaderItemStyled key={code + '_' + fuckingCounter + '_hdr'}>
                {renderHeaderDetail()}
            </HeaderItemStyled>
        </MainHeaderStyled>
    }
    const renderNavBar = (): React.ReactElement => {
        return <NavBarContainerStyledStyled id={'one'}>
            {props.sidebarNavigation && renderHamburger()}
            {renderLogo()}
            <ContentPanelStyled key={code}>
                <SelectorsStyled>
                    {!props.tandCUpgradeRequired && renderCohortSelector()}
                    {!props.tandCUpgradeRequired && renderPowerOfAttorney()}
                </SelectorsStyled>
                <ActiveUsersPanelStyled>
                    {renderActiveUsers()}
                    {renderFeUsers()}
                </ActiveUsersPanelStyled>
                {renderHorizontalMenuGroups()}
                {!props.tandCUpgradeRequired && renderTickers()}
                {renderHeader()}
            </ContentPanelStyled>
            {!props.tandCUpgradeRequired && renderToolbarMenus()}
        </NavBarContainerStyledStyled>
    }

    if (environmentContext.isMobileDevice()) {
        return <></>
    }

    return (
        <NavbarStyled id={'navbar'}>
            {renderNavBar()}
            <GradientLineStyled><span/></GradientLineStyled>
        </NavbarStyled>
    );
}
export default MainToolbar;
