import React from 'react';
import {LoginPage} from '../../../pages/login/LoginPage';
import {
    BackgroundImageStyled,
    HeaderMenuItemStyled,
    HeaderStyled, LoginWrapperStyled,
    LogoStyled,
    MenuContainerStyled,
    NavStyled, SectionStyled
} from './styled';

export const OlivetreeLogin = (): React.ReactElement => {

    const renderHeaderBar = (): React.ReactElement => {
        return (
            <HeaderStyled>
                <NavStyled><a href="/src/static">
                    <LogoStyled
                        src="https://olivetree-development.olivetreeglobal.com/_astro/LogoOliveetreeBlanco.CL2ISH_H_Z1LkkO6.webp"
                        alt="Intertrader" width="540"
                        height="86" loading="lazy" decoding="async"/> </a>
                    <MenuContainerStyled>
                        <HeaderMenuItemStyled
                            href="https://olivetree-development.olivetreeglobal.com/">Home</HeaderMenuItemStyled>
                        <HeaderMenuItemStyled href="https://olivetree-development.olivetreeglobal.com/about">About
                            us</HeaderMenuItemStyled>
                        <HeaderMenuItemStyled href="https://olivetree-development.olivetreeglobal.com/careers"
                        >Careers</HeaderMenuItemStyled>
                        <HeaderMenuItemStyled href="https://olivetree-development.olivetreeglobal.com/contact"
                        >Contact</HeaderMenuItemStyled>
                        <HeaderMenuItemStyled href="https://olivetree-development.olivetreeglobal.com/contact"
                        >Login</HeaderMenuItemStyled>
                    </MenuContainerStyled>
                </NavStyled>
            </HeaderStyled>
        )
    }
    return (
        <div id={'olivetree-login'}>
            {/*{renderHeaderBar()}*/}
            <main>
                <SectionStyled>
                    <BackgroundImageStyled
                        src="/assets/images/cover_london.webp"
                        alt="hero" loading="lazy" decoding="async"/>
                    <LoginWrapperStyled>
                        <LoginPage noModal={true}/>
                    </LoginWrapperStyled>
                </SectionStyled>
            </main>
        </div>

    )


}
