import styled, {ThemeProps} from 'styled-components';

export const ActiveUsersStyled = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-center'
}))`
    margin-left: 30%;
`


export const UsersStyled = styled.div.attrs((props) => ({
    className: 'col-6'
}))`
    width:250px;
`

export const UserAvatarWrapperStyled = styled.div.attrs((props) => ({}))`
  text-align: center;
  vertical-align: middle;
`

export const UserAvatarStyled = styled.img.attrs((props) => ({}))`
  width: 26px;
  border-radius: 20px;
  //margin-right: -4px;
  margin: 0px auto;
  height: 26px;

`

export const UserInitialWrapperStyled = styled.div.attrs((props) => ({}))`
  width: 26px;
  border-radius: 50%;
  margin-right: -4px;
  background-color: ${props => props.theme.navigation.sideMenu.header.avatar.bgColor};
  color: ${props => props.theme.navigation.sideMenu.header.avatar.color};
  height: 26px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  
`

export const UserInitialStyled = styled.div.attrs((props) => ({
    className:'d-flex align-items-center justify-content-center'
}))`
// text-align: center;
// if you comment out below please let Grant know why
//    position: relative;
//    top: 6px;
    font-weight: bold;
  width: 26px;
  height: 26px  
`


interface BlastConnectedProps extends ThemeProps<any> {
    connected: boolean
}

export const BlastConnectedStyled = styled.div.attrs((props: BlastConnectedProps) => ({}))<BlastConnectedProps>`
    border-radius: 5px;
    background-color: red;
    color: white;
    margin: 5px;
    padding: 6px;
    veritcal-align:middle;
    font-size:10px;
    border-radius: 5px;
    background-color: #c10606;
    margin-left:40px;
    font-weight: bold;
`
