// -------------- config
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ColorPickerTogglePayload {
    code: string,
    show: boolean,
}

export interface ColorPickersState {
    code: string | undefined,
    show: boolean,
}

const initialState: ColorPickersState = {
    code: undefined,
    show: false,
}

export const colorPickersToggleSlice = createSlice({
    name: 'ColorPickersToggleSlice',
    initialState,
    reducers: {
        toggleColorPicker(state, action: PayloadAction<ColorPickerTogglePayload>) {
            if ((state.code && state.code === action.payload.code)) {
                state.show = false
                state.code = undefined
            } else {
                state.show = true
                state.code = action.payload.code
            }
        },
    },
})
export const {
    toggleColorPicker,
} = colorPickersToggleSlice.actions

export default colorPickersToggleSlice.reducer
