// @ts-nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {clone, compare, safeStream} from '../../../utils/Utils';
import {ActionConfigDTO, FieldConfigDTO, TableRowDTO} from '../../types';

// -------------- actions
export interface NestedFieldInitPayload {
    code: string,

    tableData: TableRowDTO[],
    fields: FieldConfigDTO[],

    actions?: ActionConfigDTO[]

    onChange: (name: string, value: any) => void
    onArrayChange?: ((parentName: string, childName: string, value: any, rowIndex: number) => void) | undefined

    getCurrentRowData: () => any
}

export interface NestedFieldCleanUpPayload {
    code: string
}

export interface DeleteRowPayload {
    code: string,
    rowIndex: number
}

export interface AddRowPayload {
    code: string,

    append: boolean,
}

export interface UpdatePayload {
    code: string,
    name: string,
    value: any,
    rowIndex: number,

}


// -------------- states
export interface MasterState {
    [id: string]: NestedFieldState
}

export interface NestedFieldState {
    code: string,
    tableData: TableRowDTO[]
    allFields: FieldConfigDTO[],

    showFields: FieldConfigDTO[],

    formFields: FieldConfigDTO[],
    actions: ActionConfigDTO[];

    onChange: (name: string, value: any) => void

    onArrayChange?: ((parentName: string, childName: string, value: any, rowIndex: number) => void) | undefined

    getCurrentRowData: () => any

    getRow: (index: number, data: TableRowDTO[]) => TableRowDTO

}

// -------------- utils
const getRowData = (index: number, data: TableRowDTO[]) => {
    console.log('getRowData =>', index, data)
    if (data?.length > index) {
        return data[index]
    }
    return undefined;
}
const loadInitValues = (values: NestedFieldInitPayload): NestedFieldState => {

    const data: TableRowDTO[] = safeStream(values.tableData).map((row, index: number) => {
        return {...row, actions: values.actions || [], rowIndex: index}
    }).sort((a, b) => compare(a.displayOrder, b.displayOrder))

    return {
        code: values.code,
        actions: values.actions || [],
        tableData: data,
        allFields: values.fields,
        showFields: values.fields.filter(fld => fld.displayInTable),
        formFields: values.fields.map(fld => {
            return {...fld, noLabel: true}
        }).filter(fld => fld.displayInTable),
        onChange: values.onChange,
        onArrayChange: values.onArrayChange,
        getCurrentRowData: values.getCurrentRowData,
        getRow: getRowData
    }
}

const addRowToTable = (entry: NestedFieldState, append: boolean) => {
    const newData: TableRowDTO[] = [];
    if (append) {
        entry.tableData.map((row, index: number) => newData.push(row))
        const row = {
            rowIndex: entry.tableData.length,
            id: '' + (entry.tableData.length),
            actions: entry.actions
        }
        newData.push(row)
    } else {
        newData.push({rowIndex: 0, id: '0', actions: entry.actions})
        entry.tableData.map((row, index: number) => newData.push({...row, id: '' + (index + 1)}))
    }
    entry.tableData = newData
    const currentRowData = entry.getCurrentRowData();
    currentRowData[entry.code] = newData
    entry.getRow = getRowData

    // entry.onChange(entry.code, newData)
}

const updateRowInTable = (entry: NestedFieldState, name: string, value: any, rowIndex: number) => {
    if (rowIndex >= 0 && rowIndex < (entry.tableData.length || 0)) {
        //const newRow = current(entry.tableData[rowIndex]);
        // const newRow: any = clone(entry.tableData[rowIndex])
        // newRow[name] = value
        //entry.tableData[rowIndex] = newRow

        // @ts-ignore
        //entry.tableData[rowIndex][name]=value

        const currentRowData = entry.getCurrentRowData();
        const r = {...currentRowData[entry.code][rowIndex]}
        r[name] = value
        const rows = clone(entry.getCurrentRowData()[entry.code])
        rows[rowIndex] = r

        const newRowData = clone(entry.getCurrentRowData())
        newRowData[entry.code] = rows
        entry.getCurrentRowData()[entry.code] = rows

    }
    // if (entry.onArrayChange) {
    //     entry.onArrayChange(entry.code, name, value, rowIndex)
    // }
}

const deleteRowFromTable = (entry: NestedFieldState, rowIndex: number) => {
    if (rowIndex >= 0 && rowIndex < (entry.tableData?.length || 0)) {
        entry.tableData = entry.tableData.splice(rowIndex, 1)
    }
    entry.onChange(entry.code, entry.tableData)
}


const createDefault = (code: string): NestedFieldState => {
    return {
        code: code,
        tableData: [],
        allFields: [],
        showFields: [],
        formFields: [],
        actions: [],
        onChange: () => {
        },
        onArrayChange: () => {
        },
        getCurrentRowData: () => {
            return {}
        },
        getRow: (index: number, data: TableRowDTO[]) => {
            return getRowData(index, data)
        }
    }
}

const getState = (state: MasterState, code: string): NestedFieldState => {
    let entry: NestedFieldState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry;
}

// -------------- config
const initialState: MasterState = {}
export const nestedFieldSlice = createSlice({
    name: 'nestedFieldSlice',
    initialState,
    reducers: {
        initField(state, action: PayloadAction<NestedFieldInitPayload>) {
            state[action.payload.code] = loadInitValues(action.payload)
        },
        cleanUpNestedField(state, action: PayloadAction<NestedFieldCleanUpPayload>) {
            delete state[action.payload.code]
        },
        addRow(state, action: PayloadAction<AddRowPayload>) {
            const entry: NestedFieldState = getState(state, action.payload.code)
            addRowToTable(entry, action.payload.append);
        },
        updateRow(state, action: PayloadAction<UpdatePayload>) {
            const entry: NestedFieldState = getState(state, action.payload.code)
            updateRowInTable(entry, action.payload.name, action.payload.value, action.payload.rowIndex);
            state[action.payload.code] = entry;
        },
        deleteRow(state, action: PayloadAction<DeleteRowPayload>) {
            const entry: NestedFieldState = getState(state, action.payload.code)
            deleteRowFromTable(entry, action.payload.rowIndex);
        }


    }
})

export const {
    initField,
    addRow,
    updateRow,
    deleteRow,
    cleanUpNestedField
} = nestedFieldSlice.actions

export default nestedFieldSlice.reducer
