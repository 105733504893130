import React, {useState} from "react";
import {IMenuDTO, WidgetPanelProperties} from '../../../types';
import MainToolbarDropdown from '../../../ui/navigation/navbar/MainToolbarDropdown';
import {ToolbarMenuStyled} from '../../../ui/navigation/navbar/styled';


interface MenubarProps {
    entityId?: string,
    menuGroups?: IMenuDTO[],
    widgetPanelProperties?: WidgetPanelProperties
}


const MenuBar = ({entityId, menuGroups,widgetPanelProperties}: MenubarProps): React.ReactElement => {
    const [className] = useState('Menubar-' + new Date().getTime());
    return (
        <ToolbarMenuStyled>
            {menuGroups?.map((menuGroup, idx: any) =>
                <MainToolbarDropdown key={'tb_' + entityId + '_' + idx} menuGroup={[menuGroup]} entityId={entityId} widgetPanelProperties={widgetPanelProperties}/>
            )}
        </ToolbarMenuStyled>
    )
}

export default MenuBar;
