import React from 'react';

interface SinglesTabProperties {
    onClose?: () => void

}

export const SinglesTab = ({}: SinglesTabProperties): React.ReactElement<SinglesTabProperties> => {

    return (
        <p>Singles - coming soon</p>
    )
}
