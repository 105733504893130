import {notDefined} from '../../../utils/TypeCheckers';
import {safeStream} from '../../../utils/Utils';
import {IMenuDTO} from '../../types';
import {TradeAction} from './TradePanel';


export const createMenuItem = (code: string, label: string, displayOrder: number): IMenuDTO => {
    return {
        code: code,
        label: label,
        displayOrder: displayOrder,
    } as IMenuDTO

}

export const createMenuItems = (properties: any): IMenuDTO[] => {
    const menus: IMenuDTO[] = []
    if (notDefined(properties)) {
        return menus;
    }

    switch (properties.action) {
        case  TradeAction.CloseOut:
        case  TradeAction.PositionInfo:
        case TradeAction.PositionNewOrder:
            menus.push(createMenuItem('NewOrder', 'New Order', 10))
            menus.push(createMenuItem('CloseOut', 'Close Out', 20))
            menus.push(createMenuItem('Info', 'Info', 30))
            break;
        case  TradeAction.NewOrder:
        case  TradeAction.WatchListInfo:
            menus.push(createMenuItem('NewOrder', 'New Order', 10))
            menus.push(createMenuItem('Info', 'Info', 10))
    }
    return menus;

}
export const selectActiveMenu = (menus: IMenuDTO[], properties: any): IMenuDTO | undefined => {
    let active: IMenuDTO | undefined = undefined
    safeStream(menus).forEach(menu => {
        switch (properties.action) {
            case  TradeAction.CloseOut:
                if (menu.label === 'Close Out') {
                    active = menu;
                }
                break
            case  TradeAction.WatchListInfo:
            case  TradeAction.PositionInfo:
                if (menu.label === 'Info') {
                    active = menu;
                }
                break;
            case  TradeAction.NewOrder:
            case  TradeAction.PositionNewOrder:
                if (menu.label === 'New Order') {
                    active = menu;
                }
                break;
        }
    })
    return active
}

