import React from "react";
import { MiniLoadingSpinnerStyled } from "../../../gusl-table/styled/table/styled";

export interface MiniLoadingSpinnerDO {
  isLoading: boolean | undefined;
}

export default function BSSpinner({ isLoading }: MiniLoadingSpinnerDO) {

  return (
    isLoading ? <MiniLoadingSpinnerStyled>
      <div className="">
        {/*@ts-ignore*/}
        <i className="fa-solid fa-circle fa-beat"
          /*@ts-ignore*/
           style={{ "--fa-animation-duration": "1.5s", "--fa-beat-scale": "0", color: "#1FBB59" }}></i>
        {/*@ts-ignore*/}
        <i className="fa-solid fa-circle fa-beat mx-2"
          /*@ts-ignore*/
           style={{ "--fa-animation-duration": "2s", "--fa-beat-scale": "0", color: "#03B2FF" }}></i>
        {/*@ts-ignore*/}
        <i className="fa-solid fa-circle fa-beat"
          /*@ts-ignore*/
           style={{ "--fa-animation-duration": "2.5s", "--fa-beat-scale": "0", color: "#FF8F14" }}></i>
      </div>
    </MiniLoadingSpinnerStyled> : <span>&nbsp;</span>
  );
}
