import {Modal} from 'react-bootstrap';
import styled from 'styled-components';

export const ActionDialogTitleStyled = styled(Modal.Title).attrs(props => ({}))`
  color: ${props => props.theme.panel.titleColor} !important;
  font-size: ${props => props.theme.panel.titleFontSize};
`
export const ActionDialogHeaderStyled = styled(Modal.Header).attrs(props => ({
    id:'action_dialog_hdr',
    className:'draggable-dialog-header'
}))`
    background-color: ${props => props.theme.panel.titleBgColor} !important;
`

export const ActionDialogBodyStyled = styled(Modal.Body).attrs(props => ({
    id:'action_dialog_body'
}))`
  background-color: ${props => props.theme.colors.background[1]} !important;
  padding: 0px !important;
`
