import styled, {ThemeProps} from 'styled-components';
import {DateRangeUnitProperties} from "../date-range/styled";

export interface FromToSwitchItemProperties extends ThemeProps<any> {
    active?: boolean | undefined,
    className?: string,
    onClick?: Function,

}

export interface SelectorProperties extends ThemeProps<any> {
    active?: boolean | undefined,
    className?: string,
    onClick?: Function,

}

export interface CalendarDirectionProps extends ThemeProps<any> {

    className?: string,


}

export const DateRangeUnitSelectorStyled = styled.span.attrs((props: DateRangeUnitProperties) => ({
    onClick: props.onClick,
    className: 'col'
}))<DateRangeUnitProperties>`
      color: ${props => props.theme.colors.dark[1]};
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    background-color:${props => props.theme.colors.dark[0]};
    font-size:13px;
    margin:5px 0 0 0;
    border-bottom: 2px solid ${props => props.active ? props.theme.colors.dark[1] : 'transparent'};
    padding:0 10px;
    text-align:center;
     :hover{
                cursor:pointer;
                font-weight:bold;
               
               
            }
`
export const UnitsWrapperStyled = styled.div.attrs((props: DateRangeUnitProperties) => ({
    className: props.className
}))<DateRangeUnitProperties>`
   
    margin-top: 5px;
    margin-bottom:25px;
    border-bottom: 1px solid ${props => props.theme.colors.accentColorMuted};
`

export const TextUnitsWrapperStyled = styled.div.attrs((props) => ({}))`
    padding: 10px 5px;
`

export const CalendarItemStyled = styled.div.attrs((props: FromToSwitchItemProperties) => ({
    role: 'button',
    className: '',
    onClick: props.onClick
}))<FromToSwitchItemProperties>`
   
    border: 3px solid  ${props => props.theme.colors.dark[0]};
    height: 30px;
    width: 30px;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    backgroundColor: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.dark[1]};
    :hover{
        background-color: ${props => props.theme.colors.accentColorLight};
        color: ${props => props.theme.colors.dark[0]};
    }
`
export const TextUnitStyled = styled.div.attrs((props: SelectorProperties) => ({
    role: 'button',
    className: '',
    onClick: props.onClick
}))<SelectorProperties>`
   
    text-align:start;
    padding-left:5px;
    color: ${props => props.theme.colors.dark[1]};
    :hover{
        background-color: ${props => props.theme.colors.accentColorLight};
        color: ${props => props.theme.colors.dark[0]};
    }
`
export const CalendarItemNumberStyled = styled.div.attrs((props) => ({}))`
  
    font-size:12px;
    font-weight:bold;
    padding: 0 3px;
    margin:0;
   
`
export const CalendarDirectionStyled = styled.p.attrs((props:CalendarDirectionProps) => ({
    className:props.className
}))<CalendarDirectionProps
    >`
    text-align:center;
    font-weight:bold;  
`
export const FromToSwitchStyled = styled.p.attrs((props) => ({}))`
    color:${props => props.theme.colors.dark[1]};
    font-weight: bold;  
    margin-left:10px;
    margin-top:4px;
  
`
export const FromToSwitchItemStyled = styled.span.attrs((props: FromToSwitchItemProperties) => ({
    onClick: props.onClick,
    role: 'button',
}))<FromToSwitchItemProperties>`
    color:${props => props.isActive ? props.theme.colors.dark[1] : props.theme.colors.accentColorMuted};
    margin: 5px 5px 0 0;
   
`

export const CustomDateCalendarStyled = styled.div.attrs((props) => ({
    className:'row g-0 d-flex justify-content-between align-items-center py-1'
}))`
    background-color:${props => props.theme.colors.background[1]};
`
