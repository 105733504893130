import {Subject} from 'rxjs'
import {log} from '../../services/LogService';
import {isFunction, isJson, isString} from '../../utils/TypeCheckers';
import {BLAST_VERSION, BlastServiceConfig} from './types'

interface MessageHandler {
    messageHandler: Function,
    service: any
}

export class BlastService {
    private reconnectAttempts = 0
    private sendQueue: any[] = []
    private onMessageCallbacks: any[] = []
    private onOpenCallbacks: Function[] = []
    private onErrorCallbacks: Function[] = []
    private onCloseCallbacks: Function[] = []
    private readyStateConstants = {
        CONNECTING: 0,
        OPEN: 1,
        CLOSING: 2,
        CLOSED: 3,
        RECONNECT_ABORTED: 4,
    }

    private normalCloseCode = 1000
    private reconnectedStatusCodes = [4000]
    private socket: WebSocket | undefined
    private readonly dataStream: Subject<any>
    private internalConnectionState: number | undefined
    private _handlers: MessageHandler[] = []

    constructor(
        private url: string,
        connectNow?: boolean,
        private protocols?: Array<string>,
        private readonly config?: BlastServiceConfig,
    ) {
        const match = new RegExp('wss?://').test(url)
        if (!match) {
            throw new Error('Invalid url provided')
        }
        this.config = config || {
            initialTimeout: 500,
            maxTimeout: 300000,
            reconnectIfNotNormalClose: true,
        }
        this.dataStream = new Subject()

        if (connectNow === undefined || connectNow) {
            this.connect(true)
        }
    }

    public connect = (force = false) => {
        const self = this
        if (
            force ||
            !this.socket ||
            this.socket.readyState !== this.readyStateConstants.OPEN
        ) {
            self.socket = this.protocols
                ? new WebSocket(this.url, this.protocols)
                : new WebSocket(this.url)

            self.socket.onopen = (ev: Event) => {
                this.onOpenHandler(ev)
            }
            self.socket.onmessage = (ev: MessageEvent) => {
                if (isJson(ev.data)) {
                    const message = JSON.parse(ev.data)
                    log.debug(
                        'BlastService',
                        'MSG001',
                        'jsonMessage',
                        'passing to handlers',
                        ev.data,
                    )

                    // call a json message handler - if true, then message handled mustn't carry on
                    if (self.handleJsonMessage(message)) {
                        return
                    }
                }
                self.onMessageHandler(ev.data)
                this.dataStream.next(ev.data)
            }

            self.socket.onclose = (ev: CloseEvent) => {
                self.onCloseHandler(ev)
            }

            self.socket.onerror = (ev: Event) => {
                log.info('BlastService', 'ERR001', 'Error connecting',ev)
                log.flushLogs()
                if (ev instanceof ErrorEvent) {
                    self.onErrorHandler(ev)
                    this.dataStream.error(ev)
                }
            }
        }
    }

    /**
     * Run in Block Mode
     * Return true when can send and false in socket closed
     * @param data
     * @param binary
     * @returns
     */
    private sendMessage(data: any, binary?: boolean): boolean {
        const self = this
        if (
            this.getReadyState() !== this.readyStateConstants.OPEN &&
            this.getReadyState() !== this.readyStateConstants.CONNECTING
        ) {
            this.connect()
        }
        log.debug('BlastService', 'MSG002', 'sendMessage', data)
        self.sendQueue.push({message: data, binary: binary})
        if (self.socket) {
            if (self.socket.readyState === self.readyStateConstants.OPEN) {
                self.fireQueue()
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    send(data: any, binary?: boolean): any {
        return this.sendMessage(data, binary)
    }

    getDataStream(): Subject<any> {
        return this.dataStream
    }

    notifyOpenCallbacks(event: Event) {
        for (let i = 0; i < this.onOpenCallbacks.length; i++) {
            this.onOpenCallbacks[i].call(this, event)
        }
    }

    fireQueue() {
        if (this.socket) {
            while (
                this.sendQueue.length &&
                this.socket.readyState === this.readyStateConstants.OPEN
                ) {
                const data = this.sendQueue.shift()

                if (data.binary) {
                    this.socket.send(data.message)
                } else {
                    this.socket.send(
                        isString(data.message)
                            ? data.message
                            : JSON.stringify(data.message),
                    )
                }
            }
        }
    }

    notifyCloseCallbacks(event: Event) {
        for (let i = 0; i < this.onCloseCallbacks.length; i++) {
            this.onCloseCallbacks[i].call(this, event)
        }
    }

    notifyErrorCallbacks(event: Event) {
        for (let i = 0; i < this.onErrorCallbacks.length; i++) {
            this.onErrorCallbacks[i].call(this, event)
        }
    }

    onOpen(cb: Function) {
        this.onOpenCallbacks.push(cb)
        return this
    }

    onClose(cb: Function) {
        this.onCloseCallbacks.push(cb)
        return this
    }

    onError(cb: Function) {
        this.onErrorCallbacks.push(cb)
        return this
    }

    onMessage(callback: Function, options?: { filter: any; autoApply: any }) {
        if (!isFunction(callback)) {
            throw new Error('Callback must be a function')
        }

        this.onMessageCallbacks.push({
            fn: callback,
            pattern: options ? options.filter : undefined,
            autoApply: options ? options.autoApply : true,
        })
        return this
    }

    public registerMessageHandler(handler: Function, that: any) {
        this._handlers.push({messageHandler: handler, service: that})
    }

    /* eslint-enable @typescript-eslint/no-unused-vars */
    handleJsonMessage(message: any): boolean {
        let passed: boolean = false
        this._handlers.forEach(handler => {
            if (!passed) {
                const messageHandler = handler.messageHandler.bind(handler.service);
                passed = messageHandler(message)
            }
        })
        // as a default return false i.e. don't change message flow
        // enables extended classes to override this function
        return passed
    }

    onMessageHandler(message: MessageEvent) {
        log.debug('BlastService', 'MSG003', 'onMessageHandler', message.data)
        const self = this
        let currentCallback
        for (let i = 0; i < self.onMessageCallbacks.length; i++) {
            currentCallback = self.onMessageCallbacks[i]
            currentCallback.fn.apply(self, [message])
        }
    }

    onOpenHandler(event: Event) {
        log.debug('BlastService', 'MSG004', 'connected')
        this.reconnectAttempts = 0
        this.notifyOpenCallbacks(event)
        this.fireQueue()
    }

    onCloseHandler(event: CloseEvent) {
        log.debug('BlastService', 'MSG005', 'closed')
        this.notifyCloseCallbacks(event)
        if (
            (this.config &&
                this.config.reconnectIfNotNormalClose &&
                event.code !== this.normalCloseCode) ||
            this.reconnectedStatusCodes.indexOf(event.code) > -1
        ) {
            console.log('-- try reconnect --')
            this.reconnect()
        } else {
            this.sendQueue = []
            this.dataStream.complete()
        }
    }

    onErrorHandler(event: ErrorEvent) {
        log.debug('BlastService', 'MSG006', 'onErrorHandler', event)
        this.notifyErrorCallbacks(event)
    }

    reconnect() {
        this.close(true)
        const backoffDelay = this.getBackoffDelay(++this.reconnectAttempts)
        //         let backoffDelaySeconds = backoffDelay / 1000;
        //         // console.log('Reconnecting in ' + backoffDelaySeconds + ' seconds');
        log.debug('BlastService', 'MSG007', 'reconnectDelay', backoffDelay)
        setTimeout(() => this.connect(), backoffDelay)
        return this
    }

    close(force: boolean = false): void {
        if (this.socket) {
            if (force || !this.socket.bufferedAmount) {
                try {
                    this.socket?.close(this.normalCloseCode)
                    this.socket?.close()
                    // @ts-ignore
                    this.socket = null
                    console.log('-------- this socket closed ------')
                } catch (error) {
                    console.log('error', error)
                }
            }
        }
    }

    getBackoffDelay(attempt: number) {
        const R = Math.random() + 1
        const T = this.config ? this.config.initialTimeout : 60
        const F = 2
        const N = attempt
        const M = this.config ? this.config.maxTimeout : 60

        return Math.floor(Math.min(R * T * Math.pow(F, N), M))
    }

    // setInternalState(state: number) {
    //   if (Math.floor(state) !== state || state < 0 || state > 4) {
    //     throw new Error('state must be an integer between 0 and 4, got: ' + state)
    //   }
    //   this.internalConnectionState = state
    // }

    getReadyState(): number {
        if (this.socket == null) {
            return -1
        }
        return this.internalConnectionState || this.socket.readyState
    }

    getVersion(): string {
        return BLAST_VERSION
    }


}
