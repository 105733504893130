import {
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import React, {useEffect, useRef, useState} from 'react';
import {Radar} from 'react-chartjs-2';
import {log} from "../../../services/LogService";
import ErrorMsg from "../../ui/ErrorMsg";
import {ChartProps} from "../types";
import prepareChartData from "../utils/PrepareChartData";


ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Title
);
type Props = {
    chartFor: string,
    apiSlice: string,

}


export default function RadarChart({endpoint, serverData, chartFor}: ChartProps) {

    const [className] = useState('RadarChart-' + new Date().getTime());
    const chartRef = useRef<ChartJS>(null);
    const [chartData, setChartData] = useState({datasets: [], labels: []});
    const _options = serverData?.chartOptions?.radar
    const [options, setOptions] = useState(_options);
    const _height = serverData?.chartOptions?.radar?.chart?.canvas.height
    const [height, setHeight] = useState<string | number | undefined>(_height);

    try {
        useEffect(() => {

            let chart = chartRef.current;
            if (!chart) {
                return;
            }

            const {datasets, options, labels, canvasHeight} = prepareChartData({
                chartType: 'radar',
                endpoint,
                serverData,
                chart
            })
            // @ts-ignore
            setChartData({labels, datasets});
            setOptions(options);
            setHeight(canvasHeight)
       }, [chartFor,JSON.stringify(serverData)]);

        //  const height : number|string|undefined = options.chart.canvas.parentNode.style.height;


        // @ts-ignore
        return <Radar ref={chartRef} data={chartData} options={options} type={'radar'} height={height}/>;
    } catch (e) {
        log.error(className, 'MSG001', {endpoint, error: e})
        return <ErrorMsg/>
    }

}
