import {RELEASE_DETAILS} from '../features/release-details';
import {IEnvironment} from '../providers/environment/types'

const baseUrl = window.location.hostname;
export const Environments: IEnvironment[] = [
    {
        name: 'default',
        production: false,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'https://haproxy-development.alvarfinancial.dev/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'bo-local',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://' + baseUrl + ':8080/backoffice/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'fe-local',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://' + baseUrl + ':8080/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'bo-gusl',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://89.163.146.165:8080/backoffice/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'fe-gusl',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://89.163.146.165:8080/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'allQA',
        production: false,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://' + baseUrl + ':8080/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'backoffice-local',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://' + baseUrl + ':8080/backoffice/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'local',
        production: false,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://' + baseUrl + ':8080/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'local-split',
        production: false,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://' + baseUrl + ':8400/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'gusl-backoffice',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://89.163.146.165:8300/backoffice/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },

    {
        name: 'gusl',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://gusldevtwo:8400/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'prod',
        production: true,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'https://api.supernovasports.dev/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    },
    {
        name: 'dan',
        production: false,
        apiKey: 'AABBCC123',
        authorisation: 'Basic YWRtaW46MTIzNDU2NyE=',
        apiBase: 'http://local.supernovasports.com:8400/edge/rest',
        VERSION: RELEASE_DETAILS.releaseNumber,
    }

];
