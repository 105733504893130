import {ClientAuthError} from 'msal';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
// @ts-ignore
import logo from '../../assets/icons/microsoft.svg'
import {RELEASE_DETAILS} from '../../features/release-details';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {guslStorage} from '../../providers/session/GuslStorage';
import {SessionContext} from '../../providers/session/SessionContext';
import {SignInResponseDTO} from '../../providers/session/types';
import {log} from '../../services/LogService';
import {FormErrorMessageStyled, LoginButtonStyled, VersionStyled} from './styled';
import {LoginProps} from './types';

const LoginMsalSSO = (props: LoginProps): React.ReactElement => {

    const environmentContext = React.useContext(EnvironmentContext)
    const sessionContext = React.useContext(SessionContext)
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    // const [loggedIn,setLoggedIn] = useState<boolean>(false);
    const [handlingLogin, setHandlingLogin] = useState<boolean>(false);

    // RunOnceEffect(() => {
    //     let subscription = sessionContext.watchLogin().subscribe((user: GuslUser | null) => {
    //         if (isDefined(user) && notBlank(user?.username) && !handlingLogin) {
    //             navigate('/' + (environmentService.getEnvironment()?.homePage || ''))
    //         }
    //     });
    //     return () => {
    //         unSubscribe(subscription)
    //     }
    //
    // });

    const handleLogin = () => {
        let msalInstance = sessionContext.getMsalInstance();
        setHandlingLogin(true);

        if (msalInstance) {
            setErrorMessage(undefined)
            if (environmentContext.isMsalRedirect()) {
                msalInstance.loginRedirect({
                    scopes: environmentService.getEnvironment()?.msalScopes || []
                })
            } else {
                msalInstance.loginPopup({
                    scopes: environmentService.getEnvironment()?.msalScopes || []
                }).then((response) => {
                    if (response.account?.userName) {
                        guslStorage.setMsalUserName(response.account.userName);
                    }
                    if (response.account?.name) {
                        guslStorage.setMsalName(response.account.name);
                    }

                    guslStorage.setMsalToken(response?.idToken?.rawIdToken || '')

                    sessionContext.loginWithMsalSsoToken(response?.account?.name, response?.account?.userName, response?.idToken?.rawIdToken) // response
                        .then((response: SignInResponseDTO) => {
                            log.debug('Login', 'MSG006', 'Login response:', response);
                            console.log('navigate to ->', environmentService.getEnvironment()?.homePage)
                            navigate('/' + (environmentService.getEnvironment()?.homePage || ''))
                        }).catch((error: any) => {
                        setErrorMessage('Error in MSAL SSO login (' + error + ')');
                        setHandlingLogin(false);
                    });

                }).catch((e: ClientAuthError) => {
                    try {
                        if (e) {
                            if (e.errorMessage.startsWith('Login_In_Progress')) {
                                setErrorMessage("Error: Already have a 'Sign in' popup open")

                            } else {
                                setErrorMessage('Error: ' + e.errorMessage)
                            }
                        } else {
                            setErrorMessage('Error during sign in')
                        }
                    } catch (error) {
                        console.log('--------- error', error)
                        setErrorMessage('Error during sign in')
                    }
                    setHandlingLogin(false);
                });
            }
        } else {
            setErrorMessage('MSAL not configured')
        }
    }

    const clearSession = () => {
        sessionContext.clearSession();
    };

    return (
        <div style={{"textAlign": "center"}}>
            <LoginButtonStyled onClick={() => handleLogin()} className="button-login">
                <img src={logo} alt="microsoft login"
                     className="icon small-login-logo"/>
                <span className="buttonText">Sign in with Microsoft</span>
            </LoginButtonStyled>
            {errorMessage && <FormErrorMessageStyled>{errorMessage}</FormErrorMessageStyled>}
            <VersionStyled onClick={() => clearSession()}>V: {RELEASE_DETAILS.releaseNumber}</VersionStyled>
        </div>
    );
}

export default LoginMsalSSO;

