export const BAR_DEFAULT_OPTIONS = {
    scales: {
        x: {
            display:true,
            ticks: {
                color: '#fafafa',
                font: {
                    size: 10
                }
            },
            grid: {
                display: false
            }
        },
        y: {
            display:true,
            ticks: {
                color: '#fafafa',
                font: {
                    size: 10
                }
            },
            grid: {
                display: false
            }
        }
    },
    chart: {canvas: {parentNode: {style: {height: '500px'}}}},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: true,
            labels: {
                boxWidth: 8,
                boxHeight:8,
                // color: '#fafafa',
                // usePointStyle:true,
                // rotation:30
                // pointStyle:'circle',
                font:{size:10}
            }
        },
        title: {
            display: false,
        },
    },

    barThickness: 5,
    maxBarThickness: 18,
    borderBlockColor:'#ff0000'


}
