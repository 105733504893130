import styled, {ThemeProps} from 'styled-components';
import {getTextAlign} from '../../../utils/CssUtils';
import Checkbox from '../maintain-table/bootstrap/Checkbox';
import {ColumnFieldProperties} from '../maintain-table/styled';

export const CheckboxFieldStyled = styled.div.attrs((props: ColumnFieldProperties) => ({}))<ColumnFieldProperties>`
  text-align: ${props => getTextAlign(props.textAlign)};

`

interface CheckboxStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}


export const CheckboxStyled = styled(Checkbox).attrs((props: CheckboxStyledProps) => ({
    className: "form-control shadow mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<CheckboxStyledProps>`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`

interface InputCheckboxStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
    readonly: boolean
}


export const InputCheckboxStyled = styled.input.attrs((props: InputCheckboxStyledProps) => ({}))<InputCheckboxStyledProps>`
  color: ${props => props.theme.table.columnColor} !important;
  appearance: auto;
  height: 12px !important;
  filter: ${props => {
    if (props.readonly) {
      return 'grayscale(100%)'
    } else {
      return 'none'
    }
  }};
`

interface CheckboxWrapperStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}

export const CheckboxWrapperStyled = styled.div.attrs((props: CheckboxWrapperStyledProps) => ({
    className: "form-control  mb-1 bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<CheckboxWrapperStyledProps>`
`
