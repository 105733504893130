import {Editor} from 'grapesjs';
import {GuslUiWrapper} from './GuslUiWrapper';

export const ListingComponent = (editor: Editor, options = {}) => {
    editor.Components.addType('GuslUiComponent', {
        extend: 'react-component',
        model: {
            defaults: {
                component: GuslUiWrapper,
                stylable: true,
                resizable: true,
                editable: true,
                draggable: true,
                droppable: true,
                attributes: {
                    mlsid: 'Default MLSID',
                    editable: true
                },
                traits: [
                    {
                        type: 'number',
                        label: 'MLS ID',
                        name: 'mlsid'
                    }
                ]
            }
        },
        isComponent: (el) => {
            // console.log('el', el,el.tagName,el.tagName === 'LISTING');
            // return el.tagName === 'LISTING'
            console.log('el', el, el.tagName, el.tagName === 'GUSLUICOMPONENT');
            return el.tagName === 'GUSLUICOMPONENT'
        }
    });

    editor.BlockManager.add('GuslUiComponent', {
        label: "<div class='gjs-fonts gjs-f-b1'>Demo</div>",
        category: 'React Components',
        content: `<DemoComponent data-gjs-type="GuslUiComponent"></DemoComponent>`
    });

    // content: '<Listing>FooBar</Listing>'

    // editor.Blocks.add('Listing', {
    //     label: "<div class='gjs-fonts gjs-f-b1'>Listing</div>",
    //     category: 'React Components',
    //     content: '<Listing data-gjs-type="Listing">Foo</Listing>'
    // });

    // editor.BlockManager.add('Listing', {
    //     label: "<div class='gjs-fonts gjs-f-b1'>Listing</div>",
    //     category: 'React Components',
    //     content: '<Listing data-gjs-type="Listing">Foo</Listing>'
    // });
};
