import {BehaviorSubject, Observable} from 'rxjs';

interface RefreshRateRecord {
    refreshRate: number;
    numberOfSubscribers: number;

    interval: NodeJS.Timer;
}

class RefreshService {

    private _refreshRecords: { [id: number]: RefreshRateRecord } = {}

    private _subject: BehaviorSubject<number> = new BehaviorSubject<number>(-1)


    public registerRefreshRate(refreshRate: number) {
        if (refreshRate < 1) {
            return;
        }
        let record: RefreshRateRecord = this._refreshRecords[refreshRate]
        if (!record) {
            record = {
                refreshRate: refreshRate, numberOfSubscribers: 0,
                interval: setInterval(() => {
                    this._subject.next(refreshRate)
                }, refreshRate)
            }
            this._refreshRecords[refreshRate] = record
        }
        record.numberOfSubscribers++
    }

    public unregisterRefreshRate(refreshRate: number) {
        if (refreshRate < 1) {
            return;
        }
        let record: RefreshRateRecord = this._refreshRecords[refreshRate]
        if (!record) {
            return
        }
        record.numberOfSubscribers--
        if (record.numberOfSubscribers < 1) {
            try {
                clearInterval(record.interval)
                delete this._refreshRecords[refreshRate]

            } catch (error) {
                console.log('error', error)
            }
        }
    }

    public watchRefreshRate(): Observable<number> {
        return this._subject.asObservable()
    }


}

const refreshService = new RefreshService();

export {refreshService};

export default RefreshService;
