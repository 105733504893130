import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IMenuDTO} from "../../components/types";

export interface IsActiveDO {
    isActive?: boolean;
}

export interface HandleSiteTabsPayload {
    item: IMenuDTO & IsActiveDO;
    action?: string;

}

export interface QuickAccessPayload {
    open: boolean;

}

export interface ChangeFieldPayload {
    name: string;
    value: any
}


// Define a type for the slice state
interface UiState {
    theme: string,
    customTheme: boolean,
    splitScreen: boolean,
    siteTabs: IMenuDTO[],
    activeTab: IMenuDTO,
    quickAccessOpen: boolean,

    // MK 15/08/2023
    isSideNavOpen: boolean,
    sideNavClosedAt: number,
    // END OF 15/08/2023
    formData: any,

    // MK 31/08/2024
    topSearchClosedAt: number,
    filtersClosedAt: number,
    columnSettingsClosedAt: number,


}

// Define the initial state using that type
const initialState: UiState = {

    theme: 'default',
    customTheme: false,
    splitScreen: false,
    siteTabs: [],
    activeTab: {label: '', displayOrder: 0},
    quickAccessOpen: false,
    // MK 15/08/2023
    isSideNavOpen: true,
    sideNavClosedAt: 0,
    formData: undefined,

    // MK 31/08/2024
    topSearchClosedAt: 0,
    filtersClosedAt: 0,
    columnSettingsClosedAt: 0,
}

export interface MakeTabActiveDO {
    state: UiState,
    currentItem: HandleSiteTabsPayload['item'],
    action?: string | undefined
}

function makeTabActive({state, currentItem, action}: MakeTabActiveDO) {
    if (action === "add" || action === "alreadyIn") {
        makeAllTabsInactive(state);
    }
    const current = state.siteTabs.filter((tab: HandleSiteTabsPayload['item']) => tab.code === currentItem.code)[0];
    const indexOfCurrent = state.siteTabs.indexOf(current);
    if (action === 'remove') {
        const floatingIndex: number = indexOfCurrent === 0 ? 0 : indexOfCurrent - 1;
        state.siteTabs = state.siteTabs.filter((tab) => tab.code !== currentItem.code)
        if (currentItem.isActive) {
            state.siteTabs[floatingIndex] = {...state.siteTabs[floatingIndex], ...{isActive: true}};
        }
    } else {
        state.siteTabs[indexOfCurrent] = {...current, ...{isActive: true}};
    }
}

function makeAllTabsInactive(state: UiState) {
    Array.from({length: state.siteTabs.length}).forEach((_, idx) =>
        state.siteTabs[idx] = {...state.siteTabs[idx], ...{isActive: false}}
    )
}

export const uiSlice = createSlice({
    name: 'uiSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        setCustomTheme(state, action: PayloadAction<boolean>) {
            state.customTheme = action.payload;
        },

        setTheme(state, action: PayloadAction<string>) {
            state.theme = action.payload;
        },
        toggleSplitScreen(state) {
            state.splitScreen = !state.splitScreen;
        },
        handleSiteTabs(state, action: PayloadAction<HandleSiteTabsPayload>) {
            const currentItem = action.payload.item;
            const alreadyIn = state.siteTabs.filter((tab) => tab.code === currentItem.code).length > 0;
            if (action.payload.action === 'first') {
                state.siteTabs = [currentItem];
            } else if (action.payload.action === 'add' && !alreadyIn) {
                state.siteTabs.push(currentItem); // appends new tab to the end
                //state.siteTabs.unshift(currentItem); // appends new tab at the start
                makeTabActive({state, currentItem, action: 'add'});
            } else if (action.payload.action === 'remove') {
                makeTabActive({state, currentItem, action: 'remove'});
            } else if (action.payload.action === 'replace') {

                const currentActive = state.siteTabs.filter((tab: HandleSiteTabsPayload['item']) =>
                    tab.isActive)[0];

                const indexOfActiveTab = state.siteTabs.indexOf(currentActive);
                makeAllTabsInactive(state);

                state.siteTabs[indexOfActiveTab < 0 ? (state.siteTabs.length - 1) : indexOfActiveTab] =
                    {...action.payload.item, ...{isActive: true}};

            } else if (alreadyIn) {
                makeTabActive({state, currentItem, action: 'alreadyIn'});
            }
        },

        setQuickAccessOpen(state, action: PayloadAction<QuickAccessPayload>) {
            state.quickAccessOpen = action.payload.open;
        },

        // MK 15/08/2023  when sidenav is open and then when you flip
        // between the tables and then close again, table stays with reduced width due to
        // side nav open => hence watching for closing and refreshing in ListView.tsx
        toggleSideNavOpen(state) {
            if (state.isSideNavOpen) {
                state.sideNavClosedAt = new Date().getTime();
            }
            state.isSideNavOpen = !state.isSideNavOpen;

        },
        sidePanelClosed(state){
            state.sideNavClosedAt = new Date().getTime();
        },

        // MK 31/08/2024
        topFiltersClosed(state){
            state.topSearchClosedAt = new Date().getTime();
        },
        filtersClosed(state){
            state.filtersClosedAt = new Date().getTime();
        },
        columnSettingsClosed(state){
            state.columnSettingsClosedAt = new Date().getTime();
        }
    }
})

export const {
    setTheme,
    setCustomTheme,
    toggleSplitScreen,
    handleSiteTabs,
    setQuickAccessOpen,
    toggleSideNavOpen,
    sidePanelClosed,

    filtersClosed,
    columnSettingsClosed
} = uiSlice.actions


export default uiSlice.reducer

