import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {handleSiteTabs, IsActiveDO, setQuickAccessOpen} from "../../../../features/ui/uiSlice";
import {SystemContext} from "../../../../providers/system/SystemContext";
import {log} from "../../../../services/LogService";
import {isElementOverFlown} from "../../../../utils/CssUtils";
import {ElementWithTooltip} from "../../../common/element-with/element-with-tooltip/ElementWithTooltip";
import {CloseTabIconStyled} from "../../../common/gusl-table/styled/split-list-view/styled";
import {
    OpenTabStyledV2,
    OpenTabWrapperV2,
    QuickAccessBtnStyled,
    TabsHeaderStyledV2
} from "../../../common/gusl-table/styled/tabs/styled";
import {maintainTableService} from "../../../common/maintain-table/MaintainTableService";
import {IMenuDTO} from "../../../types";

type NavigationHandlerDO = {
    tab: IMenuDTO & IsActiveDO,
}
type RemoveOpenTabHandlerDO = {
    tab: IMenuDTO & IsActiveDO,
    idx: number,

}
export default function SiteTabsV2() {

    const [className] = useState('SiteTabs-' + new Date().getTime());
    const dispatch = useAppDispatch();
    const systemContext = useContext(SystemContext);
    const navigate = useNavigate();
    const siteTabs: (IMenuDTO & IsActiveDO)[] = useAppSelector(state => state.uiSlice.siteTabs);
    const currentCode = maintainTableService.getCodeFromUrl();
    const [animate, setAnimate] = useState<boolean>(false);
    const quickAccessOpen = useAppSelector(state => state.uiSlice.quickAccessOpen);

    log.info(className,'MSG001',{animate})

    const [menuItems] = useState<(IMenuDTO & IsActiveDO)[]>(() => {
        return maintainTableService.extractMenuItemsFromMenuGroups(systemContext.getSystemConfig()?.menuGroups || []);
    });


    const isNavigationItem: boolean = menuItems
        .filter((menuItem) => menuItem.code === currentCode).length === 1;

    const activeTab: IMenuDTO & IsActiveDO = useAppSelector(state => state.uiSlice.activeTab);

    const [currentTab, setCurrentTab] = useState<IMenuDTO & IsActiveDO | undefined>(
        isNavigationItem ? menuItems
            .filter((tab) => tab.code === currentCode)[0] : undefined);

    useEffect(() => {

        if (isNavigationItem) {
            const currentTab: IMenuDTO & IsActiveDO = menuItems
                .filter((tab) => tab.code === currentCode)[0];

            setCurrentTab(currentTab);

            if (siteTabs.length < 2) {

                const currentMenuItem: IMenuDTO = menuItems.filter((menuItem) =>
                    menuItem.code === currentCode)[0];

                dispatch(handleSiteTabs({item: currentMenuItem, action: 'first'}));
            }
        }

    }, [currentCode])

    function navigateHandler(tab: IMenuDTO & IsActiveDO) {
        navigate(tab.route || '/' + tab.code);
    }

    function navigationHandler({tab}: NavigationHandlerDO) {
        dispatch(handleSiteTabs({item: tab, action: 'add'}));
        navigateHandler(tab);
    }

    function removeOpenTabHandler({tab, idx}: RemoveOpenTabHandlerDO) {
        const tabToNavigate: IMenuDTO & IsActiveDO = siteTabs[idx !== 0 ? (idx - 1) : (idx + 1)];
        dispatch(handleSiteTabs({item: tab, action: 'remove'}));
        if (tab.isActive) {
            navigateHandler(tabToNavigate);
        }

    }

    // const key = 'code';
    // let uniqueSiteTabs = [...new Map(siteTabs.map(item =>
    //     [item[key], item])).values()];
    //
    // useEffect(() => {
    //     uniqueSiteTabs = [...new Map(siteTabs.map(item =>
    //         [item[key], item])).values()];
    // }, [currentCode])

    const tabsWrapper = document.getElementById('site-tabs-' + currentCode);
    const isOverFlown = isElementOverFlown({element:tabsWrapper, direction:'x'});

    return (
        siteTabs.length > 1 ?
            <>
                <QuickAccessBtnStyled
                    onMouseEnter={() => {
                         dispatch(setQuickAccessOpen({open:true}))

                    }}
                    onClick={() => {
                        dispatch(setQuickAccessOpen({open:!quickAccessOpen}))

                    }}


                >
                    <ElementWithTooltip
                        placement={"left-start"}
                        element={<span>quick access <br/>{siteTabs.length}</span>}
                        tooltip={<span>click to toggle</span>}/>
                </QuickAccessBtnStyled>

                <TabsHeaderStyledV2 className={quickAccessOpen ? (animate ?  'slide-in-right' : '') : (animate ? 'slide-out-right':'d-none')}
                                    key={'site-tabs-' + currentCode} id={'site-tabs-' + currentCode}>
                    {siteTabs.map((tab, idx) =>

                        <OpenTabWrapperV2
                            key={idx}
                            isLast={idx + 1 === siteTabs.length}
                            isActive={tab.isActive as boolean}
                            isOverFlown={isOverFlown}>
                            <OpenTabStyledV2
                                isActive={tab.isActive as boolean}
                                onClick={(tab.isActive) ? () => {
                                } : () => {
                                    navigationHandler({
                                        tab: activeTab.code === tab.code ? currentTab as IMenuDTO : tab
                                    })
                                }}
                            >
                                {tab.label}
                            </OpenTabStyledV2>
                            <CloseTabIconStyled
                                isActive={tab.isActive as boolean}
                                onClick={() => {
                                    removeOpenTabHandler({tab, idx})
                                }}><i className={'fa-solid fa-close'}/></CloseTabIconStyled>
                        </OpenTabWrapperV2>
                    )}
                    <OpenTabWrapperV2   isAnimation={true} key={'animate'} isLast={false} isActive={animate}>
                         <OpenTabStyledV2

                                isActive={animate}
                                onClick={()=>{setAnimate(!animate)}}
                            >
                                <ElementWithTooltip  placement={'left-start'}
                                    element={<span>Animation {animate ? 'On':'Off'}</span>}
                                                    tooltip={<span>Turn animation {animate ? 'Off':'On'}</span>}/>
                            </OpenTabStyledV2>
                    </OpenTabWrapperV2>
                </TabsHeaderStyledV2>
            </> : <></>
    );

}

export function hasActionOrCommand(item: IMenuDTO) {
    return item.action || item.command || item.commandFunction;
}

