import styled from 'styled-components';

export const ThemeContainerStyled = styled.div.attrs((props) => ({
    className: "form-control shadow mb-1 bg-transparent rounded-0 border-0 text-light "

}))`
    color: ${props => props.theme.table.columnColor} !important ;
    font-size: ${props => props.theme.table.columnFontSize};
    height: 550px !important;
  overflow: hidden;
`
export const ThemeWrapperStyled = styled.div.attrs((props) => ({
}))`
  color: ${props => props.theme.table.columnColor} !important ;
  height: 500px !important;
  overflow-x: auto;
  font-size: ${props => props.theme.table.columnFontSize};
`

export const ThemeCollectionStyled = styled.div.attrs((props) => ({
}))`
  border: 1px solid ${props => props.theme.table.columnColor} !important;
  border-radius: 5px;
  height:fit-content;
  margin-bottom: 5px;
  margin-right: 5px;
`
export const CollectionNameStyled = styled.div.attrs((props) => ({
}))`
  color: ${props => props.theme.panel.subPanel.titleColor} !important ;
  font-size: ${props => props.theme.panel.subPanel.titleFontSize};
  background-color: ${props => props.theme.panel.subPanel.titleBgColor};
  padding:5px;
`
export const CollectionFieldsContainerStyled = styled.div.attrs((props) => ({
}))`
  color: ${props => props.theme.table.columnColor} !important ;
  font-size: ${props => props.theme.table.columnFontSize};
`
export const ThemeCollectionSubPanelStyled = styled.div.attrs((props) => ({
    className: "form-control bg-transparent  shadow mb-1 rounded-0 border-top-0 border-start-0 border-end-0 text-light "
}))`
    color: ${props => props.theme.table.columnColor} !important ;
    font-size: ${props => props.theme.table.columnFontSize};
`
export const ThemeCollectionFieldsContainerStyled = styled.div.attrs((props) => ({
}))`
  color: ${props => props.theme.table.columnColor} !important ;
  font-size: ${props => props.theme.table.columnFontSize};
  height: fit-content;

`
