import React, {useEffect, useRef, useState} from 'react';

import Modal from 'react-bootstrap/Modal';
import {useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {GuslThemeContext} from '../../../providers/theme/GuslThemeProvider';
import {getSettings, performValidation} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {assignReferences, extractExtraFieldProperties, noop, RunOnceEffect} from '../../../utils/Utils';
import {ClosedBookIcon} from '../../styled-icons/ClosedBookIcon';
import {OpenBookIcon} from '../../styled-icons/OpenBookIcon';
import {FieldProperties, FormMode} from '../../types';
import {ActionDialogHeaderStyled, ActionDialogTitleStyled} from '../action-dialog/styled';
import {DraggableModalDialog} from '../draggable-modal/draggable-modal';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, InputStyled} from '../text/styled';
import {ImageActionDialogBodyStyled, ImageStyled} from './styled';

const SVG_MAPPER: { [id: string]: any } = {
    'assets/icons/open-book.svg': OpenBookIcon,
    'assets/icons/closed-book.svg': ClosedBookIcon
}


export const ImageField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'ImageField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '');
    const valueRef = useRef(properties?.data);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const extraFieldProperties = extractExtraFieldProperties(properties);

    let root = document.documentElement;

    if (extraFieldProperties?.modalWidth) {
        root.style.setProperty('--modal-width', extraFieldProperties?.modalWidth);
    }
    if (extraFieldProperties?.modalHeight) {
        root.style.setProperty('--modal-height', extraFieldProperties?.modalHeight);
    }


    if (!extraFieldProperties?.withImageSuffix) {
        extraFieldProperties.noImageSuffix = true
    }
    const [logoUrl, setLogoUrl] = useState<string>(environmentService.getImageSrc(
        properties?.data,
        environmentService.getEnvironment()?.assetsUrl || '',
        guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,
        extraFieldProperties.noImageSuffix) || '')
    const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
    const [timedOut, setTimedOut] = useState<boolean>(false);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
        setLogoUrl(environmentService.getImageSrc(
            properties?.data,
            environmentService.getEnvironment()?.assetsUrl || '',
            guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,
            extraFieldProperties.noImageSuffix) || '')
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
        setLogoUrl(environmentService.getImageSrc(
            properties?.data,
            environmentService.getEnvironment()?.assetsUrl || '',
            guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,
            extraFieldProperties.noImageSuffix) || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            fieldValue,
            setSubmitted,
            setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const imageLoaded = () => {
        setImageLoaded(true)
    }

    const imageError = () => {
        // set timed out
        setTimedOut(true)
    }

    const onImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        if (e) {
            e.stopPropagation()
        }
        if (!extraFieldProperties?.allowClickToEnlarge) {
            return;
        }
        setShowDialog(true)
    }

    const onHide = () => {
        setShowDialog(false)
    }


    const renderImageModal = (): React.ReactElement => {
        return (
            <Modal
                dialogAs={DraggableModalDialog}
                centered
                show={true}
                keyboard={true}
                onHide={() => onHide()}
                backdrop={true}
                dialogClassName="modal-wrapper"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <ActionDialogHeaderStyled closeButton={true}>
                    <ActionDialogTitleStyled>{properties.fieldConfig.label}</ActionDialogTitleStyled>
                </ActionDialogHeaderStyled>
                <ImageActionDialogBodyStyled>
                    <ImageStyled key={properties.fieldConfig.name} src={logoUrl}
                                 width={'100%'}
                                 alt="image"
                                 onLoad={() => imageLoaded()}
                                 onError={() => imageError()}
                    />
                </ImageActionDialogBodyStyled>
            </Modal>
        )
    }


    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        let component: any;
        for (const origKey in SVG_MAPPER) {
            if (SVG_MAPPER.hasOwnProperty(origKey)) {
                // @ts-ignore
                if (SVG_MAPPER[origKey] && formValue.endsWith(origKey)) {
                    component = SVG_MAPPER[origKey]
                }
            }
        }

        if (component) {
            return <>{component()}</>
        }

        if (!component && timedOut && !isImageLoaded) {
            return <></>
        }
        return (
            <>
                {logoUrl &&
                    <ImageStyled key={properties.fieldConfig.name} src={logoUrl}
                                 onClick={(e) => onImageClick(e)}
                                 width={extraFieldProperties?.imageWidth}
                                 height={extraFieldProperties?.imageHeight}
                                 alt="image" style={style}
                                 onLoad={() => imageLoaded()}
                                 onError={() => imageError()}
                    />
                }
                {logoUrl && extraFieldProperties?.allowClickToEnlarge && showDialog && renderImageModal()}
            </>
        )
    }

    const renderField = (): React.ReactElement => {

        const onDataInputChange = (value: string) => {
            setFormValue(value);
            properties.onChange(properties.fieldConfig.name, valueRef?.current?.value);
        }


        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        if (formMode === FormMode.EDIT) {
            return (
                <FieldContentStyled>
                    <InputStyled type={'text'}
                                 id={properties?.fieldConfig?.name || 'def'}
                                 key={properties?.fieldConfig?.name || 'def'}
                                 ref={valueRef}
                                 value={formValue}
                                 autoFocus={properties.isFirstField}
                                 readOnly={disableField}
                                 disabled={disableField}
                                 onChange={(e) => onDataInputChange(e.target.value)}
                                 required={required}
                                 submitted={submitted}
                                 noValue={!formValue}
                    />
                </FieldContentStyled>
            )
        }
        return (
            <FieldContentStyled>
                {formValue &&
                    <ImageStyled src={logoUrl} alt="image"
                                 onClick={(e) => onImageClick(e)}
                                 width={extraFieldProperties?.imageWidth}/>
                }
            </FieldContentStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        // console.log('logoUrl', logoUrl)

        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            {renderField()}
                        </FieldContentWrapperStyled>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                </>}
                {logoUrl && extraFieldProperties?.allowClickToEnlarge && showDialog && renderImageModal()}
            </>
        )


    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
