import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {SessionContext} from '../../../providers/session/SessionContext';
import {SystemContext} from '../../../providers/system/SystemContext';
import {cancelAbortController, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {QueryParamsDTO} from '../../types';
import {
    getGlobalSearchResults,
    GlobalSearchResponseWrapper,
    GlobalSearchState,
    initGlobalSearch,
    resetGlobalSearch,
    setGlobalSearchSelectionField,
    toggleFuzzy,
    updateGlobalSearch
} from './GlobalSearchSlice';
import GlobalSearchBox from './search-box/GlobalSearchBox';
import {GlobalSearchBoxWrapperStyled} from './styled';
import {GlobalSearchDTO, SearchFieldsDTO} from './type';

export interface GlobalSearchProperties extends GlobalSearchDTO {
    code: string | undefined,
    queryParams?: QueryParamsDTO,
    renderResultDialog?: (item: any) => React.JSX.Element
}

export const GlobalSearch = (props: GlobalSearchProperties): React.ReactElement<GlobalSearchProperties> => {
    const environmentContext = React.useContext(EnvironmentContext);
    const sessionContext = React.useContext(SessionContext);
    const systemContext = React.useContext(SystemContext)
    const dispatch = useAppDispatch();
    const location = useLocation();

    const _globalSearchSlice: GlobalSearchState = useAppSelector(state => state.globalSearchSlice);

    const isMobile = environmentContext.isMobileDevice();

    const [lastQueryParams, setLastQueryParams] = useState<QueryParamsDTO>(props.queryParams || {skip: 0, limit: 20});

    const [dataForTable, setDataForTable] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [systemLoaded, setSystemLoaded] = useState<boolean>(false);
    const [haveGlobalSearch, setHaveGlobalSearch] = useState<boolean>(false);
    const paths = location.pathname.split('/');

    RunOnceEffect(() => {
        let loaderSubscription = systemContext.watchSystemLoaded().subscribe((systemLoaded: boolean) => {
            if (systemLoaded) {
                const config: GlobalSearchDTO | undefined = props?.selectUrl ? props : systemContext.getGlobalSearchConfig()
                if (config) {
                    setHaveGlobalSearch(true)
                    dispatch(initGlobalSearch({
                        selectUrl: config.selectUrl,
                        searchFields: config.fields || [],
                        currentNavigationPath: location.pathname,
                        autoSearchLength: config.autoSearchLength,
                        renderResultDialog: props.renderResultDialog
                    }))
                }
            }
            setSystemLoaded(systemLoaded);
        });
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    useEffect(() => {
            let abortController = new AbortController();
            if (_globalSearchSlice.refreshSearchRequestCounter > 0 && (_globalSearchSlice?.queryParams?.searchString?.length||0) > 0) {
                dispatch(getGlobalSearchResults({
                    url: _globalSearchSlice.selectUrl,
                    queryParams: _globalSearchSlice.queryParams,
                    sessionContext: sessionContext,
                    abortController: abortController
                }))
                    .unwrap()
                    .then((reply: GlobalSearchResponseWrapper) => {
                        setLoaded(true)
                    })
                    .catch((error: any) => {
                        console.error("error", error);
                        setLoaded(true)
                    });
            }

            return () => {
                cancelAbortController(abortController);
            }
        }, [_globalSearchSlice.refreshSearchRequestCounter]
    )

    const onSearchChange = (searchString: string | undefined) => {
        if (searchString && searchString.length > 1) {
            dispatch(updateGlobalSearch({searchString}))
        }
    }
    const onFuzzySearch = () => {
        dispatch(toggleFuzzy({}))
    }

    const onSearchableFieldChange = (field: SearchFieldsDTO) => {
        dispatch(setGlobalSearchSelectionField({field}))
    }
    const onSearchReset = () => {
        dispatch(resetGlobalSearch({}))
    }

    return (
        <>
            {haveGlobalSearch &&
                <GlobalSearchBoxWrapperStyled>
                    <GlobalSearchBox
                        key={'gs_' + _globalSearchSlice.refreshCount}
                        code={props.code || 'gs'}
                        searchableFields={_globalSearchSlice.searchFields}
                        searchString={_globalSearchSlice?.queryParams?.searchString || ''}
                        fuzzy={_globalSearchSlice?.queryParams?.fuzzy || false}
                        searchPrompt={'Global Search'}
                        showFieldSelect={!isMobile}
                        onSearchValueChange={(searchValue: string | undefined) => onSearchChange(searchValue)}
                        onFuzzySearch={onFuzzySearch}
                        onSearchableFieldChange={onSearchableFieldChange}
                        onSearchReset={onSearchReset}
                        autoSearchLength={_globalSearchSlice?.autoSearchLength}
                    />
                </GlobalSearchBoxWrapperStyled>
            }
        </>
    )

}
