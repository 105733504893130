import {Modal} from 'react-bootstrap';
import styled, {ThemeProps} from 'styled-components';

interface ImageWrapperStyledProps extends ThemeProps<any> {
    width?: string;
}


export const ImageStyled = styled.img.attrs((props: ImageWrapperStyledProps) => ({}))<ImageWrapperStyledProps>`
    // width: ${props => props.width ? props.width : '40px'}; // gbw because of news images
  ${props => props.width && 'width: ' + props.width + ';'};
  vertical-align: middle;
  fill: currentColor;
  
`
export const ImageActionDialogBodyStyled = styled(Modal.Body).attrs(props => ({
    id:'image_action_dialog_body'
}))`
  background-color: ${props => props.theme.colors.background[1]} !important;
  padding: 0px !important;
  max-height:700px;
  max-width:1000px;
  overflow: auto;
`
