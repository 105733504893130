import React, { useState } from "react";
import { BuySellState } from "../../buySellSlice";
import { BoxStyled, ErrorPanelStyled } from "../../styled_v2";

export interface RenderErrorDO {
  buySellState: BuySellState;
}

export default function RenderError({ buySellState }: RenderErrorDO) {

  const [className] = useState("RenderError-" + new Date().getTime());
  const errorMessage: string = buySellState.errorMessage;
  const isNotMapped: boolean = errorMessage === "Contract not mapped";
  return (
    isNotMapped ?  <BoxStyled><ErrorPanelStyled>
      {buySellState.errorMessage}
    </ErrorPanelStyled></BoxStyled>:<></>

  );
}

