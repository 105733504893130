import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {BaseTheme} from '../../../providers/theme/GuslThemeProvider';
import {getColours, getHoverColours} from '../../../styles/styled'
import {Size} from '../../../styles/types'
import {ButtonProps, LinkButtonProps} from './types'

const getButtonSize = (theme: BaseTheme, size?: Size) => {
  const common = `            
    border-radius: 8px;
  `
  switch (size) {
    case 'xs':
      return `
      border-radius: 2px;
      padding: ${theme.grid.baseline}px ${theme.grid.baseline}px;
      font-size: 0.7rem;     
      `
    case 's':
      return `
      ${common}
      padding: ${theme.grid.baseline}px ${theme.grid.baseline * 2}px;
      font-size: 0.8rem;      
      `
    default:
    case 'm':
      return `
      ${common}
      padding: ${theme.grid.baseline * 2}px ${theme.grid.baseline * 3}px;
      font-size: 1rem;           
      `
    case 'l':
      return `
      ${common}
      padding: ${theme.grid.baseline * 2}px ${theme.grid.baseline * 6}px;
      font-size: 1.2rem;         
      `
    case 'xl':
      return `
      ${common}      
      padding: ${theme.grid.baseline * 2}px ${theme.grid.baseline * 6}px;
      font-size: 1.2rem;           
      width: 100%;
      `
  }
}

export const ButtonStyled = styled.button<ButtonProps>`
  ${props => getColours(props.theme, props.colour)}
  ${props => getHoverColours(props.theme, props.colour)}
  ${props => getButtonSize(props.theme, props.size)}
  text-align: center;
  outline: none;
  border: none;
`

export const LinkStyled = styled(Link)<LinkButtonProps>`
  ${props => getColours(props.theme, props.colour)}
  ${props => getHoverColours(props.theme, props.colour)}
  ${props => getButtonSize(props.theme, props.size)}
  display: block;
  text-decoration: none;
  text-align: center;
`
