import {Capacitor} from '@capacitor/core';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import ActionDialog from '../../components/common/action-dialog/ActionDialog';
import {hideAction} from '../../components/common/action-dialog/actionSlice';
import {GlobalSearchState} from '../../components/common/global-search/GlobalSearchSlice';
import {GlobalSearchResults} from '../../components/common/global-search/results/GlobalSearchResults';
import {GuslOverlay} from '../../components/gusl-overlay/GuslOverlay';
import {TermsAndConditions} from '../../components/terms-and-conditions/TermsAndConditions';
import {ActionConfigDTO, TableRowDTO} from '../../components/types';
import LeftHandMenu from '../../components/ui/navigation/left-hand-menu/LeftHandMenu';
import TopNavigation from "../../components/ui/navigation/navbar/TopNavigation";
import SiteTabsV2 from "../../components/ui/navigation/site-tabs/SiteTabsV2";
import {StatusBarBackground} from '../../components/ui/status-bar/StatusBarBackground';
import UserInfo from '../../components/ui/UserInfo';
import {RELEASE_DETAILS} from '../../features/release-details';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {SessionContext} from '../../providers/session/SessionContext';
import {GuslUser, SessionContextProps} from '../../providers/session/types';
import {RunOnceEffect, unSubscribe} from '../../utils/Utils';
import {
    ContentWrapperStyled,
    MainContentWrapperStyled,
    PageContainerStyled,
    PageContentContainerStyled,
    StyledFooter,
    StyledLeftHandMenu
} from './styled';

const JupiterLayout = (): React.ReactElement => {

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('JupiterLayout-' + new Date().getTime());
    const environmentContext = React.useContext(EnvironmentContext)


    // const [isMobile, setIsMobile] = useState<boolean>(false);

    const showActionModal: boolean = useAppSelector(state => state.actionSlice.showActionModal);
    const _globalSearchSlice: GlobalSearchState = useAppSelector(state => state.globalSearchSlice);

    const selectedRows: {
        [id: string]: TableRowDTO
    } | undefined = useAppSelector(state => state.actionSlice.selectedRows);
    const activeAction: ActionConfigDTO | undefined = useAppSelector(state => state.actionSlice.activeAction);
    const dispatch = useAppDispatch();
    const sessionContext: SessionContextProps = useContext(SessionContext);
    const [nickname, setNickname] = useState<string>('');
    const [initial, setInitial] = useState<string>('');
    const [accountType, setAccountType] = useState<string>('');

    const [isSideNavOpen, setSideNavOpen] = useState<boolean>(false);
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS());
    const [tandCUpgradeRequired, setTandCUpgradeRequired] = useState<boolean>(false);

    const contentElement = useRef(null);
    const [startPos, setStartPos] = useState<number>(36); // CONTENT_START_POSITION
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
            }, 100)
        });
    }, [contentElement]);


    RunOnceEffect(() => {
        let sideMenuSubscription: Subscription = environmentService.watchSideMenu().subscribe((menuOpen: boolean) => {
            setSideNavOpen(menuOpen)
        })
        let tandCSubscription = sessionContext.watchTandCUpgradeRequired().subscribe((upgradeReqd: boolean) => {
            setRefreshCounter(refreshCounter + 1)
            setTandCUpgradeRequired(upgradeReqd)
        });


        return () => {
            unSubscribe(sideMenuSubscription);
            unSubscribe(tandCSubscription);
        }
    });

    RunOnceEffect(() => {
        let componentActive: boolean = true;
        const loginSubscription = sessionContext.watchLogin()?.subscribe((user: GuslUser | undefined | null) => {
            if (componentActive) {
                if (user) {
                    setNickname(user.nickname || '');
                    if (user.thirdPartyId && user.thirdPartyId.length > 0) {
                        setInitial(user.thirdPartyId)
                    } else if (user.nickname && user.nickname.length > 0) {
                        setInitial((user.nickname || ' ').substring(0, 1))
                    }
                    if (user.accountType && user.accountType.length > 0) {
                        setAccountType(user.accountType + '  A/c')
                    }
                }
            }
        });
        return () => {
            componentActive = false;
            unSubscribe(loginSubscription);
        }
    })

    const renderToolbar = (): React.ReactElement => {
        return (
            <TopNavigation sidebarNavigation={false} tandCUpgradeRequired={tandCUpgradeRequired}/>
        )
    }

    const renderFooter = (): React.ReactElement => {
        const platform = Capacitor?.getPlatform() || 'uk'
        const native = Capacitor?.isNativePlatform() || 'false'
        return <>
            <StyledFooter isMobile={false}>
                <div>Release: {RELEASE_DETAILS.releaseNumber} ({platform}/{native})</div>
            </StyledFooter>
        </>
    }

    const renderLeftHandMenu = (): React.ReactElement => {
        return (
            <StyledLeftHandMenu $isSideNavOpen={isSideNavOpen} id={'lhm'} isIOS={isIOS}>
                <UserInfo/>
                <LeftHandMenu/>
                {renderFooter()}
            </StyledLeftHandMenu>
        )
    }

    const renderActionPlaceholder = (): React.ReactElement => {
        return <>
            {/*{showActionModal && <p>show modal</p>}*/}
            {showActionModal && activeAction &&
                <ActionDialog action={activeAction}
                              onHide={() => {
                                  dispatch(hideAction(true))
                              }}
                              closeDialog={() => {
                                  dispatch(hideAction(true))
                              }}
                              refreshTable={activeAction.refreshTable}
                              selectedRows={selectedRows}
                />}
        </>;
    };
    if (tandCUpgradeRequired) {
        return <PageContainerStyled isMobile={environmentContext.isMobileDevice()} id={"page-layout"}>
            <StatusBarBackground/>
            {renderToolbar()}
            <PageContentContainerStyled ref={contentElement} startPos={startPos}
                                        footerHeight={footerHeight}
                                        isMobile={environmentContext.isMobileDevice()}>
                <ContentWrapperStyled id={'cws'}>
                    <TermsAndConditions/>
                </ContentWrapperStyled>
            </PageContentContainerStyled>
        </PageContainerStyled>
    }

    return (
        <PageContainerStyled isMobile={environmentContext.isMobileDevice()} id={"page-layout"}>
            <StatusBarBackground/>
            {renderToolbar()}
            <PageContentContainerStyled ref={contentElement} startPos={startPos}
                                        footerHeight={footerHeight}
                                        isMobile={environmentContext.isMobileDevice()}>
                <ContentWrapperStyled id={'cws'}>
                    {/*{!environmentContext.isMobileDevice() && <LeftHandWrapperStyled $isSideNavOpen={true}*/}
                    {/*                                                                isIOS={isIOS}>{renderLeftHandMenu()}</LeftHandWrapperStyled>}*/}
                    <MainContentWrapperStyled
                        $isSideNavOpen={false}
                        isMobile={environmentContext.isMobileDevice()}
                        isIOS={isIOS}
                        startPos={startPos}
                        footerHeight={footerHeight}

                    >
                        {!_globalSearchSlice.resultPanelOpened && <Outlet/>}
                        {!_globalSearchSlice.resultPanelOpened && <SiteTabsV2/>}
                        {_globalSearchSlice.resultPanelOpened &&
                            <GlobalSearchResults key={'global_results_' + _globalSearchSlice?.refreshCount}/>}
                    </MainContentWrapperStyled>
                    {renderActionPlaceholder()}
                    <GuslOverlay/>
                </ContentWrapperStyled>
            </PageContentContainerStyled>
        </PageContainerStyled>
    )
};

export default JupiterLayout;
