import React, {useEffect, useId, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom';
import {useSwipeable} from 'react-swipeable';
import {HandledEvents} from 'react-swipeable/src/types';
import {Subscription} from 'rxjs';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {CONTENT_START_POSITION} from '../../../providers/theme/GuslThemeProvider';
import {getSettings} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {arrayIsEmpty, notDefined} from '../../../utils/TypeCheckers';
import {assignReferences, constructUrl, noop, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {ReportPanel} from '../../bespoke/sports-betting/landing-page/sliding-panel/panels/ReportPanel';
import {
    actionClosePanel,
    actionOpenPanel
} from '../../bespoke/sports-betting/landing-page/sliding-panel/slidingPanelOpenSlice';
import {
    BackIconStyled,
    HeaderStyled,
    SlidingPanelBodyStyled,
    SlidingPanelHeaderStyled,
    SlidingPanelStyled,
    SubHeaderStyled
} from '../../bespoke/sports-betting/landing-page/sliding-panel/styled';
import {fieldService} from '../../FieldService';
import {ActionConfigDTO, ActionType, FieldProperties, FormMode} from '../../types';
import {OffCanvasBodyStyled, OffCanvasContentStyled} from '../../ui/navigation/navbar/styled';
import {DraggableModalDialog} from '../draggable-modal/draggable-modal';
import FieldLabel from '../field-label/FieldLabel';
import {GuslReport} from '../gusl-report/GuslReport';
import {closeCard, openCard, turnOffTableUpdates, turnOnTableUpdates} from '../gusl-table/guslTableSlice';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import MaintainForm from '../maintain-form/MaintainForm';
import {maintainTableService} from '../maintain-table/MaintainTableService';
import {ReportDO} from '../report/types';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled} from '../text/styled';
import {initCardPanel, MasterCardActionState, panelClosed} from './cardActionSlice';
import {
    ActionMenuIconStyled,
    CardActionButtonStyled,
    CardActionsLeftContainerStyled,
    CardActionsRightContainerStyled,
    CardActionWrapperStyled,
    CardContainerStyled,
    CardContentContainerStyled,
    CardContentStyled,
    CardContentWrapperStyled,
    CardWrapperStyled,
    DotsButtonStyled,
    DotsContainerStyled,
    DotsWrapperStyled,
    ModalBodyStyled,
    ModalHeaderStyled,
    OffCanvasStyled
} from './styled';
import './styles.css';
import {CardBO, CardPanelBO} from './types';


interface OnTapEvents {
    event: HandledEvents
}

export const CardField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [wrongId] = useState<string>(useId());
    //
    const [uniqueId] = useState<string>(properties.tableCode + '_' + (properties.tableRowState?.rowId || 'norowid') + '_' + properties.fieldConfig.name);

    const sessionContext = React.useContext(SessionContext);
    const environmentContext = React.useContext(EnvironmentContext)


    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS());

    const contentElement = useRef(null);
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION);

    const _cardActionState: MasterCardActionState = useAppSelector(state => state.cardActionSlice);
    // const [cardOpened] = useState<boolean>(() => {
    //     console.log('------ properties?.tableState?.cards', properties?.tableState?.cards)
    //     return properties?.tableState?.cards[uniqueId] || false
    // });
    const [cardOpened, setCardOpened] = useState<boolean>(false);

    // const cardOpened = properties?.tableState?.cards[uniqueId] || false

    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [isPanelScrolling, setIsPanelScrolling] = useState<boolean>(false);
    const [isTopExpanded, setIsTopExpanded] = useState<boolean>(false);
    const [isBottomExpanded, setIsBottomExpanded] = useState<boolean>(false);
    const [isRightSwipeExpanded, setIsRightSwipeExpanded] = useState<boolean>(false);
    const [isLeftSwipeExpanded, setIsLeftSwipeExpanded] = useState<boolean>(false);
    const [keyId] = useState<string>('card_' + properties.fieldConfig.name + '_' + (properties?.rowData?.id ? properties?.rowData?.id : new Date().getTime()));
    const [tableCode] = useState<string>(properties?.tableCode || 'no_code')

    const [cardPanel] = useState<CardPanelBO | undefined>(properties?.data?.cardPanel);
    const [contentWidth, setContentWidth] = useState<number>(window.innerWidth);
    const [refreshCounter, setRefreshCounter] = useState<number>(1)

    const isMobile = environmentContext.isMobileDevice();
    const [oldResize, setOldResize] = useState<number | undefined>(undefined);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (contentElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setStartPos(rect.y)
                    }
                }
            }, 100)
        });
    }, [contentElement]);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        const subscriptionResize = environmentContext.watchResize().subscribe((value: number) => {
            if (!oldResize || oldResize !== value) {
                setOldResize(value)
                setRefreshCounter(refreshCounter + 1)
                setContentWidth(value)
            }

        });
        return () => {
            unSubscribe(subscriptionResize)
        }
    }, [])

    useEffect(() => {
        dispatch(initCardPanel({code: uniqueId}))
        return (() => {
            // dispatch(cleanUpCardPanel({code: uniqueId}))
        })
    }, [uniqueId])


    const [bespokePage, setBespokePage] = useState<string | undefined>(undefined);
    useEffect(() => {
        const cardActionStateElement = _cardActionState[uniqueId];
        // console.log('--------------- cardActionStateElement ' + uniqueId, cardActionStateElement)
        if (cardActionStateElement) {
            setCardOpened(cardActionStateElement.opened)
            if (cardActionStateElement.opened) {
                dispatch(turnOffTableUpdates({code: tableCode}))
                setBespokePage(cardActionStateElement.bespokePage)
                // dispatch(openCard({code: tableCode, uniqueId: uniqueId}))
            } else {
                setBespokePage(undefined)
            }
        }
    }, [wrongId, uniqueId, _cardActionState]);


    const cardHandlers = useSwipeable({
        onSwiped: () => handlePanEnd(),
        onSwipeStart: (eventData) => handlePanStart(eventData),
        onSwiping: (eventData) => handleSwipe(eventData),
        onTap: (eventData) => handleOnTap(eventData),
        trackMouse: true,
    });

    const panelHandlers = useSwipeable({
        onSwiped: () => handlePanelPanEnd(),
        onSwipeStart: (eventData) => handlePanelPanStart(eventData),
        onSwiping: (eventData) => handlePanelSwipe(eventData),
        trackMouse: true,
    });


    const id = useId();

    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<CardBO | undefined>(properties?.data);
    const [formValue, setFormValue] = useState<CardBO | undefined>(_guslFormState?.getFieldValue(properties) || properties.data || '');

    const [numberSwipeLeftActions] = useState<number>(formValue?.swipeLefts?.length || 0);
    const [numberSwipeRightActions] = useState<number>(formValue?.swipeRights?.length || 0);

    const [haveSwipeLeftActions] = useState<boolean>((formValue?.swipeLefts?.length || 0) > 0);
    const [haveSwipeRightActions] = useState<boolean>((formValue?.swipeRights?.length || 0) > 0);

    const handlePanStart = (e: any) => {
        if (e.dir === 'Down' || e.dir === 'Up') {
            setIsScrolling(true);
        }
    }

    const handlePanelPanStart = (e: any) => {
        if (e.dir === 'Down' || e.dir === 'Up') {
            setIsPanelScrolling(true);
        }
    }

    const handleOnTap = (event: OnTapEvents) => {
        if (formValue?.cardPanel
            && (formValue?.cardPanel.form || formValue?.cardPanel.report || formValue?.cardPanel.reportUrl)
            && (event.event.type === 'touchend' || event.event.type === 'mouseup')) {
            if (cardPanel) {
                dispatch(openCard({code: tableCode, uniqueId: uniqueId}))
                dispatch(turnOffTableUpdates({code: tableCode}))
            }
        }
    }

    const handlePanelPanEnd = () => {
        setIsPanelScrolling(false);
    }

    const handlePanEnd = () => {
        setIsScrolling(false);
    }

    const onOpen = (): void => {

    }
    const onClose = (): void => {

    }

    const sendActionOpen = () => {
        dispatch(actionOpenPanel({}))
    }
    const sendActionClosed = () => {
        setTimeout(() => {
            dispatch(actionClosePanel({}))
        }, 100)

    }


    const handlePanelSwipe = (e: any) => {
        if (!isPanelScrolling) {
            if (e.dir === 'Right') {
                // onOffCanvassClose()
            }
        }

    }

    const handleSwipe = (e: any) => {
        if (!isScrolling) {
            if (e.dir === 'Left') {
                if (!isLeftSwipeExpanded) {
                    if (isRightSwipeExpanded) {
                        setIsRightSwipeExpanded(false)
                        sendActionClosed()
                    } else {
                        if (haveSwipeLeftActions) {
                            setIsLeftSwipeExpanded(true)
                            sendActionOpen()
                        }
                    }
                }
            } else if (e.dir === 'Right') {
                if (!isRightSwipeExpanded) {
                    if (isLeftSwipeExpanded) {
                        setIsLeftSwipeExpanded(false)
                        sendActionClosed()
                    } else {
                        if (haveSwipeRightActions) {
                            setIsRightSwipeExpanded(true)
                            sendActionOpen()
                        }
                    }
                }
            } else if (e.dir === 'Up') {
                if (!isTopExpanded) {
                    setIsTopExpanded(true)
                    sendActionOpen()
                } else {
                    // close right
                    setIsBottomExpanded(false)
                    sendActionClosed()
                }
            } else if (e.dir === 'Down') {
                if (isTopExpanded) {
                    setIsTopExpanded(false)
                    sendActionClosed()
                } else {
                    // close right
                    setIsBottomExpanded(true)
                    sendActionOpen()
                }
            }
            // if (e.dir === 'Left' && !isExpanded) {
            //     // LEFT SWIPE...
            //     setIsExpanded(true);
            //     if (onOpen) {
            //         onOpen();
            //     }
            // } else if (e.dir === 'Right' && isExpanded) {
            //     // RIGHT SWIPE...
            //     setIsExpanded(false);
            //     if (onClose) {
            //         onClose();
            //     }
            // }
        }
    }

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, () => {
            return true
        })
    });

    const renderSwipePanelContents = (): React.ReactElement => {
        if (!formValue) {
            return <></>
        }
        if (notDefined(formValue.content)) {
            return <div>{formValue.label}</div>
        }
        return (
            <CardContentStyled>
                <GuslReport
                    // code={properties?.fieldConfig?.name || 'rep'}
                    code={uniqueId}
                    data={formValue.content}
                    rowData={properties.getCurrentRowData()}
                    reLoad={properties.reLoad}
                />
            </CardContentStyled>
        )
    }

    const renderDots = (leftSide: boolean): React.ReactElement => {
        if ((leftSide && !formValue?.swipeRightIcon) || (!leftSide && !formValue?.swipeLeftIcon)) {
            return <></>
        }
        if (!leftSide && arrayIsEmpty(formValue?.swipeLefts)) {
            return <></>
        }

        if (leftSide && arrayIsEmpty(formValue?.swipeRights)) {
            return <></>
        }

        const icon = leftSide ? formValue?.swipeRightIcon : formValue?.swipeLeftIcon
        return (
            <DotsContainerStyled>
                <DotsWrapperStyled>
                    <DotsButtonStyled
                        onClick={(e) => {
                            if (e) {
                                e.stopPropagation()
                            }
                            const shouldClose = leftSide ? isRightSwipeExpanded : isLeftSwipeExpanded;
                            if (leftSide) {
                                setIsRightSwipeExpanded(!shouldClose);
                            } else {
                                setIsLeftSwipeExpanded(!shouldClose);
                            }
                            if (shouldClose) {
                                sendActionClosed()
                            } else {
                                sendActionOpen()
                            }
                            if (shouldClose && onClose) {
                                onClose();
                            } else if (!shouldClose && onOpen) {
                                onOpen();
                            }
                        }}
                        aria-label={'action'}
                        aria-controls={id}
                        aria-expanded={(isLeftSwipeExpanded || isRightSwipeExpanded) ? 'true' : 'false'}
                    >
                        <ActionMenuIconStyled className={icon}/>
                    </DotsButtonStyled>
                </DotsWrapperStyled>
            </DotsContainerStyled>
        )
    }

    const renderForm = (form: ActionConfigDTO): React.ReactElement => {
        return (
            <MaintainForm
                isDialog={false}
                controlOnTop={false}
                canEdit={false}
                formMode={FormMode.VIEW}
                rowData={{...properties.getCurrentRowData()}}
                data={{}}
                menuItem={maintainTableService.convertToMenu(properties.getCurrentRowData(), form)}
                refreshTable={() => {
                }}
                closeDialog={() => {
                }}/>
        )
    }

    const renderReport = (report: ReportDO): React.ReactElement => {
        return (
            <GuslReport
                code={uniqueId}
                data={report}
                rowData={properties.getCurrentRowData()}
                reLoad={properties.reLoad}
            />
        )
    }

    const renderReportUrl = (reportUrl: string): React.ReactElement => {
        return (
            <ReportPanel reportUrl={reportUrl}/>
        )
    }

    const renderCardBody = (): React.ReactElement => {
        const form: ActionConfigDTO | undefined = cardPanel?.form
        const report: ReportDO | undefined = cardPanel?.report
        const reportUrl: string | undefined = cardPanel?.reportUrl
        // const pagedQuery: CardPagedQueryBO | undefined = cardPanel?.pagedQuery
        const bespokeRoute: string | undefined = cardPanel?.bespokeRoute

        if (form) {
            return <>{renderForm(form)}</>
        } else if (report) {
            return <>{renderReport(report)}</>
        } else if (reportUrl) {
            return <>{renderReportUrl(reportUrl)}</>
        } else if (bespokeRoute) {
            let props = {
                ticker: properties?.rowData.bbgTicker,
                actionParam: 'close',
                noTitle: true
            }
            return <CardContentWrapperStyled id={'ccws'}
                key={'ccws_' + refreshCounter}>{fieldService.getBespokeInstance(bespokeRoute, props)}</CardContentWrapperStyled>
        } else if (bespokePage) {
            // let props = {
            //     ticker: properties?.rowData.bbgTicker,
            //     actionParam: 'close',
            //     noTitle: true
            // }
            let props = {
                properties: _cardActionState[uniqueId]?.properties,
                rowData: _cardActionState[uniqueId]?.rowData
            }
            return <>{fieldService.getBespokeInstance(bespokePage, props)}</>
        }
        return (<></>)
    }

    const onOffCanvassClose = () => {
        dispatch(closeCard({code: tableCode, uniqueId: uniqueId}))
        dispatch(turnOnTableUpdates({code: tableCode}))
        dispatch(panelClosed({code: uniqueId}))
        if (properties?.reLoad) {
            properties.reLoad()
        }
    }

    const renderOffCanvas = (): React.ReactElement => {
        return (
            <SlidingPanelStyled
                id={"sliding_panel"}
                opening={cardOpened}
                windowHeight={window.innerHeight}
                windowWidth={contentWidth}
                isMobile={environmentContext.isMobileDevice()}
                {...panelHandlers}
            >
                <SlidingPanelHeaderStyled width={contentWidth}>
                    <BackIconStyled>
                        <i className="fas fa-chevron-left" onClick={() => onOffCanvassClose()}/>
                    </BackIconStyled>
                    <HeaderStyled width={contentWidth}>{cardPanel?.title || ''}</HeaderStyled>
                    {cardPanel?.subTitle &&
                        <SubHeaderStyled width={contentWidth}>{cardPanel?.subTitle}</SubHeaderStyled>}
                </SlidingPanelHeaderStyled>
                <SlidingPanelBodyStyled ref={contentElement} startPos={startPos} isMobile={isMobile}
                                        width={contentWidth}
                                        footerHeight={footerHeight}
                                        id={'card_sliding_panel_body'}>
                    <OffCanvasContentStyled>
                        {cardOpened && renderCardBody()}
                    </OffCanvasContentStyled>
                </SlidingPanelBodyStyled>
            </SlidingPanelStyled>
        )

    }


    const renderTableView = (): React.ReactElement => {

        const handleActionClicked = (action: ActionConfigDTO) => {
            if (isLeftSwipeExpanded) {
                setIsLeftSwipeExpanded(false);
            } else if (isRightSwipeExpanded) {
                setIsRightSwipeExpanded(false);
            } else if (isTopExpanded) {
                setIsTopExpanded(false);
            } else if (isBottomExpanded) {
                setIsBottomExpanded(false);
            }
            sendActionClosed()

            if (action.actionType === ActionType.ROUTE) {
                const url = ("/" + constructUrl(action.route as string, {})).replace('//', '/')
                navigate(url)

            }
            if (action.actionType === ActionType.ACTION_ONLY) {
                sessionContext.post<any, any>(action.url, properties.rowData)
                    .then(() => {
                        if (properties.reLoad) {
                            properties.reLoad()
                        }
                    }).catch(error => {
                    console.error("Error", error);
                })
            }

        }


        return (
            <CardContainerStyled key={keyId} id={keyId} isMobile={isMobile}>
                <div {...cardHandlers}>
                    <CardWrapperStyled>

                        <CardActionsLeftContainerStyled isExpanded={isRightSwipeExpanded}>
                            {(formValue?.swipeRights || []).map((action, index) => (
                                <CardActionWrapperStyled key={`actionKey_${index}`}>
                                    <CardActionButtonStyled style={getStyle(action.buttonCss)}
                                                            onClick={() => handleActionClicked(action)}>{action.popUpTitle}</CardActionButtonStyled>
                                </CardActionWrapperStyled>
                            ))}
                        </CardActionsLeftContainerStyled>


                        <CardContentContainerStyled isLeftExpanded={isLeftSwipeExpanded}
                                                    isRightExpanded={isRightSwipeExpanded}
                                                    numberOfActions={isLeftSwipeExpanded ? numberSwipeLeftActions : numberSwipeRightActions}>
                            {renderDots(true)}
                            {renderSwipePanelContents()}
                            {renderDots(false)}
                        </CardContentContainerStyled>
                        <CardActionsRightContainerStyled isExpanded={isLeftSwipeExpanded}>
                            {(formValue?.swipeLefts || []).map((action, index) => (
                                <CardActionWrapperStyled key={`actionKey_${index}`}>
                                    <CardActionButtonStyled style={getStyle(action.buttonCss)}
                                                            onClick={() => handleActionClicked(action)}>{action.popUpTitle}</CardActionButtonStyled>
                                </CardActionWrapperStyled>
                            ))}
                        </CardActionsRightContainerStyled>
                    </CardWrapperStyled>
                </div>
                {isMobile && <OffCanvasStyled show={cardOpened}
                                              isios={isIOS ? 'true' : 'false'}
                                              ismobile={isMobile ? 'true' : 'false'}
                                              footerHeight={footerHeight}
                    // style={offCanvasStyle}
                    // onHide={() => onOffCanvassClose()}
                                              placement={"end"}
                >
                    {/*<OffCanvasHeaderStyled closeButton >{renderOffCanvasHeader()}</OffCanvasHeaderStyled>*/}
                    <OffCanvasBodyStyled id={"offcanvas_body"}>
                        {renderOffCanvas()}
                    </OffCanvasBodyStyled>
                </OffCanvasStyled>}
                {!isMobile && cardOpened && <Modal show={cardOpened}
                                                   dialogAs={DraggableModalDialog}
                                                   centered={true}
                                                   onHide={() => onOffCanvassClose()}
                                                   size={"lg"}
                                                   scrollable>
                    <ModalHeaderStyled closeButton>{cardPanel?.title || ''} </ModalHeaderStyled>

                    <ModalBodyStyled>
                        {renderCardBody()}
                    </ModalBodyStyled>

                </Modal>}
            </CardContainerStyled>
        )

    }

    const renderFormView = (): React.ReactElement => {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                                {renderTableView()}
                            </FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                </>}
            </>
        )
    }


    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
