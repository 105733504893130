import {FieldConfigDTO} from '../../components/types';
import {log} from '../../services/LogService';
import {isDefined} from '../../utils/TypeCheckers';
import {BaseTheme} from '../theme/GuslThemeProvider';
import defaultTheme from '../theme/themes/defaultTheme';
import {JWTTokens, Session} from './types';

const LOCAL_STORE_KEY = '{storagePrefix}_jwt-session';
const LOCAL_GOOGLE_STORE_KEY = '{storagePrefix}_google-sso-session';
const LOCAL_GOOGLE_GUSL_STORE_KEY = '{storagePrefix}_google-gusl-session';
const LOCAL_MSAL_STORE_KEY = '{storagePrefix}_msal-sso-session';
const LOCAL_MSAL_USER_NAME = '{storagePrefix}_msal-user-name';
const LOCAL_MSAL_NAME = '{storagePrefix}_msal-name';
const ORDERED_FIELDS: string = '{storagePrefix}_ordered_fields_';
const LOCAL_COHORT_ID = '{storagePrefix}_cohort_id';
const LOCAL_THEME = '{storagePrefix}_theme';
const LOCAL_THEME_ID = '{storagePrefix}_theme_id';
const LOCAL_THEME_LAST_UPDATED = '{storagePrefix}_theme_last_updated';
const RESIZED_COLUMNS: string = '{storagePrefix}_resized_columns_';
const LOCAL_JWT_USER_NAME = '{storagePrefix}_jwt-user-name';

const LOCAL_DASHBOARD_LAYOUT = '{storagePrefix}_dashboard_layout';


class GuslStorage {

    private storagePrefix: string = 'default'

    public setStoragePrefix = (prefix?: string): void => {
        if (prefix) {
            this.storagePrefix = prefix
        }
    }

    public getKey = (key: string): string => {
        if (key) {
            return key.replace('{storagePrefix}', this.storagePrefix)
        } else {
            return key
        }
    }
    public clearSession = (): void => {
        console.log('------------------------------- clearSession ------------------------------- ')
        localStorage.removeItem(this.getKey(LOCAL_STORE_KEY));
        localStorage.removeItem(this.getKey(LOCAL_GOOGLE_STORE_KEY));
        localStorage.removeItem(this.getKey(LOCAL_GOOGLE_GUSL_STORE_KEY));
        localStorage.removeItem(this.getKey(LOCAL_MSAL_STORE_KEY));
    }


    public getJwtSession = (): JWTTokens | undefined => {
        const tokensStr = localStorage.getItem(this.getKey(LOCAL_STORE_KEY));
        log.info('SessionStorage', 'MSG001', 'get tokens', tokensStr ? (JSON.parse(tokensStr) as JWTTokens) : undefined)
        return tokensStr ? (JSON.parse(tokensStr) as JWTTokens) : undefined;
    }

    public setJwtSession = (tokens: JWTTokens) => {
        log.info('SessionStorage', 'MSG002', 'set tokens', JSON.stringify(tokens))
        localStorage.setItem(this.getKey(LOCAL_STORE_KEY), JSON.stringify(tokens));
        this.getJwtSession()
    }

    public getCohortId = (): string => {
        return localStorage.getItem(this.getKey(LOCAL_COHORT_ID)) || '';
    }

    public setCohortId = (token: string) => {
        localStorage.setItem(this.getKey(LOCAL_COHORT_ID), token);
    }

    public getGoogleToken = (): string => {
        return localStorage.getItem(this.getKey(LOCAL_GOOGLE_STORE_KEY)) || '';
    }

    public setGoogleToken = (token: string) => {
        localStorage.setItem(this.getKey(LOCAL_GOOGLE_STORE_KEY), token);
    }

    public setGoogleSessionToken = (token: string) => {
        localStorage.setItem(this.getKey(LOCAL_GOOGLE_GUSL_STORE_KEY), token);
    }

    public isGoogle = (): boolean => {
        return isDefined(localStorage.getItem(this.getKey(LOCAL_GOOGLE_STORE_KEY)));
    }

    public getMsalToken = (): string => {
        return localStorage.getItem(this.getKey(LOCAL_MSAL_STORE_KEY)) || '';
    }


    public setMsalToken = (token: string | undefined) => {
        if (token) {
            localStorage.setItem(this.getKey(LOCAL_MSAL_STORE_KEY), token);
        } else {
            localStorage.removeItem(this.getKey(LOCAL_MSAL_STORE_KEY))
        }
    }

    public setMsalUserName = (userName: string) => {
        localStorage.setItem(this.getKey(LOCAL_MSAL_USER_NAME), userName);
    }

    public getMsalUserName = (): string | null => {
        return localStorage.getItem(this.getKey(LOCAL_MSAL_USER_NAME));
    }


    public setJwtUserName = (userName: string) => {
        localStorage.setItem(this.getKey(LOCAL_JWT_USER_NAME), userName);
    }

    public getJwtUserName = (): string => {
        return localStorage.getItem(this.getKey(LOCAL_JWT_USER_NAME)) || '';
    }


    public getMsalName = (): string | null => {
        return localStorage.getItem(this.getKey(LOCAL_MSAL_NAME));
    }

    public setMsalName = (name: string) => {
        localStorage.setItem(this.getKey(LOCAL_MSAL_NAME), name);
    }

    public removeMsalName = (): void => {
        localStorage.removeItem(this.getKey(LOCAL_MSAL_NAME));
    }

    public isMsal = (): boolean => {
        return isDefined(localStorage.getItem(this.getKey(LOCAL_MSAL_STORE_KEY)));
    }


    public isJwt = (): boolean => {
        return isDefined(localStorage.getItem(this.getKey(LOCAL_STORE_KEY)));
    }

    getSession(): Promise<Session> {
        return new Promise<Session>(resolve => {
            resolve({
                jwtTokens: guslStorage.getJwtSession(),
                ssoToken: guslStorage.getGoogleToken() || null,
                isSsoToken: (): boolean => {
                    return guslStorage.getGoogleToken() !== null;
                },
                msalToken: guslStorage.getMsalToken() || null,
                isMsalToken: (): boolean => {
                    return guslStorage.getMsalToken() !== null;
                },
            })
        });
    }

    hasOrderedFields(code: string): boolean {
        return localStorage.getItem(this.getKey(this.getOrderFieldsKey(code))) !== undefined;
    }

    mergeFields(fields: FieldConfigDTO[], storageFields: FieldConfigDTO[]): FieldConfigDTO[] {
        if (!storageFields || storageFields.length === 0) {
            return fields
        }
        // going to add smarts about merging the two
        return storageFields;
    }

    getOrderedFields(code: string, fields: FieldConfigDTO[]): FieldConfigDTO[] {
        if (this.hasOrderedFields(code)) {
            return this.mergeFields(fields, this.getOrderedFieldsFromStorage(code))
        } else {
            return fields;
        }
    }

    getOrderedFieldsFromStorage(code: string): FieldConfigDTO[] {
        if (localStorage.getItem(this.getKey(this.getOrderFieldsKey(code)))) {
            return JSON.parse(localStorage.getItem(this.getKey(this.getOrderFieldsKey(code))) as string);
        } else if (localStorage.getItem(this.getOrderFieldsKey(code))) {
            return JSON.parse(localStorage.getItem(this.getOrderFieldsKey(code)) as string);
        }
        return [];
    }

    getOrderFieldsKey(code: string): string {
        return ORDERED_FIELDS + code;
    }

    getResizedColumnsKey(code: string): string {
        return RESIZED_COLUMNS + code;
    }

    saveOrderedFields(code: string, fields: FieldConfigDTO[]): void {
        localStorage.setItem(this.getKey(this.getOrderFieldsKey(code)), JSON.stringify(fields));
    }

    saveResizedColumns(code: string, resizedColumns: { [index: string]: number | string }) {
        localStorage.setItem(this.getKey(this.getResizedColumnsKey(code)), JSON.stringify(resizedColumns));
    }

    resetResizedColumns(code: string) {
        localStorage.removeItem(this.getKey(this.getResizedColumnsKey(code)));
    }

    getResizedColumns(code: string) {
        const resizedColumns = localStorage.getItem(this.getKey(this.getResizedColumnsKey(code)));
        return resizedColumns ? JSON.parse(resizedColumns) : {}

    }

    clearOrderedFields(): void {
        Object.keys(localStorage).filter(key => key.startsWith(this.getKey(ORDERED_FIELDS))).forEach(key => {
            const storageKey = this.getKey(key)
            localStorage.removeItem(storageKey)
            console.log('cleared ordered field ', storageKey)
        })
        Object.keys(localStorage).filter(key => key.startsWith(this.getKey(RESIZED_COLUMNS))).forEach(key => {
            const storageKey = this.getKey(key)
            localStorage.removeItem(storageKey)
            console.log('cleared ordered field ', storageKey)
        })
    }

    public saveTheme = (theme: BaseTheme): void => {
        localStorage.setItem(this.getKey(LOCAL_THEME), JSON.stringify(theme));
    }

    public hasTheme = (): boolean => {
        let theme: string | null = localStorage.getItem(this.getKey(LOCAL_THEME));
        return isDefined(theme)
    }

    public getTheme = (): BaseTheme => {
        let theme: string | null = localStorage.getItem(this.getKey(LOCAL_THEME));
        if (!theme) {
            try {
                this.saveTheme(defaultTheme)
            } catch (err) {
                console.error("Failed to save theme")
            }
            return defaultTheme;
        }
        try {
            const themeObj = JSON.parse(theme) as BaseTheme
            return themeObj
        } catch (err) {
            console.error("Failed to parse theme")
            this.saveTheme(defaultTheme)
            return defaultTheme;
        }
    }

    /**
     *
     */
    public getThemeId = (): string | undefined => {
        const value = localStorage.getItem(this.getKey(LOCAL_THEME_ID));
        // @ts-ignore
        //set null as undefined
        return isDefined(value) ? value : undefined
    }

    /**
     *
     * @param themeId
     */
    public saveThemeId = (themeId: string): void => {
        localStorage.setItem(this.getKey(LOCAL_THEME_ID), themeId)
    }

    /**
     * Get date of last server update of theme
     * @return Date of last (server) update of theme or undefined if not known
     */
    public getThemeLastUpdated = (): Date | undefined => {
        const value = localStorage.getItem(this.getKey(LOCAL_THEME_LAST_UPDATED));
        // @ts-ignore
        //set null as undefined
        return isDefined(value) ? new Date(value) : undefined
    }

    /**
     * Saves date when theme was last updated on server
     * @param themeLastUpdated The last date that the theme was updated on server, enables theme to be refreshed if any changes
     */
    public saveThemeLastUpdated = (themeLastUpdated: Date): void => {
        localStorage.setItem(this.getKey(LOCAL_THEME_LAST_UPDATED), themeLastUpdated.toISOString())
    }
    public removeThemeLastUpdated = (): void => {
        localStorage.removeItem(this.getKey(LOCAL_THEME_LAST_UPDATED))
    }


    public saveDashboardLayout = (key: string, value: any) => {
        localStorage.setItem(this.getKey(LOCAL_DASHBOARD_LAYOUT), JSON.stringify({
            [key]: value
        }));
    }

    public getDashboardLayout = (key: string): any => {
        let ls: any = {};
        try {
            ls = JSON.parse(localStorage.getItem(this.getKey(LOCAL_DASHBOARD_LAYOUT)) || '{}');
        } catch (e) {
        }
        return ls[key];
    }
}

const guslStorage = new GuslStorage();

export {guslStorage};

export default GuslStorage;
