import styled, {ThemeProps} from 'styled-components';

interface MenuBarProperties extends ThemeProps<any> {
    extraClassName?: string | undefined
}

export const MenuBarStyled = styled.div.attrs((props: MenuBarProperties) => ({
    className: 'd-flex justify-content-start align-items-center' + (props.extraClassName ? props.extraClassName : '')
}))<MenuBarProperties>`
    color:${props => props.theme.navigation.dropdownMenu.color};
`

// export const ActionButtonStyled = styled(ActionButton).attrs((props: MenuBarProperties) => ({}))<MenuBarProperties>`
//     :hover {
//         color:${props => props.theme.navigation.dropdownMenu.hoverButtonColor};
//         .action_icon {
//             color:${props => props.theme.navigation.dropdownMenu.hoverButtonColor};
//         }
//     }
// border: 1px solid var(--MUTED);
// `


export interface ActionButtonProperties extends ThemeProps<any> {
    colorName: string,
    onClick?: Function,
    notBordered?: boolean,
    notRounded?: boolean
    isAction?: boolean
}

export const ActionButtonStyled = styled.button.attrs((props: ActionButtonProperties) => ({
    onClick: props.onClick,
}))<ActionButtonProperties>`
    background-color:${props => props.isAction ? props.theme.action.bgColor : props.theme.colors.dark[0]};
    border: 1px solid ${props => props.notBordered ? 'transparent' : props.isAction ? props.theme.action.borderColor : props.theme.colors.dark[1]};
    color: ${props => {
    if (props.colorName) {
        const clr = props.theme.action.colors[props.colorName === 'default' ? 'defaultColor' : props.colorName ]
        return clr ? clr : props.theme.action.colors.default
    } else {
        if (!props.isAction) {
            return props.theme.table.menuButtonHoverBgColor
          // return props.theme.colors.dark[1]
        } else {
          return props.theme.table.menuButtonHoverBgColor
            // return props.theme.colors.dark[0]
        }
    }
}};
    padding:4px 4px;
    border-radius:${props => props.notRounded ? '0px' : '4px'};
    :hover{
     background-color:${props => props.isAction ? props.theme.action.hoverBgColor : props.theme.colors.accentColorMuted};
     border: 1px solid ${props => props.notBordered ? 'transparent' : props.isAction ? props.theme.action.hoverBorderColor : props.theme.colors.accentColor};
     color:${props => props.isAction ? props.theme.action.hoverColor : props.theme.colors.accentColor};
    }
    margin-right: ${props => props.withMargin ? '10px' : '0'};
    
`
