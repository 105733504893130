import React, {ChangeEventHandler} from "react";

type Props = {
    inputId?: string,
    name?: string,
    value?: any,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    required?: boolean,
    checked?: boolean,
    disabled?: boolean,
    invalid?: boolean,
    readOnly?: boolean,
    className?: string

}
export default function Checkbox({
                                     inputId,
                                     name,
                                     value,
                                     onChange,
                                     required,
                                     checked,
                                     disabled,
                                     invalid,
                                     readOnly,
                                     className
                                 }: Props) {

    return (

        <input type="checkbox"
               id={inputId}
               name={name}
               value={value}
               onChange={onChange}
               required={required}
               checked={checked}
               disabled={disabled}
               readOnly={readOnly}
               onClick={(e) => e.stopPropagation()}
               className={'form-check-input ' + (invalid ? 'invalid_input' : '')}
        />

    );
}
