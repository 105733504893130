import styled from 'styled-components';

interface LabelProperties {
    asUppercase: boolean
}

export const LabelStyled = styled.label.attrs((props: LabelProperties) => ({}))<LabelProperties>`
  ${props => props.asUppercase && 'text-transform: uppercase;'}
  padding: 0px 0px 4px 10px;
  font-size: 14px !important;
  font-weight: normal !important;
`
