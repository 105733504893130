import styled, {ThemeProps} from 'styled-components';

interface TextFilterWrapperProps extends ThemeProps<any> {

    onClick: Function,
    isActive: boolean
}
// MK 31/08/2023 flipped active colors
export const TextFilterWrapperStyled = styled.div.attrs((props: TextFilterWrapperProps) => ({
    onClick: props.onClick
}))
    `
        margin:0px 0px 7px 5px;
        padding:0px 5px 2px 5px;
        font-size:14px;
        border-bottom: 1px solid ${props => props.theme.colors.dark[0]};
        color:${props => !props.isActive ? props.theme.colors.accentColorMuted : props.theme.colors.accentColorLight};
    `
export const TextFilterValueStyled = styled.div.attrs((props) => ({
}))
    `
      float:right;
    `
