import {DateRangeUnitDO} from "../../types";

export const date_units: DateRangeUnitDO[] = [
    {
        unit: 'd',
        label: 'Day'
    },
    {
        unit: 'w',
        label: 'Week'
    },
    {
        unit: 'M',
        label: 'Month'
    },
    {
        unit: 'y',
        label: 'Year'
    }
];

export const time_units: DateRangeUnitDO[] = [
    // {
    //     unit: 's',
    //     label: 'Second'
    // },
    // {
    //     unit: 'm',
    //     label: 'Minute'
    // },
    {
        unit: 'h',
        label: 'Hour'
    },
];

export const date_time_units: DateRangeUnitDO[] = [...time_units, ...date_units];
