import React from 'react';
import {LoadingSpinnerStyled, ThreeDotsStyled} from './styled';

export type LoadingSpinnerProps = {
    size?:number,
    flip?:boolean,
    loaded?:boolean
}
export default function LoadingSpinner({size,flip,loaded}:LoadingSpinnerProps) {

    return (
        <LoadingSpinnerStyled loaded={loaded} key={'spinner_'+loaded}>
            <ThreeDotsStyled
                flip={flip}
                height={size || 100}
                width={size || 100}
                // timeout={3000} // 3 secs
            />
        </LoadingSpinnerStyled>
    );
};
