// -------------- actions

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Tab} from "./types";

// -------------- states
export interface GuslTabbedFormInitPayload {
    code: string;
    tabs: Tab[];
}

export interface GuslTabbedFormTabSelectedPayload {
    code: string;
    tabNumber: number;
}

// -------------- states
interface GuslTabbedFormState {
    [id: string]: TabbedFormState
}

export interface TabbedFormState {
    code: string,
    tabs: Tab[];
    activeTab: Tab | undefined;
}

const initialState: GuslTabbedFormState = {}

// -------------- utils
const createDefault = (code: string): TabbedFormState => {
    return {
        code: code,
        tabs: [],
        activeTab: undefined
    }
}

const getTabbedFormState = (state: GuslTabbedFormState, code: string): TabbedFormState => {
    let entry: TabbedFormState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry;
}

const loadInitValues = (entry: TabbedFormState, values: GuslTabbedFormInitPayload) => {
    entry.code = values.code;
    entry.tabs = values.tabs;

    // find if any set to active
    let firstActive = -1;
    let index = 0;
    (entry.tabs || []).forEach((tab: Tab) => {
        if (tab.active) {
            if (firstActive === -1) {
                firstActive = index;
            }
            index++;
        }
    })
    if (firstActive === -1) {
        firstActive = 0;
    }

    // reset all to in-active
    index = 0;
    (entry.tabs || []).forEach((tab: Tab) => {
        tab.tabNumber = index;
        tab.active = index === firstActive;
        if (index === firstActive) {
            entry.activeTab = tab
        }
        index++;
    })
}

function changeTab(entry: TabbedFormState, payload: GuslTabbedFormTabSelectedPayload) {
    (entry.tabs || []).forEach((tab: Tab) => {
        tab.active = payload.tabNumber === tab.tabNumber;
        if (payload.tabNumber === tab.tabNumber) {
            entry.activeTab = tab
        }
    })
}


// -------------- config
export const guslTabbedFormSlice = createSlice({
    name: 'guslTabbedFormSlice',
    initialState,
    reducers: {
        initTabs(state, action: PayloadAction<GuslTabbedFormInitPayload>) {
            const entry: TabbedFormState = getTabbedFormState(state, action.payload.code)
            loadInitValues(entry, action.payload)
            state[action.payload.code] = entry
        },
        tabSelected(state, action: PayloadAction<GuslTabbedFormTabSelectedPayload>) {
            const entry: TabbedFormState = getTabbedFormState(state, action.payload.code)
            changeTab(entry, action.payload)
            state[action.payload.code] = entry
        },
    }
})

export const {
    initTabs,
    tabSelected
} = guslTabbedFormSlice.actions


export default guslTabbedFormSlice.reducer
