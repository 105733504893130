import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import AvatarImage from "../../../assets/images/account.png";
import {getSettings, performValidation} from '../../../services/ValidationService';
import {assignReferences, noop, RunOnceEffect} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import {AvatarWrapperStyled} from './styled';

export const AvatarField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'AvatarField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode);
    const [formValue, setFormValue] = useState<string>(properties?.data || '');
    const valueRef = useRef(properties?.data);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
    }, [properties])

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            fieldValue,
            setSubmitted,
            setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        return (
            <></>
        )
    }

    const renderFormView = (): React.ReactElement => {

        const onDataInputChange = (value: string) => {

            if (properties.isNumber) {
                try {
                    const number = parseInt(value, 10);
                    if ('' + number === value) {
                        setFormValue(value);
                        properties.onChange(properties.fieldConfig.name, parseInt(value, 10));
                    }
                } catch (err) {
                    // log.warn(className, 'WRN001', 'not a number', value);
                }
            } else {
                setFormValue(value);
                properties.onChange(properties.fieldConfig.name, valueRef?.current?.value);
            }
        }

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        // @ts-ignore
        return (
            <>
                {!hideField && <>
                    <AvatarWrapperStyled>
                            {formValue &&
                            <img src={formValue} alt="avatar" style={{width: 100, height: 100}}/>}
                            {!formValue &&
                                <img src={AvatarImage} alt="avatar" style={{width: 100, height: 100}}/>}
                    </AvatarWrapperStyled>
                </>}
            </>
        )
    }
    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
