import React, { useMemo, useState } from "react";
import BSChart from "./BSChart";
import { NewTickerDO } from "../types";
import BSInfo from "./BSInfo";
import { ButtonGroupStyled, BuySellButtonStyled, WidgetWrapperStyled } from "../../styled_v2";
import { ElementWithTooltip } from "../../../element-with/element-with-tooltip/ElementWithTooltip";
import BSEvents from "./BSEvents";
import { PreOrderDTO } from "../../types";

export interface AcuityWidgetsDO {
  ticker: NewTickerDO;
  widgetExpanded: boolean;
  setWidgetExpanded: Function;
  acuityCode: PreOrderDTO["acuityCode"];
}

export type AcuityWidgetType = "chart" | "info" | "events";
export default function Widgets({ ticker, widgetExpanded, setWidgetExpanded, acuityCode }: AcuityWidgetsDO) {

  const [className] = useState("AcuityWidgets-" + new Date().getTime());
  const chart: React.JSX.Element = <BSChart acuityCode={acuityCode} key={"chart-" + ticker?.displayTicker}
                                            ticker={ticker} />;
  const info: React.JSX.Element = <BSInfo acuityCode={acuityCode} key={"info-" + ticker?.displayTicker}
                                          ticker={ticker} />;
  const events: React.JSX.Element = <BSEvents acuityCode={acuityCode} key={"events-" + ticker?.displayTicker}
                                              ticker={ticker} />;

  const showWidgets: boolean = typeof acuityCode !== "undefined";
  const [currentWidgetType, setCurrentWidgetType] = useState<AcuityWidgetType>("chart");
  // useMemo not working as expected...
  const widgetMap = useMemo(() => {
    return { chart, info, events };
  }, [ticker]);

  const currentWidget = widgetMap[currentWidgetType];


  return (
    <WidgetWrapperStyled>
       <div className="row g-0">
         {showWidgets &&
        <div className="col-5 col-lg-2 mb-3 ">
          <ButtonGroupStyled className="row g-0 row-cols-3 d-flex justify-content-center">
            <BuySellButtonStyled
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setCurrentWidgetType("chart")}
              active={currentWidgetType === "chart"}
            >Chart
            </BuySellButtonStyled>
            <BuySellButtonStyled
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setCurrentWidgetType("info")}
              active={currentWidgetType === "info"}
            >Info
            </BuySellButtonStyled>
            <BuySellButtonStyled
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setCurrentWidgetType("events")}
              active={currentWidgetType === "events"}
            >Events
            </BuySellButtonStyled>
          </ButtonGroupStyled>
        </div>
         }
        <div className="col-4 col-lg-1 ms-auto mb-3">

          <ButtonGroupStyled className="row g-0 row-cols-2 d-flex justify-content-center">
            <BuySellButtonStyled
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setWidgetExpanded(true)}
              active={widgetExpanded}
            ><ElementWithTooltip
              element={<i className="fa-solid fa-arrows-left-right-to-line" />}
              tooltip={<span>{widgetExpanded ? "Expanded" : "Expand"}</span>} />
            </BuySellButtonStyled>
            <BuySellButtonStyled
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setWidgetExpanded(false)}
              active={!widgetExpanded}
            ><ElementWithTooltip
              element={<i className="fa-solid fa-minimize"></i>}
              tooltip={<span>{widgetExpanded ? "Collapse" : "Collapsed"}</span>} />
            </BuySellButtonStyled>
          </ButtonGroupStyled>
        </div>
      </div>


      <div>
        {showWidgets && currentWidget}
        {!showWidgets && <div className={"text-center mt-3"}>
          <i className="fa-solid fa-chart-line fa-5x"></i>
          <p className="lead mt-3">coming soon...</p>
          <br />
          <i className="fa-solid fa-circle-info fa-5x"></i>
          <p className="lead mt-3">coming soon...</p>
          <br />
          <i className="fa-solid fa-calendar-days fa-5x"></i>
          <p className="lead mt-3">coming soon...</p>
        </div>}
      </div>

    </WidgetWrapperStyled>

  );
}

