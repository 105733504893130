import {RotatingLines} from 'react-loader-spinner';
import styled, {ThemeProps} from 'styled-components';

const TEAM_NAME_WIDTH = 150
const ODDS_WIDTH = 80
const ODDS_BUTTON_WIDTH = 120
const COL_PADDING = 5
const REQUEST_BUTTON_WIDTH = 230

const STAKE_WIDTH = TEAM_NAME_WIDTH + ODDS_WIDTH

export const ButtonsWrapperStyled = styled.div.attrs((props: ButtonProperties) => ({}))
    `
      display: flex;
      padding: 10px;
    `

interface ButtonProperties extends ThemeProps<any> {
    isSelected?: boolean;
    large?: boolean
}

export const ButtonStyled = styled.div.attrs((props: ButtonProperties) => ({
    className: 'border'
}))<ButtonProperties>`
  color: ${props => props.isSelected ? '#000' : props.theme.table.headerColor} !important;
  background-color: ${props => props.isSelected ? '#daffdd' : '#7d8187'};
  width: ${props => props.large ? REQUEST_BUTTON_WIDTH : ODDS_BUTTON_WIDTH}px;
  min-width: ${props => props.large ? REQUEST_BUTTON_WIDTH : ODDS_BUTTON_WIDTH}px;
  max-width: ${props => props.large ? REQUEST_BUTTON_WIDTH : ODDS_BUTTON_WIDTH}px;
  text-align: center;
  vertical-align: middle;
  // line-height: 40px;
  line-height: 2.0;
  margin: auto;
  padding: 3px;
`

export const ButtonLabelStyled = styled.div.attrs((props: ButtonProperties) => ({}))<ButtonProperties>`
  color: ${props => props.isSelected ? '#000' : props.theme.table.headerColor} !important;
  margin: auto;
  vertical-align: middle;

  div {
    color: ${props => props.isSelected ? '#000' : props.theme.table.headerColor} !important;
  }
`

export const LabelStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.headerColor} !important;
  vertical-align: middle;
  margin: auto;
  display: inline-block;
  vertical-align: middle;
`
export const SubLabelStyled = styled.span.attrs((props) => ({}))`
    //   color: ${props => props.theme.colors.warning} !important;
  color: ${props => props.theme.table.headerColor} !important;
  font-size: 12px;
  padding: 0px;
  margin: auto;
  vertical-align: middle;
  display: block;
`


interface SpinnerStyledProperties extends ThemeProps<any> {
    flip?: boolean
}

export const SpinnerStyled = styled(RotatingLines).attrs((props: SpinnerStyledProperties) => ({
    color: props.theme.spinner.color,
    // strokeColor: props.theme.spinner.color,
    strokeColor: '#fff',
    strokeWidth: "5",
    animationDuration: "0.75",
    width: 35,
    visible: true

}))<SpinnerStyledProperties>`
`

export const TableStyled = styled.table.attrs((props) => ({}))`
  display: table;
  margin: auto;
`

export const TableBodyStyled = styled.tbody.attrs((props) => ({}))`
  display: table-row-group;
`

export const RowStyled = styled.tr.attrs((props) => ({}))`
  display: table-row;
`

/*
  width: ${props => TEAM_NAME_WIDTH}px;
  min-width: ${props => TEAM_NAME_WIDTH}px;
  max-width: ${props => TEAM_NAME_WIDTH}px;

 */
export const TeamNameColStyled = styled.td.attrs((props) => ({}))`
  padding: ${props => COL_PADDING}px;
  vertical-align: middle;
  display: table-cell;
  text-align: left;
  padding-right: 10px;
  min-width: 200px;
`

export const OddsColStyled = styled.td.attrs((props) => ({}))`
  padding: ${props => COL_PADDING}px;
  vertical-align: middle;
  display: table-cell;
  width: ${props => ODDS_WIDTH}px;
  min-width: ${props => ODDS_WIDTH}px;
  max-width: ${props => ODDS_WIDTH}px;
`

export const ColStyled = styled.td.attrs((props) => ({}))`
  padding: ${props => COL_PADDING}px;
  vertical-align: middle;
  display: table-cell;
`


export const ColumnLabelStyled = styled.div.attrs((props) => ({}))`
`


export const BreadcrumbsStyled = styled.div.attrs((props) => ({}))
    `

      background-color: ${props => props.theme.panel.titleBgColor};
      padding: 10px;
      color: ${props => props.theme.panel.titleColor};
      font-size: 12px;
    `
export const StakeWrapperStyled = styled.div.attrs((props) => ({}))`
  padding: 20px 0px 20px 0px;
`

export const StakeLabelStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.headerColor};
  font-size: ${props => props.theme.table.headerFontSize};
`
export const StakeInputStyled = styled.div.attrs((props) => ({}))`

  input[type='number'] {
    height: 50px !important;
    border-style: none;
    border-bottom: ${props => props.theme.table.gridBorder};

  }
`
export const ContentPanelStyled = styled.div.attrs((props) => ({}))`
  padding: 5px;
`

export const OutcomesPanelStyled = styled.div.attrs((props) => ({}))`
  padding: 5px;
  text-align: center;
`

export const BetPlacedPanelStyled = styled.div.attrs((props) => ({}))`
  padding: 20px;
  background-color: #daffdd;
`

export const TitleStyled = styled.div.attrs((props) => ({}))`
  font-size: 24px;
  font-wieght: bold;
  color: #000;
  padding-bottom: 10px;
`

export const EventNameStyled = styled.div.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;
`

export const OutcomeNameStyled = styled.div.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;
  font-weight: bold;
`
export const ReferenceIdStyled = styled.div.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;
`

export const DatePlacedStyled = styled.div.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;
`

export const RiskGainStyled = styled.div.attrs((props) => ({}))`
  padding: 20px 0px 10px 0px;
`

export const MoneyContainerStyled = styled.table.attrs((props) => ({}))`
  width: 100%;
  margin: auto;
`

export const MoneyBodyStyled = styled.tbody.attrs((props) => ({}))`
`

export const MoneyWrapperStyled = styled.tr.attrs((props) => ({}))`
`

export const MoneyLeftWrapperStyled = styled.td.attrs((props) => ({}))`
`

export const MoneyRightWrapperStyled = styled.td.attrs((props) => ({}))`
`

export const MoneyLabelStyled = styled.span.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;
  font-weight: bold;
`
export const MoneyValueStyled = styled.span.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;

  div {
    display: inline-block;
    padding-left: 5px;
    font-size: 16px;
    color: #000 !important;
  }
`
export const MoneyStyled = styled.div.attrs((props) => ({}))`
  font-size: 16px;
  color: #000;
`
export const FieldStyled = styled.div.attrs((props) => ({}))`
  font-size: 14px;
  color: #000;
  padding: 5px;
`

export const FieldLabelStyled = styled.div.attrs((props) => ({}))`
  font-size: 14px;
  color: #000;
  display: inline-block;
  padding-right: 5px;
`
export const ValueStyled = styled.div.attrs((props) => ({}))`
  font-size: 14px;
  color: #000;
  display: inline-block;

  div {
    color: #000;
    background-color: inherit;
  }
`
export const BetPlacedBreadcrumbsStyled = styled.div.attrs((props) => ({}))
    `
      padding: 5px;
      color: #000;
      font-size: 14px;
    `
export const LinkWrapperStyled = styled.div.attrs((props) => ({}))
    `
      padding: 5px;
      color: #000;
      font-size: 14px;
      width: 100%;
      margin: auto;
      //border: solid 1px #000
    `
export const LinkLabelStyled = styled.div.attrs((props) => ({}))
    `
      text-align: center;
      padding: 10px;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    `

export const BetSlipPanelStyled = styled.div.attrs((props) => ({
    id: 'bet_slip_panel'
}))
    `
    `

export const BrokerBetsStyled = styled.div.attrs((props) => ({
    id: 'broker_bets'

}))
    `

    `

export const BrokerNoBetsStyled = styled.div.attrs((props) => ({}))
    `
      color: #000 !important;
      font-size: 14px;
      padding: 10px;
      margin: 0px auto;
      text-align: center;

    `

export const BrokerBetStyled = styled.div.attrs((props) => ({}))
    `
      border-radius: 5px;
    `

interface BetSlipTabPanelProperties {
    height: number
    startPosition: number
    isMobile:boolean
}

export const BetSlipTabPanelStyled = styled.div.attrs((props: BetSlipTabPanelProperties) => ({
    id: 'bet_slip_tab_panel'
}))<BetSlipTabPanelProperties>

    `
        // height: ${props => props.height}px;
      ${props => props.isMobile && 'height: 370px;overflow: hidden;padding: 5px;'}
      ${props => !props.isMobile && 'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + props.startPosition + 'px);overflow: auto;'}

      

      ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
      }
    ;

      ::-webkit-scrollbar-track {
        background: #fff;
      }
    ;

      ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.dark[0]};
        border: 1px solid ${props => props.theme.colors.dark[1]};
        border-radius: 10px;

      }
    ;

      ::-webkit-scrollbar-thumb:hover {
          background: ${props => props.theme.table.titleBgColor};
      }
    ;

    `

export const BetSlipTabsStyled = styled.div.attrs((props) => ({}))
    `
      display: inline-block;
      border-bottom: 1px solid #676767;
      width: 100%;
    `

interface BetSlipTabStyled {
    active: boolean
}

/*
      ${props => props.active && 'text-decoration: underline;'}

 */
export const BetSlipTabStyled = styled.div.attrs((props: BetSlipTabStyled) => ({}))<BetSlipTabStyled>
    `
      padding: 5px;
      font-size: 12px;
      display: inline-block;
      ${props => props.active && 'font-weight: bold;'}

    `

export const QuoteEventNameStyled = styled.div.attrs((props) => ({}))
    `
      background-color: ${props => props.theme.navigation.topNavbarBgColor};
      color: ${props => props.theme.navigation.topNavbarColor};
      padding: 10px;
    `

interface InputStyledProps extends ThemeProps<any> {
    noValue: boolean;
    submitted: boolean;
}

export const StakeAmountInputStyled = styled.input.attrs((props: InputStyledProps) => ({
    className: "form-control form-control-sm bg-transparent rounded-0 text-light  " +
        ((props.submitted && props.noValue) ? 'yellow' : '')
}))<InputStyledProps>`
  color: #000 !important;
  font-size: ${props => props.theme.table.columnFontSize};

  .form-control {
    border-bottom: none !important;
  }
`

interface OpenBetsTabWrapperProperties {
    isMobile: boolean
}

export const OpenBetsTabWrapperStyled = styled.div.attrs((props: OpenBetsTabWrapperProperties) => ({
    id: 'open-bet-tab'
}))<OpenBetsTabWrapperProperties>
    `
      ${props => props.isMobile && 'height: 370px;overflow: hidden;'}
      ${props => !props.isMobile && 'height: 100%;overflow: auto;'}
      
      color: #000 !important;

      ::-webkit-scrollbar-track {
        background: #fff !important;
      }


      #fl_scroll {
        ${props => props.isMobile && 'height: 336px;'}
      }

      #fl_l {
        ${props => props.isMobile && 'height: 336px;'}
        min-height: revert;
      }

      #fl_panel {
        padding: 0px;
        overflow: auto;

        ::-webkit-scrollbar {
          width: var(--scrollbar-width);
          height: var(--scrollbar-height);
          background: #fff;
        }

        ::-webkit-scrollbar-track {
          background: #fff;
        }

        ::-webkit-scrollbar-thumb {
          background: #000;
          border: 1px solid #fff;;
          border-radius: 10px;

        }

        ::-webkit-scrollbar-thumb:hover {
          background: #fff;
        }

      }

      #fl_lp {
        ${props => props.isMobile && 'height: 330px;'}
        ${props => !props.isMobile && 'height: auto;'}

        overflow: hidden;

        ::-webkit-scrollbar {
          width: var(--scrollbar-width);
          height: var(--scrollbar-height);
          background: #fff;
        }

        ::-webkit-scrollbar-track {
          background: #fff;
        }

        ::-webkit-scrollbar-thumb {
          background: #000;
          border: 1px solid #fff;;
          border-radius: 10px;

        }

        ::-webkit-scrollbar-thumb:hover {
          background: #fff;
        }
      }

      div {
        color: #000 !important;
      }

      div [id^='section_items_'] {
        display: inline-block;
        color: red;
      }

      div [id^='card_mobileView_'] {
        ${props => !props.isMobile && 'width: 365px;'}
        border-bottom: 1px solid #b7b7b7;
        margin-bottom: 15px;
      }



    `
export const BetSlipTabScrollContainerStyled = styled.div.attrs((props) => ({
    id: 'bs_tab_scroll_cont'
}))`

`
