import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {getSettings, performValidation} from '../../../services/ValidationService';
import {cleanWidthFromStyle, getStyle} from '../../../utils/CssUtils';
import {arrayIsEmpty, isObject} from '../../../utils/TypeCheckers';
import {assignReferences, noop, RunOnceEffect} from '../../../utils/Utils';
import {ActionConfigDTO, FieldProperties, FormMode} from '../../types';
import {setActiveAction} from '../action-dialog/actionSlice';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {TextCommentStyled} from '../text-comment/styled';
import {FloatingFormStyled} from '../text/styled';
import {CommentTableStyled} from './styled';

type CommentBO = {
    id: string;
    comment: string;
    username: string;
    removed: boolean;
    dateCreated: string;
    dateRemoved: string;
}
type CommentsBO = {
    comments: CommentBO[];
}

export const CommentField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'CommentFieldField-' + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<CommentsBO>(properties?.data || {comments: []});
    const [formValue, setFormValue] = useState<CommentsBO>(_guslFormState?.getFieldValue(properties)  ||  properties.data || {comments: []});

    const valueRef = useRef(properties?.data);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [comments, setComments] = useState<CommentBO[]>([]);
    const [latestComment, setLatestComment] = useState<string>('');
    const dispatch = useAppDispatch();
    const [actionLink, setActionLink] = useState<ActionConfigDTO| undefined>(undefined);
    const [numberRows, setNumberRows] = useState<number>(4);
    const [fieldWidth, setFieldWidth] = useState<number>(300);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    useEffect(() => {
        setFormValue(properties?.data || '')

        if (isObject(properties?.data)) {
            const data: CommentsBO = properties?.data || {comments: []} as CommentsBO
            // @ts-ignore
            setComments(data.comments || [])
            if (data.comments.length > 0) {
                setLatestComment((data?.comments[0].comment || '').replace('\n',' '));
                let count = ((data?.comments[0].comment || '').match(/\n/g) || []).length;
                setNumberRows(count + 4)
            }
        }

        if (properties.rowData && properties.rowData.actions) {
            const actions: ActionConfigDTO[] = properties?.rowData?.actions || []
            actions.filter(action => action.buttonLabel === 'View comments').forEach(action=> setActionLink(action))
        }

    }, [properties,formMode])

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (valueRef?.current) {
                    if (valueRef?.current?.getBoundingClientRect) {
                        // @ts-ignore
                        const rect = valueRef?.current?.getBoundingClientRect();
                        if (rect) {
                            const w = window.innerWidth - rect.left - 200;
                            console.log('w=>', w)
                            setFieldWidth(w > 0 ? w : 300);
                        } else {
                            setFieldWidth(300);
                        }
                    }
                }
            }, 100)
        });
    }, [properties.formMode]);

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            fieldValue,
            setSubmitted,
            setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderCommentDetails = (): React.ReactElement => {
        return (
            <p>hi</p>
        )
    }
    const renderComments = (): React.ReactElement => {
        return (<></>)
        // return (
        //     <CommentsContainerStyled>
        //         <CommentsDetailsStyled>
        //             <CommentsHeaderDetailsStyled>Comments
        //                 <CloseIconStyled role={'button'}
        //                                  onClick={() => setShowComments(false)}/>
        //             </CommentsHeaderDetailsStyled>
        //             <CommentsInfoStyled>{renderCommentDetails()}</CommentsInfoStyled>
        //         </CommentsDetailsStyled>
        //     </CommentsContainerStyled>
        // )

    }

    const renderTableView = (): React.ReactElement => {
        //
        // const onClick = () => {
        //     console.log('actionLink',actionLink)
        //     console.log('properties',properties)
        //      // dispatch(setOpenModalActionId(actionLink?.id as string));
        //     if (actionLink) {
        //         dispatch(setActiveAction(actionLink))
        //         // dispatch(toggleActionItem({
        //         //     rowId: properties?.rowData?.id || '1',
        //         //     actionItem: actionLink,
        //         //     showType: ShowActionType.MODAL
        //         // }))
        //     }
        // }
        //
        if (arrayIsEmpty(comments)) {
            return (
                <></>
            )
        }
        // onClick={() => onClick()}
        const style = cleanWidthFromStyle(getStyle(properties.fieldConfig.tableCss, ''))

        return (
            <CommentTableStyled >
            <span className="badge bg-primary"
                  // onClick={() => setShowComments(!showComments)}
            >
                {comments.length}
            </span>&nbsp;
               <span className="" style={style} dangerouslySetInnerHTML={{__html: latestComment}}></span>

                {/*<TextCommentStyled*/}
                {/*    style={style}*/}
                {/*    numberRows={numberRows}*/}
                {/*    fieldWidth={fieldWidth}*/}
                {/*    id={properties?.fieldConfig?.name || 'def'}*/}
                {/*    key={properties?.fieldConfig?.name || 'def'}*/}
                {/*    ref={valueRef}*/}
                {/*    value={latestComment||''}*/}
                {/*    autoFocus={properties.isFirstField}*/}
                {/*    readOnly={true}*/}
                {/*    disabled={true}*/}
                {/*    submitted={submitted}*/}
                {/*    noValue={!formValue}*/}
                {/*/>*/}

                {/*{latestComment}*/}
                {/*{showComments && renderComments()}*/}
            </CommentTableStyled>
        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        // @ts-ignore
        return (
            <>
                {!hideField && <>
                    <FloatingFormStyled>
                        <p>Field</p>
                        <FieldLabel properties={properties}/>
                        {submitted && errorMessage &&
                            <small className="yellow">{errorMessage}</small>}
                    </FloatingFormStyled>
                </>}
            </>
        )
    }
    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
