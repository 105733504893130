export const RADAR_DEFAULT_OPTIONS = {

    maintainAspectRatio: false,
    responsive: true,
    borderWidth: 1,
    scale: {
        ticks: {
            beginAtZero: true
        },
    },
    plugins: {
        legend: {
            position: 'left' as const,
            display: false,
            labels: {
                boxWidth: 12,
                color: '#fafafa',
                // usePointStyle:true,
                // rotation:30
                // pointStyle:'circle',
                // filter: (legendItem: { text: string; datasetIndex: any; },
                //          data: { labels: string | string[]; datasets: { labels: { [x: string]: any; }; }[]; }) => {
                //     console.log('radar',{legendItem,data})
                //     // First, retrieve the data corresponding to that label
                //     const label = legendItem.text
                //     const lIndex = legendItem.datasetIndex
                //      console.log('radar index',{lIndex})
                //     const newText = data.datasets[0].labels[lIndex]
                //     // @ts-ignore
                //     const qtd = data.datasets[0].data[lIndex]
                //     // Second, mutate the legendItem to include the new text
                //     legendItem.text = `${newText}:(${qtd})`
                //     // Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                //     return true
                // }

            }
        },
        tooltip: {

            callbacks: {
                // title: function (context: { formattedValue: string; }[]) {
                //     console.log('context title', context)
                //     // @ts-ignore
                //     return context[0].label + ':' + context[0].formattedValue;
                //
                // },
                label: function () {
                    return '';
                }
            }
        },

    },
    scales: {
        x: {
            display: false,
            grid: {
                display: false
            }
        },
        y: {
            display: false,
            grid: {
                display: false
            }
        },
        r: {

            ticks: {
                color: '#9aa9b2',
                showLabelBackdrop: false,
                font: {
                    size: 10
                }
            }, pointLabels: {
                color: '#9aa9b2'
            },
            angleLines: {
                color: '#fafafa',
                lineWidth: 0.1,
                borderDash: [2, 3, 4],
            },
            grid: {
                display: false,
                color: '#e30f0f',
                borderWidth: 2
            }
        }
    },

}
