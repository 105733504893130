import React from 'react';
import {useNavigate} from 'react-router-dom';
import {constructUrl} from '../../../../../utils/Utils';
import PagedResponse from '../../../../paged-response/PagedResponse';
import {
    ArticleSubjectStyled,
    ArticleSummaryStyled,
    ArticleTimeStyled,
    ArticleTitleStyled
} from '../../articles-view/styled';
import {DEAL_PAGE} from '../../deal-view/DealView';
import {OttoBoxStyled, ScrollBox} from '../../styled';
import {OSSArticleDTO, UpcomingEventDTO} from '../../types';
import {CollectionHeaderStyled} from '../styled';

const SELECT_URL = '/otto-articles/{dealType}/paged-popular'

interface PopularArticlesProperties {
    code: string,
    dealType: string,
    contentsHeight: string
}

const PopularArticles = ({code, dealType, contentsHeight}: PopularArticlesProperties): React.ReactElement => {
    const navigate = useNavigate()

    const onArticleClick = (selectedEvent: OSSArticleDTO | UpcomingEventDTO) => {
        navigate(DEAL_PAGE + '/' + selectedEvent.dealId + '/' + selectedEvent.id)
    }

    const renderItem = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleTitleStyled
                key={'t_' + idx + article.id}
                large={true}
                active={false}
                onClick={() => {
                    onArticleClick(article)
                }}>
                <ArticleSummaryStyled>
                    <ArticleTimeStyled>{article.articleTime}</ArticleTimeStyled>
                    {article?.subject && <ArticleSubjectStyled
                        dangerouslySetInnerHTML={{__html: article?.subject || ''}}></ArticleSubjectStyled>}
                </ArticleSummaryStyled>
            </ArticleTitleStyled>
        )
    }

    return (
        <OttoBoxStyled>
            <CollectionHeaderStyled>Popular</CollectionHeaderStyled>
            <ScrollBox id={'scrollable_' + code} height={contentsHeight}>
                <PagedResponse code={code}
                               infinityScroll={false}
                               selectUrl={constructUrl(SELECT_URL, {dealType})}
                               renderItem={renderItem}
                />
            </ScrollBox>
        </OttoBoxStyled>
    )
}

export default PopularArticles
