import React, {useState} from 'react'
import {Observable} from 'rxjs'
import {CurrencyDTO, SystemNotificationDTO} from '../../components/types';
import {log} from '../../services/LogService';
import {isDefined} from '../../utils/TypeCheckers';
import {RunOnceEffect, safeStream, unSubscribe} from '../../utils/Utils';
// import {CustomerBalanceDTO} from '../../features/wallet/api/types'
import {EnvironmentContext} from '../environment/EnvironmentContext'
import {IEnvironment} from '../environment/types'
import {ReactChildProperties} from '../theme/GuslThemeProvider';
import {BlastContext} from './BlastContext'
import {SimpleMessageBO} from './commands';
// import {CustomerBalanceBO} from './commands'
import DataCache from './DataCache'
import {graphService} from './graph/GraphService';
import {AttachmentInstance} from './types';

export const BlastProvider: React.FC<ReactChildProperties> = ({children}) => {

    const [className] = React.useState<string>(() => 'BlastProvider-' + new Date().getTime())
    const environmentContext = React.useContext(EnvironmentContext)

    const [dataCache] = React.useState<DataCache>(new DataCache())
    const [haveEnvironment, setHaveEnvironment] = useState<boolean>(false)

    const [blastGraphStore] = useState<Map<string, AttachmentInstance>>(new Map<string, AttachmentInstance>())


    RunOnceEffect(() => {
        let subscription = environmentContext.watchEnvironment().subscribe((environment: IEnvironment) => {
            if (environment.loaded) {
                setHaveEnvironment(true)
                dataCache.setEndpoint(environment.wsEndpoint || '')
                dataCache.notificationObservable.subscribe((notification: SystemNotificationDTO) => {
                    log.debug(className, 'MSG002', 'Notification', notification)
                })
            }
        })
        dataCache.blastServiceSubject.subscribe(blastService => {
            graphService.setBlastService(blastService)
        })
        return () => {
            unSubscribe(subscription)
        }
    })


    const login = (idToken: string) => {
        log.debug(className, 'MSG001', 'Login with token:', idToken)
        if (dataCache) {
            dataCache.connectWithToken(idToken)
        }
    }

    const observeNotifications = (): Observable<SystemNotificationDTO> => {
        if (dataCache) {
            return dataCache.notificationObservable
        } else {
            return new Observable<SystemNotificationDTO>((observer) => {
                observer.complete()
            })
        }
    }
    const observeInboundCommands = (): Observable<SimpleMessageBO<any>> => {
        if (dataCache) {
            return dataCache.observeCommands()
        } else {
            console.log('oh not here')
            log.error('BlastProvider', 'ERR001', 'No dataCache exists')
            log.flushLogs()
            return new Observable<SimpleMessageBO<any>>((observer) => {
                observer.complete()
            })
        }
    }

    // const observeBalance = (): Observable<CustomerBalanceDTO> => {
    //   if (dataCache) {
    //     return dataCache.balanceObservable
    //   } else {
    //     return new Observable<CustomerBalanceDTO>((observer) => {
    //       observer.complete()
    //     })
    //   }
    // }

    const observeCollection = (collection: string): Observable<any> => {
        if (dataCache) {
            return dataCache.getCollectionAsObservable(collection)
        } else {
            return new Observable<any>((observer) => {
                observer.complete()
            })
        }
    }

    const observeConnection = (): Observable<boolean> => {
        if (dataCache) {
            return dataCache.observeConnection();
            // return dataCache.connectedWatcher.asObservable()
        } else {
            return new Observable<boolean>((observer) => {
                observer.complete()
            })
        }
    }
    const getCollection = (collection: string): any[] => {
        console.log('---------------------- collection', collection, dataCache)
        if (dataCache) {
            const contents = dataCache.getCollection(collection);
            console.log('---------------------- collection', collection, dataCache, contents)
            if (isDefined(contents)) {
                return contents || []
            } else {
                log.debug(className, 'ERR002', 'Collection not found', collection)
            }
        }
        return []

    }

    const subscribeTopic = (topic: string) => {
        if (dataCache) {
            dataCache.subscribeTopic(topic)
        }
    }

    const unsubscribeTopic = (topic: string) => {
        if (dataCache) {
            dataCache.unsubscribeTopic(topic)
        }
    }

    const disconnect = () => {
        if (dataCache) {
            dataCache.disconnect()
        }
    }

    const attachAndWatch = (path: string): Observable<any> => {
        let instance: AttachmentInstance | undefined = blastGraphStore.get(path)
        if (!instance) {
            instance = {
                path: path,
                numberOfAttachments: 1,
                data: []
            }
            const attachment = graphService.attach(path, instance.data);
            instance.attachment = attachment
            blastGraphStore.set(path, instance)
        } else {
            instance.numberOfAttachments++
        }
        // @ts-ignore
        return instance.attachment._tickStream.asObservable()
    }
    const detach = (path: string): void => {
        let instance: AttachmentInstance | undefined = blastGraphStore.get(path)
        if (instance) {
            instance.numberOfAttachments--
            if (instance.numberOfAttachments < 1) {
                graphService.detach(path)
                blastGraphStore.delete(path)
            }
        }
    }

    const getCurrencyMap = () : { [id: string]: CurrencyDTO }  => {
        const currencyMap: { [id: string]: CurrencyDTO } = {}
        if (dataCache) {
            const currencies = dataCache.getCollection('currency');
            safeStream(currencies).forEach(currency => {
                if (currency.code) {
                    currencyMap[currency.code] = currency
                }
            })
        } else {
            console.log('data cache not loaded yet')
        }
        return currencyMap
    }

    if (haveEnvironment) {
        return (
            <BlastContext.Provider
                value={{
                    login,
                    observeConnection,
                    observeNotifications,
                    observeInboundCommands,
                    observeCollection, getCollection, subscribeTopic, unsubscribeTopic, disconnect,
                    attachAndWatch, detach,
                    getCurrencyMap
                }}>
                {children}
            </BlastContext.Provider>
        )
    } else {
        return <></>
    }

}
