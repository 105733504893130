import React from 'react';
import {FieldProperties} from '../types';
import {SvgStyled} from './styled';

export const OopsIcon = (): React.ReactElement<FieldProperties> => {

    return (
        <SvgStyled width={'300px'}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                <g>
                    <path
                        d="M127.1,352.5c-40.9,0-71.8,15-92.7,44.9C18.1,420.7,10,448.8,10,481.5c0,36.3,9.5,66.3,28.5,90.1c21.3,24.6,50.6,37,87.9,37c39.9,0,70.7-14.1,92.2-42.2c18.4-24,27.6-54,27.6-90.2c0-33.4-8-60.9-24.1-82.3C201.6,366.3,169.9,352.5,127.1,352.5z M193.2,548.2c-12.9,20.8-33.9,31.1-62.8,31.1c-30.5,0-52.5-9.2-66.1-27.7C50.8,533.3,44,510.6,44,483.8c0-33.6,7.9-59,23.8-76.2c15.8-17.2,36.3-25.8,61.4-25.8c25.8,0,46.2,8.7,61.1,26.1c14.9,17.4,22.4,40.3,22.4,68.8C212.6,503.7,206.1,527.5,193.2,548.2z"/>
                    <path
                        d="M346.5,435.5c-27.3,0-47.9,10-61.8,29.9c-10.8,15.6-16.3,34.2-16.3,56.1c0,24.2,6.3,44.2,19,60c14.2,16.4,33.8,24.6,58.6,24.6c26.6,0,47.1-9.4,61.5-28.2c12.2-16,18.4-36,18.4-60.2c0-22.3-5.4-40.6-16.1-54.9C396.1,444.7,375,435.5,346.5,435.5z M390.5,566c-8.6,13.8-22.6,20.7-41.8,20.7c-20.3,0-35-6.1-44-18.4c-9.1-12.3-13.6-27.4-13.6-45.3c0-22.4,5.3-39.3,15.8-50.8c10.6-11.5,24.2-17.2,40.9-17.2c17.2,0,30.8,5.8,40.8,17.4c9.9,11.6,14.9,26.9,14.9,45.9C403.5,536.3,399.2,552.2,390.5,566z"/>
                    <path
                        d="M526.3,439.9h-72.3v161.6h21.9v-68.3h50.5c16.2,0,28.2-4.7,36-14c7.8-9.4,11.8-20.3,11.8-32.9c0-14.7-4.4-26-13.2-34.1C552.2,444,540.6,439.9,526.3,439.9z M542.9,508.4c-6.1,4.2-14,6.3-23.8,6.3h-43.2v-56h43.2c8.4,0,15,1.1,19.8,3.4c8.7,4.3,13.1,12.3,13.1,24.2C552.1,496.9,549,504.3,542.9,508.4z"/>
                    <path
                        d="M706.6,523.4c-5.8-4.3-13.9-7.7-24.5-10.2l-21.9-5.2c-14.8-3.5-24.3-6.5-28.5-8.8c-6.4-3.7-9.6-9.5-9.6-17.4c0-7.2,2.7-13.5,8.1-18.9c5.4-5.4,14.3-8.1,26.7-8.1c15.3,0,26.2,4.2,32.6,12.7c3.4,4.6,5.7,11.3,6.8,19.9h20.6c0-18-5.8-31-17.3-39.2c-11.5-8.2-25.5-12.3-42-12.3c-17.9,0-31.8,4.6-41.5,14c-9.8,9.3-14.7,21.2-14.7,35.8c0,13.4,5,23.5,14.9,30.2c5.8,3.9,15.6,7.4,29.4,10.7l21.2,5c11.5,2.7,20,5.9,25.4,9.6c5.3,3.7,8,10,8,18.8c0,11.7-6.3,19.9-18.9,24.4c-6.5,2.3-13.7,3.5-21.6,3.5c-17.7,0-30-5.3-37-16.1c-3.7-5.7-5.8-13.2-6.3-22.3h-20.6c-0.2,17.5,5.2,31.3,16.3,41.5c11.1,10.3,26.4,15.4,46,15.4c16.8,0,31.5-3.9,44.2-11.6c12.7-7.7,19-20.5,19-38.4C721.5,541.9,716.6,531,706.6,523.4z"/>
                    <path d="M761.3,578.6h21.9v22.9h-21.9V578.6z"/>
                    <path d="M761.3,493l5.7,69.9h11.2l5.4-69.9v-53.1h-22.3V493z"/>
                    <path d="M885.4,485.2h23v24h-23V485.2L885.4,485.2z"/>
                    <path d="M885.4,577.5h23v24h-23V577.5z"/>
                    <path
                        d="M968.3,600.6c-5.9-17.6-8.9-37-8.9-58.3c0-21,2.6-39.6,7.8-55.7c3.4-10.6,10.9-27,22.4-49.4h-13.7c-13.7,21.5-22.7,37.6-27.1,48.4c-6.8,16.9-10.2,36.3-10.2,57.9c0,5.8,0.4,11.9,1.2,18.3c1.6,12.3,4.5,23.9,8.6,34.7c3,7.9,5.8,14.2,8.4,18.8c2.6,4.6,9.2,15.3,19.9,32H990C979.2,627.1,972,611.4,968.3,600.6z"
                        />
                </g>
            </svg>
        </SvgStyled>
    )
}
