import React, { useState } from "react";
import { log } from "../../../../services/LogService";
import { ChangeFieldStyled } from "../styled_v2";

export interface ChangePercDO {
  value: number;
  multiplyBy100?: boolean;
}

export default function ChangePerc({value,multiplyBy100=false}: ChangePercDO) {

  const [className] = useState("ChangePerc-" + new Date().getTime());
  const change: string | undefined =   value > 0 ? "up" :  value < 0 ? "down" :  value === 0 ? "even" : "muted";
  const colorMap:{[change:string]:string} = {
    up:"positive",
    down:"negative",
    even:"light",
    muted:"muted"

  }

  return (
    <ChangeFieldStyled color={colorMap[change]}>
      <i className={`fa-solid fa-caret-${change}`} />
      <span>{multiplyBy100 ? value * 100 : value}{"%"}</span>
    </ChangeFieldStyled>
  );
}

