import {BaseTheme} from "../../../../../../providers/theme/GuslThemeProvider";

export const wrapperStyle = (themeColors: BaseTheme,isMobile:boolean = false) => {
    return {
        backgroundColor: themeColors.colors.dark[0],
        border: `1px solid ${isMobile ? themeColors.colors.accentColorLight : themeColors.colors.accentColorMuted}`,
        color: themeColors.colors.dark[1],
        padding: 5,
        minHeight:350,
    }
}
export const triggerElementStyle = (themeColors: BaseTheme) => {
    return {
        // minWidth: 150,
        color: themeColors.colors.dark[1] ,
        borderBottom: `1px solid ${themeColors.action.borderColor}`,
        padding: '4px 3px 4px 3px',
        fontSize:'12px',
        minHeight:'30px'


    }
}
export const editRowWrapperStyle = (themeColors: BaseTheme) => {
    return {
        backgroundColor: themeColors.colors.dark[0],
        color: themeColors.colors.dark[1],
        padding: 5,
        minHeight:100,
    }
}
