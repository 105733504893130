import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import {Box, IconButton, Popover, Typography} from '@mui/material';
import React from 'react';
import {PopperInner} from '../../scheduler/common/styles/styles';

interface GuslPopoverProperties {
    title: string;
    date: string;
    onClose: () => void;
    anchorEl?: Element | null;
    viewWidth?: string | '400px';
    headerBgColor?: string | 'red';
    headerColor?: string | 'white';
    children: React.JSX.Element | React.JSX.Element[]
}

export const GuslPopover = ({
                                anchorEl,
                                title,
                                date,
                                onClose,
                                headerBgColor,
                                headerColor,
                                viewWidth,
                                children
                            }: GuslPopoverProperties): React.ReactElement => {

    console.log('GuslPopover',headerBgColor,headerColor)
    const renderInnerContents = (): React.ReactElement => {
        return <>{children}</>
    }

    const renderActions = (): React.ReactElement => {
        // placeholder, if we ever wanted to add actions
        return <></>
    }


    const performClose = () => {
        if (onClose) {
            onClose()
        }
    }
    const renderPopperInner = (): React.ReactElement => {
        return (
            <PopperInner style={{width: viewWidth}}>
                <Box
                    sx={{
                        bgcolor: headerBgColor,
                        color: headerColor,
                    }}
                >
                    <div className="rs__popper_actions">
                        <div>
                            <IconButton
                                size="small"
                                onClick={() => performClose()}
                            >
                                <ClearRoundedIcon color="disabled"/>
                            </IconButton>
                        </div>
                        {renderActions()}
                    </div>
                    <Typography style={{padding: "5px 0"}} noWrap>
                        {title}
                    </Typography>
                </Box>
                <div style={{padding: "5px 10px"}}>
                    <Typography
                        style={{display: "flex", alignItems: "center", gap: 8}}
                        color="textSecondary"
                        variant="caption"
                        noWrap
                    >
                        <EventNoteRoundedIcon/>
                        <div>{date}</div>
                    </Typography>
                    {renderInnerContents()}
                </div>
            </PopperInner>
        )
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => performClose()}
            anchorOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {renderPopperInner()}
        </Popover>
    )
}
