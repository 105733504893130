import styled, {ThemeProps} from 'styled-components';
import {getTextAlign} from '../../../utils/CssUtils';
import {defaultCellWidth} from "../gusl-table/guslTableSlice";
import {displayTable, flipX, flipY, RowStyledProperties} from '../gusl-table/styled/table/styled';
import {TdProperties} from '../maintain-table/styled';


export const DateSummaryWrapperStyled = styled.div.attrs((props) => ({}))`
  margin: -20px;
`


interface TableTitleProperties extends ThemeProps<any> {
}

export const TableContainerStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
  margin-bottom: 30px;
`
export const TableContentStyled = styled.div.attrs((props: TableTitleProperties) => ({}))<TableTitleProperties>`
  background-color: ${props => props.theme.table.panelBgColor};
  color: ${props => props.theme.table.columnColor};
`


export interface TableStyledProps extends ThemeProps<any> {
    className?: string,
    tBodyHeight?: number,
    isOverFlown?: boolean,
    id?: string,

}

export const TableResponsiveStyled = styled.div.attrs((props: TableStyledProps) => ({
    className: props.className,
    id: props.id
}))<TableStyledProps>`
    // ${flipY}   ;
  height: 200px;

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};

  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;

  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;
`


export const TableStyled = styled.table.attrs((props: TableStyledProps) => ({
    className: props.className,
    border: "2"
}))<TableStyledProps>`
  border: ${props => props.theme.table.gridBorder};
    // ${flipY}


`

export const TableHeadStyled = styled.thead.attrs((props: TableStyledProps) => ({
    className: props.className
}))<TableStyledProps>`
  ${displayTable}
`

export const TableBodyStyled = styled.tbody.attrs((props: TableStyledProps) => ({
    className: props.className,

}))<TableStyledProps>`

  display: ${props => props.isOverFlown ? 'inline-' : ''}block;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: auto;
  max-height: ${props => props.tBodyHeight ? props.tBodyHeight + 'px' : '500px'};

    // ${flipX}

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};

  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;

  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;
`

/* RowStyled MOVED from maintain-table/styled.ts as TableRowStyled */
/*
    :hover {
        background-color:${props => {
    if (props.firstTab) {
        return props.theme.table.rowHoverBgColor;
    } else {
        return 'inherit';
    }
}
}
    }

 */
export const TableHeaderRowStyled = styled.tr.attrs((props: RowStyledProperties) => ({}))<RowStyledProperties>`

    // ${flipX}

  ${displayTable}
  color: ${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};

  :nth-of-type(odd) > * {
    color: ${props => props.theme.table.columnColor} !important;
    font-size: ${props => props.theme.table.columnFontSize};
  }
`

export const TableRowStyled = styled.tr.attrs((props: RowStyledProperties) => ({}))<RowStyledProperties>`
  :hover {
    background-color: ${props => {
      if (props.firstTab || props.lookupField) {
        return props.theme.table.rowHoverBgColor;
      } else {
        return 'inherit';
      }
    }
    }
  }

    // ${flipX}

  ${displayTable}
  color: ${props => props.theme.table.columnColor};
  background-color: ${props => props.active ? props.theme.table.rowHoverBgColor : 'inherit'};
  font-size: ${props => props.theme.table.columnFontSize};

  :nth-of-type(odd) > * {
    color: ${props => props.theme.table.columnColor} !important;
    font-size: ${props => props.theme.table.columnFontSize};
  }
`

export interface ColumnFieldProperties extends ThemeProps<any> {
    textAlign?: string | undefined
}

export interface ColumnHeaderProperties extends ThemeProps<any> {
    textAlign?: string | undefined,
    className?: string | undefined,
}

export const ColumnHeaderStyled = styled.th.attrs((props: ColumnHeaderProperties) => ({
    className: props.className
}))<ColumnHeaderProperties>`
  text-align: ${props => getTextAlign(props.textAlign)};
  color: ${props => props.theme.table.headerColor};
  background-color: ${props => props.theme.table.panelBgColor} !important;
  font-size: ${props => props.theme.table.headerFontSize};
`

export const ColumnStyled = styled.td.attrs((props: TdProperties) => ({}))<TdProperties>`
  color: ${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};
  min-width: ${props => typeof props.cellWidth !== "undefined" ? props.cellWidth : defaultCellWidth + "px"};
  max-width: ${props => typeof props.cellWidth !== "undefined" ? props.cellWidth : defaultCellWidth + "px"};
  word-break: break-all;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};

  text-align: ${props => getTextAlign(props.textAlign)};

  vertical-align: top;
  border: ${props => props.theme.table.gridBorder};

`

export const DateSelectorWrapperStyled = styled.div.attrs((props) => ({}))`
  margin: 20px 10px 20px 10px;
  display: inline-block;

  color: ${props => props.theme.table.columnColor};
`

export const DateSelectorLabelStyled = styled.div.attrs((props) => ({}))`
  margin: 0px 5px 0px 0px;
  display: inline-block;
  color: ${props => props.theme.table.columnColor};
`


export interface ButtonStyledProperties extends ThemeProps<any> {
    active: boolean,
    onClick: Function
}

export const ButtonStyled = styled.div.attrs((props: ButtonStyledProperties) => ({}))<ButtonStyledProperties>`
  display: ${props => props.active ? 'inline' : 'none'};
  margin: 5px 0px 5px 0px;
  padding: 5px;
  color: ${props => props.theme.table.menuButtonColor};
  background-color: ${props => props.theme.table.menuButtonBgColor} !important;
`

export const DateSelectorStyled = styled.div.attrs((props) => ({}))`
  display: inline;
    // border: solid 1px ${props => props.theme.table.columnColor};
  display: inline;
  margin: 0px;
  padding: 6px;
  color: ${props => props.theme.table.columnColor};
  background-color: ${props => props.theme.table.menuButtonBgColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`
