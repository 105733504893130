import React, { useState } from "react";
import { BuySellState } from "../../buySellSlice";
import { OrderAction, TickerDO } from "../../types";
import { BoxStyled, CurrencyTickerStyled } from "../../styled_v2";
import BSButtons from "../BSButtons";
import MarketLimitButtons from "../MarketLimitButtons";
import RenderError from "./RenderError";
import RenderQuantity from "./RenderQuantity";
import RenderLimit from "./RenderLimit";
import RenderDisplayInfo from "./RenderDisplayInfo";
import { BuySellFormProperties } from "../../BuySellForm";
import { extractDisplayTicker } from "../../functions";

export interface RenderBSFormDO {
  buySellState: BuySellState;
  properties: BuySellFormProperties;
  setSideHandler: Function;
  onOrderType: Function;
  placeOrderError: boolean;
  isDMA: Function;
  limitRef: React.MutableRefObject<any>;
  quantityRef: React.MutableRefObject<any>;
  limitStep: number;
  reset: Function;
  setPlaceOrderError: Function;
}

export default function RenderBSForm({
                                       buySellState,
                                       properties,
                                       setSideHandler,
                                       onOrderType,
                                       placeOrderError,
                                       isDMA,
                                       limitRef,
                                       quantityRef,
                                       limitStep,
                                       reset,
                                       setPlaceOrderError
                                     }: RenderBSFormDO) {

  const [className] = useState("RenderBSForm-" + new Date().getTime());
  const ticker: TickerDO | undefined = buySellState.ticker;
  const newOrder: boolean = buySellState.orderAction === OrderAction.NEW;
  const tradeOrder: boolean = buySellState.orderAction === OrderAction.TRADE;
  const isCloseOut = buySellState?.order?.isCloseout() || false;
  const isMarket: boolean = (isCloseOut ? "LIMIT" : (buySellState?.order?.getOrderType() || "MARKET")) === "MARKET";

  return (
    <>
      <div className="row g-0 mb-3">
        <div className="col-6">Order form</div>
        <div className="col-6 ">
          <MarketLimitButtons onOrderType={onOrderType} isMarket={isMarket} />
        </div>
      </div>
      <BoxStyled>
        <CurrencyTickerStyled>{buySellState.ticker?.displayTicker || buySellState.ticker?.symbol}</CurrencyTickerStyled>
        <BSButtons properties={properties}
                   newOrder={newOrder}
                   setSideHandler={setSideHandler}
                   tradeOrder={tradeOrder}
                   buySellState={buySellState} />
      </BoxStyled>
      <div className="row g-0">

        {/*{placeOrderError && <RenderPlaceOrderError />}*/}
        {buySellState.hasError && <RenderError buySellState={buySellState} />}
        <BoxStyled>
          <RenderQuantity buySellState={buySellState} quantityRef={quantityRef} />
        </BoxStyled>

        {!isMarket && <BoxStyled>
          <RenderLimit limitRef={limitRef} limitStep={limitStep} />
        </BoxStyled>}

        {buySellState.canShowInfo && <BoxStyled>
          <RenderDisplayInfo buySellState={buySellState}
                             setPlaceOrderError={setPlaceOrderError}
                             isDMA={isDMA}
                             reset={reset} />
        </BoxStyled>}
      </div>

    </>
  );
}

