import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import ActionDialog from '../../components/common/action-dialog/ActionDialog';
import LoadingSpinner from '../../components/common/loading-spinner/LoadingSpinner';
import Icon from '../../components/common/maintain-table/bootstrap/Icon';
import {ActionConfigDTO} from '../../components/types';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {IEnvironment} from '../../providers/environment/types';
import {SessionContext} from '../../providers/session/SessionContext';
import {SignInResponseDTO} from '../../providers/session/types';
import {log} from '../../services/LogService';
import {translateService} from '../../services/TranslateService';
import {isBlank, notBlank} from '../../utils/TypeCheckers';
import {getErrorMessageKey, IMountedMonitor, MountedMonitor, RunOnceEffect, unSubscribe} from '../../utils/Utils';
import Password from './components/Password';
import Totp from './components/Totp';
import Username from './components/Username';
import './Login.css';
import {inputClass} from "./LoginUsernamePassword";
import {FormErrorMessageStyled, FormStyled, LoginButtonStyled} from './styled';
import {LoginProps} from './types';

const LoginUsernamePasswordTOTP = (props: LoginProps): React.ReactElement => {

    const navigate = useNavigate();
    const environmentContext = React.useContext(EnvironmentContext)

    const [className] = React.useState<string>(() => 'LoginUsernamePasswordTOTP-' + new Date().getTime());
    const sessionContext = React.useContext(SessionContext)
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [qrErrorMessage, setQrErrorMessage] = useState<string | null>(null);

    const [qrCodeLink, setQrCodeLink] = useState<string | null>(null);
    const [homePage, setHomePage] = useState<string | undefined>(undefined);
    const [changePasswordAction] = useState<ActionConfigDTO | undefined>(() => environmentService.getEnvironment()?.changePassword);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [isPasswordRevealed, setIsPasswordRevealed] = useState<boolean>(false);
    const submitLabel = translateService.getValue('login.submit', 'Login')

    // @ts-ignore
    const usernameRef = React.useRef<HTMLInputElement>(null)
    // @ts-ignore
    const passwordRef = React.useRef<HTMLInputElement>(null)
    // @ts-ignore
    const totpRef = React.useRef<HTMLInputElement>(null)

    const [loading, setLoading] = useState(false);

    const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
    const [qrRegistrationSteps, setQrRegistrationSteps] = useState<number>(1);
    const [submitted, setSubmitted] = useState(false);

    const [mountedMonitor] = useState<IMountedMonitor>(MountedMonitor(className, true));

    const [username, setUsername] = useState<string>(() => {
        return localStorage.getItem('username') || '';
    });
    const [password, setPassword] = useState<string>('');
    const [totp, setTotp] = useState<string>('');

    RunOnceEffect(() => {
        let subscription = environmentContext.watchEnvironment().subscribe((environment: IEnvironment) => {
            if (environment.loaded) {
                setHomePage(environment.homePage);
            }
        })
        return () => {
            unSubscribe(subscription);
        }
    })

    const onSignIn = () => {
        const valid = (): boolean => {
            return notBlank(username) && notBlank(password) && notBlank(totp);
        }
        setSubmitted(true);
        if (!valid()) {
            return;
        }
        setErrorMessage('')
        setLoading(true)
        sessionContext.signIn(username, password, totp).then((response: SignInResponseDTO) => {
            if (mountedMonitor.isMounted()) {
                setLoading(false)
            }
            if (response.passwordChangeRequired && environmentService.getEnvironment()?.changePassword) {
                const action: ActionConfigDTO | undefined = environmentService.getEnvironment()?.changePassword
                if (action) {
                    setShowChangePassword(true)
                } else {
                    navigate('/' + homePage || '/')
                }
            } else {
                navigate('/' + homePage || '/')
            }

        }).catch(error => {
            log.error(className, 'MSG002 SignIn error', error)
            setErrorMessage('Invalid details');
            setLoading(false)
        });
    }

    const onForgotPassword = () => {
        log.info('Login', 'onForgotPassword')
    }

    const onNewUser = () => {
        log.info('Login', 'onNewUser')
        setShowQrCodeDialog(true)
    }

    const renderErrorMessage = (): React.ReactElement => {
        if (errorMessage) {
            return (
                <small className='yellow'>{errorMessage}</small>
            );
        }
        return <></>
    };

    const closeDialog = () => {
        setShowQrCodeDialog(false);
        setQrRegistrationSteps(1);
    }

    const qrCodeDialogFooter = (): JSX.Element => {
        if (qrRegistrationSteps === 2) {
            return (
                <div>
                    <button
                        className={inputClass('border-0 fs-5 fw-bold')}
                        onClick={() => closeDialog()}
                    > Close
                    </button>
                </div>)
        } else {
            return (<></>)
        }

    }

    const renderActionPlaceholder = (): React.ReactElement => {
        return <>
            {showChangePassword && changePasswordAction &&
                <ActionDialog action={changePasswordAction}
                              cannotClose={true}
                              closeDialog={() => {
                                  setShowChangePassword(false)
                                  navigate('/' + homePage || '/')
                              }}/>}
        </>
    }

    const renderStepOne = (): React.ReactElement => {
        const getQrCode = () => {
            setQrErrorMessage('');
            const valid = (): boolean => {
                return notBlank(username);
            }
            setSubmitted(true);
            if (!valid()) {
                return;
            }
            setQrRegistrationSteps(99)
            sessionContext.getQrCode(username).then(response => {
                log.debug('LoginUsernamePasswordOtp', 'MSG001 getQrCode response', response)
                setQrCodeLink(response.qrCode);
                setQrRegistrationSteps(2)
            }).catch(error => {
                log.error('LoginUsernamePasswordOtp', 'MSG002 getQrCode error', error)
                const errorMessageKey = getErrorMessageKey(error);
                if ('record.not.found') {
                    setQrErrorMessage('Please ensure your administrator has added you email');
                } else {
                    setQrErrorMessage('Request failed - please try again');
                }
                setQrRegistrationSteps(1)
            })
        }

        return (
            <div className={'widget_blue'}>
                <h3>First time user</h3>
                <p>Access to VisionX requires a 'One Time Password' code generated by Google Authenticator. </p>
                <p><i><b>SmartTech247</b></i> must be registered with your Google Authenticator app.</p>
                <p>If you have Google Authenticator and <i><b>SmartTech247</b></i> is registered then there is no need
                    to continue. Close this dialog and proceed with SignIn.</p>
                <p>If necessary, download 'Google Authenticator' from Google Play or IPhone App Store.</p>
                <p>To register <i><b>SmartTech247</b></i> please enter your username below. (Note: This username must be
                    pre-registered by your VisionX Administrator).</p>
                <div className="row">
                    <div className=' col-md-3'>
                        <input placeholder='Username'
                               value={username}
                               className={inputClass()}
                               onChange={e => {
                                   localStorage.setItem('username', e.target.value);
                                   setUsername(e.target.value);

                               }} required autoFocus/>

                        {submitted && isBlank(username) &&
                            <div><small className='yellow'>Username is required.</small></div>}
                    </div>
                    <div className=' col-md-3'>
                        <button

                            className={'form-control shadow mb-1 bg-transparent rounded-0 muted'}
                            onClick={() => getQrCode()}
                        >Request QR Code
                        </button>
                    </div>
                    <div className={'offset-md-3 col-md-3'}>
                        <button
                            className={'form-control shadow mb-1 bg-transparent rounded-0 muted'}
                            onClick={() => closeDialog()}
                        > Close
                        </button>
                    </div>
                </div>
                {qrErrorMessage && <small className='yellow'>{qrErrorMessage}</small>}
            </div>
        )
    }

    const renderStepTwo = (): React.ReactElement => {
        // const closeQrCode = () => {
        //     setShowQrCodeDialog(false)
        // }

        return (
            <>
                <h3>QR Code</h3>
                <p>Please scan the QR Code below using 'Google Authenticator' app on your phone.</p>
                <img src={qrCodeLink || ''} className='qrCode' alt='QR Code'/>
            </>
        )
    }

    const renderQrSteps = (): React.ReactElement => {
        switch (qrRegistrationSteps) {
            default:
            case 1:
                return (
                    <>{renderStepOne()}</>
                )
            case 2:
                return (
                    <>{renderStepTwo()}</>
                )
            case 99:
                return (
                    <LoadingSpinner/>
                )
        }
    }

    const renderNewUserDialog = (): React.ReactElement => {
        return (
            <div className={'bg-light text-dark ' + showQrCodeDialog ? 'p-5' : ''}>
                {showQrCodeDialog && renderQrSteps()}
                {qrCodeDialogFooter()}
            </div>
        )
    }

    const renderOld = (): React.ReactElement => {

        return (
            <>
                <div className="col-12 col-md-3 px-md-3 py-md-5 m-md-5 p-2 login_box">

                    {/*<div className=''>*/}
                    {/*    <h4 className={'widget_heading mb-3'}>Google One Time Password Auth</h4>*/}
                    {/*</div>*/}

                    <div className=''>
                        <input placeholder='Username' tabIndex={1} autoFocus={isBlank(username)} required
                               ref={usernameRef}
                               value={username}
                               onChange={e => {
                                   localStorage.setItem('username', e.target.value);
                                   setUsername(e.target.value);
                               }}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       passwordRef.current?.focus()
                                   }
                               }}
                               className={inputClass()}
                        />
                        {submitted && isBlank(username) && <small className='yellow'>Username is required.</small>}
                    </div>
                    <div className={'password-wrapper'}>
                        <div>
                            <input type={!isPasswordRevealed ? 'password' : 'text'} tabIndex={2}
                                   autoFocus={notBlank(username)} required placeholder='Password'
                                   ref={passwordRef} value={password}
                                   onChange={e => {
                                       setPassword(e.target.value)
                                   }}
                                   onKeyPress={(e) => {
                                       if (e.key === 'Enter') {
                                           totpRef.current?.focus()
                                       }
                                   }}
                                   className={inputClass('password-input')}
                            />
                        </div>
                        <div className="login-password-icon">
                            <Icon icon={'fa fa-eye'}
                                  onClick={() => {
                                      setIsPasswordRevealed(!isPasswordRevealed)
                                  }}/>
                        </div>
                    </div>
                    <div>
                        {submitted && isBlank(password) && <small className='yellow'>Password is required.</small>}
                    </div>


                    <div className=''>
                        <input type='text' tabIndex={3} required placeholder='Google authenticator code'
                               ref={totpRef} value={totp}
                               onChange={e => {
                                   setTotp(e.target.value)
                               }}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       onSignIn()
                                   }
                               }}
                               className={inputClass()}
                        />
                        {submitted && isBlank(totp) && <small className='yellow'>Code is required.</small>}
                    </div>
                    <div className='mt-5'>
                        <button onClick={onSignIn} className={inputClass()}>
                            {loading && <i className={'fas fa-spinner text-light fa-2xl'}/>}
                            Sign In
                        </button>
                    </div>
                    {renderErrorMessage()}

                    <div className='row d-flex justify-content-around mt-5'>
                        <div className='col-md-5 text-center'>
                            {/*<button type='button' className={inputClass('border-0 green')} onClick={onForgotPassword}>Forgot*/}
                            {/*    Password?*/}
                            {/*</button>*/}
                        </div>
                        <div className='col-md-5 text-center'>
                            <button type='button' className={inputClass('border-0 green')} onClick={onNewUser}>New User
                            </button>
                        </div>
                    </div>


                </div>
                <div className={'offset-md-4  col-md-7 col-12 '
                    + (showQrCodeDialog ? ' inside_border_all  widget_blue p-5 mt-md-5 mt-0' : '')}>
                    {renderNewUserDialog()}

                </div>
                {renderActionPlaceholder()}
            </>
        )
    }

    return (
        <>
            <FormStyled>
                <Username
                    hasLabel={props.environment?.loginUiProperties?.withLabel || false}
                    asUppercase={props.environment?.loginUiProperties?.asUppercase || false}
                    hasIcon={props.environment?.loginUiProperties?.withIcon || false}
                    value={username}
                    autoFocus={isBlank(username)}
                    formSubmitted={submitted}
                    onNextField={() => {
                    }}
                    onChange={(value: string) => setUsername(value)}
                />
                <Password
                    hasLabel={props.environment?.loginUiProperties?.withLabel || false}
                    asUppercase={props.environment?.loginUiProperties?.asUppercase || false}
                    hasIcon={props.environment?.loginUiProperties?.withIcon || false}
                    value={password}
                    autoFocus={notBlank(username) && isBlank(password)}
                    formSubmitted={submitted}
                    onNextField={() => {
                    }}
                    onChange={(value: string) => setPassword(value)}
                />
                <Totp
                    hasLabel={props.environment?.loginUiProperties?.withLabel || false}
                    hasIcon={props.environment?.loginUiProperties?.withIcon || false}
                    value={totp}
                    autoFocus={notBlank(username) && notBlank(password)}
                    formSubmitted={submitted}
                    onNextField={() => {
                        onSignIn()
                    }}
                    onChange={(value: string) => setTotp(value)}
                />
                <LoginButtonStyled size={'xl'} tabIndex={4} type="submit"
                                   onClick={onSignIn}>{submitLabel}</LoginButtonStyled>
            </FormStyled>
            {errorMessage && <FormErrorMessageStyled>{errorMessage}</FormErrorMessageStyled>}
            {renderActionPlaceholder()}
        </>
    )
}

export default LoginUsernamePasswordTOTP;
