import React, {useContext, useEffect, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {BlastContext} from '../../../providers/blast/BlastContext';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {GuslThemeContext} from '../../../providers/theme/GuslThemeProvider';
import {getSettings} from '../../../services/ValidationService';
import {isDefined} from '../../../utils/TypeCheckers';
import {assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import {watchBlastDeltas} from '../../ui/FieldUtils';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {ExtraFieldProperties} from '../money/types';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled} from '../text/styled';
import {CircleFieldStyled, CircleWrapperStyled} from './styled';

export const CircleField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'CohortLogoField-' + new Date().getTime());

    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)

    const [trueColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.positiveColor);
    const [falseColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).money.negativeColor);

    const [formMode, setFormMode] = useState(properties.formMode);
    const [formValue, setFormValue] = useState<boolean>(() => {
        const extendedProps = extractExtraFieldProperties(properties)
        let value = isDefined(_guslFormState?.getFieldValue(properties)) ? _guslFormState?.getFieldValue(properties) : false
        if (extendedProps && extendedProps.flip) {
            value = !value
        }
        return value
    });

    const [refreshCounter, setRefreshCounter] = useState<number>(1)
    const [extraFieldProperties] = useState<ExtraFieldProperties>(extractExtraFieldProperties(properties));

    const getValue = (value: boolean | undefined): boolean => {
        // @ts-ignore
        let newValue: boolean = isDefined(value) ? value : false
        if (extraFieldProperties && extraFieldProperties.flip) {
            newValue = !newValue
        }
        return newValue

    }

    useEffect(() => {
        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode)
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            setFormValue(getValue(newValue))
            setRefreshCounter(refreshCounter + 1)
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        }
    }, [properties]);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(getValue(properties?.data))
        // setFormValue(isDefined(properties?.data) ? properties?.data : false);
    }

    useEffect(() => {
        setFormValue(getValue(properties?.data))
        // setFormValue(isDefined(properties?.data) ? properties?.data : false);
    }, [properties])


    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });


    const renderCircle = (): React.ReactElement => {
        return (
            <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
                <circle fill={formValue ? trueColor : falseColor} cx="75" cy="75" r="75"/>
            </svg>
        )
    }
    const renderTableView = (): React.ReactElement => {
        return (
            <CircleWrapperStyled>
                <CircleFieldStyled
                    key={properties.fieldConfig.name + '_' + refreshCounter}>{renderCircle()}</CircleFieldStyled>
            </CircleWrapperStyled>
        )

    }
    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        return (
            <>
                {!hideField &&
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                                {renderTableView()}
                            </FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                }
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}
