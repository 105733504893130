import React, {useRef, useState} from 'react';
// @ts-ignore
import {SketchPicker} from 'react-color';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {getSettings, performValidation} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {assignReferences, noop, RunOnceEffect} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {OptionViewStyled} from '../option/styled';
import {FloatingFormStyled, TextFieldStyled} from '../text/styled';
import {ColorPickersState, toggleColorPicker} from './colorPickerSlice';
import {ColorBlockStyled, ColorInputStyled, ColorPickerIconStyled, ColorPickerPanelStyled} from './styled';

export const ColorField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {


    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'ColorField-' + new Date().getTime());
    const [uniqueId] = React.useState<string>(() => 'id_' + Math.random());
    const valueRef = useRef(properties?.data);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<string>(properties?.data || '#fff');
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) ||  properties.data || '#fff');

    const state: ColorPickersState = useAppSelector(state => state.colorPickersToggleSlice);
    const dispatch = useAppDispatch();

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
    }

    const doValidation = (fieldValue: any): boolean => {
        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            fieldValue,
            setSubmitted,
            setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')

        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}>
                <span className="" style={style}>{formValue}</span>
            </TextFieldStyled>
        )
    }

    const renderColorPicker = (): React.ReactElement => {

        const handleChangeComplete = (color: any) => {
            console.log('color ==>', color)
            if (color && color.rgb && color.rgb.a !== 1) {
                const newValue = 'rgba(' + color.rgb.r+','+  color.rgb.g+',' +color.rgb.b+',' +  +color.rgb.a + ')'
                setFormValue(newValue)
                properties.onChange(properties.fieldConfig.name, newValue);
            } else {
                setFormValue(color.hex)
                properties.onChange(properties.fieldConfig.name, color.hex);
            }
        };

        return <ColorPickerPanelStyled index={0}>
            <SketchPicker
                color={formValue}
                presetColors={['#aaa', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8']}
                onChangeComplete={handleChangeComplete}/>
        </ColorPickerPanelStyled>
    }

    const invertColor = (hex: string) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    const padZero = (str: string, len?: number) => {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }
    const renderColorBlock = (): React.ReactElement => {
        const onDataInputChange = (value: string) => {
            setFormValue(value);
            properties.onChange(properties.fieldConfig.name, valueRef?.current?.value);
        }

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        return <>
            <div>
                <ColorBlockStyled color={formValue}>
                    <ColorInputStyled type={'text'}
                                      id={properties?.fieldConfig?.name || 'def'}
                                      key={properties?.fieldConfig?.name || 'def'}
                                      ref={valueRef}
                                      value={formValue}
                                      readOnly={disableField}
                                      disabled={disableField}
                                      onChange={(e) => onDataInputChange(e.target.value)}
                                      required={required}
                                      submitted={submitted}
                                      noValue={!formValue}
                    />
                    {/*<ColorValueStyled color={invertColor(formValue)}>{formValue}</ColorValueStyled>*/}
                </ColorBlockStyled>
                {!disableField && <ColorPickerIconStyled
                    onClick={() => dispatch(toggleColorPicker({code: uniqueId, show: !state.show}))}>
                    <i className="fa-solid fa-palette"></i>
                </ColorPickerIconStyled>}
            </div>
        </>
    }

    const renderFormView = (): React.ReactElement => {

        /* eslint-disable @typescript-eslint/no-unused-vars */
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        if (hideField) {
            return <></>
        }

        // if (formMode === FormMode.VIEW || disableField) {
        return <>
            <FloatingFormStyled>
                <OptionViewStyled>
                    {renderColorBlock()}
                    {state.show && state.code === uniqueId && renderColorPicker()}
                </OptionViewStyled>
                <FieldLabel properties={properties}/>
                {submitted && errorMessage &&
                    <small className="yellow">{errorMessage}</small>}
            </FloatingFormStyled>
        </>
        // }
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}

