import styled, {ThemeProps} from 'styled-components';
import {getTextAlign} from '../../../utils/CssUtils';

export const ScoreWrapperStyled = styled.div.attrs((props) => ({}))`
color: ${props => props.theme.table.columnColor} !important ;
font-size: ${props => props.theme.table.columnFontSize};
display:inline-block;
width:400px;
text-align:center;
`

export const LeftTeamStyled = styled.div.attrs((props) => ({}))`
text-align:right;
display:inline-flex;
`

export const RightTeamStyled = styled.div.attrs((props) => ({}))`
text-align:left;
display:inline-flex;
`


export const DashStyled = styled.div.attrs((props) => ({}))`
display:inline-flex;
text-align:center;
`


export const TeamNameStyled = styled.div.attrs((props) => ({}))`
display:inline-flex;
word-break: break-all;
`

export const ScoreValueWrapperStyled = styled.div.attrs((props) => ({}))`
display:inline-flex;
margin-left: 5px;
margin-right:5px;
`

export interface ScoreStyledProperties extends ThemeProps<any> {
    textAlign?: string | undefined
}

export const ScoreStyled = styled.div.attrs((props: ScoreStyledProperties) => ({}))<ScoreStyledProperties>`
  text-align: ${props => getTextAlign(props.textAlign)};

display:inline-flex;

`
export const LogoStyled = styled.img.attrs((props) => ({}))`
display:inline-flex;
width:30px;
height:30px;
`
