import {BehaviorSubject} from 'rxjs';

export class LogService {

    logging: BehaviorSubject<boolean> = new BehaviorSubject(false);


    setLog(value: boolean) {
        this.logging.next(value);
    }

    log(message: string, data?: any) {
        const date = new Date();
        if (this.logging.getValue()) {
            if (data === undefined) {
                console.log('Blast [' + date.getHours() +
                    ':' + date.getMinutes() + ':' + date.getSeconds() + '.' +
                    date.getMilliseconds() + '] ' + message);
            } else {
                console.log('Blast [' + date.getHours() +
                    ':' + date.getMinutes() + ':' + date.getSeconds() + '.' +
                    date.getMilliseconds() + '] ' + message, data);
            }
        }
    }
}
