import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {CohortDTO} from '../../../providers/session/types';
import {GuslThemeContext} from '../../../providers/theme/GuslThemeProvider';
import {getSettings} from '../../../services/ValidationService';
import {getStyle} from '../../../utils/CssUtils';
import {isDefined, notBlank} from '../../../utils/TypeCheckers';
import {addImageSuffix, assignReferences, noop, RunOnceEffect} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled} from '../text/styled';
import {CohortLogoStyled, CohortLogoWrapperStyled} from './styled';

export const CohortLogoField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'CohortLogoField-' + new Date().getTime());

    const environmentContext = React.useContext(EnvironmentContext)
    const guslThemeContext = React.useContext(GuslThemeContext)
    const [assetsUrl] = useState<string>(environmentService.getEnvironment()?.assetsUrl || '');


    const [formMode, setFormMode] = useState(properties.formMode);
    // const [formValue, setFormValue] = useState<CohortDTO | undefined>(properties?.data || undefined);
    const [formValue, setFormValue] = useState<CohortDTO | undefined>(_guslFormState?.getFieldValue(properties) ||  properties.data || '');
    const [hasLogo, setHasLogo] = useState<boolean>(isDefined(properties?.data?.logo));

    // const [logoUrl, setLogoUrl] = useState<string>((environmentService.getEnvironment()?.assetsUrl || '') + addImageSuffix(properties?.data?.logo || '', guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix) || '');
    const [logoUrl, setLogoUrl] = useState<string>(environmentService.getImageSrc(properties?.data?.logo || '', environmentService.getEnvironment()?.assetsUrl || '', guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,true) || '');


    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setFormValue(properties?.data || '');
        // setLogoUrl(assetsUrl + addImageSuffix(properties?.data?.logo || '', guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix) || '')
        setLogoUrl(environmentService.getImageSrc(properties?.data?.logo || '', environmentService.getEnvironment()?.assetsUrl || '', guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,true) || '')
        setHasLogo(isDefined(properties?.data?.logo))
    }

    useEffect(() => {
        setFormValue(properties?.data || '')
        setLogoUrl(environmentService.getImageSrc(properties?.data?.logo || '', environmentService.getEnvironment()?.assetsUrl || '', guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()).imageSuffix,true) || '')
        setHasLogo(isDefined(properties?.data?.logo))
    }, [properties])


    const doValidation = (fieldValue: any): boolean => {
        return true
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });


    const renderTableView = (): React.ReactElement => {
        const style = getStyle(properties.fieldConfig.tableCss, '')
        return (
            <CohortLogoWrapperStyled style={style}>
                {hasLogo &&
                    <CohortLogoStyled src={logoUrl}  alt="cohort logo"/>}
            </CohortLogoWrapperStyled>
        )

    }
    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        return (
            <>
                {!hideField &&
                    <FloatingFormStyled>
                        <FieldContentWrapperStyled>
                            <FieldContentStyled>
                                {notBlank(logoUrl) &&
                                    <>
                                        <CohortLogoStyled src={logoUrl}
                                                          alt="cohort logo"/><span>{' ' + (formValue?.name || '')}</span>
                                    </>
                                }
                            </FieldContentStyled>
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                }
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )
}
