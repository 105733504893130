import styled, {ThemeProps} from 'styled-components';

export const OttoContentStyled = styled.div.attrs((props) => ({
    className: 'g-0 p-2',
    id: 'otto-content'
}))`
    background-color: #2a2d2c;
    height: 100%;
    flex-direction: column;
`

// background: linear-gradient(90deg, #0a4232, #579b88);

interface BoxStyledProps extends ThemeProps<any> {
    level?: string;
    className?: string;
}

export const ArticleWrapperStyled = styled.div.attrs((props) => ({
    id: 'article-wrapper'
}))`
    border-radius: 5px;
    padding: 5px;
    border: solid 1px rgb(101 245 125 / 20%);
    background-color: #ffffff;
`;

export const OttoBoxStyled = styled.div.attrs((props: BoxStyledProps) => ({
    className: props.className
}))<BoxStyledProps>`
    background-color: #0a4232;
    background: -moz-linear-gradient(left, #0a4232 0%, #579b88 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #0a4232 0%, #579b88 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #0a4232 0%, #579b88 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //padding: 2px 0px;
    border-radius: 5px;
    border: 1px solid ${props => props.level === "WARNING" ? props.theme.colors.warning : props.level === "INFO" ? props.theme.colors.info : "inherit"};
`;

export const OttoBoxNoBgStyled = styled.div.attrs((props: BoxStyledProps) => ({
    className: props.className
}))<BoxStyledProps>`
    padding: 2px 0;
    border-radius: 5px;
`;

export const OttoGrid = styled.div.attrs((props) => ({
    className: 'g-0',
}))`
    width: 850px;
`


export const OttoRow = styled.div.attrs((props) => ({
    className: 'row g-0',
}))`
`

export const OttoCol3 = styled.div.attrs((props) => ({
    className: 'col-lg-3  p-2',
}))`
`

export const OttoCol4 = styled.div.attrs((props) => ({
    className: 'col-lg-4  p-2',
}))`
`

export const OttoCol6 = styled.div.attrs((props) => ({
    className: 'col-lg-6  p-2',
}))`
`
export const OttoCol12 = styled.div.attrs((props) => ({
    className: 'col-lg-12  p-2',
}))`
`

interface ScrollBoxProperties {
    height?: string,
    startPos?: number
}

export const ScrollBox = styled.div.attrs((props: ScrollBoxProperties) => ({
    className: '',
}))<ScrollBoxProperties>`
    overflow-y: auto;
    ${props => props.height && 'height: ' + props.height + ';'}
    ${props => props.startPos && 'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + 90) + 'px)'};

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }

    ::-webkit-scrollbar-track {
        background: #579b88;
    }

    ::-webkit-scrollbar-thumb {
        background: #0c4233;
        border: 1px solid ${props => props.theme.colors.dark[1]};
        border-radius: 10px;

    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.table.titleBgColor};
    }

`
/*
 className="lead my-3"
 */
export const ArticleTextStyle = styled.div.attrs((props) => ({
    className: '',
}))`
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 20px;
`
