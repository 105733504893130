// @ts-ignore
import gradientPalette from './createGradientPaletteArray'

export const defaultRadarColors: { [index: string]: string } = {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    pointBackgroundColor: 'green'
}
export const NUM_OF_COLORS = 23;

export const CHART_COLORS = gradientPalette
    .setGradient(
        '#00ff00',
        '#0041f1',
        '#fafafa',
        '#fd7803',
        '#cf03fd',
        '#e38575')
    .setMidpoint(NUM_OF_COLORS)
    .getArray()
    .map((color: string) => hex2rgba(color));

//shuffleArray(CHART_COLORS);
export const COLORS_ARRAY_FOR_SOLID_GRADIENT = CHART_COLORS.map((color: string, idx: number) => {

        return [
            CHART_COLORS[idx],
            CHART_COLORS[idx + (idx < NUM_OF_COLORS - 2 ? 1 : -1)],
            // CHART_COLORS[idx + (idx < NUM_OF_COLORS - 3 ? 2 : -2)]
        ]


    }
);
export const COLORS_ARRAY_FOR_TRANSPARENT_GRADIENT = CHART_COLORS.map((color: string) => {

        const alpha_1 = '0.15';
        const alpha_2 = '0.6';

        const _gradientSplit = color.split(',');
        const base = _gradientSplit[0] + ',' + _gradientSplit[1] + ',' + _gradientSplit[2];
        const gradient_1 = base + ',' + '0)';
        const gradient_2 = base + ',' + alpha_1 + ')';
        const gradient_3 = base + ',' + alpha_2 + ')';
        return [gradient_1, gradient_2, gradient_3]
    }
);

function hex2rgba(hex: string, alpha = 1) {
    if (alpha > 1 || alpha < 0) {
        throw new Error('alpha is not correct!');
    }

    const red = parseInt(hex.slice(1, 3), 16);
    const green = parseInt(hex.slice(3, 5), 16);
    const blue = parseInt(hex.slice(5, 7), 16);

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}


