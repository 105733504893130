import styled from 'styled-components';


export const NotFoundWrapperStyled = styled.div.attrs((props) => ({
    className:'row g-0 d-flex mx-auto'
}))`
width:500px;
text-align:center;
`


export const NotFoundStyled = styled.div.attrs((props) => ({}))`
font-size:32px;
color: ${props => props.theme.table.columnColor} !important ;

`

export const NotFoundLinkStyled = styled.div.attrs((props) => ({}))`
margin-top:10px;
font-size:14px;
color: ${props => props.theme.login.linkColor} !important ;
text-decoration: underline;
padding:20px;
cursor:pointer;

`

export const ImageStyled = styled.img.attrs((props) => ({
}))`
height:200px;
`
