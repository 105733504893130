import {isMobile} from '../../../../utils/TypeCheckers';

export const BUBBLE_DEFAULT_OPTION = {

    maintainAspectRatio: false,
    responsive: true,
    borderWidth: 1,
    scale: {
        ticks: {
            beginAtZero: true
        },
    },
    plugins: {
         legend: {

            display: true,
            position: (isMobile ? 'top' as const : "bottom" as const),
            fontSize: 5,
            labels: {

                padding: isMobile ? 5 : 15,
                boxWidth: 8,
                boxHeight: 8,
                usePointStyle: true,
                pointStyle: 'circle',
                font: {size: 10},

                filter: (legendItem: { text: string; }, data: { labels: string | string[]; datasets: { realValue: any; }[]; }) => {
                    // console.log({legendItem, data})
                    // First, retrieve the data corresponding to that label
                    const label = legendItem.text
                    const lIndex = data.labels.indexOf(label)
                    const qtd = data.datasets[lIndex].realValue
                    // Second, mutate the legendItem to include the new text
                    //legendItem.text = `${legendItem.text.split(' - ')[1]}:(${qtd})`
                     legendItem.text = `${legendItem.text}:(${qtd})`
                    // Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                    return true
                }
            }
        },
        tooltip: {

            callbacks: {
                title: function (context: {
                    label: string;}[]) {
                    // @ts-ignore
                    return context[0].label +':'+context[0]['dataset'].realValue ;

                },
                label: function () { return ''; }
            }
        },

    },
    scales: {
        x: {
            display: false,
            grid: {
                display: false
            }
        },
        y: {
            display: false,
            grid: {
                display: false
            }
        },

    },
}
