import styled from 'styled-components';

export const DealContainerStyled = styled.div.attrs((props) => ({
    className: '',
    id: 'deal-content'
}))`
    //background-color: #2a2d2c;
    //height: 100%;
`

export const DealInnerContainerStyled = styled.div.attrs((props) => ({
    className: '',
    id: 'deal-inner'
}))`
    height: 100%;
`

export const ChartWrapperStyled = styled.div.attrs((props) => ({
    className: 'p-3',
    id: 'chart-wrapper'
}))`
    height: 200px;
`
