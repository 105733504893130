import * as React from 'react';
import {handleSiteTabs} from "../../../../features/ui/uiSlice";
import {IMenuDTO} from "../../../types";
import {hasActionOrCommand} from "./SiteTabs";

type HandleSiteTabsDO = {
    event: React.MouseEvent,
    item: IMenuDTO,
    dispatch: Function

};
export const handleNavigationClicks = ({event, item, dispatch}: HandleSiteTabsDO) => {
    const className = 'HandleSiteTabs-' + new Date().getTime();
    if (hasActionOrCommand(item)) return null;
    // const key = 'shiftKey';   /// click + shift key tends to select other elements
    // const key = 'altKey';  /// click + alt key feels cleaner
    const key = 'ctrlKey';  /// click + ctrl key feels cleaner
    // const action: string | undefined = event[key] ? 'add' : !event[key] ? 'replace' : undefined;
    const hasKey = event['ctrlKey'] || event['altKey'] || event['metaKey']
    hasKey && dispatch(handleSiteTabs({item, action: hasKey ? 'add' : 'replace'}));

    return null;
};
