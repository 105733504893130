import React, {useContext, useRef, useState} from "react";
import {useAppSelector} from "../../../../../app/hooks";
import {EnvironmentContext} from "../../../../../providers/environment/EnvironmentContext";
import {areFieldsMediaTypeSensitive, compare, matchMediaTypeWithField} from "../../../../../utils/Utils";
import {MediaType, WidgetPanelProperties} from "../../../../types";
import {ActionHeaderColumnStyled, TopFiltersTHeadStyled, TopFiltersWrapperStyled} from "../../../maintain-table/styled";
import {GuslTableState} from "../../guslTableSlice";
import TopFilter from "./TopFilter";

export interface TopFiltersDO {
    code: string;
    isIndividual?: boolean | undefined,
    data: any,
    widgetPanelProperties?: WidgetPanelProperties
}

// MK 11/08/2023
export default function TopFilters({code, isIndividual, data, widgetPanelProperties}: TopFiltersDO) {
    const [className] = useState("TopFilters-" + new Date().getTime());
    const tableState: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);
    const environmentContext = useContext(EnvironmentContext);
    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(widgetPanelProperties));
    const mediaTypeSensitive = tableState ? areFieldsMediaTypeSensitive(tableState.allFields) : true;
    const ref = useRef(null);
    const topFiltersId: string = (isIndividual ? "ind_" : "") + 'top_filters_id_' + code;

    const numOfActions: number = tableState?.rowActions.length;

    return (
        <TopFiltersWrapperStyled key={topFiltersId} id={topFiltersId} ref={ref}>
            <table>
                <TopFiltersTHeadStyled>
                    <tr>
                        {tableState?.rowActions.length > 0 &&
                            <ActionHeaderColumnStyled numberOfActions={numOfActions}/>}
                        {tableState && tableState.allFields
                            .filter(fld => fld.displayInTable)
                            .filter(fld => matchMediaTypeWithField(fld, mediaType, mediaTypeSensitive))
                            .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                            .map((fld, idx) =>
                                <TopFilter
                                    idx={idx}
                                    code={code}
                                    fld={fld}
                                    key={idx}
                                    data={data}
                                    disableColumnResize={true}
                                    isIndividual={isIndividual}
                                    widgetPanelProperties={widgetPanelProperties}/>
                            )}
                    </tr>
                </TopFiltersTHeadStyled>
            </table>
        </TopFiltersWrapperStyled>
    );
};
