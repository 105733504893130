import {ArcElement, Chart as ChartJS, Legend, Title, Tooltip} from 'chart.js';
import React, {useEffect, useRef, useState} from 'react';
import {Pie} from 'react-chartjs-2';
import {log} from "../../../services/LogService";
import ErrorMsg from "../../ui/ErrorMsg";
import {ChartProps} from "../types";
import prepareChartData from "../utils/PrepareChartData";

ChartJS.register(ArcElement, Tooltip, Legend, Title);


export default function PieChart({endpoint, serverData, chartFor}: ChartProps) {

    const [className] = useState('PieChart-' + new Date().getTime());
    const chartRef = useRef<ChartJS>(null);
    const [chartData, setChartData] = useState({datasets: [], labels: []});
    const _options = serverData?.chartOptions?.pie
    const [options, setOptions] = useState(_options);
    const _height = serverData?.chartOptions?.pie?.chart?.canvas.height
    const [height, setHeight] = useState<string | number | undefined>(_height);
    try {
        useEffect(() => {

            const chart = chartRef.current;
            if (!chart) {
                return;
            }

            const {datasets, options, labels} = prepareChartData({
                chartType: 'pie',
                endpoint,
                serverData,
                chart
            })
            // @ts-ignore
            setChartData({labels, datasets});
            setOptions(options);

        }, [chartFor,JSON.stringify(serverData)]);
        // log.info(className, 'MSG001->datasets', chartData)
        // @ts-ignore
        return <Pie ref={chartRef} data={chartData} options={options} type={'pie'} height={height}/>;
    } catch (e) {
        log.error(className, 'MSG001', {endpoint})
        return <ErrorMsg/>
    }

}
