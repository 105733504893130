import GjsEditor from '@grapesjs/react';
import {PluginToLoad} from '@grapesjs/react/dist/utils/plugins';
import grapesjs, {Editor, EditorConfig, type ProjectData, SectorProperties, usePlugin} from 'grapesjs';
import React, {useState} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {sessionService} from '../../../providers/session/SessionService';
import {getSettings, performValidation} from '../../../services/ValidationService';
import {assignReferences, extractExtraFieldProperties, noop, RunOnceEffect, safeStream} from '../../../utils/Utils';
import {FieldConfigDTO, FieldProperties, FormMode} from '../../types';
import 'grapesjs/dist/css/grapes.min.css';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {ExtraFieldProperties} from '../money/types';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled} from '../text/styled';
import {TimetableContent} from './components/bespoke/timetable/TimetableContent';
import {BespokePlugins} from './plugins/BespokePlugins';
import {RegisteredPlugins} from './plugins/RegisteredPlugins';
import './grape-js-field.css';
// @ts-ignore
import STYLES from './sample.css'
import {GrapeContainerStyled, ToggleLabelStyled} from './styled';
import {StyleRegistry} from './styles/StyleRegistry';
import {GrapeConfiguration} from './types';

export const GrapeJsField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    const projectId = properties.fieldConfig.name

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '');

    const [isEdit, setIsEdit] = useState(false);
    const [isDesignMode, setIsDesignMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties));
    const [grapeConfiguration, setGrapeConfiguration] = useState<GrapeConfiguration | undefined>(undefined);

    RunOnceEffect(() => {
        const request: any = {}

        sessionService.post<any, GrapeConfiguration>('/article/grape-configuration', request)
            .then(response => {
                const configuration: GrapeConfiguration = response?.data
                setGrapeConfiguration(configuration)
            })
            .catch((error: any) => {
                console.log('error', error)
            })
    })


    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setIsEdit(mode === FormMode.EDIT || mode === FormMode.NEW || mode === FormMode.ACTION_DIALOG)
        setFormValue(properties?.data || '');
    }

    const doValidation = (): boolean => {
        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            formValue,
            setSubmitted,
            setErrorMessage)
    }

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation)
    });

    function getRequiredPlugins(fieldConfig: FieldConfigDTO) {
        // return ['gjs-blocks-basic', 'oss-header', 'oss-sub-header', 'oss-comment', 'oss-image']

        return ['gjs-blocks-basic', 'oss-header', 'oss-sub-header', 'oss-comment', 'oss-image', 'base-react', 'ucit', 'timetable','grapesjs-preset-webpage'
            // 'base-react', 'react-component', 'html-block',
        ]


        // return ['gjs-blocks-basic','oss-header','oss-sub-header','oss-comment','oss-image','gjs-open-import-template']
        // return ['gjs-blocks-basic','grapesjs-component-code-editor','grapesjs-parser-postcss','grapesjs-user-blocks'];
        // return ['grapesjs-user-blocks'];

    }

    function getRequiredStyles(fieldConfig: FieldConfigDTO) {
        return ['general', 'typography', 'decoration', 'dimension', 'flex', 'transform']
    }

    const [thirdPartyPlugins] = useState<PluginToLoad []>(() => {
        const requiredPlugins: string[] = getRequiredPlugins(properties.fieldConfig)
        return RegisteredPlugins.filter(plugin => requiredPlugins.includes(plugin.id.toLowerCase()));
    });

    const [bespokePlugins] = useState<any[]>(() => {
        const callbackPlugin = usePlugin
        const requiredPlugins: string[] = getRequiredPlugins(properties.fieldConfig)
        // @ts-ignore
        return BespokePlugins.filter(plugin => requiredPlugins.includes(plugin.id.toLowerCase())).map(plugin => callbackPlugin(plugin.plugin));
    })

    const [styleSectors] = useState<SectorProperties []>(() => {
        const requiredStyles: string[] = getRequiredStyles(properties.fieldConfig)
        return StyleRegistry.filter(style => requiredStyles.includes(style.id.toLowerCase())).map(style => style.sector);
    });


    const gjsOptions: EditorConfig = {
        height: '100%',
        storageManager: {
            type: 'local',
            options: {
                local: {key: `gjsProject-${projectId}`}
            }
        },
        showToolbar: false,
        showDevices: true,
        showOffsets: true,

        undoManager: {trackSelection: false},

        selectorManager: {
            componentFirst: true
        },
        styleManager: {
            sectors: styleSectors
        },

        projectData: {
            // assets: [
            //     'https://via.placeholder.com/350x250/78c5d6/fff',
            //     'https://via.placeholder.com/350x250/459ba8/fff',
            //     'https://via.placeholder.com/350x250/79c267/fff',
            //     'https://via.placeholder.com/350x250/c5d647/fff',
            //     'https://via.placeholder.com/350x250/f28c33/fff',
            // ],
            pages: [
                {
                    name: 'Home page',
                    component: `<body id="gjs-body"></body>`,
                },
            ],
        }
    }

    const onUpdate = (projectData: ProjectData, editor: Editor) => {
        console.log('onUpdate', {projectData});
    }

    const addSaveTemplate = (editor: Editor) => {
        editor.Commands.add('save-template', {
            run: function (editor, sender) {
                sender && sender.set('active'); // turn off the button
                let components = editor.getComponents();
                let style = editor.getStyle()
                let templateData = {
                    components: components,
                    style: style
                };
                localStorage.setItem('gjs-storage', JSON.stringify(templateData));
            }
        });
    }

    const addCloseButton = (editor: Editor) => {
        // Add close button
        editor.Panels.addButton('options', [{
            id: 'close-edit',
            className: 'fa fa-window-close icon-blank',
            command: 'cmd-close-edit',
            attributes: {title: 'Close Edit'}
        }]);

        editor.Commands.add('cmd-close-edit', {
            run: function (editor: Editor, sender: any) {
                setIsDesignMode(false)
                sender && sender.set('active'); // turn off the button
            },
            stop: function (editor: Editor, sender: any) {
            }
        });
    }

    const makeBlocksActive = (editor: Editor) => {
        const openBlockButton = editor.Panels.getButton('views', 'open-blocks');
        if (openBlockButton) {
            openBlockButton.set('active', true)
        }
    }

    const hideButtons = (editor: Editor) => {
        // sw-visibility, preview, fullscreen
        editor.Panels.removeButton('options', 'fullscreen');
        editor.Panels.removeButton('options', 'export-template');

        editor.Panels.removeButton('views', 'open-layers'); // layers
        editor.Panels.removeButton('views', 'open-sm'); // gear - settings
        // editor.Panels.removeButton('views', 'open-tm'); // paint brush styles

    }

    const addStyles = (editor: Editor) => {
        // editor.setStyle(STYLES);
        // editor.addComponents(`<style>${STYLES}</style>`)
        editor.addComponents(`<style>td { color:red}</style>`)
    }

    const loadServerSideComponents = (editor: Editor) => {
        if (grapeConfiguration) {
            safeStream(grapeConfiguration.components).forEach(component => {
                editor.Components.addType(component.name, {
                    isComponent: (el) => {
                        return el.tagName === component.name.toUpperCase()
                    },
                    extend: 'base-react-component',
                    model: {
                        defaults: {
                            component: TimetableContent,
                            url: component.url,
                            stylable: false,
                            resizable: true,
                            editable: false,
                            draggable: true,
                            droppable: true,
                            attributes: {
                                mlsid: 'Default MLSID',
                                editable: false,
                                type: 'style1',
                                content: ''
                            },
                            traits: [
                                ...component.traits
                            ]
                        }
                    }
                })

                editor.BlockManager.add(component.name, {
                    media: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15 15" xml:space="preserve"><g>
                   <text x="4px" y="12px" textanchor="middle" fill="#FFFFFF" strokewidth="5px" fontsize="100px" fontweight="bold">${component.name.substring(0, 1).toUpperCase()}</text></g></svg>`,
                    label: `<div class='gjs-fonts gjs-f-b1'>${component.label}</div>`,
                    category: component.category,
                    content: `<TimetableContent data-gjs-type="${component.name}"></TimetableContent>`
                });

            })
        }
    }

    const createBespokePanels = (editor: Editor) => {

        // try {
        //     const panelsDiv = document.getElementsByClassName('gjs-pn-panels');
        //     console.log('panelsDiv', panelsDiv)
        //     if (panelsDiv && panelsDiv.item(0)) {
        //         const htmlElement = document.createElement("div");
        //
        //         const root = createRoot(htmlElement);
        //         root.render(<MyFirstPanel/>);
        //
        //         panelsDiv?.item(0)?.appendChild(htmlElement)
        //     }
        // } catch
        //     (error: any) {
        //     console.log('error', error)
        // }

        // Add panel button
        editor.Panels.addButton('views', [{
            id: 'my-panel',
            className: 'fas fa-save icon-blank',
            command: 'cmd-my-panel',
            attributes: {title: 'My Panel'},
            visible: true,
            active: true,

        }]);

        editor.Commands.add('cmd-my-panel', {
            run: function (editor: Editor, sender: any) {
                console.log('-- cmd-my-panel run --')
                // const panel1 = editor.Panels.getPanel('my-panel');
                // panel1?.set('active')
                sender && sender.set('active'); // turn off the button
                // modal.setTitle('<div>Choose Template</div>');
                // modal.setContent('<div>A template</div>');
                // modal.open();
                // modal.getModel().once('change:open', function () {
                //     console.log('change open')
                //     //do stuff before modal open
                // });

            },
            stop: function (editor: Editor, sender: any) {
                console.log('-- cmd-my-panel stop --')
                const panel1 = editor.Panels.getPanel('my-panel');
                panel1?.set('active')
            }
        });


    }
    const onEditor = (editor: Editor) => {

        addCloseButton(editor)
        addSaveTemplate(editor)
        makeBlocksActive(editor)
        hideButtons(editor)
        addStyles(editor)
        loadServerSideComponents(editor)
        createBespokePanels(editor)
    }

    const onToggleDesignMode = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (e) {
            e.stopPropagation()
        }
        setIsDesignMode(!isDesignMode)
    }

    const renderTableView = (): React.ReactElement => {
        return <div>&nbsp;</div>
    }

    const renderFormView = (): React.ReactElement => {

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        return (
            <>
                {!hideField && <>

                    <FloatingFormStyled id={'ffs_' + properties?.fieldConfig?.name}>
                        <FieldContentWrapperStyled id={'fcws_' + properties?.fieldConfig?.name}
                                                   isEdit={isEdit}
                                                   inLine={properties.inline}>
                            <FieldContentStyled id={'fcs_' + properties?.fieldConfig?.name}>
                                {(formMode === FormMode.EDIT || formMode === FormMode.NEW) && <>
                                    <ToggleLabelStyled>
                                        <a href='#'
                                           onClick={(e) => onToggleDesignMode(e)}>{isDesignMode ? 'Hide' : 'Show'}</a>
                                    </ToggleLabelStyled>
                                    <GrapeContainerStyled isDesignMode={isDesignMode}>
                                        <GjsEditor
                                            grapesjs={grapesjs}
                                            grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
                                            options={gjsOptions}
                                            plugins={[...thirdPartyPlugins, ...bespokePlugins]} // , HtmlContentGjsComponent
                                            onEditor={onEditor}
                                            onUpdate={onUpdate}
                                            style={STYLES}
                                            waitReady={true}>
                                        </GjsEditor>
                                    </GrapeContainerStyled>
                                </>
                                }
                            </FieldContentStyled>
                            {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                            {submitted && errorMessage &&
                                <small className="yellow">{errorMessage}</small>}
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                </>}
            </>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
