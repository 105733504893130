import styled, {ThemeProps} from 'styled-components';
import {OpenTabProps, OpenTabWrapperProps} from "../split-list-view/styled";

export interface TabsHeaderStyledV2Props extends ThemeProps<any> {

    className:string
}
export const TabsHeaderStyled = styled.div.attrs((props) => ({
    className: ' d-flex align-items-center justify-content-start'
}))`
   height:32px;
   background-color: ${props => props.theme.colors.dark[0]};
   overflow-x:auto;
   overflow-y:hidden;
   display:block;
   padding-bottom:5px;
  // margin-top:-2px;
   //border-bottom: solid 1px  ${props => props.theme.navigation.sideMenu.rightBorderColor} ;
   margin-bottom:5px;
   
   
    ::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-height);
      padding-bottom:10px;
    };
    
    ::-webkit-scrollbar-track {
        background: ${props => props.theme.table.panelBgColor};   
    };
    
    ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.accentColorMuted};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;
   
   
    };
    
    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.table.titleBgColor};
    };  
 
   
`
export const TabsHeaderStyledV2 = styled.div.attrs((props:TabsHeaderStyledV2Props) => ({
    className: props.className
}))<TabsHeaderStyledV2Props>`
   width:100px;
   position:fixed;
   bottom:70px;
   border-radius:3px;
   border: 2px solid ${props => props.theme.colors.light};
   right:0px;
   overflow-x:hidden;
   overflow-y:auto;
   display:block;
 
    ::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-height);
      padding-left:10px;
    };
    
    ::-webkit-scrollbar-track {
        background: ${props => props.theme.table.panelBgColor};   
    };
    
    ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.accentColorMuted};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;
   
   
    };
    
    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.table.titleBgColor};
    };  
 
   
`
export const QuickAccessBtnStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-center text-center',
    role:'button'
}))`
   font-size:11px;
   width:50px;
   height:50px;
   border-radius:100%;
   position:fixed;
   bottom:15px;
   right:15px;
   color:${props => props.theme.colors.light};
   background-color: ${props => props.theme.colors.accentColor};
   :hover{
   color:${props => props.theme.colors.light};
   }
   
`
export const
    OpenTabWrapperV2 = styled.div.attrs((props: OpenTabWrapperProps) => ({
        className: 'd-flex align-items-center justify-content-between '

    }))<OpenTabWrapperProps>`
 
     height: 30px;
   background-color: ${props => props.isAnimation ? props.theme.colors.dark[0]: props.isActive ?  props.theme.table.rowHoverBgColor : props.theme.colors.dark[1]};
    // background-color: ${props => props.isActive ?  props.theme.table.rowHoverBgColor : props.theme.colors.dark[1]};
     color: ${props => props.isActive ?  props.theme.colors.accentColor : props.theme.table.panelBgColor};
     border-top : 1px solid ${props => props.isActive ? props.theme.navigation.sideMenu.rightBorderColor : props.theme.colors.light};
     :hover{
         background-color:${props => props.theme.colors.dark[0]};
         color:${props => props.theme.colors.dark[1]};
     }
`
export const
    OpenTabStyledV2 = styled.div.attrs((props: OpenTabProps) => ({
        onClick: props.onClick,

    }))<OpenTabProps>`
    //text-align: ${props => props.isAnimation  ? 'center':'left'}
    padding: 2px 5px;
    cursor:pointer;
    color: ${props => props.theme.colors.light};
    font-size:12px;
    font-weight:lighter;
     
`
