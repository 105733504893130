import styled from 'styled-components';

interface ArticleTitle {
    active: boolean,
    large?: boolean

}

export const ArticleTitleStyled = styled.div.attrs((props: ArticleTitle) => ({
    className: 'mb-1 otto-link '
}))<ArticleTitle>`
        // ${props => props.active && 'font-size: 12px; font-weight:bold; text-decoration: underline; color: #fff; line-height:20px;'}
    font-size: ${props => props.large ? '14px' : '12px'};
    line-height: ${props => props.large ? '20px' : '20px'};

    border: solid 1px rgb(101 245 125 / 20%);
    border-radius: 5px;
    padding: 5px;
    // background-color: rgb(255 255 255 / 10%);
    background-color:  ${props => props.active ? 'rgb(255 255 255 / 30%)' : 'rgb(255 255 255 / 10%)'};
    color: #fff;
`

export const ArticleDealNameStyled = styled.div.attrs((props) => ({}))`
    text-decoration-color:  rgba(255, 240, 246, 0.5);
    text-decoration-line: underline;
    text-underline-offset: 4px;
`

export const ArticleSummaryStyled = styled.div.attrs((props) => ({}))`
    margin-bottom: 10px;

`

export const ArticleTimeStyled = styled.span.attrs((props) => ({}))`
    font-weight: bold;
    font-size: 14px;
    padding-right: 5px;
`
export const ArticleSubjectStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    display:inline;
    
    h3,p{
        color: #ffffff;
    }
    h3 {
        font-weight: bold;
    }
`

export const ArticleInlineHeaderStyled = styled.div.attrs((props) => ({
    className: ''
}))`
    line-height: 20px;
    color: #000000;
`

export const ArticleHeaderStyled = styled.div.attrs((props) => ({
    className: ''
}))`
    line-height: 20px;
`

export const ArticleSubHeaderStyled = styled.div.attrs((props) => ({
    className: ''
}))`
    padding-top: 5px;
    font-size: 16px;
`

interface ArticleDateProperties {
    large?: boolean
}

export const ArticleInlineDateStyled = styled.div.attrs((props: ArticleDateProperties) => ({
    className: 'mb-3'
}))<ArticleDateProperties>`
    font-size: ${props => props.large ? '14px' : '10px'};
    padding-top: 5px;
    color: #000000;
`

export const ArticleDateStyled = styled.div.attrs((props: ArticleDateProperties) => ({
    className: 'text-white mb-3'
}))<ArticleDateProperties>`
    font-size: ${props => props.large ? '14px' : '10px'};
    padding-top: 5px;
`


/*
    className: 'link-text '

 */
export const ArticleInfoStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    padding-top: 4px;
    line-height: 22px;

    span {
        font-weight: bold;
    }
`
export const WysiwygTableContentStyled = styled.div.attrs((props) => ({
    className: 'wysiwyg-content',
    id: 'wysiwyg_content'
}))`
    padding: 40px 10px 10px 10px;
`
export const GoToRouteStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    padding-top: 4px;
    line-height: 22px;
    color: #003bff;

    &:hover {
        text-decoration: underline;
    }
`
