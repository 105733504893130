import {useEffect, useState} from "react";

export const useKeyPress = function(targetKey: string) {
    const [keyPressed, setKeyPressed] = useState(false);
    function downHandler(e: { key: string; }) {
        if (e.key === targetKey) {
            setKeyPressed(true);
        }
    }
    function upHandler(e: { key: string; }) {
        if (e.key === targetKey) {
            setKeyPressed(false);
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    });

    return keyPressed;
};
