import styled, {ThemeProps} from "styled-components";

export interface DraggableColumnProperties extends ThemeProps<any> {
    key: number,
    role: string,
    onClick: Function,
    onDragEnter: Function,
    onDragStart: Function,
    onDragEnd: Function,
    onDragOver: Function,
    draggable: boolean,
    active: boolean
}

export interface DraggableIconProperties extends ThemeProps<any> {
    active: boolean,
    icon: string,
    className: string
}
export const DraggableColumnStyled = styled.div.attrs((props: DraggableColumnProperties) => ({
    key: props.key,
    role: props.role,
    onClick: props.onClick,
    onDragEnter: props.onDragEnter,
    onDragStart: props.onDragStart,
    onDragEnd: props.onDragEnd,
    onDragOver: props.onDragOver,
    draggable: true,
    className: 'd-flex justify-content-between mb-1' + (props.active ? 'active' : '')

}))<DraggableColumnProperties>
`
 color:${props => !props.active ?  props.theme.money.negativeColor : props.theme.colors.dark[1]};
 border: 1px dashed ${props => props.active ? props.theme.colors.dark[1] : 'transparent'};
 // border-bottom: 1px solid ${props => props.theme.colors.dark[0]};
 font-size:14px;
  width: 100%;

  @media all and (max-width: 768px){
    line-height: 30px;
  }
  
  @media only screen and (max-device-width: 1024px) {
    line-height: 30px;
  }
  
  
`

export const DraggableIconStyled = styled.i.attrs((props: DraggableIconProperties) => ({
    className: props.icon + ' ' + props.className,

}))<DraggableIconProperties>
    `
     color:${props => props.active ? props.theme.colors.dark[1] :  props.theme.money.negativeColor};
    `

export interface HideShowProperties extends ThemeProps<any> {
    active: boolean,
}

export const HideShowColumnStyled = styled.div.attrs((props:HideShowProperties ) => ({
    className: 'd-flex justify-content-between mb-1' + (props.active ? 'active' : '')

}))<HideShowProperties>
    `
      text-align: left;
    `

interface TickProperties extends ThemeProps<any> {
    active?: boolean,
}

export const TickStyled = styled.span.attrs((props: TickProperties) => ({
}))<TickProperties>
    `
      color: ${props => !props.active ? props.theme.money.negativeColor : props.theme.colors.dark[1]};
      text-align: left;
      cursor: pointer;
      
      :hover {
        background-color: ${props =>props.theme.colors.dark[0]};
        border-radius: 5px;
      }
      @media all and (max-width: 768px) {
        font-size: 24px;
      }

      @media only screen and (max-device-width: 1024px) {
        font-size: 24px;
      }

    `
export const ColumnNameStyled = styled.span.attrs((props: TickProperties) => ({

}))<TickProperties>
    `
      color:${props => !props.active ? props.theme.money.negativeColor : props.theme.colors.dark[1]};
    text-align: left;
    `
