import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {BET_SLIP_NON_MOBILE_PANEL_WIDTH, CONTENT_START_POSITION} from '../../providers/theme/GuslThemeProvider';
import {fieldService} from '../FieldService';
import {closeDialog, FooterOverlayState} from '../ui/navigation/left-hand-menu/footerOverlaySlice';
import {
    OverlayContentStyled,
    OverlayOffCanvasBodyStyled,
    OverlayOffCanvasHeaderStyled,
    OverlayOffCanvasStyled
} from './styled';

interface GuslOverlayProperties {
}

export const GuslOverlay = ({}: GuslOverlayProperties): React.ReactElement => {

    const environmentContext = React.useContext(EnvironmentContext);
    const [isIOS] = useState<boolean>(environmentContext.isNativeIOS());

    const dispatch = useAppDispatch();

    const showOverlay: boolean = useAppSelector(state => state.footerOverlaySlice.showOverlay);
    const overlayState: FooterOverlayState = useAppSelector(state => state.footerOverlaySlice);
    const [visibleScrolling, setVisibleScrolling] = useState(true)
    const isMobile = environmentContext.isMobileDevice();

    const hideDialog = () => {
        dispatch(closeDialog(true))
    }

    const renderBodyContent = (): React.ReactElement => {
        let props = {
            properties: overlayState.properties,
            rowData: overlayState.rowData,
            onClose: hideDialog
        }
        if (overlayState.route) {
            return <>{fieldService.getBespokeInstance(overlayState.route, props)}</>
        }

        return <></>
    }

    const mobileStyle = {
        width: "100%",
        maxWidth: '100%',
        height: '450px',
        border: '1px solid #000',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        marginBottom: '50px'
    }

    const nonMobileStyle = {
        top: CONTENT_START_POSITION + 'px',
        width: BET_SLIP_NON_MOBILE_PANEL_WIDTH + "px",
        height: '100%',
        border: '1px solid #000',
        // borderTopLeftRadius: '15px'
        // borderTopRightRadius: '15px'
    }

    return (
        <OverlayOffCanvasStyled show={showOverlay}
                                onHide={hideDialog}
                                scroll
                                backdrop={false}
                                style={isMobile ? mobileStyle : nonMobileStyle}
                                placement={isMobile ? 'bottom' : 'end'}
        >
            <OverlayOffCanvasHeaderStyled closeButton={true}
                                          onHide={hideDialog}>{overlayState?.title || ''}</OverlayOffCanvasHeaderStyled>
            <OverlayOffCanvasBodyStyled>
                <OverlayContentStyled>
                    {renderBodyContent()}
                </OverlayContentStyled>
            </OverlayOffCanvasBodyStyled>
        </OverlayOffCanvasStyled>
    )
}
