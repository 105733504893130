import AutoNumeric from "autonumeric";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Subscription } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BlastContext } from "../../../providers/blast/BlastContext";
import { FieldDeltaDO, SimpleMessageBO } from "../../../providers/blast/commands";
import { EnvironmentContext } from "../../../providers/environment/EnvironmentContext";
import { environmentService } from "../../../providers/environment/EnvironmentService";
import { SessionContext } from "../../../providers/session/SessionContext";
import { SystemContext } from "../../../providers/system/SystemContext";
import { CONTENT_START_POSITION } from "../../../providers/theme/GuslThemeProvider";
import { log } from "../../../services/LogService";
import { isDefined } from "../../../utils/TypeCheckers";
import { RunOnceEffect, unSubscribe } from "../../../utils/Utils";
import { CurrencyDTO, FieldProperties } from "../../types";
import { ComponentTitleWrapperStyled } from "../../ui/styled";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { TableTitleStyled } from "../maintain-table/styled";
import {
  BuySellState,
  initBuySellOrder,
  performCalculation,
  removeBuySellOrder,
  resetBuySellOrder,
  showError,
  updateBuySellOrder,
  updatePrice
} from "./buySellSlice";
import { getLookupFieldProperties } from "./form-properties";
import {
  BlankLineStyled,
  BoxStyled,
  BuySellBodyStyled,
  BuySellPanelFormWrapper,
  BuySellPanelStyled,
  DisclaimerHeaderDivStyled,
  DisclaimerHeaderStyled,
  HeaderWrapperStyled,
  LookupWrapperStyled,
  WidgetWrapperStyled
} from "./styled_v2";
import { BuySellOrder, OrderAction, PreOrderDTO, TickerDO } from "./types";
import Widgets from "./v2_components/acuity-widgets/Widgets";
import { Blotter } from "./v2_components/blotter/Blotter";
import RenderBSForm from "./v2_components/bs-form/RenderBSForm";
import BSHeader from "./v2_components/BSHeader";
import BSList from "./v2_components/BSList";
import { BlastTickerDO, NewTickerDO, TickersResponseDO } from "./v2_components/types";

export interface BuySellFormProperties {
  ticker?: string;
  actionParam?: string;
  noTitle?: boolean;
  side?: string;
  isTradePanel?: boolean;
  isCloseOut?: boolean;
}

// code for form
const BUY_SELL_CODE = "buy-sell";

export const BuySellForm = (properties: BuySellFormProperties): React.ReactElement<BuySellFormProperties> => {

  const [className] = useState("BuySellForm-" + new Date().getTime());
  const systemContext = useContext(SystemContext);
  const sessionContext = useContext(SessionContext);
  const blastContext = useContext(BlastContext);
  const environmentContext = React.useContext(EnvironmentContext);
  const _buySellSlice: BuySellState = useAppSelector(state => state.buySellSlice[className]);
  const [isIOS] = useState<boolean>(environmentContext.isNativeIOS());

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [initState, setInitState] = useState<boolean>(false);
  const [placeOrderError, setPlaceOrderError] = useState<boolean>(false);
  const isMobile = environmentContext.isMobileDevice();

  const quantityRef = useRef<any>();
  const limitRef = useRef<any>();
  const [quantityField, setQuantityField] = useState<AutoNumeric | undefined>();
  const [limitField, setLimitField] = useState<AutoNumeric | undefined>();


  const [systemReady, setSystemReady] = useState<boolean>(false);
  const [blastSubscription, setBlastSubscription] = useState<Subscription>();
  const [loading, setLoading] = useState<boolean>(false);

  const [tickers, setTickers] = useState<NewTickerDO[]>([]);
  const [originalTickers, setOriginalTickers] = useState<NewTickerDO[]>([]);
  const [preOrderError, setPreOrderError] = useState<boolean>(false);
  const [preOrderErrorMsg, setPreOrderErrorMsg] = useState<string>("");
  const [lookUpClosed, setLookUpClosed] = useState(false);
  const [acuityCode, setAcuityCode] = useState<PreOrderDTO["acuityCode"]>(undefined);

  const menuItem = systemContext.getSystemConfig().menuGroups
    .filter((menuGroup => menuGroup?.label === "Trading"))[0]?.menuItems?.filter(
    (menuItem) => menuItem?.code === "NewOrder")[0];
  const ticker: NewTickerDO | undefined = _buySellSlice?.ticker as NewTickerDO;

  const [currentTicker, setCurrentTicker] = useState<NewTickerDO | undefined>(ticker);
  const [wLQueryRefresher, setWLQueryRefresher] = useState<number>(0);

  const registerChildReference = () => {
  };

  const [lookupFieldProperties, setLookupFieldProperties] = useState<any | undefined>(undefined);
  let stepMap: { [index: number]: number } = {};
  const numOfDecimals: number = 2;
  const maxNumberOfDecimals = 10;
  const contentElement = useRef(null);
  const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION);
  const tickerParam = params?.tickerId ? decodeURIComponent(params?.tickerId) : undefined;
  const actionParam = params?.action ? decodeURIComponent(params?.action) : undefined;
  const [widgetExpanded, setWidgetExpanded] = useState<boolean>(false);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [blastConnected, setBlastConnected] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<CurrencyDTO[]>([]);

  const [currentlySelectedFavorites, setCurrentlySelectedFavorites] = useState<NewTickerDO[]>([]);

  // useEffect(() => {
  //     const currencySubscription = blastContext.observeCollection('currency').subscribe((data: any[]) => {
  //         setCurrencies(data);
  //     });
  //
  //     const blastConnectionSubscription = blastContext.observeConnection().subscribe((connected: boolean) => {
  //         console.log('----------- bloast connected',connected)
  //         console.log('currency=>',blastContext.getCollection('currency'))
  //         setBlastConnected(connected);
  //     });
  //     return () => {
  //         unSubscribe(blastConnectionSubscription);
  //     }
  // }, [blastContext]);


  RunOnceEffect(() => {
    let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
      setFooterHeight(height);
    });
    return () => {
      unSubscribe(heightSubscription);
    };
  });

  useEffect(() => {
    window.requestAnimationFrame(function() {
      setTimeout(() => {
        // @ts-ignore
        if (contentElement?.current?.offsetHeight) {
          // @ts-ignore
          const rect = contentElement?.current?.getBoundingClientRect();
          if (rect) {
            setStartPos(rect.y);
          }
        }
      }, 100);
    });
  }, [contentElement]);

  Array.from({ length: maxNumberOfDecimals }).forEach((_, idx) => {
    stepMap[idx] = 1 / Math.pow(10, idx);
  });
  const limitStep = stepMap[numOfDecimals];


  useEffect(() => {
    const lookupProps = getLookupFieldProperties(BUY_SELL_CODE, registerChildReference, getFieldConfig("realtickMapper"));
    if ((systemReady && typeof tickerParam === "undefined" && typeof actionParam === "undefined")) {

      getTickerMapping("", OrderAction.NEW, lookupProps);
    }
  }, [systemReady]);

  // console.log({ _buySellSlice });

  const getTickerMapping = (tickerParam: string, orderAction: OrderAction.NEW | OrderAction.CLOSE | OrderAction.TRADE, lookupProps: FieldProperties) => {


    sessionContext.post<any, TickersResponseDO>("/tickers/get", { searchValue: tickerParam })
      .then((response) => {
        const responseTickers: NewTickerDO[] = response.data.tickers;
        if (responseTickers.length > 0) {
          setTickers(responseTickers);
          setOriginalTickers(responseTickers);
          let currentTicker: NewTickerDO | undefined;
          if (tickerParam) {
            currentTicker = responseTickers.find(tic => tic.id === tickerParam);
          }
          if (currentTicker && tickerParam) {
            performPreOrderQuery(currentTicker, orderAction);
            if (orderAction === OrderAction.TRADE || orderAction === OrderAction.NEW) {
              const value = { ...lookupProps };
              value.data = responseTickers[0]?.symbol;
              setLookupFieldProperties(value);
            }

          } else if (!tickerParam && responseTickers[0]) {
            performPreOrderQuery(responseTickers[0], orderAction);
            if (orderAction === OrderAction.TRADE || orderAction === OrderAction.NEW) {
              const value = { ...lookupProps };
              value.data = responseTickers[0]?.symbol;
              setLookupFieldProperties(value);
            }
          } else {
            dispatch(showError({
              code: className,
              hasError: true,
              errorMessage: "Contract not mapped",
              apiError: false
            }));
          }

        } else {
          dispatch(showError({
            code: className,
            hasError: true,
            errorMessage: "Contract not mapped",
            apiError: false
          }));
        }
        setInitState(true);
      }, reason => {
        dispatch(showError({
          code: className,
          hasError: true,
          errorMessage: "Failed to find ticker, contact support.",
          apiError: true
        }));
        log.error(className, "ERR001", reason);
        setInitState(true);
      });
  };

  // nice page covering options for AutoNumeric https://www.jqueryscript.net/other/Easy-Numbers-Currency-Formatting-Plugin-autoNumeric.html#google_vignette
  // https://docs.autonumeric.org/Documentation/static%20methods/#get
  useEffect(() => {
    window.requestAnimationFrame(function() {
      try {
        if (quantityRef?.current) {
          if (!AutoNumeric.isManagedByAutoNumeric(quantityRef?.current)) {
            const qtyElement = new AutoNumeric(quantityRef.current, _buySellSlice?.order?.getQuantity() || "", {
              // currencySymbol: '$',
              modifyValueOnWheel: false,
              decimalPlaces: 2,
              selectOnFocus: false,
              minimumValue: "0"
              // minimumValue: '1',
            });
            qtyElement.node().addEventListener("input", (event: Event) => {
              // @ts-ignore
              const value = parseInt((event?.target?.value ? "" + event?.target?.value : "0").replaceAll(",", ""), 10);
              try {
                // const number = parseFloat(event);
                if (isDefined(value)) {
                  dispatch(updateBuySellOrder({
                    code: className,
                    quantity: value || 0
                  }));
                } else {
                  dispatch(updateBuySellOrder({
                    code: className,
                    quantity: 0
                  }));
                }
              } catch (err) {
                // log.warn(className, 'WRN001', 'not a number', value);
              }
            });
            setQuantityField(qtyElement);
          } else {
            if (_buySellSlice?.order?.isCloseout() && quantityField && isDefined(_buySellSlice?.order?.getQuantity())) {
              // @ts-ignore
              quantityField.set(_buySellSlice.order.getQuantity());
            }
          }
        }
        if (limitRef?.current) {
          if (!AutoNumeric.isManagedByAutoNumeric(limitRef?.current)) {
            //buySellState?.order?.getQuantity() ||
            const limitElement = new AutoNumeric(limitRef.current, _buySellSlice?.order?.getLimitPrice() || "", {
              // currencySymbol: '$',
              // rawValueDivisor <-- go for money
              modifyValueOnWheel: false,
              decimalPlaces: 8,
              selectOnFocus: false,
              minimumValue: "0"
            });
            limitElement.node().addEventListener("input", (event: Event) => {
              // @ts-ignore
              const value = parseFloat(((event?.target?.value ? "" + event?.target?.value : "0").replaceAll(",", "")), 10);
              try {
                // const number = parseFloat(event);
                if (isDefined(value)) {
                  dispatch(updateBuySellOrder({
                    code: className,
                    limitPrice: value || 0
                  }));
                } else {
                  dispatch(updateBuySellOrder({
                    code: className,
                    limitPrice: 0
                  }));
                }
              } catch (err) {
                // log.warn(className, 'WRN001', 'not a number', value);
              }
            });
            setLimitField(limitElement);
          }
        }
      } catch (err) {
        console.error("error", err);
      }
    });
  }, [className, systemReady, loading, _buySellSlice?.order?.getOrderType()]);

  useEffect(() => {
    let orderAction: OrderAction = OrderAction.NEW;
    try {
      if (actionParam) {
        if (actionParam.toUpperCase() === "NEW") {
          orderAction = OrderAction.NEW;
        } else if (actionParam.toUpperCase() === "CLOSE") {
          orderAction = OrderAction.CLOSE;
        } else if (actionParam.toUpperCase() === "POSITIONNEWORDER") {
          orderAction = OrderAction.NEW;
        } else if (actionParam.toUpperCase() === "TRADE") {
          // desktop watchlist to trade
          // orderAction = OrderAction.TRADE
          orderAction = OrderAction.NEW;
        }
      }
    } catch (error) {
      console.error("Failed to convert", actionParam);
    }
    dispatch(initBuySellOrder({
      code: className,
      ticker: undefined,
      orderAction: orderAction,
      side: properties.side ?? "BUY"
    }));

    if (tickerParam) {
      // closeOrder
      const lookupProps = getLookupFieldProperties(BUY_SELL_CODE, registerChildReference, getFieldConfig("realtickMapper"));
      getTickerMapping(tickerParam, orderAction, lookupProps);
    } else {
      setInitState(true);
    }

    let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
      setSystemReady(systemReady);
      if (systemReady) {
        // set up form fields
        const lookupProps = getLookupFieldProperties(BUY_SELL_CODE, registerChildReference, getFieldConfig("realtickMapper"));

        if (properties.ticker) {

          let ordAction = OrderAction.NEW;
          if (properties.actionParam) {
            if (properties.actionParam.toUpperCase() === "NEW") {
              ordAction = OrderAction.NEW;
            } else if (properties.actionParam.toUpperCase() === "CLOSE") {
              ordAction = OrderAction.CLOSE;
            } else if (properties.actionParam.toUpperCase() === "CLOSE") {
              ordAction = OrderAction.CLOSE;
            } else if (properties.actionParam.toUpperCase() === "TRADE") {
              ordAction = OrderAction.TRADE;
            }
          } else {
            ordAction = OrderAction.NEW;
          }
          getTickerMapping(properties.ticker, ordAction, lookupProps);

        }

        if (tickerParam) {
          // closeOrder
          getTickerMapping(tickerParam, orderAction, lookupProps);
        } else {
          setLookupFieldProperties(lookupProps);
        }

      }
    });
    return () => {
      unSubscribe(loaderSubscription);
    };
  }, [className, dispatch]);

  useEffect(() => {
    let loaded = true;
    if (systemReady && loaded) {
      unSubscribe(blastSubscription);
      let blastDeltaSubscription: Subscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {

        const blastTicker: BlastTickerDO = message as unknown as BlastTickerDO;
        //console.log("blasting",blastTicker.data.value?.bbgTicker)
        //console.log("blasting",blastTicker, currentTicker)
        const dailyHigh: number = blastTicker?.data?.value?.dailyHigh ?? currentTicker?.dailyHigh;
        const dailyLow: number = blastTicker?.data?.value?.dailyLow ?? currentTicker?.dailyLow;
        const volume: number = blastTicker?.data?.value?.volume ?? currentTicker?.volume;
        const lastPrice: number = blastTicker?.data?.value?.lastPrice ?? currentTicker?.lastPrice;
        if (blastTicker?.data?.value?.bbgTicker && ticker && blastTicker.data.value.bbgTicker === ticker.id) {
          // @ts-ignore
          const updatedTicker: NewTickerDO = { ...currentTicker, dailyLow, dailyHigh, volume, lastPrice };
          console.log("===========updating current ticker==========");
          console.log({ oldValues: currentTicker, newValues: updatedTicker, blastTicker });
          setCurrentTicker(updatedTicker);


        }

        dispatch(updatePrice({
          code: className,
          ticker: message?.data?.keyValue,
          bidPrice: message?.data?.value?.bid,
          askPrice: message?.data?.value?.ask
        }));
      });
      setBlastSubscription(blastDeltaSubscription);
    }

    return () => {
      unSubscribe(blastSubscription);
    };

  }, [className, systemReady, ticker]);

  useEffect(() => {
    return () => {
      dispatch(removeBuySellOrder({ code: className }));
    };
  }, [className]);

  const performPreOrderQuery = (ticker: TickerDO, orderAction: OrderAction, idField: string = "id") => {

    setLookUpClosed(true);
    setLoading(true);
    setPreOrderError(false);
    setPreOrderErrorMsg("");
    setCurrentTicker(ticker as NewTickerDO);
    // @ts-ignore
    sessionContext.post<any, PreOrderDTO>("/order-entry/pre-order", { id: ticker[idField], orderAction })
      .then((response) => {
        setLoading(true);
        // @ts-ignore
        if (response.data.hasError || response.data.error) {
          dispatch(showError({
            code: className,
            hasError: true,
            errorMessage: response.data.errorMessage,
            apiError: true
          }));
          setPreOrderError(true);
          setPreOrderErrorMsg(response.data.errorMessage);
          log.error(className, "ERR004", response.data.errorMessage);
        } else {
          const data: PreOrderDTO = response.data;
          setAcuityCode(data.acuityCode);
          // log.debug(className, "PreOrder", data);
          dispatch(initBuySellOrder({
            code: className,
            preOrder: data,
            ticker: ticker,
            orderAction: data.orderAction,
            side: properties.side ?? "BUY"
          }));
          dispatch(performCalculation({
            code: className
          }));
        }
      }, reason => {
        setLoading(true);
        dispatch(showError({
          code: className,
          hasError: true,
          errorMessage: "Error with pre order, contact support.",
          apiError: true
        }));
        log.error(className, "ERR002", reason);
        setPreOrderError(true);
        setPreOrderErrorMsg("Error with pre order, contact support.");
      });

  };

  if (!systemContext.getSystemConfig().menuGroups
    .filter((menuGroup => menuGroup.label === "Trading")).length) {
    return <></>;
  }

  const fields = menuItem?.action?.rows[0].columns[0].fields;
  const getFieldConfig = (_field: string) => {
    return fields?.filter((field) => field.name === _field)[0];
  };

  const reset = (internal: boolean, orderAction: OrderAction) => {
    if (orderAction === OrderAction.CLOSE) {
      navigate("/Position");
      return;
    }

    dispatch(resetBuySellOrder({
      code: className
    }));
    if (lookupFieldProperties?.reference?.doRefresh) {
      lookupFieldProperties.reference.doRefresh();
    }
    const lookupProps = getLookupFieldProperties(BUY_SELL_CODE, registerChildReference, getFieldConfig("realtickMapper"));

    getTickerMapping("", OrderAction.NEW, lookupProps);
  };

  const setSideHandler = (side: string) => {
    dispatch(updateBuySellOrder({
      code: className,
      side: side
    }));
  };
  const onOrderType = (e: React.MouseEvent<HTMLElement, MouseEvent>, orderType: string) => {
    if (e) {
      e.stopPropagation();
    }
    dispatch(updateBuySellOrder({
      code: className,
      orderType: orderType
    }));
  };

  const isDMA = (order: BuySellOrder): boolean => {
    if (!isDefined(order)) {
      return false;
    }

    if (order.isDelayed()) {
      return false;
    }

    return order.isDma();
  };

  // const isDMA = (order: BuySellOrder): Boolean => {
  //   isDefined(order);
  //   {
  //     if (order.isDelayed()) {
  //       return false;
  //     }
  //     return order!.isDma();
  //   }
  //   return false;
  // };

  const renderWatchList = (): React.ReactElement => {
    // const menuItem = systemContext.getMenuConfig('WatchListMobile');
    // const refreshCounter = 1
    // console.log('WatchListMobile ', menuItem)
    // if (menuItem) {
    //     return (
    //         <div id={'WatchListMobile'}>
    //             <TabbedPage key={'pd_buysell'} path={'/WatchListMobile'} widgetPanelProperties={{
    //                 width: 300,
    //                 height: 800,
    //                 x: 0,
    //                 y: 0,
    //                 noTableControls: true,
    //                 noPagination: true
    //             }}/>
    //         </div>
    //     )
    // }
    // return (<></>)
    return (
      <BSList performPreOrderQuery={performPreOrderQuery} queryRefresher={wLQueryRefresher}
              setWLQueryRefresher={setWLQueryRefresher} setCurrentlySelectedFavorites={setCurrentlySelectedFavorites} />
    );
  };

  const renderOrderFormContent = (): React.ReactElement => {

    return (
      <LookupWrapperStyled id={"buy-sell-lookup-wrapper"}>
        <div className={"row"}>
          <div className="col-lg-12 p-0 m-0">
            <HeaderWrapperStyled>
              <BSHeader
                lookUpClosed={lookUpClosed}
                setLookUpClosed={setLookUpClosed}
                setTickers={setTickers}
                _buySellSlice={_buySellSlice}
                tickers={tickers}
                ticker={currentTicker as NewTickerDO}
                originalTickers={originalTickers}
                performPreOrderQuery={performPreOrderQuery}
                setWLQueryRefresher={setWLQueryRefresher}
                currentlySelectedFavorites={currentlySelectedFavorites} />
            </HeaderWrapperStyled>
          </div>
          <div className={`p-0 m-0  col-lg-${widgetExpanded ? "12" : "6"}`}>
            <Widgets ticker={ticker} widgetExpanded={widgetExpanded} setWidgetExpanded={setWidgetExpanded}
                     acuityCode={acuityCode} />
          </div>
          <div className={`col-lg-3 p-0 m-0 ${widgetExpanded ? "d-none" : ""}`}>
            {renderWatchList()}
          </div>
          <div className={` col-lg-3 p-0 m-0 ${widgetExpanded ? "d-none" : ""}`}>
            <WidgetWrapperStyled
            >
              {preOrderError ?
                <BoxStyled><span className={"invalid xs-small"}>{preOrderErrorMsg}</span></BoxStyled> :
                <RenderBSForm buySellState={_buySellSlice}
                              properties={properties}
                              setSideHandler={setSideHandler}
                              onOrderType={onOrderType}
                              placeOrderError={placeOrderError}
                              isDMA={isDMA}
                              limitRef={limitRef}
                              quantityRef={quantityRef}
                              limitStep={limitStep}
                              reset={reset}
                              setPlaceOrderError={setPlaceOrderError} />
              }
            </WidgetWrapperStyled>
          </div>
          <div className={`p-0 m-0  col-lg-12`}>
            <Blotter code={className} />
          </div>
        </div>
      </LookupWrapperStyled>
    );
  };

  const renderHeader = (buySellState: BuySellState): React.ReactElement => {
    return (
      <>
        <ComponentTitleWrapperStyled>
          <TableTitleStyled isMobile={false} footerHeight={footerHeight}>
            {buySellState.orderAction === OrderAction.CLOSE ? "Close Out Form" : "New Order Form"}
            {isMobile &&
              <DisclaimerHeaderDivStyled>*All GBP quotes in Pounds not
                pence.</DisclaimerHeaderDivStyled>}
            {!isMobile &&
              <DisclaimerHeaderStyled>*All GBP quotes in Pounds not pence.</DisclaimerHeaderStyled>}
          </TableTitleStyled>
        </ComponentTitleWrapperStyled>
      </>
    );
  };


  const renderPage = (buySellState: BuySellState): React.ReactElement => {

    if (!buySellState.order) {
      return <></>;
    }
    const isPartOfCloseOut = isDefined(properties.ticker);

    return (
      <div className="row-g-0" id={"bs_wrapper"}>
        {!properties.noTitle && renderHeader(buySellState)}
        <BuySellBodyStyled id={"bs_body"} isMobile={isMobile} ref={contentElement} startPos={startPos}
                           footerHeight={footerHeight}>
          <BuySellPanelFormWrapper isMobile={isMobile} isTradePanel={properties.isTradePanel}
                                   isIOS={isIOS}
                                   footerHeight={footerHeight}
                                   id={"bs_panel_wrapper"}>
            <BuySellPanelStyled id={"bs_panel"}>
              {!isPartOfCloseOut && renderOrderFormContent()}
              <BlankLineStyled>&nbsp;</BlankLineStyled>
            </BuySellPanelStyled>
          </BuySellPanelFormWrapper>
        </BuySellBodyStyled>
      </div>
    );
  };

  return (
    <>
      {!initState ? <LoadingSpinner /> : renderPage(_buySellSlice)}
    </>
  );
};

