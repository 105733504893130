import React, {useState} from 'react';
import {Subscription} from 'rxjs';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {SessionContext} from '../../providers/session/SessionContext';
import {GuslUser} from '../../providers/session/types';
import {log} from '../../services/LogService';
import {isDefined} from '../../utils/TypeCheckers';
import {RunOnceEffect, unSubscribe} from '../../utils/Utils';
import {
    LookupColumnStyled,
    LookupTableBodyStyled,
    LookupTableRowStyled,
    LookupTableStyled
} from '../common/lookup/styled';
import {TableContentStyled} from '../common/maintain-table/styled';
import {OptionTableContainerStyled} from '../common/option/styled';
import {ActionCompletedBO} from '../types';
import {
    ClientStyled,
    ClientsWrapperStyled,
    MobilePOAWrapperStyled,
    PowerOfAttorneyContainerStyled,
    PowerOfAttorneyLabelStyled,
    PowerOfAttorneyLookupStyled,
    PowerOfAttorneyOptionComboStyled,
    PowerOfAttorneySelectionStyled,
    PowerOfAttorneySelectorContainerStyled,
    PowerOfAttorneyTableResponsiveStyled,
    TitleStyled
} from './styled';

interface ClientsIdsDTO {
    clientIds: string[];
}

export const PowerOfAttorneySelector = (): React.ReactElement => {

    const [className] = useState("PowerOfAttorneySelector-" + new Date().getTime());
    const sessionContext = React.useContext(SessionContext);
    const environmentContext = React.useContext(EnvironmentContext);

    const [selectorOpen, setSelectorOpen] = useState<boolean>(false);
    const isMobile = environmentContext.isMobileDevice();

    const [currentClient, setCurrentClient] = useState<string>();
    const [clientIds, setClientIds] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<GuslUser | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    RunOnceEffect(() => {
        let subscription = sessionContext.watchLogin().subscribe((guslUser: GuslUser | null) => {
            if (isDefined(guslUser)) {
                setCurrentClient(guslUser?.thirdPartyId)
                // @ts-ignore
                setUser(guslUser);
            }
        });
        return () => {
            unSubscribe(subscription)
        }

    });

    const loadData = () => {
        setLoading(true)
        sessionContext.post<any, ClientsIdsDTO>('/poa/get-all', {})
            .then((response) => {
                if (response.data) {
                    const data: ClientsIdsDTO = response.data;
                    setClientIds(data.clientIds)
                    setLoading(false)
                    setSelectorOpen(true)
                }
            })
            .catch((error => {
                console.log('error', error);
                setLoading(false)
            }))

    }
    const onClientChange = (clientId: string) => {
        if (clientId) {
            sessionContext.post<any, ActionCompletedBO>('/poa/set-client', {clientId: clientId})
                .then((response) => {
                    setCurrentClient(clientId);
                    if (user) {
                        user.thirdPartyId = clientId
                    }
                    // vvvv this was causing login issues
                    // sessionContext.updateUser(user)

                    // @ts-ignore
                    // should reload as client may have different permissions
                    window.location.reload();
                }, reason => {
                    log.error(className, 'ERR002', reason)
                })
        }
        setSelectorOpen(false)
    }


    const renderClient = (clientId: string, idx: number) => {

        return (
            <LookupTableRowStyled role={'button'}
                                  key={'id_' + idx}
                                  id={'id_' + idx}
                                  active={false}
                                  lookupField={true}
                                  onClick={() => onClientChange(clientId)}>
                <LookupColumnStyled textAlign={'left'}
                                    key={'hdr_row_' + idx}>{clientId}</LookupColumnStyled>
            </LookupTableRowStyled>
        )
    }

    const renderClientIds = () => {
        return (
            <>
                {clientIds?.map((clientId, idx) => renderClient(clientId, idx))}
            </>
        )

    }

    const renderClientLookup = (): React.ReactElement => {
        return (
            <PowerOfAttorneyLookupStyled>
                <OptionTableContainerStyled>
                    <TableContentStyled footerHeight={footerHeight}>
                        <PowerOfAttorneyTableResponsiveStyled>
                            <LookupTableStyled>
                                <LookupTableBodyStyled id={'lk_cohorts'}
                                                       isOverFlown={false}>
                                    {renderClientIds()}
                                </LookupTableBodyStyled>
                            </LookupTableStyled>
                        </PowerOfAttorneyTableResponsiveStyled>
                    </TableContentStyled>
                </OptionTableContainerStyled>

            </PowerOfAttorneyLookupStyled>
        )
    }

    const onSelectorClicked = () => {
        if (selectorOpen) {
            setSelectorOpen(!selectorOpen)
        } else {
            loadData()
        }
    }
    const renderPowerOfAttorney = (): React.ReactElement => {
        return (
            <PowerOfAttorneyContainerStyled
                isMobile={isMobile}
                onClick={() => onSelectorClicked()}>
                <PowerOfAttorneyLabelStyled>Client: {currentClient}</PowerOfAttorneyLabelStyled>
                <PowerOfAttorneySelectionStyled>
                    <PowerOfAttorneyOptionComboStyled>
                        <i className={'fa-solid fa-caret-down me-1'}></i>
                    </PowerOfAttorneyOptionComboStyled>
                </PowerOfAttorneySelectionStyled>
                {selectorOpen && !loading && renderClientLookup()}
            </PowerOfAttorneyContainerStyled>
        )
    }

    const onItemClick = (event: React.MouseEvent, clientId: string) => {
        event.stopPropagation();
        onClientChange(clientId)
    }
    const renderClientMobile = (clientId: string, idx: number): React.ReactElement => {
        return (
            <ClientStyled key={'pos_c_' + idx} id={'pos_c_' + idx}
                          onClick={(e) => onItemClick(e, clientId)}
            >{clientId}</ClientStyled>
        )
    }

    const onOpenClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsOpen(!isOpen)
        if (!isOpen) {
            loadData()
        }

    }


    const renderCaret = (): React.ReactElement => {
        return <span className={isOpen ? 'orderBy-asc' : 'orderBy-desc'} onClick={(e) => onOpenClick(e)}> <span
            className={'caret'}/></span>
    }

    const renderLabel = (): React.ReactElement => {
        return (
            <TitleStyled onClick={(e) => onOpenClick(e)}>Client: {currentClient}</TitleStyled>
        )
    }

    const renderForMobile = (): React.ReactElement => {
        return (
            <MobilePOAWrapperStyled>
                {renderLabel()}
                {renderCaret()}
                {isOpen && <ClientsWrapperStyled>
                    {clientIds?.map((clientId, idx) => renderClientMobile(clientId, idx))}
                </ClientsWrapperStyled>}
            </MobilePOAWrapperStyled>
        )
    }

    if (isMobile && sessionContext.hasPowerOfAttorney()) {
        return (
            <>{renderForMobile()}</>
        )
    }

    return (
        <>
            {sessionContext.hasPowerOfAttorney() &&
                <PowerOfAttorneySelectorContainerStyled>{renderPowerOfAttorney()}</PowerOfAttorneySelectorContainerStyled>}
        </>
    )


}
