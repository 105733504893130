import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import {translateService} from '../../../services/TranslateService';
import {isBlank} from '../../../utils/TypeCheckers';
import {
    ErrorMessageStyled,
    FieldStyled,
    IconWrapperStyled,
    TotpCardStyled,
    TotpIconStyled,
    TotpStyled
} from '../styled';

type TotpProps = {
    value: string;
    hasLabel: boolean;
    hasIcon: boolean;
    autoFocus: boolean;
    onNextField: () => void;
    onChange: (value: string) => void;
    formSubmitted: boolean;
}
const Totp = (props: TotpProps): React.ReactElement => {

    const label = translateService.getValue('login.totp.label', 'Google Authenticator Code')
    const errorMessage = translateService.getValue('login.totp.error', 'Code is required.')
    const placeholder = translateService.getValue('login.totp.placeholder', 'Google Authenticator Code')

    return (
        <TotpCardStyled>
            {props.hasLabel && <label htmlFor="totp">{label}</label>}
            <TotpStyled>
                <Container fluid>
                    <Row>
                        {props.hasIcon && <IconWrapperStyled>
                            <TotpIconStyled/> </IconWrapperStyled>}
                        <Col>
                            <FieldStyled
                                name="totp"
                                type="text"
                                placeholder={placeholder}
                                tabIndex={3}
                                autoFocus={props.autoFocus}
                                required
                                value={props.value}
                                onChange={e => {
                                    props.onChange(e.target.value)
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        props.onNextField()
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row><Col>
                        {props.formSubmitted && isBlank(props.value) &&
                            <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                    </Col></Row>
                </Container>
            </TotpStyled>
        </TotpCardStyled>
    )
}

export default Totp;
