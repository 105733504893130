import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {clone} from '../../../utils/Utils';

export interface MasterCardFormState {
    [id: string]: CardFormState
}

export interface CardFormState {
    code: string;
    formValue: any;

}

export interface CardFormInitState {
    code: string;
    formValue: any;
}

export interface CardFormCleanUpState {
    code: string;
}

const initialState: MasterCardFormState = {}

export interface CardFormUpdateAction {
    code: string;
    name: string;
    value: any | undefined
}

export interface CardFormCleanUpPayload {
    code: string;
}

const loadInitValues = (values: CardFormInitState): CardFormState => {
    return {
        code: values.code,
        formValue: values.formValue,
    }
}

const updateFieldValue = (entry: CardFormState, action: CardFormUpdateAction): CardFormState => {
    // console.log('updateFieldValue', action)
    const newFormValue: any = clone(entry.formValue)
    newFormValue[action.name] = action.value
    entry.formValue = newFormValue
    return entry;
}

const createDefault = (code: string): CardFormState => {
    return {
        code: code,
        formValue: {},
    }
}
const getState = (state: MasterCardFormState, code: string): CardFormState => {
    let entry: CardFormState = state[code]
    if (!entry) {
        entry = createDefault(code)
    }
    return entry;
}

export const cardFormSlice = createSlice({
    name: 'cardFormSlice',
    initialState,
    reducers: {
        initCardForm(state, action: PayloadAction<CardFormInitState>) {
            state[action.payload.code] = loadInitValues(action.payload)
        },
        cardFormFieldChange(state, action: PayloadAction<CardFormUpdateAction>) {
            const entry: CardFormState = getState(state, action.payload.code)
            updateFieldValue(entry, action.payload)
            state[action.payload.code] = entry
        },
        cleanUpCardForm(state, action: PayloadAction<CardFormCleanUpState>) {
            delete state[action.payload.code]
        },
    }
})

export const {
    initCardForm,
    cardFormFieldChange,
    cleanUpCardForm
} = cardFormSlice.actions

export default cardFormSlice.reducer
