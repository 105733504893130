import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppSelector} from '../../../app/hooks';
import {inputClass} from "../../../pages/login/LoginUsernamePassword";
import {BlastContext} from '../../../providers/blast/BlastContext';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {SessionContext} from "../../../providers/session/SessionContext";
import {SystemContext} from "../../../providers/system/SystemContext";
import {log} from "../../../services/LogService";
import {getSettings, shouldHide, validate} from "../../../services/ValidationService";
import {RunOnceEffect, unSubscribe} from "../../../utils/Utils";
import {FieldPositionProperties, FieldProperties, FormMode, LookupConfigDTO} from "../../types";
import {handleResize, handleWindowChange, initFieldPositionProperties} from '../../ui/FieldUtils';
import ElementWithPopover from "../element-with/element-with-popover/ElementWithPopover";
import {ElementWithTooltip} from "../element-with/element-with-tooltip/ElementWithTooltip";
import FieldLabel from "../field-label/FieldLabel";
import MiniLoadingSpinner from "../gusl-table/table-controls/MiniLoadingSpinner";
import {
    ControlContainerStyled,
    DownArrowStyled,
    DownArrowSvgPathStyled,
    DownArrowSvgStyled,
    IndicatorsContainerStyled,
    LookupContainerStyled,
    LookupDataItemStyled,
    LookupInputStyled,
    LookupItemStyled,
    LookupTableFieldStyled,
    MatchesStyled,
    NoMatchStyled,
    SelectAllWrapperStyled,
    SelectedFormValueStyled,
    SelectedFormValueWrapperContentStyled,
    SelectedFormValueWrapperStyled,
    SeparatorStyled,
    SpacerStyled
} from "../lookup/styled";
import {CurrentMatchesDO, IsSelectedDO, SearchRequestDTO, StartAgainDO} from '../lookup/types';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {maintainTableService} from '../maintain-table/MaintainTableService';
import {FloatingFormStyled} from "../text/styled";


export const MultiLookupField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {


    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const systemContext = useContext(SystemContext);
    const sessionContext = useContext(SessionContext);
    const blastContext = useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext);

    const [className] = React.useState<string>(() => "MultiLookupField-" + new Date().getTime());
    const [formMode, setFormMode] = useState(properties.formMode);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [submitted, setSubmitted] = useState(false);
    const valueRef = useRef(properties?.data);
    const inputRef = useRef<HTMLInputElement>(null);
    const [matchesOpen, setMatchesOpen] = useState<boolean>(false);
    const serverSearch: boolean = typeof properties.fieldConfig.lookupSearchUrl !== "undefined";
    const blastSearch: boolean = properties.fieldConfig.lookupCollection !== "";
    const [loading, setLoading] = useState<boolean>(false);
    const [lookupData, setLookupData] = useState<any[]>([]);

    // const [formValue, setFormValue] = useState<string | undefined>(undefined);
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || '');

    let [multiFormValues, setMultiFormValues] = useState<any[]>([]);

    const [refreshRequest, setRefreshRequest] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>(inputRef?.current?.value || '');
    let matches: string[] = [];
    const referenceName = properties.reference.name;
    const matchesWrapperId = 'matches_' + referenceName;

    const [lookupConfig, setLookupConfig] = useState<LookupConfigDTO | undefined>(() => {
        return {
            name: "lookup",
            extractLabel: "return `${data.code}` ",
            tableFields: ["code"],
            fieldTypes: ["text"],
            sortBy: "code",
            pageable: false,
            retValue: "return data"
        } as LookupConfigDTO;
    });

    const lookupElement = useRef(null);
    const [oldResize, setOldResize] = useState<number | undefined>(undefined);
    const [fieldPositionProperties, setFieldPositionProperties] = useState<FieldPositionProperties>(() => initFieldPositionProperties(environmentContext, properties));

    RunOnceEffect(() => {
        const subscriptionResize = handleResize(environmentContext, lookupElement, oldResize, fieldPositionProperties, setFieldPositionProperties, setOldResize)
        return () => {
            unSubscribe(subscriptionResize)
        }
    })

    useEffect(() => {
        handleWindowChange(lookupElement, fieldPositionProperties, setFieldPositionProperties)
    }, [formMode]);

    const extractTableValue = () => {
        if (properties.data) {
            // need to use Lookup config
            return properties.data.code;
        }
        return "";
    };
    const extractValue = (): string => {
        if (properties?.fieldConfig.lookup) {
            // @ts-ignore
            const lookupCfg: LookupConfigDTO | undefined = maintainTableService.findLookupConfig(properties?.fieldConfig?.lookup, systemContext.getSystemConfig().lookups)
            if (lookupCfg) {
                setLookupConfig(lookupCfg)
                try {
                    return format(properties.data)
                } catch (err) {
                    console.log('Error setting table value');
                }
            }
        }
        return properties?.data?.code || ''
    }

    const [tableValue, setTableValue] = useState<string>(() => {
        return extractValue();
    });

    log.info(className, 'MSG001', {multiFormValues});


    // useEffect(() => {
    //     setRefreshRequest(refreshRequest + 1);
    //     setFormValue(format(properties?.data || ""));
    //     setTableValue(format(properties?.data || ""));
    //
    //
    // }, [properties]);

    useEffect(() => {
        setMultiFormValues(multiFormValues)
    }, [refreshRequest])

    /** in overflown container with long list, we scroll back to
     * selected element that is deep down in the list
     * to avoid manual scrolling down again...*/
    function scrollToSelected(scrollTop: number) {
        try {
            // @ts-ignore
            document.getElementById(matchesWrapperId).scrollTo({top: scrollTop, behavior: 'smooth'});
        } catch (e) {
            /** dropdown is closed*/
            //log.error(className, 'MSGE-1', 'no element')

        }
    }

    const rowSelected = (rowData: any) => {

        setFormValue(format(rowData));
        properties.onChange(properties.fieldConfig.name, rowData);

        const selected = format(rowData);

        if (!multiFormValues.includes(selected)) {
            multiFormValues.unshift(selected)
        }

        const scrollTop = document?.getElementById(matchesWrapperId)?.scrollTop || 0;
        setTimeout(scrollToSelected, 100, scrollTop)
    };

    function rowDeSelected(selected: string | undefined) {
        multiFormValues = multiFormValues.filter((item) => item !== selected)
        setMultiFormValues(multiFormValues)
    }

    const getLookupData = () => {
        console.log('select url', properties.fieldConfig.lookupSelectUrl)
        console.log('search url', properties.fieldConfig.lookupSearchUrl)
        let data: any[] = [];

        if (properties.fieldConfig.lookupCollection) {

            blastContext.getCollection(properties.fieldConfig.lookupCollection).forEach(item => data.push(item))
            setLookupData(data);
            setLoading(false);
            log.debug(className, 'MSG001', '=========> BLAST')


        } else if (properties.fieldConfig.lookupSelectUrl) {
            log.debug(className, 'MSG001', '=========> LOOKUP URL')
            setLoading(true);
            sessionContext.get<any[]>(properties.fieldConfig.lookupSelectUrl)
                .then((response) => {
                    data = response.data || [];
                    setLookupData(response.data || []);
                    setLoading(false);

                }, reason => {
                    setErrorMessage('Could not load data');
                    setLoading(false);
                    log.error(className, "ERR001", "Error getting template data", reason);
                });
        } else if (properties.fieldConfig.lookupSearchUrl) {
            setLoading(true);
            sessionContext.post<SearchRequestDTO, any[]>(properties.fieldConfig.lookupSearchUrl,
                {searchValue: inputRef?.current?.value as string})
                .then((response) => {
                    data = response.data || [];
                    setLookupData(response.data || []);
                    setLoading(false);
                }, reason => {
                    log.error(className, "ERR001", "Error getting template data", reason);
                    setErrorMessage('Could not load data');
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }

        return data;
    };

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
    };

    const onRefresh = () => {
    };

    const doValidation = (fieldValue: any): boolean => {
        setSubmitted(true);
        setErrorMessage('');
        const hideField = shouldHide(formMode, properties.fieldConfig, fieldValue);
        if (!hideField) {
            const message = validate(properties.menuItem?.code,
                properties.fieldConfig.name,
                properties.fieldConfig.validators,
                "text",
                fieldValue);
            if (message) {
                setErrorMessage(message);
                return false;
            }
        }
        return true;
    };

    RunOnceEffect(() => {
        // init
        properties.reference.changeMode = onFormModeChange;
        properties.reference.doRefresh = onRefresh;
        properties.reference.doValidation = doValidation;
        properties.reference.register(properties.reference);
        return () => {
            // clean up
        };
    });

    const convertData = (data: any) => {
        try {
            return JSON.parse(data);
        } catch (exception) {
            return data;
        }
    };

    const format = (rowData: any): string => {
        if (!lookupConfig || !rowData || !lookupConfig.extractLabel) {
            return "";
        }
        try {
            const func = new Function("self", "data", lookupConfig.extractLabel);
            const val = func(this, convertData(rowData));
            if (val === "undefined") {
                return "";
            }
            return val;
        } catch (error) {
            log.error(className, "ERR001", "Json parsing failed", rowData);
            return "";
        }
    };

    function showLookupDataHandler() {
        if (!lookupData.length || blastSearch) {
            getLookupData();
        }
        setMatchesOpen(!matchesOpen);
        setFormValue("");
        setSearchValue("");
        setErrorMessage("");

        if (inputRef?.current?.value) {
            inputRef.current.value = "";
        }

    }

    function isSelected({rowData}: IsSelectedDO) {
        return multiFormValues.includes(format(rowData));
    }

    function isJustSelected({rowData}: IsSelectedDO) {
        return multiFormValues[0] === format(rowData);
    }

    function inputHandler() {

        if (inputRef.current) {
            if (matches.length && !matchesOpen) {
                setMatchesOpen(true)
            }
            setSearchValue(inputRef.current.value)
            /* SERVER LOOKUP*/
            if (properties.fieldConfig.lookupSelectUrl) {
                /*get data once*/
                if (inputRef.current.value.length === 1 && !lookupData.length) {
                    getLookupData();
                }
            }
            /*BLAST | SERVER SEARCH*/
            else if (properties.fieldConfig.lookupCollection || properties.fieldConfig.lookupSearchUrl) {
                /*look for data when search string is between 2-4 chars*/
                if (inputRef.current.value.length > 1 && inputRef.current.value.length < 5) {
                    getLookupData();
                }
            }
        }
    }

    function canSelectAll({collection}: CurrentMatchesDO) {
        let canSelectAll: boolean = false;

        collection.forEach((item) => {
            if (!multiFormValues.includes(format(item))) {
                canSelectAll = true
            }
        })

        return canSelectAll;
    }


    /**COMPONENTS ↓ ↓ ↓ */

    function SelectAll({collection}: CurrentMatchesDO) {

        const selectAll: boolean = canSelectAll({collection});

        function selectAllHandler() {

            collection.forEach((rowData: any) => {
                selectAll ? rowSelected(rowData) : rowDeSelected(format(rowData))
            })
            setRefreshRequest(refreshRequest + 1)
        }

        return (
            <SelectAllWrapperStyled onClick={selectAllHandler}>
                <span>{selectAll ? "" : "de-"}select all {collection.length}</span>
            </SelectAllWrapperStyled>
        );
    }

    function CurrentMatches({collection}: CurrentMatchesDO) {
        return <MatchesStyled id={matchesWrapperId}>
            <SelectAll collection={collection}/>

            {collection.map((rowData, idx) =>
                <LookupDataItemStyled key={idx}
                                      isJustSelected={isJustSelected({rowData})}
                                      isSelected={isSelected({rowData})}
                                      onClick={() => rowSelected(rowData)}>
                    {lookupConfig && lookupConfig.tableFields.map((field, idx) =>
                        <LookupItemStyled key={idx}>{rowData[field]}</LookupItemStyled>)}
                    {isSelected({rowData}) && <>&#x2713;</>}
                </LookupDataItemStyled>)}
        </MatchesStyled>
    }

    function Matches() {

        if (searchValue.length > 1) {

            matches = serverSearch ? lookupData : lookupData.filter((item) =>
                item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.code.toLowerCase().includes(searchValue.toLowerCase())
            )

        }

        if (loading) {
            return <MiniLoadingSpinner isLoading={loading}/>
        } else if (errorMessage?.length) {
            return <NoMatchStyled>{errorMessage} <StartAgain/></NoMatchStyled>
        } else { // @ts-ignore
            if (inputRef?.current?.value?.length > 2 && !matches.length) {
                return <NoMatchStyled>No matches <StartAgain/></NoMatchStyled>
            } else if ((matches.length && matchesOpen)) {
                return <CurrentMatches collection={matches}/>
            } else if (matchesOpen) {
                return <CurrentMatches collection={lookupData}/>
            } else {
                return <></>
            }
        }
    }

    function StartAgain({selected}: StartAgainDO) {
        return <ElementWithTooltip
            element={<i className={'fa-solid fa-xmark ms-3 me-1'}
                        onClick={() => {
                            setFormValue("");
                            //setSearchValue("");
                            setErrorMessage("");
                            if (inputRef?.current?.value) {
                                inputRef.current.value = "";
                            }
                            rowDeSelected(selected);
                            const scrollTop = document?.getElementById(matchesWrapperId)?.scrollTop || 0;
                            setTimeout(scrollToSelected, 100, scrollTop)

                        }}
            />}
            tooltip={<span>Remove</span>}
        />
    }

    const renderDownArrow = (): React.ReactElement => {
        return <ElementWithTooltip
            element={
                <IndicatorsContainerStyled isActive={matchesOpen} onClick={showLookupDataHandler}>
                    <SeparatorStyled/>
                    <DownArrowStyled>
                        <DownArrowSvgStyled><DownArrowSvgPathStyled/></DownArrowSvgStyled>
                    </DownArrowStyled>
                </IndicatorsContainerStyled>
            }
            tooltip={<span>{matchesOpen ? "Collapse" : "Expand"}</span>}/>;
    };

    const renderTableView = (): React.ReactElement => {
        return (
            <LookupTableFieldStyled>{tableValue}</LookupTableFieldStyled>
        );
    };

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        if (hideField) {
            return <></>;
        }
        if (formMode === FormMode.VIEW || disableField) {
            return <>
                <div className="form-floating" key={"id_" + refreshRequest}>
                    <div className={inputClass()}>
                        {renderTableView()}
                    </div>
                    <FieldLabel properties={properties}/>
                </div>
            </>;
        }
        return (
            <div>
                <div className="row g-0">
                    {multiFormValues.slice(0, 2).map((formValue, idx) =>
                        <SelectedFormValueWrapperStyled key={idx}
                                                        className={multiFormValues.length < 3 ? 'col-5' : 'col'}>
                            <SelectedFormValueWrapperContentStyled>
                                <SelectedFormValueStyled isMulti>
                                    <span> {formValue}</span>
                                    <StartAgain selected={formValue}/>
                                </SelectedFormValueStyled>
                            </SelectedFormValueWrapperContentStyled>
                        </SelectedFormValueWrapperStyled>
                    )}
                    {/** all selected items in popover if > 2*/}
                    {multiFormValues.length > 2 &&
                        <>
                            <ElementWithPopover
                                placement={"left"}
                                trigger={'click'}
                                element={
                                    <SelectedFormValueWrapperStyled key={'all-selected'} role={'button'}
                                                                    className="col-2 ">
                                        <SelectedFormValueWrapperContentStyled>
                                            <SelectedFormValueStyled>
                                                <SpacerStyled>all ({multiFormValues.length})</SpacerStyled>
                                            </SelectedFormValueStyled>
                                        </SelectedFormValueWrapperContentStyled>
                                    </SelectedFormValueWrapperStyled>
                                }
                                popover={
                                    <>
                                        {multiFormValues.map((formValue, idx) =>
                                            <SelectedFormValueStyled key={idx} isMulti>
                                                <span> {formValue}</span>
                                                <StartAgain selected={formValue}/>
                                            </SelectedFormValueStyled>
                                        )}
                                    </>
                                }/>
                        </>}

                </div>
                <FloatingFormStyled  ref={lookupElement}>
                    <LookupContainerStyled submitted={submitted}
                                           noValue={!formValue}>
                        <ControlContainerStyled {...fieldPositionProperties}>
                            <div>
                                <LookupInputStyled onInput={inputHandler}
                                                   ref={inputRef}
                                                   type="text"
                                                   placeholder={serverSearch ? "Start typing..." : "Start typing or select..."}/>
                            </div>
                            <div className={'d-flex align-items-center'}>

                                {searchValue.length ?
                                    <ElementWithTooltip
                                        element={<span onClick={() => {
                                            setSearchValue("");
                                            setMatchesOpen(false)
                                            if (inputRef?.current?.value) {
                                                inputRef.current.value = "";
                                            }
                                        }}><i className={'fa-solid fa-xmark me-3'}/></span>}
                                        tooltip={<span>Reset search</span>}/>
                                    : <></>}

                                {!serverSearch && renderDownArrow()}

                            </div>

                        </ControlContainerStyled>
                    </LookupContainerStyled>

                    <FieldLabel properties={properties}/>

                    {submitted && !formValue &&
                        <small className="invalid">{properties?.fieldConfig?.label || ""} is required.</small>}

                    <Matches/>
                </FloatingFormStyled>

            </div>
        );
    };

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    );

};

