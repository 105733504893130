import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {SessionContext} from '../../../../providers/session/SessionContext';
import {SystemContext} from '../../../../providers/system/SystemContext';
import {log} from '../../../../services/LogService';
import {getStyle, replaceBespokeVariables} from '../../../../utils/CssUtils';
import {isDefined, notBlank} from '../../../../utils/TypeCheckers';
import {getErrorMessage} from '../../../../utils/Utils';
import GuslChart from '../../../charts/GuslChart';
import {fieldService} from '../../../FieldService';
import {ActionConfigDTO, ChildReference, FieldConfigDTO, FormMode, IMenuDTO, TableRowDTO} from '../../../types';
import {IconStyled} from '../../../ui/navigation/navbar/styled';
import {cardFormFieldChange, CardFormState} from '../../card/cardFormSlice';
import {initForm} from '../../maintain-form/guslFormSlice';
import {ActionHandler} from '../../maintain-table/ActionHandler';
import {getColFormat, getColWidth, getFormat, getRowFormat, getRowStyle, getTableWidth} from '../ReportUtils';
import {InputWrapperStyled} from '../styles';
import {ReportColumnDO, ReportRowDO, ReportTableDO} from '../types';

interface ReportTableProperties {
    code: string,
    table: ReportTableDO,
    index: number,
    rowData?: TableRowDTO
    reLoad?: () => void;

}

export const RenderTable = ({
                                table,
                                code,
                                index,
                                rowData,
                                reLoad
                            }: ReportTableProperties): React.ReactElement<ReportTableDO> => {

    const sessionContext = useContext(SessionContext);
    const systemContext = useContext(SystemContext);
    const environmentContext = React.useContext(EnvironmentContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const [childReferences] = useState<Map<string, ChildReference>>(new Map());
    // const [formValue, setFormValue] = useState<any>({...rowData});
    const _cardFormState: CardFormState = useAppSelector(state => state.cardFormSlice[code]);

    const isMobile = environmentContext.isMobileDevice();

    useEffect(() => {
        dispatch(initForm({
            code: code,
            formData: rowData,
            rowData: rowData,
            fields: []
        }))
    }, [code]);


    const renderChart = (chartData: any, innerCss?: string | undefined, outerCss?: string | undefined): React.ReactElement => {
        log.debug('Report', 'MSG003', 'chartData', chartData)
        const haveChartData = isDefined(chartData);
        const outerStyle = replaceBespokeVariables(isMobile, getStyle(outerCss, ''))

        // @ts-ignore
        return (
            <div style={outerStyle}>
                {haveChartData && <GuslChart chart={chartData}/>}
            </div>
        )
    }
    const renderIcon = (icon: string, action: ActionConfigDTO): React.ReactElement => {
        return (
            <span>
                <IconStyled icon={icon || action.icon || 'fa-solid fa-plus'} $isItem={false}/>
            </span>
        )
    }
    const renderImage = (column: ReportColumnDO, fieldName?: string, innerCss?: string | undefined, outerCss?: string | undefined): React.ReactElement => {
        const outerStyle = replaceBespokeVariables(isMobile, getStyle(outerCss, ''))

        const name = fieldName || 'image'

        const extendProperties: any = {}
        if (column.w) {
            extendProperties.imageWidth = column.w
        }

        if (column.h) {
            extendProperties.imageHeight = column.h
        }

        // properties: column.ep,

        if (column.a) {
            return renderIcon(column.i, column.a)
        }

        const fieldConfig: FieldConfigDTO = {
            name: name,
            label: name,
            type: 'image',
            tableCss: innerCss,
            properties: JSON.stringify(extendProperties)
        }
        const data = {}
        // @ts-ignore
        data[name] = column.i || ''

        return (
            <div style={outerStyle}>
                {fieldService.getTableField(fieldConfig, undefined, data)?.render()}
            </div>
        )
    }

    const renderField = (
        fieldType: string,
        column: ReportColumnDO,
        value: any
    ) => {
        const innerCss = column.icss;
        const outerCss = column.ocss;
        const isInput = column.inp;
        const name = column.inpid || column.n || fieldType

        const outerStyle = replaceBespokeVariables(isMobile, getStyle(outerCss, ''))
        const fieldConfig: FieldConfigDTO = {
            name: name,
            label: name,
            type: fieldType,
            properties: column.ep,
            blastDeltaCommand: table.bdc,
            keyField: table.k,
            keyValue: table.kv,
            tableCss: innerCss,
            dateFormat: column.df
        }

        const data: any = {}
        // @ts-ignore
        data[name] = value //  || 0
        // @ts-ignore
        data['id'] = rowData?.id

        if (rowData?.id) {
            // console.log('==> ', rowData?.id)
            dispatch(initForm({
                code: rowData?.id,
                formData: data,
                rowData: rowData,
                fields: []
            }))
        }

        const onSubmitted = (): void => {
            // do nothing
        }
        const onChange = (name: string, value: any): void => {

            try {
                const currentChildReference = childReferences.get(name)

                // if (currentChildReference?.fieldType === 'money') {
                //     dataToUpdate[name] = {...formData[name]};
                //     dataToUpdate[name]['value'] = value;
                // } else {
                // const newData = {...formValue}
                // // @ts-ignore
                // newData[name] = value;
                // setFormValue(newData)

                dispatch(cardFormFieldChange({
                    code: code,
                    name: name,
                    value: value
                }))

            } catch (error) {
                console.error('error', error)
            }
            // }
        }

        const menuItem: IMenuDTO = {
            label: name,
            code: name,
            displayOrder: 1
        }

        const getCurrentRowData = (): any => {
            return rowData
        }

        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference);
        }

        if (isInput) {
            return (
                <InputWrapperStyled key={'iws_' + code}>
                    {fieldService.getFormTemplate({
                        code: code,
                        formMode: FormMode.EDIT,
                        isFirstField: true,
                        inline: true,
                        fieldConfig,
                        menuItem,
                        // @ts-ignore
                        data: data[name],
                        onSubmitted,
                        onChange,
                        getCurrentRowData,
                        isDialog: false,
                        reference: {
                            name: fieldConfig.name,
                            displayOrder: 1,
                            register: registerChildReference,
                            fieldType: fieldConfig.type
                        },
                        // @ts-ignore
                        uniqueId: data['id']
                    })}
                </InputWrapperStyled>
            )
        }

        return (
            <div style={outerStyle} id={'outer'}>
                {fieldService.getTableField(fieldConfig, undefined, data)?.render()}
            </div>
        )

    }

    const renderTables = (tables: ReportTableDO[], innerCss?: string | undefined, outerCss?: string | undefined): React.ReactElement => {
        const style = getStyle(outerCss, '')
        return (
            <div style={style}>
                {tables.map((table, index) => <RenderTable
                        key={'sub_' + index} code={code}
                        table={table}
                        index={index}
                        rowData={rowData}
                        reLoad={reLoad}
                    />
                )}
            </div>
        )
    }

    const onActionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, actionItem: ActionConfigDTO | undefined) => {
        // console.log('onActionClick: ', code, actionItem, rowData)
        if (!actionItem) {
            return
        }
        if (e) {
            e.stopPropagation()
        }
        // console.log('onActionClick: ', code, actionItem, _cardFormState?.formValue)
        // console.log('onActionClick: ', rowData)
        const actionHandler: ActionHandler = new ActionHandler(navigate, sessionContext, systemContext, dispatch)
        setErrorMessage(undefined)
        actionHandler.handle(code, actionItem, _cardFormState?.formValue || rowData, rowData, reLoad)
            .then((complete: boolean) => {
            }).catch(errors => {
            setErrorMessage(getErrorMessage(errors))
        })
    }

    const renderDetail = (column: ReportColumnDO): React.ReactElement => {
        const isImage = notBlank(column.i)
        const isValue = isDefined(column.v)
        const isChart = isDefined(column.c)
        const isChartJS = isDefined(column.cjs)
        const isTable = notBlank(column.r?.tbl)
        const isMovement = notBlank(column.p)
        const isMoney = notBlank(column.m)
        const isDecimal = notBlank(column.d)
        const isNumberValue = notBlank(column.nv)
        const isDoubleValue = notBlank(column.dv)
        const isCircleValue = notBlank(column.cir)
        const isPercentage = notBlank(column.pcent)
        const isBreadcrumb = notBlank(column.bc)
        const isDate = notBlank(column.dt)
        const isTotal = notBlank(column.t)
        const isIframe = notBlank(column.if)
        const isExternalLink = notBlank(column.el)
        const innerCss = column.icss;
        const outerCss = column.ocss;
        const isOdds = column.odds;

        return (
            <div onClick={(e) => onActionClick(e, column.a)}>
                {isImage && renderImage(column, column.n, innerCss, outerCss)}
                {isValue && renderField('text', column, column.v)}
                {isChart && renderChart(column.c, innerCss, outerCss)}
                {isChartJS && renderChart(column.cjs, innerCss, outerCss)}
                {isTable && renderTables(column.r.tbl, innerCss, outerCss)}
                {isMovement && renderField('position', column, column.p)}
                {isMoney && renderField('money', column, column.m)}
                {isDecimal && renderField('decimal', column, column.d)}
                {isNumberValue && renderField('number', column, column.nv)}
                {isDoubleValue && renderField('number', column, column.dv)}
                {isCircleValue && renderField('circle', column, column.cir)}
                {isPercentage && renderField('percent', column, column.pcent)}
                {isDate && renderField('date_time', column, column.dt)}
                {isTotal && renderField('total', column, column.t)}
                {isBreadcrumb && renderField('breadcrumb', column, column.bc)}
                {isIframe && renderField('iframe', column, column.if)}
                {isExternalLink && renderField('external_link', column, column.el)}
                {isOdds && renderField('odds', column, column.odds)}
            </div>
        )
    }


    const renderWrapColumnDetailInLink = (column: ReportColumnDO): React.ReactElement => {
        return (
            <a href={column.l}>
                {renderDetail(column)}
            </a>
        )
    }

    const renderCol = (table: ReportTableDO, row: ReportRowDO, column: ReportColumnDO, index: number): React.ReactElement => {
        const hasLink = isDefined(column.l) && notBlank(column.l);
        return (
            <td className={getColFormat(table, row, column)} key={code + '_tbl_col_' + index}
                id={code + '_tbl_col_' + index}
                style={getColWidth(table, row, column, index)}>
                {hasLink && renderWrapColumnDetailInLink(column)}
                {!hasLink && renderDetail(column)}
            </td>
        )
    }

    const renderRow = (table: ReportTableDO, row: ReportRowDO, rowIndex: number): React.ReactElement => {
        return (
            <tr className={getRowFormat(table, row)} style={getRowStyle(row)}
                key={'row_' + code + '_' + rowIndex} id={'row_' + code + '_' + rowIndex}>
                {row?.c?.map((column, index) => renderCol(table, row, column, index))}
            </tr>
        )
    }

    const outerCss = replaceBespokeVariables(isMobile, getStyle(table.ocss, ''))
    outerCss.overflowX = 'auto'
    outerCss.overflowY = 'hidden'

    return (
        <div style={outerCss} key={code + '_' + index++} id={'tblw_' + code + '_' + index}>
            <div className="title">{table.t}</div>
            <table className={getFormat(table.f)} style={getTableWidth(table)}
                   key={code + '_tbl_' + index++} id={'tbl_' + code + '_' + index++}>
                <tbody style={{overflow: 'hidden'}} key={code + '_tbl_body_' + index++}
                       id={'tbl_body_' + code + '_' + index++}>
                {table.r?.map((row, rowIndex) => renderRow(table, row, rowIndex))}
                </tbody>
            </table>
            {errorMessage &&
                <small className="yellow">{errorMessage}</small>}
        </div>
    )

}
