import {BaseTheme} from '../GuslThemeProvider';

const blueTheme: BaseTheme = {
    "imageSuffix":"white",
    "headerPanel": {
        "fgColor": "#9aa9b2",
        "bgColor": "#202931",
        "border": "solid 1px #7d8187",
        "borderRadius": "5px",
    },
    colors: {
        "bespoke": [
            "#00B050",
            "#FF0000"
        ],
        primary: ['#045de2', '#518eeb'],
        secondary: ['#04e282', '#56e7a8'],
        background: ['#131a4e', '#202931'],
        dark: ['#303840', '#7d8187'],
        light: '#ffffff',
        error: '#c83331',
        warning: '#ead553',
        notification: '#338a4b',
        accentColor: '#0285b6',
        accentColorLight: '#c2e8fb',
        accentColorMuted: 'rgba(2,133,182,0.4)',
    },
    body: {
        background: '#303840',
    },
    splash: {
        background: '#00205a',
        color: '#0285b6',
        size: 80,
        left: '40px',
        top: '40px'
    },
    spinner: {
        color: '#0285b6'
    },
    modal: {
        outerBackground: 'rgba(2, 133, 182, 0.5)',
        innerBackground: '#00205a',
    },
    login: {
        fontColor: '#9aa9b2',
        linkColor: '#045de2',
        buttonBgColor: '#045de2',
        buttonFgColor: '#ffffff',
        outerBackground: 'rgba(2, 133, 182, 0.5)',
        innerBackground: '#00205a',
    },
    filterControls : {
        bgColor: 'rgb(48, 56, 64)',
        gridBorder: ''
    },
    table: {
        panelBgColor: '#0c296c',
        titleColor: '#ffffff',
        titleBgColor: '#2585b6',
        // titleBgColor: '#00205a',
        titlePadding: '10px 10px 5px 10px',
        titleFontSize: '20px',
        headerColor: '#ffffff',
        headerBgColor: '#1f1f1f',
        columnColor: '#9aa9b2',
        highlightBgColor: 'rgba(2, 133, 182, 0.5)',
        gridBorder: 'solid 1px #00205a',
        selectedColor: '#2585b6',
        headerFontSize: '14px',
        columnFontSize: '14px',
        menuButtonColor: '#ffffff',
        menuButtonBgColor: '#131a4e',
        menuButtonHoverColor: '#ffffff',
        menuButtonHoverBgColor: '#131a4e',
        rowHoverBgColor: '#040421',
        summaryHeader: {
            bgColor: '#00205a',
        },
        summaryFooter: {
            bgColor: '#00205a',
        }
    },

    panel: {
        panelBgColor: '#00205a',
        titleColor: '#ffffff',
        titleBgColor: '#2585b6',
        titleFontSize: '20px',
        subPanel: {
            titleColor: '#ffffff',
            titleBgColor: '#2585b6',
            titleFontSize: '20px',
        }
    },
    form: {
        fieldBorder: 'solid 1px #454545'
        // #4c4e4f
    },
    action: {
        bgColor: 'transparent',
        borderColor: '#454545',
        hoverBgColor: '#040421',
        hoverColor: '#ffffff',
        hoverBorderColor: '#FFBE66',
        colors: {
            defaultColor: '#9aa9b2',
            warning: '#FFBE66',
            save: '#008C57',
            refresh: '#008C57',
            new: '#9aa9b2',
            update: '#008C57',
            edit: '#008C57',
            cancel: '#9aa9b2',
            "newAction": "#0185b7",
            delete: '#B91B10'
        }
    },
    lookup: {
        headerColor: '#ffffff',
        columnColor: '#9aa9b2',
        headerFontSize: '14px',
        columnFontSize: '14px',
        hoverColor: '#9aa9b2',
        hoverBgColor: '#00205a',
    },
    money: {
        positiveColor: '#338a4b',
        negativeColor: '#c83331',
    },
    navigation: {
        topNavbarColor: 'whitesmoke',
        topNavbarBgColor: '#00205a',
        gradient: ['#00205a', '#040421', '#00205a'],
        buttonColor: 'whitesmoke',
        buttonBgColor: 'transparent',
        hoverButtonColor: '#9aa9b2',
        hoverButtonBgColor: '#00205a',
        hamburgerColor: '#9aa9b2',
        borderColor: '#040421',
        dropdownMenu: {
            bgColor: '#00205a',
            color: 'whitesmoke',
            hoverButtonColor: '#9aa9b2',
            hoverButtonBgColor: '#00205a',
            leftBarColor: '#80a6e4'
        },
        sideMenu: {
            bgColor: '#00205a',
            color: 'whitesmoke',
            hoverBgColor: '#131a4e',
            hoverColor: '#9aa9b2',
            rightBorderColor: '#9aa9b2',
            footer: {
                bgColor: '#131a4e',
                color: 'whitesmoke',
            },
            header: {
                avatar: {
                    bgColor: '#131a4e',
                    color: 'whitesmoke',
                },
                bgColor: '#131a4e',
                color: 'whitesmoke',
            }

        },
    },
    chart: [
        {
            borderColor: '#a05195',
            backgroundColor: ['#a05195', '#a05195']
        },
        {
            borderColor: '#d45087',
            backgroundColor: ['#d45087', '#d45087']
        },
        {
            borderColor: '#f95d6a',
            backgroundColor: ['#f95d6a', '#f95d6a']
        },
        {
            borderColor: '#ff7c43',
            backgroundColor: ['#ff7c43', '#ff7c43']
        },
        {
            borderColor: '#ffa600',
            backgroundColor: ['#ffa600', '#ffa600']
        },
        {
            borderColor: '#003f5c',
            backgroundColor: ['#003f5c', '#003f5c']
        },
        {
            borderColor: '#2f4b7c',
            backgroundColor: ['#2f4b7c', '#2f4b7c']
        },
        {
            borderColor: '#665191',
            backgroundColor: ['#665191', '#665191']
        },

    ],
    padding: {
        xs: '5px',
        s: '10px',
        m: '10px',
        l: '20px',
        xl: '40px',
    },
    margin: {
        s: '10px',
        m: '10px',
        l: '20px',
    },
    borderRadius: {
        s: '5px',
        m: '10px',
        l: '20px',
    },
    grid: {
        baseline: 5,
        gap: {
            s: 5,
            m: 10,
            l: 20,
        },
    },
    maxContentWidth: '1264px',
    maxPageWidth: '1500px',
    mediaQueryBreakPoints: {
        xs: 320,
        sm: 480,
        md: 768,
        lg: 1200,
    },
    typography: {
        fontFamilyRegular: '"SofiaProRegular", serif',
        fontFamilySemiBold: '"SofiaProSemiBold", serif',
        baseFontSize: '16px',
    },
    contentPadding: {
        desktop: '32px',
        mobile: '16px',
    },
    fontSizes: {
        headings: {
            xxl: '40px',
            xl: '32px',
            l: '28px',
            m: '24px',
            s: '20px',
            xs: '16px',
        },
        body: {
            l: '16px',
            m: '14px',
            s: '12px',
            xs: '10px',
            xxs: '8px',
        },
        tooltip:'15px',
    },
    fontWeights: {
        bold: 'bold',
        medium: 500,
        regular: 400,
    },
    marginMap: {
        sm: 4,
        md: 8,
        lg: 10,
        default: 0,
    },
}

export default blueTheme
