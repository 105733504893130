import React from 'react';
import {isDefined, notDefined} from '../../../utils/TypeCheckers';
import {FieldConfigDTO} from '../../types';
import {RenderTable} from './components/RenderTable';
import {ReportContainerStyled} from './styles';
import {ReportProperties, ReportTableDO} from './types';

export const Report = (properties: ReportProperties): React.ReactElement<ReportProperties> => {

    const renderTableView = (): React.ReactElement => {
        return (
            <></>
        )
    }

    const renderLabel = (fieldConfig: FieldConfigDTO): React.ReactElement => {
        const hasLabel = notDefined(fieldConfig.noLabel) && isDefined(fieldConfig.label)
        return (
            <>
                {hasLabel && <p className="title">{fieldConfig.label}</p>}
            </>
        )
    }
    const renderFormView = (): React.ReactElement => {
        return (
            <ReportContainerStyled key={'rcs_' + properties.code} id={'rcs_' + properties.code}>
                {renderLabel(properties.fieldConfig)}
                {properties?.reportData?.tbl.map((table: ReportTableDO, idx: number) => <RenderTable table={table}
                                                                                                     rowData={properties.rowData}
                                                                                                     key={'rt_' + properties.code + '_' + idx}
                                                                                                     code={properties.code}
                                                                                                     reLoad={properties.reLoad}
                                                                                                     index={idx}/>)}
            </ReportContainerStyled>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}

export default Report;
