import {DEFAULT_CHART_OPTIONS} from "../chart-options/defaultChartOptions";
import {ChartDO, ChartOptionsDO} from "../types";

export interface SetChartOptionsProps {
    serverData: ChartDO,
    chartType: string
    clientChartOptions?: { [index: string]: ChartOptionsDO | undefined },
    singleValue?: string,
    singleChart?: string

}

export default function setChartOptions({serverData, chartType, clientChartOptions, singleChart, singleValue}: SetChartOptionsProps) {

    const className = 'setChartOptions-' + new Date().getTime();

    /*DEFAULT HEIGHT IF NOT SET ON THE SERVER OR ON THE CLIENT*/
    function canvasHeight(chartType: any, singleValue: any) {
        if (singleValue) {
            return 50;
        } else if (['radar', 'bubble'].indexOf(chartType) !== -1 && !singleChart) {
            return 350;
        } else {
            return undefined;
        }
    }

    if (clientChartOptions && clientChartOptions[chartType]) {

       // log.info(className, 'MSG001', 'using clientChartOptions for ' + chartType, {clientChartOptions})
        serverData.chartOptions[chartType] = clientChartOptions[chartType];

    } else if (serverData.chartOptions && serverData.chartOptions[chartType]) {
        //log.info(className, 'MSG001', 'using serverData ChartOptions for ' + chartType, {serverChartOptions: serverData.chartOptions[chartType]})
        //serverData.chartOptions[chartType] = serverData.chartOptions[chartType]
    } else {
        /*DEFAULT OPTIONS IF NOT SET ON THE SERVER OR ON THE CLIENT*/
        //log.info(className, 'MSG001', 'using defaultChartOptions for ' + chartType, {defaultOptions: DEFAULT_CHART_OPTIONS[chartType]})
        serverData.chartOptions = {[chartType]: DEFAULT_CHART_OPTIONS[chartType]}

        serverData.chartOptions[chartType]['chart'] = {
            canvas: {height: canvasHeight(chartType, singleValue)}
        }
    }

    return serverData;
}
