import React from 'react';
import {useAppDispatch} from '../../../../../app/hooks';
import {constructUrl, safeStream} from '../../../../../utils/Utils';
import PagedResponse from '../../../../paged-response/PagedResponse';
import {
    ArticleDateStyled,
    ArticleDealNameStyled,
    ArticleSubjectStyled,
    ArticleSummaryStyled,
    ArticleTimeStyled,
    ArticleTitleStyled
} from '../../articles-view/styled';
import {CollectionHeaderStyled} from '../../content/styled';
import {OttoBoxStyled, ScrollBox} from '../../styled';
import {ArticlesByDateDTO, OSSArticleDTO} from '../../types';
import {selectedOtherDealArticle} from '../otherDealsSlice';

const SELECT_URL = '/otto-articles/paged-rumours'

interface RumoursArticlesProperties {
    code: string,
    contentsHeight: string
}

const RumoursArticles = ({code, contentsHeight}: RumoursArticlesProperties): React.ReactElement => {
    const dispatch = useAppDispatch();


    const renderDealType = (article: OSSArticleDTO): React.ReactElement => {
        if (article.dealType) {
            return <span> - {article.dealType}</span>
        } else {
            return <span></span>
        }
    }

    const onArticleClick = (selectedArticle: OSSArticleDTO) => {
        dispatch(selectedOtherDealArticle({article: selectedArticle}))
    }

    const renderCard = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleTitleStyled
                key={'t_' + idx + article.id}
                large={false}
                active={false}
                onClick={() => onArticleClick(article)}>
                <ArticleDealNameStyled>{article.subject}</ArticleDealNameStyled>
                <ArticleSummaryStyled>
                    <ArticleTimeStyled>{article.articleTime}</ArticleTimeStyled>
                    {article?.content && <ArticleSubjectStyled
                        dangerouslySetInnerHTML={{__html: article?.content || ''}}></ArticleSubjectStyled>}
                </ArticleSummaryStyled>
            </ArticleTitleStyled>
        )
    }

    const renderItem = (articlesByDate: ArticlesByDateDTO, idx: number): React.ReactElement => {
        return (
            <div key={idx} className={"p-2"}>
                <ArticleDateStyled large={false}>{articlesByDate.publishedDate}</ArticleDateStyled>
                {safeStream(articlesByDate.articles).map((article: OSSArticleDTO, idx) => renderCard(article, idx))}
            </div>
        )
    }

    return (
        <OttoBoxStyled>
            <CollectionHeaderStyled>Rumours</CollectionHeaderStyled>
            <ScrollBox id={'scrollable_' + code} height={contentsHeight}>
                <PagedResponse code={code}
                               infinityScroll={true}
                               selectUrl={constructUrl(SELECT_URL, {})}
                               renderItem={renderItem}
                />
            </ScrollBox>
        </OttoBoxStyled>
    )
}

export default RumoursArticles
