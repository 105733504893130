import React, {useState} from "react";
import {DefaultTextFilter} from "../default-text-filter/DefaultTextFilter";
import {FilterDO} from "../types";
import {GuslTableState} from "../../guslTableSlice";
import {useAppSelector} from "../../../../../app/hooks";
import {CheckboxFiltersIndividualStyled, CheckboxFiltersTopStyled} from "../../../maintain-table/styled";


export type CheckboxFiltersDO = {
    filter: FilterDO,
    code: string
}
export default function CheckboxFilters({filter, code}: CheckboxFiltersDO) {

    const [className] = useState('CheckboxFilters-' + new Date().getTime());

    // MK 26/08/2023 different look in top filters to align with the rest...
    const state: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);
    const individualAdvancedSearchFields: string[] = state.individualAdvancedSearchFields;
    const showAsIndividual: boolean | undefined = individualAdvancedSearchFields.includes(filter.name);
    const showTopTableFilters = state?.showTopFilters;
    const isTopFilter: boolean = showAsIndividual || showTopTableFilters;
    // MK 27/08/2023
    const CheckFilters = ({isIndividual}: { isIndividual: boolean }) => (<>
        <DefaultTextFilter filter={{...filter, value: 'true'}}
                           label={'YES'}
                           code={code}
                           isIndividual={isIndividual}/>
        <DefaultTextFilter filter={{...filter, value: 'false'}}
                           label={'NO'}
                           code={code}
                           isIndividual={isIndividual}/>
    </>)
    return showAsIndividual ?
        <CheckboxFiltersIndividualStyled>
            <CheckFilters isIndividual={true}/>
        </CheckboxFiltersIndividualStyled> :
        isTopFilter ?
            <CheckboxFiltersTopStyled>
                <CheckFilters isIndividual={true}/>
            </CheckboxFiltersTopStyled> :
            <CheckFilters isIndividual={false}/>;
}
