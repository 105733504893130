import styled from 'styled-components';

export const AppImageSealStyled = styled.div.attrs((props ) => ({}))`
    display:block;
  
  img {
  }
  div {
  }

  #254vwlHVh {
    div {
      background-color: green;
    }
  }
  
  
`
