import React, { useState } from "react";
import { NavTabsStyled, NavTabStyled, NavTabsWrapperStyled } from "../../styled_v2";
import EquitiesTable from "./equities/EquitiesTable";
import FXTable from "./fx/FXTable";
import { NewTickerDO } from "../types";
import IndicesTable from "./indices/IndicesTable";

export interface SearchTableContentDO {
  searchHandler: Function;
  tickers: NewTickerDO[];
  setTickers: Function;
  performPreOrderQuery: Function;
  setWLQueryRefresher: Function;
  currentlySelectedFavorites: NewTickerDO[];
}

export default function SearchTableContent({
                                             searchHandler,
                                             tickers,
                                             setTickers,
                                             performPreOrderQuery,
                                             setWLQueryRefresher,
                                             currentlySelectedFavorites
                                           }: SearchTableContentDO) {

  const [className] = useState("SearchTableContent-" + new Date().getTime());
  const tabs: string[] = ["Equities", "FX", "Indices"];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const contentMap: { [tab: string]: React.JSX.Element } = {
    "Equities": <EquitiesTable searchHandler={searchHandler}
                               tickers={tickers}
                               setTickers={setTickers}
                               performPreOrderQuery={performPreOrderQuery}
                               setWLQueryRefresher={setWLQueryRefresher}
                               currentlySelectedFavorites={currentlySelectedFavorites} />,
    "FX": <FXTable />,
    "Indices": <IndicesTable />
  };
  const currentContent: React.JSX.Element = contentMap[currentTab];

  return (
    <div>
      <NavTabsWrapperStyled>
        <NavTabsStyled className="nav nav-tabs card-header-tabs">
          {tabs.map((tab, idx) => <NavTabStyled key={tab + idx} active={tab === currentTab} onClick={() => {
            setCurrentTab(tab);
          }}>
            <span>{tab}</span>
          </NavTabStyled>)}
        </NavTabsStyled>
      </NavTabsWrapperStyled>

      {currentContent}

    </div>
  );
}

