import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';


export const OverlayOffCanvasStyled = styled(Offcanvas).attrs((props) => ({}))`
`

interface OverlayOffCanvasHeader {
    isMobile?: boolean;
}

export const OverlayOffCanvasHeaderStyled = styled(Offcanvas.Header).attrs((props: OverlayOffCanvasHeader) => ({}))<OverlayOffCanvasHeader>`
  color: ${props => props.theme.panel.subPanel.titleColor} !important;
  font-size: ${props => props.theme.panel.subPanel.titleFontSize};
  background-color: ${props => props.theme.panel.subPanel.titleBgColor};
  ${props => props.isMobile && 'border-top-left-radius: 15px;'}
  
  ${props => props.isMobile && 'border-top-right-radius: 15px;'};
`
export const OverlayOffCanvasTitleStyled = styled(Offcanvas.Title).attrs((props) => ({}))`
`

export const OverlayOffCanvasBodyStyled = styled(Offcanvas.Body).attrs((props) => ({
    id: 'off_canvas_body'
}))`
  padding: 0px;
  color: var(--dark-color);
`

export const OverlayContentStyled = styled.div.attrs((props) => ({
    id: 'overlay_content'
}))`
  overflow-y: auto;
  padding-left: 0px;
  padding: 0px;


  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }
;

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.table.panelBgColor};

  }
;

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark[0]};
    border: 1px solid ${props => props.theme.colors.dark[1]};
    border-radius: 10px;

  }
;

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.table.titleBgColor};
  }
;

`
