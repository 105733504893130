import Pagination from "react-bootstrap/Pagination";
import styled, {ThemeProps} from 'styled-components';

export interface ActionIconProperties extends ThemeProps<any> {
    isMobile:boolean,
    active?: boolean | undefined,
    color?: string,
    className?: string,
    onClick?: Function,
    filtersApplied?:boolean,
    isOrderedBy?:boolean



}

export interface IconProperties extends ThemeProps<any> {
    colorType: string,
    icon: string,
    extraClass?: string

}

export interface PaginationBtnProps {
    disabled?: boolean,
    onClick: Function
}

const noop = () => {}
export const PaginationStyled = styled(Pagination).attrs(props => ({}))`
  background-color: unset !important;
`

export const paginationBtnFontSize = 16;
export const paginationBtnPadding = "5px 4px 5px 4px";
export const PaginationFirstStyled = styled.i.attrs((props: PaginationBtnProps) => ({
    onClick: props.disabled ? noop : props.onClick,
    className:'fa-solid fa-angles-left'
}))<PaginationBtnProps>`
  cursor: pointer;
  padding:${paginationBtnPadding};
  background-color: unset !important;
  font-size: ${paginationBtnFontSize}px !important;
  color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.dark[1]};
  :hover {
    color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.accentColorLight};
  }
  }

`
export const PaginationPrevStyled = styled.i.attrs((props: PaginationBtnProps) => ({
    onClick: props.disabled ? noop : props.onClick,
    className:'fa-solid fa-angle-left'
}))<PaginationBtnProps>`
  cursor: pointer;
  padding:${paginationBtnPadding};
  background-color: unset !important;
  font-size: ${paginationBtnFontSize}px !important;
  color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.dark[1]};
  :hover {
    color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.accentColorLight};
  }
}
`
export const PaginationNextStyled = styled.i.attrs((props: PaginationBtnProps) => ({
    onClick: props.disabled ? noop : props.onClick,
    className:'fa-solid fa-angle-right'
}))<PaginationBtnProps>`
  cursor: pointer;
  padding:${paginationBtnPadding};
  background-color: unset !important;
  font-size: ${paginationBtnFontSize}px !important;
  color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.dark[1]};
  :hover {
    color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.accentColorLight};
  }
}
`
export const PaginationLastStyled = styled.i.attrs((props: PaginationBtnProps) => ({
    onClick: props.disabled ? noop : props.onClick,
    className:'fa-solid fa-angles-right'
}))<PaginationBtnProps>`
  cursor: pointer;
  padding:${paginationBtnPadding};
  background-color: unset !important;
  font-size: ${paginationBtnFontSize}px !important;
  color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.dark[1]};
  :hover {
    color: ${props => props.disabled ? props.theme.colors.background[1] : props.theme.colors.accentColorLight};
  }
}
`
export const
    ActionIconStyled = styled.span.attrs((props: ActionIconProperties) => ({
        onClick: props.onClick,
        className: props.className,
    }))<ActionIconProperties>`
      cursor: pointer;
      color: ${props => props.color ?
        (props.active ? props.theme.colors.background[1] : props.color) :
        (props.filtersApplied ? props.theme.money.positiveColor :  props.active ? props.theme.colors.light : props.theme.colors.dark[1])};
      margin-right: ${props => props.isMobile ? '15px' : '5px'};

      :hover {
        color: ${props => props.theme.colors.light};
      }
    `
export const
    OrderByIconStyled = styled.span.attrs((props: ActionIconProperties) => ({
        className:props.className
    }))<ActionIconProperties>`
      cursor: pointer;
      color: ${props => (props.isOrderedBy ? props.theme.colors.secondary[0] :  props.theme.colors.light)};
    `
export const
    CloseFilterBtnStyled = styled.i.attrs((props: ActionIconProperties) => ({
        className:props.className
    }))<ActionIconProperties>`
      cursor: pointer;
      color: ${props => props.theme.money.positiveColor};
      
    `
export const
    SplitIconStyled = styled.span.attrs((props: ActionIconProperties) => ({
        onClick: props.onClick
    }))<ActionIconProperties>`
      cursor: pointer;
      color: ${props => props.theme.colors.light};

    `
export const IconStyled = styled.i.attrs((props: IconProperties) => ({
    className: props.icon + (props.extraClass ? ' ' + props.extraClass : '')
}))<IconProperties>`
  color: ${props => props.colorType ? props.theme.colors[props.colorType] : 'inherit'};
`
export const WarningMsgStyled = styled.span.attrs((props) => ({
    className: 'float-end mt-3'
}))`
  color: ${props => props.theme.colors.warning};

`
