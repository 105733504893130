import React, {useContext, useEffect, useId, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {BlastContext} from '../../providers/blast/BlastContext';
import {FieldDeltaDO, SimpleMessageBO} from '../../providers/blast/commands';
import {EnvironmentContext} from '../../providers/environment/EnvironmentContext';
import {environmentService} from '../../providers/environment/EnvironmentService';
import {SessionContext} from '../../providers/session/SessionContext';
import {cleanWidthFromStyle, getStyle} from '../../utils/CssUtils';
import {isDefined} from '../../utils/TypeCheckers';
import {
    areFieldsMediaTypeSensitive,
    compare,
    groupListByKey,
    matchMediaTypeWithField,
    RunOnceEffect,
    unSubscribe
} from '../../utils/Utils';
import {
    AccordionListPanelStyled,
    ListContainerStyled,
    ListScrollContainerStyled,
    TitleWrapperStyled
} from '../accordion-list/styled';
import {OrderByActions} from '../common/gusl-table/table-controls/OrderByActions';
import SearchBox from '../common/gusl-table/table-controls/search-box/SearchBox';
import {SearchIconStyled} from '../common/gusl-table/table-controls/search-box/styled';
import LoadingSpinner from '../common/loading-spinner/LoadingSpinner';
import {maintainTableService} from '../common/maintain-table/MaintainTableService';
import {fieldService} from '../FieldService';
import {
    BreadcrumbsDO,
    FieldConfigDTO,
    FilterActionControlDO,
    FilterActionDO,
    IMenuDTO,
    MatchQueryDTO,
    MediaType,
    OrderByActionDO,
    OrderByDTO,
    QueryParamsDTO,
    WidgetPanelProperties
} from '../types';
import {
    BreadcrumbsWrapperStyled,
    FilterActionItemStyled,
    FilterActionWrapperStyled,
    FlexListColumnStyled,
    FlexListPanelStyled,
    FlexListRowStyled,
    FlexListSectionItemsWrapperStyled,
    FlexListSectionStyled,
    FlexListSectionWrapperStyled,
    GroupByNameStyled,
    MainContainerStyled,
    NoDataStyled,
    OpenIconStyled,
    OrderByActionsStyled,
    SearchBoxWrapperStyled,
    TableControlsStyled
} from './styled';

type FlexListProperties = {
    menuItem: IMenuDTO,
    queryParams?: QueryParamsDTO | undefined;
    widgetPanelProperties?: WidgetPanelProperties
}

export const FlexList = (props: FlexListProperties): React.ReactElement => {

    const environmentContext = React.useContext(EnvironmentContext);
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext)

    const navigate = useNavigate();

    const id = useId()

    const listElement = useRef(null);

    // const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(props.widgetPanelProperties));
    const mediaType:MediaType = environmentContext.getCurrentMediaType(props.widgetPanelProperties)
    console.log('mediaType',mediaType, environmentContext.getCurrentMediaType(props.widgetPanelProperties), props.widgetPanelProperties)
    const [fields] = useState<FieldConfigDTO[]>(maintainTableService.extractFields(props.menuItem, true));
    const mediaTypeSensitive = areFieldsMediaTypeSensitive(fields)
    const [dataForTable, setDataForTable] = useState<any[]>([]);
    const [groupedData, setGroupedData] = useState<{
        [id: string]: any[]
    }>({});
    const [groupedDataOpened, setGroupedDataOpened] = useState<{
        [id: string]: boolean
    }>({});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectUrl] = useState<string | undefined>(maintainTableService.extractSelectAllEndpoint(props.menuItem));
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const isMobile = environmentContext.isMobileDevice(props.widgetPanelProperties);
    console.log('isMobile', props.menuItem?.code, isMobile, props.widgetPanelProperties)
    const [hasComplexHeader, setHasComplexHeader] = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsDO | undefined>(undefined);
    const [filterActionControl, setFilterActionControl] = useState<FilterActionControlDO | undefined>(undefined);
    const [groupBy, setGroupBy] = useState<string | undefined>(undefined);
    const [groupOrderBy, setGroupOrderBy] = useState<string | undefined>(undefined);
    const [listStartPos, setListStartPos] = useState<number>(150);

    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchableFields, setSearchableFields] = useState<FieldConfigDTO[]>(maintainTableService.extractAllSearchableFields(props.menuItem))
    const [lastQueryParams, setLastQueryParams] = useState<QueryParamsDTO>({skip: 0, limit: 20});
    const [orderByActions, setOrderByActions] = useState<OrderByActionDO[]>(maintainTableService.extractOrderByActionsFromMenuItem(props.menuItem));
    const [hasSearch] = useState<boolean>(maintainTableService.extractAllSearchableFields(props.menuItem).length > 0);
    const [searchPrompt] = useState<string>('Many');

    const [blastDeltaCommand] = useState<string | undefined>(maintainTableService.extractBlastDeltaCommand(props.menuItem));
    const [rendered, setRendered] = useState<boolean>(false);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    useEffect(() => {
        setLastQueryParams(props.queryParams || {skip: 0, limit: 20})
    }, [props.queryParams]);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            if (loaded) {
                setRendered(true)
            }
            setTimeout(() => {
                // @ts-ignore
                if (listElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = listElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setListStartPos(rect.top)
                    } else {
                        setListStartPos(150)
                    }
                }
            }, 100)
        });
    }, [loaded]);

    useEffect(() => {
        let loaded = true;
        let blastDeltaSubscription: Subscription;
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            if (systemReady && blastDeltaCommand && loaded) {
                blastDeltaSubscription = blastContext.observeInboundCommands().subscribe((message: SimpleMessageBO<FieldDeltaDO>) => {
                    if (blastDeltaCommand && systemReady && message && message.cmd === 'table.delta' && message.data && message.data.tableKey === blastDeltaCommand) {
                        const newData: any[] = []
                        let haveChange = false;

                        if (message.data?.action === 'INSERT') {
                            newData.push(message.data?.value)
                            haveChange = true;
                        }
                        dataForTable?.forEach(row => {
                            if (row[message.data?.keyField || 'ignore'] === message?.data?.keyValue) {
                                if (message.data?.action === 'REMOVE') {
                                    haveChange = true;
                                } else if (message.data?.action === 'UPDATE') {
                                    haveChange = true;
                                    newData.push(message.data?.value)
                                }
                            } else {
                                newData.push(row)
                            }
                        })
                        if (message.data?.action === 'UPDATE' && !haveChange) {
                            // update on non existing row
                            newData.push(message.data?.value)
                            haveChange = true;

                        }

                        if (haveChange) {
                            setDataForTable(newData)
                            if (groupBy) {
                                performGrouping(newData, groupBy, groupOrderBy)
                            }
                            setRefreshCounter(refreshCounter + 1)
                        }
                    }
                });
            }
        });
        return () => {
            loaded = false;
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
        }
    }, [props, blastDeltaCommand]);

    const performGrouping = (tableData: any[], groupBy: string, orderBy: string | undefined) => {
        const data: {
            [id: string]: any[]
        } = groupListByKey(tableData, groupBy, orderBy)
        const groupOpened: {
            [id: string]: boolean
        } = {}
        Object.keys(data).sort((a, b) => {
            try {
                if (orderBy) {
                    const recA = data[a];
                    const recB = data[b];
                    return (recA[0][orderBy] || 0) - (recB[0][orderBy] || 0)

                } else {
                    return a?.localeCompare(b)
                }
            } catch (error) {
                console.error('Error sorting', error);
                return 0
            }
        })
            .forEach((key: string, idx: number) => {
                if (idx < 2) {
                    groupOpened[key] = true
                } else {
                    groupOpened[key] = false
                }
            })
        setGroupedData(data)
        setGroupedDataOpened(groupOpened)
    }
    const getData = (abortController: AbortController, query?: QueryParamsDTO) => {
        if (selectUrl) {
            setLoaded(false)

            let queryParams = lastQueryParams
            if (props.queryParams) {
                queryParams = props.queryParams
            }
            sessionContext.post<any, any>(selectUrl, query || queryParams, abortController)
                .then(response => {
                    setLoaded(true)
                    setDataForTable(response.data.content);
                    setLastQueryParams(response.data.queryParams)

                    if (response?.data?.breadCrumb) {
                        setBreadcrumbs(response.data.breadCrumb)
                    }
                    if (response?.data?.filterAction) {
                        const filterActionControl = response?.data?.filterAction;
                        if (filterActionControl && filterActionControl.filterActions) {
                            filterActionControl.filterActions.sort((a: FilterActionDO, b: FilterActionDO) => (a?.displayOrder || 0) - (b?.displayOrder || 0))
                        }
                        setFilterActionControl(filterActionControl)
                    }
                    if (response?.data?.orderByActions) {
                        setOrderByActions(response?.data?.orderByActions)
                    }
                    if (response?.data?.groupBy) {
                        setGroupBy(response?.data?.groupBy)
                        setGroupOrderBy(response?.data?.orderBy)
                        performGrouping(response.data.content, response?.data?.groupBy, response?.data?.orderBy)

                    } else {
                        setGroupedDataOpened({})
                        setGroupedData({})
                        setGroupBy(undefined)
                    }
                    setRefreshCounter(refreshCounter + 1)
                }).catch(error => {
                console.error("Error", error);
                setLoaded(true)
            })
        }
    }
    useEffect(() => {
        getData(new AbortController())
    }, [props]);

    const reLoad = (query?: QueryParamsDTO) => {
        setTimeout(() => {
            getData(new AbortController(), query)
        }, 500)
    }

    const renderFields = (row: any, rowId: number): React.ReactElement => {
        return (
            <>
                {fields.filter(fld => fld.displayInTable)
                    .filter(fld => matchMediaTypeWithField(fld, mediaType, mediaTypeSensitive))
                    .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                    .map((fld, idx) => {
                        return <FlexListColumnStyled key={'col_' + rowId + '_' + idx}
                                                     style={fld.style ? cleanWidthFromStyle(fld.style) : cleanWidthFromStyle(getStyle(fld.tableCss))}
                                                     id={'col_' + rowId + '_' + idx}>
                            {fieldService.getTableField(fld, undefined, row, reLoad, ('id_' + rowId))?.render()}
                        </FlexListColumnStyled>
                    })
                }
            </>
        )
    }

    const renderListByGroup = (): React.ReactElement => {

        const onSectionHeaderClick = (key: string) => {
            const data = groupedDataOpened
            data[key] = !groupedDataOpened[key]
            setGroupedDataOpened({...data})
        }

        return (
            <>
                <FlexListSectionStyled isMobile={isMobile} id={'fl_flss'} listStartPos={listStartPos}>
                    {Object.keys(groupedData).sort((a, b) => {
                        try {
                            if (groupOrderBy) {
                                const recA = groupedData[a];
                                const recB = groupedData[b];
                                return (recA[0][groupOrderBy] || 0) - (recB[0][groupOrderBy] || 0)
                            } else {
                                return a?.localeCompare(b)
                            }
                        } catch (error) {
                            console.error('Error sorting', error);
                            return 0
                        }
                    })
                        .map((key: string, idx: number) =>
                            <FlexListSectionWrapperStyled key={'section_' + idx} id={'section_' + idx}>
                                <GroupByNameStyled isOpen={groupedDataOpened[key]} id={'section_hdr_' + idx}
                                                   onClick={() => onSectionHeaderClick(key)}>{key}<OpenIconStyled
                                    isOpen={groupedDataOpened[key]}></OpenIconStyled></GroupByNameStyled>
                                {groupedDataOpened[key] &&
                                    <FlexListSectionItemsWrapperStyled isMobile={isMobile} id={'section_items_' + idx}>
                                        {groupedData[key]?.map((row: any, rowIndex: number) => {
                                            return <FlexListRowStyled id={'fl_row_' + idx + '_' + rowIndex}
                                                                      isMobile={isMobile}
                                                                      key={'row_' + idx + '_' + rowIndex}>{renderFields(row, rowIndex)}</FlexListRowStyled>
                                        })}
                                    </FlexListSectionItemsWrapperStyled>}
                            </FlexListSectionWrapperStyled>
                        )}
                </FlexListSectionStyled>
            </>

        )
    }

    const renderList = (): React.ReactElement => {
        if (groupBy) {
            return renderListByGroup()
        }

        if (!dataForTable || dataForTable.length === 0) {
            return <NoDataStyled>No results</NoDataStyled>
        }
        return (
            <>
                <FlexListPanelStyled isMobile={isMobile} id={'fl_panel'} key={'fl_panel_' + refreshCounter}
                                     listStartPos={listStartPos}>
                    {dataForTable?.map((row: any, rowIndex: number) => {
                        return <FlexListRowStyled id={'fl_row_' + rowIndex} isMobile={isMobile}
                                                  key={'row_' + rowIndex}>{renderFields(row, rowIndex)}</FlexListRowStyled>
                    })}
                </FlexListPanelStyled>
            </>
        )
    }

    // if (!loaded) {
    //     return (
    //         <TableSpinner/>
    //     )
    // }

    const renderBreadcrumbs = (): React.ReactElement => {
        const fieldConfig: FieldConfigDTO = {
            name: 'breadcrumb',
            label: '',
            type: 'breadcrumb'
        }

        const rowData: any = {}
        rowData.breadcrumb = breadcrumbs;
        return (
            <BreadcrumbsWrapperStyled>
                {fieldService.getTableField(fieldConfig, undefined, rowData, reLoad)?.render()}
            </BreadcrumbsWrapperStyled>
        )
    }

    const renderFilterActionItem = (filterAction: FilterActionDO, idx: number): React.ReactElement => {

        const onFilterActionItemClick = (filterAction: FilterActionDO) => {
            if (filterAction.route) {
                sessionContext.setQueryParams(JSON.stringify(filterAction.queryParams))
                navigate('/' + filterAction.route)
            }
        };

        return (
            <FilterActionItemStyled key={'flai_' + idx} id={'flai_' + idx} active={false} isFlex={true}
                                    onClick={() => onFilterActionItemClick(filterAction)}>{filterAction.label}</FilterActionItemStyled>
        )
    }

    const renderFilterAction = (): React.ReactElement => {
        return (
            <FilterActionWrapperStyled isFlex={true}>
                {filterActionControl?.filterActions.map((action, idx) => renderFilterActionItem(action, idx))}
            </FilterActionWrapperStyled>
        )
    }

    const renderTableControls = (): React.ReactElement => {
        const onQueryParamsChange = (orderBys: OrderByDTO[]) => {
            const query: QueryParamsDTO = {...lastQueryParams, orderBys: orderBys, skip: 0}
            setLastQueryParams(query)
            reLoad(query)
        }

        const onSearchChange = (searchValue: string | undefined) => {
            const shoulds: MatchQueryDTO[] = [];
            if (searchValue) {
                searchableFields.forEach((field) => shoulds.push({
                    field: field.name,
                    value: searchValue || '',
                    fuzzy: true
                }))
            }
            const query: QueryParamsDTO = {...lastQueryParams, should: shoulds, skip: 0, fuzzy: true}
            setLastQueryParams(query)
            reLoad(query)
        }
        if (orderByActions?.length === 0 && !hasSearch) {
            return <></>
        }
        return (
            <TableControlsStyled>
                <OrderByActionsStyled>
                    <OrderByActions
                        orderByActions={orderByActions}
                        isMobile={isMobile}
                        mediaType={mediaType}
                        lastQueryParams={lastQueryParams}
                        onQueryParamsChange={(orderBys: OrderByDTO[]) => onQueryParamsChange(orderBys)}
                    />
                    {hasSearch && <SearchIconStyled active={showSearch} onClick={() => {
                        setShowSearch(!showSearch)
                    }}/>}
                </OrderByActionsStyled>
                {showSearch && <SearchBoxWrapperStyled>
                    <SearchBox code={id}
                               searchableFields={searchableFields}
                               searchString={''}
                               searchPrompt={searchPrompt}
                               showFieldSelect={!isMobile}
                               onSearchValueChange={(searchValue: string | undefined) => onSearchChange(searchValue)}
                    />
                </SearchBoxWrapperStyled>}
            </TableControlsStyled>
        )
    }
    const renderHeader = (): React.ReactElement => {
        if (isDefined(breadcrumbs)) {
            return renderBreadcrumbs()
        }
        if (isDefined(filterActionControl)) {
            return renderFilterAction()
        }
        if (props?.menuItem?.noLabel || false) {
            return (
                <></>
            )
        }

        return (
            <TitleWrapperStyled>{props?.menuItem?.label || ''}</TitleWrapperStyled>
        )
    }

    if (!loaded) {
        return <LoadingSpinner loaded={false}/>

    }
    return (
        <>
            <LoadingSpinner loaded={rendered}/>
            <MainContainerStyled rendered={rendered}>
                {renderHeader()}
                {renderTableControls()}
                <AccordionListPanelStyled isMobile={isMobile} id={'fl_lp'} ref={listElement} listStartPos={listStartPos}
                                          hasOrderByActions={orderByActions?.length > 0}
                                          footerHeight={footerHeight}
                                          searchOpen={showSearch}>
                    <ListContainerStyled isMobile={isMobile} id={'fl_l'} listStartPos={listStartPos}
                                         footerHeight={footerHeight}>
                        <ListScrollContainerStyled isMobile={isMobile} id={'fl_scroll'} listStartPos={listStartPos}
                                                   footerHeight={footerHeight}>
                            {renderList()}
                        </ListScrollContainerStyled>
                    </ListContainerStyled>
                </AccordionListPanelStyled>
            </MainContainerStyled>
        </>
    )

}
