import styled from 'styled-components';

export const ImageStyled = styled.img.attrs((props) => ({
}))`
height:200px;
`
export const RestrictedHeaderStyled = styled.div.attrs((props) => ({
}))`
  font-size:22px;
`

export const RestrictedContentStyled = styled.div.attrs((props) => ({
}))`
 font-size:18px;
  padding:10px;
  text-align: center;
`
