import React from 'react';
import {FieldProperties} from '../types';
import {SvgStyled} from './styled';

export const ClosedBookIcon = (): React.ReactElement<FieldProperties> => {

    return (
        <SvgStyled width={'20px'}>
            <svg className="svg-icon"
                 viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M288 128C235.264 128 192 171.264 192 224V832h13.44c14.08 35.52 42.304 64 82.56 64H832v-64H288a31.616 31.616 0 0 1-32-32c0-17.984 14.016-32 32-32H832V128z m0 64H768v512H288c-11.264 0-22.016 2.56-32 6.016V224c0-17.984 14.016-32 32-32zM320 320v64h384V320z"
                    fill="#fff"/>
            </svg>
        </SvgStyled>
    )
}
