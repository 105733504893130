import styled from 'styled-components';

export const FavouriteWrapperStyled = styled.div.attrs((props) => ({
}))
    `
        //text-align:center;
`

export const FavouriteSvgStyled = styled.svg.attrs((props) => ({
}))
    `
        width:22px;
`
