import {createSlice, PayloadAction} from '@reduxjs/toolkit'


// Define a type for the slice state
interface DevState {
    isLoggedIn: boolean,
    userToken: string,
    sessionToken: string,
}

// Define the initial state using that type
const initialState: DevState = {

    isLoggedIn: false,
    userToken: '',
    sessionToken: '',

}

export const devSlice = createSlice({
    name: 'devSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        // setIsDataNormalized(state,  action: PayloadAction<boolean>) {
        //     state.isDataNormalized = action.payload;
        // },
        setIsLoggedIn(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },

        setUserToken(state, action: PayloadAction<string>) {
            state.userToken = action.payload;
        },
        setSessionToken(state, action: PayloadAction<string>) {
            state.sessionToken = action.payload;
        },

    }
})

export const {
    setIsLoggedIn,
    setSessionToken,
    setUserToken,
} = devSlice.actions


export default devSlice.reducer