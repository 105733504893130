// @ts -nocheck
import React, {useEffect, useRef, useState} from "react";

import {
    DragDropContext,
    Draggable,
    DraggingStyle,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    NotDraggingStyle
} from 'react-beautiful-dnd';
import {Subscription} from 'rxjs';
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {EnvironmentContext} from '../../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../../providers/environment/EnvironmentService';
import {GuslThemeContext} from '../../../../providers/theme/GuslThemeProvider';
import {RunOnceEffect, unSubscribe} from '../../../../utils/Utils';
import {FieldConfigDTO} from '../../../types';
import {ElementWithTooltip} from "../../element-with/element-with-tooltip/ElementWithTooltip";
import {GuslTableState, reOrderColumn, toggleColumnsSettings, toggleFieldDisplay} from "../guslTableSlice";
import {SidePanelTitle} from "../side-panel/SidePanelTitle";

import {
    ColumnNameStyled,
    DraggableColumnStyled,
    DraggableIconStyled,
    HideShowColumnStyled,
    TickStyled,
} from "../styled/columns-settings/styled";
import {FilterTypeWrapperStyled, TableSidePanelWrapperStyled} from "../styled/side-panel/styled";
import { columnSettingsClosed } from "../../../../features/ui/uiSlice";

// const {DragDropContext, Draggable, Droppable } = window.ReactBeautifulDnd;

type ColumnsSettingsProps = {
    code: string,

}
export default function
    ColumnsSettings({code}: ColumnsSettingsProps) {

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('ColumnsSettings-' + new Date().getTime());
    const title = 'Column Settings';

    const dispatch = useAppDispatch();
    const state: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);
    const guslThemeContext = React.useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const isMobile = environmentContext.isMobileDevice();

    const [lastIdx, setLastIdx] = useState<number>(1000);
    const [grid] = useState<number>(2);
    const displayElement = useRef(null);
    const [bodyHeight, setBodyHeight] = useState(600);

    const [panelBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).panel.panelBgColor);
    const [movingBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.hoverBgColor);
    // const [movingBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).colors.primary[1]);
    const [movingFgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.hoverColor);
    const [itemBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.bgColor);
    const [itemFgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.color);
    const [itemFontSize] = useState<string>('14px');
    const [border] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).form.fieldBorder);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });


    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (displayElement) {
                    // @ts-ignore
                    const rect = displayElement?.current?.getBoundingClientRect();
                    if (rect) {
                        let h = window.innerHeight - rect.top;
                        if (isMobile) {
                            h = h - footerHeight - 3
                        } else {
                            h = h - 20
                        }
                        setBodyHeight(h > 0 ? h : 500);
                    } else {
                        setBodyHeight(500);
                    }
                }
            }, 100)
        });
    }, [code]);
    const updateHiddenColumnsOnServer = (field: FieldConfigDTO) => {
        state.sessionContext?.post('/preferences/column-toggle', {code: code, field: field.name})
            .then(() => {
            })
            .catch(() => {
                // ignore
            })
    }

    const updateDisplayOrderListOnServer = (firstIdx: number, lastIdx: number) => {
        state.sessionContext?.post('/preferences/column-order', {code: code, firstIdx: firstIdx, lastIdx: lastIdx})
            .then(() => {
            })
            .catch(() => {
                // ignore
            })
    }

    function columnsHandler(field: FieldConfigDTO) {
        dispatch(toggleFieldDisplay({code, field}))
        updateHiddenColumnsOnServer(field);
    }

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? panelBgColor : panelBgColor,
        padding: grid,
        width: '100%'
    });

    const getItemStyle = (draggableStyle: DraggingStyle | NotDraggingStyle | undefined, isDragging: boolean) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? movingBgColor : itemBgColor,
        color: isDragging ? movingFgColor : itemFgColor,
        fontSize: itemFontSize,
        borderBottom: border,
        lineHeight: environmentContext.isMobileDevice() ? '30px' : '18px',
        ...draggableStyle
    });


    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        dispatch(reOrderColumn({code, firstIdx: result.source.index, lastIdx: result.destination.index}))
        updateDisplayOrderListOnServer(result.source.index, result.destination.index)
        setLastIdx(1000);
    }

    const renderDraggableItem = (field: FieldConfigDTO, index: number): React.ReactElement => {
        return (
            <HideShowColumnStyled active={lastIdx === index}>
                <ElementWithTooltip
                    element={
                        <TickStyled
                            className={'px-2'}
                            active={field.displayInTable}
                            onClick={() => {
                                columnsHandler(field)
                            }}
                        >
                            {field.displayInTable ? <>&#x2713;</> : <>&#x2717;</>}

                        </TickStyled>}
                    tooltip={
                        <span>{field.displayInTable ? 'Hide' : 'Show'} {field.label}</span>
                    }
                />

                <DraggableColumnStyled key={index}
                                       role={'button'}
                                       active={lastIdx === index}
                                       className={'d-flex justify-content-between'}
                >
                    <div>
                        <ColumnNameStyled active={field.displayInTable}>
                            {field.label}
                        </ColumnNameStyled>
                    </div>

                    <ElementWithTooltip
                        element={
                            <DraggableIconStyled
                                icon={'fa-solid fa-up-down-left-right '}
                                className={'pt-1 pe-1'}
                                active={field.displayInTable || false}
                            />
                            // </div>
                        }
                        tooltip={<span>Drag up or down to re-order columns.</span>}/>


                </DraggableColumnStyled>
            </HideShowColumnStyled>
        )
    }

    const renderDraggableItems = (): React.ReactElement => {
        return (
            <>
                {state.allFields
                    .map((field, index) => (
                        <Draggable
                            key={field.name}
                            draggableId={field.name}
                            index={index}
                        >
                            {(provided: any, snapshot: any) => (
                                <div>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            provided.draggableProps.style,
                                            snapshot.isDragging
                                        )}
                                    >
                                        {renderDraggableItem(field, index)}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    ))}
            </>
        )
    }


    const renderDraggableList = (): React.ReactElement => {
        return (
            <TableSidePanelWrapperStyled>
                <SidePanelTitle title={title} onCloseClick={() => {
                    dispatch(toggleColumnsSettings({code: code}));
                    if(state.showColumnsSettings){
                        dispatch(columnSettingsClosed());
                    }
                }}/>
                <FilterTypeWrapperStyled ref={displayElement} height={bodyHeight}>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    {renderDraggableItems()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </FilterTypeWrapperStyled>
            </TableSidePanelWrapperStyled>
        )
    }


    return (
        // <>{renderOriginal()}</>
        <>{renderDraggableList()}</>
    );
}
