import styled, {ThemeProps} from 'styled-components';


interface LegalsWrapperProperties extends ThemeProps<any> {
    horizontalLayout: boolean
}

export const LegalsWrapperStyled = styled.div.attrs((props: LegalsWrapperProperties) => ({}))<LegalsWrapperProperties>`
  display: ${props => props.horizontalLayout ? 'inline-block' : 'block'};
`


export const LegalTitleStyled = styled.h1`
  text-align: center;
  color: ${props => props.theme.colors.light};
  font-weight: bold;
  margin-bottom : 5px;
`

interface LegalProperties extends ThemeProps<any> {
    horizontalLayout: boolean;
    onClick: Function,
}

export const LegalStyled = styled.div.attrs((props: LegalProperties) => ({
    onClick: props.onClick
}))<LegalProperties>`
  color: ${props => props.theme.colors.light};
  font-size: 12px;
  display: ${props => props.horizontalLayout ? 'inline' : 'block'};
  margin-right: 20px;
  cursor: pointer;  
  line-height: 20px;
  padding: 6px 12px 6px 12px;
  font-weight: bold;
`
