import React, {useState} from "react";
import {Draggable, DraggingStyle, NotDraggingStyle} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {GuslThemeContext} from '../../../providers/theme/GuslThemeProvider';
import {canShowOnForm} from '../../../services/ValidationService';
import {cleanWidthFromStyle, getStyle, getWidthFromStyle} from '../../../utils/CssUtils';
import {notDefined} from '../../../utils/TypeCheckers';
import {constructUrl} from '../../../utils/Utils';
import {fieldService} from '../../FieldService';
import {
    ActionConfigDTO,
    ActionType,
    ChildReference,
    ConditionalRowDetailsDTO,
    FieldConfigDTO,
    FormMode,
    MediaType,
    TableRowDTO,
    WidgetPanelProperties
} from '../../types';
import {setOpenExpandedRowId} from '../action-dialog/actionSlice';
import {TableColumnResizeState} from '../gusl-table/guslColumnResizeSlice';
import {defaultCellWidth} from '../gusl-table/guslTableSlice';
import {expandableIconCellWidth} from '../gusl-table/ListView';
import {DraggableIconStyled} from '../gusl-table/styled/columns-settings/styled';
import {GuslFormState, updateNestedRow} from '../maintain-form/guslFormSlice';
import {RowActionOpenState} from '../maintain-table/table-row/rowActionOpenSlice';
import {RowExpanderActionState, toggleRowExpander} from '../maintain-table/table-row/rowExpanderSlice';
import {ChevronIconStyled} from '../maintain-table/table-row/styled';
import {ShowActionType, toggleActionItem} from '../maintain-table/table-row/tableRowSlice';
import {toggleOptionFieldOpen} from '../option/optionFieldSlice';
import {NestedTableColumnStyled, NestedTableRowStyled, RemoveIconStyled} from './styled';

export type TableRowProperties = {
    code: string;
    formMode: FormMode,
    rowId: string;
    // row: TableRowDTO,
    fields: FieldConfigDTO[],
    menuItem: any,
    performTableRefresh: () => void,
    conditionalRowDetails?: ConditionalRowDetailsDTO | undefined
    firstTab?: string | undefined,
    editAs?: 'popover' | 'modal',
    isDialog?: boolean;
    fieldName?: string | undefined,

    onChange: (name: string, value: any, rowIndex: number) => void,

    onDelete: (rowIndex: number) => void,

    rowIndex: number;

    mediaType: MediaType;

    // ref: any

    canReorder: boolean;

    canDelete: boolean;

    widgetPanelProperties?: WidgetPanelProperties

}

export const NestedTableRow = ({
                                   code,
                                   fieldName,
                                   rowId,
                                   // row,
                                   fields,
                                   menuItem,
                                   performTableRefresh,
                                   conditionalRowDetails,
                                   firstTab,
                                   formMode,
                                   onChange,
                                   onDelete,
                                   rowIndex,
                                   mediaType,
                                   canReorder,
                                   canDelete, widgetPanelProperties
                               }: TableRowProperties): React.ReactElement => {
    const navigate = useNavigate();

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('TableRow-' + new Date().getTime())
    const guslThemeContext = React.useContext(GuslThemeContext)
    const environmentContext = React.useContext(EnvironmentContext);

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[code]);
    const [formValue, setFormValue] = useState<TableRowDTO>(_guslFormState?.formData[fieldName || ''] || {});

    const dispatch = useAppDispatch();
    const _columnResizeState: TableColumnResizeState = useAppSelector(state => state.guslColumnResizeSlice[code]);
    const sessionContext = React.useContext(SessionContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [childReferences] = useState<Map<string, ChildReference>>(new Map());

    const openExpandedRowId = useAppSelector(state => state.actionSlice.openExpandedRowId);
    const _rowActionOpenState: RowActionOpenState = useAppSelector(state => state.rowActionOpenSlice);

    const [panelBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).panel.panelBgColor);
    const [movingBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.hoverBgColor);
    // const [movingBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).colors.primary[1]);
    const [movingFgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.hoverColor);
    // const [itemBgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.bgColor);
    const [itemBgColor] = useState<string>('transparent');
    const [itemFgColor] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).navigation.sideMenu.color);
    const [itemFontSize] = useState<string>('14px');
    const [border] = useState<string>(guslThemeContext.getCurrentTheme(environmentService.getEnvironment()?.storagePrefix).form.fieldBorder);
    const [grid] = useState<number>(2);

    const currentlyResizingHeaderName: string | undefined = _columnResizeState?.resizeColumnFieldName;

    const isActionIconActive = (row: TableRowDTO, actionItem: ActionConfigDTO): boolean => {
        // if (state.currentAction) {
        //     return state.currentAction?.id === actionItem.id && state.currentAction.buttonLabel === actionItem.buttonLabel;
        // }
        return false;
    }

    const onActionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, row: TableRowDTO, actionItem: ActionConfigDTO) => {
        // console.log('onActionClick', actionItem)
        if (e) {
            e.stopPropagation()
        }
        if (!actionItem) {
            return
        }
        if (actionItem.actionType === 'ROUTE') {
            navigate("/" + constructUrl(actionItem?.route || '', row));
            return
        } else if (actionItem.inLine && !(actionItem.actionType === ActionType.ACTION_ONLY || actionItem.actionType === ActionType.DOWNLOAD_NO_PROMPT)) {

            // if (state.currentAction && state.currentAction.id === actionItem.id) {
            //     dispatch(closeAction({rowId})) // clean the action
            // } else if (state.currentAction && state.currentAction.id !== actionItem.id) {
            //     dispatch(closeAction({rowId})) // clean the action
            //     dispatch(updateCurrentAction({
            //         rowId,
            //         currentAction: actionItem
            //     }))
            // } else {
            //     dispatch(updateCurrentAction({
            //         rowId,
            //         currentAction: actionItem
            //     }))
            // }
            dispatch(toggleOptionFieldOpen({id: rowId}))
        } else {
            if (actionItem.actionType === ActionType.ACTION_ONLY) {

                if (actionItem.actionOnlyFunction) {
                    // log.info(className, 'MSG001', 'ACTION_ONLY', {rowId: row.id, type: action.actionType})
                    actionItem.actionOnlyFunction(actionItem, row)
                }
            } else if (actionItem.actionType === ActionType.DOWNLOAD_NO_PROMPT) {
                if (actionItem.downloadFunction) {
                    actionItem.downloadFunction(actionItem, row)
                }
            } else {
                dispatch(toggleActionItem({rowId, actionItem, showType: ShowActionType.MODAL}))
            }
        }
    }

    const renderRowActions = (): React.ReactElement => {
        return (<></>)
        // return (
        //     <ActionColumnStyled key={'actions'} numberOfActions={numberOfActions}>
        //         {
        //             row.actions && row.actions
        //                 .filter(action => maintainTableService.passesCondition(action, row, sessionContext.getLoggedInUser()))
        //                 .map((action: ActionConfigDTO, idx) =>
        //                     (typeof action?.tooltip === "undefined") ?
        //                         <span key={idx} className={'mx-1'}>
        //                         <Icon
        //                             onClick={(e) => onActionClick(e, row, action)}
        //                             className={'action_icon ' + action.icon + (isActionIconActive(row, action) ? ' active' : '')}
        //                             icon={action.icon}/>
        //                     </span> :
        //                         <ElementWithTooltip
        //                             key={idx}
        //                             element={
        //                                 <span key={idx} className={'mx-1'}>
        //                         <Icon
        //                             onClick={(e) => onActionClick(e, row, action)}
        //                             className={'action_icon ' + action.icon + (isActionIconActive(row, action) ? ' active' : '')}
        //                             icon={action.icon}/>
        //                     </span>}
        //                             tooltip={<span>{maintainTableService.getTitle(row, action?.tooltip || '')}</span>}/>
        //                 )
        //         }
        //
        //     </ActionColumnStyled>
        // )
    }

    const renderExpandedPanel = ({state, opened}: RowExpanderActionState): React.ReactElement => {
        // if (!opened) {
        return <></>
        // } else {
        //     return (
        //         <NestedTableRowStyled key={'id_' + rowId + '_' + state.currentAction?.id}>
        //             <NestedTableColumnStyled colSpan={fields.length + 1 + (arrayNotEmpty(row.actions) ? 1 : 0)}
        //                                      cellWidth={'auto'}>
        //                 <div className="row g-0">
        //                     <div className={'col-md-12 '}>
        //                         <ExpandedPanelStyled>
        //                             {conditionalRowDetails?.table && <GuslTable
        //                                 code={'id_' + rowId + '_' + state.currentAction?.id}
        //                                 hideHeaderPanel={true}
        //                                 label={'cascading table'}
        //                                 selectUrl={constructUrl(conditionalRowDetails?.url || conditionalRowDetails?.expandUrl || '', row)}
        //                             />}
        //                             {conditionalRowDetails?.report && <GuslReport
        //                                 code={'id_' + rowId + '_' + state.currentAction?.id}
        //                                 selectUrl={constructUrl(conditionalRowDetails?.url || conditionalRowDetails?.expandUrl || '', row)}
        //                             />
        //                             }
        //                         </ExpandedPanelStyled>
        //                     </div>
        //                 </div>
        //             </NestedTableColumnStyled>
        //         </NestedTableRowStyled>
        //     )
        // }
    }

    const renderRowExpandIcon = ({state, opened}: RowExpanderActionState): React.ReactElement => {
        const onRowExpandClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation()
            dispatch(toggleRowExpander({state: state, id: rowId}))
            dispatch(setOpenExpandedRowId((openExpandedRowId !== "" && openExpandedRowId === rowId && opened) ? "" : rowId))
        }

        if (notDefined(conditionalRowDetails)) {
            return <></>
        }

        return (
            <NestedTableColumnStyled key={'row-expand'} cellWidth={expandableIconCellWidth}>
                        <span className={'mx-1'}>
                                <ChevronIconStyled isOpen={opened}
                                                   onClick={(e) => onRowExpandClick(e)}
                                                   icon={'fa fa-chevron-right'}/>
                            </span>
            </NestedTableColumnStyled>
        )
    }

    const mapStateToProps = (state: any): RowExpanderActionState => {
        let currentState = state.rowExpanderSlice[rowId];
        return ({
            opened: currentState ? currentState.opened : false,
            state: currentState
        })
    }
    const InLineRowExpanderIconConnectedContainer = connect(mapStateToProps)(renderRowExpandIcon);
    const InLineExpanderPanelConnectedContainer = connect(mapStateToProps)(renderExpandedPanel);

    function getCellWidth(fld: FieldConfigDTO) {
        const hdrKey = "hdr_" + fld.name + "_" + code // + (isSummaryHeader ? '_header' : '') + (isSummaryFooter ? '_footer' : '')
        const correspondingHeader = document.getElementById(hdrKey);
        return _columnResizeState?.resizedColumns[fld.name] || getWidthFromStyle(fld.style) || getWidthFromStyle(getStyle(fld.tableCss)) || fld.cellWidth || defaultCellWidth;
    }

    function isBeingResized(fld: FieldConfigDTO) {
        return fld.name === currentlyResizingHeaderName;
    }

    const renderRemoveRow = (): React.ReactElement => {
        if (canDelete && formMode === FormMode.EDIT) {
            return (
                <NestedTableColumnStyled key={'remove_' + rowId}
                                         cellWidth={50}
                                         id={'remove_' + rowId}
                                         className={'joe ' + rowId}>
                    <RemoveIconStyled role={'button'}
                                      onClick={() => onDelete(rowIndex)}/>

                </NestedTableColumnStyled>
            )
        } else {
            return <></>
        }
    }

    const renderDragIcon = (): React.ReactElement => {
        if (!canReorder) {
            return <></>
        }
        return (
            <NestedTableColumnStyled key={'drag_' + rowId}
                                     cellWidth={50}
                                     id={'remove_' + rowId}
                                     className={'joe ' + rowId}>
                <DraggableIconStyled
                    icon={'fa-solid fa-up-down-left-right '}
                    className={'pt-1 pe-1'}
                    active={canReorder}
                />

            </NestedTableColumnStyled>
        )
    }


    const renderFields = (): React.ReactElement => {

        const onSubmitted = (): void => {
            // do nothing
        }
        const onChangeHandler = (name: string, value: any, rowIndex: number): void => {
            dispatch(updateNestedRow({code: code, name, value, rowIndex, parentFieldName: fieldName}))
        }
        const getCurrentRowData = (): any => {
            return _guslFormState?.formData[fieldName || '']
        }

        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference);
        }

        const reLoad = () => {
            // onRefresh()
        }

        const editable = (formMode === FormMode.NEW || formMode === FormMode.EDIT)
        return (
            <>
                {fields.filter((fieldConfig: FieldConfigDTO) => canShowOnForm(fieldConfig, formMode)).map((fieldConfig: FieldConfigDTO, idx: number) => {
                        // @ts-ignore
                        // const fieldData = formValue[fieldConfig.name]
                        const rowData = _guslFormState?.formData[fieldName || ''][rowIndex]
                        const fieldData = rowData[fieldConfig.name]
                        return <NestedTableColumnStyled key={'det_' + rowId + '_' + idx}
                                                        beingResized={isBeingResized(fieldConfig)}
                                                        style={fieldConfig.style ? cleanWidthFromStyle(fieldConfig.style) : cleanWidthFromStyle(getStyle(fieldConfig.tableCss))}
                                                        cellWidth={getCellWidth(fieldConfig)}
                                                        id={'det_' + rowId + '_' + idx}
                                                        className={'joe ' + rowId}>
                            {!editable && fieldService.getTableField(fieldConfig, menuItem, rowData)?.render()}
                            {editable && fieldService.getFormTemplate({
                                code: code,
                                formMode: formMode,
                                fieldConfig,
                                // menuItem: properties.menuItem,
                                data: fieldData,
                                onSubmitted,
                                onChange: (name: string, value: any) => onChangeHandler(name, value, rowIndex),
                                getCurrentRowData,
                                isDialog: false,
                                menuItem: undefined,
                                reference: {
                                    name: fieldConfig.name,
                                    displayOrder: idx,
                                    register: registerChildReference
                                },
                                reLoad: reLoad,
                                inline: true
                            })}
                        </NestedTableColumnStyled>
                    }
                )}
            </>
        )
    }

    const getItemStyle = (draggableStyle: DraggingStyle | NotDraggingStyle | undefined, isDragging: boolean) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? movingBgColor : itemBgColor,
        color: isDragging ? movingFgColor : itemFgColor,
        fontSize: itemFontSize,
        // borderBottom: border,
        lineHeight: environmentContext.isMobileDevice(widgetPanelProperties) ? '30px' : '18px',
        ...draggableStyle
    });


    const renderTableRows = (): React.ReactElement => {
        const onRowClicked = (id: string) => {
            if (firstTab) {
                navigate('/' + code + '/' + id + '/' + firstTab)
            }
        }


        // if (row.flash) {
        //     setTimeout(() => document?.getElementById(code + '_id_' + rowId)?.classList?.remove('highlight'), 3000)
        // }

        return (
            <Draggable
                key={'row_' + rowIndex}
                draggableId={'row_' + rowIndex}
                index={rowIndex}
                isDragDisabled={!canReorder}
            >
                {(provided: any, snapshot: any) => (


                    <NestedTableRowStyled
                        ref={provided.innerRef}
                        provided={provided}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={getItemStyle(
                            provided.draggableProps.style,
                            snapshot.isDragging
                        )}

                        key={code + '_id_' + rowId} id={code + '_id_' + rowId}
                        className={'fred ' + rowId + ' '}
                        // onClick={() => onRowClicked(row.id)} firstTab={firstTab}
                    >
                        {/*{state.expandableRow && <InLineRowExpanderIconConnectedContainer/>}*/}
                        {/*{arrayNotEmpty(row.actions) && renderRowActions()}*/}
                        {renderFields()}
                        {renderRemoveRow()}
                        {renderDragIcon()}

                    </NestedTableRowStyled>
                )}
            </Draggable>

        )
    }

    const renderRow = (): React.ReactElement => {
        if (loading) {
            return <></>
        }
        const closeDialog = () => {
            performTableRefresh()
        }

        return (
            <>
                {renderTableRows()}
                <InLineExpanderPanelConnectedContainer/>
            </>
        )
    }


    return (
        <>
            {!loading && renderRow()}
        </>
    )
}
