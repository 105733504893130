import styled from 'styled-components';

export const HoverOuterPanelStyled = styled.div.attrs((props) => ({}))`
  backdrop-filter: blur(10px);
  position: absolute;
  top: -15px;
  z-index: 20;
  border-radius: 10px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 108px
   
`

export const HoverInnerPanelStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.columnColor};
  background-color: ${props => props.theme.colors.background[1]};
  opacity: 1;

  overflow: auto;

  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px rgba(242, 244, 248, 0.25), 0 1px 1px 1px rgba(12, 17, 247, 0.15);
  margin: 5px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  
`
export const HoverLinkStyled = styled.div.attrs((props) => ({}))`

  position: relative;

`
