import styled from 'styled-components';

export const GlobalSearchBoxWrapperStyled = styled.div.attrs((props) => ({
    id:'globalsearch_box_wrapper'
}))
    `
        padding: 5px;

        .float-end {
            float: none !important;;
        }
    `;
