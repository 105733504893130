// @ts-  nocheck
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import React from 'react';
import {BlastProps} from '../../../../../providers/blast/BlastContext';
import {SessionContextProps} from '../../../../../providers/session/types';
import {arrayNotEmpty} from '../../../../../utils/TypeCheckers';
import {matchMediaTypeWithAction, matchMediaTypeWithOrderByAction} from '../../../../../utils/Utils';
import {
    ActionConfigDTO,
    FormMode,
    IMenuDTO,
    MediaType,
    OrderByActionDO,
    PageResponseDTO,
    QueryParamsDTO,
    TableRowDTO
} from '../../../../types';
import {getTableData, TableResponseWrapper} from '../../../gusl-table/guslTableSlice';
import paginationService from '../../../gusl-table/PaginationService';
import {maintainTableService} from '../../../maintain-table/MaintainTableService';

interface DataStateRepo {
    [id: string]: DataState;
}

export interface DataState {
    code: string,
    sessionContext: SessionContextProps | undefined,
    blastContext: BlastProps | undefined,
    label: string | undefined,
    selectUrl: string,
    tableData: TableRowDTO[] | undefined,
    tableActions: ActionConfigDTO[],
    rowActions: ActionConfigDTO[],
    groupActions: ActionConfigDTO[],
    orderByActions: OrderByActionDO[],
    onRefresh: (() => void) | undefined,
    onInlineMenuGroupAction: ((event: React.MouseEvent, item: IMenuDTO) => void) | undefined,
    performDownloadWithoutPromptAction: ((action?: ActionConfigDTO, row?: TableRowDTO) => void) | undefined,
    performActionOnly: ((action?: ActionConfigDTO, row?: TableRowDTO) => void) | undefined,
    mediaType: MediaType,
    refreshCounter: number,
    haveRowActions: boolean
    haveGroupActions: boolean;
    haveOrderByActions: boolean;
    lastQueryParams: QueryParamsDTO | undefined
}

export interface BespokeDataInitPayload {
    code: string;
    sessionContext: SessionContextProps,
    blastContext: BlastProps | undefined,
    label: string | undefined
    selectUrl: string,
    tableData: TableRowDTO[] | undefined,
    tableActions: ActionConfigDTO[],
    rowActions: ActionConfigDTO[],
    groupActions: ActionConfigDTO[],
    orderByActions: OrderByActionDO[],
    onRefresh: (() => void) | undefined,
    mediaType: MediaType
}

export interface StartBlastListenPayload {
    code: string;
}

const createDefault = (code: string): DataState => {
    return {
        code: code,
        sessionContext: undefined,
        blastContext: undefined,
        label: undefined,
        tableData: [],
        selectUrl: '',
        tableActions: [],
        rowActions: [],
        groupActions: [],
        orderByActions: [],
        haveRowActions: false,
        haveGroupActions: false,
        haveOrderByActions: false,
        onRefresh: undefined,
        onInlineMenuGroupAction: undefined,
        performDownloadWithoutPromptAction: undefined,
        performActionOnly: undefined,
        mediaType: MediaType.Desktop,
        refreshCounter: 0,
        lastQueryParams: paginationService.blankQueryParam()
    }
}

const initialState: DataStateRepo = {};

const getDataState = (state: DataStateRepo, code: string): DataState => {
    let entry: DataState = state[code];
    if (!entry) {
        entry = createDefault(code);
    }
    return entry;
};

const loadInitValues = (entry: DataState, values: BespokeDataInitPayload, code: string) => {
    const enhancedTableData = (values.tableData || []).flatMap<TableRowDTO>(row => {
        const newRow = Object.assign({}, row);
        newRow.formMode = FormMode.VIEW;
        return newRow;
    });

    entry.lastQueryParams = paginationService.blankQueryParam();
    entry.groupActions = values.groupActions;
    if (arrayNotEmpty(values.groupActions.filter(action => matchMediaTypeWithAction(action, values.mediaType)))) {
        entry.haveGroupActions = true;
    }

    entry.orderByActions = values.orderByActions;
    if (arrayNotEmpty(values.orderByActions.filter(action => matchMediaTypeWithOrderByAction(action, values.mediaType)))) {
        entry.haveOrderByActions = true;
    }

    if (arrayNotEmpty(values.rowActions.filter(action => matchMediaTypeWithAction(action, values.mediaType)))) {
        entry.haveRowActions = true;
    }
    entry.sessionContext = values.sessionContext;
    entry.blastContext = values.blastContext;

    entry.label = values.label;
    entry.tableData = enhancedTableData; // values.tableData
    entry.selectUrl = values.selectUrl;
    entry.tableActions = values.tableActions;
    entry.rowActions = values.rowActions;
    entry.groupActions = values.groupActions;
    entry.orderByActions = values.orderByActions;
    entry.mediaType = values.mediaType;
    entry.onRefresh = values.onRefresh;
};

const copyState = (entry: DataState): DataState => {
    const newState = {};
    for (const key in entry) {
        if (entry.hasOwnProperty(key)) {
            // @ts-ignore
            newState[key] = entry[key];
        }
    }
    return newState as DataState;
};

const updateData = (inboundState: DataState, response: PageResponseDTO): DataState => {
    const entry: DataState = copyState(inboundState);
    if (response) {
        entry.tableData = response.content;
        if (response.queryParams) {
            entry.lastQueryParams = response.queryParams;
        }


        if (response.actions) {
            // this is cascading table row actions
            entry.rowActions = response.actions.filter(action => matchMediaTypeWithAction(action, entry.mediaType));
        }

        if (response.groupActions) {
            // this is cascading table row actions
            entry.groupActions = response.groupActions.filter(action => matchMediaTypeWithAction(action, entry.mediaType));
        }

        if (response.orderByActions) {
            // this is cascading table row actions
            entry.orderByActions = response.orderByActions.filter(action => matchMediaTypeWithOrderByAction(action, entry.mediaType));
        }

        if (arrayNotEmpty(entry.rowActions)) {
            entry.haveRowActions = true;
        }

        if (arrayNotEmpty(entry.groupActions)) {
            entry.haveGroupActions = true;
        }

        entry.tableData = (response.content || []).flatMap<TableRowDTO>(row => {
            const newRow = Object.assign({}, row);
            // const newRow = current( row)
            newRow.actions = maintainTableService.extractRowActionsFlattenedForRow(row, entry.rowActions || [],
                entry.performDownloadWithoutPromptAction, entry.performActionOnly)
                .filter(action => matchMediaTypeWithAction(action, entry.mediaType));
            newRow.formMode = FormMode.VIEW;
            return newRow;
        });
        //  entry.refreshCounter = entry.refreshCounter + 1
        // }
    }
    return entry;
};

const listenToBlastCommands = (inboundState: DataState): void => {
    console.log('--- listening to blast commands ----')
}

const stopListeningToBlastCommands = (inboundState: DataState): void => {
    console.log('--- stop listening to blast commands ----')
}

export const bespokeDataSlice = createSlice({
    name: "bespokeDataSlice",
    initialState,
    reducers: {
        initDateTable(state, action: PayloadAction<BespokeDataInitPayload>) {
            const code = action.payload.code;
            // @ts-ignore
            const entry: DataState = getDataState(state, code);
            loadInitValues(entry, action.payload, code);
            // @ts-ignore
            state[action.payload.code] = entry;
        },

        startBlastListen(state, action: PayloadAction<StartBlastListenPayload>) {
            const code = action.payload.code;
            // @ts-ignore
            const entry: DataState = getDataState(state, code);
            listenToBlastCommands(entry);
        },
        closeBlastListener (state, action: PayloadAction<StartBlastListenPayload>) {
    const code = action.payload.code;
    // @ts-ignore
    const entry: DataState = getDataState(state, code);
    stopListeningToBlastCommands(entry);
},

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getTableData.fulfilled, (state, action: PayloadAction<TableResponseWrapper>) => {
            // @ts-ignore
            const entry: DataState = getDataState(state, action.payload.code);
            // @ts-ignore
            state[action.payload.code] = updateData(entry, action.payload.response);
        });
    }
});

export const {
    initDateTable,
    startBlastListen,
    closeBlastListener

} = bespokeDataSlice.actions;

export default bespokeDataSlice.reducer;
