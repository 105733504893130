export const DecorationStyle: any = {
    name: 'Decoration',
    open: false,
    properties: [
        'opacity',
        'border-radius',
        'border',
        'box-shadow',
        'background', // { id: 'background-bg', property: 'background', type: 'bg' }
    ],
}
