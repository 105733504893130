
export function camelToKebabCase(val: string) {
    let result: string = val;

    // Convert camelCase capitals to kebab-case.
    result = result.replace(/([a-z][A-Z])/g, function (match) {
        return match.substr(0, 1) + '-' + match.substr(1, 1).toLowerCase();
    });

    // Convert non-camelCase capitals to lowercase.
    result = result.toLowerCase();

    // Convert non-alphanumeric characters to hyphens
    result = result.replace(/[^-a-z0-9]+/g, '-');

    // Remove hyphens from both ends
    result = result.replace(/^-+/, '').replace(/-$/, '');

    return result;
}

export function kebabCaseToCamel(val: string): string {
    return val.replace(/-([a-z])/gi, function ($0, $1) {
        return $1.toUpperCase();
    });
}

export function encodeRequest(o: any): any {
    if (typeof o === 'string') {
        return camelToKebabCase(o);
    }
    let newO: any, newKey: string, value;
    if (o instanceof Array) {
        newO = [];
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                value = o[origKey];
                if (typeof value === 'object') {
                    value = encodeRequest(value);
                }
                newO.push(value);
            }
        }
    } else {
        newO = {};
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = camelToKebabCase(origKey);
                value = o[origKey];
                if (value !== null && value !== undefined && value.constructor === Object) {
                    value = encodeRequest(value);
                }
                newO[newKey] = value;
            }
        }
    }
    return newO;
}

export function decodeResponse(o: any): any {

    if (typeof o === 'string') {
        return kebabCaseToCamel(o);
    }
    let newO: any, newKey: string, value;
    if (o instanceof Array) {
        newO = [];
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                value = o[origKey];

                if (typeof value === 'object') {
                    value = decodeResponse(value);
                }
                newO.push(value);
            }
        }
    } else {
        newO = {};
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = kebabCaseToCamel(origKey);
                value = o[origKey];
                if (value !== null && value.constructor === Object) {
                    value = decodeResponse(value);
                } else if (value instanceof Array) {
                    value = decodeResponse(value);
                }
                newO[newKey] = value;
            }
        }
    }
    return newO;
}
