export const DimensionStyle: any = {
    name: 'Dimension',
    open: false,
    properties: [
        'width',
        {
            id: 'flex-width',
            type: 'integer',
            name: 'Width',
            units: ['px', '%'],
            property: 'flex-basis',
            toRequire: 1,
        },
        'height',
        'max-width',
        'min-height',
        'margin',
        'padding'
    ],
}
