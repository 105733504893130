import {Modal} from 'react-bootstrap';
import styled from 'styled-components';

export const OtherDealGrid = styled.div.attrs((props) => ({
    className: 'g-0',
    id:'other-deals-grid'
}))`
`
export const ArticleViewDialogBodyStyled = styled(Modal.Body).attrs(props => ({
    id:'article_view_dialog_body'
}))`
    background-color: #fff;
    padding: 0 !important;
    //max-height: 700px;
    overflow: auto;
`
export const ArticleViewWrapperStyled = styled.div.attrs((props) => ({
    id: 'article-wrapper'
}))`
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
`;

export const SearchWrapperStyled = styled.div.attrs((props) => ({
    id: 'search-wrapper'
}))`
    width: 35%;
    text-align: center;
    margin: 0 auto;
    padding: 10px;    
`;
