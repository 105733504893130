/*just the chart*/
export const NAKED_CHART_OPTIONS = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        x: {
            display: true,
            grid: {
                display: false
            }
        },
        y: {
            display: true,
            grid: {
                display: false
            }
        },
        r: {
            display: false,
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            labels: {
                show: false,
                boxWidth: 12,
                color: '#95a0ac',
                // usePointStyle:true,
                // rotation:30,
                // pointStyle:'star',

            }
        },
        title: {
            display: false,

        },

    },

}
