import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {inputClass} from "../../../../pages/login/LoginUsernamePassword";
import {EnvironmentContext} from "../../../../providers/environment/EnvironmentContext";
import {environmentService} from "../../../../providers/environment/EnvironmentService";
import MGMutation from "../../../../providers/session/MGMutation";
import {SessionContext} from "../../../../providers/session/SessionContext";
import {FavQueryActionConfigDTO, FavQueryDTO, QueryParamsDTO} from "../../../types";
import {ElementWithTooltip} from "../../element-with/element-with-tooltip/ElementWithTooltip";
import {GuslTableState, handleFavQueriesUpdates} from "../guslTableSlice";
import {GuslTableQueryParamState} from "../queryParamsSlice";
import {ErrorMsgStyled, FavQueriesFormStyled} from "../styled/side-panel/styled";
import {FavQueryDO} from "./FavQuery";
import {validateQueryName} from "./functions";

interface FavQueryFormDO {
    code: string;
    action: "add" | "edit" | "remove";
    favQuery?: FavQueryDTO;
    functions: FavQueryDO["functions"];
    errorMsg: string;
}

export default function FavQueryForm({code, action, favQuery, functions, errorMsg}: FavQueryFormDO) {
    const [className] = useState("FavQueryForm-" + new Date().getTime());
    const dispatch = useAppDispatch();
    const sessionContext = useContext(SessionContext);
    const environmentContext = React.useContext(EnvironmentContext);
    const state: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);
    const queryParamsState: GuslTableQueryParamState = useAppSelector(state => state.queryParamsSlice[code]);
    const favQueries: FavQueryDTO[] = state.favQueries;
    const queryNameRef = useRef<HTMLTextAreaElement>(null);
    const isDefaultRef = useRef<HTMLInputElement>(null);
    // MK 07-01-2024
    const isGlobalRef = useRef<HTMLInputElement>(null);
    const isQueryGlobal: boolean | undefined = favQuery?.isGlobal;
    const [validationErrorMsg, setErrorMsg] = useState<string>("");
    let queryParams: QueryParamsDTO = favQuery ? favQuery.queryParams : queryParamsState.queryParams;
    let toUpdateQueryParams: QueryParamsDTO = {...queryParams, fuzzy: queryParamsState.queryParams.fuzzy };

    console.log({favQueries, state:queryParamsState.queryParams, queryParams})
    const favQueriesURLs: FavQueryActionConfigDTO = environmentService.getEnvironment()?.favQueriesUrls as FavQueryActionConfigDTO;
    const addUrl: string = favQueriesURLs?.addUrl || "needToDoBackoffice";
    const updateUrl: string = favQueriesURLs?.updateUrl || "needToDoBackoffice";
    const deleteUrl: string = favQueriesURLs?.deleteUrl || "needToDoBackoffice";
    const formTitle: string = (action === "add" ? "Save current" : "Edit this") + " query";
    const saveBtnText: string = action === "add" ? "Save" : "Update";
    const isMobile = environmentContext.isMobileDevice();

    // MK 07-01-2024 todo get this permission from the server

    const hasPermissionToSaveAsGlobal: boolean = true;


    const urlToUseMap: { [key in FavQueryFormDO["action"]]: string } = {
        add: addUrl as string,
        edit: updateUrl as string,
        remove: deleteUrl as string
    };

    const urlToUse: string = urlToUseMap[action];

    useEffect(() => {

        if (favQuery && queryNameRef && isDefaultRef) {
            queryNameRef!.current!.value = favQuery.queryName;
            isDefaultRef!.current!.checked = favQuery.isDefault;
        }
    }, [queryNameRef, isDefaultRef]);


    async function favQueryHandler() {


        const isDefault = isDefaultRef!.current!.checked;
        const isGlobal = false;
        const queryName = queryNameRef!.current!.value.trim();
        let _favQuery: FavQueryDTO = {
            queryName,
            isDefault,
            code,
            queryParams:toUpdateQueryParams,
            isGlobal
        };
        if (action === "edit") {
            _favQuery.id = favQuery!.id;
        }
        const errorMessage: string = validateQueryName(favQueries, action, _favQuery).validationErrorMsg;
        if (errorMessage) {
            setErrorMsg(errorMessage);
            return;
        }
        setErrorMsg("");

        await MGMutation({
            // @ts-ignore
            sessionContext,
            endpoint: urlToUse,
            queryParams: {..._favQuery}
        }).then(result => {
            // MK 06-01-2024
            dispatch(handleFavQueriesUpdates({code}));

        }).catch(e => {
            functions.setErrorMsg("Something went wrong!");
        });

    }

    return (
        <FavQueriesFormStyled>
            <p className={"text-center"}>{formTitle}</p>
            {errorMsg && <ErrorMsgStyled>Something went wrong!</ErrorMsgStyled>}
            <div className="input-group mb-3">
                <div className="input-group-text bg-transparent rounded-0  border-0">
                    <ElementWithTooltip
                        placement={isMobile ? "bottom" : "left-start"}
                        element={<input className="mt-0 "
                                        ref={isDefaultRef}
                                        type="checkbox"
                                        defaultChecked={favQuery?.isDefault}
                        />} tooltip={<span>Make this query a default.
                                        <hr/>We will use this query, when loading this view for the first time.
                  </span>}/>
                </div>
                <textarea ref={queryNameRef} name="name" id="name"
                          placeholder={"Name or description"}
                          className={inputClass("small")}></textarea>
            </div>
            {validationErrorMsg ? <ErrorMsgStyled>{validationErrorMsg}</ErrorMsgStyled> : <></>}
            <div className="d-flex align-items-center justify-content-center">
                {/*{hasPermissionToSaveAsGlobal && <ElementWithTooltip*/}
                {/*    placement={isMobile ? "bottom" : "left-start"}*/}
                {/*    element={<input className="mt-0 "*/}
                {/*                    ref={isGlobalRef}*/}
                {/*                    type="checkbox"*/}
                {/*                    defaultChecked={isQueryGlobal}*/}
                {/*    />} tooltip={<span> {isQueryGlobal ? "This query is global." : "Make this query a global."}*/}
                {/*    <hr/>We will load this query for every user.*/}
                {/*  </span>}/>}*/}
                <button className={"bg-transparent border-0"} role={"button"}
                        onClick={favQueryHandler}>{saveBtnText}</button>
            </div>


        </FavQueriesFormStyled>
    );
};
