import styled from 'styled-components';

export const FeUsersStyled = styled.div.attrs((props) => ({
    className: 'col-6'
}))`
  color: ${props => props.theme.navigation.topNavbarColor};
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
  min-width: 80px;
  position: relative;
  top: 6px;  
`

export const DisplayFeContainerStyled = styled.div.attrs((props) => ({}))`
  z-index: 1050;
  position: relative;
  left: -100px;
  top: 20px;
`

export const DisplayFeDetailsStyled = styled.div.attrs((props) => ({}))`
  background-color: ${props => props.theme.modal.innerBackground};
  border: ${props => 'solid 1px ' + props.theme.navigation.borderColor};
  min-width: 300px;
  max-width: 450px;
  max-height: 450px;
  position: absolute;
  top: 0px
`

export const FeHeaderDetailsStyled = styled.div.attrs((props) => ({}))`
  background-color: ${props => props.theme.modal.outerBackground};
  border-bottom: ${props => 'solid 1px ' + props.theme.table.headerColor};
  padding: 5px;
`
export const FeDetailsStyled = styled.div.attrs((props) => ({}))`
  background-color: ${props => props.theme.colors.dark[0]};
  padding: 5px;
  max-height: 200px;
  overflow: auto;`

export interface DetailTableStyledProperties {
    numberRows: number
}

export const DetailTableStyled = styled.table.attrs((props: DetailTableStyledProperties) => ({}))<DetailTableStyledProperties>`
  padding: 3px;
  color: ${props => props.theme.table.headerColor};
  font-size: ${props => props.theme.table.headerFontSize};
  width: ${props => props.numberRows === 0 ? '150px' : '400px'};
`

export interface DetailColumnStyledProperties {
    align?: string;
}

export const DetailHeaderStyled = styled.th.attrs((props: DetailColumnStyledProperties) => ({}))<DetailColumnStyledProperties>`
  padding: 3px;
  color: ${props => props.theme.table.headerColor};
  font-size: ${props => props.theme.table.headerFontSize};
  text-align: ${props => {
    if (props.align) {
      return props.align
    } else {
      return 'inherit'
    }
  }};
}

;
`
export const DetailColumnStyled = styled.td.attrs((props: DetailColumnStyledProperties) => ({}))<DetailColumnStyledProperties>`
  padding: 3px;
  color: ${props => props.theme.table.columnColor};
  font-size: ${props => props.theme.table.columnFontSize};
  text-align: ${props => {
    if (props.align) {
      return props.align
    } else {
      return 'inherit'
    }
  }};
`
export const CloseIconStyled = styled.i.attrs((props) => ({
    className: 'fa-solid fa-xmark me-1'
}))`
  margin-top: 3px;
  float: right;

  &:hover {
    background-color: ${props => props.theme.modal.outerBackground};
  }

`

export const MessageStyled = styled.div.attrs((props) => ({}))`
  padding-top: 5px;
`
