import {BaseReactComponent} from '../components/BaseReactComponent';
import {HtmlContentGjsComponent} from '../components/bespoke/htmlcontent/HtmlContentGjsComponent';
import {ListingComponent} from '../components/bespoke/ListingComponent';
import {TimetableContentGjsComponent} from '../components/bespoke/timetable/TimetableContentGjsComponent';
import {HtmlBlock} from './bespoke/HtmlBlock';
import {OssComment} from './bespoke/OssComment';
import {OssHeader} from './bespoke/OssHeader';
import {OssImage} from './bespoke/OssImage';
import {OssSubHeader} from './bespoke/OssSubHeader';

export interface BespokePlugin {
    id: string,
    plugin: Function

}

export const BespokePlugins: BespokePlugin [] = [
    {
        id: 'oss-header',
        plugin: OssHeader
    },
    {
        id: 'oss-sub-header',
        plugin: OssSubHeader
    },
    {
        id: 'oss-comment',
        plugin: OssComment
    },
    {
        id: 'oss-image',
        plugin: OssImage
    },
    {
        id: 'react-component',
        plugin: ListingComponent
    },
    {
        id: 'base-react',
        plugin: BaseReactComponent
    },
    {
        id: 'ucit',
        plugin: HtmlContentGjsComponent
    },
    {
        id: 'timetable',
        plugin: TimetableContentGjsComponent
    }

]
