import React, {useState} from 'react';
import {useAppDispatch} from '../../../../../app/hooks';
import {addAllMustNots, removeAllMustNots} from '../../queryParamsSlice';
import {DefaultTextFilter} from '../default-text-filter/DefaultTextFilter';
import {TextFilterToolbarItemStyled, TextFilterToolbarStyled} from '../text-filters/styled';
import {FilterDO} from '../types';

export type OptionFiltersDO = {
    code: string,
    filter: FilterDO,

}
export const OptionFilter = ({code, filter}: OptionFiltersDO): React.ReactElement => {
    const dispatch = useAppDispatch();

    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const [options, setOptions] = useState<string[]>(filter?.options || []);

    const renderToolbar = (): React.ReactElement => {
        const onUnsetAll = (e: React.MouseEvent<HTMLDivElement>) => {
            const fieldName = filter.fieldName || filter.name
            dispatch(addAllMustNots({code, fieldName: fieldName, values: options}))
            setRefreshCounter(refreshCounter + 1)
        }

        const onSetAll = (e: React.MouseEvent<HTMLDivElement>) => {
            const fieldName = filter.fieldName || filter.name
            dispatch(removeAllMustNots({code, fieldName: fieldName}))
            setRefreshCounter(refreshCounter + 1)
        }

        return (
            <TextFilterToolbarStyled>
                <TextFilterToolbarItemStyled onClick={(e) => onSetAll(e)}>Set All</TextFilterToolbarItemStyled>
                <TextFilterToolbarItemStyled onClick={(e) => onUnsetAll(e)}>Unset All</TextFilterToolbarItemStyled>
            </TextFilterToolbarStyled>
        )
    }


    return (
        <>
            {renderToolbar()}

            {options.map((option, idx) =>
                <DefaultTextFilter key={idx} code={code} filter={{...filter, value: option}}/>
            )}
        </>
    )
}
